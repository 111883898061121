import styled from 'styled-components';

export const Footer = styled.div`
  padding: 24px;
`;

export const Container = styled.div`
  hot-sidebar-header {
    padding-right: 8px;
  }

  hot-sidebar-body {
    padding: 16px;
  }
`;
