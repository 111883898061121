export const timezoneOptions = [
  {
    id: 'utcMinus1200MainCities',
    label: '(UTC-12:00)',
    value: '-12:00',
  },
  {
    id: 'utcMinus1100MainCities',
    label: '(UTC-11:00) Pago Pago',
    value: '-11:00',
  },
  {
    id: 'utcMinus1000MainCities',
    label: '(UTC-10:00) Honolulu',
    value: '-10:00',
  },
  {
    id: 'utcMinu0100MainCities',
    label: '(UTC-09:00) Juneau',
    value: '-09:00',
  },
  {
    id: 'pacificTimeZone',
    label: '(UTC-08:00) Zona de Horário do Pacífico (EUA & Canadá)',
    value: '-08:00',
  },
  {
    id: 'pacificTimeZoneDaylightSavingTime',
    label:
      '(UTC-07:00) Zona de Horário do Pacífico (EUA & Canadá - Horário de Verão)',
    value: '-07:00_pacificTimeZoneDaylightSavingTime',
  },
  {
    id: 'mountainTimeZone',
    label: '(UTC-07:00) Zona de Horário da Montanha (EUA & Canadá)',
    value: '-07:00_mountainTimeZone',
  },
  {
    id: 'mountainTimeZoneDaylightSavingTime',
    label:
      '(UTC-06:00) Zona de Horário da Montanha (EUA & Canadá - Horário de Verão)',
    value: '-06:00_mountainTimeZoneDaylightSavingTime',
  },
  {
    id: 'centralTimeZone',
    label: '(UTC-06:00) Zona de Horário Central (EUA & Canadá)',
    value: '-06:00_centralTimeZone',
  },
  {
    id: 'centralTimeZoneDaylightSavingTime',
    label:
      '(UTC-05:00) Zona de Horário Central (EUA & Canadá - Horário de Verão)',
    value: '-05:00_centralTimeZoneDaylightSavingTime',
  },
  {
    id: 'easternTimeZone',
    label: '(UTC-05:00) Zona de Horário Oriental (EUA & Canadá)',
    value: '-05:00_easternTimeZone',
  },
  {
    id: 'utcMinus0500MainCities',
    label: '(UTC-05:00) Bogotá, Lima, Quito, Rio Branco',
    value: '-05:00_utcMinus0500MainCities',
  },
  {
    id: 'easternTimeZoneDaylightSavingTime',
    label:
      '(UTC-04:00) Zona de Horário Oriental (EUA & Canadá - Horário de Verão)',
    value: '-04:00_easternTimeZoneDaylightSavingTime',
  },
  {
    id: 'atlanticTimeZone',
    label: '(UTC-04:00) Zona de Horário do Atlântico (EUA & Canadá)',
    value: '-04:00_atlanticTimeZone',
  },
  {
    id: 'utcMinus0400MainCities',
    label: '(UTC-04:00) Cuiabá, Manaus, La Paz, Caracas, Assunção',
    value: '-04:00_utcMinus0400MainCities',
  },
  {
    id: 'atlanticTimeZoneDaylightSavingTime',
    label:
      '(UTC-03:00) Zona de Horário do Atlântico (EUA & Canadá - Horário de Verão)',
    value: '-03:00_atlanticTimeZoneDaylightSavingTime',
  },
  {
    id: 'utcMinus0400MainCitiesDaylightSavingTime',
    label: '(UTC-03:00) Cuiabá (Horário de Verão)',
    value: '-03:00_utcMinus0400MainCitiesDaylightSavingTime',
  },
  {
    id: 'utcMinus0300MainCities',
    label:
      '(UTC-03:00) São Paulo, Brasília, Fortaleza, Buenos Aires, Montevidéu, Nuuk',
    value: '-03:00_utcMinus0300MainCities',
  },
  {
    id: 'utcMinus0300MainCitiesDaylightSavingTime',
    label: '(UTC-02:00) São Paulo, Brasília, Nuuk (Horário de Verão)',
    value: '-02:00_utcMinus0300MainCitiesDaylightSavingTime',
  },
  {
    id: 'utcMinus0200MainCities',
    label: '(UTC-02:00) Fernando de Noronha',
    value: '-02:00_utcMinus0200MainCities',
  },
  {
    id: 'utcMinus0100MainCities',
    label: '(UTC-01:00) Praia, Ponta Delgada',
    value: '-01:00',
  },
  {
    id: 'utcPlus0000MainCities',
    label: '(UTC+00:00) Londres, Dublim, Lisboa, Acra, Freetown',
    value: '+00:00',
  },
  {
    id: 'utcPlus0000MainCitiesDaylightSavingTime',
    label: '(UTC+01:00) Londres, Dublim, Lisboa (Horário de Verão)',
    value: '+01:00_utcPlus0000MainCitiesDaylightSavingTime',
  },
  {
    id: 'centralEuropeanTime',
    label: '(UTC+01:00) Horário da Europa Central',
    value: '+01:00_centralEuropeanTime',
  },
  {
    id: 'centralEuropeanTimeDaylightSavingTime',
    label: '(UTC+02:00) Horário da Europa Central (Horário de Verão)',
    value: '+02:00_centralEuropeanTimeDaylightSavingTime',
  },
  {
    id: 'easternEuropeanTime',
    label: '(UTC+02:00) Horário da Europa Oriental',
    value: '+02:00_easternEuropeanTime',
  },
  {
    id: 'easternEuropeanTimeDaylightSavingTime',
    label: '(UTC+03:00) Horário da Europa Oriental (Horário de Verão)',
    value: '+03:00_easternEuropeanTimeDaylightSavingTime',
  },
  {
    id: 'utcPlus0300MainCities',
    label: '(UTC+03:00) Moscou, Bagdá, Istambul, Riade, Nairóbi',
    value: '+03:00_utcPlus0300MainCities',
  },
  {
    id: 'utcPlus0400MainCities',
    label: '(UTC+04:00) Dubai, Samara',
    value: '+04:00',
  },
  {
    id: 'utcPlus0500MainCities',
    label: '(UTC+05:00) Carachi, Malé, Ecaterimburgo',
    value: '+05:00',
  },
  {
    id: 'utcPlus0530MainCities',
    label: '(UTC+05:30) Mumbai, Colombo',
    value: '+05:30',
  },
  {
    id: 'utcPlus0600MainCities',
    label: '(UTC+06:00) Daca, Thimbu, Omsk',
    value: '+06:00',
  },
  {
    id: 'utcPlus0700MainCities',
    label: '(UTC+07:00) Jacarta, Banguecoque, Krasnoyarsk',
    value: '+07:00',
  },
  {
    id: 'utcPlus0800MainCities',
    label: '(UTC+08:00) Xangai, Hong Kong, Taipé, Singapura, Perth',
    value: '+08:00',
  },
  {
    id: 'utcPlus0900MainCities',
    label: '(UTC+09:00) Tóquio, Seul, Pyongyang',
    value: '+09:00',
  },
  {
    id: 'utcPlus1000MainCities',
    label: '(UTC+10:00) Sydney',
    value: '+10:00',
  },
  {
    id: 'utcPlus1000MainCitiesDaylightSavingTime',
    label: '(UTC+11:00) Sydney (Horário de Verão)',
    value: '+11:00_utcPlus1000MainCitiesDaylightSavingTime',
  },
  {
    id: 'utcPlus1100MainCities',
    label: '(UTC+11:00) Porto Vila, Nouméa',
    value: '+11:00_utcPlus1100MainCities',
  },
  {
    id: 'utcPlus1200MainCities',
    label: '(UTC+12:00) Auckland, Suva',
    value: '+12:00',
  },
  {
    id: 'utcPlus1300MainCities',
    label: "(UTC+13:00) Apia, Nuku'alofa",
    value: '+13:00',
  },
  {
    id: 'utcPlus1400MainCities',
    label: '(UTC+14:00) Apia (Horário de Verão)',
    value: '+14:00',
  },
];
