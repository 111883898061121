import React, { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserInfo, usePageInfo, useFeatureFlags } from '@hooks/index';
import { Submenu } from '@components/Submenu';
import { DraggableElement } from './components/DraggableElement';
import { BlockedElement } from './components/BlockedElement';
import { getAddableLayouts, getAddableElements } from './addable-elements';
import { Content, Divider, DraggableContent, Title } from './styles';

export const AddSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();

  const { pageWithRedirectEnabled } = useUserInfo();
  const { transparentCheckoutEnabled } = useFeatureFlags();
  const { isFunnelCapturePage } = usePageInfo();

  const hiddenElements = useMemo(() => {
    const elements = [];

    if (!transparentCheckoutEnabled) {
      elements.push('addableTransparentCheckout');
    }

    if (isFunnelCapturePage) {
      elements.push('addableEmptyForm');
    }

    return elements;
  }, [transparentCheckoutEnabled, isFunnelCapturePage]);

  const addableElementsConfig = useMemo(
    () => ({
      blockedItems: { html: !pageWithRedirectEnabled },
      hiddenItems: hiddenElements,
    }),
    [hiddenElements, pageWithRedirectEnabled]
  );

  const renderAddableElement = (element: AddableDraggableElement) =>
    element.isBlocked ? (
      <BlockedElement element={element} key={element.id} />
    ) : (
      <DraggableElement element={element} key={element.id} />
    );

  return (
    <Submenu title="sideMenu.add.title" padding="0px" {...attrs}>
      <Content data-onboarding="addable-structures">
        <Title>{t('sideMenu.add.layout')}</Title>
        <DraggableContent>
          {getAddableLayouts().map((element) => renderAddableElement(element))}
        </DraggableContent>
      </Content>

      <Divider />

      <Content data-onboarding="addable-elements">
        <Title>{t('sideMenu.add.element')}</Title>
        <DraggableContent>
          {getAddableElements(addableElementsConfig).map((element) =>
            renderAddableElement(element)
          )}
        </DraggableContent>
      </Content>
    </Submenu>
  );
};
