import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';

import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useUseCases } from '@hooks/useUseCases';
import { templateToHtml } from '@hotmart-org-ca/saas-pages-engine-utils';
import { Block } from '@components/Block';
import { Sidebar } from '@components/Sidebar';
import { useDetectBrowser } from '@hooks/useDetectBrowser';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { getTemplateByType } from '../../salesUseCase/templates/index';
import {
  Container,
  Overlay,
  Radio,
  RadioLabel,
  SectionContent,
  SidebarFooter,
  SidebarTitle,
  TemplateContainer,
  Templates,
  ThumbContent,
  ThumbnailContainer,
  UseTemplateButton,
} from './styles';
import { SalesUseCase } from '../../salesUseCase';

export type SelectTemplateProps = HTMLAttributes<HTMLDivElement> & {
  properties: SalesPageProperties;
  preventNextClick?: boolean;
  hideThumb?: boolean;
  label?: string;
  onTemplateChange: (props: SalesPageProperties) => void;
  onNextClick?: () => void;
};

type TemplateConfig = {
  id: SalesPageTemplateType;
  template: Template;
  label: string;
};

export const SelectTemplateComponent: React.FC<SelectTemplateProps> = ({
  properties,
  preventNextClick = false,
  hideThumb = false,
  label: labelProp = 'pageWizard.salesPage.model.title',
  onTemplateChange,
  onNextClick,
  ...attrs
}) => {
  const { isFirefox } = useDetectBrowser();
  const { t } = useTranslation();
  const { setFooterRightButtonConfig } = useUseCases();
  const previewContainerRef = useRef<HTMLDivElement | null>(null);
  const thumbContainerRef = useRef<HTMLDivElement | null>(null);

  const [previewContainerWidth, setPreviewContainerWidth] = useState<
    number | undefined
  >(undefined);
  const [previewContainerHeight, setPreviewContainerHeight] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (isFirefox && previewContainerRef.current) {
      setPreviewContainerWidth(
        previewContainerRef.current.parentElement?.getBoundingClientRect().width
      );
      setPreviewContainerHeight(
        previewContainerRef.current.parentElement?.getBoundingClientRect()
          .height
      );
      return;
    }

    setPreviewContainerWidth(undefined);
  }, [isFirefox, previewContainerHeight]);

  const [selected, setSelected] =
    useState<SalesPageTemplateType>('dark-secondary');

  const debouncedModifierChange = useRef(debounce(onTemplateChange, 500));
  const debouncedNextClick = useRef(
    onNextClick ? debounce(onNextClick, 300) : null
  );

  const [templatePreview, setTemplatePreview] = useState<{
    id: SalesPageTemplateType;
    template: Template;
  }>();

  const [previewHtml, setPreviewHtml] = useState<string>('');
  const [previewStyles, setPreviewStyles] = useState<string>('');

  const templates = useMemo<TemplateConfig[]>(
    () => [
      {
        id: 'dark-secondary',
        template: getTemplateByType('dark-secondary'),
        label: 'pageWizard.salesPage.model.darkSecondary',
      },
      {
        id: 'default',
        template: getTemplateByType('default'),
        label: 'pageWizard.salesPage.model.default',
      },
      {
        id: 'dark-primary',
        template: getTemplateByType('dark-primary'),
        label: 'pageWizard.salesPage.model.darkPrimary',
      },
      {
        id: 'classic',
        template: getTemplateByType('classic'),
        label: 'pageWizard.salesPage.model.classic',
      },
    ],
    []
  );

  const scrollPreviewToTop = () => {
    previewContainerRef.current?.parentElement?.scroll({ top: 0 });
  };

  const handleChange = useCallback(
    (id: SalesPageTemplateType) => {
      const image = SalesUseCase.imagesByTemplate[id];
      setSelected(id);

      debouncedModifierChange.current({
        ...properties,
        template: id,
        product: {
          ...properties.product,
          imgSrc: properties.product.imgSrc || image,
        },
        author: {
          ...properties.author,
          imgSrc: properties.author.imgSrc || image,
        },
      });
    },
    [properties]
  );

  const handlePreviewChange = useCallback(
    (id: SalesPageTemplateType, template: Template) => {
      const { html, styles } = templateToHtml({
        template,
      });
      const stringifiedStyles = Object.values(styles).join('');

      setTemplatePreview({ id, template });
      setPreviewHtml(html);
      setPreviewStyles(stringifiedStyles);

      scrollPreviewToTop();
    },
    []
  );

  const handlePreviewSelected = useCallback(() => {
    if (templatePreview) {
      const image = SalesUseCase.imagesByTemplate[templatePreview.id];
      const value = {
        ...properties,
        template: templatePreview.id,
        product: {
          ...properties.product,
          imgSrc: properties.product.imgSrc || image,
        },
        author: {
          ...properties.author,
          imgSrc: properties.author.imgSrc || image,
        },
      };

      setSelected(templatePreview.id);

      if (preventNextClick) {
        debouncedModifierChange.current(value);
      } else {
        onTemplateChange(value);

        debouncedNextClick.current?.();
      }
      setTemplatePreview(undefined);
    }
  }, [onTemplateChange, preventNextClick, properties, templatePreview]);

  const filterSectionsOfEachTemplate = (template: Template) => ({
    ...template,
    children: template.children?.slice(0, 3),
  });

  useEffect(() => {
    setSelected(properties.template || 'dark-secondary');
  }, [properties.template]);

  useEffect(() => {
    setFooterRightButtonConfig({
      disabled: false,
      errorMessage: '',
    });
  }, [setFooterRightButtonConfig]);

  return (
    <Block
      label={labelProp}
      description={hideThumb ? '' : 'pageWizard.salesPage.model.description'}
      width="100%"
      {...attrs}
    >
      <Templates>
        {templates.map(({ id, template, label }) => {
          const filteredTemplate = filterSectionsOfEachTemplate(template);
          const { html, styles } = templateToHtml({
            template: filteredTemplate,
          });
          const stringifiedStyles = Object.values(styles).join('');

          return (
            <TemplateContainer key={id} hideThumb={hideThumb}>
              <label htmlFor={`template-${id}`}>
                <Radio className="hot-form hot-form--custom hot-form--radio">
                  <input
                    id={`theme-${id}`}
                    type="radio"
                    className="hot-form__input"
                    checked={id === selected}
                    onChange={() => handleChange(id)}
                  />
                  <RadioLabel
                    className="hot-form__label"
                    htmlFor={`theme-${id}`}
                  >
                    {hideThumb ? t(label) : ''}
                  </RadioLabel>
                </Radio>
              </label>

              {!hideThumb && (
                <ThumbnailContainer
                  onClick={() => handleChange(id)}
                  ref={thumbContainerRef}
                >
                  {/* eslint-disable react/no-danger */}
                  <div
                    dangerouslySetInnerHTML={{ __html: stringifiedStyles }}
                  />
                  {/* eslint-enable react/no-danger */}
                  <ThumbContent
                    isFirefox
                    dangerouslySetInnerHTML={{
                      __html: html,
                    }}
                  />
                  <Overlay>
                    <button
                      className="hot-button hot-button--primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePreviewChange(id, template);
                      }}
                    >
                      {t('pageWizard.salesPage.model.preview')}
                    </button>
                  </Overlay>
                </ThumbnailContainer>
              )}
            </TemplateContainer>
          );
        })}
      </Templates>

      <Sidebar
        isOpen={Boolean(templatePreview)}
        onClose={() => setTemplatePreview(undefined)}
        header={
          <SidebarTitle>{t('pageWizard.salesPage.model.title')}</SidebarTitle>
        }
        footer={
          <SidebarFooter>
            <button
              className="hot-button hot-button--secondary"
              onClick={() => setTemplatePreview(undefined)}
            >
              {t('pageWizard.salesPage.model.sidebar.backButton')}
            </button>
            <UseTemplateButton
              className="hot-button hot-button--primary"
              onClick={handlePreviewSelected}
            >
              <span>
                {t('pageWizard.salesPage.model.sidebar.confirmButton')}
              </span>
              <i className="fal fa-arrow-right" />
            </UseTemplateButton>
          </SidebarFooter>
        }
      >
        <Container isFirefox={isFirefox} ref={previewContainerRef}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: previewStyles }} />
          <SectionContent
            previewContainerWidth={previewContainerWidth}
            previewContainerHeight={previewContainerHeight}
            dangerouslySetInnerHTML={{
              __html: previewHtml,
            }}
          />
        </Container>
      </Sidebar>
    </Block>
  );
};

export const SelectTemplate = memo(SelectTemplateComponent);
