import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';

export const getFolderMedia = createAsyncThunk<
  Media[],
  string,
  {
    state: LocalState;
  }
>('mediaCenter/getFolderMedia', async (folderId) => {
  try {
    const response = await MediaCenter.getFolderMedia(folderId);
    return response;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const getFolderMediaBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(getFolderMedia.pending, (state: MediaCenterState) => {
    state.loading = true;
  });
  builder.addCase(
    getFolderMedia.fulfilled,
    (state: MediaCenterState, action) => {
      state.media = action.payload;
      state.loading = false;
    }
  );
  builder.addCase(getFolderMedia.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
