import { css } from 'styled-components';

export const innerPopoverStyle = css`
  :host {
    max-width: 16rem;
    box-shadow: 0px 2px 16px -6px rgb(0 0 0 / 25%);
    padding: var(--spacer-4);
  }

  :host(.hot-popover--bottom.show) .arrow::before {
    filter: drop-shadow(1px -2px 1px rgba(0, 0, 0, 0.05));
  }
`;
