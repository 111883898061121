import { TextInput } from '@components/TextInput';
import styled from 'styled-components';

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
