import styled from 'styled-components';

export const ProductNotFound = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--white);
  z-index: 12;
  font-size: 20px;
  gap: 8px;
`;
