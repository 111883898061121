import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';

import { HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Preview, Image, StyledUploadMedia, Title } from './styles';

export type UploadMediaWithPreviewProps = HTMLAttributes<HTMLDivElement> & {
  title?: string;
  previewSrc?: string;
  previewHeight?: string;
  hideDescription?: boolean;
  onUpload?: (media: UploadMediaFile) => void;
};

const UploadMediaWithPreviewComponent: React.FC<UploadMediaWithPreviewProps> =
  ({
    title,
    previewSrc,
    previewHeight,
    hideDescription = true,
    onUpload,
    ...attrs
  }) => {
    const { t } = useTranslation();

    return (
      <Container {...attrs}>
        {title && <Title>{t(title)}</Title>}

        <Preview previewHeight={previewHeight}>
          {previewSrc ? (
            <Image src={previewSrc} alt={t('mediaCenter.imagePreview')} />
          ) : (
            <div>
              <i className="fal fa-image" />
            </div>
          )}
        </Preview>

        <StyledUploadMedia
          isSingleUpload
          onSingleUpload={onUpload}
          hideDescription={hideDescription}
          showLoader
        />
      </Container>
    );
  };

export const UploadMediaWithPreview = memo(UploadMediaWithPreviewComponent);
