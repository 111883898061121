import { HTMLAttributes, memo, useCallback } from 'react';
import { AlertModal } from '@components/AlertModal';
import { useUserInfo } from '@hooks/index';

type UpgradeModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  feature?: string;
  onClose?: () => void;
  secondaryButtonLabel?: string;
  onPrimaryButtonClicked?: () => void;
  onSecondaryButtonClick?: () => void;
};

const UpgradeModalComponent: React.FC<UpgradeModalProps> = ({
  isOpen,
  onClose,
  feature = 'generic',
  secondaryButtonLabel,
  onSecondaryButtonClick,
  onPrimaryButtonClicked,
  ...attrs
}) => {
  const { isExtensions } = useUserInfo();

  const handlePageAccessClick = useCallback(() => {
    onPrimaryButtonClicked?.();
    window.open(
      `${
        isExtensions
          ? 'https://extensions.hotmart.com/salespage'
          : 'https://klickpages.com.br/monteseuplano'
      }`,
      '_blank'
    );
  }, [onPrimaryButtonClicked, isExtensions]);

  return (
    <AlertModal
      {...attrs}
      isOpen={isOpen}
      imageSrc="/images/planlimit.svg"
      onClose={onClose}
      title="modals.upgrade.generic.title"
      description={`modals.upgrade.${feature}.description`}
      onPrimaryButtonClick={handlePageAccessClick}
      primaryButtonLabel="modals.upgrade.generic.secondButton"
      secondaryButtonLabel={secondaryButtonLabel}
      onSecondaryButtonClick={onSecondaryButtonClick}
    />
  );
};

export const UpgradeModal = memo(UpgradeModalComponent);
