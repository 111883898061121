import styled, { css } from 'styled-components';

export const Templates = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  max-width: 660px;
`;

type StyledTemplateContainerProps = {
  hideThumb?: boolean;
};

type HasFireFoxChecker = {
  isFirefox?: boolean;
};

type SectionContentProps = {
  previewContainerWidth?: number;
  previewContainerHeight?: number;
};

export const TemplateContainer = styled.div<StyledTemplateContainerProps>`
  display: inline-flex;
  gap: 10px;

  ${({ hideThumb }) => css`
    ${!hideThumb && 'width: 308px'};
  `}

  label {
    margin-bottom: 0;
  }
`;

export const Radio = styled.div`
  display: inline-flex;
  margin: 0;
`;

export const RadioLabel = styled.label`
  height: 24px;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(49, 49, 49, 0.8);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
`;

export const ThumbnailContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid var(--gray-300);
  position: relative;
  background: var(--white);
  width: 276px;
  height: 272px;

  &:hover ${Overlay} {
    opacity: 1;
    visibility: visible;
  }
`;

export const SidebarTitle = styled.span`
  color: var(--gray-700);
  font-size: 20px;
  font-weight: 300;
  line-height: 120%;
`;

export const SidebarFooter = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const UseTemplateButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TemplateImg = styled.img`
  width: 100%;
`;

export const Container = styled.div<HasFireFoxChecker>`
  border-radius: 12px;
  background: var(--white);
  outline: 1px solid var(--gray-300);
  width: 100%;
`;

export const SectionContent = styled.div<SectionContentProps>`
  zoom: 60%;
  -moz-transform: scale(0.6);
  -moz-transform-origin: left top;
  z-index: 10;
  pointer-events: none;
  position: relative;

  ${({ previewContainerWidth, previewContainerHeight }) => css`
    width: ${previewContainerWidth
      ? `${previewContainerWidth * 1.6 - 30}px`
      : 'unset'};
    height: ${previewContainerHeight
      ? `${previewContainerHeight - 32}px`
      : 'unset'};
  `}
`;

export const ThumbContent = styled.div<HasFireFoxChecker>`
  border-radius: 8px;
  zoom: 18%;
  -moz-transform: scale(0.18);
  -moz-transform-origin: left top;

  ${({ isFirefox }) => css`
    width: ${isFirefox ? '1520px' : 'unset'};
  `}
`;
