import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Image: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="32"
    height="25"
    viewBox="0 0 32 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M29 0.0600586H3C1.3125 0.0600586 0 1.43506 0 3.06006V21.0601C0 22.7476 1.3125 24.0601 3 24.0601H29C30.625 24.0601 32 22.7476 32 21.0601V3.06006C32 1.43506 30.625 0.0600586 29 0.0600586ZM30 21.0601C30 21.6226 29.5 22.0601 29 22.0601H3C2.4375 22.0601 2 21.6226 2 21.0601V3.06006C2 2.56006 2.4375 2.06006 3 2.06006H29C29.5 2.06006 30 2.56006 30 3.06006V21.0601ZM7 10.5601C8.875 10.5601 10.5 8.99756 10.5 7.06006C10.5 5.18506 8.875 3.56006 7 3.56006C5.0625 3.56006 3.5 5.18506 3.5 7.06006C3.5 8.99756 5.0625 10.5601 7 10.5601ZM7 5.56006C7.8125 5.56006 8.5 6.24756 8.5 7.06006C8.5 7.93506 7.8125 8.56006 7 8.56006C6.125 8.56006 5.5 7.93506 5.5 7.06006C5.5 6.24756 6.125 5.56006 7 5.56006ZM19.9375 7.06006L14 12.9976L12 11.0601C11.4375 10.4351 10.5 10.4351 9.9375 11.0601L4.4375 16.5601C4.1875 16.7476 4 17.2476 4 17.5601V19.3101C4 19.7476 4.3125 20.0601 4.75 20.0601H27.25C27.625 20.0601 28 19.7476 28 19.3101V13.5601C28 13.1851 27.8125 12.8101 27.5 12.5601L22 7.06006C21.4375 6.43506 20.5 6.43506 19.9375 7.06006ZM26 18.0601H6V17.8101L11 12.8101L14 15.8101L21 8.81006L26 13.8101V18.0601Z"
      fill={color}
    />
  </StyledSVG>
);
