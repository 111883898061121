import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSavedSections } from '@hooks/useSavedSections';
import { BaseModal } from '@components/BaseModal';
import Toast from '@services/toast';
import { Description, ModalTitle, CancelButton } from './styles';

type RemoveSectionModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  sectionId: string;
  onClose: () => void;
};

const RemoveSectionModalComponent: React.FC<RemoveSectionModalProps> = ({
  isOpen,
  sectionId,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { deleteUserSection, loading } = useSavedSections();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDeleteMedia = useCallback(async () => {
    try {
      await deleteUserSection(sectionId);
      Toast.success(t('toastMessages.saveSectionModal.removed.success'));
    } catch {
      Toast.error(t('toastMessages.saveSectionModal.removed.error'));
    } finally {
      handleCloseModal();
    }
  }, [deleteUserSection, handleCloseModal, sectionId, t]);

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      header={<ModalTitle>{t(`modals.removeSavedSections.title`)}</ModalTitle>}
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t(`modals.removeSavedSections.cancelButton`)}
          </CancelButton>
          <button
            onClick={handleDeleteMedia}
            className={classNames('hot-button hot-button--danger', {
              'hot-button--loading': loading,
            })}
          >
            {t(`modals.removeSavedSections.removeButton`)}
          </button>
        </>
      }
    >
      <Description>{t(`modals.removeSavedSections.description`)}</Description>{' '}
    </BaseModal>
  );
};

export const RemoveSectionModal = memo(RemoveSectionModalComponent);
