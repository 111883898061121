import '@hotmart-org-ca/cosmos/dist/alert';
import '@hotmart-org-ca/cosmos/dist/toast';
import '@hotmart-org-ca/cosmos/dist/alert/variations/success.css';
import '@hotmart-org-ca/cosmos/dist/alert/variations/danger.css';

import { GlobalToastStyle } from './styles';

export const Toast: React.FC = () => (
  <>
    <GlobalToastStyle />
    <hot-toast />
  </>
);
