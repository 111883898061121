import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Icon: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M16 0.560059C7.4375 0.560059 0.5 7.49756 0.5 16.0601C0.5 24.6226 7.4375 31.5601 16 31.5601C24.5625 31.5601 31.5 24.6226 31.5 16.0601C31.5 7.49756 24.5625 0.560059 16 0.560059ZM16 29.5601C8.5 29.5601 2.5 23.5601 2.5 16.0601C2.5 8.62256 8.5 2.56006 16 2.56006C23.4375 2.56006 29.5 8.62256 29.5 16.0601C29.5 23.5601 23.4375 29.5601 16 29.5601ZM21.625 20.4351C20.1875 22.1226 18.125 23.0601 16 23.0601C13.8125 23.0601 11.75 22.1226 10.3125 20.4351C10 19.9976 9.375 19.9976 8.9375 20.3101C8.5 20.6851 8.4375 21.3101 8.8125 21.7476C10.5625 23.8726 13.1875 25.0601 16 25.0601C18.75 25.0601 21.375 23.8726 23.125 21.7476C23.5 21.3101 23.4375 20.6851 23 20.3101C22.625 19.9976 21.9375 19.9976 21.625 20.4351ZM11 15.0601C12.0625 15.0601 13 14.1851 13 13.0601C13 11.9976 12.0625 11.0601 11 11.0601C9.875 11.0601 9 11.9976 9 13.0601C9 14.1851 9.875 15.0601 11 15.0601ZM21 15.0601C22.0625 15.0601 23 14.1851 23 13.0601C23 11.9976 22.0625 11.0601 21 11.0601C19.875 11.0601 19 11.9976 19 13.0601C19 14.1851 19.875 15.0601 21 15.0601Z"
      fill={color}
    />
  </StyledSVG>
);
