import React, {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../../Select';
import { Label } from '../../../../../Label';
import { Container, GroupWrapper, EndDateWrapper } from './styles';
import '@hotmart-org-ca/cosmos/dist/form/input-validate.css';
import { timezoneOptions } from './timezones';

export type CounterDateProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  type?: 'endDate' | 'timeOnPage';
  timezone?: number;
  endDate?: string;
  timeOnPage?: TimeOnPageValueProps;
  onChange?: (props: Record<string, any>) => void;
};

const CounterDateComponent: React.FC<CounterDateProps> = ({
  type = 'timeOnPage',
  timezone = '-03:00',
  endDate = '',
  timeOnPage = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString()
  );
  const [selectedTime, setSelectedTime] = useState(
    new Date().toLocaleTimeString()
  );

  const selectCounterType = useMemo(
    () => [
      {
        id: 'timeOnPage',
        label: t('timerMenu.counterType.timeOnPage'),
        value: 'timeOnPage',
      },
      {
        id: 'endDate',
        label: t('timerMenu.counterType.endDate'),
        value: 'endDate',
      },
    ],
    [t]
  );

  const timeOnPageOptions = useMemo<
    { id: keyof TimeOnPageValueProps; label: string }[]
  >(
    () => [
      {
        id: 'days',
        label: t('timerMenu.counterType.days'),
      },
      {
        id: 'hours',
        label: t('timerMenu.counterType.hours'),
      },
      {
        id: 'minutes',
        label: t('timerMenu.counterType.minutes'),
      },
    ],
    [t]
  );

  const handlePropsChange = useCallback(
    (props: Record<string, any>) => {
      onChange?.(props);
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const updatedTimeOnPage = { ...timeOnPage, [id]: +event.target.value };
      handlePropsChange({ 'time-on-page': JSON.stringify(updatedTimeOnPage) });
    },
    [timeOnPage, handlePropsChange]
  );

  const datePickerChangeListener = useCallback(
    (e: Event) => {
      const { dateTime } = (e as CustomEvent).detail;
      const date = new Date(dateTime);
      const [convertedDate] = date.toISOString().split('T');

      if (convertedDate && selectedTime) {
        const updatedEndDate = `${convertedDate}T${selectedTime}`;
        handlePropsChange({ 'end-date': updatedEndDate });
      }

      const [year, month, day] = convertedDate.split('-');
      setSelectedDate(`${day}/${month}/${year}`);
    },
    [handlePropsChange, selectedTime]
  );

  const timePickerChangeListener = useCallback(
    (e: Event) => {
      const { value } = (e as CustomEvent).detail;
      const time = new Date(value);
      const localeTime = time.toLocaleTimeString('pt-BR');

      if (selectedDate && localeTime) {
        const [day, month, year] = selectedDate.split('/');
        const updatedEndDate = `${year}-${month}-${day}T${localeTime}`;

        if (updatedEndDate !== endDate) {
          handlePropsChange({ 'end-date': updatedEndDate });
        }
      }
      setSelectedTime?.(localeTime);
    },
    [endDate, handlePropsChange, selectedDate]
  );

  useEffect(() => {
    const hotDatePicker = document.querySelector('hot-date-picker');
    const hotTimePicker = document.querySelector('hot-time-picker');

    const datePickerConcrete = document.querySelector<HTMLElement>(
      'hot-date-picker-concrete'
    );

    if (datePickerConcrete) {
      datePickerConcrete.dataset.preventDelete = 'true';
    }

    hotDatePicker?.addEventListener('change', datePickerChangeListener);
    hotTimePicker?.addEventListener('change', timePickerChangeListener);

    return () => {
      hotDatePicker?.removeEventListener('change', datePickerChangeListener);
      hotTimePicker?.removeEventListener('change', timePickerChangeListener);
    };
  }, [datePickerChangeListener, timePickerChangeListener]);

  useEffect(() => {
    if (endDate) {
      const [date, time] = endDate.split('T');
      const [year, month, day] = date.split('-');

      setSelectedDate(`${day}/${month}/${year}`);
      setSelectedTime(time);
    }
  }, [endDate]);

  return (
    <Container {...attrs}>
      <Select
        id="timer-options"
        label={t('timerMenu.counterType.title')}
        infoTooltip={{
          title: 'labelTooltip.timer.counterType.title',
          description: 'labelTooltip.timer.counterType.description',
        }}
        placeholder={t('form.placeholder')}
        selectedOption={type}
        options={selectCounterType}
        onChange={(typeValue) => handlePropsChange({ type: typeValue })}
      />

      <div style={{ display: type === 'endDate' ? 'block' : 'none' }}>
        <Label label={t('timerMenu.counterType.endsOnDate')} />

        <hot-date-picker date={selectedDate} />

        <EndDateWrapper>
          <Label label={t('timerMenu.counterType.endsOnTime')} />
          <hot-time-picker data-prevent-delete time={selectedTime} time24hr />
        </EndDateWrapper>

        <EndDateWrapper>
          <Select
            id="timezone"
            label={t('timerMenu.counterType.timezone')}
            placeholder={t('form.placeholder')}
            selectedOption={timezone.toString()}
            options={timezoneOptions}
            onChange={(timezoneValue) =>
              handlePropsChange({ timezone: timezoneValue })
            }
          />
        </EndDateWrapper>
      </div>

      <GroupWrapper
        style={{ display: type === 'timeOnPage' ? 'flex' : 'none' }}
      >
        {timeOnPageOptions.map((option) => (
          <div key={option.id}>
            <Label label={option.label} />

            <hot-input-group>
              <input
                data-prevent-delete
                type="number"
                className="hot-form__input hot-form__input--sm"
                id={option.id}
                name={option.id}
                onChange={(e) => handleInputChange(e, option.id)}
                value={timeOnPage[option.id]}
                min="0"
              />
            </hot-input-group>
          </div>
        ))}
      </GroupWrapper>
    </Container>
  );
};

export const CounterDate = memo(CounterDateComponent);
