import TagManager, { TagManagerArgs } from 'react-gtm-module';

const gtmConfigs: TagManagerArgs[] = [
  {
    gtmId: 'GTM-M7Z594X',
  },
  {
    gtmId: 'GTM-NZB9QBR',
  },
];

export const initGTM = () => {
  if (process.env.REACT_APP_ENV !== 'development') {
    gtmConfigs.forEach((gtmConfig) => {
      TagManager.initialize(gtmConfig);
    });
  }
};
