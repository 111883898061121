import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .hot-form__label::before,
  .hot-form__label::after {
    top: 0;
    left: 0;
  }
`;

export const CheckboxLabel = styled.label`
  width: 16px;
  height: 16px;
`;
