import React, { HTMLAttributes, memo } from 'react';
import { Links } from '../Links';
import { StepContainer } from './styles';

export type LinkTreeSecondStepProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    links: LinkTreeLinksConfig[];
  };

const SecondStepComponent: React.FC<LinkTreeSecondStepProps> = ({
  onModifierChange,
  links,
  ...attrs
}) => (
  <StepContainer {...attrs}>
    <Links
      linkList={links}
      onLinksValueChange={(value) => onModifierChange({ prop: 'links', value })}
    />
  </StepContainer>
);

export const SecondStep = memo(SecondStepComponent);
