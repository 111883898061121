import styled from 'styled-components';

export const StepContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: 12px;
`;
