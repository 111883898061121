import { LsEditorDraggable } from '@hotmart-org-ca/saas-pages-engine-react';
import styled, { css } from 'styled-components';

export const FragmentList = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

type StyledFragmentWrapperProps = {
  isSelectable?: boolean;
};

export const FragmentWrapper = styled.div<StyledFragmentWrapperProps>`
  position: relative;

  ${({ isSelectable }) => css`
    width: ${isSelectable ? 'unset' : '100%'};
  `}
`;

type StyledFragmentProps = {
  hideBorder?: boolean;
  isPopup?: boolean;
  isSelected?: boolean;
  isSelectable?: boolean;
  isFirefox?: boolean;
};

const getSelectableBorder = (hideBorder?: boolean, isSelected?: boolean) => {
  if (isSelected) {
    return '4px solid var(--blue-light)';
  }

  if (hideBorder) {
    return 'none';
  }

  return '1px solid var(--gray-300)';
};

export const Fragment = styled.div<StyledFragmentProps>`
  position: relative;

  ${({ isSelectable, isSelected, isPopup, hideBorder, isFirefox }) => css`
    ${isSelectable
      ? css`
          cursor: pointer;
          overflow: hidden;
          height: 160px;
          width: 260px;
          align-items: center;
          justify-content: center;
          border: ${getSelectableBorder(hideBorder, isSelected)};

          ${isPopup &&
          css`
            background-color: var(--gray-200);
          `}

          ${!isFirefox &&
          css`
            display: flex;
          `}

          :hover {
            border: ${isSelected
              ? '4px solid var(--blue-light)'
              : '1px solid var(--blue)'};
          }

          :after {
            content: '';
            pointer-events: none;
            position: absolute;
            transition: all 0.2s ease;
            z-index: 12;
            inset: ${hideBorder ? '0' : '-1px'};
            width: ${hideBorder ? '100%' : 'calc(100% + 2px)'};
            background-color: ${isSelected
              ? 'rgba(190, 209, 255, 0.5)'
              : 'rgba(0, 0, 0, 0)'};
          }

          :hover::after {
            background-color: ${isSelected
              ? 'rgba(190, 209, 255, 0.7)'
              : 'rgba(190, 209, 255, 0.5)'};
          }
        `
      : css`
          width: ${isPopup ? '50%' : '100%'};
          margin: ${isPopup ? 'auto' : 'unset'};
          border: ${hideBorder ? 'none' : '1px solid var(--gray-300)'};
        `}
  `}
`;

type StyledFragmentContentProps = {
  isFirefox?: boolean;
  isPopup?: boolean;
  isSelectable?: boolean;
};

const getFragmentContentFirefoxWidth = (
  isPopup?: boolean,
  isSelectable?: boolean
) => {
  if (isPopup) {
    return '622px';
  }

  return isSelectable ? '1320px' : '1252px';
};

const getFragmentContentWidth = (isPopup?: boolean, isSelectable?: boolean) =>
  isPopup && isSelectable ? '50%' : '100%';

export const FragmentContent = styled.div<StyledFragmentContentProps>`
  zoom: 20%;
  -moz-transform: scale(0.2);
  -moz-transform-origin: left top;
  z-index: 10;
  pointer-events: none;
  position: relative;

  ${({ isFirefox, isPopup, isSelectable }) => css`
    width: ${isFirefox
      ? getFragmentContentFirefoxWidth(isPopup, isSelectable)
      : getFragmentContentWidth(isPopup, isSelectable)};
    margin: ${isFirefox && isPopup ? '0 70px' : 'unset'};
  `}
`;

export const StyledLsEditorDraggable = styled(LsEditorDraggable)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

export const DraggableContainer = styled.div`
  background: var(--white);
  width: 100%;
  height: 100%;
`;

export const FragmentToolbar = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-300);
  justify-content: space-between;
  padding: 8px 16px;
  gap: 12px;
`;

export const Label = styled.div`
  color: var(--gray-700);
  font-size: 12px;
  max-width: 200px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LabelIcon = styled.div`
  hot-tooltip {
    display: flex;
  }
`;

export const Description = styled.div`
  display: flex;
  color: var(--gray-600);
  font-size: 12px;
  margin-bottom: 24px;
`;
