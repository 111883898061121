import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ComponentState,
  EditorEngineManager,
} from '@hotmart-org-ca/saas-pages-engine';
import { Submenu } from '../../../Submenu';
import { Divider } from './styles';
import '@hotmart-org-ca/cosmos/dist/input-group/input-group.css';
import '@hotmart-org-ca/cosmos/dist/date-picker';
import '@hotmart-org-ca/cosmos/dist/time-picker';

import { CounterDate } from './components/CounterDate';
import { Actions } from './components/Actions';
import { ItemsVisibility } from './components/ItemsVisibility';

export const TimerSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const slices = useSelector((state: State) => state);

  const activeComponent = useSelector(
    (state: State) => state.lsEditor.activeComponent
  );

  const [timerProps, setTimerProps] = useState<Record<string, any>>({});

  useEffect(() => {
    if (activeComponent) {
      const { component, uid } = activeComponent;
      const slice = slices[uid] as ComponentState;

      if (component === 'ls-timer' && slice) {
        setTimerProps(slice.props);
      }
    }
  }, [activeComponent, slices]);

  const handleUpdateElement = useCallback(
    (props: Record<string, unknown>) => {
      if (activeComponent) {
        const { uid } = activeComponent;
        EditorEngineManager.updateElement(uid, {
          props,
        });
      }
    },
    [activeComponent]
  );

  return (
    <Submenu title="timerMenu.title" padding="0px" {...attrs}>
      <CounterDate
        type={timerProps.type}
        endDate={timerProps['end-date']}
        timezone={timerProps.timezone}
        timeOnPage={
          timerProps['time-on-page']
            ? JSON.parse(timerProps['time-on-page'])
            : undefined
        }
        onChange={(props) => handleUpdateElement(props)}
      />

      <Divider />

      <Actions
        hasAction={timerProps['has-action']}
        actionType={timerProps['action-type']}
        redirectUrl={timerProps['redirect-url']}
        message={timerProps.message}
        onChange={(props) => handleUpdateElement(props)}
      />

      <Divider />

      <ItemsVisibility
        itemsVisibility={
          timerProps['items-visibility']
            ? JSON.parse(timerProps['items-visibility'])
            : undefined
        }
        onChange={(props) => handleUpdateElement(props)}
      />
    </Submenu>
  );
};
