import { v4 as uuid } from 'uuid';

export const aboutSections: PageFragmentListItem[] = [
  {
    id: 'about-8',
    name: 'About',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5372386/bg-obrigado_assets_template-nath-arcuri.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {
        background: '#D1BEDD',
      },
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '88px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '80px 0px 0px 0px',
          },
          mobile: {
            'flex-direction': 'column',
            padding: '48px 0px 0px 0px',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '550px',
                  href: '',
                  'max-width': '550px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '450px',
                    'max-width': '450px',
                  },
                  mobile: {
                    'max-width': '300px',
                    height: '300px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5372775/foto_obrigado-nath-arcuri.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5372775/thumb_foto_obrigado-nath-arcuri.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 80px 0px',
              },
              mobile: {
                padding: '0px 0px 56px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h3><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Olá! Eu sou o/a [NOME]</strong></h3>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<h3 style="text-align: center; line-height: 1.15;"><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(209, 190, 221); background-color: rgb(97, 91, 155);"> Olá! Eu sou o/a [NOME] </strong></h3>',
                    padding: '0px 10px 10px 10px',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-size: 32px; font-family: Montserrat, sans-serif; color: rgb(209, 190, 221); background-color: rgb(97, 91, 155);"> Olá! Eu sou o/a [NOME] </strong></p>',
                    padding: '0px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DA ISCA], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DA ISCA], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '85%',
                    padding: '40px 10px 10px 10px',
                  },
                  mobile: {
                    padding: '32px 10px 10px 10px',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'About',
        templateId: 'about-1',
        templateName: 'About',
      },
    },
  },
  {
    id: 'about-9',
    name: 'About',
    template: {
      background: '#F3F5FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 5',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 88px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 30px 70px',
              },
              mobile: {
                padding: '40px 0px 10px 24px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 55px;">QUEM SERÁ SEU GUIA?</strong></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 40px;">QUEM SERÁ SEU GUIA?</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><strong style="color: rgb(59, 68, 183); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 36px;">Olá! Eu sou o/a [NOME].</strong></p>',
                  'max-width': '90%',
                  padding: '20px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p><strong style="color: rgb(59, 68, 183); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 36px;">Olá! Eu sou o/a [NOME].</strong></p>',
                  },
                  mobile: {
                    text: '<p><strong style="color: rgb(59, 68, 183); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 20px;">Olá! Eu sou o/a [NOME].</strong></p>',
                    padding: '15px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 18px;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 18px;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 18px;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  'max-width': '83%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 15px;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 15px;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 15px;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                    padding: '14px 10px 10px 10px',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '100px 0px 72px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '30px 0px 60px 0px',
                'align-items': 'center',
                'justify-content': 'end',
              },
              mobile: {
                padding: '10px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '100px 10px 0px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'end',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'radial-gradient(#2d3388, #1c1e51)',
                  'max-width': '450px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '400px',
                    padding: '30px 10px 0px 10px',
                  },
                  mobile: {
                    'max-width': '300px',
                    padding: '10px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '430px',
                      href: '',
                      'max-width': '500px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {
                        height: '387px',
                      },
                      mobile: {
                        height: '285px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5394829/img-bio_assets_template-albertoni.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5394829/thumb_img-bio_assets_template-albertoni.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'about-10',
    name: 'About',
    template: {
      background: 'linear-gradient(#393939, #232121)',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Autor',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 0px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '170px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {
                        margin: '0px 0px 0px 0px',
                      },
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5357385/dots2-vendas_assets_template-erico-rocha.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5357385/thumb_dots2-vendas_assets_template-erico-rocha.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '550px',
                  href: '',
                  'max-width': '500px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '450px',
                    'max-width': '450px',
                    margin: '0px 0px 0px 0px',
                    'border-style': 'solid',
                    'border-color': '#FFFFFF',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    'border-radius': '20px 20px 20px 20px',
                  },
                  mobile: {
                    'max-width': '280px',
                    height: '280px',
                    margin: '20px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5347133/img3-captura_assets_template-erico-rocha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5347133/thumb_img3-captura_assets_template-erico-rocha.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '45px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '45px 80px 50px 80px',
              },
              mobile: {
                padding: '45px 20px 10px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p><strong style="color: rgb(125, 206, 98); font-family: Montserrat, sans-serif; font-size: 60px;">Olá!</strong><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> Eu sou o(a) [NOME].</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p><strong style="color: rgb(125, 206, 98); font-family: Montserrat, sans-serif; font-size: 40px;">Olá!</strong><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 40px;"> </strong><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 28px;">Eu sou o(a) [NOME].</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  'max-width': '75%',
                  padding: '30px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    padding: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  padding: '10px 0px 10px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'flex-direction': 'column',
                    'align-items': 'end',
                    'justify-content': 'center',
                    padding: '10px 20px 10px 0px',
                    margin: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      color: '#FFFFFF',
                      background: '#FF5F33',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'QUERO COMPRAR AGORA!',
                      margin: '15px 20px 20px 0px',
                      'max-width': '400px',
                      mobile: {
                        'font-size': '16px',
                        'max-width': '300px',
                        padding: '13px 16px 13px 16px',
                        margin: '15px 0px 20px 0px',
                      },
                      padding: '16px 16px 16px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '120px',
                      href: '',
                      'max-width': '150px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '90px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5357385/dots2-vendas_assets_template-erico-rocha.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5357385/thumb_dots2-vendas_assets_template-erico-rocha.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'about-11',
    name: 'About',
    template: {
      background: '#F3F5FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 4',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 50px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '30px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 41px;">Olá! Eu sou o/a [NOME]</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 41px;">Olá! Eu sou o/a [NOME]</strong></p>',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 30px;">Olá! Eu sou o/a [NOME]</strong></p>',
                    'max-width': '70%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  'max-width': '95%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 13px;">Bom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p style="text-align: center; line-height: 1.15;"><br></p><p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 13px;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p style="text-align: center; line-height: 1.15;"><br></p><p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 13px;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                    'max-width': '85%',
                    padding: '15px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  padding: '10px 10px 20px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '560px',
                  margin: '30px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '40px 10px 10px 10px',
                    padding: '10px 10px 20px 48px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '10px 10px 20px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '45px',
                      href: '',
                      'max-width': '55px',
                      margin: '20px 0px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '36px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5400761/icone-14.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5400761/thumb_icone-14.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 16px;">[</span><span style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 16px;">Depoimento curto em texto feito por usuários reais]</span></p><p><br></p><p><span style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 16px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      'max-width': '90%',
                      padding: '10px 0px 10px 15px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 14px;">[</span><span style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 14px;">Depoimento curto em texto feito por usuários reais]</span></p><p><br></p><p><span style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                        'max-width': '92%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  color: '#141436',
                  background: '#61F5B0',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO RECEBER MEU ACESSO',
                  margin: '40px 0px 0px 10px',
                  'max-width': '400px',
                  mobile: {
                    margin: '30px 0px 0px 0px',
                    'max-width': '320px',
                    'font-size': '17px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 80px 0px',
              },
              mobile: {
                padding: '0px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '180px 0px 0px 0px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'end',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'radial-gradient(#2d3388, #1c1e51)',
                  'max-width': '450px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    padding: '92px 0px 0px 0px',
                    'max-width': '400px',
                  },
                  mobile: {
                    'max-width': '242px',
                    padding: '37px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 20px 20px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '447px',
                      href: '',
                      'max-width': '500px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {
                        height: '410px',
                      },
                      mobile: {
                        height: '235px',
                        'max-width': '227px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5394829/img-bio_assets_template-albertoni.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5394829/thumb_img-bio_assets_template-albertoni.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'about-12',
    name: 'About',
    template: {
      background: '#ECECFB',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 6',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '100px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'end',
                padding: '64px 0px 0px 0px',
              },
              mobile: {
                padding: '40px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '595px',
                  href: '',
                  'max-width': '550px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '400px',
                    'max-width': '450px',
                    src: '//static-public.klickpages.com.br/uploads/media/file/5486946/img3_vendas-assets_template-gi-isquierdo.png',
                    'thumb-src':
                      '//static-public.klickpages.com.br/uploads/media/file/5486946/thumb_img3_vendas-assets_template-gi-isquierdo.png',
                  },
                  mobile: {
                    'max-width': '250px',
                    height: '300px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5465994/img2_assets_template-gi-isquierdo.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5465994/thumb_img2_assets_template-gi-isquierdo.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '64px 0px 64px 30px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '40px 64px 88px 64px',
              },
              mobile: {
                padding: '20px 40px 56px 40px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1;"><strong style="background-color: transparent; color: rgb(199, 198, 243); font-family: Oswald, sans-serif; font-size: 160px;">Oi!</strong></p>',
                  'max-width': '60%',
                  padding: '10px 0px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="line-height: 1;"><strong style="background-color: transparent; color: rgb(199, 198, 243); font-family: Oswald, sans-serif; font-size: 100px;">Oi!</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><strong style="background-color: transparent; color: rgb(56, 54, 145); font-family: Oswald, sans-serif; font-size: 45px;">Eu sou o/a </strong><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 45px;">[NOME DO AUTOR]</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p><strong style="background-color: transparent; color: rgb(56, 54, 145); font-family: Oswald, sans-serif; font-size: 40px;">Eu sou o/a </strong><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 40px;">[NOME DO AUTOR]</strong></p>',
                    padding: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">Bem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  'max-width': '90%',
                  padding: '40px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '100%',
                  },
                  mobile: {
                    padding: '24px 0px 10px 0px',
                    text: '<p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">Bem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">Sou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">Então, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'about-1',
    name: 'About',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '48px 0px 48px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '400px',
                  href: '',
                  'max-width': '400px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5239076/pergil.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5239076/thumb_pergil.png',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch3\u003e\u003cstrong style="font-size: 40px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eOlá! Eu sou o [NOME]\u003c/strong\u003e\u003c/h3\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eBom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DA ISCA], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DA ISCA], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 4' },
    },
  },
  {
    id: 'about-2',
    name: 'About',
    template: {
      background: '#79DBE0',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 20px 90px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '50px 20px 30px 0px',
              },
              mobile: {
                padding: '30px 20px 30px 20px',
                'align-items': 'start',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 40px; font-family: Raleway, sans-serif;"\u003eOlá! Eu sou o [NOME]\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '75%' },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 15px;"\u003eBom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 15px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 15px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '14px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '75%' },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '90px 0px 90px 50px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 0px 50px 50px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 30px 50px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#FFFFFF',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '3px',
                  'border-right-width': '3px',
                  'border-bottom-width': '3px',
                  'border-left-width': '3px',
                  height: '369px',
                  href: '',
                  'max-width': '370px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '100%',
                    'max-width': '100%',
                    margin: '0px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284759/foto_quem-sou-eu.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284759/thumb_foto_quem-sou-eu.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 7' },
    },
  },
  {
    id: 'about-3',
    name: 'About',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5278636/bg-captura_assets_template-empreend-ladeira.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 1' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '40px 30px 30px 30px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '30px 30px 20px 30px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 80px;"\u003eOlá!\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 40px;"\u003eEu sou o(a) [NOME].\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 30px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 60px;"\u003eOlá!\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 40px;"\u003eEu sou o(a) [NOME].\u003c/strong\u003e\u003c/p\u003e',
                  },
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 40px;"\u003eOlá!\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 25px;"\u003eEu sou o(a) [NOME].\u003c/strong\u003e\u003c/p\u003e',
                    padding: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;"\u003eBom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eBom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                    padding: '10px 10px 30px 10px',
                  },
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eBom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                    padding: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#FF4A12',
                  'border-color': '#08014E',
                  'border-radius': '10px 2px 10px 2px',
                  'border-style': 'none',
                  'border-top-width': '4px',
                  'border-right-width': '4px',
                  'border-bottom-width': '4px',
                  'border-left-width': '4px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO APROVEITAR AGORA',
                  margin: '20px 0px 20px 10px',
                  'max-width': '350px',
                  mobile: { margin: '30px 20px 20px 20px' },
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: { padding: '0px 0px 50px 0px' },
              mobile: { padding: '0px 0px 50px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '550px',
                  href: '',
                  'max-width': '530px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '350px',
                    'object-fit': 'contain',
                    'max-width': '350px',
                  },
                  mobile: {
                    'object-fit': 'contain',
                    height: '300px',
                    'max-width': '500px',
                    margin: '0px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5281320/bio-captura_assets_template-empreend-ladeira.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5281320/thumb_bio-captura_assets_template-empreend-ladeira.png',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'about-4',
    name: 'About',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 20px 90px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 20px 20px 0px' },
              mobile: { padding: '40px 20px 40px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-size: 32px; font-family: Montserrat, sans-serif;"\u003eConheça seus mentores\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eBom, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eSou fundador(a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '90px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 40px 0px' },
              mobile: { padding: '0px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '400px',
                  href: '',
                  'max-width': '400px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { 'object-fit': 'contain', height: '375px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315374/micha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315374/thumb_micha.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 3' },
    },
  },
  {
    id: 'about-5',
    name: 'About',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Bio' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '100px 0px 100px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '60px 0px 0px 0px' },
              mobile: { background: '#FFFFFF' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '650px',
                  href: '',
                  'max-width': '550px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '400px', 'max-width': '350px' },
                  mobile: { height: '300px', 'max-width': '250px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5305290/imagem3-bio-captura_assets_template-orange-chic.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5305290/thumb_imagem3-bio-captura_assets_template-orange-chic.png',
                },
              ],
            },
            {
              padding: '60px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '60px 40px 40px 40px' },
              mobile: {
                padding: '30px 20px 60px 20px',
                'align-items': 'start',
                'justify-content': 'center',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 124, 53); font-family: Raleway, sans-serif; font-size: 60px;"\u003eOi!\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 40px;"\u003eEu sou o(a)\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 40px;"\u003e[NOME DO AUTOR]\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: { 'max-width': '100%' },
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 124, 53); font-family: Raleway, sans-serif; font-size: 50px;"\u003eOi!\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 30px;"\u003eEu sou o(a)\u003c/strong\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 30px;"\u003e[NOME DO AUTOR]\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 124, 53); font-size: 24px;"\u003e_____________\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: { padding: '2px 10px 10px 10px' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif;"\u003eBem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '100%' },
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eBem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#222222',
                  background: '#00F7F2',
                  'border-color': '#FFFFFF',
                  'border-radius': '10px 100px 100px 100px',
                  'border-style': 'none',
                  'border-top-width': '4px',
                  'border-right-width': '4px',
                  'border-bottom-width': '4px',
                  'border-left-width': '4px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '19px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO CONHECER MAIS',
                  margin: '30px 0px 30px 10px',
                  'max-width': '400px',
                  mobile: {
                    margin: '20px 10px 0px 10px',
                    'font-size': '17px',
                    padding: '13px 10px 13px 10px',
                    'max-width': '350px',
                  },
                  padding: '15px 16px 15px 15px',
                  tablet: {
                    'max-width': '670px',
                    padding: '20px 16px 20px 15px',
                  },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'about-6',
    name: 'About',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5303311/bg-captura_assets_template-orange-chic.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Autor' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '40px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '650px',
                  href: '',
                  'max-width': '500px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { 'max-width': '400px', height: '450px' },
                  mobile: { height: '350px', 'max-width': '250px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315870/imagem-bio-vendas_assets_template-orange-chic.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315870/thumb_imagem-bio-vendas_assets_template-orange-chic.png',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 65px;"\u003eOi!\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 36px;"\u003eEu sou o(a) [NOME DO AUTOR].\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '85%' },
                  mobile: {
                    text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 45px;"\u003eOi!\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 20px;"\u003eEu sou o(a) [NOME DO AUTOR].\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eBem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eBem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '40px 0px 50px 0px' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '30px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#222222',
                  background: '#00FFFA',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 100px 100px 100px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '19px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO CONHECER',
                  margin: '20px 0px 20px 10px',
                  'max-width': '460px',
                  mobile: { 'max-width': '80%', 'font-size': '18px' },
                  padding: '15px 15px 15px 15px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'about-7',
    name: 'About',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5335062/bg2-vendas_assets_template-navy-blue.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Autor' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '100px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '60px 0px 0px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '550px',
                  href: '',
                  'max-width': '800px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { 'max-width': '500px', height: '450px' },
                  mobile: { 'max-width': '300px', height: '250px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5325768/img-bio-captura_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5325768/thumb_img-bio-captura_assets_template-navy-blue.png',
                },
              ],
            },
            {
              padding: '80px 0px 80px 60px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '40px 60px 80px 60px' },
              mobile: { padding: '20px 40px 40px 40px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="color: rgb(192, 203, 245); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 150px;"\u003eOi!\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '50%',
                  padding: '10px 10px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="color: rgb(192, 203, 245); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 100px;"\u003eOi!\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '60%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 34px;"\u003eEu sou o(a) [NOME DO AUTOR].\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 34px;"\u003eEu sou o(a) [NOME DO AUTOR].\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-size: 17px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(14, 33, 74);"\u003eBem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 17px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(14, 33, 74);"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 17px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(14, 33, 74);"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '30px 60px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-size: 15px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(14, 33, 74);"\u003eBem, como alguns de vocês já sabem, tem bom tempo que eu trabalho com [ASSUNTO DO CURSO], e acredito que eu possa colaborar bastante durante a trajetória de vocês.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 15px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(14, 33, 74);"\u003eSou fundador (a) da empresa [NOME DA EMPRESA] e estou neste mercado há X anos. Já ajudei grandes marcas e players como [NOMES] a quebrarem barreiras e alavancarem seus negócios.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 15px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(14, 33, 74);"\u003eEntão, se você não apenas quer fazer parte do mundo do [ASSUNTO DO CURSO], mas também ter sucesso na sua jornada, basta clicar no botão abaixo para conhecer mais sobre a minha metodologia.\u003c/span\u003e\u003c/p\u003e',
                    padding: '20px 0px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#FF5959',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO SABER MAIS',
                  margin: '30px 0px 30px 10px',
                  'max-width': '350px',
                  mobile: {
                    'max-width': '90%',
                    padding: '15px 16px 15px 16px',
                    'font-size': '15px',
                    margin: '40px 0px 30px 10px',
                  },
                  padding: '18px 16px 18px 16px',
                  tablet: { 'max-width': '65%' },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
