import '@hotmart-org-ca/cosmos/dist/form/input-validate.css';
import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import {
  Switch,
  Description,
  ErrorMessage,
  Label,
  BlockIconWrapper,
  BlockedContentWrapper,
  BlockedContentTitle,
  BlockedContentDescription,
  Form,
} from './styles';
import { getLabelText, normalizePlaceholder, getId } from './formUtils';

export type HTMLFormConfigProps = HTMLAttributes<HTMLDivElement> & {
  value?: string;
  isCheckedOwnForm?: boolean;
  onFormChange?: (form: Template) => void;
  isBlocked: boolean;
};

const HTMLFormConfigComponent: React.FC<HTMLFormConfigProps> = ({
  value: propValue = '',
  isCheckedOwnForm: isChecked = false,
  onFormChange,
  isBlocked,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(propValue);
  const [isCheckedOwnForm, setIsCheckedOwnForm] = useState(isChecked);

  const [invalidForm, setInvalidForm] = useState(false);

  const buildField = useCallback(
    (
      input: HTMLInputElement,
      inputsFields: ExternalFormField[],
      form: HTMLFormElement,
      isCheckbox: boolean = false
    ) => {
      const name = input.getAttribute('name') || '';
      const id = input.getAttribute('id') || '';
      const inputValue = input.getAttribute('value') || '';
      const { required } = input;
      const labelElement = form.querySelector<HTMLLabelElement>(
        `label[for="${id}"]`
      );
      const label = getLabelText(labelElement);
      const normalizedPlaceholder = normalizePlaceholder(name);

      const idNormalized = getId(id);
      const idNormalizedName = getId(name);

      const props: ExternalFormField = {
        id: idNormalized || idNormalizedName,
        name,
        label,
        value: inputValue,
        required,
        defaultValue: input.value,
        type: input.type,
      };

      if (!isCheckbox) {
        props.placeholder =
          input.getAttribute('placeholder') || label || normalizedPlaceholder;
      }
      inputsFields.push(props);
    },
    []
  );

  const importCheckboxes = useCallback(
    (form: HTMLFormElement) => {
      const checkboxSelector =
        'input[name][type=checkbox],input[name][type=gdpr]';
      const checkbox =
        form.querySelectorAll<HTMLInputElement>(checkboxSelector);
      const checkboxFields: ExternalFormCheckbox[] = [];

      checkbox.forEach((input) => {
        buildField(input, checkboxFields, form, true);
      });

      return checkboxFields;
    },
    [buildField]
  );

  const importInputs = useCallback(
    (form: HTMLFormElement, isKsend: boolean) => {
      const inputSelector = isKsend
        ? 'input[name]:not([type=submit]):not([tabindex="-1"]):not([src*="aweber"]),textarea[name]'
        : 'input[name]:not([type=submit]):not([tabindex="-1"]):not([src*="aweber"]):not([type=checkbox]):not([type=gdpr]),textarea[name]';
      const inputs = form.querySelectorAll<HTMLInputElement>(inputSelector);
      const inputsFields: ExternalFormField[] = [];

      inputs.forEach((input) => {
        buildField(input, inputsFields, form);
      });

      return inputsFields;
    },
    [buildField]
  );

  const importAction = useCallback(
    (form: HTMLFormElement) => form.getAttribute('action') || '',
    []
  );

  const importFormId = useCallback(
    (form: HTMLFormElement) => form.getAttribute('id') || '',
    []
  );

  const importKlicksendGDPRText = (form: HTMLFormElement) => {
    const gdprDiv = form.querySelector('div[klicksend-gdpr-text]');

    if (!gdprDiv) return {};

    return {
      data_info: gdprDiv.querySelector('p')?.textContent,
      privacy_policy: gdprDiv.querySelector('a')?.href,
    };
  };

  const klicksendRecaptchaEnabled = useCallback(
    (form: HTMLFormElement) => !!form.querySelector('.g-recaptcha'),
    []
  );

  const importExternalForm = useCallback(
    (form: HTMLFormElement, isKsend: string | null | undefined) => {
      setInvalidForm(false);

      const mountedForm: ExternalForm = {
        inputs: [],
        checkbox: [],
        formId: '',
        action: '',
        recaptcha_enabled: false,
      };

      mountedForm.inputs = importInputs(form, Boolean(isKsend));
      mountedForm.checkbox = importCheckboxes(form);
      mountedForm.action = importAction(form);
      mountedForm.formId = importFormId(form);

      if (isKsend) {
        mountedForm.gdpr_info = importKlicksendGDPRText(form);
        mountedForm.recaptcha_enabled = klicksendRecaptchaEnabled(form);
      }

      return mountedForm;
    },
    [
      importAction,
      importCheckboxes,
      importFormId,
      importInputs,
      klicksendRecaptchaEnabled,
    ]
  );

  const getGdprProps = useCallback((form: ExternalForm) => {
    const gdpr = form.inputs.find((el) => el.name === 'gdpr');

    if (gdpr) {
      const gdprFields = JSON.stringify({
        ...gdpr,
        'font-family': 'Raleway, sans-serif',
        'font-size': '14px',
        color: '#191C1F',
      });

      return {
        checkbox: gdprFields,
        'text-gdpr': JSON.stringify({
          text: `<p><span style="font-family: Raleway, sans-serif; color:#191C1F; font-size: 14px;">${form.gdpr_info?.data_info}</span></p>`,
        }),
        'link-gdpr': JSON.stringify({
          text: `<p><a style="font-family: Raleway, sans-serif; font-size: 14px;" href="${form.gdpr_info?.privacy_policy}">${form.gdpr_info?.privacy_policy}</a></p>`,
        }),
      };
    }

    return {};
  }, []);

  const importRecaptchaScript = useCallback((form: HTMLElement) => {
    const script = form.querySelector('script');
    return script?.getAttribute('src');
  }, []);

  const handleValueChange = useCallback(
    (html: string, ownCode?: boolean) => {
      setValue(html);

      if (ownCode) {
        onFormChange?.({
          uid: 'html-form',
          component: 'ls-html-form',
          html,
          ownCode,
          renderOnce: true,
        });
        return;
      }

      const element = document.createElement('div');
      element.innerHTML = html;

      const form = element.querySelector<HTMLFormElement>('form');
      const inputsForm = form?.querySelector<HTMLInputElement>('input');

      if (!form || !inputsForm) {
        setInvalidForm(true);
        return;
      }

      const klicksendID = form?.getAttribute('klicksend-form-id');

      const externalForm = importExternalForm(form, klicksendID);
      const inputsString = JSON.stringify(externalForm.inputs);
      const inputs = {
        fields: `${inputsString}`,
        'font-family': 'Raleway, sans-serif',
        color: '#191C1F',
      };
      const checkboxString = JSON.stringify(externalForm.checkbox);
      const checkbox = {
        checkbox: `${checkboxString}`,
        'font-family': 'Raleway, sans-serif',
        'font-size': '14px',
        color: '#191C1F',
      };
      const button = JSON.stringify({
        uid: 'ls-button',
        component: 'ls-button',
        label: 'Enviar',
        'font-family': 'Montserrat, sans-serif',
        background: '#191C1F',
        'border-radius': '24px 24px 24px 24px',
        padding: '8px 32px 8px 32px',
        'is-bold': 'true',
      });

      if (klicksendID) {
        const recaptchaScript = importRecaptchaScript(element);

        const klicksendForm: KlicksendSelectedForm = {
          uid: 'form',
          ownCode,
          component: 'ls-klicksend-form',
          html,
          externalKsend: true,
          button,
          'form-id': klicksendID,
          fields: JSON.stringify(inputs),
          'recaptcha-enabled': externalForm.recaptcha_enabled,
          'recaptcha-script-link': `${recaptchaScript}`,
          'submit-link': `${externalForm.action}`,
          ...getGdprProps(externalForm),
        };

        onFormChange?.(klicksendForm);
        return;
      }

      const formElement = {
        uid: 'form',
        component: 'ls-form',
        'form-id': `${externalForm.formId}`,
        action: `${externalForm.action}`,
        html,
        button,
        fields: JSON.stringify(inputs),
        checkbox: JSON.stringify(checkbox),
        ownCode,
        'simple-form': false,
      };

      onFormChange?.(formElement);
    },
    [getGdprProps, importExternalForm, importRecaptchaScript, onFormChange]
  );

  const handleToggleChange = useCallback(
    (e: Event) => {
      const target = e.target as HTMLInputElement;
      handleValueChange(value, target.checked);
    },
    [handleValueChange, value]
  );

  useEffect(() => {
    setIsCheckedOwnForm(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return isBlocked ? (
    <BlockedContentWrapper {...attrs}>
      <BlockIconWrapper>
        <FontAwesomeIcon icon={faLock as IconProp} />
      </BlockIconWrapper>
      <BlockedContentTitle>
        {t(`modals.upgrade.generic.title`)}
      </BlockedContentTitle>
      <BlockedContentDescription>
        {t(`modals.upgrade.externalHtmlForm.description`)}
      </BlockedContentDescription>
      <a
        href="https://extensions.hotmart.com/salespage"
        className="hot-button hot-button--success"
      >
        {t(`modals.upgrade.generic.firstButton`)}
      </a>
    </BlockedContentWrapper>
  ) : (
    <Form className="hot-form" {...attrs}>
      <Label htmlFor="html-code" className="hot-form__label">
        {t('form.html.label')}
      </Label>

      <textarea
        data-prevent-delete
        id="html-code"
        className={classNames('hot-form__input hot-form__textarea--sm', {
          'is-invalid': invalidForm,
        })}
        placeholder={t('form.html.placeholder')}
        value={value}
        onChange={(event) =>
          handleValueChange(event.target.value, isCheckedOwnForm)
        }
        rows={6}
      />

      {invalidForm && (
        <ErrorMessage className="invalid-feedback">
          {t('form.html.invalidForm')}
        </ErrorMessage>
      )}

      <Description>{t('form.html.description')}</Description>

      {value && (
        <>
          <Switch className="hot-form hot-form--custom hot-form--switch">
            <div>
              <input
                type="checkbox"
                className="hot-form__input"
                id="OwnForm"
                checked={isCheckedOwnForm}
                onChange={(event: any) => handleToggleChange(event)}
              />
              <label htmlFor="OwnForm" className="hot-form__label">
                {t('form.html.ownForm')}
              </label>
            </div>
          </Switch>
          <Description>{t('form.html.ownDescription')}</Description>
        </>
      )}
    </Form>
  );
};

export const HTMLFormConfig = memo(HTMLFormConfigComponent);
