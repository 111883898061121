import '@hotmart-org-ca/cosmos/dist/dropdown';
import '@hotmart-org-ca/cosmos/dist/select';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/tooltip';

import React, {
  useMemo,
  HTMLAttributes,
  useState,
  useCallback,
  useEffect,
  memo,
} from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { CosmosButton } from '@components/CosmosButton';
import { Select, SelectOption } from '@components/Select';
import { rebrand } from '@config/rebrand';
import { usePageInfo } from '@hooks/usePageInfo';
import Klicksend from '@services/klicksend';
import {
  Container,
  Button,
  LinkDescription,
  Divider,
  Loader,
  ContentWrapper,
  ButtonsWrapper,
} from './styles';
import { FormCreation } from './components/FormCreation';

export type KlicksendFormConfigProps = HTMLAttributes<HTMLDivElement> & {
  value?: string;
  onFormChange?: (form: Template) => void;
};

const KlicksendFormConfigComponent: React.FC<KlicksendFormConfigProps> = ({
  value: propValue = '',
  onFormChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { isFunnelCapturePage } = usePageInfo();

  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState<KlicksendForm[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState(propValue);
  const [selectedForm, setSelectedForm] = useState<KlicksendForm>();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const getForms = useCallback(async () => {
    setLoading(true);

    const klicksendForms = await Klicksend.getForms();

    setForms(klicksendForms.items);
    setSelectOptions(
      klicksendForms.items.map((form: KlicksendForm) => ({
        id: form.id,
        label: form.name,
        value: form.id,
      }))
    );
    setLoading(false);
    return klicksendForms.items;
  }, []);

  const getGdprProps = useCallback((form: KlicksendForm) => {
    const gdpr = form.fields.find((el) => el.name === 'gdpr');

    if (gdpr) {
      const gdprFields = JSON.stringify({
        ...gdpr,
        'font-family': 'Raleway, sans-serif',
        'font-size': '14px',
        color: '#191C1F',
      });

      return {
        checkbox: gdprFields,
        'text-gdpr': JSON.stringify({
          text: `<p><span style="font-family: Raleway, sans-serif; color:#191C1F; font-size: 14px;">${form.gdpr_info?.data_info}</span></p>`,
        }),
        'link-gdpr': JSON.stringify({
          text: `<p><a style="font-family: Raleway, sans-serif; font-size: 14px;" href="${form.gdpr_info?.privacy_policy}">${form.gdpr_info?.privacy_policy}</a></p>`,
        }),
      };
    }

    return {};
  }, []);

  const getSelectedForm = useCallback(
    (value: string, updatedForms?: KlicksendForm[]) =>
      (updatedForms || forms).find((el) => el.id === value) as KlicksendForm,
    [forms]
  );

  const dispatchFormChange = useCallback(
    (value: string, updatedForms?: KlicksendForm[]) => {
      const updatedCurrentForm = getSelectedForm(value, updatedForms);
      const fieldsString = JSON.stringify(updatedCurrentForm.fields);
      const inputs = {
        fields: `${fieldsString}`,
        color: '#191C1F',
        'font-family': 'Raleway, sans-serif',
      };
      const formElement: KlicksendSelectedForm = {
        uid: 'form',
        component: 'ls-klicksend-form',
        button: JSON.stringify({
          uid: 'ls-button',
          component: 'ls-button',
          label: 'Enviar',
          'font-family': 'Montserrat, sans-serif',
          background: '#191C1F',
          'border-radius': '24px 24px 24px 24px',
          padding: '8px 32px 8px 32px',
          'is-bold': 'true',
        }),
        'is-bold': true,
        'form-id': updatedCurrentForm.id,
        fields: JSON.stringify(inputs),
        'recaptcha-enabled': updatedCurrentForm.recaptcha_enabled,
        'recaptcha-script-link': `${rebrand.urls.KLICKSEND_URL}/static/js/recaptcha.min.js`,
        'submit-link': `${rebrand.urls.KLICKSEND_HANDLER_URL}/${updatedCurrentForm.id}`,
        ...getGdprProps(updatedCurrentForm),
      };

      onFormChange?.(formElement);
    },
    [getGdprProps, getSelectedForm, onFormChange]
  );

  const handleSelectForm = useCallback(
    (value: string, updatedForms?: KlicksendForm[]) => {
      setSelectedOption(value);
      dispatchFormChange(value, updatedForms);
    },
    [dispatchFormChange]
  );

  const action = useMemo(
    () => (
      <hot-tooltip position="top" content={t('form.klicksend.tooltip')}>
        <Button className="hot-button hot-button--tertiary" onClick={getForms}>
          <i className={classNames('far fa-sync', { spin: loading })} />
        </Button>
      </hot-tooltip>
    ),
    [t, getForms, loading]
  );

  const sendAccessSendEvent = useCallback(
    () =>
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_editor_access_send',
          event_details: undefined,
        },
      }),
    []
  );

  const handleCreateButtonClick = useCallback(
    (eventDetails: string, clearSelected: boolean = false) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_editor_form_send_action',
          event_details: eventDetails,
        },
      });

      if (clearSelected) {
        setSelectedForm(undefined);
      }
      setIsCreating(true);
    },
    []
  );

  useEffect(() => {
    getForms();
  }, [getForms]);

  useEffect(() => {
    setSelectedOption(propValue);
  }, [propValue, selectedOption]);

  const handleSavedForm = useCallback(
    async (formId: string) => {
      setIsCreating(false);
      const updatedForms = await getForms();
      if (updatedForms.find((form) => form.id === formId)) {
        handleSelectForm(formId, updatedForms);
      }
    },
    [getForms, handleSelectForm]
  );

  useEffect(() => {
    if (selectedOption && !isCreating) {
      setSelectedForm(getSelectedForm(selectedOption));
    }
  }, [getSelectedForm, isCreating, selectedOption]);

  return loading ? (
    <Loader />
  ) : (
    <Container {...attrs}>
      {isCreating ? (
        <FormCreation
          onCancelled={() => setIsCreating(false)}
          onSaved={(formId) => handleSavedForm(formId)}
          selectedForm={selectedForm}
        />
      ) : (
        <>
          <ContentWrapper>
            <Select
              id="klicksend-forms"
              label={t('form.klicksend.label')}
              placeholder={t('form.klicksend.placeholder')}
              options={selectOptions}
              selectedOption={selectedOption}
              onChange={handleSelectForm}
              action={action}
              style={isFunnelCapturePage ? { pointerEvents: 'none' } : {}}
            />
            <ButtonsWrapper>
              {selectedForm && (
                <CosmosButton
                  label="form.klicksend.formCreation.editForm"
                  onButtonClicked={() => handleCreateButtonClick('Edit form')}
                  type="secondary"
                  role="button"
                />
              )}
              {!isFunnelCapturePage && (
                <CosmosButton
                  label="form.klicksend.formCreation.createForm"
                  onButtonClicked={() =>
                    handleCreateButtonClick(
                      'Create new form',
                      Boolean(selectedForm)
                    )
                  }
                  type={selectedForm ? 'tertiary' : 'secondary'}
                  role="button"
                />
              )}
            </ButtonsWrapper>
          </ContentWrapper>
          <Divider />
          <ContentWrapper>
            <LinkDescription>
              {isFunnelCapturePage
                ? t('form.klicksend.linkDescriptionFunnel')
                : t('form.klicksend.linkDescription')}{' '}
              <a
                target="_blank"
                href={
                  isFunnelCapturePage
                    ? `${rebrand.urls.KLICKSEND_URL}/forms/${selectedForm?.id}/statistics`
                    : `${rebrand.urls.KLICKSEND_URL}/forms`
                }
                onClick={sendAccessSendEvent}
                rel="noreferrer"
              >
                {t('form.klicksend.link')}
              </a>
            </LinkDescription>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};

export const KlicksendFormConfig = memo(KlicksendFormConfigComponent);
