import styled from 'styled-components';

export const ModalTitle = styled.h1`
  font-size: 24px;
  margin: 0;
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
`;
