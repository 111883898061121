import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import { minify } from '@common/utils';
import { Divider, ButtonsWrapper, Container } from './styles';
import { tooltipStyle } from '../../../../../../../../../../styles/global';

type FormCreationButtonsProps = HTMLAttributes<HTMLDivElement> & {
  canSave?: boolean;
  onCancelClicked: () => void;
  onSaveClicked: () => void;
};

export const FormCreationButtonsComponent: React.FC<FormCreationButtonsProps> =
  ({ canSave = true, onCancelClicked, onSaveClicked, ...attrs }) => {
    const { t } = useTranslation();

    const renderTooltip = useCallback(
      () =>
        !canSave
          ? minify(
              `<style>${tooltipStyle}</style>
        <div class="custom-tooltip__description">${t(
          'form.klicksend.formCreation.disabledSaveDescription'
        )}</div>`
            )
          : null,
      [canSave, t]
    );

    return (
      <Container {...attrs}>
        <Divider />
        <ButtonsWrapper>
          <CosmosButton
            label="form.klicksend.formCreation.cancelButton"
            type="tertiary"
            role="button"
            onButtonClicked={() => onCancelClicked()}
          />

          <hot-tooltip
            content={renderTooltip()}
            position="top"
            style={{ width: '100%' }}
          >
            <CosmosButton
              label="form.klicksend.formCreation.saveButton"
              type="primary"
              role="button"
              width="121.5px"
              disabled={!canSave}
              onButtonClicked={() => {
                if (canSave) {
                  onSaveClicked();
                }
              }}
            />
          </hot-tooltip>
        </ButtonsWrapper>
      </Container>
    );
  };
export const FormCreationButtons = memo(FormCreationButtonsComponent);
