import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import classNames from 'classnames';

import React, { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, DescriptionArea } from './styles';

export type DescriptionProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  value?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
};

const DescriptionComponent: React.FC<DescriptionProps> = ({
  value = '',
  error,
  errorMessage,
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleDescriptionChange = useCallback(
    async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div className="hot-form" {...attrs}>
      <Title htmlFor="description">{t('seo.description.title')}</Title>
      <textarea
        id="description"
        className={classNames('hot-form__input  hot-form__textarea--sm', {
          'is-invalid': error,
        })}
        placeholder={t('seo.description.placeholder')}
        defaultValue={value}
        onChange={handleDescriptionChange}
        rows={3}
      />

      {error && <div className="invalid-feedback">{t(`${errorMessage}`)}</div>}

      <DescriptionArea>{t('seo.description.description')}</DescriptionArea>
    </div>
  );
};

export const Description = memo(DescriptionComponent);
