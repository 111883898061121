import styled from 'styled-components';
import { Label } from '@components/Label';

export const IconBox = styled.div`
  width: 100%;
  height: 74px;
  background: var(--gray-100);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.3s;
`;

export const Container = styled.div`
  position: relative;
  width: calc(33.3333% - 8px);

  :hover,
  &.onboarding-highlight {
    hot-tooltip {
      display: inline-block;
    }

    ${IconBox} {
      background: var(--blue-lightest);

      svg * {
        transition: all ease 0.3s;
        fill: var(--blue);
      }
    }
  }

  hot-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 23px);
    position: absolute;
    top: -8px;
    pointer-events: none;
    width: 45px;
    height: 22px;
  }
`;

export const DraggableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLabel = styled(Label)`
  position: absolute;
  top: 0;
  right: 2px;
`;

export const Title = styled.span`
  font-size: 12px;
  margin: 8px 0;
  color: var(--gray-700);
  text-align: center;
`;
