import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const Button = styled.button`
  width: 100%;
`;

export const ProductInfo = styled.div`
  color: var(--gray-500);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 16px;
  line-height: 18px;
  margin: 0 0 16px;
`;

export const ProductInfoLabel = styled.label`
  color: var(--gray-900);
  display: block;
  font-size: 16px;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
