import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';

import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import HotmartProductService from '@services/hotmartProduct';
import { BaseModal } from '@components/BaseModal';
import { OfferTable } from '@components/OfferTable';
import { Container, Description, ModalCustomStyle, Title } from './styles';

export type ChangeOfferProps = HTMLAttributes<HTMLDivElement> & {
  productId: string;
  offerId?: string;
  onOfferChange: (updatedOffer: Partial<ProductProps>) => void;
};

export const ChangeOffer: React.FC<ChangeOfferProps> = ({
  productId,
  offerId = '',
  onOfferChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [currentOffer, setCurrentOffer] = useState(offerId);
  const [showModal, setShowModal] = useState(false);
  const [offers, setOffers] = useState<HotmartProductOffer[]>([]);
  const [loading, setLoading] = useState(true);

  const handleButtonClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleOfferChange = useCallback(
    (updatedOffer: Partial<ProductProps>) => {
      setCurrentOffer(updatedOffer.offerId as string);
    },
    []
  );

  const handleSelectOffer = useCallback(() => {
    const offer = offers.find((offerItem) => offerItem.id === currentOffer);
    handleCloseModal();
    onOfferChange?.({
      offerId: offer?.id,
      offerKey: offer?.key,
      offerLink: offer?.link,
      offerPrice: offer?.price,
      offerMaxInstallments: offer?.maxInstallments,
    });
  }, [currentOffer, handleCloseModal, offers, onOfferChange]);

  const getOffers = useCallback(async () => {
    try {
      setLoading(true);

      const offersData = await HotmartProductService.getOffersByProductId(
        productId
      );

      setOffers(offersData);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  useEffect(() => setCurrentOffer(offerId), [offerId]);

  const header = useMemo(
    () => (
      <>
        <Title>
          {t('pageWizard.salesPage.paidWithProduct.updateOffer.modal.title')}
        </Title>
        <Description>
          {t(
            'pageWizard.salesPage.paidWithProduct.updateOffer.modal.description'
          )}
        </Description>
      </>
    ),
    [t]
  );

  const footer = useMemo(
    () => (
      <>
        <button
          className="hot-button hot-button--tertiary"
          onClick={handleCloseModal}
        >
          {t('pageWizard.salesPage.paidWithProduct.updateOffer.modal.back')}
        </button>
        <button
          className="hot-button hot-button--primary"
          onClick={handleSelectOffer}
        >
          {t('pageWizard.salesPage.paidWithProduct.updateOffer.modal.select')}
        </button>
      </>
    ),
    [handleCloseModal, handleSelectOffer, t]
  );

  return (
    <Container {...attrs}>
      <button
        className="hot-button hot-button--tertiary"
        onClick={handleButtonClick}
      >
        {t('pageWizard.salesPage.paidWithProduct.updateOffer.changeOffer')}
      </button>

      <ModalCustomStyle />

      <BaseModal
        size="medium"
        isOpen={showModal}
        onClose={handleCloseModal}
        header={header}
        footer={footer}
      >
        {!loading && (
          <OfferTable
            offers={offers}
            selectedOfferId={currentOffer}
            onSelectOffer={handleOfferChange}
          />
        )}
      </BaseModal>
    </Container>
  );
};
