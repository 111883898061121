import '@hotmart-org-ca/cosmos/dist/dropdown';

import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import RewriteAI from '@services/rewriteAI';
import { useNavigate } from 'react-router-dom';
import { sleep } from '@common/utils';
import { HTMLEditorElement } from '@hotmart-org-ca/saas-pages-engine';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useDataHub } from '@hooks/useDataHub';
import { Container, Icon, Comment, Loading, Feedback } from './styles';

const urlParamOptions = {
  rewrite: 'rewrite',
  survey: 'survey',
  tone: 'tone',
};

type ActionType =
  | 'objective'
  | 'detailed'
  | 'review'
  | 'inspirational'
  | 'persuasive';

const rewriteActions: Record<ActionType, ActionType> = {
  objective: 'objective',
  detailed: 'detailed',
  review: 'review',
  inspirational: 'inspirational',
  persuasive: 'persuasive',
};

export type HotmartAIDropdownActionProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps;

export const HotmartAIDropdownAction: React.FC<HotmartAIDropdownActionProps> =
  ({ uid }) => {
    const { t } = useTranslation();
    const { rewriteTextEnabled } = useFeatureFlags();
    const { sendMetrify } = useDataHub();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const hotDropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const listener = () => {
        if (hotDropdownRef.current) {
          hotDropdownRef.current.click();
        }

        sendMetrify({
          group: 'pages_hotmart_ai',
          name: 'float_menu_icon_click',
          system: 'lead_solutions',
        });
      };
      window.addEventListener(`rewrite-text-${uid}`, listener);
      return () => {
        window.removeEventListener(`rewrite-text-${uid}`, listener);
      };
    }, [sendMetrify, uid]);

    const handleAdditionParamOnURL = useCallback(
      async (urlParam: string, closeDropdown: boolean = false) => {
        if (closeDropdown && hotDropdownRef.current) {
          hotDropdownRef.current.click();
        }

        const urlParamMapping = {
          [urlParamOptions.survey]: '?survey=ai_feature',
          [urlParamOptions.rewrite]: '?rewrite=ai_feature',
          [urlParamOptions.tone]: '?tone=ai_feature',
        };

        const search = urlParamMapping[urlParam];

        navigate({ search }, { replace: true });

        await sleep(3000);

        navigate({ search: '' }, { replace: true });
      },
      [navigate]
    );

    const handleRewrite = useCallback(
      async (
        resultGetter: (textContent: string) => Promise<string>,
        urlOption?: 'rewrite' | 'survey' | 'tone',
        actionType?: ActionType
      ) => {
        const element = window.document.querySelector<HTMLEditorElement>(
          `#ls-text-editable-${uid}__editor`
        );
        const textContent = element?.textContent?.replace(/\n/g, '');
        if (textContent && element) {
          try {
            setLoading(true);

            const text = await resultGetter(textContent);
            element?.richText.replaceText(text);

            if (urlOption) {
              handleAdditionParamOnURL(urlParamOptions[urlOption]);
            }
            sendMetrify({
              group: 'pages_hotmart_ai',
              name: 'float_menu_action_click',
              params: `rewrite_${actionType}`,
              system: 'lead_solutions',
            });
          } finally {
            setLoading(false);
          }
        }
      },
      [handleAdditionParamOnURL, sendMetrify, uid]
    );

    const handleRewriteByType = useCallback(
      async (textContent: string, value: string) => {
        const rewroteTextResult = await RewriteAI.getRewriteTextByType(
          value,
          textContent
        ).promise;

        return rewroteTextResult.output.rewrite[0];
      },
      []
    );

    const handleRewriteToneByType = useCallback(
      async (textContent: string, tone: string) => {
        const rewroteTextResult = await RewriteAI.getToneTextByType(
          tone,
          textContent
        ).promise;

        return rewroteTextResult.output.tone[0];
      },
      []
    );

    const handleReviewText = useCallback(async (textContent: string) => {
      const revisedTextResult = await RewriteAI.getRevisedText(textContent)
        .promise;

      return revisedTextResult.output.text[0];
    }, []);

    return (
      <Container>
        {loading ? (
          <Loading className="hot-button--loading" />
        ) : (
          <hot-dropdown>
            <div
              slot="button"
              ref={hotDropdownRef}
              role="button"
              aria-hidden="true"
            />
            <hot-dropdown-menu-header slot="menu">
              {t('floatMenu.actions.hotmartAIDropdown.title')}
            </hot-dropdown-menu-header>
            <hot-dropdown-menu-divider slot="menu" />
            <hot-dropdown-menu-header slot="menu">
              {t('floatMenu.actions.hotmartAIDropdown.subtitle')}
            </hot-dropdown-menu-header>
            <hot-dropdown-menu-item
              slot="menu"
              onClick={() =>
                handleRewrite(
                  (textContent: string) =>
                    handleRewriteByType(textContent, rewriteActions.objective),
                  'rewrite',
                  rewriteActions.objective
                )
              }
            >
              <Icon icon={['fal', 'grip-lines']} />
              <div>
                {t('floatMenu.actions.hotmartAIDropdown.shortTextItem')}
              </div>
            </hot-dropdown-menu-item>
            <hot-dropdown-menu-item
              slot="menu"
              onClick={() =>
                handleRewrite(
                  (textContent: string) =>
                    handleRewriteByType(textContent, rewriteActions.detailed),
                  'rewrite',
                  rewriteActions.detailed
                )
              }
            >
              <Icon icon={['fal', 'bars']} />
              <div>
                {t('floatMenu.actions.hotmartAIDropdown.descriptiveTextItem')}
              </div>
            </hot-dropdown-menu-item>
            {rewriteTextEnabled && (
              <hot-dropdown-menu-item
                slot="menu"
                onClick={() =>
                  handleRewrite(
                    (textContent: string) => handleReviewText(textContent),
                    'rewrite',
                    rewriteActions.review
                  )
                }
              >
                <Icon icon={['fal', 'spell-check']} />

                <div>{t('floatMenu.actions.hotmartAIDropdown.reviewText')}</div>
              </hot-dropdown-menu-item>
            )}

            {rewriteTextEnabled && (
              <>
                <hot-dropdown-menu-header slot="menu">
                  {t('floatMenu.actions.hotmartAIDropdown.toneAdjustmentTitle')}
                </hot-dropdown-menu-header>
                <hot-dropdown-menu-item
                  slot="menu"
                  onClick={() =>
                    handleRewrite(
                      (textContent: string) =>
                        handleRewriteToneByType(
                          textContent,
                          rewriteActions.inspirational
                        ),
                      'tone',
                      rewriteActions.inspirational
                    )
                  }
                >
                  <Icon icon={['fal', 'lightbulb-on']} />
                  <div>
                    {t('floatMenu.actions.hotmartAIDropdown.inspirationalText')}
                  </div>
                </hot-dropdown-menu-item>
                <hot-dropdown-menu-item
                  slot="menu"
                  onClick={() =>
                    handleRewrite(
                      (textContent: string) =>
                        handleRewriteToneByType(
                          textContent,
                          rewriteActions.persuasive
                        ),
                      'tone',
                      rewriteActions.persuasive
                    )
                  }
                >
                  <Icon icon={['fal', 'bullhorn']} />
                  <div>
                    {' '}
                    {t('floatMenu.actions.hotmartAIDropdown.persuasiveText')}
                  </div>
                </hot-dropdown-menu-item>
              </>
            )}

            <hot-dropdown-menu-divider slot="menu" />
            <Comment slot="menu">
              <Trans
                i18nKey="floatMenu.actions.hotmartAIDropdown.getToKnowMore"
                components={{
                  u: <u />,
                  a: (
                    <a
                      href="https://hotmart.com/pt-br/inteligencia-artificial?itm_source=pages_ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      HotmartAI Link
                    </a>
                  ),
                  c: (
                    <a
                      href="https://hotmart.com/pt-br/legal?itm_source_pages_ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy Link
                    </a>
                  ),
                  d: (
                    <Feedback
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        handleAdditionParamOnURL(urlParamOptions.survey, true)
                      }
                      onKeyDown={() =>
                        handleAdditionParamOnURL(urlParamOptions.survey, true)
                      }
                    >
                      Feedback
                    </Feedback>
                  ),
                }}
                values={{
                  feature: t('floatMenu.actions.hotmartAIDropdown.title'),
                  policy: t('floatMenu.actions.hotmartAIDropdown.policy'),
                  feedback: t('floatMenu.actions.hotmartAIDropdown.feedback'),
                }}
              />
            </Comment>
          </hot-dropdown>
        )}
      </Container>
    );
  };
