import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const DestinationFolderDescription = styled.div`
  color: var(--gray-500);
  font-size: 14px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`;

export const CancelButton = styled.button`
  margin-right: 16px;
`;
