import { HTMLAttributes, memo, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { minify } from '@common/utils';
import { tooltipStyle } from '@styles/global';
import { Container, Description, Label, LabelGroup } from './styles';

type BlockProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  label?: string;
  description?: string;
  showSeparator?: boolean;
  width?: string;
  noPreview?: boolean;
  descriptionMarginBottom?: string;
  infoTooltip?: {
    displayMode?: 'hover' | 'always';
    title?: string;
    description?: string;
  };
  tooltipPosition?: 'top' | 'right';
};

const BlockComponent: React.FC<BlockProps> = ({
  children,
  description,
  label,
  descriptionMarginBottom = '24px',
  showSeparator,
  width,
  infoTooltip,
  tooltipPosition = 'right',
  ...attrs
}) => {
  const { t } = useTranslation();

  const renderTooltip = useCallback(() => {
    if (infoTooltip) {
      return minify(
        `<style>${tooltipStyle}</style>
        <div class="custom-tooltip__label">${t(`${infoTooltip?.title}`)}</div>
        <div class="custom-tooltip__description">${t(
          `${infoTooltip.description}`
        )}</div>`
      );
    }
    return '';
  }, [infoTooltip, t]);

  return (
    <Container isShowSeparator={showSeparator} width={width} {...attrs}>
      {label && (
        <LabelGroup hasDescription={Boolean(description)}>
          <Label>{t(label)}</Label>
          {infoTooltip && (
            <hot-tooltip content={renderTooltip()} position={tooltipPosition}>
              <i className="fal fa-info-circle" />
            </hot-tooltip>
          )}
        </LabelGroup>
      )}
      {description && (
        <Description descriptionMarginBottom={descriptionMarginBottom}>
          {t(description)}
        </Description>
      )}
      {children}
    </Container>
  );
};

export const Block = memo(BlockComponent);
