import '@hotmart-org-ca/cosmos/dist/pagination/variations/small.css';
import '@hotmart-org-ca/cosmos/dist/pagination/pagination.css';

import { useCallback } from 'react';
import classNames from 'classnames';
import { Container } from './styles';
import { getPaginationArray } from './helpers';

export type PaginationProps = {
  currentPage: number;
  totalPages: number;
  paginationSize?: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  paginationSize = 5,
  onPageChange,
  ...attrs
}) => {
  const previousPage = useCallback(() => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  }, [currentPage, onPageChange]);

  const nextPage = useCallback(() => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  }, [currentPage, onPageChange, totalPages]);

  const renderFixedItem = useCallback(
    (type: string, disabled: boolean = false, onClick?: () => void) => (
      <hot-pagination-item
        role="button"
        key={type}
        onClick={onClick}
        class={classNames({
          'hot-pagination__item--disabled': disabled,
        })}
      >
        <span className={`hot-pagination__${type}`}>{type}</span>
      </hot-pagination-item>
    ),
    []
  );

  const handleClick = useCallback(
    (buttonContent: string, index: number) => {
      if (buttonContent !== '...') {
        return onPageChange(+buttonContent);
      }
      if (index >= paginationSize) {
        return onPageChange(currentPage + paginationSize);
      }
      return onPageChange(currentPage - paginationSize);
    },
    [currentPage, onPageChange, paginationSize]
  );

  return (
    <Container {...attrs}>
      <hot-pagination class="hot-pagination--sm">
        {renderFixedItem('prev', currentPage === 1, previousPage)}
        {getPaginationArray({
          currentPageNumber: currentPage,
          lastPageNumber: totalPages,
          paginationSize,
        }).map((pageItem, index) => {
          const key = pageItem + index;
          return (
            <hot-pagination-item
              role="button"
              key={key}
              onClick={() => handleClick(pageItem, index)}
              class={classNames({
                'hot-pagination__item--active': currentPage === +pageItem,
              })}
              style={{
                minWidth: '33px',
                justifyContent: 'center',
              }}
            >
              {pageItem}
            </hot-pagination-item>
          );
        })}
        {renderFixedItem('next', currentPage === totalPages, nextPage)}
      </hot-pagination>
    </Container>
  );
};

export default Pagination;
