import '@hotmart-org-ca/cosmos/dist/header';

import { HTMLAttributes, memo, useEffect } from 'react';
import { Container, LeftSlot, RightSlot, CenterSlot } from './styles';

export type BaseHeaderProps = HTMLAttributes<HTMLDivElement> & {
  leftSlot?: React.ReactElement;
  centerSlot?: React.ReactElement;
  rightSlot?: React.ReactElement;
  centerSlotMaxWidth?: string;
};

const BaseHeaderComponent: React.FC<BaseHeaderProps> = ({
  leftSlot,
  centerSlot,
  rightSlot,
  centerSlotMaxWidth = 'unset',
  ...attrs
}) => {
  useEffect(() => {
    const logoBrand = document.querySelector('hot-header');
    logoBrand?.shadowRoot
      ?.querySelector('.hot-application__header-brand')
      ?.remove();
  }, []);

  return (
    <hot-header slot="header" {...attrs}>
      <Container slot="header-actions">
        <LeftSlot>{leftSlot}</LeftSlot>
        {centerSlot && (
          <CenterSlot maxWidth={centerSlotMaxWidth}>{centerSlot}</CenterSlot>
        )}
        <RightSlot>{rightSlot}</RightSlot>
      </Container>
    </hot-header>
  );
};

export const BaseHeader = memo(BaseHeaderComponent);
