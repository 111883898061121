import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import classNames from 'classnames';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import Toast from '@services/toast';
import { useEngine } from '@hooks/useEngine';
import { useSavedSections } from '@hooks/useSavedSections';
import { BaseModal } from '@components/BaseModal';
import { Label, ModalTitle, CancelButton, ModalCustomStyle } from './styles';

type SaveSectionModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;
  templateSection: Template;
};

const SaveSectionModalComponent: React.FC<SaveSectionModalProps> = ({
  isOpen,
  onClose,
  templateSection,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { userSections, createUserSection, loading } = useSavedSections();
  const { getTemplateWithDefaults } = useEngine();

  const [nameSection, setNameSection] = useState('');
  const [isNameRepeated, setIsNameRepeated] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose?.();
    setNameSection('');
  }, [onClose]);

  const handleSaveSection = useCallback(async () => {
    const saveSectionConfig = {
      template: getTemplateWithDefaults(templateSection),
      name: nameSection,
    };

    try {
      await createUserSection(saveSectionConfig);
      handleCloseModal();
      setNameSection('');

      const title = t('toastMessages.saveSectionModal.saved.success');
      const description = t(
        'toastMessages.saveSectionModal.saved.successDescription'
      );
      const link = t('toastMessages.saveSectionModal.saved.openSavedSections');

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_save_section_confirm',
          event_details: undefined,
        },
      });

      const customEventName = 'toast-open-menu';
      const customEventValue = JSON.stringify({
        detail: {
          menu: 'models-list',
          submenu: ['sections-template', 'saved-sections'],
          customEventName: 'click_open_saved_sections',
        },
      }).replace(/"/g, "'");

      Toast.success(
        `<b>${title}</b><br>${description}<br><br><a href='#' onclick="window.dispatchEvent(new CustomEvent('${customEventName}', ${customEventValue}))">${link}</a>`
      );
    } catch {
      Toast.error(t('toastMessages.saveSectionModal.saved.error'));
    }
  }, [
    createUserSection,
    getTemplateWithDefaults,
    handleCloseModal,
    nameSection,
    t,
    templateSection,
  ]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setNameSection(value);

      const nameRepeated = userSections?.some(
        (section) => section.name === value
      );

      setIsNameRepeated(Boolean(nameRepeated));
    },
    [userSections]
  );

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      headerClass="save-section-modal-header"
      footerClass="save-section-modal-footer"
      header={<ModalTitle>{t(`modals.saveSections.title`)}</ModalTitle>}
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t('modals.saveSections.cancelButton')}
          </CancelButton>
          <button
            onClick={handleSaveSection}
            className={classNames('hot-button hot-button--primary', {
              'hot-button--disabled': isNameRepeated,
              'hot-button--loading': loading,
            })}
          >
            {t(`modals.saveSections.saveButton`)}
          </button>
        </>
      }
    >
      <ModalCustomStyle />
      <>
        <Label> {t(`modals.saveSections.inputTitle`)}</Label>{' '}
        <input
          id="saveSectionName"
          className={classNames('hot-form__input', {
            'is-invalid': isNameRepeated,
          })}
          placeholder={t(`modals.saveSections.inputPlaceholder`)}
          value={nameSection}
          onChange={(event) => handleInputChange(event)}
          data-prevent-delete
        />
        {isNameRepeated && (
          <div className="invalid-feedback">
            {t(`modals.saveSections.invalidNameError`)}
          </div>
        )}
      </>
    </BaseModal>
  );
};

export const SaveSectionModal = memo(SaveSectionModalComponent);
