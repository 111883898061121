import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';
import Files from '@services/files';
import { setUrlParamFormMenu } from '@store/slices/menu';

const createUrlParam = (urlParams: UrlParam[], urlParamToSave: UrlParam) => [
  ...urlParams,
  { ...urlParamToSave, id: uuidV4() },
];

const updateUrlParam = (urlParams: UrlParam[], urlParamToUpdate: UrlParam) => {
  let paramFound: boolean = false;
  const updatedUrlParams = [...urlParams].map((urlParamOnConfig) => {
    if (urlParamOnConfig.id === urlParamToUpdate.id) {
      paramFound = true;
      return { ...urlParamOnConfig, ...urlParamToUpdate };
    }
    return urlParamOnConfig;
  });
  if (!paramFound) {
    throw new Error('Url param not found');
  }
  return updatedUrlParams;
};

export const saveUrlParam = createAsyncThunk<
  UrlParam[],
  UrlParam,
  {
    state: LocalState;
  }
>('pageConfig/saveUrlParam', async (urlParamToSave, { getState, dispatch }) => {
  const {
    pageConfig,
    pageInfo: { uuid },
  } = getState();

  try {
    dispatch(setUrlParamFormMenu({ loading: true }));
    const updatedUrlParams = !urlParamToSave.id
      ? createUrlParam(pageConfig.urlParams, urlParamToSave)
      : updateUrlParam(pageConfig.urlParams, urlParamToSave);

    await Files.saveConfigFile(uuid, {
      ...pageConfig,
      urlParams: updatedUrlParams,
    });
    return updatedUrlParams;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  } finally {
    dispatch(setUrlParamFormMenu({ loading: false }));
  }
});

export const saveUrlParamBuilder = (
  builder: ActionReducerMapBuilder<PageConfigState>
) => {
  builder.addCase(
    saveUrlParam.fulfilled,
    (state: PageConfigState, { payload }: PayloadAction<UrlParam[]>) => {
      state.urlParams = payload;
    }
  );
};
