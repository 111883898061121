import { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useEngine } from '@hooks/useEngine';
import { useHigherNameByElement } from '@hooks/useHigherNameByElement';
import { dispatchOpenPopup, scrollToSection, sleep } from '@common/utils';
import { CosmosButton } from '@components/CosmosButton';
import { TemplateModelsModal } from '@components/TemplateModelsModal';
import { getRootAddableTemplate } from './helpers';

type AddToRootButtonProps = HTMLAttributes<HTMLDivElement> & {
  addableElementType: 'section' | 'popup';
  activeElementAfterAdd?: boolean;
  forceAddEmptyElement?: boolean;
  disabled?: boolean;
  label?: string;
  sendMetrifyOptions?: Omit<SendMetrifyOptions, 'element'>;
};

export const AddToRootButton: React.FC<AddToRootButtonProps> = ({
  addableElementType,
  disabled = false,
  sendMetrifyOptions,
  activeElementAfterAdd = false,
  forceAddEmptyElement = false,
  label = '',
  ...attrs
}) => {
  const { page, addElement, openedPopup } = useEngine();
  const { higherName } = useHigherNameByElement(`ls-${addableElementType}`);
  const [showTemplateModelsModal, setShowTemplateModelsModal] = useState(false);

  const isSection = useMemo(
    () => addableElementType === 'section',
    [addableElementType]
  );

  const createEmptyElement = useCallback(async () => {
    const uid = addElement(
      getRootAddableTemplate({
        elementType: addableElementType,
        metadataLabel: higherName,
      }),
      page?.uid || '',
      page?.children?.length || 0
    );

    await sleep(200);

    if (isSection) {
      scrollToSection(uid, true);
    } else {
      dispatchOpenPopup(uid, openedPopup);
    }

    if (activeElementAfterAdd) {
      document.getElementById(uid)?.click();
    }
  }, [
    activeElementAfterAdd,
    addElement,
    addableElementType,
    higherName,
    isSection,
    openedPopup,
    page?.children?.length,
    page?.uid,
  ]);

  const handleAddTemplate = useCallback(async () => {
    if (addableElementType === 'popup') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_create_pop_up',
          event_details: undefined,
        },
      });
    }

    if (forceAddEmptyElement) {
      createEmptyElement();
      return;
    }

    setShowTemplateModelsModal(true);
  }, [forceAddEmptyElement, addableElementType, createEmptyElement]);

  return (
    <div {...attrs}>
      <CosmosButton
        label={label || `sideMenu.pageStructure.${addableElementType}s.button`}
        type="secondary"
        iconClass="fal fa-plus"
        iconSide="left"
        disabled={disabled}
        onButtonClicked={handleAddTemplate}
        sendMetrifyOptions={sendMetrifyOptions}
      />

      <TemplateModelsModal
        {...attrs}
        isOpen={showTemplateModelsModal}
        elementType={addableElementType}
        onClose={() => setShowTemplateModelsModal(false)}
      />
    </div>
  );
};
