import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedSections from '@services/savedSections';

export const getUserSections = createAsyncThunk<
  PageFragmentListItem[],
  void,
  {
    state: LocalState;
  }
>('pageFragments/getUserSections', async () => {
  try {
    const response = await SavedSections.get();
    return response;
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const getUserSectionsBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(getUserSections.pending, (state: PageFragmentsState) => {
    state.sectionLoading = true;
  });
  builder.addCase(
    getUserSections.fulfilled,
    (state: PageFragmentsState, action) => {
      state.userSections = action.payload;
      state.sectionLoading = false;
    }
  );
  builder.addCase(getUserSections.rejected, (state: PageFragmentsState) => {
    state.sectionLoading = false;
  });
};
