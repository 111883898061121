import { AxiosRequestConfig } from 'axios';
import { casEnabled } from 'src/auth/authService';
import Api from '../api';

export default class KlickHttp {
  private instance = casEnabled()
    ? Api.getCasInstance()
    : Api.getKlickInstance();

  public async delete<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.instance.delete<T>(url, config);
    return data;
  }

  public async get<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.instance.get<T>(url, config);
    return data;
  }

  public async patch<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance.patch<T>(url, body, config);
    return data;
  }

  public async post<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance.post<T>(url, body, config);
    return data;
  }

  public async put<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance.put<T>(url, body, config);
    return data;
  }
}
