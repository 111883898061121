import { useSelector } from 'react-redux';
import {
  ActiveComponentState,
  ComponentProps,
  ComponentState,
  EditorEngineManager,
  Preview,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import { useCallback } from 'react';

type UseEngineProps = {
  elementUid?: string;
};

export const useEngine = ({ elementUid }: UseEngineProps = {}) => {
  const activeComponent = useSelector(
    (state: State) => state.lsEditor.activeComponent
  );

  const dragging = useSelector((state: State) => state.lsEditor.dragging);

  const elementState = useSelector((state: State) =>
    elementUid ? (state[elementUid] as ComponentState) : undefined
  );

  const openedPopup = useSelector((state: State) => state.lsEditor.openedPopup);

  const page = useSelector((state: State) => state.lsEditor.page);

  const preview = useSelector((state: State) => state.lsEditor.preview);

  const redo = useSelector((state: State) => state.lsEditor.redo);

  const undo = useSelector((state: State) => state.lsEditor.undo);

  const addElement = useCallback(
    (template: Template, parent: string, position: number) =>
      EditorEngineManager.addElement(template, parent, position),
    []
  );

  const clearActiveComponent = useCallback(
    () => EditorEngineManager.clearActive(),
    []
  );

  const clearState = useCallback(() => EditorEngineManager.clearState(), []);

  const createElementSlice = useCallback(
    (template: Template) => EditorEngineManager.createElementSlice(template),
    []
  );

  const duplicateElement = useCallback(
    (uid: string, parent: string) =>
      EditorEngineManager.duplicateElement(uid, parent),
    []
  );

  const getElement = useCallback(
    (uid: string) => (page ? EditorEngineManager.getElement(uid, page) : false),
    [page]
  );

  const getFinalFiles = useCallback(
    () => EditorEngineManager.getFinalFiles(),
    []
  );

  const getFinalPage = useCallback(
    () => EditorEngineManager.getFinalPage(),
    []
  );

  const getTemplateWithDefaults = useCallback(
    (template: Template) =>
      EditorEngineManager.getTemplateWithDefaults(template),
    []
  );

  const hasPopupParent = useCallback(
    (componentUid: string): boolean => {
      const { parent } = EditorEngineManager.getElement(
        componentUid,
        page!
      ) as Template;

      if (!parent) {
        return false;
      }

      return parent.component === 'ls-popup'
        ? true
        : hasPopupParent(parent.uid);
    },
    [page]
  );

  const removeElement = useCallback(
    (uid: string) => EditorEngineManager.removeElement(uid),
    []
  );

  const replaceElement = useCallback((uid: string, newElement: Template) => {
    EditorEngineManager.replaceElement(uid, newElement);
  }, []);

  const replaceTemplate = useCallback((uid: string, newElement: Template) => {
    EditorEngineManager.replaceTemplate(uid, newElement);
  }, []);

  const setActiveComponent = useCallback(
    (activatingComponent: ActiveComponentState) =>
      EditorEngineManager.setActive(activatingComponent),
    []
  );

  const setPage = useCallback(
    (template: Template) => EditorEngineManager.setPage(template),
    []
  );

  const setPreview = useCallback(
    (previewDevice: Preview) => EditorEngineManager.setPreview(previewDevice),
    []
  );

  const setRedo = useCallback(() => EditorEngineManager.redo(), []);

  const setUndo = useCallback(() => EditorEngineManager.undo(), []);

  const updateElement = useCallback(
    (uid: string, payload: ComponentProps, addToHistory: boolean = true) => {
      EditorEngineManager.updateElement(uid, payload, addToHistory);
    },
    []
  );

  return {
    activeComponent,
    dragging,
    elementState,
    openedPopup,
    page,
    preview,
    redo,
    undo,
    addElement,
    clearActiveComponent,
    clearState,
    createElementSlice,
    duplicateElement,
    getElement,
    getFinalFiles,
    getFinalPage,
    getTemplateWithDefaults,
    hasPopupParent,
    removeElement,
    replaceElement,
    replaceTemplate,
    setActiveComponent,
    setPage,
    setPreview,
    setRedo,
    setUndo,
    updateElement,
  };
};
