import { HTMLAttributes, useCallback } from 'react';
import { EditableName } from '@components/EditableName';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '@hooks/useUserInfo';
import { useApplication } from '@hooks/useApplication';
import { usePageInfo } from '@hooks/usePageInfo';
import TagManager from 'react-gtm-module';
import PagesService from '../../../../services/pages';
import Toast from '../../../../services/toast';
import { Container } from './styles';

export const PageName: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();

  const { mode, uuid, pageName, updateInfo } = usePageInfo();
  const { isPreviewMode } = useApplication();
  const { templateManagementEnabled } = useUserInfo();

  const handleNameChange = useCallback(
    async (name: string) => {
      updateInfo({ name });
      try {
        await PagesService.updatePage(
          uuid,
          { name },
          mode,
          templateManagementEnabled
        );
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            custom_event_name: 'click_navbar_editor',
            event_details: 'Rename::Finished',
          },
        });
        Toast.success('toastMessages.general.success.saved');
      } catch {
        Toast.error('toastMessages.general.error.saved');
      }
    },
    [mode, templateManagementEnabled, updateInfo, uuid]
  );

  return (
    <Container {...attrs}>
      <EditableName
        name={pageName}
        onNameChanged={handleNameChange}
        fontSize={16}
        blockEdition={isPreviewMode}
        tooltipText={t('header.edit')}
      />
    </Container>
  );
};
