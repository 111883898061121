import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  initializeCheckoutIntegration,
  initializeCheckoutIntegrationBuilder,
} from './initializeCheckoutIntegration';

export const checkoutThunks = {
  initializeCheckoutIntegration,
};

export const checkoutExtraReducers = (
  builder: ActionReducerMapBuilder<CheckoutState>
) => {
  initializeCheckoutIntegrationBuilder(builder);
};
