import { innerPopoverStyle } from '../styles';

const getPopoverConcrete = (popoverElement: HotPopoverElement) =>
  popoverElement.shadowRoot?.querySelector(
    'hot-popover-concrete'
  ) as HTMLElement;

const createConcreteStyleTag = () => {
  const popoverConcreteStyle = document.createElement('style');
  popoverConcreteStyle.id = 'innerPopoverStyle';
  popoverConcreteStyle.innerHTML = innerPopoverStyle.toString();
  return popoverConcreteStyle;
};

export const addInnerPopoverStyle = (popoverElement: HotPopoverElement) => {
  const innerPopover = getPopoverConcrete(popoverElement);
  const innerPopoverStyleTag = innerPopover?.shadowRoot?.querySelector(
    '#innerPopoverStyle'
  ) as HTMLStyleElement;
  if (!innerPopoverStyleTag) {
    const concreteStyleTag = createConcreteStyleTag();
    innerPopover?.shadowRoot?.prepend(concreteStyleTag);
  }
};
