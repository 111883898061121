import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';

import { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Icon, Button } from './styles';
import { MoveMediaModal } from './components/MoveMediaModal';
import { RemoveMediaModal } from '../../../RemoveMediaModal';

export type BarActionsProps = HTMLAttributes<HTMLDivElement>;

export const BarActions: React.FC<BarActionsProps> = ({ ...attrs }) => {
  const { t } = useTranslation();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const [openMoveModal, setOpenMoveModal] = useState(false);

  const handleRemoveMediaClick = useCallback(() => {
    setOpenRemoveModal(true);
  }, []);

  const handleMoveMediaClick = useCallback(() => {
    setOpenMoveModal(true);
  }, []);

  return (
    <Container {...attrs}>
      <Button
        className="hot-button hot-button--primary"
        onClick={handleMoveMediaClick}
      >
        <Icon className="fal fa-folder-open" />
        {t('mediaCenter.infoBar.actions.move')}
      </Button>

      <Button
        className="hot-button hot-button--danger"
        onClick={() => handleRemoveMediaClick()}
      >
        <Icon className="fal fa-trash-alt" />
        {t('mediaCenter.infoBar.actions.remove')}
      </Button>

      <MoveMediaModal
        isOpen={openMoveModal}
        onClose={() => setOpenMoveModal(false)}
      />

      <RemoveMediaModal
        isOpen={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}
      />
    </Container>
  );
};
