import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div``;

export const ModalCustomStyle = createGlobalStyle`
  .save-section-modal-header {
    padding-bottom: 8px;
  }
  .save-section-modal-footer {
    justify-content: end;
    padding-top: 48px;
  }
`;

export const ModalTitle = styled.label`
  font-size: 24px;
  font-weight: 300;
  color: var(--gray-700);
  margin-bottom: 0;
`;

export const Label = styled.span`
  font-size: 16px;
  color: var(--gray-500);
`;

export const CancelButton = styled.button`
  margin-right: 16px;
`;
