import styled from 'styled-components';

export const Container = styled.div`
  .select-multiple__input-group {
    width: 100%;
  }

  .select-multiple__option {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
