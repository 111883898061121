import styled, { css } from 'styled-components';

type StyledThemeContainerProps = {
  disabled?: boolean;
};

type StyledContainerProps = {
  isReducedThemes?: boolean;
};

export const Container = styled.div<StyledContainerProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;

  ${({ isReducedThemes }) => css`
    width: ${isReducedThemes ? '100%' : '276px'};
  `}
`;

export const ThemeContainer = styled.label<StyledThemeContainerProps>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid var(--gray-300);
  background: var(--white);
  margin-bottom: 0;
  cursor: pointer;

  ${({ disabled }) => css`
    ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  `}
`;

export const Radio = styled.div`
  display: inline-flex;
  margin: 0;
`;

export const RadioLabel = styled.label`
  height: 24px;
`;

type StyledColorSampleProps = {
  color: string;
};

export const ColorSample = styled.div<StyledColorSampleProps>`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: 0.6px solid var(--gray-300);

  ${({ color }) => css`
    background-color: ${color};
  `}
`;
