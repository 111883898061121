import { HTMLAttributes, memo } from 'react';
import { useMediaCenter } from '@hooks/useMediaCenter';
import { BarActions } from './components/BarActions';
import { BarMessage } from './components/BarMessage';
import { Breadcrumbs } from './components/Breadcrumbs';
import { Container } from './styles';

export type InfoBarProps = HTMLAttributes<HTMLDivElement> & {
  displayMode?: MediaCenterDisplayMode;
};

const InfoBarComponent: React.FC<InfoBarProps> = ({
  displayMode = 'submenu',
  ...attrs
}) => {
  const { breadcrumbs, showInfoBar, selectedMediaLength, mediaListLength } =
    useMediaCenter();

  if (!showInfoBar) {
    return null;
  }

  return (
    <Container displayMode={displayMode} {...attrs}>
      {Boolean(selectedMediaLength) && <BarActions />}

      {breadcrumbs.length === 0 ? (
        <BarMessage
          selectedMediaLength={selectedMediaLength}
          searchResultLength={mediaListLength}
        />
      ) : (
        <Breadcrumbs />
      )}
    </Container>
  );
};

export const InfoBar = memo(InfoBarComponent);
