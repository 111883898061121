import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';

import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { useEngine } from '@hooks/useEngine';
import { Container } from './styles';
import { SavePopupModal } from './components/SavePopupModal';

export type SavePopupModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps;

export const SavePopupModifier: React.FC<SavePopupModifierProps> = ({
  uid,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { getElement } = useEngine();

  const [showModal, setShowModal] = useState(false);
  const [template, setTemplate] = useState<Template>();

  const handleClose = useCallback(() => {
    setTemplate(undefined);
    setShowModal(false);
  }, []);

  const handleSave = useCallback(
    (origin: string) => {
      const element = getElement(uid);

      if (element) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            custom_event_name: 'click_save_pop_up',
            event_details: origin,
          },
        });

        setTemplate(element);
        setShowModal(true);
      }
    },
    [getElement, uid]
  );

  useEffect(() => {
    const listener = () => handleSave('Icon');

    window.addEventListener(`save-popup-${uid}`, listener);

    return () => {
      window.removeEventListener(`save-popup-${uid}`, listener);
    };
  }, [handleSave, uid]);

  return (
    <Container {...attrs}>
      <button
        className="hot-button hot-button--secondary hot-button--sm"
        onClick={() => handleSave('Side_bar')}
      >
        <i className="fal fa-bookmark" />
        {t('sideMenu.adjustments.popup.saveButton')}
      </button>

      <SavePopupModal
        isOpen={showModal}
        template={template as Template}
        onClose={() => handleClose()}
      />
    </Container>
  );
};
