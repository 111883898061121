import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import {
  WhatsappShareButton,
  TelegramShareButton,
  FacebookShareButton,
} from 'react-share';
import { Icon } from '@components/Icon';
import {
  Container,
  Image,
  Title,
  Description,
  ActionsContainer,
  PrimaryButton,
  SecondaryButton,
  ModalFooterStyle,
  Divider,
  ShareButtons,
  ShareLabel,
} from './styles';

type AlertModalModalProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  isOpen: boolean;
  onClose?: () => void;
  primaryButtonLabel?: string;
  primaryButtonIcon?: string;
  secondaryButtonLabel?: string;
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  imageSrc?: string;
  imageSize?: { width?: number; height?: number };
  socialShareUrl?: string;
  fixedSize?: string;
  buttonsMinWidth?: string;
  closeIconAbsolute?: boolean;
};

const AlertModalComponent: React.FC<AlertModalModalProps> = ({
  isOpen,
  onClose,
  primaryButtonLabel,
  primaryButtonIcon,
  secondaryButtonLabel,
  title,
  description,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  imageSrc,
  imageSize,
  socialShareUrl,
  fixedSize,
  buttonsMinWidth,
  closeIconAbsolute,
  ...attrs
}) => {
  const { t } = useTranslation();

  const renderByTypeOf = useCallback(
    (value: string | React.ReactElement) =>
      typeof value === 'string' ? t(value) : value,
    [t]
  );

  return (
    <Container>
      <ModalFooterStyle />
      <BaseModal
        {...attrs}
        fixedSize={fixedSize || '428px'}
        isOpen={isOpen}
        closeIconAbsolute={closeIconAbsolute}
        header={
          <Image>
            <img
              src={imageSrc}
              width={imageSize?.width || 256}
              height={imageSize?.height || 256}
              alt="alert"
            />
          </Image>
        }
        footerClass="modal-warning-footer"
        footer={
          <ActionsContainer>
            <PrimaryButton
              className="hot-button hot-button--primary"
              onClick={onPrimaryButtonClick}
              minWidth={buttonsMinWidth}
            >
              {t(`${primaryButtonLabel}`)}
              {primaryButtonIcon && (
                <span>
                  <i className={primaryButtonIcon} />
                </span>
              )}
            </PrimaryButton>
            {secondaryButtonLabel && (
              <SecondaryButton
                className="hot-button hot-button--tertiary"
                onClick={onSecondaryButtonClick}
                minWidth={buttonsMinWidth}
              >
                {t(`${secondaryButtonLabel}`)}
              </SecondaryButton>
            )}
            {socialShareUrl && (
              <>
                <Divider />
                <ShareLabel>{t(`modals.publish.shareLabel`)}</ShareLabel>
                <ShareButtons>
                  <WhatsappShareButton
                    id="share-whatsapp"
                    url={socialShareUrl!}
                    title={t(`modals.publish.shareMessage`)}
                  >
                    <Icon name="whatsapp" size={32} />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    id="share-telegram"
                    url={socialShareUrl!}
                    title={t(`modals.publish.shareMessage`)}
                  >
                    <Icon name="telegram" size={32} />
                  </TelegramShareButton>
                  <FacebookShareButton
                    id="share-facebook"
                    url={socialShareUrl!}
                    title={t(`modals.publish.shareMessage`)}
                  >
                    <Icon name="facebook" size={32} />
                  </FacebookShareButton>
                </ShareButtons>
              </>
            )}
          </ActionsContainer>
        }
        onClose={onClose}
      >
        <Title>{renderByTypeOf(title)}</Title>
        {description && (
          <Description>{renderByTypeOf(description)}</Description>
        )}
      </BaseModal>
    </Container>
  );
};

export const AlertModal = memo(AlertModalComponent);
