import '@hotmart-org-ca/cosmos/dist/structure';
import '@hotmart-org-ca/cosmos/dist/content';
import '@hotmart-org-ca/cosmos/dist/menu';
import '@hotmart-org-ca/cosmos/dist/header';
import '@hotmart-org-ca/cosmos/dist/collapse';
import '@hotmart-org-ca/cosmos/dist/table/table.css';
import '@hotmart-org-ca/cosmos/dist/table/variations/bordered.css';
import '@hotmart-org-ca/cosmos/dist/table/variations/card.css';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/small.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/danger.css';
import '@hotmart-org-ca/cosmos/dist/styles/utilities/spacing/spacing.css';
import '@hotmart-org-ca/cosmos/dist/grid/grid.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { setAppLoading } from '@store/slices/application';
import i18n, { getLocaleLink } from '@i18n/index';
import { ListHeader } from '@components/ListHeader';
import { isValidRoutePermission } from '@routes/index';
import { redirectToErrorPage } from '@common/utils';
import PagesService from '@services/pages';
import Files from '@services/files';
import {
  Actions,
  EditButton,
  EmptyState,
  Loader,
  PageHeader,
  SearchInput,
  Table,
  TemplateAttr,
  TemplateName,
  Title,
} from './styles';
import { RemoveTemplateModal } from './components/RemoveTemplateModal';

const mode: EditorMode = 'template';

export const Templates: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState<Record<string, boolean>>(
    {}
  );
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [search, setSearch] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState('');
  const [templates, setTemplates] = useState<PageItem[]>([]);
  const permissions = useSelector((state: State) => state.userInfo.permissions);

  const dateFormatter = useMemo(() => {
    const options = {
      day: 'numeric' as 'numeric',
      month: 'numeric' as 'numeric',
      year: 'numeric' as 'numeric',
      hour: 'numeric' as 'numeric',
      minute: 'numeric' as 'numeric',
      hour12: false,
    };
    return new Intl.DateTimeFormat(i18n.language, options);
  }, []);

  const filteredTemplates = useMemo(() => {
    if (search) {
      return templates.filter(
        (template) =>
          template.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }
    return templates;
  }, [search, templates]);

  const getTemplates = useCallback(async () => {
    try {
      setTemplatesLoading(true);
      setTemplates(
        await PagesService.getTemplates(permissions.templateManagementEnabled)
      );
      setTemplatesLoading(false);
      dispatch(setAppLoading(false));
    } catch (e) {
      const error = e as ErrorResponse;
      if (error.response?.status === 403) {
        redirectToErrorPage('403');
      }
    }
  }, [dispatch, permissions]);

  const handleCloseModal = useCallback(() => {
    setShowRemoveModal(false);
    setCurrentTemplate('');
  }, []);

  const deleteTemplate = useCallback(async (uuid: string) => {
    setCurrentTemplate(uuid);
    setShowRemoveModal(true);
  }, []);

  const duplicateTemplate = useCallback(
    async ({ uuid: templateUuid, name }: PageItem) => {
      setIsDuplicating((state) => ({
        ...state,
        [templateUuid]: true,
      }));

      const files = await Files.getFiles(
        templateUuid,
        mode,
        permissions.templateManagementEnabled
      );
      const { uuid } = await PagesService.createPage(
        { name: `${name} - Cópia` },
        mode,
        permissions.templateManagementEnabled
      );

      await Files.createFiles(
        uuid,
        files,
        mode,
        permissions.templateManagementEnabled
      );
      await getTemplates();

      setIsDuplicating((state) => ({
        ...state,
        [templateUuid]: false,
      }));
    },
    [getTemplates, permissions.templateManagementEnabled]
  );

  const createTemplate = useCallback(async () => {
    setIsCreating(true);
    EditorEngineManager.newPage();
    const page = EditorEngineManager.getFinalPage();

    if (page) {
      const { uuid } = await PagesService.createPage(
        { name: 'Novo template' },
        mode,
        permissions.templateManagementEnabled
      );
      const [config] = Files.normalizePageConfig();

      await Files.createFiles(
        uuid,
        [
          {
            name: 'page',
            content: page,
          },
          {
            name: 'config',
            content: config,
          },
        ],
        mode,
        permissions.templateManagementEnabled
      );

      navigate(getLocaleLink(`/templates/${uuid}`));
    }
    setIsCreating(false);
  }, [navigate, permissions.templateManagementEnabled]);

  const init = useCallback(async () => {
    if (isValidRoutePermission(permissions)) {
      await getTemplates();
    } else {
      redirectToErrorPage('403');
    }
  }, [getTemplates, permissions]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const hotMenu = document.querySelector<HTMLElement>('hot-menu')!;
    const content = document.querySelector<HTMLElement>('hot-content')!;
    const menu = hotMenu.shadowRoot?.querySelector(
      '.hot-application__menu'
    ) as HTMLElement;

    content.style.marginLeft = '0px';
    menu.style.display = 'none';
  }, []);

  return (
    <hot-structure>
      <hot-menu slot="menu" role="menu">
        <ListHeader />
      </hot-menu>

      <hot-content slot="content">
        <div className='hot-container _py-5 _px-5"'>
          <PageHeader>
            <Title>Templates</Title>
            <button
              className={classNames('hot-button hot-button--primary', {
                'hot-button--loading': isCreating,
              })}
              onClick={createTemplate}
            >
              Criar template
            </button>
          </PageHeader>

          <SearchInput
            type="search"
            className="hot-form__input action-bar__input"
            id="search"
            placeholder="Buscar template"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(event.target.value)
            }
          />

          <Table className="hot-table hot-table--card hot-table--card-white hot-table--bordered">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Criado em</th>
                <th>Modificado em</th>
              </tr>
            </thead>

            <tbody>
              {filteredTemplates.map((template) => (
                <tr key={template.uuid}>
                  <td>
                    <TemplateName>{template.name}</TemplateName>
                  </td>
                  <td>
                    <TemplateAttr>
                      {dateFormatter.format(new Date(template.createdAt))}
                    </TemplateAttr>
                  </td>
                  <td>
                    <TemplateAttr>
                      {dateFormatter.format(new Date(template.updatedAt))}
                    </TemplateAttr>
                  </td>
                  <td>
                    <Actions>
                      <Link to={getLocaleLink(`/templates/${template.uuid}`)}>
                        <EditButton className="hot-button hot-button--sm hot-button--primary">
                          Editar
                          <i className="far fa-pen" />
                        </EditButton>
                      </Link>

                      {permissions.templateManagementEnabled && (
                        <>
                          <button
                            className={classNames(
                              'hot-button hot-button--sm hot-button--primary',
                              {
                                'hot-button--loading':
                                  isDuplicating[template.uuid],
                              }
                            )}
                            onClick={() => duplicateTemplate(template)}
                          >
                            Duplicar
                          </button>

                          <button
                            className="hot-button hot-button--sm hot-button--danger"
                            onClick={() => deleteTemplate(template.uuid)}
                          >
                            Excluir
                          </button>
                        </>
                      )}
                    </Actions>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {!filteredTemplates.length && !templatesLoading && (
            <EmptyState>
              {search
                ? 'Nenhum template encontrado'
                : 'Nenhum template cadastrado'}
            </EmptyState>
          )}

          {templatesLoading && (
            <Loader>
              <hot-loading />
            </Loader>
          )}
        </div>
      </hot-content>

      <RemoveTemplateModal
        isOpen={showRemoveModal}
        uuid={currentTemplate}
        onClose={handleCloseModal}
        onDelete={getTemplates}
      />
    </hot-structure>
  );
};
