import styled, { css } from 'styled-components';

type StyledFooterProps = {
  isFixed?: boolean;
  padding?: string;
};

export const Container = styled.div<StyledFooterProps>`
  background-color: #ffffff;
  z-index: 10;
  bottom: 0;
  left: 0;
  border-top: var(--structure-border-width, 0.0625rem) solid
    var(--structure-header-border-color, #e6e9ed);

  ${({ isFixed, padding }) => css`
    ${isFixed && 'position: sticky;'}
    padding: ${isFixed ? padding : '0px 24px'};
  `}
`;

export const Content = styled.div`
  display: flex;
  min-height: 70px;
`;

export const LeftSlot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex: 1;
  overflow: hidden;
`;

export type StyledCenterSlotProps = {
  maxWidth: string;
};

export const CenterSlot = styled.div<StyledCenterSlotProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  ${({ maxWidth }) => css`
    max-width: ${maxWidth};
  `}
`;

export const RightSlot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 16px;
`;
