import styled from 'styled-components';

export const StepContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const FormInputsContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 0;
`;

export const EditButton = styled.button`
  width: 30px;
  height: 30px;
  margin-left: 8px;
  padding: 0;
  font-size: 12px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
