import Token, { TokenKinds } from '@services/token';
import Http from '@services/http';
import PublicHttp from '@services/public-http';
import { rebrand } from '@config/rebrand';
import { removeEmojis } from '@common/utils';

export default class User {
  private static http = new Http();

  private static publicHttp = new PublicHttp();

  static getUserPublicId() {
    // eslint-disable-next-line camelcase
    return this.http.get<{ public_id: string }>(
      `${rebrand.urls.KLICKART_URL}/v1/api/user/public-id`
    );
  }

  static getUserPagesInfo() {
    return this.http.get<UserPagesInfo>(
      `${rebrand.urls.KLICKART_URL}/v1/api/user_pages_info`
    );
  }

  public static getUserFrequencyAccessInfo() {
    return this.http.get<{
      subscription?: UserFrequencySubscription;
      paymentInfo?: UserFrequencyPayment;
    }>(`${rebrand.urls.KLICKART_URL}/v1/api/user/frequency/access`);
  }

  static async refreshAuth() {
    const { token } = await this.http.post<{ token: string }>(
      Token.refreshTokenUrl,
      {
        application: 'editor',
        token_kind: TokenKinds['user-auth-token'],
      }
    );

    return token;
  }

  static async getUserPublicProfile(id: string) {
    try {
      const user = await this.publicHttp.get<UserPublicProfile>(
        `${process.env.REACT_APP_HOTMART_DISPLAY_API}/back/rest/v2/hotmarter?id=${id}`
      );
      return { ...user, biography: removeEmojis(user.biography) };
    } catch {
      return null;
    }
  }
}
