import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_PATH } from '@common/constants';
import Token from '@services/token';
import { bootstrap, casEnabled } from '@auth/authService';

export const StartLogoutPage = () => {
  const navigate = useNavigate();
  const casLogout = useCallback(async () => {
    await bootstrap.logout();
    navigate({ pathname: LOGOUT_PATH }, { replace: true });
  }, [navigate]);

  useEffect(() => {
    Token.remove();

    if (casEnabled()) {
      casLogout();
    } else {
      navigate({ pathname: LOGOUT_PATH }, { replace: true });
    }
  }, [casLogout, navigate]);

  return null;
};
