import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedSections from '@services/savedSections';

export const createUserSection = createAsyncThunk<
  PageFragmentListItem[],
  Omit<PageFragmentListItem, 'id'>,
  {
    state: LocalState;
  }
>('pageFragments/createUserSection', async (section) => {
  try {
    await SavedSections.create(section);

    const userSections = await SavedSections.get();

    return userSections;
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const createUserSectionBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(createUserSection.pending, (state: PageFragmentsState) => {
    state.sectionLoading = true;
  });
  builder.addCase(
    createUserSection.fulfilled,
    (state: PageFragmentsState, action) => {
      state.userSections = action.payload;
      state.sectionLoading = false;
    }
  );
  builder.addCase(createUserSection.rejected, (state: PageFragmentsState) => {
    state.sectionLoading = false;
  });
};
