import clone from 'clone';
import i18next from 'i18next';
import { v4 as uuid } from 'uuid';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { hexToRgb } from '@common/utils';

const getText = (text: string, color: string, isMobile?: boolean) =>
  isMobile
    ? `<p style="text-align: center; line-height: 1.15;"><span style="color: ${color}; font-family: Montserrat, sans-serif; font-size: 20px;">${text}</span></p>`
    : `<p style="text-align: center; line-height: 1.15;"><span style="color: ${color}; font-family: Raleway, sans-serif; font-size: 24px;">${text}</span></p>`;

export const getDefaultProperties = () => ({
  pageName: i18next.t('pageWizard.publishPage.pageName'),
  name: i18next.t('pageWizard.publishPage.name.placeholder'),
  imgSrc:
    '//static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg',
  colorsConfig: [
    {
      id: 'sectionBackground',
      name: 'background',
      label: 'pageWizard.publishPage.colors.sectionBackground',
      color: '#FF00B2',
    },
    {
      id: 'nameTextColor',
      name: 'color',
      label: 'pageWizard.publishPage.colors.nameTextColor',
      color: '#FFFFFF',
    },
    {
      id: 'buttonBackground',
      name: 'background-color',
      label: 'pageWizard.publishPage.colors.buttonBackground',
      color: '#FFFFFF',
    },
    {
      id: 'buttonColor',
      name: 'color',
      label: 'pageWizard.publishPage.colors.buttonColor',
      color: '#00B1CC',
    },
  ],
  links: [
    {
      id: `ls-${uuid()}`,
      href: '',
      label: 'LINK 01',
    },
    {
      id: `ls-${uuid()}`,
      href: '',
      label: 'LINK 02',
    },
    {
      id: `ls-${uuid()}`,
      href: '',
      label: 'LINK 03',
    },
    {
      id: `ls-${uuid()}`,
      href: '',
      label: 'LINK 04',
    },
  ],
});

export const getTemplateWithProps = (
  template: Template,
  properties: LinkTreeProperties
) => {
  const updatedTemplate = clone(template);
  const { name, imgSrc, colorsConfig, links } = properties;
  const { children } = updatedTemplate.children![0].children![0].children![0];
  const colors: Record<string, string> = colorsConfig.reduce(
    (acc, current) => ({ ...acc, [current.id]: current.color }),
    {}
  );
  const textColor = hexToRgb(colors.nameTextColor);
  const elements: Template[] = [];
  const defaultImage = children!.find(
    (element) => element.component === 'ls-image'
  ) as Template;
  const defaultText = children!.find(
    (element) => element.component === 'ls-text'
  ) as Template;
  const defaultButton = children!.find(
    (element) => element.component === 'ls-button'
  ) as Template;

  updatedTemplate.children![0].background = colors.sectionBackground;

  elements.push({
    ...defaultImage,
    src: imgSrc,
  });

  elements.push({
    ...defaultText,
    text: getText(name, textColor),
    mobile: { text: getText(name, textColor, true) },
  });

  links.forEach(({ id, href, label }) => {
    elements.push({
      ...defaultButton,
      uid: id,
      href,
      label,
      background: colors.buttonBackground,
      color: colors.buttonColor,
    });
  });

  updatedTemplate.children![0].children![0].children![0].children = elements;

  return updatedTemplate;
};
