import React from 'react';
import { useTranslation } from 'react-i18next';
import { SEO } from './components/SEO';
import { Indexation } from './components/Indexation';
import { Favicon } from './components/Favicon';
import { MadeWithKlickpages } from './components/MadeWithKlickpages';
import { Divider } from './styles';
import { Submenu } from '../../../Submenu';
import { ShareImage } from './components/ShareImage';

export const SeoSubmenu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Submenu title={t('sideMenu.settings.seo')} padding="0">
      <SEO />
      <Divider />
      <Indexation />
      <Divider />
      <ShareImage />
      <Favicon />
      <Divider />
      <MadeWithKlickpages />
    </Submenu>
  );
};
