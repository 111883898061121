import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/custom.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/danger.css';

import { HTMLAttributes, memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Toast from '@services/toast';
import { BaseModal } from '@components/BaseModal';
import { usePageConfig } from '@hooks/usePageConfig';
import { useMenu } from '@hooks/useMenu';
import { Description, ModalTitle, CancelButton } from './styles';

type RemoveUrlParamModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose?: () => void;
};

const RemoveUrlParamModalComponent: React.FC<RemoveUrlParamModalProps> = ({
  isOpen,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();

  const { deleteUrlParam } = usePageConfig();
  const { selectedUrlParam, urlParamMenuLoading } = useMenu();

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDeleteScript = useCallback(async () => {
    try {
      await deleteUrlParam();
      Toast.success('urlParams.modal.deleteSuccess');
    } catch (error) {
      Toast.error('urlParams.modal.deleteError');
    } finally {
      handleCloseModal();
    }
  }, [deleteUrlParam, handleCloseModal]);

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      header={
        <ModalTitle>
          <Trans
            i18nKey="urlParams.modal.title"
            components={{ b: <b /> }}
            values={{ name: selectedUrlParam.name }}
          />
        </ModalTitle>
      }
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t('urlParams.modal.cancel')}
          </CancelButton>
          <button
            onClick={handleDeleteScript}
            className={classNames('hot-button hot-button--danger', {
              'hot-button--loading': urlParamMenuLoading,
            })}
          >
            {t('urlParams.modal.delete')}
          </button>
        </>
      }
    >
      <Description>{t('urlParams.modal.description')}</Description>
    </BaseModal>
  );
};

export const RemoveUrlParamModal = memo(RemoveUrlParamModalComponent);
