import '@hotmart-org-ca/cosmos/dist/dropdown';
import '@hotmart-org-ca/cosmos/dist/select-multiple';

import React, {
  useEffect,
  useRef,
  useCallback,
  HTMLAttributes,
  memo,
} from 'react';
import { Container } from './styles';
import { Label } from '../Label';

export type SelectOption = {
  id: string;
  value: string;
  label: string;
};

export type SelectProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  id: string | number | null;
  label?: string;
  infoTooltip?: {
    title?: string;
    description?: string;
    displayMode?: 'hover' | 'always';
  };
  options: SelectOption[];
  placeholder?: string;
  selectedOptions?: string[];
  action?: React.ReactNode;
  prepend?: React.ReactNode;
  disabled?: boolean;
  onChange?: (values: string[]) => void;
};

const SelectMultipleComponent: React.FC<SelectProps> = ({
  id,
  label,
  infoTooltip,
  placeholder,
  options,
  selectedOptions = [],
  action,
  prepend,
  disabled = undefined,
  onChange,
  ...attrs
}) => {
  const selectEl = useRef<HTMLElement>(null);

  const changeListener = useCallback(
    (e: Event) => {
      const selectedValues = (e as CustomEvent).detail as {
        text: string;
        value: string;
      }[];
      onChange?.(selectedValues.map((item) => item.value));
    },
    [onChange]
  );

  const isSelected = useCallback(
    (value: string) => selectedOptions.some((option) => option === value),
    [selectedOptions]
  );

  useEffect(() => {
    const hotSelectRef = selectEl.current;
    hotSelectRef?.addEventListener('change', changeListener);

    return () => {
      hotSelectRef?.removeEventListener('change', changeListener);
    };
  }, [changeListener]);

  useEffect(() => {
    const hotSelectRef = selectEl.current;

    if (hotSelectRef) {
      const dropdown = hotSelectRef.shadowRoot?.querySelector('hot-dropdown');

      if (dropdown) {
        const input = dropdown.querySelector('input');

        if (input) {
          input.setAttribute('autocomplete', 'off');
          input.style.textOverflow = 'ellipsis';
        }
      }
    }
  }, []);

  return (
    <Container className="hot-form" {...attrs}>
      <Label label={label} infoTooltip={infoTooltip} append={action} />
      <hot-input-group>
        {prepend && (
          <div className="hot-input-group__prepend hot-input-group__prepend--sm">
            {prepend}
          </div>
        )}

        <hot-select-multiple
          data-prevent-delete
          id={id}
          class="select-multiple__input-group"
          ref={selectEl}
          placeholder={placeholder}
          disabled={disabled || undefined}
        >
          {options.map((option) => (
            <hot-select-multiple-option
              className="select-multiple__option"
              key={option.id}
              value={option.value}
              {...(isSelected(option.value) ? { selected: true } : {})}
            >
              {option.label}
            </hot-select-multiple-option>
          ))}
        </hot-select-multiple>
      </hot-input-group>
    </Container>
  );
};

export const SelectMultiple = memo(SelectMultipleComponent);
