import { rebrand } from '@config/rebrand';
import Http from '../http';

export default class SupportChat {
  private static Http = new Http();

  public static async getChat() {
    return this.Http.get<SupportChatResponse>(
      `${rebrand.urls.KLICKART_URL}/v1/api/support_chats`
    );
  }
}
