import styled from 'styled-components';

export const CheckBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 0 8px;
`;
