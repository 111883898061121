import '@hotmart-org-ca/cosmos/dist/loading';

import { HTMLAttributes } from 'react';
import { LoaderWrapper } from './styles';

export const CosmosLoader: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => (
  <LoaderWrapper {...attrs}>
    <hot-loading />
  </LoaderWrapper>
);
