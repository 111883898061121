import '@hotmart-org-ca/cosmos/dist/loading';
import { HTMLAttributes, memo } from 'react';
import { StyledLoader } from './styles';

export type LoaderProps = HTMLAttributes<HTMLDivElement> & {
  loading?: boolean;
};

const LoaderComponent: React.FC<LoaderProps> = ({ loading, ...attrs }) => (
  <StyledLoader isLoading={loading} {...attrs}>
    <hot-loading />
  </StyledLoader>
);

export const Loader = memo(LoaderComponent);
