import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BaseModal } from '@components/BaseModal';
import { useSavedPopups } from '@hooks/useSavedPopups';
import Toast from '@services/toast';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { useEngine } from '@hooks/useEngine';
import TagManager from 'react-gtm-module';
import { Label, ModalTitle, CancelButton, ModalCustomStyle } from './styles';

type SavePopupModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  template: Template;
  onClose: () => void;
};

const SavePopupModalComponent: React.FC<SavePopupModalProps> = ({
  isOpen,
  onClose,
  template,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { userPopups, createUserPopup, loading } = useSavedPopups();
  const { getTemplateWithDefaults } = useEngine();

  const [popupName, setPopupName] = useState('');
  const [isNameRepeated, setIsNameRepeated] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose?.();
    setPopupName('');
  }, [onClose]);

  const handleSavePopup = useCallback(async () => {
    const popup = {
      template: getTemplateWithDefaults(template),
      name: popupName,
    };

    try {
      await createUserPopup(popup);
      handleCloseModal();
      setPopupName('');

      const title = t('toastMessages.savePopupModal.saved.success');
      const description = t(
        'toastMessages.savePopupModal.saved.successDescription'
      );
      const link = t('toastMessages.savePopupModal.saved.openSavedPopups');

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_save_popup_confirm',
          event_details: undefined,
        },
      });

      const customEventName = 'toast-open-menu';
      const customEventValue = JSON.stringify({
        detail: {
          menu: 'models-list',
          submenu: ['popups-submenu', 'saved-popups'],
          customEventName: 'click_open_saved_popups',
        },
      }).replace(/"/g, "'");

      Toast.success(
        `<b>${title}</b><br>${description}<br><br><a href='#' onclick="window.dispatchEvent(new CustomEvent('${customEventName}', ${customEventValue}))">${link}</a>`
      );
    } catch {
      Toast.error(t('toastMessages.savePopupModal.saved.error'));
    }
  }, [
    getTemplateWithDefaults,
    handleCloseModal,
    popupName,
    createUserPopup,
    t,
    template,
  ]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPopupName(value);

      const nameRepeated = userPopups?.some(
        (section) => section.name === value
      );

      setIsNameRepeated(Boolean(nameRepeated));
    },
    [userPopups]
  );

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      headerClass="save-section-modal-header"
      footerClass="save-section-modal-footer"
      header={<ModalTitle>{t(`modals.savePopups.title`)}</ModalTitle>}
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t('modals.savePopups.cancelButton')}
          </CancelButton>
          <button
            onClick={handleSavePopup}
            className={classNames('hot-button hot-button--primary', {
              'hot-button--disabled': isNameRepeated,
              'hot-button--loading': loading,
            })}
          >
            {t(`modals.savePopups.saveButton`)}
          </button>
        </>
      }
    >
      <ModalCustomStyle />
      <>
        <Label> {t(`modals.savePopups.inputTitle`)}</Label>{' '}
        <input
          id="saveSectionName"
          className={classNames('hot-form__input', {
            'is-invalid': isNameRepeated,
          })}
          placeholder={t(`modals.savePopups.inputPlaceholder`)}
          value={popupName}
          onChange={(event) => handleInputChange(event)}
          data-prevent-delete
        />
        {isNameRepeated && (
          <div className="invalid-feedback">
            {t(`modals.savePopups.invalidNameError`)}
          </div>
        )}
      </>
    </BaseModal>
  );
};

export const SavePopupModal = memo(SavePopupModalComponent);
