import { Icon } from '@components/Icon';
import styled from 'styled-components';

const transition = 'all ease 0.3s';

export const IconBox = styled.div`
  width: 100%;
  height: 74px;
  background: var(--gray-100);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${transition};
`;

export const LockContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LockIconBox = styled.svg`
  font-size: 28px;
  height: 32px;
  display: none;
`;

export const ElementIcon = styled(Icon)`
  opacity: 1;
  transition: ${transition};
`;

export const LockIcon = styled.svg`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 6px;
  right: 7px;
  transition: ${transition};
`;

export const Container = styled.div`
  width: calc(33.3333% - 8px);

  :hover {
    ${ElementIcon} {
      opacity: 0;
      transition: ${transition};
    }

    ${LockIcon} {
      top: 22px;
      right: 25px;
      font-size: 28px;
      height: 32px;
      transition: ${transition};
    }

    ${IconBox} {
      background: var(--blue-lightest);

      svg * {
        transition: ${transition};
        fill: var(--blue);
      }
    }
  }
`;

export const Title = styled.span`
  font-size: 12px;
  margin: 8px 0;
  color: var(--gray-700);
`;
