import i18n from 'i18next';

export const sectionsCategories = <{ id: string; label: string }[]>[
  { id: 'header', label: 'sideMenu.sectionsTemplate.categories.header' },
  {
    id: 'showcase',
    label: 'sideMenu.sectionsTemplate.categories.showcase',
  },
  {
    id: 'capture',
    label: 'sideMenu.sectionsTemplate.categories.capture',
  },
  { id: 'list', label: 'sideMenu.sectionsTemplate.categories.list' },
  { id: 'cta', label: 'sideMenu.sectionsTemplate.categories.cta' },
  { id: 'about', label: 'sideMenu.sectionsTemplate.categories.about' },
  { id: 'faq', label: 'sideMenu.sectionsTemplate.categories.faq' },
  {
    id: 'depositions',
    label: 'sideMenu.sectionsTemplate.categories.depositions',
  },
  {
    id: 'thankYou',
    label: 'sideMenu.sectionsTemplate.categories.thankYou',
  },
  { id: 'bonus', label: 'sideMenu.sectionsTemplate.categories.bonus' },
  { id: 'footer', label: 'sideMenu.sectionsTemplate.categories.footer' },
  { id: 'video', label: 'sideMenu.sectionsTemplate.categories.video' },
  {
    id: 'linkInBio',
    label: 'sideMenu.sectionsTemplate.categories.linkInBio',
  },
  {
    id: 'content',
    label: 'sideMenu.sectionsTemplate.categories.content',
  },
  {
    id: 'warranty',
    label: 'sideMenu.sectionsTemplate.categories.warranty',
  },
];

export const getSortedCategories = () => {
  const sortedCategories = sectionsCategories
    .slice()
    .sort((currentCategory, nextCategory) => {
      const currentName = i18n.t(currentCategory.label).toUpperCase();
      const nextName = i18n.t(nextCategory.label).toUpperCase();
      if (currentName === nextName) {
        return 0;
      }
      return currentName > nextName ? 1 : -1;
    });

  sortedCategories.unshift({
    id: 'savedSections',
    label: i18n.t('sideMenu.sectionsTemplate.savedSections'),
  });

  return sortedCategories;
};
