import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import application from './slices/application';
import menu from './slices/menu';
import pageConfig from './slices/pageConfig';
import userInfo from './slices/userInfo';
import pageInfo from './slices/pageInfo';
import mediaCenter from './slices/mediaCenter';
import pageScripts from './slices/pageScripts';
import useCases from './slices/useCases';
import pageFragments from './slices/pageFragments';
import checkout from './slices/checkout';

const rootReducer = {
  application,
  menu,
  pageConfig,
  pageInfo,
  userInfo,
  mediaCenter,
  pageScripts,
  useCases,
  pageFragments,
  checkout,
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;

export { rootReducer };
