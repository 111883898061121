import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 16px;
  gap: 4px;
`;

export const Slash = styled.span`
  color: var(--gray-500);
  opacity: 0.5;

  &.active-folder {
    opacity: 1;
  }
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  opacity: 0.5;
  color: var(--gray-500);
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    opacity: 1;
    color: var(--primary-dark);
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:first-of-type {
    width: auto;
    overflow: unset;
    padding-left: 0;
  }

  &.active-folder {
    padding-right: 0;
    opacity: 1;
    pointer-events: none;
    cursor: unset;
    color: var(--gray-700);
  }
`;

export const IconWrapper = styled.span`
  color: var(--primary-dark);
`;
