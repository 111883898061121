import styled, { css } from 'styled-components';

type StyledContainerProps = {
  showInfoBar?: boolean;
  selectedMedia?: boolean;
};

type StyledMediaProps = {
  isSelectable?: boolean;
  isFolder?: boolean;
};

type StyledContainerPreviewProps = {
  isNotFoundMedia?: boolean;
  isSelectable?: boolean;
};

export const Media = styled.div<StyledMediaProps>`
  ${({ isSelectable, isFolder }) => css`
    cursor ${isSelectable || isFolder ? 'pointer' : 'move'};
    width: ${isSelectable ? '150px' : '100%'};
  `}
`;

const getContainerPadding = (selected?: boolean) =>
  selected ? '108px' : '36px';

export const Container = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;

  hot-loading {
    margin: 0 auto;
  }

  ${({ showInfoBar, selectedMedia }) => css`
    padding-bottom: ${showInfoBar ? getContainerPadding(selectedMedia) : '0'};
  `}
`;

export const NotFoundMedia = styled.label`
  color: var(--gray-600);
  font-size: 12px;
`;

export const ContainerPreview = styled.div<StyledContainerPreviewProps>`
  display: grid;
  grid-template-columns: calc(100% / 3 - 5px) calc(100% / 3 - 5px) calc(
      100% / 3 - 5px
    );
  gap: 8px;

  ${({ isNotFoundMedia, isSelectable }) => css`
    display: ${isNotFoundMedia || isSelectable ? 'flex' : 'grid'};
    ${isSelectable &&
    css`
      flex-wrap: wrap;
      gap: 16px;
    `}
  `}
`;

export const Label = styled.label`
  color: var(--gray-600);
  font-size: 12px;
  height: 28px;
`;

export const MediaNameContainer = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
`;

export const MediaNameContent = styled.label`
  color: var(--gray-500);
  margin: 0;
  display: flex;
  font-size: 12px;
  justify-content: left;
  width: 100%;
`;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Extension = styled.div``;

export const Content = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  position: relative;
  width: 100%;
`;

export const Divider = styled.hr`
  margin: var(--spacer-2) 0;
  border-color: var(--gray-200);
`;
