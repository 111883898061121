import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/success.css';
import '@hotmart-org-ca/cosmos/dist/loading';
import '@hotmart-org-ca/cosmos/dist/list-group/list-group.css';

import classNames from 'classnames';
import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import MediaCenter from '@services/mediaCenter';
import Toast from '@services/toast';
import { useMediaCenter } from '@hooks/useMediaCenter';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import {
  FolderContainer,
  Icon,
  ModalBodyStyle,
  FolderName,
  Arrow,
} from './styles';

type MoveMediaModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen?: boolean;
  onClose?: () => void;
};

const MoveMediaModalComponent: React.FC<MoveMediaModalProps> = ({
  isOpen = false,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();

  const { selectedMedia, moveMedia } = useMediaCenter();

  const [folderList, setFolderList] = useState<FolderTree>();
  const [selectedFolder, setSelectedFolder] = useState<FolderTree>();
  const [loading, setLoading] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const hasSelectedMedia = useMemo(
    () => Object.keys(selectedMedia).length,
    [selectedMedia]
  );

  const getFolderTree = useCallback(
    (parentId: string | null, parent: FolderTree, rootFolders: Folder[]) => {
      const remainingFolders = rootFolders.filter(
        (item: Folder) => item.parentId !== parentId
      );

      return rootFolders
        .filter((item: Folder) => item.parentId === parentId)
        .map(({ id, name }: Folder) => {
          const tree: FolderTree = {
            id,
            name,
            parent,
            folders: [],
          };
          tree.folders = getFolderTree(id, tree, remainingFolders);

          return tree;
        });
    },
    []
  );

  const getFolders = useCallback(async () => {
    try {
      setLoading(true);
      setSelectedFolder(undefined);
      setFolderList(undefined);

      const selectedFolderIds = Object.values(selectedMedia).reduce<string[]>(
        (acc, mediaItem) =>
          mediaItem.type === 'media_folder' ? [...acc, mediaItem.id] : acc,
        []
      );
      const folders = await MediaCenter.getRootFolders();
      const foldersWithoutSelected = folders
        .filter((folder) => !selectedFolderIds.includes(folder.id))
        .sort((item: Folder, nextItem: Folder) =>
          item.name.toLowerCase() > nextItem.name.toLowerCase() ? 1 : -1
        );

      const folderTree: FolderTree = {
        id: 'home',
        name: t('mediaCenter.infoBar.moveModal.body.breadcrumbHome'),
        parent: undefined,
      };

      folderTree.folders = getFolderTree(
        null,
        folderTree,
        foldersWithoutSelected
      );

      setSelectedFolder(folderTree);
      setFolderList(folderTree);
    } finally {
      setLoading(false);
    }
  }, [getFolderTree, selectedMedia, t]);

  const selectItem = useCallback((folder: FolderTree) => {
    setSelectedFolder(folder);
  }, []);

  const handleOpenFolder = useCallback((folder: FolderTree) => {
    setSelectedFolder(folder);
    setFolderList(folder);
  }, []);

  const handleMoveMedia = useCallback(async () => {
    setIsMoving(true);
    try {
      await moveMedia(selectedFolder?.id);
      Toast.success('toastMessages.media.success.moved');
    } catch {
      Toast.error('toastMessages.media.error.moved');
    } finally {
      setIsMoving(false);
      onClose?.();
    }
  }, [moveMedia, onClose, selectedFolder]);

  useEffect(() => {
    if (isOpen && hasSelectedMedia) {
      getFolders();
    }
  }, [getFolders, isOpen, hasSelectedMedia]);

  return (
    <>
      <ModalBodyStyle />
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        contentScroll
        header={
          <Header
            onBreadcrumbClick={handleOpenFolder}
            folderList={folderList}
          />
        }
        footer={
          <Footer
            isMoving={isMoving}
            selectedFolder={selectedFolder}
            onMoveMedia={handleMoveMedia}
            onClose={onClose}
          />
        }
        size="small"
        bodyClass="move-media-modal__body"
        {...attrs}
      >
        {loading ? <hot-loading /> : ''}

        {isOpen && Boolean(folderList) && (
          <hot-list-group>
            {folderList?.folders?.map((item) => (
              <hot-list-group-item
                key={item.id}
                onClick={() => selectItem(item)}
                active={selectedFolder?.id === item.id ? true : null}
              >
                <FolderContainer>
                  <i
                    className={classNames(
                      'fal',
                      `${item.folders?.length ? 'fa-folders' : 'fa-folder'}`
                    )}
                  />

                  <FolderName>{item.name}</FolderName>

                  {Boolean(item.folders?.length) && (
                    <hot-tooltip
                      content={t(
                        'mediaCenter.infoBar.moveModal.body.accessFolder'
                      )}
                      position="top"
                    >
                      <Arrow onClick={() => handleOpenFolder(item)}>
                        <Icon className="fal fa-arrow-right" />
                      </Arrow>
                    </hot-tooltip>
                  )}
                </FolderContainer>
              </hot-list-group-item>
            ))}
          </hot-list-group>
        )}
      </BaseModal>
    </>
  );
};

export const MoveMediaModal = memo(MoveMediaModalComponent);
