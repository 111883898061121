import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';

import { HTMLAttributes, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ComponentState } from '@hotmart-org-ca/saas-pages-engine';
import { useUserInfo } from '@hooks/useUserInfo';
import { useCheckout } from '@hooks/useCheckout';
import { ExternalLink } from '@components/ExternalLink';
import { Button, Container, ProductInfo, ProductInfoLabel } from './styles';
import { CheckoutModifierModalSet } from '../CheckoutModifierModalSet';

export type TransparentCheckoutModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps;

const TransparentCheckoutModifierComponent: React.FC<TransparentCheckoutModifierProps> =
  ({ uid, ...attrs }) => {
    const { t } = useTranslation();
    const { isExtensions } = useUserInfo();
    const { initializeCheckoutIntegration } = useCheckout();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showSelectProductModal, setShowSelectProductModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const elementState = useSelector<State, ComponentState>(
      (state) => state[uid] as ComponentState
    );
    const hasSelectedProduct = useMemo(
      () =>
        elementState.metadata?.checkout?.productId &&
        elementState.metadata?.checkout?.offerId,
      [elementState.metadata?.checkout]
    );

    const handleOpenModal = useCallback(async () => {
      setButtonLoading(true);

      const { isProductListEmpty } = await initializeCheckoutIntegration();

      if (!isExtensions || isProductListEmpty) {
        setShowAlertModal(true);
      } else {
        setShowSelectProductModal(true);
      }
      setButtonLoading(false);
    }, [initializeCheckoutIntegration, isExtensions]);

    return (
      <Container {...attrs}>
        <div>
          <ProductInfo>
            {hasSelectedProduct ? (
              <>
                <div>
                  <ProductInfoLabel>
                    {t('modifiers.checkout.chosenProduct')}
                  </ProductInfoLabel>
                  <input
                    className="hot-form__input hot-form__input--sm"
                    type="text"
                    value={elementState.metadata.checkout.productName}
                    readOnly
                  />
                </div>
                <div>
                  <ExternalLink
                    href={elementState.metadata.checkout.offerUrl}
                    text={t('modifiers.checkout.offerLink')}
                    openInNewTab
                  />
                </div>
              </>
            ) : (
              t('modifiers.checkout.noProductSelected')
            )}
          </ProductInfo>
        </div>

        <Button
          className={classNames(
            'hot-button hot-button--secondary hot-button--sm',
            {
              'hot-button--loading': buttonLoading,
            }
          )}
          onClick={handleOpenModal}
        >
          {t(
            `modifiers.checkout.${
              hasSelectedProduct ? 'changeProduct' : 'selectProduct'
            }`
          )}
        </Button>

        <CheckoutModifierModalSet
          modifierType="transparentCheckout"
          isAlertModalOpen={showAlertModal}
          isProductModalOpen={showSelectProductModal}
          uid={uid}
          onAlertModalClose={() => setShowAlertModal(false)}
          onProductModalClose={() => setShowSelectProductModal(false)}
        />
      </Container>
    );
  };

export const TransparentCheckoutModifier = memo(
  TransparentCheckoutModifierComponent
);
