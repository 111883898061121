import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import '@hotmart-org-ca/cosmos/dist/input-group/input-group.css';
import '@hotmart-org-ca/cosmos/dist/dropdown';
import '@hotmart-org-ca/cosmos/dist/select';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/tooltip';

import React, {
  useEffect,
  useRef,
  useCallback,
  HTMLAttributes,
  memo,
  useMemo,
} from 'react';
import { Container } from './styles';
import { Label } from '../Label';

export type SelectOption = {
  id: string | number;
  value: string;
  label: string;
};

export type SelectProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  id: string | number | null;
  label?: string;
  infoTooltip?: {
    title?: string;
    description?: string;
    displayMode?: 'hover' | 'always';
  };
  options: SelectOption[];
  placeholder?: string;
  selectedOption?: string | number;
  size?: 'sm' | 'md';
  action?: React.ReactNode;
  prepend?: React.ReactNode;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

const SelectComponent: React.FC<SelectProps> = ({
  id,
  label,
  infoTooltip,
  placeholder,
  options,
  selectedOption,
  size = 'sm',
  action,
  prepend,
  disabled = undefined,
  onChange,
  ...attrs
}) => {
  const selectEl = useRef<HTMLElement>(null);

  const changeListener = useCallback(
    (e: Event) => {
      const { value } = (e as CustomEvent).detail;
      onChange?.(value);
    },
    [onChange]
  );

  useEffect(() => {
    const hotSelectRef = selectEl.current;
    hotSelectRef?.addEventListener('change', changeListener);

    return () => {
      hotSelectRef?.removeEventListener('change', changeListener);
    };
  }, [changeListener]);

  useEffect(() => {
    const hotSelectRef = selectEl.current;

    if (hotSelectRef) {
      const dropdown = hotSelectRef.shadowRoot?.querySelector('hot-dropdown');

      if (dropdown) {
        const input = dropdown.querySelector('input');

        if (input) {
          input.setAttribute('autocomplete', 'off');
          input.style.textOverflow = 'ellipsis';
          const labelSelected = selectedOption
            ? options.find((option) => option.value === selectedOption)
                ?.label || ''
            : '';
          input.value = labelSelected;
        }
      }
    }
  }, [options, selectedOption]);

  const classList = useMemo(() => size, [size]);

  return (
    <Container className="hot-form" {...attrs}>
      <Label label={label} infoTooltip={infoTooltip} append={action} />
      <hot-input-group>
        {prepend && (
          <div className="hot-input-group__prepend hot-input-group__prepend--sm">
            {prepend}
          </div>
        )}

        <hot-select
          data-prevent-delete
          id={id}
          class={`hot-select--${classList} select__input-group`}
          ref={selectEl}
          placeholder={placeholder}
          disabled={disabled || undefined}
        >
          {options.map((option) => (
            <hot-select-option
              key={option.id}
              class="select__option"
              value={option.value}
              {...(option.value === selectedOption ? { selected: true } : {})}
            >
              {option.label}
            </hot-select-option>
          ))}
        </hot-select>
      </hot-input-group>
    </Container>
  );
};

export const Select = memo(SelectComponent);
