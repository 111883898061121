import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  padding: 16px 24px;
  border-bottom: 0.0625rem solid var(--gray-200);
  font-size: 12px;
  display: flex;
  justify-content: space-between;

  .hot-button {
    border: none;

    &--danger {
      background-color: var(--red-lightest);
      color: var(--red);
    }

    &--primary {
      background-color: var(--blue-lightest);
      color: var(--blue);
    }
  }
`;

export const Button = styled.button`
  width: 114px;
`;

export const Icon = styled.i`
  font-size: 16px;
  margin-right: 8px;
`;
