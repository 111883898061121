import i18n from 'i18next';
import emojiRegex from 'emoji-regex';
import { FlattenSimpleInterpolation } from 'styled-components';
import { ComponentState, Template } from '@hotmart-org-ca/saas-pages-engine';
import { rebrand } from '@config/rebrand';
import { AppError } from './app-error';

export const redirectToErrorPage = (error: string) => {
  window.location.replace(`${rebrand.urls.KLICKART_URL}/${error}`);
};

export const getConcatUrl = (domainUrl: string, pathUrl: string) => {
  const validUrl = pathUrl.startsWith('/') ? pathUrl : `/${pathUrl}`;
  return `https://${domainUrl}${validUrl}`;
};

export const copyToClipboard = async (text: string) => {
  if (!navigator?.clipboard) {
    return false;
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch {
    return false;
  }
};

const isAsPattern = (label: string, translatedSectionWord: string) =>
  !!label!.match(new RegExp(`${translatedSectionWord} (\\d+$)`));

const pageChildLabelByType: StringMap<string> = {
  'ls-popup': 'sideMenu.pageStructure.popups.label',
  'ls-section': 'sideMenu.sections.section',
};

export const getPageChildrenFirstName = (
  type: PageChildType = 'ls-section'
) => {
  const label = i18n.t(pageChildLabelByType[type]);
  return `${label} 1`;
};

export const getHigherChildLabel = (
  storeState: State,
  type: PageChildType = 'ls-section'
) => {
  const translatedChildLabel = i18n.t(pageChildLabelByType[type]);
  const children =
    storeState.lsEditor.page?.children?.filter(
      (child) => child.component === type
    ) || [];

  const highestNumber = children.reduce((currentHighest, child) => {
    const childSlice = storeState[child.uid];
    const childLabel = (childSlice as ComponentState)?.metadata?.label;

    if (childLabel && isAsPattern(childLabel, translatedChildLabel)) {
      const labelNumber = +childLabel.replace(/\D+/g, ' ').trim();
      return labelNumber > currentHighest ? labelNumber : currentHighest;
    }

    return currentHighest;
  }, 0);

  return `${translatedChildLabel} ${highestNumber + 1}`;
};

export const minify = (text: string | FlattenSimpleInterpolation) =>
  text
    .toString()
    .replace(/\r?\n|\r/g, '')
    .replace(/\s\s+/g, ' ')
    .trim();

const removeHighlightClass = () => {
  const sectionHighlight = document.querySelector('.section-highlight');
  sectionHighlight?.classList.remove('section-highlight');
};

export const scrollToSection = (uid: string, highlight?: boolean) => {
  const element = document.getElementById(uid);
  element?.scrollIntoView({
    behavior: 'smooth',
  });

  if (highlight) {
    removeHighlightClass();

    const highlightElement = element?.querySelector(
      'ls-editor-highlight .ls-editor-highlight'
    );
    highlightElement?.classList.add('section-highlight');

    setTimeout(() => {
      highlightElement?.classList.remove('section-highlight');
    }, 1600);
  }
};

export const removeBreadcrumbById = (
  breadcrumbs: Breadcrumb[],
  idToRemove: string
) => {
  const index = breadcrumbs.findIndex(({ id }) => idToRemove === id.toString());

  if (index !== -1) {
    breadcrumbs.splice(index, breadcrumbs.length - index);
    return true;
  }

  return false;
};

export const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

const allowedEmojis = ['©'];

const matchInvalidEmoji = (value: string) => {
  const matches = Array.from(value.matchAll(emojiRegex()), (match) => match[0]);

  return matches.filter((match) => !allowedEmojis.includes(match));
};

export const removeEmojis = (
  value: Record<string | number, unknown> | string
) => {
  const isString = typeof value === 'string';
  let valueWithoutEmojis = isString ? value : JSON.stringify(value);

  const matches = Array.from(
    valueWithoutEmojis.matchAll(emojiRegex()),
    (match) => match[0]
  );
  const matchesWithoutAllowed = matches.filter(
    (match) => !allowedEmojis.includes(match)
  );

  matchesWithoutAllowed.forEach((match) => {
    valueWithoutEmojis = valueWithoutEmojis.replace(match, '');
  });

  return isString ? valueWithoutEmojis : JSON.parse(valueWithoutEmojis);
};

export const validateEmoji = (value: Record<string | number, unknown>) => {
  const pageStringify = JSON.stringify(value);

  if (matchInvalidEmoji(pageStringify).length) {
    throw new AppError('toastMessages.general.error.emoji');
  }
};

export const isInvalidEmoji = (value: string) =>
  Boolean(matchInvalidEmoji(value).length);

export const containInvalidEmoji = (emojiObj: Record<string, unknown>) =>
  Object.values(emojiObj).filter((item) => item === true).length;

export const getPageRoute = (
  mode: EditorMode = 'page',
  templateManagementEnabled: boolean = false
) => {
  if (mode === 'page') {
    return `v1/pages`;
  }

  if (templateManagementEnabled) {
    return `v1/admin/templates`;
  }

  return `v1/templates`;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const rgb = [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ];

    return `rgb(${rgb.join(', ')})`;
  }

  return 'rgb(255, 255, 255)';
};

const hasRedirectionScript = (code: any) => {
  const redirectionPatterns = [
    /location\.replace\s*\(\s*.*\s*\)/,
    /location\.assign\s*\(\s*.*\s*\)/,
    /location\.href\s*=\s*.*\s*/,
    /window\.open\s*\(\s*.*\s*\)/,
    /location\[['"]([^'"]+)['"]\]\(\s*.*\s*\)/,
  ];

  const suspiciousDomains = ['wvw-kucoln'];

  const customRedirectionPattern =
    /var\s+url\s*=\s*['"]([^'"]+)['"];\s*var\s+ads\s*=\s*['"]([^'"]+)['"];/;

  return (
    redirectionPatterns.some((pattern) => pattern.test(code)) ||
    suspiciousDomains.some((domain) => code.includes(domain)) ||
    customRedirectionPattern.test(code)
  );
};

const hasRedirectionScriptInArray = (codeArray: (string | undefined)[]) =>
  codeArray.some((code) => {
    if (code) {
      return hasRedirectionScript(code);
    }
    return false;
  });

export const hasRedirectScriptInPage = (
  stringParameters: (string | undefined)[] = []
) => hasRedirectionScriptInArray(stringParameters);

export const extractHtmlValues = (template: Template): string[] => {
  const htmlValues: string[] = [];

  const extractFromTemplate = (currentTemplate: Template) => {
    if (currentTemplate.html) {
      htmlValues.push(currentTemplate.html);
    }

    currentTemplate.children?.forEach(extractFromTemplate);
  };

  extractFromTemplate(template);

  return htmlValues;
};

export const persistentQuerySelector = (
  selector: string,
  retry: number = 100
): Promise<Element | null> => {
  let retryCount = 0;

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const element = document.querySelector(selector);

      if (element) {
        clearInterval(interval);
        resolve(element);

        return;
      }

      if (retryCount >= retry) {
        clearInterval(interval);
        resolve(null);
      }

      retryCount += 1;
    }, 10);
  });
};

export const addQueryParams = (
  baseUrl: string,
  params: Record<string, string>
) => {
  const url = new URL(baseUrl);
  Object.entries(params).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );
  return url.href.toString();
};

export const dispatchClosePopup = (uid: string) => {
  window.dispatchEvent(new CustomEvent(`closePopup-${uid}`));
};

export const dispatchOpenPopup = (uid: string, openedPopup?: string) => {
  if (openedPopup) {
    dispatchClosePopup(openedPopup);
  }
  window.dispatchEvent(new CustomEvent(`openPopup-${uid}`));
};

export const getURLSearch = (
  urlSearchParams: URLSearchParams,
  reservedKeys: string[] = []
) => {
  const search: string[] = [];

  urlSearchParams.forEach((value, key) => {
    if (!reservedKeys.includes(key)) {
      search.push(`${key}=${value}`);
    }
  });

  return search.length ? `?${search.join('&')}` : '';
};
