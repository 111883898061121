import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useAppDispatch } from '@store/index';
import * as pageInfoSlice from '@store/slices/pageInfo';

export const useTemplateInfo = () => {
  const dispatch = useAppDispatch();

  const uuid = useSelector((state: State) => state.pageInfo.uuid);

  const name = useSelector((state: State) => state.pageInfo.name);

  const isExpert = useSelector((state: State) =>
    Boolean(state.pageInfo.isExpert)
  );

  const isNew = useSelector((state: State) => Boolean(state.pageInfo.isNew));

  const thumb = useSelector((state: State) => state.pageInfo.thumb);

  const mode = useSelector((state: State) => state.pageInfo.mode);

  const enabled = useSelector((state: State) => state.pageInfo.enabled);

  const templateCategories = useSelector(
    (state: State) => state.pageInfo.templateCategories || []
  );

  const updateInfo = useCallback(
    (updatedInfo: Partial<PageInfoState>) => {
      dispatch(pageInfoSlice.updateInfo(updatedInfo));
    },
    [dispatch]
  );

  return {
    updateInfo,
    uuid,
    name,
    isExpert,
    isNew,
    thumb,
    templateCategories,
    mode,
    enabled,
  };
};
