import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import UseCases from '@services/useCases';

export const getUseCase = createAsyncThunk<
  KlickartUseCasesData,
  number,
  {
    state: LocalState;
  }
>('useCases/getUseCase', async (useCaseId) => {
  try {
    const response = await UseCases.get(useCaseId);
    return response;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const getUseCaseBuilder = (
  builder: ActionReducerMapBuilder<UseCasesState>
) => {
  builder.addCase(getUseCase.fulfilled, (state: UseCasesState, action) => {
    state.id = action.payload.id;
    state.properties = action.payload.properties;
    state.maxStep = action.payload.maxStep;
    state.currentStep = action.payload.currentStep;
    state.type = action.payload.type;
  });
};
