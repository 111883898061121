import { memo, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldItem } from './styles';

type SortableFieldItemProps = {
  label: string;
};

const SortableFieldItemComponent: React.FC<SortableFieldItemProps> = ({
  label,
}) => {
  const { t } = useTranslation();
  const {
    attributes,
    transform: hookTransform,
    transition,
    listeners,
    setNodeRef,
  } = useSortable({
    id: label,
  });

  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(hookTransform),
      transition,
    }),
    [hookTransform, transition]
  );

  return (
    <FieldItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <FontAwesomeIcon icon={['fal', 'bars']} />
      <span>{t(label)}</span>
    </FieldItem>
  );
};

export const SortableFieldItem = memo(SortableFieldItemComponent);
