import { useApplication } from '@hooks/useApplication';
import i18n from 'i18next';
import { HTMLAttributes, useCallback } from 'react';
import TagManager from 'react-gtm-module';

export const BackToEditButton: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  ...attrs
}) => {
  const { setIsPreviewMode } = useApplication();

  const handleBackToEdit = useCallback(() => {
    setIsPreviewMode(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: 'click_navbar_editor',
        event_details: 'Edit',
      },
    });
  }, [setIsPreviewMode]);

  return (
    <button
      className="hot-button hot-button--primary"
      onClick={handleBackToEdit}
      {...attrs}
    >
      {i18n.t('header.edit')}
    </button>
  );
};
