import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeLgAlt, faFolder } from '@fortawesome/free-solid-svg-icons';
import React, { HTMLAttributes, useCallback } from 'react';
import { Button, Container, IconWrapper, Slash } from './styles';
import { useMediaCenter } from '../../../../../../../../hooks/useMediaCenter';

export const Breadcrumbs: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { breadcrumbs, navigateBackFolder } = useMediaCenter();

  const handleHomeClick = useCallback(async () => {
    navigateBackFolder({ to: 'ROOT_FOLDER' });
  }, [navigateBackFolder]);

  const handleBreadcrumbClick = useCallback(
    async (breadcrumbIndex: number) => {
      navigateBackFolder({ to: 'FOLDER', folderIndex: breadcrumbIndex });
    },
    [navigateBackFolder]
  );

  return (
    <Container {...attrs}>
      <Button title="home" onClick={handleHomeClick}>
        <IconWrapper>
          <FontAwesomeIcon icon={faHomeLgAlt} />
        </IconWrapper>
      </Button>
      {breadcrumbs.map((breadCrumb: Breadcrumb, index: number) => (
        <React.Fragment key={`${breadCrumb.id}_breadcrumb`}>
          <Slash
            className={index === breadcrumbs.length - 1 ? 'active-folder' : ''}
          >
            /
          </Slash>
          <Button
            title={breadCrumb.name}
            onClick={() =>
              index === breadcrumbs.length - 1
                ? null
                : handleBreadcrumbClick(index)
            }
            onKeyDown={() =>
              index === breadcrumbs.length - 1
                ? null
                : handleBreadcrumbClick(index)
            }
            className={index === breadcrumbs.length - 1 ? 'active-folder' : ''}
          >
            {index === breadcrumbs.length - 1 ? (
              <IconWrapper>
                <FontAwesomeIcon icon={['fad', 'folder-open']} />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <FontAwesomeIcon icon={faFolder} />
              </IconWrapper>
            )}
            {` ${breadCrumb.name}`}
          </Button>
        </React.Fragment>
      ))}
    </Container>
  );
};
