import { HTMLAttributes, memo, useCallback, useMemo, useState } from 'react';
import {
  usePageConfig,
  useUserInfo,
  useMenu,
  useEngine,
  useApplication,
} from '@hooks/index';
import { TemplateModelsModal } from '@components/TemplateModelsModal';
import { MadeWith } from './components/MadeWith';
import { Container, Content, LsPage } from './styles';

export type EditorContainerProps = HTMLAttributes<HTMLDivElement> & {
  mode?: EditorMode;
};

const EditorContainerComponent: React.FC<EditorContainerProps> = ({
  mode = 'page',
  ...attrs
}) => {
  const { madeWithManagementEnabled } = useUserInfo();
  const { showMadeWithKlickpages } = usePageConfig();
  const { setActiveMenu } = useMenu();
  const { clearActiveComponent, preview: devicePreview } = useEngine();
  const { isPreviewMode } = useApplication();
  const [templateModelsModalElementType, setTemplateModelsModalElementType] =
    useState<'section' | 'popup'>('section');
  const [replaceElementUid, setReplaceElementUid] = useState(undefined);
  const [getPosition, setPosition] = useState<number>();
  const [isTemplateModelsModalOpen, setIsTemplateModelsModalOpen] =
    useState(false);

  const resetState = useCallback(() => {
    setTemplateModelsModalElementType('section');
    setReplaceElementUid(undefined);
    setPosition(undefined);
  }, []);

  const handleAddElement = useCallback(() => {
    clearActiveComponent();
    setActiveMenu('add');
  }, [clearActiveComponent, setActiveMenu]);

  const handleAddTemplate = useCallback((e: CustomEvent) => {
    setTemplateModelsModalElementType(
      e.detail.rootComponent === 'ls-section' ? 'section' : 'popup'
    );
    setReplaceElementUid(e.detail.replaceElementUid);
    setIsTemplateModelsModalOpen(true);
  }, []);

  const handleTemplateModelsModalClose = useCallback(() => {
    resetState();
    setIsTemplateModelsModalOpen(false);
  }, [resetState]);

  const shouldRenderMadeWith = useMemo(
    () => !madeWithManagementEnabled || showMadeWithKlickpages,
    [madeWithManagementEnabled, showMadeWithKlickpages]
  );

  const handleAddBetweenSections = useCallback((event: CustomEvent) => {
    const position: number = Number(event.detail.position);

    setPosition(position);

    setTemplateModelsModalElementType('section');
    setIsTemplateModelsModalOpen(true);
  }, []);

  return (
    <Container {...attrs} mode={mode}>
      <Content mode={mode} device={devicePreview}>
        <LsPage
          editable={!isPreviewMode}
          onEmptyStateAddLinkClicked={handleAddElement}
          onEmptyStateTemplateLinkClicked={handleAddTemplate}
          onAddBetweenSections={(event: CustomEvent<{ position: number }>) =>
            handleAddBetweenSections(event)
          }
        />
        <TemplateModelsModal
          isOpen={isTemplateModelsModalOpen}
          elementType={templateModelsModalElementType}
          replaceUid={replaceElementUid}
          elementPosition={getPosition}
          activeElementAfterAdd
          onClose={handleTemplateModelsModalClose}
        />
        {shouldRenderMadeWith && <MadeWith />}
      </Content>
    </Container>
  );
};

export const EditorContainer = memo(EditorContainerComponent);
