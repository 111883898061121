import styled, { css } from 'styled-components';
import { Icon } from '../../../Icon';

type StyledChildProps = {
  itemType: 'sections' | 'popups';
  isActive?: boolean;
};

type StyledChildIconProps = {
  isActive?: boolean;
  isActiveHighlight?: boolean;
};

export const Message = styled.div`
  display: flex;
  padding-right: 12px;
  font-size: 12px;
  color: var(--gray-600);
  margin-top: 8px;
`;

export const Content = styled.div`
  padding: 0;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const Label = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
`;

export const Title = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  padding-top: 24px;
`;

export const ChildIcon = styled(Icon)<StyledChildIconProps>`
  ${({ isActive, isActiveHighlight }) => css`
    ${(isActive || isActiveHighlight) &&
    css`
      * {
        fill: var(--blue);
      }
    `}
  `}
`;

export const IconGroup = styled.span`
  z-index: 1;
  cursor: pointer;
  display: none;
  color: var(--gray-600);

  :hover {
    color: var(--blue);
  }
`;

export const Child = styled.div<StyledChildProps>`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 6px 0px;
  min-height: 40px;

  ${({ itemType, isActive }) => css`
    cursor: ${itemType === 'popups' ? 'default' : 'move'};

    ${isActive &&
    css`
      ${IconGroup} {
        display: flex;
      }
    `}
  `}

  &:hover {
    ${IconGroup} {
      display: flex;
    }

    ${ChildIcon} * {
      fill: var(--blue);
    }
  }
`;

export const AddButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Link = styled.a`
  align-items: center
  color: var(--blue);
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  &:hover {
    color: var(--blue);
  }
`;

export const LinkIcon = styled.i`
  margin-left: 4px;
`;
