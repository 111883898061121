import '@hotmart-org-ca/cosmos/dist/breadcrumb/breadcrumb.css';

import { HTMLAttributes, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, HeaderTitle, HeaderSubtitle, Anchor } from './styles';

export type MoveModalHeaderProps = HTMLAttributes<HTMLDivElement> & {
  folderList?: FolderTree;
  onBreadcrumbClick: (folder: FolderTree) => void;
};

const HeaderComponent: React.FC<MoveModalHeaderProps> = ({
  folderList,
  onBreadcrumbClick,
  ...attrs
}) => {
  const { t } = useTranslation();

  const breadcrumbsItems = useMemo(() => {
    const result: FolderTree[] = [];

    if (folderList) {
      result.unshift(folderList);

      let folders: FolderTree = folderList;

      while (folders.parent) {
        folders = folders.parent;
        result.unshift(folders);
      }
    }

    return result;
  }, [folderList]);

  return (
    <Container {...attrs}>
      <HeaderTitle>
        {t('mediaCenter.infoBar.moveModal.header.title')}
      </HeaderTitle>

      <HeaderSubtitle>
        {t('mediaCenter.infoBar.moveModal.header.subtitle')}
      </HeaderSubtitle>

      <hot-breadcrumb>
        {breadcrumbsItems.map((item: FolderTree) => (
          <hot-breadcrumb-item
            active={item.id === folderList?.id}
            key={`${item.id}-${item.name}`}
            className="breadcrumb"
          >
            {item.id === folderList?.id ? (
              <span className="breadcrumb__current">{item.name}</span>
            ) : (
              <Anchor
                onClick={() => onBreadcrumbClick(item)}
                className="breadcrumb__back"
              >
                {item.name}
              </Anchor>
            )}
          </hot-breadcrumb-item>
        ))}
      </hot-breadcrumb>
    </Container>
  );
};

export const Header = memo(HeaderComponent);
