export const getTemplate = () => ({
  background: 'none',
  padding: '0px 0px 0px 0px',
  desktop: {},
  tablet: {},
  mobile: {},
  uid: 'ls-xhBgn1DPsDbixbfdnSoXJq',
  component: 'ls-page',
  children: [
    {
      uid: 'ls-36a90a00-49a6-11ed-8e02-c9471a46a5ea',
      component: 'ls-section',
      children: [
        {
          uid: 'ls-36a90a01-49a6-11ed-8e02-c9471a46a5ea',
          component: 'ls-row',
          children: [
            {
              uid: 'ls-36a90a02-49a6-11ed-8e02-c9471a46a5ea',
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '100px',
                  href: '',
                  'is-webp': true,
                  'max-width': '100px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: 'ls-qdJXBUbR8iN1x9UpUL2ww8',
                  component: 'ls-image',
                  src: 'https://media.tenor.com/A-1z4jlGrXgAAAAi/onay2.gif',
                  'thumb-src':
                    'https://media.tenor.com/A-1z4jlGrXgAAAAi/onay2.gif',
                  metadata: {},
                },
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(58, 58, 58); font-size: 48px;">Obrigado por se inscrever!</strong></h1>',
                  'max-width': '70%',
                  padding: '32px 10px 16px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(58, 58, 58); font-size: 35px;">Obrigado por se inscrever!</strong></p>',
                    'max-width': '80%',
                    padding: '10px 10px 20px 10px',
                  },
                  uid: 'ls-aYWkfcXAYXTWZ4bPkEyaNF',
                  component: 'ls-text',
                  metadata: {
                    unlinkedProps: {
                      mobile: {
                        text: true,
                        'max-width': true,
                        padding: true,
                      },
                      tablet: {
                        'max-width': true,
                      },
                      desktop: {},
                    },
                  },
                },
                {
                  text: '<p style="text-align: center;"><span style="color: rgb(123, 122, 122); font-family: "Nunito Sans", sans-serif; font-size: 20px;">Você acaba de dar o primeiro passo rumo ao sucesso! Logo você receberá no seu email as informações de acesso.</span></p>',
                  'max-width': '60%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="color: rgb(123, 122, 122); font-family: "Nunito Sans", sans-serif; font-size: 18px;">Você acaba de dar o primeiro passo rumo ao sucesso! Logo você receberá no seu email as informações de acesso.</span></p>',
                  },
                  uid: 'ls-pV1B8vXXj77Bm29Ciqd8uZ',
                  component: 'ls-text',
                  metadata: {
                    unlinkedProps: {
                      mobile: {
                        text: true,
                      },
                      tablet: {
                        'max-width': true,
                      },
                      desktop: {},
                    },
                  },
                },
                {
                  color: '#FFFFFF',
                  background: '#4CAF50',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO PARTICIPAR DO GRUPO',
                  margin: '32px 0px 24px 0px',
                  'max-width': '400px',
                  mobile: {
                    'max-width': '80%',
                    'font-size': '16px',
                  },
                  padding: '16px 16px 14px 16px',
                  tablet: {
                    'max-width': '60%',
                  },
                  target: '_blank',
                  uid: 'ls-jxqkombS2N44cxakLUf4Vm',
                  component: 'ls-button',
                  metadata: {
                    unlinkedProps: {
                      desktop: {},
                      tablet: {
                        'max-width': true,
                      },
                      mobile: {
                        'max-width': true,
                        'font-size': true,
                      },
                    },
                  },
                },
              ],
              'align-items': 'center',
              'justify-content': 'center',
              padding: '200px 0px 200px 0px',
              mobile: {
                padding: '70px 0px 70px 0px',
              },
              tablet: {
                padding: '170px 0px 200px 0px',
              },
              desktop: {},
              metadata: {
                unlinkedProps: {
                  mobile: {
                    padding: true,
                  },
                  tablet: {
                    padding: true,
                  },
                  desktop: {},
                },
              },
            },
          ],
          tablet: {},
          mobile: {},
          desktop: {},
          metadata: {
            unlinkedProps: {
              desktop: {},
              tablet: {},
              mobile: {},
            },
          },
        },
      ],
      metadata: {
        label: 'Obrigado',
        unlinkedProps: {
          desktop: {},
          tablet: {},
          mobile: {},
        },
      },
      background: '#FDFDFD',
      tablet: {},
      mobile: {},
      desktop: {},
      padding: '0px 0px 0px 0px',
    },
    {
      background: '#4CAF50',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: 'ls-g3suWvoKVRpYRczVLZWSZm',
      component: 'ls-section',
      metadata: {
        label: 'Rodapé',
        unlinkedProps: {
          desktop: {},
          tablet: {},
          mobile: {},
        },
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: 'ls-qwEqkwFQaf5kvYYJvXDcQX',
          component: 'ls-row',
          children: [
            {
              padding: '30px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 20px 0px',
              },
              mobile: {
                padding: '32px 0px 32px 0px',
              },
              uid: 'ls-c1uLPJpHWpvrTvDFm8pSVi',
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Copyright © 2023</span></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Todos os direitos reservados.</span></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="color: rgb(57, 57, 57); font-family: Montserrat, sans-serif; font-size: 14px;">Copyright © 2022</span></p><p style="text-align: center;"><span style="color: rgb(57, 57, 57); font-family: Montserrat, sans-serif; font-size: 14px;">Todos os direitos reservados.</span></p>',
                    'max-width': '50%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="color: rgb(57, 57, 57); font-family: Montserrat, sans-serif; font-size: 12px;">Copyright © 2022</span></p><p style="text-align: center;"><span style="color: rgb(57, 57, 57); font-family: Montserrat, sans-serif; font-size: 12px;">Todos os direitos reservados.</span></p>',
                    'max-width': '70%',
                    padding: '0px 10px 5px 10px',
                  },
                  uid: 'ls-cgKcezRK2ZeM1msi71t7Ud',
                  component: 'ls-text',
                  metadata: {
                    unlinkedProps: {
                      desktop: {},
                      tablet: {
                        text: true,
                        'max-width': true,
                      },
                      mobile: {
                        text: true,
                        'max-width': true,
                        padding: true,
                      },
                    },
                  },
                },
              ],
              metadata: {
                unlinkedProps: {
                  mobile: {
                    padding: true,
                  },
                  tablet: {
                    'align-items': true,
                    'justify-content': true,
                    padding: true,
                  },
                  desktop: {},
                },
              },
            },
          ],
          metadata: {
            unlinkedProps: {
              desktop: {},
              tablet: {
                'flex-direction': true,
              },
              mobile: {
                'flex-direction': true,
              },
            },
          },
        },
      ],
    },
  ],
  metadata: {
    unlinkedProps: {
      desktop: {},
      tablet: {},
      mobile: {},
    },
  },
});

export const getFontsConfig = () => ({
  Montserrat: {
    regular: [400, 700],
    italic: [400, 700],
  },
  'Nunito Sans': {
    regular: [400, 700],
    italic: [400, 700],
  },
});
