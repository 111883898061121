import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
`;

export const CircleBorder = styled.div`
  align-items: center;
  border-radius: 100%;
  border: 1px solid var(--gray-200);
  color: var(--gray-400);
  display: flex;
  font-size: 32px;
  justify-content: center;
  height: 100px;
  width: 100px;
`;

export const FirstDescription = styled.div`
  color: var(--gray-900);
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  margin-top: 24px;
  text-align: center;
  width: 252px;
  height: 48px;
`;

export const SecondDescription = styled.div`
  color: var(--gray-900);
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
