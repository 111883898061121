import { v4 as uuid } from 'uuid';

export const thankYouSections: PageFragmentListItem[] = [
  {
    id: 'thank-you-7',
    name: 'Thank You',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '40px 0px 0px 0px',
              },
              mobile: {
                padding: '30px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1; text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 57px;">ESPERE!</strong></p>',
                  'max-width': '50%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="line-height: 1; text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;">ESPERE!</strong></p>',
                    'max-width': '60%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 1.15; text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 22px;">Siga os passos abaixo para completar sua inscrição:</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 50px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="line-height: 1.15; text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">Siga os passos abaixo para completar sua inscrição:</span></p>',
                    'max-width': '70%',
                    padding: '10px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '142px 10px 142px 10px',
                  'border-radius': '20px 0px 0px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '80%',
                    'border-radius': '20px 20px 0px 0px',
                    padding: '10px 10px 10px 10px',
                    margin: '10px 0px 0px 0px',
                    background: '#25257C',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 60px;"> PASSO 01</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> PASSO 01</strong></p>',
                        'max-width': '70%',
                      },
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 20px;"> PASSO 01</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '50px 10px 70px 10px',
                  'border-radius': '0px 20px 20px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    margin: '0px 0px 20px 0px',
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '30px 10px 50px 10px',
                    'border-radius': '0px 0px 20px 20px',
                  },
                  mobile: {
                    padding: '10px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Assista o vídeo abaixo para entender como garantir XPTO.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Assista o vídeo abaixo para entender como garantir XPTO.</span></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;">Assista o vídeo abaixo para entender como garantir XPTO.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      'border-color': '#FFFFFF',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '197px',
                      margin: '30px 0px 0px 0px',
                      'max-width': '350px',
                      src: 'https://www.youtube.com/embed/dtd2U0-ZOJg',
                      'thumb-url': '',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '250px',
                        height: '141px',
                        margin: '20px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-video',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '80px 10px 80px 10px',
                  'border-radius': '20px 0px 0px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    padding: '10px 10px 10px 10px',
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 0px 0px 0px',
                    background: '#25257C',
                    'border-radius': '20px 20px 0px 0px',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 60px;"> PASSO 02</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> PASSO 02</strong></p>',
                        padding: '10px 10px 10px 10px',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '60px 10px 60px 10px',
                  'border-radius': '0px 20px 20px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '30px 10px 40px 10px',
                    'border-radius': '0px 0px 20px 20px',
                    margin: '0px 0px 20px 0px',
                  },
                  mobile: {
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Entre nesse link para garantir que você será notificado por WhatsApp:</span></p>',
                      'max-width': '70%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Entre nesse link para garantir que você será notificado por WhatsApp:</span></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;">Entre nesse link para garantir que você será notificado por WhatsApp:</span></p>',
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#141436',
                      background: '#6AFFB9',
                      'border-color': '#EF4E23',
                      'border-radius': '16px 16px 16px 16px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '16px',
                      'hover-background': '#282A74',
                      'hover-color': '#6AFFB9',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'QUERO SER NOTIFICADO',
                      margin: '17px 0px 13px 0px',
                      'max-width': '260px',
                      mobile: {
                        'font-size': '16px',
                      },
                      padding: '13px 16px 13px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '66px 10px 66px 10px',
                  'border-radius': '20px 0px 0px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '80%',
                    padding: '10px 10px 10px 10px',
                    'border-radius': '20px 20px 0px 0px',
                    margin: '10px 0px 0px 0px',
                    background: '#25257C',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 60px;"> PASSO 03</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> PASSO 03</strong></p>',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '60px 10px 60px 10px',
                  'border-radius': '0px 20px 20px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '80%',
                    margin: '0px 0px 20px 0px',
                    'border-radius': '0px 0px 20px 20px',
                    padding: '30px 10px 40px 10px',
                  },
                  mobile: {
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Me siga no Instagram:</span></p>',
                      'max-width': '70%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Me siga no Instagram:</span></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">Me siga no Instagram:</span></p>',
                        'max-width': '80%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#141436',
                      background: '#6AFFB9',
                      'border-color': '#EF4E23',
                      'border-radius': '16px 16px 16px 16px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '16px',
                      'hover-background': '#282A74',
                      'hover-color': '#6AFFB9',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'SEGUIR @SEU_USUÁRIO',
                      margin: '17px 0px 13px 0px',
                      'max-width': '260px',
                      mobile: {},
                      padding: '13px 16px 13px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 0px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '66px 10px 66px 10px',
                  'border-radius': '20px 0px 0px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '80%',
                    padding: '10px 10px 10px 10px',
                    background: '#25257C',
                    'border-radius': '20px 20px 0px 0px',
                    margin: '10px 0px 0px 0px',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 60px;"> PASSO 04</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> PASSO 04</strong></p>',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 60px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '0px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '46px 10px 47px 10px',
                  'border-radius': '0px 20px 20px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#23237E',
                  'max-width': '100%',
                  margin: '10px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '80%',
                    padding: '30px 10px 40px 10px',
                    margin: '0px 0px 20px 0px',
                    'border-radius': '0px 0px 20px 20px',
                  },
                  mobile: {
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">[Opcional] Você quer acelerar os seus resultados?</span></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Entre nesse link para entender como:</span></p>',
                      'max-width': '90%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">[Opcional] Você quer acelerar os seus resultados?</span></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Entre nesse link para entender como:</span></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">[Opcional] Você quer acelerar os seus resultados?</span></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">Entre nesse link para entender como:</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#141436',
                      background: '#6AFFB9',
                      'border-color': '#EF4E23',
                      'border-radius': '16px 16px 16px 16px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '16px',
                      'hover-background': '#282A74',
                      'hover-color': '#6AFFB9',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'QUERO ACELERAR MEUS RESULTADOS',
                      margin: '17px 0px 13px 0px',
                      'max-width': '380px',
                      mobile: {
                        'max-width': '250px',
                      },
                      padding: '13px 16px 13px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Seção 1',
      },
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5395155/bg3-vendas_assets_template-albertoni.png') center/cover",
      mobile: {},
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'thank-you-8',
    name: 'Thank You',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5372386/bg-obrigado_assets_template-nath-arcuri.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {
        background: '#D1BEDD',
      },
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '32px 0px 32px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '32px 0px 24px 0px',
          },
          mobile: {
            'flex-direction': 'column',
            padding: '16px 0px 16px 0px',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h3 style="text-align: center;"><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(101, 95, 159);">Adicionar título de agradecimento</strong></h3>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(101, 95, 159);">Adicionar título de agradecimento</strong></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-size: 30px; font-family: Montserrat, sans-serif; color: rgb(101, 95, 159);">Adicionar título de agradecimento</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(101, 95, 159);">Liste o passo a passo necessário para seu cliente acessar o material:</span></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                    text: '<p style="text-align: center;"><span style="font-size: 20px; font-family: Montserrat, sans-serif; color: rgb(101, 95, 159);">Liste o passo a passo necessário para seu cliente acessar o material:</span></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(101, 95, 159);">Liste o passo a passo necessário para seu cliente acessar o material:</span></p>',
                    padding: '15px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '32px 0px 88px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '16px 0px 72px 0px',
              },
              mobile: {
                padding: '0px 0px 48px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '65px 10px 10px 40px',
                  'border-radius': '16px 16px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#FFFFFF',
                  'max-width': '95%',
                  margin: '10px 0px 0px 0px',
                  desktop: {},
                  tablet: {
                    padding: '48px 10px 10px 60px',
                    'max-width': '85%',
                  },
                  mobile: {
                    padding: '24px 10px 10px 23px',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '80px',
                        'max-width': '80px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410326/icone-5.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410326/thumb_icone-5.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(101, 95, 159);">Passo 1</span></p>',
                      'max-width': '50%',
                      padding: '10px 10px 10px 30px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 10px 20px',
                      },
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; font-size: 20px; color: rgb(101, 95, 159);">Passo 1</strong></p>',
                        padding: '10px 10px 10px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '15px 10px 15px 40px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#FFFFFF',
                  'max-width': '95%',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '85%',
                    padding: '15px 10px 15px 60px',
                  },
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '15px 10px 15px 23px',
                    'max-width': '85%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '80px',
                        'max-width': '80px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410332/icone-6.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410332/thumb_icone-6.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(101, 95, 159);">Passo 2</span></p>',
                      'max-width': '50%',
                      padding: '10px 10px 10px 30px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 10px 20px',
                      },
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; font-size: 20px; color: rgb(101, 95, 159);">Passo 2</strong></p>',
                        padding: '10px 10px 10px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 65px 40px',
                  'border-radius': '0px 0px 16px 16px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#FFFFFF',
                  'max-width': '95%',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '85%',
                    padding: '10px 10px 48px 60px',
                  },
                  mobile: {
                    padding: '10px 0px 24px 10px',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '80px',
                        'max-width': '80px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410339/icone-7.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410339/thumb_icone-7.png',
                      metadata: {},
                    },
                    {
                      color: '#655F9F',
                      background: '#FCD944',
                      'border-color': '#EF4E23',
                      'border-radius': '16px 16px 16px 16px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'ENTRAR NO GRUPO',
                      margin: '0px 0px 0px 30px',
                      'max-width': '300px',
                      mobile: {
                        'font-size': '15px',
                        margin: '0px 10px 0px 6px',
                        padding: '13px 0px 13px 0px',
                        'border-radius': '13px 13px 13px 13px',
                        'max-width': '200px',
                      },
                      padding: '16px 16px 16px 16px',
                      tablet: {
                        margin: '0px 0px 0px 20px',
                      },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'end',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '553px',
                  href: '',
                  'max-width': '601px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '465px',
                    'max-width': '527px',
                  },
                  mobile: {
                    'max-width': '300px',
                    height: '275px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5368642/foto_nath-arcuri.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5368642/thumb_foto_nath-arcuri.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Thank You',
        templateId: 'thank-you-1',
        templateName: 'Thank You',
      },
    },
  },
  {
    id: 'thank-you-1',
    name: 'Thank You',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '24px 0px 24px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch3 style="text-align: center;"\u003e\u003cstrong style="font-size: 40px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eAdicionar título de agradecimento\u003c/strong\u003e\u003c/h3\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eListe o passo a passo necessário para seu cliente acessar o material\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: '#F5F5F5',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '300px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5239172/group_95.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5239172/thumb_group_95.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003ePasso 1\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '300px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5239170/group_96.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5239170/thumb_group_96.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003ePasso 2\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '300px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5239144/3.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5239144/thumb_3.png',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#424242',
                      'border-color': '#EF4E23',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family':
                        'Arial, Helvetica Neue, Helvetica, sans-serif',
                      'font-size': '16px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'Entrar no grupo',
                      margin: '0px 0px 0px 10px',
                      'max-width': '300px',
                      mobile: {},
                      padding: '8px 16px 8px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '300px',
                  href: '',
                  'max-width': '350px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5239105/obrigado.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5239105/thumb_obrigado.png',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'thank-you-2',
    name: 'Thank You',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '60px 30px 0px 30px' },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '180px',
                  margin: '0px 0px 30px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5280678/seu-logo-aqui.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5280678/thumb_seu-logo-aqui.png',
                },
                {
                  text: '\u003ch1 style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-size: 48px; font-family: Raleway, sans-serif;"\u003eAdicionar título agradecimento\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003ch1 style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-size: 39px; font-family: Raleway, sans-serif;"\u003eAdicionar título agradecimento\u003c/strong\u003e\u003c/h1\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eListe o passo a passo necessário pro seu cliente acessar o material.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 50px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 90px 50px',
              'justify-content': 'start',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 50px 40px' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 20px 30px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '5px 10px 5px 60px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: { padding: '2px 10px 2px 60px' },
                  mobile: { padding: '2px 10px 2px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '50px',
                      margin: '5px 5px 5px 5px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282986/icone_01.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282986/thumb_icone_01.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003ePasso 1 - lorem ipsum dollor sit amet\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '5px 10px 5px 60px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: { padding: '2px 10px 2px 60px' },
                  mobile: { padding: '2px 10px 2px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '50px',
                      margin: '5px 5px 5px 5px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282990/icone_02.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282990/thumb_icone_02.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003ePasso 1 - Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '5px 10px 5px 60px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: { padding: '2px 10px 2px 60px' },
                  mobile: { padding: '2px 10px 2px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '50px',
                      margin: '5px 5px 5px 5px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282991/icone_03.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282991/thumb_icone_03.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003ePasso 1 - lorem ipsum dollor sit amet\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 60px 70px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'start',
                padding: '0px 20px 60px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '400px',
                  href: '',
                  'max-width': '380px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5286632/ilustra_obrigado.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5286632/thumb_ilustra_obrigado.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 1' },
    },
  },
  {
    id: 'thank-you-3',
    name: 'Thank You',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5278636/bg-captura_assets_template-empreend-ladeira.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Obrigado' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '200px 0px 200px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '80px 0px 30px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '40px 0px 10px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '315px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/rvDAIith64A',
                  'thumb-url': '',
                  desktop: {},
                  tablet: { height: '280px' },
                  mobile: { height: '200px', 'max-width': '330px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                },
              ],
            },
            {
              padding: '200px 0px 200px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '15px 25px 80px 25px',
              },
              mobile: { padding: '10px 10px 50px 10px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '0px 0px 0px 0px',
                    padding: '0px 0px 0px 0px',
                  },
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '0px 20px 0px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 50px;"\u003eOba!\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;"\u003eA sua (planilha, inscrição, e-book) foi enviada para o seu e-mail!\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '600px',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        padding: '0px 5px 10px 5px',
                        'max-width': '80%',
                      },
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;"\u003eOba!\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eA sua (planilha, inscrição, e-book) foi enviada para o seu e-mail!\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 19px;"\u003eEnquanto isso, assista o recado especial que eu gravei para você com tudo que você precisa saber para (informação do que você está oferecendo gratuitamente).\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '80%',
                        padding: '20px 5px 0px 5px',
                      },
                      mobile: {
                        'max-width': '90%',
                        text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 16px;"\u003eEnquanto isso, assista o recado especial que eu gravei para você com tudo que você precisa saber para (informação do que você está oferecendo gratuitamente).\u003c/span\u003e\u003c/p\u003e',
                        padding: '10px 5px 0px 5px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#EF4E23',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 2px 10px 2px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '16px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'BAIXAR PLANILHA',
                      margin: '30px 0px 0px 10px',
                      'max-width': '350px',
                      mobile: {
                        'max-width': '320px',
                        margin: '35px 0px 0px 0px',
                      },
                      padding: '12px 16px 12px 16px',
                      tablet: {
                        'font-family': 'Montserrat, sans-serif',
                        'is-bold': true,
                        margin: '40px 0px 0px 0px',
                        'max-width': '450px',
                      },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'thank-you-4',
    name: 'Thank You',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '60px',
                  href: '',
                  'max-width': '300px',
                  margin: '10px 20px 10px 20px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '500px',
                  margin: '0px 20px 0px 20px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003ch1 style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 54px;"\u003eParabéns!\u003c/strong\u003e\u003c/h1\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003ch1 style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 50px;"\u003eParabéns!\u003c/strong\u003e\u003c/h1\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px; color: rgb(255, 255, 255);"\u003eVocê deu o primeiro passo de sucesso na sua jornada. Em breve um consultor do nosso time entrará em contato para te fazer decolar!\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 20px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
              padding: '200px 0px 200px 0px',
              'flex-direction': 'row',
              'align-items': 'center',
              'justify-content': 'center',
              tablet: {
                padding: '80px 30px 150px 30px',
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
            },
          ],
        },
      ],
      metadata: { label: 'Section 1' },
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5316172/bg.png') center/cover",
    },
  },
  {
    id: 'thank-you-5',
    name: 'Thank You',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 0px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '70%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 124, 53); font-family: Raleway, sans-serif;"\u003eParabéns!\u003c/strong\u003e\u003c/h1\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 124, 53); font-family: Raleway, sans-serif; font-size: 52px;"\u003eParabéns!\u003c/strong\u003e\u003c/h1\u003e',
                        'max-width': '60%',
                      },
                      mobile: {
                        text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="color: rgb(255, 124, 53); font-family: Raleway, sans-serif; font-size: 44px;"\u003eParabéns!\u003c/strong\u003e\u003c/h1\u003e',
                        'max-width': '100%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 22px;"\u003eVocê deu o primeiro passo de sucesso na sua jornada. Em breve um consultor do nosso time entrará em contato para te fazer decolar!\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 19px;"\u003eVocê deu o primeiro passo de sucesso na sua jornada. Em breve um consultor do nosso time entrará em contato para te fazer decolar!\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eVocê deu o primeiro passo de sucesso na sua jornada. Em breve um consultor do nosso time entrará em contato para te fazer decolar!\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '550px',
                  href: '',
                  'max-width': '600px',
                  margin: '40px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '25px 0px 0px 0px',
                    height: '350px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5308759/imagem-obrigado_assets_template-orange-chic.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5308759/thumb_imagem-obrigado_assets_template-orange-chic.png',
                },
              ],
              'align-items': 'center',
              'justify-content': 'center',
              padding: '30px 0px 0px 0px',
              mobile: { padding: '20px 0px 0px 0px' },
            },
          ],
        },
      ],
      metadata: { label: 'Obrigado' },
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5308773/bg-obrigado_assets_template-orange-chic.jpg') center/cover",
    },
  },
  {
    id: 'thank-you-6',
    name: 'Thank You',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '40px 0px 10px 0px' },
              mobile: { padding: '10px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '700px',
                  href: '',
                  'max-width': '550px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '450px',
                    'max-width': '500px',
                    src: '//static-public.klickpages.com.br/uploads/media/file/5329226/img2-obrigado_assets_template-navy-blue.png',
                    'thumb-src':
                      '//static-public.klickpages.com.br/uploads/media/file/5329226/thumb_img2-obrigado_assets_template-navy-blue.png',
                  },
                  mobile: {
                    src: '//static-public.klickpages.com.br/uploads/media/file/5329226/img2-obrigado_assets_template-navy-blue.png',
                    'thumb-src':
                      '//static-public.klickpages.com.br/uploads/media/file/5329226/thumb_img2-obrigado_assets_template-navy-blue.png',
                    height: '270px',
                    'max-width': '260px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5328816/img-obrigado_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5328816/thumb_img-obrigado_assets_template-navy-blue.png',
                },
              ],
            },
            {
              padding: '90px 0px 0px 20px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'start',
                padding: '0px 80px 350px 120px',
              },
              mobile: {
                padding: '0px 20px 280px 20px',
                'align-items': 'center',
                'justify-content': 'start',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '80%',
                  margin: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: { 'max-width': '80%' },
                  mobile: {
                    margin: '0px 0px 10px 0px',
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="color: rgba(255, 255, 255, 0.65); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 80px;"\u003eParabéns!\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 04px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cstrong style="color: rgba(255, 255, 255, 0.65); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 50px;"\u003eParabéns!\u003c/strong\u003e\u003c/p\u003e',
                        padding: '10px 10px 10px 07px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 17px;"\u003eVocê deu o primeiro passo para transformar a sua jornada. Agora, basta acessar o seu email, checar sua caixa de entrada e abrir o email que eu acabei de te enviar.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 17px;"\u003eAproveite e entre no meu grupo do WhatsApp:\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 14px;"\u003eVocê deu o primeiro passo para transformar a sua jornada. Agora, basta acessar o seu email, checar sua caixa de entrada e abrir o email que eu acabei de te enviar.\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 14px;"\u003eAproveite e entre no meu grupo do WhatsApp:\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '100%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#FF5959',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Open Sans, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'ENTRAR NO GRUPO',
                      margin: '30px 0px 30px 10px',
                      'max-width': '400px',
                      mobile: {
                        'font-size': '15px',
                        padding: '12px 16px 12px 16px',
                        'max-width': '300px',
                        margin: '20px 0px 20px 10px',
                      },
                      padding: '18px 16px 18px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 17px;"\u003eCaso não encontre, não se esqueça de conferir a pasta de spam ou de promoções, ok?\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: { 'max-width': '90%' },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 14px;"\u003eCaso não encontre, não se esqueça de conferir a pasta de spam ou de promoções, ok?\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '100%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 1' },
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5365198/bg-obrigado_assets_template-navy-blue.png') center/cover",
    },
  },
];
