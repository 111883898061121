import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/custom.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/danger.css';

import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useAppDispatch } from '@store/index';
import Toast from '@services/toast';
import { BaseModal } from '@components/BaseModal';
import { deleteScript } from '@store/thunks/pageScripts/deleteScript';
import { Description, ModalTitle, CancelButton } from './styles';

type RemoveScriptModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  scriptFormConfig: ScriptFormConfig;
  onClose?: () => void;
};

const RemoveScriptModalComponent: React.FC<RemoveScriptModalProps> = ({
  isOpen,
  scriptFormConfig,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loading = useSelector(
    (state: State) => state.pageScripts.scriptForm.loading
  );

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDeleteScript = useCallback(async () => {
    try {
      await dispatch(deleteScript(scriptFormConfig)).unwrap();
      Toast.success('scripts.modal.deleteSuccess');
    } catch (error) {
      Toast.error('scripts.modal.deleteError');
    } finally {
      handleCloseModal();
    }
  }, [dispatch, handleCloseModal, scriptFormConfig]);

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      header={<ModalTitle>{t('scripts.modal.title')}</ModalTitle>}
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t('scripts.modal.cancel')}
          </CancelButton>
          <button
            onClick={handleDeleteScript}
            className={classNames('hot-button hot-button--danger', {
              'hot-button--loading': loading,
            })}
          >
            {t('scripts.modal.delete')}
          </button>
        </>
      }
    >
      <Description>{t('scripts.modal.description')}</Description>
    </BaseModal>
  );
};

export const RemoveScriptModal = memo(RemoveScriptModalComponent);
