import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import classNames from 'classnames';

import React, { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Description } from './styles';

export type ShareUrlProps = HTMLAttributes<HTMLDivElement> & {
  name?: string;
  error?: boolean;
  errorMessage?: string;
  onNameChanged: (value: string) => void;
};

const FormNameComponent: React.FC<ShareUrlProps> = ({
  name,
  error,
  errorMessage,
  onNameChanged,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onNameChanged?.(e.target.value);
    },
    [onNameChanged]
  );

  return (
    <div {...attrs} className="hot-form">
      <Title htmlFor="shareUrl">{t('form.klicksend.formCreation.name')}</Title>
      <input
        id="shareUrl"
        className={classNames('hot-form__input  hot-form__input--sm', {
          'is-invalid': error,
        })}
        defaultValue={name}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={handleNameChange}
      />

      {error && <div className="invalid-feedback">{t(`${errorMessage}`)}</div>}

      <Description>{t('form.klicksend.formCreation.nameInfo')}</Description>
    </div>
  );
};

export const FormName = memo(FormNameComponent);
