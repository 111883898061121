import { memo, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { SideBarHeader } from './styles';

export type AiSideBarHeaderProps = HTMLAttributes<HTMLParagraphElement> & {
  title?: string;
};

const AiSideBarHeaderComponent: React.FC<AiSideBarHeaderProps> = ({
  title = 'pageWizard.salesPage.paidWithProduct.sidebar.headerText',
  ...attrs
}) => {
  const { t } = useTranslation();

  return <SideBarHeader {...attrs}>{t(title)}</SideBarHeader>;
};

export const AiSideBarHeader = memo(AiSideBarHeaderComponent);
