import { TokenNames } from '@services/token';
import { casEnabled } from '@auth/authService';
import ColaboratorTokenStrategy from '../tokenStrategies/colaboratorTokenStrategy';

const isColaborator = () => {
  const colaboratorTokenStrategy = new ColaboratorTokenStrategy();
  const colaboratorToken = colaboratorTokenStrategy.getToken();

  return Boolean(colaboratorToken);
};

export const getAuthTokenMode = () => {
  if (casEnabled()) {
    if (isColaborator()) {
      return TokenNames.colaborator;
    }

    return TokenNames.cas;
  }

  return TokenNames.api;
};
