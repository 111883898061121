import i18n from 'i18next';
import { modifierConfigs } from '@hotmart-org-ca/saas-pages-engine';
import {
  BackgroundModifier,
  BackgroundModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/BackgroundModifier';
import {
  CheckoutLinkModifier,
  CheckoutLinkModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/CheckoutLinkModifier';
import {
  RecommendImageSizeModifier,
  RecommendImageSizeModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/RecommendImageSizeModifier';
import {
  ElementLabelModifier,
  ElementLabelModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/ElementLabelModifier';
import {
  TransparentCheckoutModifier,
  TransparentCheckoutModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/TransparentCheckoutModifier';

import {
  PopupOptionsModifier,
  PopupOptionsModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/PopupOptionsModifier';
import {
  SavePopupModifier,
  SavePopupModifierProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/SavePopupModifier';
import {
  HotmartAIDropdownAction,
  HotmartAIDropdownActionProps,
} from '@components/Submenus/components/AdjustmentsSubmenu/components/HotmartAIDropdownAction';

const positioningCommonModifiers: CustomModifierConfig = {
  structure: [
    {
      id: 'custom-background',
      name: 'background',
      propName: { imageProp: 'background', isWebp: 'background-is-webp' },
      label: 'modifiers.background.name',
      group: 'style',
      imageButton: true,
      updateSchema: 'defaultDevice',
    },
  ],
  hiddenModifiers: ['background'],
  customModifiers: {
    'ls-modifier-custom-background-after': [
      (props: BackgroundModifierProps) => (
        <BackgroundModifier
          {...props}
          eventPrefix="background-modifier"
          displayMode="background"
        />
      ),
    ],
  },
};

export const getModifiersConfig: () => Record<string, CustomModifierConfig> =
  () => ({
    'ls-section': positioningCommonModifiers,
    'ls-row': positioningCommonModifiers,
    'ls-column': positioningCommonModifiers,
    'ls-group': positioningCommonModifiers,
    'ls-text': {
      actions: [
        {
          eventName: 'rewrite-text',
          icon: 'fal fa-sparkles',
          label: 'Hotmart AI',
        },
      ],
      actionSlots: {
        'ls-action-rewrite-text': [
          (props: HotmartAIDropdownActionProps) => (
            <HotmartAIDropdownAction {...props} />
          ),
        ],
      },
    },
    'ls-image': {
      structure: [
        {
          id: 'custom-src',
          name: 'image-background',
          propName: { imageProp: 'src', isWebp: 'is-webp' },
          label: 'modifiers.textInput.imageSrc',
          group: 'properties',
          imageButton: true,
          hasThumb: true,
          updateSchema: 'defaultDevice',
        },
        ...modifierConfigs['ls-image'].structure,
      ],
      replace: true,
      hiddenModifiers: ['src'],
      customModifiers: {
        'ls-modifier-custom-src-before': [
          (props: RecommendImageSizeModifierProps) => (
            <RecommendImageSizeModifier {...props} />
          ),
        ],
        'ls-modifier-custom-src-after': [
          (props: BackgroundModifierProps) => (
            <BackgroundModifier
              {...props}
              eventPrefix="image-background-modifier"
              displayMode="image"
            />
          ),
        ],
      },
    },
    'ls-button': {
      structure: [],
      customModifiers: {
        'ls-modifier-ls-button-linkAnchor-after': [
          (props: CheckoutLinkModifierProps) => (
            <CheckoutLinkModifier {...props} />
          ),
          (props: PopupOptionsModifierProps) => (
            <PopupOptionsModifier {...props} />
          ),
        ],
      },
    },
    'ls-popup': {
      customModifiers: {
        'ls-modifier-show-before': [
          (props: ElementLabelModifierProps) => (
            <ElementLabelModifier
              {...props}
              propName="label"
              label="sideMenu.pageStructure.popups.nameModifierLabel"
              component="ls-popup"
            />
          ),
        ],
        'ls-modifier-padding-after': [
          (props: SavePopupModifierProps) => <SavePopupModifier {...props} />,
        ],
      },
      actions: [
        {
          eventName: 'save-popup',
          icon: 'fal fa-bookmark',
          label: i18n.t('sideMenu.adjustments.popup.saveButton'),
        },
      ],
    },
    'ls-transparent-checkout': {
      structure: [
        {
          id: 'fake',
          propName: {},
          name: 'fake',
          group: 'settings',
        },
        ...modifierConfigs['ls-transparent-checkout'].structure,
      ],
      hiddenModifiers: ['offerKey'],
      replace: true,
      customModifiers: {
        'ls-modifier-fake-before': [
          (props: TransparentCheckoutModifierProps) => (
            <TransparentCheckoutModifier {...props} />
          ),
        ],
      },
    },
  });
