import { v4 as uuid } from 'uuid';

export const faqSections: PageFragmentListItem[] = [
  {
    id: 'faq-4',
    name: 'FAQ',
    template: {
      background: '#615B9B',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 32px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 24px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(255, 255, 255);">Ainda está com dúvidas?</strong></h1>',
                  'max-width': '100%',
                  padding: '56px 10px 8px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 32px; color: rgb(255, 255, 255);">Ainda está com dúvidas?</strong></p>',
                    'max-width': '70%',
                    padding: '48px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">Aproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!</span></p>',
                  'max-width': '65%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '85%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Aproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '16px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '8px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '600px',
                  margin: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '16px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Emite certificado?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Emite certificado?</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '600px',
                  margin: '10px 10px 10px 20px',
                  desktop: {},
                  tablet: {
                    margin: '20px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '16px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso baixar as aulas?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso baixar as aulas?</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '600px',
                  margin: '30px 20px 30px 10px',
                  desktop: {},
                  tablet: {
                    margin: '20px 10px 30px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '16px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso assistir o conteúdo até que dia?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso assistir o conteúdo até que dia?</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '600px',
                  margin: '30px 10px 30px 20px',
                  desktop: {},
                  tablet: {
                    margin: '0px 10px 20px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '16px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso parcelar a compra?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso parcelar a compra?</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '600px',
                  margin: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '16px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso pagar por boleto ou pix?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Posso pagar por boleto ou pix?</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '600px',
                  margin: '10px 10px 10px 20px',
                  desktop: {},
                  tablet: {
                    margin: '20px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '16px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Funciona para (Buyer Persona 1)</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="font-size: 24px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Funciona para (Buyer Persona 1)</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '56px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '32px 0px 56px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#615B9B',
                  background: '#FCDC4B',
                  'border-color': '#EF4E23',
                  'border-radius': '16px 16px 16px 16px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '24px',
                  'hover-background': '#FFE675',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'COMPRAR AGORA!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '250px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'FAQ',
        templateId: 'faq-1',
        templateName: 'FAQ',
      },
    },
  },
  {
    id: 'faq-5',
    name: 'FAQ',
    template: {
      background: '#F3F5FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 15',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 50px 30px',
              },
              mobile: {
                padding: '40px 0px 30px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 110px; color: rgb(59, 68, 183);">PERGUNTAS</strong></p>',
                  'max-width': '80%',
                  padding: '15px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 81px; color: rgb(59, 68, 183);">PERGUNTAS </strong></p>',
                    'max-width': '85%',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 41px; color: rgb(59, 68, 183);">PERGUNTAS</strong></p>',
                    'max-width': '90%',
                    padding: '10px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 110px; color: rgb(186, 191, 255);">FREQUENTES</strong></p>',
                  'max-width': '80%',
                  padding: '0px 10px 15px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 81px; color: rgb(186, 191, 255);">FREQUENTES</strong></p>',
                    'max-width': '85%',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 41px; color: rgb(186, 191, 255);">FREQUENTES</strong></p>',
                    'max-width': '90%',
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">[Coloque aqui as perguntas e respostas mais relevantes para a pessoa que está comprando seu produto. Por exemplo:]</span></p>',
                  'max-width': '100%',
                  padding: '20px 10px 10px 16px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 14px;">[Coloque aqui as perguntas e respostas mais relevantes para a pessoa que está comprando seu produto. Por exemplo:]</span></p>',
                    'max-width': '90%',
                    padding: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 50px 0px',
              },
              mobile: {
                padding: '0px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '20px 20px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#141436',
                  'max-width': '100%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="color: rgb(65, 78, 239); font-family: Montserrat, sans-serif; font-size: 18px;">01.</strong></p>',
                      'max-width': '4%',
                      padding: '10px 0px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '7%',
                      },
                      mobile: {
                        text: '<p><strong style="color: rgb(65, 78, 239); font-family: Montserrat, sans-serif; font-size: 20px;">01.</strong></p>',
                        'max-width': '13%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">EU PRECISO SER DESIGNER, EDITOR, REDATOR OU TER ALGUMA FORMAÇÃO PRÉVIA PARA VENDER ESSES SERVIÇOS?</strong></p>',
                      'max-width': '90%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 10px 10px',
                      },
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">EU PRECISO SER DESIGNER, EDITOR, REDATOR OU TER ALGUMA FORMAÇÃO PRÉVIA PARA VENDER ESSES SERVIÇOS?</strong></p>',
                        padding: '10px 10px 10px 13px',
                        'max-width': '87%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 10px 20px 10px',
                  'border-radius': '0px 0px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '100%',
                  margin: '0px 10px 40px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    padding: '15px 10px 15px 10px',
                    margin: '0px 10px 32px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="color: rgb(20, 20, 54); background-color: transparent; font-family: Montserrat, sans-serif;">Não! Você aprenderá tudo que precisa aqui no Profissionais da Internet! Do zero!</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="color: rgb(20, 20, 54); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 14px;">Não! Você aprenderá tudo que precisa aqui no Profissionais da Internet! Do zero!</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '20px 20px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#141436',
                  'max-width': '100%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="color: rgb(65, 78, 239); font-family: Montserrat, sans-serif; font-size: 18px;">02.</strong></p>',
                      'max-width': '4%',
                      padding: '10px 0px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '7%',
                      },
                      mobile: {
                        text: '<p><strong style="color: rgb(65, 78, 239); font-family: Montserrat, sans-serif; font-size: 20px;">02.</strong></p>',
                        'max-width': '14%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">JÁ SOU COPYWRITER, GESTOR DE TRÁFEGO, DESIGNER OU SOCIAL MÍDIA, ENTÃO NÃO É PRA MIM?</strong></p>',
                      'max-width': '90%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 10px 10px',
                      },
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">JÁ SOU COPYWRITER, GESTOR DE TRÁFEGO, DESIGNER OU SOCIAL MÍDIA, ENTÃO NÃO É PRA MIM?</strong></p>',
                        padding: '10px 10px 10px 13px',
                        'max-width': '86%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 10px 20px 10px',
                  'border-radius': '0px 0px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '100%',
                  margin: '0px 10px 40px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    padding: '15px 10px 15px 10px',
                    margin: '0px 10px 32px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="color: rgb(20, 20, 54); background-color: transparent; font-family: Montserrat, sans-serif;">Claro que é! Aqui você aprenderá diversas outras habilidades, transformando-se em um profissional multidisciplinar.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="color: rgb(20, 20, 54); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 14px;">Claro que é! Aqui você aprenderá diversas outras habilidades, transformando-se em um profissional multidisciplinar.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '20px 20px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#141436',
                  'max-width': '100%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="color: rgb(65, 78, 239); font-family: Montserrat, sans-serif; font-size: 18px;">03.</strong></p>',
                      'max-width': '4%',
                      padding: '10px 0px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '7%',
                      },
                      mobile: {
                        text: '<p><strong style="color: rgb(65, 78, 239); font-family: Montserrat, sans-serif; font-size: 20px;">03.</strong></p>',
                        'max-width': '14%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">PRECISO SABER USAR AS FERRAMENTAS PARA FAZER O CURSO? PHOTOSHOP, EDITORES DE VÍDEO, WORDPRESS, ETC?</strong></p>',
                      'max-width': '90%',
                      padding: '10px 10px 10px 0px',
                      desktop: {},
                      tablet: {
                        padding: '10px 0px 10px 10px',
                      },
                      mobile: {
                        text: '<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">PRECISO SABER USAR AS FERRAMENTAS PARA FAZER O CURSO? PHOTOSHOP, EDITORES DE VÍDEO, WORDPRESS, ETC?</strong></p>',
                        padding: '10px 0px 10px 13px',
                        'max-width': '86%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 10px 20px 10px',
                  'border-radius': '0px 0px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '100%',
                  margin: '0px 10px 40px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    padding: '15px 10px 15px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="color: rgb(20, 20, 54); background-color: transparent; font-family: Montserrat, sans-serif;">Não! Fique tranquilo, nós vamos te guiar em tudo desde o início.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="color: rgb(20, 20, 54); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 14px;">Não! Fique tranquilo, nós vamos te guiar em tudo desde o início.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'faq-6',
    name: 'FAQ',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'FAQ',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 50px 0px',
              },
              mobile: {
                padding: '40px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(147, 154, 189); font-size: 30px;">Para as perguntas mais frequentes sobre o curso, você tem as respostas abaixo:</span></p>',
                  'max-width': '60%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '78%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(147, 154, 189); font-size: 23px;">Para as perguntas mais frequentes sobre o curso, você tem as respostas abaixo:</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">O que é o (NOME DO SEU MÉTODO)? Como posso me inscrever?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">O que é o (NOME DO SEU MÉTODO)? Como posso me inscrever?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">O (NOME DO SEU CURSO) é online ou presencial?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">O (NOME DO SEU CURSO) é online ou presencial?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="color: rgb(57, 57, 57); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Estou começando agora. O (NOME DO SEU CURSO) é para mim?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">Estou começando agora. O (NOME DO SEU CURSO) é para mim?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Por quanto tempo terei acesso ao conteúdo?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">Por quanto tempo terei acesso ao conteúdo?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Moro fora do Brasil. Posso fazer o curso?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">Moro fora do Brasil. Posso fazer o curso?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Vou ganhar certificado?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">Vou ganhar certificado?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Como funciona a garantia?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">Como funciona a garantia?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 60px 0px',
              },
              mobile: {
                padding: '0px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#393939',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Quais são as formas de pagamento?</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 16px;">Quais são as formas de pagamento?</strong></p>',
                        padding: '5px 10px 5px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(57, 57, 57);">Breve explicativo que não passe de 4 linhas.</span></p>',
                        padding: '0px 10px 0px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 60px 0px',
              },
              mobile: {
                padding: '0px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#FFFFFF',
                  background: '#FF5F33',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO COMPRAR AGORA!',
                  margin: '15px 20px 20px 0px',
                  'max-width': '400px',
                  mobile: {
                    'font-size': '16px',
                    'max-width': '300px',
                    padding: '13px 16px 13px 16px',
                    margin: '15px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'faq-1',
    name: 'FAQ',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003eAinda está com dúvidas?\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '48px 10px 8px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eAproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '65%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '16px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '600px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eEmite certi\u003c/strong\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif;"\u003eficado?\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '600px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003ePosso baixar as aulas?\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '600px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003ePosso assistir o conteúdo até que dia?\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '600px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003ePosso parcelar a compra?\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '600px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003ePosso pagar por boleto ou pix?\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '600px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eFunciona para (Buyer Persona 1)\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '18px 0px 48px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#FFFFFF',
                  background: '#424242',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Nunito Sans, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Comprar agora!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 6' },
    },
  },
  {
    id: 'faq-2',
    name: 'FAQ',
    template: {
      background: '#EDF0F4',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 40px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 20px 0px' },
              mobile: { padding: '30px 20px 20px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 40px;"\u003eAinda está com dúvidas?\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eAproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 16px;"\u003eAproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eAproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 30px 20px 50px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '15px 30px 15px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 20px;"\u003eEmite certificado?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eEmite certificado?\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 50px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: { padding: '15px 30px 15px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-size: 20px; font-family: Raleway, sans-serif;"\u003ePosso baixar as aulas?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-size: 18px; font-family: Raleway, sans-serif;"\u003ePosso baixar as aulas?\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 30px 20px 50px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '15px 30px 15px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 20px;"\u003ePosso assistir o conteúdo até que dia?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 18px;"\u003ePosso assistir o conteúdo até que dia?\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 50px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: { padding: '15px 30px 15px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: Raleway, sans-serif;"\u003ePosso parcelar a compra?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cstrong style="font-size: 18px; font-family: Raleway, sans-serif;"\u003ePosso parcelar a compra?\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 30px 20px 50px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '18px 30px 15px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: Raleway, sans-serif;"\u003ePosso pagar por boleto ou pix?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cstrong style="font-size: 18px; font-family: Raleway, sans-serif;"\u003ePosso pagar por boleto ou pix?\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 50px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: { padding: '15px 30px 16px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: Raleway, sans-serif;"\u003eFunciona para (Buyer Persona 1)\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cstrong style="font-size: 18px; font-family: Raleway, sans-serif;"\u003eFunciona para (Buyer Persona 1)\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 0px 90px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '30px 0px 50px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#000000',
                  background: '#79E0BB',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Comprar agora!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '300px',
                  mobile: {},
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 6' },
    },
  },
  {
    id: 'faq-3',
    name: 'FAQ',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 40px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 20px 0px' },
              mobile: {
                background: '#FFFFFF',
                padding: '50px 30px 20px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-size: 32px; font-family: Montserrat, sans-serif;"\u003eAinda está com dúvidas?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 28px; font-family: Montserrat, sans-serif;"\u003eAinda está com dúvidas?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eAproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px;"\u003eAproveite esse espaço para listar as dúvidas mais frequentes do seu público. Faça uma espécie de FAQ e aproveite para deixar seu comprador sem dúvidas sobre a qualidade do seu produto!\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 30px 20px 30px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: {
                padding: '20px 30px 20px 30px',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-size: 18px; font-family: Montserrat, sans-serif;"\u003eEmite certificado?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-size: 17px; font-family: Montserrat, sans-serif;"\u003eEmite certificado?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 30px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: {
                padding: '20px 30px 20px 30px',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003ePosso baixar as aulas?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 17px;"\u003ePosso baixar as aulas?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 30px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: {
                padding: '20px 30px 20px 30px',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003ePosso assistir o conteúdo até que dia?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 17px;"\u003ePosso assistir o conteúdo até que dia?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 30px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: {
                padding: '20px 30px 20px 30px',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-size: 18px; font-family: Montserrat, sans-serif;"\u003ePosso parcelar a compra?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 17px;"\u003ePosso parcelar a compra?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 30px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: {
                padding: '20px 30px 20px 30px',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003ePosso pagar por boleto ou pix?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 17px;"\u003ePosso pagar por boleto ou pix?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 30px 20px 30px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 50px 20px 50px' },
              mobile: {
                padding: '20px 30px 20px 30px',
                background: '#FFFFFF',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eFunciona para (Buyer Persona 1)\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 17px;"\u003eFunciona para (Buyer Persona 1)\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '95%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eLorem ipsum dolor sit ametconsectetur adipiscing elit. Nam in magna id mauris feugiat fermentum. Nam at lobortis nisi.\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 0px 90px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '30px 0px 50px 0px' },
              mobile: { background: '#FFFFFF' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#FFFFFF',
                  background: '#E5154A',
                  'border-color': '#EF4E23',
                  'border-radius': '27px 27px 27px 27px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Comprar agora!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '263px',
                  mobile: {},
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 9' },
    },
  },
];
