import styled, { css } from 'styled-components';

type StyledWrapperProps = {
  displayMode?: 'hover' | 'always';
};

export const Wrapper = styled.div<StyledWrapperProps>`
  margin-left: auto;
  display: flex;
  align-content: center;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ displayMode }) => css`
    ${displayMode === 'hover'
      ? css`
          :hover {
            hot-tooltip {
              display: inline-block;
            }
          }
          hot-tooltip {
            display: none;
          }
        `
      : ''}
  `}

  hot-tooltip {
    margin-left: auto;
  }

  label {
    margin-bottom: 4px;
  }
`;

export const Prepend = styled.div`
  margin-right: 8px;
`;

export const Append = styled.div`
  margin-left: auto;
`;
