import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/index';
import { useCasesThunks } from '@store/thunks';
import UseCases from '@services/useCases';
import {
  setLoading as setUseCaseLoading,
  setIsPublishing as setUseCaseIsPublishing,
  setFooterRightButtonConfig as setFooterRightButton,
  setFinishedInFirstAccess as setUseCaseFirstAccess,
} from '@store/slices/useCases';

export const useUseCases = () => {
  const dispatch = useAppDispatch();

  const loading = useSelector((state: State) => state.useCases.loading);

  const loadingAI = useSelector((state: State) => state.useCases.loadingAI);

  const isPublishing = useSelector(
    (state: State) => state.useCases.isPublishing
  );

  const properties = useSelector((state: State) => state.useCases.properties);

  const currentStep = useSelector((state: State) => state.useCases.currentStep);

  const maxStep = useSelector((state: State) => state.useCases.maxStep);

  const finishedInFirstAccess = useSelector(
    (state: State) => state.useCases.finishedInFirstAccess
  );

  const footerRightButton = useSelector(
    (state: State) => state.useCases.footerRightButton
  );

  const isLoading = useMemo(() => loading || loadingAI, [loading, loadingAI]);

  const generateAiProperties = useCallback(
    () => dispatch(useCasesThunks.generateAiProperties()).unwrap(),
    [dispatch]
  );

  const stepEvents = useMemo<UseCaseEventListByType>(
    () => ({
      sales_page_paid_with_product: {
        generateAiProperties,
      },
      sales_page_paid_without_product: {
        generateAiProperties,
      },
    }),
    [generateAiProperties]
  );

  const generateTitleAiProperties = useCallback(
    ({ productName, productDescription }: GetTitleTextsFromAIAttributes) =>
      UseCases.getTitleTextsFromAi(productName, productDescription),
    []
  );

  const generateAiAuthorDescription = useCallback(
    (userTypedDescription: string) =>
      UseCases.getAboutTheAuthorFromAI(userTypedDescription),
    []
  );

  const generateDescriptionAiProperties = useCallback(
    (userTypedText: GetDescriptionFromAIAttributes) =>
      UseCases.getProductDescriptionsFromAI(
        userTypedText.productTitle,
        userTypedText.productDescription
      ),
    []
  );

  const generateCapturePageAiDescription = useCallback(
    (type: string, benefit: string, generatedOptions?: UseCaseOptions) =>
      UseCases.getCapturePageDescriptionFromAI(type, benefit, generatedOptions),
    []
  );

  const getUseCase = useCallback(
    (id: number) => dispatch(useCasesThunks.getUseCase(id)).unwrap(),
    [dispatch]
  );

  const saveProperties = useCallback(
    (id: number, props: any) =>
      dispatch(
        useCasesThunks.saveProperties({ id, properties: props })
      ).unwrap(),
    [dispatch]
  );

  const saveCurrentStep = useCallback(
    (id: number, step: number) =>
      dispatch(useCasesThunks.saveUseCase({ id, currentStep: step })).unwrap(),
    [dispatch]
  );

  const saveUseCase = useCallback(
    (id: number, props: any, step: number) =>
      dispatch(
        useCasesThunks.saveUseCase({ id, currentStep: step, properties: props })
      ).unwrap(),
    [dispatch]
  );

  const setLoading = useCallback(
    (value: boolean) => {
      dispatch(setUseCaseLoading(value));
    },
    [dispatch]
  );

  const setIsPublishing = useCallback(
    (value: boolean) => {
      dispatch(setUseCaseIsPublishing(value));
    },
    [dispatch]
  );

  const setFooterRightButtonConfig = useCallback(
    (value: FooterRightButtonConfig) => {
      dispatch(setFooterRightButton(value));
    },
    [dispatch]
  );

  const setFinishedInFirstAccess = useCallback(
    (value: boolean) => {
      dispatch(setUseCaseFirstAccess(value));
    },
    [dispatch]
  );

  const getStepEvent = useCallback(
    (type: UseCaseType, eventName: string) => {
      const eventsByType = stepEvents[type];

      if (eventsByType) {
        return eventsByType[eventName];
      }

      return undefined;
    },
    [stepEvents]
  );

  return {
    currentStep,
    isLoading,
    loading,
    loadingAI,
    maxStep,
    properties,
    isPublishing,
    footerRightButton,
    finishedInFirstAccess,
    generateAiProperties,
    generateTitleAiProperties,
    generateAiAuthorDescription,
    generateDescriptionAiProperties,
    generateCapturePageAiDescription,
    getUseCase,
    getStepEvent,
    saveCurrentStep,
    setIsPublishing,
    setLoading,
    setFooterRightButtonConfig,
    setFinishedInFirstAccess,
    saveProperties,
    saveUseCase,
  };
};
