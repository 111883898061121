import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ComponentState,
  EditorEngineManager,
  getDeviceValue,
  Template,
  ActiveComponentState,
} from '@hotmart-org-ca/saas-pages-engine';
import { useUserInfo } from '@hooks/useUserInfo';
import { useTranslation } from 'react-i18next';
import camelcase from 'camelcase';
import TagManager from 'react-gtm-module';
import { usePageInfo } from '@hooks/usePageInfo';
import { Submenu } from '../../../Submenu';
import { Select } from '../../../Select';
import { Content, Description, Divider } from './styles';
import { HTMLFormConfig } from './components/HTMLFormConfig';
import { KlicksendFormConfig } from './components/KlicksendFormConfig';
import { SimpleFormConfig } from './components/SimpleFormConfig';
import { getDefaultSimpleForm } from './components/SimpleFormConfig/simpleFormUtils';
import { getDefaultHtmlForm } from './components/HTMLFormConfig/formUtils';

export const FormsSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const { isFunnelCapturePage } = usePageInfo();

  const slices = useSelector((state: State) => state);
  const pageId = useSelector((state: State) => state.pageInfo.uuid);
  const activeComponent = useSelector(
    (state: State) => state.lsEditor.activeComponent
  );
  const { pageWithRedirectEnabled } = useUserInfo();

  const [externalKlicksend, setExternalKlicksend] = useState(false);
  const [htmlValue, setHtmlValue] = useState('');
  const [checkedOwnForm, setCheckedOwnForm] = useState(false);
  const [klicksendValue, setKlicksendValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const selectOptions = useMemo(() => {
    const options = [
      {
        id: 'klicksend',
        label: t('form.selectOption.klicksend'),
        value: 'klicksend',
      },
      {
        id: 'html',
        label: t('form.selectOption.html'),
        value: 'html',
      },
    ];
    return options;
  }, [t]);

  const updateStates = useCallback(
    (html: string = '', klicksend: string = '', option: string = '') => {
      setHtmlValue(html);
      setKlicksendValue(klicksend);
      setSelectedOption(option);
    },
    []
  );

  const checkActiveComponent = useCallback(() => {
    if (activeComponent) {
      const { component, uid } = activeComponent;

      if (
        component === 'ls-html-form' ||
        component === 'ls-form' ||
        externalKlicksend ||
        activeComponent.isExternalKsend
      ) {
        const value = getDeviceValue(uid, 'html', '');
        const simpleForm = getDeviceValue(uid, 'simple-form', '');
        const option = simpleForm ? 'simple-form' : 'html';

        updateStates(value, '', option);
      } else if (component === 'ls-klicksend-form') {
        const formId = getDeviceValue(uid, 'form-id', '');
        updateStates('', formId, 'klicksend');
      } else {
        updateStates();
      }
    }
  }, [activeComponent, externalKlicksend, updateStates]);

  const replaceElement = useCallback(
    (form: Template, activeElement: ActiveComponentState) => {
      const formUid = EditorEngineManager.replaceElement(
        activeElement.uid,
        form
      );
      EditorEngineManager.setActive({
        ...activeElement,
        component: form.component,
        uid: formUid,
      });
    },
    []
  );

  const handleFormChange = useCallback(
    (form?: Template) => {
      if (!form) {
        return;
      }
      setExternalKlicksend(Boolean(form.externalKsend));
      if (activeComponent) {
        const htmlFormName = 'ls-html-form';
        const externalForm = 'ls-form';
        const { component: formComponent, uid: _formUid, ...props } = form;
        const { component, uid } = activeComponent;

        if (
          (component === htmlFormName && formComponent === htmlFormName) ||
          (component === externalForm && formComponent === externalForm)
        ) {
          if (formComponent === externalForm) {
            EditorEngineManager.updateFormElement(uid, { props });
          } else {
            EditorEngineManager.updateElement(uid, {
              props,
            });
          }
        } else {
          replaceElement(form, activeComponent);
        }
      }
    },
    [activeComponent, replaceElement]
  );

  const handleSelectChange = useCallback(
    (option: string) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_editor_forms_list',
          event_details: t(`form.selectOption.${camelcase(option)}`, {
            lng: 'en',
          }),
        },
      });

      if (option === 'html' && !pageWithRedirectEnabled) {
        setSelectedOption(option);
        return;
      }
      setSelectedOption(option);

      if (option === 'simple-form') {
        handleFormChange(getDefaultSimpleForm());
        return;
      }

      if (option === 'html') {
        handleFormChange(getDefaultHtmlForm());
      }
    },
    [handleFormChange, pageWithRedirectEnabled, t]
  );

  useEffect(() => {
    checkActiveComponent();
  }, [checkActiveComponent]);

  useEffect(() => {
    if (activeComponent) {
      const { component, uid } = activeComponent;
      const slice = slices[uid] as ComponentState;

      if (
        (component === 'ls-html-form' ||
          component === 'ls-form' ||
          externalKlicksend) &&
        slice &&
        slice.props.html
      ) {
        setCheckedOwnForm(slice.props.ownCode);
        setHtmlValue(slice.props.html);
      }
    }
  }, [activeComponent, externalKlicksend, slices]);

  return (
    <Submenu title="sideMenu.forms.title" padding="0px" {...attrs}>
      {!isFunnelCapturePage && (
        <Content>
          <Select
            id="form-options"
            label={t('form.label')}
            placeholder={t('form.placeholder')}
            selectedOption={selectedOption}
            options={selectOptions}
            onChange={handleSelectChange}
            size="sm"
          />
          {selectedOption === 'klicksend' && (
            <Description>{t('form.klicksend.description')}</Description>
          )}
        </Content>
      )}

      {!isFunnelCapturePage && <Divider />}

      <Content padding="0" margin={isFunnelCapturePage ? '16px 0' : '0'}>
        {selectedOption === 'klicksend' && (
          <KlicksendFormConfig
            value={klicksendValue}
            onFormChange={(form) => handleFormChange(form)}
          />
        )}
        {selectedOption === 'html' && (
          <HTMLFormConfig
            value={htmlValue}
            isCheckedOwnForm={checkedOwnForm}
            onFormChange={(form) => handleFormChange(form)}
            isBlocked={!pageWithRedirectEnabled}
          />
        )}
        {selectedOption === 'simple-form' && (
          <SimpleFormConfig pageId={pageId} />
        )}
      </Content>
    </Submenu>
  );
};
