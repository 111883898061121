import { TextInput } from '@components/TextInput';
import styled from 'styled-components';

export const AiButtonWrapper = styled.div`
  min-width: 192px;
  align-self: flex-end;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 24px;
`;
