type RootAddableTemplateConfig = {
  elementType: 'popup' | 'section';
  metadataLabel: string;
  props?: Record<string, unknown>;
};

export const getRootAddableTemplate = ({
  elementType,
  metadataLabel,
  props = {},
}: RootAddableTemplateConfig) => ({
  uid: elementType,
  component: `ls-${elementType}`,
  metadata: { label: metadataLabel },
  ...props,
  children: [
    {
      uid: 'row',
      component: 'ls-row',
      children: [
        {
          uid: 'column',
          component: 'ls-column',
        },
      ],
    },
  ],
});
