import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { START_ONBOARDING_EVENT_NAME } from '@common/constants';
import { BodyPopover } from '@components/BodyPopover';
import { OnboardingModal } from './components/OnboardingModal';
import { OnboardingSteps } from './components/OnboardingSteps';
import { OnboardingStepsModal } from './components/OnboardingStepsModal';
import { RemainderPopoverBody } from './styles';

const onboardingConfigKey = 'hotmartOnboardingConfig';

export const Onboarding: React.FC = () => {
  const { t } = useTranslation();
  const [showRemainderPopover, setShowRemainderPopover] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showOnboardingStepsModal, setShowOnboardingStepsModal] =
    useState(false);

  const saveOnboardingConfig = useCallback((config: OnboardingConfig) => {
    localStorage.setItem(onboardingConfigKey, JSON.stringify(config));
  }, []);

  const openRemainderPopover = useCallback((sleep = 0) => {
    setTimeout(() => {
      setShowRemainderPopover(true);
    }, sleep);
  }, []);

  const handleCloseModal = useCallback(
    (finished) => {
      saveOnboardingConfig({ when: '', finished });
      setShowOnboardingModal(false);

      openRemainderPopover(600);
    },
    [openRemainderPopover, saveOnboardingConfig]
  );

  const handleRemainder = useCallback(
    (when: string) => {
      saveOnboardingConfig({ when, finished: false });
      setShowOnboardingModal(false);

      openRemainderPopover(600);
    },
    [openRemainderPopover, saveOnboardingConfig]
  );

  const handleStart = useCallback(
    (finished: boolean) => {
      saveOnboardingConfig({ when: '', finished });
      setShowOnboardingModal(false);

      setTimeout(() => {
        window.dispatchEvent(new CustomEvent(START_ONBOARDING_EVENT_NAME));
      }, 300);
    },
    [saveOnboardingConfig]
  );

  const openOnboardingAtStep = useCallback((step: number = 1) => {
    setShowOnboardingStepsModal(false);

    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent(START_ONBOARDING_EVENT_NAME, { detail: step })
      );
    }, 300);
  }, []);

  const handleStepsFinished = useCallback(() => {
    saveOnboardingConfig({ when: '', finished: true });
    openRemainderPopover();
  }, [openRemainderPopover, saveOnboardingConfig]);

  const openOnboardingStepsModal = useCallback(() => {
    setShowOnboardingStepsModal(true);
  }, []);

  useEffect(() => {
    const onboardingConfig = localStorage.getItem(onboardingConfigKey);

    if (!onboardingConfig) {
      setShowOnboardingModal(true);
      return;
    }

    const { when, finished }: OnboardingConfig = JSON.parse(onboardingConfig);

    if (!finished) {
      if (when) {
        const showOnboardingDate = new Date(when);
        const shouldShow = new Date() >= showOnboardingDate;

        setShowOnboardingModal(shouldShow);
      } else {
        setShowOnboardingModal(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener(
      'open-onboarding-steps-modal',
      openOnboardingStepsModal
    );

    return () => {
      window.removeEventListener(
        'open-onboarding-steps-modal',
        openOnboardingStepsModal
      );
    };
  }, [openOnboardingStepsModal]);

  return (
    <div>
      <OnboardingModal
        isOpen={showOnboardingModal}
        onClose={handleCloseModal}
        onRemainderClick={handleRemainder}
        onStart={handleStart}
      />

      <OnboardingStepsModal
        isOpen={showOnboardingStepsModal}
        onClose={() => setShowOnboardingStepsModal(false)}
        onOpenOnboarding={openOnboardingAtStep}
      />

      <OnboardingSteps
        onFinish={handleStepsFinished}
        onClose={openRemainderPopover}
      />

      {showRemainderPopover && (
        <BodyPopover
          elementSelector="#helper-button"
          openOnRender
          position="right"
          popoverCustomStyle="top: unset; bottom: -64px;"
          arrowCustomStyle="top: unset; bottom: 8px;"
          onTriggerClick={() => setShowRemainderPopover(false)}
        >
          <RemainderPopoverBody slot="body" className="hot-popover__body">
            <span>{t('onboarding.remainderPopover.description')}</span>

            <button
              className="hot-button hot-button--secondary"
              onClick={() => setShowRemainderPopover(false)}
            >
              {t('onboarding.remainderPopover.closeButton')}
            </button>
          </RemainderPopoverBody>
        </BodyPopover>
      )}
    </div>
  );
};
