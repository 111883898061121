import { CosmosSwitch } from '@components/CosmosSwitch';
import { HTMLAttributes, useCallback } from 'react';

export type TemplateStatusProps = HTMLAttributes<HTMLDivElement> & {
  isNew?: boolean;
  isExpert?: boolean;
  onStatusChange?: (isNew: boolean, isExpert: boolean) => void;
};

export const TemplateStatus: React.FC<TemplateStatusProps> = ({
  isNew = false,
  isExpert = false,
  onStatusChange,
  ...attrs
}) => {
  const handleIsNewChange = useCallback(
    (value: boolean) => {
      onStatusChange?.(value, isExpert);
    },
    [isExpert, onStatusChange]
  );

  const handleIsExpertChange = useCallback(
    (value: boolean) => {
      onStatusChange?.(isNew, value);
    },
    [isNew, onStatusChange]
  );

  return (
    <div {...attrs}>
      <CosmosSwitch
        id="is-new"
        label="templateCategorization.status.isNew"
        isChecked={isNew}
        onSwitchChanged={handleIsNewChange}
      />
      <CosmosSwitch
        id="is-expert"
        label="templateCategorization.status.isExpert"
        isChecked={isExpert}
        onSwitchChanged={handleIsExpertChange}
      />
    </div>
  );
};
