import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import Klickart from '@services/klickart';
import Token, { TokenNames } from '@services/token';
import {
  extensionsPlanNameByPlanCode,
  extensionsProductNameByProductType,
} from '@common/plan-info';
import Toast from '@services/toast';
import classNames from 'classnames';
import { useDataHub } from '@hooks/index';
import Api from '@services/api';
import {
  Container,
  Title,
  Description,
  ActionsContainer,
  PrimaryButton,
  SecondaryButton,
  ModalFooterStyle,
  ServiceList,
  ServiceListTitle,
  ServiceListItem,
  ServiceIcon,
  PlanLabel,
  PlanContainer,
  PlanTag,
  BodyContainer,
} from './styles';

type FreeToPaidModalProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  isOpen: boolean;
  primaryButtonIcon?: string;
  fixedSize?: string;
  buttonsMinWidth?: string;
  closeIconAbsolute?: boolean;
  origin: string;
  onClose?: () => void;
  onSecondayButtonClick?: () => void;
  onTrialActivated: () => void;
};

const FreeToPaidModalComponent: React.FC<FreeToPaidModalProps> = ({
  isOpen,
  primaryButtonIcon,
  fixedSize,
  buttonsMinWidth,
  closeIconAbsolute,
  origin,
  onClose,
  onTrialActivated,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { sendTrialMetrify } = useDataHub();

  const [planName, setPlanName] = useState('');
  const [isLoading, setLoading] = useState(false);

  const renderByTypeOf = useCallback(
    (value: string | React.ReactElement) =>
      typeof value === 'string' ? t(value) : value,
    [t]
  );

  const getPlanInfo = useCallback(async () => {
    try {
      const planInfo = await Klickart.getExtensionsInfo();

      const productName = extensionsProductNameByProductType(
        planInfo.plan.productType
      );
      const planNameByCode = extensionsPlanNameByPlanCode(
        planInfo.plan.planCode
      );

      setPlanName(`${t(productName)} ${t(planNameByCode)}`);
    } catch (error) {
      Toast.error('toastMessages.general.error.generic');
    }
  }, [t]);

  useEffect(() => {
    getPlanInfo();
  }, [getPlanInfo]);

  const handlePrimaryButtonClick = useCallback(async () => {
    try {
      setLoading(true);
      await Klickart.startUserTrial(origin);
      sendTrialMetrify('trial_test_1_variant', 'trial_activated', origin);
      Token.remove();
      await Token.refreshToken(Api.getInstance(), TokenNames.api);
      onTrialActivated?.();
    } catch (error) {
      Toast.error('toastMessages.general.error.generic');
    } finally {
      setLoading(false);
    }
  }, [onTrialActivated, origin, sendTrialMetrify]);

  useEffect(() => {
    if (isOpen) {
      sendTrialMetrify('trial_test_1_variant', 'modal_2_view', origin);
    }
  }, [isOpen, origin, sendTrialMetrify]);

  const serviceItems = useMemo(
    () => [
      {
        value: 1,
        label: 'modals.freeToPaidModal.list.item1',
      },
      {
        value: 2,
        label: 'modals.freeToPaidModal.list.item2',
      },
      {
        value: 3,
        label: 'modals.freeToPaidModal.list.item3',
      },
      {
        value: 4,
        label: 'modals.freeToPaidModal.list.item4',
      },
      {
        value: 5,
        label: 'modals.freeToPaidModal.list.item5',
      },
    ],
    []
  );

  return (
    <Container>
      <ModalFooterStyle />
      <BaseModal
        {...attrs}
        fixedSize={fixedSize || '458px'}
        isOpen={isOpen}
        closeIconAbsolute={closeIconAbsolute}
        onClose={onClose}
        header={
          <PlanContainer>
            <PlanLabel>{t('modals.freeToPaidModal.currentPlan')}</PlanLabel>
            <PlanTag>
              <hot-tag class="hot-tag--green hot-tag--sm">{planName}</hot-tag>
            </PlanTag>
          </PlanContainer>
        }
        footerClass="modal-warning-footer"
        footer={
          <ActionsContainer>
            <PrimaryButton
              className={classNames(`hot-button hot-button--primary`, {
                'hot-button--loading': isLoading,
              })}
              onClick={handlePrimaryButtonClick}
              minWidth={buttonsMinWidth}
            >
              {t('modals.freeToPaidModal.newPlanButton')}
              {primaryButtonIcon && (
                <span>
                  <i className={primaryButtonIcon} />
                </span>
              )}
            </PrimaryButton>
            <SecondaryButton
              className="hot-button hot-button--tertiary"
              onClick={onClose}
              minWidth={buttonsMinWidth}
            >
              {t('modals.freeToPaidModal.closeButton')}
            </SecondaryButton>
          </ActionsContainer>
        }
      >
        <BodyContainer>
          <Title>
            {renderByTypeOf(
              <Trans
                i18nKey="modals.freeToPaidModal.title"
                components={{ b: <b /> }}
              />
            )}
          </Title>
          <Description>
            {renderByTypeOf(
              <Trans
                i18nKey="modals.freeToPaidModal.description"
                components={{ b: <b /> }}
              />
            )}
          </Description>
          <ServiceList>
            <ServiceListTitle>
              {t('modals.freeToPaidModal.list.title')}
            </ServiceListTitle>
            {serviceItems.map((item) => (
              <ServiceListItem key={item.value}>
                <ServiceIcon className="far fa-check" />
                {t(item.label)}
              </ServiceListItem>
            ))}
          </ServiceList>
        </BodyContainer>
      </BaseModal>
    </Container>
  );
};

export const FreeToPaidModal = memo(FreeToPaidModalComponent);
