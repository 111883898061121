import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  createUserSection,
  createUserSectionBuilder,
} from './createUserSection';
import {
  updateUserSection,
  updateUserSectionBuilder,
} from './updateUserSection';
import {
  deleteUserSection,
  deleteUserSectionBuilder,
} from './deleteUserSection';
import { getUserSections, getUserSectionsBuilder } from './getUserSections';
import { createUserPopup, createUserPopupBuilder } from './createUserPopup';
import { deleteUserPopup, deleteUserPopupBuilder } from './deleteUserPopup';
import { getUserPopups, getUserPopupsBuilder } from './getUserPopups';
import { updateUserPopup, updateUserPopupBuilder } from './updateUserPopup';

export const pageFragmentsThunks = {
  createUserPopup,
  createUserSection,
  deleteUserPopup,
  deleteUserSection,
  getUserPopups,
  getUserSections,
  updateUserPopup,
  updateUserSection,
};

export const pageFragmentsExtraReducers = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  createUserPopupBuilder(builder);
  createUserSectionBuilder(builder);
  deleteUserPopupBuilder(builder);
  deleteUserSectionBuilder(builder);
  getUserPopupsBuilder(builder);
  getUserSectionsBuilder(builder);
  updateUserPopupBuilder(builder);
  updateUserSectionBuilder(builder);
};
