import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { VideoUpload } from './components/VideoUpload';
import { DescriptionInput } from './components/DescriptionInput';
import { AiSidebar } from './components/AiSidebar';
import { SalesUseCase } from '../../salesUseCase';

type DescribeProductProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    updatedProduct: UpdatedProductProps;
    product: ProductProps;
    useCase: SalesUseCase;
    template?: SalesPageTemplateType;
  };

const DescribeProductComponent: React.FC<DescribeProductProps> = ({
  updatedProduct,
  product,
  useCase,
  template,
  onModifierChange,
  ...attrs
}) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  const handleDescriptionChange = useCallback(
    (selectedDescriptionSuggestion: string) => {
      useCase.modifyByCategory(
        'productDescription',
        selectedDescriptionSuggestion
      );

      onModifierChange({
        prop: 'updatedProduct',
        value: {
          ...updatedProduct,
          description: selectedDescriptionSuggestion,
        },
      });
      setIsSideBarOpen(false);
    },
    [onModifierChange, updatedProduct, useCase]
  );

  const handleVideoSrcChange = useCallback(
    (videoSrc: string) => {
      useCase.modifyByCategory('videoSrc', videoSrc);

      onModifierChange({
        prop: 'product',
        value: {
          ...product,
          videoSrc,
        },
      });
    },
    [onModifierChange, product, useCase]
  );

  const handleHasVideoChange = useCallback(
    (hasVideo: boolean) => {
      useCase.modifyByCategory('hasVideo', hasVideo, {
        videoSrc: product.videoSrc,
        template,
      });

      onModifierChange({
        prop: 'product',
        value: {
          ...product,
          hasVideo,
        },
      });
    },
    [onModifierChange, product, template, useCase]
  );

  return (
    <div {...attrs}>
      <DescriptionInput
        description={updatedProduct.description}
        onTextChanged={handleDescriptionChange}
        onAiButtonClicked={() => setIsSideBarOpen(true)}
      />

      <VideoUpload
        src={product.videoSrc}
        hasVideo={product.hasVideo}
        onHasVideoChange={handleHasVideoChange}
        onSrcChange={handleVideoSrcChange}
      />

      <AiSidebar
        product={product}
        isOpen={isSideBarOpen}
        onClosed={() => setIsSideBarOpen(false)}
        onUseSuggestion={handleDescriptionChange}
      />
    </div>
  );
};
export const DescribeProduct = memo(DescribeProductComponent);
