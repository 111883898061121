import styled, { createGlobalStyle, css } from 'styled-components';

export const ModalFooterStyle = createGlobalStyle`
  .modal-warning-footer {
    justify-content: center;
  }
`;

export const Container = styled.div``;

export const Image = styled.div`
  justify-content: center;
  display: flex;
`;

export const Title = styled.div`
  font-size: 24px;
  text-align: center;
  color: var(--gray-900);
  font-weight: 300;
  line-height: 28.8px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: var(--gray-500);
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  margin-top: 12px;
`;

export const ActionsContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

type StyledButtonsProps = {
  minWidth?: string;
};

export const PrimaryButton = styled.button<StyledButtonsProps>`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;

  ${({ minWidth }) => css`
    min-width: ${minWidth || '100%'};
  `}
`;

export const SecondaryButton = styled.button<StyledButtonsProps>`
  ${({ minWidth }) => css`
    min-width: ${minWidth || '100%'};
  `}
`;

export const Divider = styled.hr`
  margin: var(--spacer-4) 0;
  border-color: var(--gray-200);
`;

export const ShareLabel = styled.div`
  font-size: 16px;
  text-align: center;
  color: var(--gray-500);
  font-weight: 400;
`;

export const ShareButtons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 32px;

  button:focus {
    outline: none;
  }
`;
