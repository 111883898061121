import styled, { css } from 'styled-components';

type NameTextProps = {
  fontSize: number;
};

export const NameText = styled.div<NameTextProps>`
  ${({ fontSize }) => css`
    font-size: ${fontSize}px;
  `}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export const Button = styled.button`
  background: transparent !important;
  border: none;
  color: var(--gray-500) !important;
  cursor: pointer;
  display: flex;

  :hover {
    background: var(--blue-lightest) !important;
  }
`;
