import React, { memo } from 'react';
import { Colors } from '@pages/PageWizard/components/Colors';
import { StepContainer } from './styles';

type LinkTreeFirstStepProps = UseCaseStepProps & {
  colors: UseCaseColors[];
};

const SecondStepComponent: React.FC<LinkTreeFirstStepProps> = ({
  colors,
  onModifierChange,
  ...attrs
}) => (
  <StepContainer {...attrs}>
    <Colors
      label="pageWizard.publishLeadCapturePage.colors.title"
      colors={colors}
      onColorsChange={(value) =>
        onModifierChange({ prop: 'colorsConfig', value })
      }
    />
  </StepContainer>
);

export const SecondStep = memo(SecondStepComponent);
