import styled from 'styled-components';

export const Label = styled.label`
  margin-bottom: 4px;
`;

export const Switch = styled.div`
  margin-top: 16px;
  margin-bottom: 0;
`;

export const Description = styled.div`
  color: var(--gray-500);
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
`;

export const ErrorMessage = styled.div`
  display: block;
`;

export const BlockedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px 16px;
`;

export const BlockIconWrapper = styled.div`
  width: 100px;
  height: 100px;
  font-size: 32px;
  color: var(--gray-400);
  border: 1px solid var(--gray-200);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlockedContentTitle = styled.p`
  margin: 24px 0 8px 0;
  font-size: var(--text-3);
  text-align: center;
`;
export const BlockedContentDescription = styled.p`
  margin: 0 0 24px 0;
  font-size: var(--text-2);
  text-align: center;
`;

export const Form = styled.div`
  padding: 0 16px 16px;
`;
