import React, { useCallback, useMemo, useState } from 'react';
import { useUserInfo } from '@hooks/useUserInfo';
import { useMenu } from '@hooks/useMenu';
import { usePageInfo } from '@hooks/usePageInfo';
import { UpgradeModal } from '@components/UpgradeModal';
import { MenuItem } from '../../../MenuItem';
import { Submenu } from '../../../Submenu';

type templateSubmenuItemsType = {
  id: string;
  label: string;
  isBlocked?: boolean;
}[];

export const SettingsSubmenu: React.FC = () => {
  const { setActiveSubmenu } = useMenu();
  const { mode } = usePageInfo();
  const { pageWithRedirectEnabled, importPageEnabled, exportPageEnabled } =
    useUserInfo();

  const defaultItems = useMemo(
    () => [
      { id: 'url', label: 'sideMenu.settings.url' },
      { id: 'seo', label: 'sideMenu.settings.seo' },
      {
        id: 'support-chat',
        label: 'sideMenu.settings.supportChat',
        isBlocked: !pageWithRedirectEnabled,
      },
      { id: 'facebook', label: 'sideMenu.settings.facebook' },
      {
        id: 'scripts',
        label: 'sideMenu.settings.scripts',
        isBlocked: !pageWithRedirectEnabled,
      },
      { id: 'url-params', label: 'sideMenu.settings.urlParams' },
    ],
    [pageWithRedirectEnabled]
  );

  const pageSubmenuItems = useMemo(
    () =>
      importPageEnabled || exportPageEnabled
        ? [
            ...defaultItems,
            { id: 'import-export', label: 'sideMenu.settings.importPage' },
          ]
        : defaultItems,
    [defaultItems, exportPageEnabled, importPageEnabled]
  );

  const templateSubmenuItems: templateSubmenuItemsType = useMemo(
    () => [
      { id: 'template-thumb', label: 'sideMenu.settings.templateThumb' },
      {
        id: 'template-categorization',
        label: 'sideMenu.settings.templateCategorization',
      },
    ],
    []
  );

  const templateSubmenu = useMemo(
    () => ({
      page: pageSubmenuItems,
      template: templateSubmenuItems,
    }),
    [pageSubmenuItems, templateSubmenuItems]
  );

  const [showModal, setShowModal] = useState(false);

  const handleClick = useCallback(
    (item) => {
      if (item.isBlocked) {
        setShowModal(true);
        return;
      }

      setActiveSubmenu(item.id);
    },
    [setActiveSubmenu]
  );

  return (
    <Submenu title="sideMenu.settings.title" padding="12px 8px">
      {templateSubmenu[mode].map((item) => (
        <MenuItem
          onboardingKey={`setting-${item.id}`}
          key={item.id}
          id={item.id}
          label={item.label}
          onClick={() => handleClick(item)}
          isBlocked={item.isBlocked}
        />
      ))}
      <UpgradeModal
        id="upgrade-modal"
        isOpen={showModal}
        feature="scripts"
        onClose={() => setShowModal(false)}
      />
    </Submenu>
  );
};
