import { v4 as uuid } from 'uuid';

export const headerSections: PageFragmentListItem[] = [
  {
    id: 'header-4',
    name: 'Header',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '30px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                  metadata: {},
                },
              ],
              'align-items': 'start',
              'justify-content': 'center',
              padding: '20px 0px 20px 0px',
              mobile: {
                padding: '30px 0px 10px 0px',
              },
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              desktop: {},
              metadata: {},
            },
          ],
          tablet: {},
          mobile: {},
          desktop: {},
          metadata: {},
        },
      ],
      metadata: {
        label: 'Seção 1',
      },
      background: '#141436',
      tablet: {},
      mobile: {},
      desktop: {},
    },
  },
  {
    id: 'header-5',
    name: 'Header',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                  metadata: {},
                },
              ],
              'align-items': 'center',
              'justify-content': 'center',
              padding: '30px 0px 30px 0px',
              mobile: {
                padding: '20px 0px 0px 0px',
              },
              tablet: {
                padding: '20px 0px 20px 0px',
              },
              desktop: {},
              metadata: {},
            },
          ],
          tablet: {},
          mobile: {},
          desktop: {},
          metadata: {},
        },
      ],
      metadata: {
        label: 'Seção 1',
      },
      background: '#141436',
      mobile: {},
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'header-6',
    name: 'Header',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5372386/bg-obrigado_assets_template-nath-arcuri.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {
        background: '#D1BEDD',
      },
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '24px 0px 16px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '32px 0px 16px 0px',
          },
          mobile: {
            'flex-direction': 'column',
            padding: '32px 0px 16px 0px',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '40px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '250px',
                    height: '30px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5367466/2-fantastica-fabrica-da-renda-extra.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5367466/thumb_2-fantastica-fabrica-da-renda-extra.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Header',
        templateId: 'header-1',
        templateName: 'Header',
      },
    },
  },
  {
    id: 'header-3',
    name: 'Header',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5368144/bg-captura_assets_template-nath-arcuri.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {
        background: '#D1BEDD',
      },
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '30px 0px 30px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '30px 0px 30px 0px',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '0px 0px 0px 0px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '40px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 10px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    'max-width': '320px',
                    margin: '0px 0px 0px 0px',
                  },
                  mobile: {
                    'max-width': '250px',
                    height: '32px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5367466/2-fantastica-fabrica-da-renda-extra.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5367466/thumb_2-fantastica-fabrica-da-renda-extra.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Header',
        templateId: 'header-1',
        templateName: 'Header',
      },
    },
  },
  {
    id: 'header-1',
    name: 'Header',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5224516/group_74.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5224516/thumb_group_74.png',
                },
              ],
              background: 'transparent',
              'align-items': 'start',
              'justify-content': 'start',
              padding: '0px 0px 0px 0px',
            },
          ],
          padding: '24px 0px 16px 0px',
        },
      ],
      metadata: { label: 'Seção 1' },
      background: '#F5F5F5',
    },
  },
  {
    id: 'header-2',
    name: 'Header',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '60px',
                  href: '',
                  'max-width': '200px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5280678/seu-logo-aqui.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5280678/thumb_seu-logo-aqui.png',
                },
              ],
              'align-items': 'center',
              'justify-content': 'start',
              padding: '30px 0px 20px 0px',
            },
          ],
        },
      ],
      metadata: { label: 'Section 1' },
    },
  },
];
