import { rebrand } from '@config/rebrand';
import Http from '@services/http';
import PublicHttp from '@services/public-http';
import { removeEmojis } from '@common/utils';

export default class HotmartProductService {
  private static http = new Http();

  private static publicHttp = new PublicHttp();

  private static numberOfInstallments: Record<string, number> = {
    A: 10,
    B: 11,
    C: 12,
  };

  private static currencyCodeToLanguage: Record<string, string> = {
    ARS: 'es-AR',
    AUD: 'en-AU',
    BRL: 'pt-BR',
    CAD: 'en-CA',
    CHF: 'fr-CH',
    CLP: 'es-CL',
    COP: 'es-CO',
    EUR: 'en-GB',
    GBP: 'en-GB',
    JPY: 'ja-JP',
    JYP: 'ja-JP',
    MXN: 'es-MX',
    NGN: 'en-NG',
    PEN: 'es-PE',
    USD: 'en-US',
  };

  private static formatPrice(price: string, currencyCode: string): string {
    const language = this.currencyCodeToLanguage[currencyCode];
    const numberFormat = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    });

    return numberFormat
      .format(+price)
      .replace(/^(\D+)/, '$1 ')
      .replace(/\s+/, ' ');
  }

  public static async getProducts(): Promise<HotmartProduct[]> {
    const products = await this.http.get<HotmartProductDTO[]>(
      `${rebrand.urls.KLICKART_URL}/v1/api/products?removed=false`
    );

    return products.map((product) => ({
      id: product.id.toString(),
      description: removeEmojis(product.descricao),
      name: removeEmojis(product.nome),
      categoryName: product.nome_categoria,
      imgSrc: product.foto_produto,
      userId: product.id_usuario.toString(),
      userImgSrc: product.foto_usuario,
    }));
  }

  public static async getOffersByProductId(
    id: string
  ): Promise<HotmartProductOffer[]> {
    const offers = await this.http.get<HotmartProductOfferDTO[]>(
      `${rebrand.urls.KLICKART_URL}/v1/api/products/${id}/offers`
    );

    return offers.map((offer) => ({
      id: offer.id.toString(),
      key: offer.chave,
      price: this.formatPrice(offer.preco, offer.currency_code),
      maxInstallments: this.getMaxInstallments(offer.opcao_parcelamento),
      link: `${process.env.REACT_APP_HOTMART_CHECKOUT_URL}/${offer.referencia}?off=${offer.chave}`,
      principal: offer.oferta_principal,
    }));
  }

  private static getMaxInstallments(installmentCode: string) {
    if (!installmentCode) {
      return 1;
    }

    const lastCharacter = installmentCode.slice(-1);
    const maxInstallments = +lastCharacter;

    if (Number.isNaN(maxInstallments)) {
      return this.numberOfInstallments[lastCharacter];
    }

    return maxInstallments;
  }

  public static async getProductVideoLink(id: string) {
    try {
      const { product } = await this.publicHttp.get<HotmartProductDisplayDTO>(
        `${process.env.REACT_APP_HOTMART_DISPLAY_API}/back/rest/v1/product/${id}/page`
      );

      return product.videoLink;
    } catch {
      return '';
    }
  }
}
