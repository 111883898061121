import { v4 as uuid } from 'uuid';

export const depositionsSections: PageFragmentListItem[] = [
  {
    id: 'depositions-8',
    name: 'Depositions',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Depositions',
        templateId: 'depositions-1',
        templateName: 'Depositions',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '80px 0px 0px 0px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '40px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(97, 91, 155);">Esses são alguns resultados no meu método:</strong></h1>',
                  'max-width': '100%',
                  padding: '64px 10px 40px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 40px; color: rgb(97, 91, 155);">Esses são alguns resultados no meu método:</strong></p>',
                    'max-width': '80%',
                    padding: '10px 10px 40px 10px',
                  },
                  mobile: {
                    'max-width': '90%',
                    padding: '10px 10px 32px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 32px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              mobile: {
                padding: '10px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'align-items': 'start',
                    'justify-content': 'start',
                    padding: '10px 20px 10px 30px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D1BEDD',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '85px',
                      href: '',
                      'max-width': '85px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310957/foto-02.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310957/thumb_foto-02.jpg',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '20px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5385554/aspas.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5385554/thumb_aspas.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum</span></p><p><br></p><p><strong style="font-size: 20px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum.</span></p><p><br></p><p><strong style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                        'max-width': '60%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '20px 10px 10px 10px',
                  },
                  mobile: {
                    padding: '10px 20px 10px 30px',
                    'align-items': 'start',
                    'justify-content': 'start',
                    margin: '20px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D1BEDD',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '85px',
                      href: '',
                      'max-width': '85px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310966/foto-08.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310966/thumb_foto-08.jpg',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '20px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5385554/aspas.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5385554/thumb_aspas.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum</span></p><p><br></p><p><strong style="font-size: 20px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum.</span></p><p style="line-height: 1.15;"><br></p><p><strong style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 64px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 80px 0px',
              },
              mobile: {
                padding: '0px 0px 40px 0px',
                'align-items': 'center',
                'justify-content': 'start',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '20px 20px 10px 10px',
                  },
                  mobile: {
                    padding: '10px 20px 10px 30px',
                    margin: '20px 10px 10px 10px',
                    'align-items': 'start',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D1BEDD',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '85px',
                      href: '',
                      'max-width': '85px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314155/depoimentos2-vendas_assets_template-orange-chic.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314155/thumb_depoimentos2-vendas_assets_template-orange-chic.jpg',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '20px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5385554/aspas.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5385554/thumb_aspas.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum</span></p><p><br></p><p><strong style="font-size: 20px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum.</span></p><p><br></p><p><strong style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                        'max-width': '60%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: {
                    padding: '20px 10px 10px 10px',
                  },
                  mobile: {
                    margin: '20px 10px 10px 10px',
                    padding: '20px 20px 10px 30px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D1BEDD',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '85px',
                      href: '',
                      'max-width': '85px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314157/depoimentos3-vendas_assets_template-orange-chic.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314157/thumb_depoimentos3-vendas_assets_template-orange-chic.jpg',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '20px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5385554/aspas.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5385554/thumb_aspas.png',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum</span></p><p><br></p><p><strong style="font-size: 20px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum.</span></p><p style="line-height: 1.15;"><br></p><p><strong style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(209, 190, 221);"> Lorem ipsum </strong></p><p><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Lorem ipsum</span></p>',
                        'max-width': '60%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'depositions-9',
    name: 'Depositions',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 6',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 60px 0px',
              },
              mobile: {
                padding: '40px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 30px;">Conheça outras pessoas, exatamente igual a você, que conseguiram alcançar os resultados esperados por você.</strong></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 27px;">Conheça outras pessoas, exatamente igual a você, que conseguiram alcançar os resultados esperados por você.</strong></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 22px;">Conheça outras pessoas, exatamente igual a você, que conseguiram alcançar os resultados esperados por você.</strong></p>',
                    'max-width': '85%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 35px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '35px 10px 20px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '95%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '85%',
                    padding: '35px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#3B44B7',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '480px',
                      href: '',
                      'max-width': '260px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '400px',
                        'max-width': '215px',
                        margin: '0px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5315287/zap_placeholder_1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5315287/thumb_zap_placeholder_1.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#FFFFFF',
                      'border-radius': '300px 300px 300px 300px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '20px 0px 10px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310965/foto-07.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310965/thumb_foto-07.jpg',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183);">Nome</strong></p><p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(110, 110, 110);">Lorem Ipsum</span></p>',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 35px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '35px 10px 20px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '95%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '85%',
                    padding: '50px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#3B44B7',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '480px',
                      href: '',
                      'max-width': '260px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 10px 0px',
                        height: '400px',
                        'max-width': '215px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5315288/zap_placeholder_2.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5315288/thumb_zap_placeholder_2.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#FFFFFF',
                      'border-radius': '300px 300px 300px 300px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '20px 0px 10px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310960/foto-04.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310960/thumb_foto-04.jpg',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183);">Nome</strong></p><p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(110, 110, 110);">Lorem Ipsum</span></p>',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '35px 10px 20px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '95%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '85%',
                    padding: '35px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#3B44B7',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '480px',
                      href: '',
                      'max-width': '260px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '400px',
                        'max-width': '215px',
                        margin: '0px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5315287/zap_placeholder_1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5315287/thumb_zap_placeholder_1.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#FFFFFF',
                      'border-radius': '300px 300px 300px 300px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '20px 0px 10px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310962/foto-06.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310962/thumb_foto-06.jpg',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183);">Nome</strong></p><p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(110, 110, 110);">Lorem Ipsum</span></p>',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 50px 0px',
              },
              mobile: {
                padding: '0px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#141436',
                  background: '#61F5B0',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'SIM! QUERO COMEÇAR AGORA!',
                  margin: '30px 0px 30px 0px',
                  'max-width': '450px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '348px',
                    margin: '20px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'depositions-10',
    name: 'Depositions',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 3',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 64px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '56px 0px 48px 0px',
              },
              mobile: {
                padding: '40px 0px 32px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(56, 54, 145); font-size: 40px;">VEJA COMO AJUDAMOS OS NOSSOS ALUNOS A (INSERIR TRANSFORMAÇÃO AQUI)</strong></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                    text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(56, 54, 145); font-size: 32px;">VEJA COMO AJUDAMOS OS NOSSOS ALUNOS A (INSERIR TRANSFORMAÇÃO AQUI)</strong></p>',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 40px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#383691',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '464px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '200px',
                    height: '369px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315287/zap_placeholder_1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315287/thumb_zap_placeholder_1.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '24px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                    padding: '10px 10px 10px 20px',
                  },
                  mobile: {
                    'max-width': '80%',
                    margin: '16px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#ECECFB',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310960/foto-04.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310960/thumb_foto-04.jpg',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Oswald, sans-serif; font-size: 20px; color: rgb(56, 54, 145);">Nome </span><span style="font-family: Oswald, sans-serif; font-size: 20px; color: rgb(255, 59, 113);">Sobrenome</span></p>',
                      'max-width': '45%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: Oswald, sans-serif; font-size: 18px; color: rgb(56, 54, 145);">Nome </span><span style="font-family: Oswald, sans-serif; font-size: 18px; color: rgb(255, 59, 113);">Sobrenome</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 40px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#383691',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '464px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '369px',
                    'max-width': '200px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315288/zap_placeholder_2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315288/thumb_zap_placeholder_2.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '24px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                    padding: '10px 10px 10px 20px',
                  },
                  mobile: {
                    'max-width': '80%',
                    margin: '16px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#ECECFB',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310966/foto-08.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310966/thumb_foto-08.jpg',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Oswald, sans-serif; font-size: 20px; color: rgb(56, 54, 145);">Nome </span><span style="font-family: Oswald, sans-serif; font-size: 20px; color: rgb(255, 59, 113);">Sobrenome</span></p>',
                      'max-width': '45%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: Oswald, sans-serif; font-size: 18px; color: rgb(56, 54, 145);">Nome </span><span style="font-family: Oswald, sans-serif; font-size: 18px; color: rgb(255, 59, 113);">Sobrenome</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#383691',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '464px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '369px',
                    'max-width': '200px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315287/zap_placeholder_1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315287/thumb_zap_placeholder_1.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '24px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                    padding: '10px 10px 10px 20px',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#ECECFB',
                      'border-radius': '100px 100px 100px 100px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310958/thumb_foto-03.jpg',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Oswald, sans-serif; font-size: 20px; color: rgb(56, 54, 145);">Nome </span><span style="font-family: Oswald, sans-serif; font-size: 20px; color: rgb(255, 59, 113);">Sobrenome</span></p>',
                      'max-width': '45%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: Oswald, sans-serif; font-size: 18px; color: rgb(56, 54, 145);">Nome </span><span style="font-family: Oswald, sans-serif; font-size: 18px; color: rgb(255, 59, 113);">Sobrenome</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '48px 0px 112px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '40px 0px 104px 0px',
              },
              mobile: {
                padding: '20px 0px 64px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="font-family: Dosis, sans-serif; font-size: 24px; color: rgb(236, 236, 251);">______________________________________________________________________________</span></p>',
                  'max-width': '70%',
                  padding: '10px 10px 72px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Dosis, sans-serif; font-size: 24px; color: rgb(236, 236, 251);">______________________________________</span></p>',
                  },
                  mobile: {
                    padding: '10px 10px 56px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 32px; color: rgb(56, 54, 145);">ENTENDA COMO NÓS CONSEGUIMOS AJUDAR A/O [NOME DA PESSOA] A [INSERIR BENEFÍCIO DO PRODUTO]</strong></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 24px; color: rgb(56, 54, 145);">ENTENDA COMO NÓS CONSEGUIMOS AJUDAR A/O [NOME DA PESSOA] A [INSERIR BENEFÍCIO DO PRODUTO]</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '281px',
                  margin: '40px 0px 0px 0px',
                  'max-width': '500px',
                  src: 'https://www.youtube.com/embed/UglacxhJZXs',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'depositions-1',
    name: 'Depositions',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 7' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003eEsses são alguns resultados no meu método:\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '48px 10px 8px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5225388/ellipse_6.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5225388/thumb_ellipse_6.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 14px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5225388/ellipse_6.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5225388/thumb_ellipse_6.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 14px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5225388/ellipse_6.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5225388/thumb_ellipse_6.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 14px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5225388/ellipse_6.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5225388/thumb_ellipse_6.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-size: 14px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eLorem ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 16px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'depositions-2',
    name: 'Depositions',
    template: {
      background: '#FF735C',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 30px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '90px 40px 30px 40px' },
              mobile: { padding: '30px 40px 20px 40px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-size: 40px; font-family: Raleway, sans-serif;"\u003eEsse são alguns resultados no meu método:\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 80px 10px 80px' },
              mobile: { padding: '10px 30px 10px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 10px 0px 10px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284236/aspas.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284236/thumb_aspas.png',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 14px 10px 14px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  padding: '10px 0px 10px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '40px 40px 40px 40px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5284254/usuario.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5284254/thumb_usuario.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eNome Sobrenome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit amet \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 15px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '20px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 80px 10px 80px' },
              mobile: { padding: '10px 30px 10px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 10px 0px 10px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284236/aspas.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284236/thumb_aspas.png',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 14px 10px 14px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  padding: '10px 0px 10px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '40px 40px 40px 40px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5284254/usuario.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5284254/thumb_usuario.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eNome Sobrenome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit amet \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 15px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '20px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 80px 50px 80px' },
              mobile: { padding: '10px 30px 50px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 10px 0px 10px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284236/aspas.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284236/thumb_aspas.png',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vitae mauris vulputate, maximus risus sit amet, suscipit leo. Sed efficitur fermentum ante, non fringilla orci interdum\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 14px 10px 14px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  padding: '10px 0px 10px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '40px 40px 40px 40px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '80px',
                      href: '',
                      'max-width': '80px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5284254/usuario.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5284254/thumb_usuario.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eNome Sobrenome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eLorem ipsum dolor sit amet \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 15px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 4' },
    },
  },
  {
    id: 'depositions-3',
    name: 'Depositions',
    template: {
      background: '#E4F7FF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Depoimentos' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 50px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '40px 0px 30px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 27px; color: rgb(39, 195, 218);"\u003eVeja o que os meus alunos estão dizendo sobre o meu método.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(39, 195, 218);"\u003eVeja o que os meus alunos estão dizendo sobre o meu método.\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px; color: rgb(39, 195, 218);"\u003eVeja o que os meus alunos estão dizendo sobre o meu método.\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 18px;"\u003eEles são pessoas \u003c/span\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 18px;"\u003ecomo você\u003c/strong\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 18px;"\u003e e conseguiram esses resultados.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 16px;"\u003eEles são pessoas \u003c/span\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 16px;"\u003ecomo você\u003c/strong\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 16px;"\u003e e conseguiram esses resultados.\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: { 'max-width': '90%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 30px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '90%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '50%' },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '450px',
                      href: '',
                      'max-width': '400px',
                      margin: '10px 10px 10px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '10px 10px 10px 10px',
                        height: '350px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
                {
                  padding: '0px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '95%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '50%',
                  },
                  mobile: { 'max-width': '90%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289938/avatar-1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289938/thumb_avatar-1.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 195, 218);"\u003eNome do Aluno\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70);"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 30px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '90%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '50%' },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '450px',
                      href: '',
                      'max-width': '400px',
                      margin: '10px 10px 10px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '350px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
                {
                  padding: '0px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '95%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '50%',
                  },
                  mobile: { 'max-width': '90%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289966/avatar-2.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289966/thumb_avatar-2.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 195, 218);"\u003eNome do Aluno\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70);"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 40px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '90%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '50%' },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '450px',
                      href: '',
                      'max-width': '400px',
                      margin: '10px 10px 10px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '350px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
                {
                  padding: '0px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '95%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '50%',
                  },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289968/avatar-3.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289968/thumb_avatar-3.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 195, 218);"\u003eNome do Aluno\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70);"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'depositions-4',
    name: 'Depositions',
    template: {
      background: '#F7F9FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '50px 30px 0px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 32px; font-family: Montserrat, sans-serif;"\u003eAlunos que (benefício do produto) e seus resultados!\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 28px; font-family: Montserrat, sans-serif;"\u003eAlunos que (benefício do produto) e seus resultados!\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eEsses alunos são pessoas como você que alcançaram resultados que antes pareciam impossíveis!\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 30px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eEsses alunos são pessoas como você que alcançaram resultados que antes pareciam impossíveis!\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981E3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '140px',
                    margin: '5px 5px 5px 5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310956/foto-01.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310956/thumb_foto-01.jpg',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '140px',
                    margin: '5px 5px 5px 5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310961/foto-05.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310961/thumb_foto-05.jpg',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '140px',
                    margin: '5px 5px 5px 5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310958/thumb_foto-03.jpg',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '140px',
                    margin: '5px 5px 5px 5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310965/foto-07.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310965/thumb_foto-07.jpg',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '0px 0px 50px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '5px 5px 5px 5px',
                    height: '140px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310960/foto-04.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310960/thumb_foto-04.jpg',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '5px 5px 5px 5px',
                    height: '140px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310966/foto-08.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310966/thumb_foto-08.jpg',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '5px 5px 5px 5px',
                    height: '140px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310957/foto-02.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310957/thumb_foto-02.jpg',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '5px 5px 5px 5px',
                    height: '140px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310962/foto-06.jpg',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5310962/thumb_foto-06.jpg',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 2' },
    },
  },
  {
    id: 'depositions-5',
    name: 'Depositions',
    template: {
      background: '#FAFAFA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 0px 0px' },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 32px; font-family: Montserrat, sans-serif;"\u003eVeja o que os meus alunos estão dizendo sobre o meu método.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 28px; font-family: Montserrat, sans-serif;"\u003eVeja o que os meus alunos estão dizendo sobre o meu método.\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eEles são pessoas como você e conseguiram esses resultados.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 10px 30px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '90%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 30px 50px 30px' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
                padding: '0px 30px 50px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  href: '',
                  'max-width': '300px',
                  margin: '10px 15px 10px 15px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '300px' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315287/zap_placeholder_1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315287/thumb_zap_placeholder_1.png',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  href: '',
                  'max-width': '300px',
                  margin: '10px 15px 10px 15px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '300px' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315288/zap_placeholder_2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315288/thumb_zap_placeholder_2.png',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  href: '',
                  'max-width': '300px',
                  margin: '10px 15px 10px 15px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '300px' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315287/zap_placeholder_1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315287/thumb_zap_placeholder_1.png',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  href: '',
                  'max-width': '300px',
                  margin: '10px 15px 10px 15px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '300px' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315288/zap_placeholder_2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315288/thumb_zap_placeholder_2.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 2' },
    },
  },
  {
    id: 'depositions-6',
    name: 'Depositions',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Depoimentos' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 10px 0px' },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '80%',
                  margin: '50px 10px 10px 10px',
                  desktop: {},
                  tablet: { margin: '40px 10px 10px 10px' },
                  mobile: { 'max-width': '90%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 40px;"\u003eHall da Fama\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 30px;"\u003eHall da Fama\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(34, 34, 34); font-size: 20px;"\u003eVeja o que as pessoas que compraram o [NOME DO PRODUTO DIGITAL] estão falando:\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(34, 34, 34); font-size: 17px;"\u003eVeja o que as pessoas que compraram o [NOME DO PRODUTO DIGITAL] estão falando:\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'row',
              size: 12,
              background: '#FFFFFF',
              desktop: {},
              tablet: {},
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '130px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '0px 0px 0px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '10px 0px 15px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      alt: '',
                      'border-color': '#222222',
                      'border-radius': '100px 100px 0px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '100px', 'max-width': '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310961/foto-05.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310961/thumb_foto-05.jpg',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F1F1F1',
                  'max-width': '315px',
                  margin: '7px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#FF7C35',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '398px',
                      href: '',
                      'max-width': '295px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
              ],
            },
            {
              padding: '40px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'row',
              size: 12,
              background: '#F1F1F1',
              desktop: {},
              tablet: {},
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '130px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '0px 0px 0px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '10px 0px 15px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      alt: '',
                      'border-color': '#222222',
                      'border-radius': '100px 100px 0px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314154/depoimentos1-vendas_assets_template-orange-chic.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314154/thumb_depoimentos1-vendas_assets_template-orange-chic.jpg',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FF7C35',
                  'max-width': '315px',
                  margin: '7px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#FFFFFF',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '398px',
                      href: '',
                      'max-width': '295px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'row',
              size: 12,
              background: '#F1F1F1',
              desktop: {},
              tablet: { background: '#FFFFFF' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '130px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '0px 0px 0px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '10px 0px 15px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      alt: '',
                      'border-color': '#222222',
                      'border-radius': '100px 100px 0px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314157/depoimentos3-vendas_assets_template-orange-chic.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314157/thumb_depoimentos3-vendas_assets_template-orange-chic.jpg',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FF7C35',
                  'max-width': '315px',
                  margin: '7px 10px 10px 10px',
                  desktop: {},
                  tablet: { background: '#F1F1F1' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#FFFFFF',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '398px',
                      href: '',
                      'max-width': '295px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { 'border-color': '#FF7C35' },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
              ],
            },
            {
              padding: '40px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'row',
              size: 12,
              background: '#FFFFFF',
              desktop: {},
              tablet: { background: '#F1F1F1' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                'flex-direction': 'column',
                padding: '20px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '130px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '0px 0px 0px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(34, 34, 34); font-family: Raleway, sans-serif; font-size: 19px;"\u003eNome\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '90%',
                      padding: '10px 0px 15px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 124, 53); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      alt: '',
                      'border-color': '#222222',
                      'border-radius': '100px 100px 0px 100px',
                      'border-style': 'solid',
                      'border-top-width': '4px',
                      'border-right-width': '4px',
                      'border-bottom-width': '4px',
                      'border-left-width': '4px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314155/depoimentos2-vendas_assets_template-orange-chic.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314155/thumb_depoimentos2-vendas_assets_template-orange-chic.jpg',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F1F1F1',
                  'max-width': '315px',
                  margin: '7px 10px 10px 10px',
                  desktop: {},
                  tablet: { background: '#FF7C35' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#FF7C35',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      height: '398px',
                      href: '',
                      'max-width': '295px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { 'border-color': '#FFFFFF' },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5289759/whatsapp1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5289759/thumb_whatsapp1.png',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'depositions-7',
    name: 'Depositions',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Depoimentos' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '90%',
                  margin: '60px 10px 40px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { margin: '30px 10px 25px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 40px;"\u003eHall da Fama\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 30px;"\u003eHall da Fama\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 20px; color: rgb(106, 129, 229);"\u003eVeja o que as pessoas que compraram o [NOME DO PRODUTO DIGITAL] estão falando.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: { 'max-width': '70%' },
                      mobile: {
                        'max-width': '90%',
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 16px; color: rgb(106, 129, 229);"\u003eVeja o que as pessoas que compraram o [NOME DO PRODUTO DIGITAL] estão falando.\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 40px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: '#FFFFFF',
              desktop: {},
              tablet: {
                padding: '0px 0px 60px 0px',
                'align-items': 'center',
                'justify-content': 'end',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#6A81E5',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'solid',
                  'border-top-width': '6px',
                  'border-right-width': '6px',
                  'border-bottom-width': '6px',
                  'border-left-width': '6px',
                  height: '386px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289750/whatsapp1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289750/thumb_whatsapp1.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '48%',
                  margin: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D3DBFC',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '7px',
                      'border-right-width': '7px',
                      'border-bottom-width': '7px',
                      'border-left-width': '7px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314154/depoimentos1-vendas_assets_template-orange-chic.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314154/thumb_depoimentos1-vendas_assets_template-orange-chic.jpg',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eNome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 30px',
                      desktop: {},
                      tablet: { 'max-width': '55%' },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 40px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: '#FFFFFF',
              desktop: {},
              tablet: { padding: '0px 0px 60px 0px' },
              mobile: { padding: '0px 0px 40px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#6A81E5',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'solid',
                  'border-top-width': '6px',
                  'border-right-width': '6px',
                  'border-bottom-width': '6px',
                  'border-left-width': '6px',
                  height: '386px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289750/whatsapp1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289750/thumb_whatsapp1.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '48%',
                  margin: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D3DBFC',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '7px',
                      'border-right-width': '7px',
                      'border-bottom-width': '7px',
                      'border-left-width': '7px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310965/foto-07.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310965/thumb_foto-07.jpg',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eNome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 30px',
                      desktop: {},
                      tablet: { 'max-width': '55%' },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 60px 0px' },
              mobile: { padding: '0px 0px 40px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#6A81E5',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'solid',
                  'border-top-width': '6px',
                  'border-right-width': '6px',
                  'border-bottom-width': '6px',
                  'border-left-width': '6px',
                  height: '386px',
                  href: '',
                  'max-width': '300px',
                  margin: '40px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { margin: '00px 0px 0px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289750/whatsapp1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289750/thumb_whatsapp1.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '48%',
                  margin: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D3DBFC',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '7px',
                      'border-right-width': '7px',
                      'border-bottom-width': '7px',
                      'border-left-width': '7px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310956/foto-01.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310956/thumb_foto-01.jpg',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eNome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 30px',
                      desktop: {},
                      tablet: { 'max-width': '55%' },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '0px 0px 20px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#6A81E5',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'solid',
                  'border-top-width': '6px',
                  'border-right-width': '6px',
                  'border-bottom-width': '6px',
                  'border-left-width': '6px',
                  height: '386px',
                  href: '',
                  'max-width': '300px',
                  margin: '40px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { margin: '0px 0px 0px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289750/whatsapp1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289750/thumb_whatsapp1.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '48%',
                  margin: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#D3DBFC',
                      'border-radius': '20px 20px 20px 20px',
                      'border-style': 'solid',
                      'border-top-width': '7px',
                      'border-right-width': '7px',
                      'border-bottom-width': '7px',
                      'border-left-width': '7px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5310960/foto-04.jpg',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5310960/thumb_foto-04.jpg',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eNome\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eLorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 30px',
                      desktop: {},
                      tablet: { 'max-width': '55%' },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '30px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 50px 0px' },
              mobile: { padding: '0px 0px 30px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#FFFFFF',
                  background: '#FF5959',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'COMEÇAR AGORA',
                  margin: '30px 0px 30px 0px',
                  'max-width': '40%',
                  mobile: {
                    'font-size': '15px',
                    'max-width': '70%',
                    padding: '15px 16px 15px 16px',
                  },
                  padding: '18px 16px 18px 16px',
                  tablet: { 'max-width': '60%' },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
