type PaginationProps = {
  currentPageNumber: number;
  lastPageNumber: number;
  paginationSize?: number;
};

const createEndPaginationArray = (
  lastPageNumber: number,
  paginationSize: number
): string[] => {
  const paginationArray = Array.from(
    { length: paginationSize + 1 },
    (_, index) => `${lastPageNumber - paginationSize + index}`
  );

  return paginationArray;
};

const createPaginationArrayFromMultiple = (
  currentPageNumber: number,
  paginationSize: number
): string[] => {
  const nextMultipleOfPaginationSize =
    Math.ceil(currentPageNumber / paginationSize) * paginationSize;

  const paginationArray = Array.from(
    { length: paginationSize },
    (_, index) =>
      `${nextMultipleOfPaginationSize - (paginationSize - 1 - index)}`
  );
  const nextPageFirstNumber = `${nextMultipleOfPaginationSize + 1}`;

  if (currentPageNumber > paginationSize) {
    const previousPageLastNumber = `${
      nextMultipleOfPaginationSize - paginationSize
    }`;
    return [previousPageLastNumber, ...paginationArray, nextPageFirstNumber];
  }

  return [...paginationArray, nextPageFirstNumber];
};

const getSmallPagination = (lastPageNumber: number) =>
  Array.from({ length: lastPageNumber }, (_, index) => `${index + 1}`);

const getOpenLeftToRight = (lastPageNumber: number, paginationSize: number) => {
  const paginationArray = createEndPaginationArray(
    lastPageNumber,
    paginationSize
  );

  return ['1', '...', ...paginationArray];
};

const getOpenLeftToOpenRight = (
  currentPageNumber: number,
  lastPageNumber: number,
  paginationSize: number
) => {
  const paginationArray = createPaginationArrayFromMultiple(
    currentPageNumber,
    paginationSize
  );

  return ['1', '...', ...paginationArray, '...', lastPageNumber.toString()];
};

const getLeftToOpenRight = (
  currentPageNumber: number,
  lastPageNumber: number,
  paginationSize: number
) => {
  const paginationArray = createPaginationArrayFromMultiple(
    currentPageNumber,
    paginationSize
  );

  return [...paginationArray, '...', lastPageNumber.toString()];
};

export const getPaginationArray = ({
  currentPageNumber,
  lastPageNumber,
  paginationSize = 5,
}: PaginationProps): string[] => {
  if (lastPageNumber <= paginationSize) {
    return getSmallPagination(lastPageNumber);
  }

  if (currentPageNumber >= lastPageNumber - paginationSize) {
    return getOpenLeftToRight(lastPageNumber, paginationSize);
  }

  if (currentPageNumber > paginationSize) {
    return getOpenLeftToOpenRight(
      currentPageNumber,
      lastPageNumber,
      paginationSize
    );
  }

  return getLeftToOpenRight(currentPageNumber, lastPageNumber, paginationSize);
};
