import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import classNames from 'classnames';

import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentTitle, Description } from './styles';

export type TitleProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  value?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
};

const TitleComponent: React.FC<TitleProps> = ({
  value = '',
  error,
  errorMessage,
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleTitleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div {...attrs} className="hot-form">
      <ContentTitle htmlFor="title">{t('seo.title.title')}</ContentTitle>
      <input
        id="title"
        className={classNames('hot-form__input hot-form__input--sm', {
          'is-invalid': error,
        })}
        placeholder={t('seo.title.placeholder')}
        defaultValue={value}
        onChange={handleTitleChange}
      />

      {error && <div className="invalid-feedback">{t(`${errorMessage}`)}</div>}

      <Description>{t('seo.title.description')}</Description>
    </div>
  );
};

export const Title = memo(TitleComponent);
