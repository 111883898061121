import React, { HTMLAttributes } from 'react';
import { Submenu } from '../../../Submenu';
import { Divider } from './styles';
import { UploadMedia } from '../../../UploadMedia';
import { Media } from '../../../Medias';

export const MediaCenterSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => (
  <Submenu title="mediaCenter.title" padding="0" {...attrs}>
    <UploadMedia />
    <Divider />
    <Media />
  </Submenu>
);
