import styled, { css } from 'styled-components';

type StyledContentProps = {
  padding?: string;
  margin?: string;
};

export const Content = styled.div<StyledContentProps>`
  ${({ padding }) => css`
    padding: ${padding || '16px 16px 0'};
  `}

  ${({ margin }) => css`
    margin: ${margin};
  `}
`;

export const Description = styled.label`
  color: var(--gray-500);
  font-size: 12px;
  margin-bottom: 0;
`;

export const Divider = styled.hr`
  margin: 16px 0;
  display: block;
`;
