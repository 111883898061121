import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { createFolderBuilder, createFolder } from './createFolder';
import { deleteMediaBuilder, deleteMedia } from './deleteMedia';
import { getFolderMediaBuilder, getFolderMedia } from './getFolderMedia';
import { openFolderBuilder, openFolder } from './openFolder';
import { openRootFolderBuilder, openRootFolder } from './openRootFolder';
import { searchMediaBuilder, searchMedia } from './searchMedia';
import { updateMediaListBuilder, updateMediaList } from './updateMediaList';
import { uploadMediaBuilder, uploadMedia } from './uploadMedia';
import { moveMediaBuilder, moveMedia } from './moveMedia';
import {
  navigateBackFolderBuilder,
  navigateBackFolder,
} from './navigateBackFolder';
import {
  navigateForwardFolderBuilder,
  navigateForwardFolder,
} from './navigateForwardFolder';

export const mediaCenterThunks = {
  createFolder,
  deleteMedia,
  getFolderMedia,
  openFolder,
  openRootFolder,
  searchMedia,
  updateMediaList,
  moveMedia,
  uploadMedia,
  navigateBackFolder,
  navigateForwardFolder,
};

export const mediaCenterExtraReducers = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  createFolderBuilder(builder);
  deleteMediaBuilder(builder);
  getFolderMediaBuilder(builder);
  openFolderBuilder(builder);
  openRootFolderBuilder(builder);
  searchMediaBuilder(builder);
  updateMediaListBuilder(builder);
  uploadMediaBuilder(builder);
  moveMediaBuilder(builder);
  navigateBackFolderBuilder(builder);
  navigateForwardFolderBuilder(builder);
};
