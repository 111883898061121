import styled, { css } from 'styled-components';
import { LsEditorPage } from '@hotmart-org-ca/saas-pages-engine-react';

type StyledContainerProps = {
  mode: EditorMode;
};

type StyledContentProps = {
  device: DeviceOptions;
  mode: EditorMode;
};

const contentModifiers = {
  mobile: 'max-width: 375px;',
  tablet: 'max-width: 768px;',
  desktop: 'min-width: 1400px;',
};

export const Container = styled.div<StyledContainerProps>`
  display: flex;
  padding: 20px;
  width: 100%;
  overflow: auto;

  ${({ mode }) => css`
    height: ${mode === 'page' ? 'calc(100vh - 102px)' : 'calc(100vh - 70px)'};
  `}
`;

export const Content = styled.div<StyledContentProps>`
  width: 100%;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  margin: auto;
  display: flex;
  flex: 1;

  ${({ device, mode }) => css`
    min-height: ${mode === 'page'
      ? 'calc(100vh - 144px)'
      : 'calc(100vh - 110px)'};

    ${contentModifiers[device]}
  `}
`;

export const LsPage = styled(LsEditorPage)`
  flex: 1;
  height: unset;

  ls-page-editable {
    > div {
      height: 100%;
    }
  }
`;
