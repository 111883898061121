import { LsEditorDraggable } from '@hotmart-org-ca/saas-pages-engine-react';
import styled, { css } from 'styled-components';
import { Container as ButtonContainer } from '../MediaOptions/styles';

type StyledLoadingProps = {
  isLoading?: boolean;
  isError?: boolean;
  isUploaded?: boolean;
  active?: boolean;
};

type StyledImageProps = {
  isSelectable?: boolean;
  isSelectedMedia?: boolean;
};

type StyledSelectedMediaProps = {
  isSelected?: boolean;
};

export const Container = styled.div<StyledImageProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-image: linear-gradient(45deg, var(--gray-200) 25%, transparent 25%),
    linear-gradient(135deg, var(--gray-200) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--gray-200) 75%),
    linear-gradient(135deg, transparent 75%, var(--gray-200) 75%);
  background-size: 16px 16px;
  background-position: 0 0, 8px 0, 8px -8px, 0px 8px;

  ${({ isSelectable }) => css`
    ${isSelectable
      ? css`
          width: 150px;
          height: 150px;
          cursor: pointer;
        `
      : css`
          width: 100%;
          height: 80px;
        `}
  `}

  :after {
    content: '';
    pointer-events: none;
    position: absolute;
    inset: 0;
    width: 100%;
    ${({ isSelectedMedia }) => css`
      ${isSelectedMedia
        ? 'background-color: rgba(190, 209, 255, 0.5);'
        : 'background-color: rgba(0, 0, 0, 0);'}
    `}

    transition: opacity 0.35s ease;
    border-radius: 4px;
  }

  :hover::after {
    border: 1.5px solid var(--blue);
    background-color: rgba(190, 209, 255, 0.5);
  }

  ${({ isSelectedMedia }) => css`
    ${isSelectedMedia
      ? `${ButtonContainer} {
          opacity: 1;
        }`
      : 'background-color: rgba(0, 0, 0, 0);'}
  `}

  :hover ${ButtonContainer} {
    opacity: 1;
  }
`;

export const Progress = styled.div<StyledLoadingProps>`
  position: absolute;
  justify-content: center;
  align-items: center;
  inset: 0;
  border-radius: 4px;
  background-color: rgba(240, 244, 255, 0.8);
  font-size: 24px;

  ${({ isLoading, isUploaded, isError }) => css`
    display: ${isLoading || isUploaded || isError ? 'flex' : 'none'};
    ${isUploaded && 'color: var(--green);'}
    ${isError && 'color: var(--red);'}
  `}
`;

export const SelectedImage = styled.div<StyledSelectedMediaProps>`
  ${({ isSelected }) => css`
    ${isSelected &&
    css`
      position: absolute;
      inset: 0;
      border-radius: 4px;
      background-color: rgba(190, 209, 255, 0.5);
      transition: opacity 0.35s ease;
      border: 4px solid var(--blue-light);
    `}
  `};
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Image = styled.img<StyledSelectedMediaProps>`
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 4px;
`;

export const StyledDraggable = styled(LsEditorDraggable)`
  width: 100%;
  height: 100%;
`;
