export const defaultFormFields = [
  {
    name: 'email',
    type: 'email',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.email',
    value: '',
    required: true,
  },
  {
    name: 'first_name',
    type: 'text',
    label:
      'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.firstName',
    value: '',
    required: false,
  },
  {
    name: 'gdpr',
    type: 'gdpr',
    label: 'form.klicksend.formCreation.gdprCheckboxLabel',
    value: 'form.klicksend.formCreation.gdprCheckboxLabel',
    required: true,
  },
];

export const defaultFormTerms = {
  singleOptIn: true,
  formTerms: false,
};
