import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '../../../../services/mediaCenter';
import { updateBreadcrumbOnBackNavigation } from '../../../slices/mediaCenter';

export const navigateBackFolder = createAsyncThunk<
  Media[],
  NavigateBackFolderConfig,
  {
    state: LocalState;
  }
>(
  'mediaCenter/navigateBackFolder',
  async ({ to, folderIndex }, { getState, dispatch }) => {
    const {
      mediaCenter: { breadcrumbs },
    } = getState();

    dispatch(updateBreadcrumbOnBackNavigation({ to, folderIndex }));
    try {
      if (to === 'ROOT_FOLDER') {
        const response = await MediaCenter.getRootMedia();
        return response;
      }

      if (folderIndex === undefined) {
        const breadcrumb = breadcrumbs[breadcrumbs.length - 2];
        const response = breadcrumb
          ? await MediaCenter.getFolderMedia(breadcrumb.id.toString())
          : await MediaCenter.getRootMedia();
        return response;
      }

      const breadcrumb = breadcrumbs[folderIndex];
      const response = await MediaCenter.getFolderMedia(
        breadcrumb.id.toString()
      );
      return response;
    } catch (error) {
      // todo - create error handling process
      const serializedError = error as SerializedError;
      throw serializedError;
    }
  }
);

export const navigateBackFolderBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(navigateBackFolder.pending, (state: MediaCenterState) => {
    state.media = [];
    state.loading = true;
  });
  builder.addCase(
    navigateBackFolder.fulfilled,
    (state: MediaCenterState, action) => {
      state.loading = false;
      state.media = action.payload;
    }
  );
  builder.addCase(navigateBackFolder.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
