import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { Block } from '@components/Block';
import classNames from 'classnames';
import { useUseCases } from '@hooks/useUseCases';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Preview,
  Label,
  InputGroup,
  InputIcon,
  Switch,
  InvalidSrc,
  SearchInput,
} from './styles';
import getUpdatedSources from './getUpdatedSources';

export type VideoUploadProps = HTMLAttributes<HTMLDivElement> & {
  src?: string;
  hasVideo?: boolean;
  onHasVideoChange?: (hasVideo: boolean) => void;
  onSrcChange?: (videoSrc: string) => void;
};

export const VideoUpload: React.FC<VideoUploadProps> = ({
  src: srcProp = '',
  hasVideo: hasVideoProp = false,
  onHasVideoChange,
  onSrcChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const { setFooterRightButtonConfig } = useUseCases();
  const [hasVideo, setHasVideo] = useState(hasVideoProp);
  const [isValidLink, setIsValidLink] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const isValid = useCallback((value: string) => {
    const { embedSrc, isValidSrc } = getUpdatedSources(value);
    setIsValidLink(isValidSrc);
    return { embedSrc, isValidSrc };
  }, []);

  const updateVideoSrc = useCallback(
    (embedSrc: string) => {
      setInputValue(embedSrc);
      onSrcChange?.(embedSrc);
    },
    [onSrcChange]
  );

  const debouncedOnValueChange = useRef(debounce(updateVideoSrc, 500));

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      const { embedSrc, isValidSrc } = isValid(value);

      setInputValue(value);

      if (isValidSrc) {
        debouncedOnValueChange.current(embedSrc);
      }
    },
    [isValid]
  );

  const handleVideoCheckbox = useCallback(
    (checked: boolean) => {
      setHasVideo(checked);
      onHasVideoChange?.(checked);
    },
    [onHasVideoChange]
  );

  useEffect(() => {
    const { embedSrc } = isValid(srcProp);

    setInputValue(embedSrc);
    setHasVideo(hasVideo);
  }, [hasVideo, isValid, srcProp]);

  useEffect(() => {
    const disabled = !inputValue && hasVideo;

    setFooterRightButtonConfig({
      disabled,
      errorMessage: disabled
        ? `${t('pageWizard.salesPage.paidWithProduct.description.emptyUrl')}`
        : '',
    });

    return () => {
      setFooterRightButtonConfig({
        disabled: false,
        errorMessage: '',
      });
    };
  }, [setFooterRightButtonConfig, hasVideo, t, inputValue]);

  return (
    <Block
      label="pageWizard.salesPage.paidWithProduct.description.videoTitle"
      {...attrs}
    >
      <Container>
        <Switch className="hot-form hot-form--custom hot-form--switch">
          <input
            id="use-video"
            type="checkbox"
            className="hot-form__input"
            checked={hasVideo}
            onChange={(event) => handleVideoCheckbox(event.target.checked)}
          />
          <label className="hot-form__label" htmlFor="use-video">
            {t(
              'pageWizard.salesPage.paidWithProduct.description.videoCheckboxLabel'
            )}
          </label>
        </Switch>
        {hasVideo && (
          <>
            <InputGroup>
              <SearchInput
                id="video"
                name="video-link"
                type="text"
                className={classNames('hot-form__input hot-form__input--lg', {
                  'is-invalid': inputValue && !isValidLink,
                })}
                placeholder={t(
                  'pageWizard.salesPage.paidWithProduct.description.videoPlaceholder'
                )}
                value={inputValue}
                onChange={(e) => handleChange(e)}
                data-prevent-delete
              />
              <hot-tooltip
                position="right"
                content={t(
                  'pageWizard.salesPage.paidWithProduct.description.infoTooltip'
                )}
              >
                <InputIcon className="far fa-info-circle" />
              </hot-tooltip>
            </InputGroup>
            {inputValue && !isValidLink ? (
              <InvalidSrc className="invalid-feedback">
                {t(
                  'pageWizard.salesPage.paidWithProduct.description.invalidUrl'
                )}
              </InvalidSrc>
            ) : (
              <Label>
                {t(
                  'pageWizard.salesPage.paidWithProduct.description.videoLabel'
                )}
              </Label>
            )}

            {inputValue && isValidLink && (
              <Preview>
                <iframe
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  src={inputValue}
                  title="video"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Preview>
            )}
          </>
        )}
      </Container>
    </Block>
  );
};
