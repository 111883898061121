import { v4 as uuid } from 'uuid';

export const videoSections: PageFragmentListItem[] = [
  {
    id: 'video-2',
    name: 'Video',
    template: {
      background: '#222222',
      'background-is-webp': false,
      tag: 'section',
      'is-fixed': false,
      'end-date': '2023-07-21T15:02:50',
      timezone: '-03:00_utcMinus0300MainCities',
      'time-on-page': '5',
      'time-on-page-unit': 'seconds',
      'delay-type': 'disabled',
      padding: '48px 0px 120px 0px',
      desktop: {},
      tablet: {
        padding: '48px 0px 100px 0px',
      },
      mobile: {
        padding: '24px 0px 70px 0px',
      },
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Vídeo 02',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '1px 0px 20px 0px',
              },
              mobile: {
                padding: '0px 0px 10px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">Título do seu vídeo aqui!</span></h1>',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<h1 style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 32px;">Título do seu vídeo aqui!</span></h1>',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">Título do seu último vídeo aqui!</strong></p>',
                    'max-width': '75%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '506px',
                  margin: '40px 0px 54px 0px',
                  'max-width': '900px',
                  src: 'https://www.youtube.com/embed/fk6KsMglc0Y',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {
                    'max-width': '700px',
                    height: '394px',
                    margin: '32px 0px 54px 0px',
                  },
                  mobile: {
                    'max-width': '320px',
                    height: '180px',
                    margin: '12px 0px 40px 0px',
                    autoplay: true,
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
                {
                  action: 'link',
                  color: '#FFFFFF',
                  background: '#FFB02F',
                  'border-color': '#EF4E23',
                  'border-radius': '100px 100px 100px 100px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2023-07-26T12:57:48',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'PARTICIPAR DO GRUPO',
                  margin: '0px 0px 0px 0px',
                  'max-width': '50%',
                  mobile: {
                    'font-size': '16px',
                    'max-width': '80%',
                    padding: '12px 32px 12px 32px',
                  },
                  padding: '16px 32px 16px 32px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'video-3',
    name: 'Video',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Depoimentos',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '40px 20px 20px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 35px;">Olha o que a galera está falando sobre o (seu método/curso)</strong></p>',
                  'max-width': '1000%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 35px;">Olha o que a galera está falando sobre o (seu método/curso)</strong></p>',
                  },
                  mobile: {
                    'max-width': '100%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 22px;">Olha o que a galera está falando sobre o (seu método/curso)</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 10px 120px 10px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 10px 40px 10px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '225px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '400px',
                  src: 'https://www.youtube.com/embed/fk6KsMglc0Y',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '50px 10px 120px 10px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 10px 40px 10px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '225px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '400px',
                  src: 'https://www.youtube.com/embed/fk6KsMglc0Y',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '50px 10px 120px 10px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 10px 80px 10px',
              },
              mobile: {
                padding: '20px 10px 60px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '225px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '400px',
                  src: 'https://www.youtube.com/embed/fk6KsMglc0Y',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'video-1',
    name: 'Video',
    template: {
      background: '#0094D7',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '130px 10px 130px 10px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                padding: '20px 10px 20px 10px',
              },
              mobile: {
                padding: '10px 10px 10px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  autoplay: false,
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '800px',
                  src: 'https://www.youtube.com/embed/wEOppuLidCA',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '100%',
                    height: '200px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  'thumb-url':
                    'https://i.ytimg.com/vi/wEOppuLidCA/hqdefault.jpg',
                  player: 'youtube',
                  performant: true,
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
