import { rebrand } from '@config/rebrand';
import Api from '../api';
import Http from '../http';
import Token, { TokenNames } from '../token';

export default class Klickart {
  private static http = new Http();

  public static getDomains() {
    return this.http.get<KlickartDomainsData[]>(
      `${rebrand.urls.KLICKART_URL}/v1/api/domains`,
      { withCredentials: false }
    );
  }

  public static getTotalPages() {
    return this.http.get<KlickartPagesData>(
      `${rebrand.urls.API_URL}/v1/info/pages`,
      { withCredentials: false }
    );
  }

  public static async fetchKlickToken() {
    const kind = Token.getTokenKind(TokenNames.klickApi);
    const instance = Api.getKlickInstance();
    const { data } = await Token.fetchToken(instance, kind);
    const { token } = data;

    Token.setKlickToken(token);
  }

  public static getZendeskToken() {
    return this.http.get<ZendeskToken>(
      `${rebrand.urls.KLICKART_URL}/v1/api/zendesk_token`
    );
  }

  public static getUserTrialSponsorInfo() {
    return this.http.get<trialSponsorInfo>(
      `${rebrand.urls.KLICKART_URL}/v1/api/user/trial/info`
    );
  }

  public static getExtensionsInfo() {
    return this.http.get<ExtensionInfoResponse>(
      `${rebrand.urls.KLICKART_URL}/v1/api/user/extensions/info`
    );
  }

  public static startUserTrial(origin: string) {
    return this.http.post<void>(
      `${rebrand.urls.KLICKART_URL}/v1/api/user/subscription/sponsor`,
      { origin }
    );
  }
}
