import React, { useCallback, useState } from 'react';
import { useSavedPopups } from '@hooks/useSavedPopups';
import { Submenu } from '@components/Submenu';
import { PageFragmentList } from '@components/PageFragmentList';
import { RemovePopupModal } from './components/RemovePopupModal';
import { EmptyState } from './components/EmptyState';

export const SavedPopupsSubmenu: React.FC = ({ ...attrs }) => {
  const {
    userPopups,
    updateUserPopup: updatePopup,
    loading,
  } = useSavedPopups();
  const [popupToRemove, setPopupToRemove] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleRemove = useCallback((popupId: string) => {
    setPopupToRemove(popupId);
    setOpenModal(true);
  }, []);

  const handleUpdate = useCallback(
    async (id: string, name: string) => {
      await updatePopup({ id, name });
    },
    [updatePopup]
  );

  const handleRemoveModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Submenu
      title="sideMenu.popupsTemplate.savedPopups"
      padding="24px"
      {...attrs}
    >
      {userPopups.length ? (
        <PageFragmentList
          hideBorder
          groupName="addablePopups"
          description="savedPopups.title"
          bookmarkTooltip="savedPopups.removeTooltip"
          items={userPopups}
          loading={loading}
          onBookmarkClick={handleRemove}
          onNameChange={handleUpdate}
        />
      ) : (
        <EmptyState />
      )}

      <RemovePopupModal
        isOpen={openModal}
        popupId={popupToRemove}
        onClose={handleRemoveModalClose}
      />
    </Submenu>
  );
};
