import '@hotmart-org-ca/cosmos/dist/button/variations/success.css';
import { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Pages from '@services/pages';
import Files from '@services/files';
import Toast from '@services/toast';
import { getConcatUrl, copyToClipboard, validateEmoji } from '@common/utils';
import { AppError } from '@common/app-error';
import { UserLimitModal } from '@components/UserLimitModal';
import { usePageInfo } from '@hooks/usePageInfo';
import { useEngine } from '@hooks/useEngine';
import { usePageConfig } from '@hooks/usePageConfig';
import { useUserInfo } from '@hooks/useUserInfo';

import TagManager from 'react-gtm-module';
import { PublishModal } from './components/PublishModal';

export const Publish: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const [isPublishing, setIsPublishing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserLimitModalOpen, setIsUserLimitModalOpen] = useState(false);
  const [errorMessageUserLimitModal, setErrorMessageUserLimitModal] =
    useState('');

  const {
    uuid,
    domain,
    redirectUrl,
    path,
    mode,
    hasMetadata,
    hasFontsConfig,
    hasBeenPublished,
    updateInfo,
  } = usePageInfo();
  const { getFinalFiles } = useEngine();
  const { updateConfig } = usePageConfig();
  const { savePageMetadata } = usePageInfo();

  const { templateManagementEnabled } = useUserInfo();
  const { mobileFirst } = usePageConfig();

  const pageUrl = useMemo(() => getConcatUrl(domain, path), [domain, path]);

  const handlePublish = useCallback(async () => {
    const { page, fontsConfig = {} } = getFinalFiles() || {};

    updateInfo({
      lastPublishingStatus: 'pending',
    });

    if (page) {
      const config = {
        draft: false,
      };

      const isTemplateMode = mode === 'template';
      const publishBody = isTemplateMode
        ? { mode }
        : { mode, domain, path, redirectUrl };

      try {
        setIsPublishing(true);
        validateEmoji(page);

        const pageToSave = isTemplateMode
          ? Files.updateElementMetadata({
              element: page,
              clearUnlinkedProps: true,
              updateImageMetadata: true,
              mobileFirst,
            })
          : page;

        await Files.saveConfigFile(
          uuid,
          config,
          mode,
          templateManagementEnabled
        );

        updateConfig(config);

        await Files.savePageFile(
          uuid,
          pageToSave,
          mode,
          templateManagementEnabled
        );
        await Files.saveFontsConfigFile(
          uuid,
          fontsConfig,
          hasFontsConfig,
          mode,
          templateManagementEnabled
        );
        await Files.saveMetadataFile(
          uuid,
          page,
          hasMetadata,
          mode,
          templateManagementEnabled
        );

        await Pages.publishPage(uuid, publishBody);

        if (isTemplateMode) {
          if (!hasBeenPublished) {
            updateInfo({
              hasBeenPublished: true,
              published: true,
            });
          }

          updateInfo({
            enabled: true,
          });
        }

        Toast.success(`toastMessages.${mode}.success.published`);

        updateInfo({
          lastPublishingStatus: 'success',
          hasUnsavedChanges: false,
          published: true,
        });

        updateConfig(config);
        copyToClipboard(pageUrl);
        setIsPublishing(false);
        setIsModalOpen(true);

        await savePageMetadata();
      } catch (e) {
        const error = e as Error;
        if (error.message === 'pageLimit' || error.message === 'domainLimit') {
          setErrorMessageUserLimitModal(error.message);
          setIsUserLimitModalOpen(true);
        }
        Toast.error(
          error instanceof AppError
            ? error.message
            : `toastMessages.${mode}.error.published`
        );
        updateInfo({
          lastPublishingStatus: 'fail',
        });
        setIsPublishing(false);
      }
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: 'click_navbar_editor',
        event_details: 'Publish',
      },
    });
  }, [
    getFinalFiles,
    updateInfo,
    mode,
    domain,
    path,
    redirectUrl,
    mobileFirst,
    uuid,
    templateManagementEnabled,
    updateConfig,
    hasFontsConfig,
    hasMetadata,
    pageUrl,
    savePageMetadata,
    hasBeenPublished,
  ]);

  return (
    <div {...attrs} data-onboarding="publish-button">
      <button
        className={classNames('hot-button hot-button--success', {
          'hot-button--loading': isPublishing,
        })}
        onClick={handlePublish}
      >
        {t('header.publish')}
      </button>

      {mode === 'page' && (
        <>
          <PublishModal
            isOpen={isModalOpen}
            pageUrl={pageUrl}
            onClose={() => setIsModalOpen(false)}
          />
          <UserLimitModal
            id="warning-modal"
            isOpen={isUserLimitModalOpen}
            error={errorMessageUserLimitModal}
            onClose={() => setIsUserLimitModalOpen(false)}
          />
        </>
      )}
    </div>
  );
};
