import { HTMLAttributes, memo } from 'react';
import { Colors } from '@pages/PageWizard/components/Colors';
import { Textbox } from '@pages/PageWizard/components/Textbox';
import { ImageUpload } from '../ImageUpload';
import { StepContainer } from './styles';

type LinkTreeFirstStepProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    name: string;
    src: string;
    colors: UseCaseColors[];
  };

const FirstStepComponent: React.FC<LinkTreeFirstStepProps> = ({
  name,
  src,
  colors,
  onModifierChange,
  ...attrs
}) => (
  <StepContainer {...attrs}>
    <Textbox
      id="link-tree-username"
      name="username"
      label="pageWizard.publishPage.name.label"
      placeholder="pageWizard.publishPage.name.placeholder"
      value={name}
      onNameChange={(value) => onModifierChange({ prop: 'name', value })}
    />
    <ImageUpload
      src={src}
      onImageChange={(value) => onModifierChange({ prop: 'imgSrc', value })}
    />
    <Colors
      label="pageWizard.publishPage.colors.title"
      colors={colors}
      onColorsChange={(value) =>
        onModifierChange({ prop: 'colorsConfig', value })
      }
    />
  </StepContainer>
);

export const FirstStep = memo(FirstStepComponent);
