import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Bookmark: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M2 1.5C2 0.6875 2.65625 0 3.5 0H12.5C13.3125 0 14 0.6875 14 1.5V15.25C14 15.6875 13.6562 16 13.2188 16C13.0625 16 12.9062 15.9688 12.7812 15.875L8 12.5L3.1875 15.875C3.0625 15.9688 2.90625 16 2.75 16C2.3125 16 2 15.6875 2 15.25V1.5Z"
      fill={color}
    />
  </StyledSVG>
);
