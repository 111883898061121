export type Theme = {
  id: string;
  primaryColor: string;
  secondaryColor: string;
};

export const reducedThemes: Theme[] = [
  {
    id: '1',
    primaryColor: '#05234A',
    secondaryColor: '#DE5A07',
  },
  {
    id: '2',
    primaryColor: '#D97523',
    secondaryColor: '#23BED9',
  },
  {
    id: '3',
    primaryColor: '#0C177B',
    secondaryColor: '#38D001',
  },
  {
    id: '4',
    primaryColor: '#DB2C8C',
    secondaryColor: '#AD23E8',
  },
];

export const themes: Theme[] = [
  ...reducedThemes,
  {
    id: '5',
    primaryColor: '#99C1DE',
    secondaryColor: '#FF709B',
  },
  {
    id: '6',
    primaryColor: '#A2B373',
    secondaryColor: '#D84A05',
  },
];

export const getThemeById = (id: string) =>
  themes.find((theme) => theme.id === id);
