import styled, { css } from 'styled-components';

type ButtonProps = {
  iconSide: 'right' | 'left';
  width: string;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  ${({ iconSide, width }) => css`
    flex-direction: ${iconSide === 'right' ? 'row' : 'row-reverse'};
    width: ${width};
  `}
`;
