import '@hotmart-org-ca/cosmos/dist/sidebar';
import '@hotmart-org-ca/cosmos/dist/sidebar/sidebar-header';
import '@hotmart-org-ca/cosmos/dist/sidebar/sidebar-body';

import {
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Footer, Container } from './styles';

const sidebarRoot = document.createElement('div');
sidebarRoot.setAttribute('id', 'sidebar-portal');
document.body.appendChild(sidebarRoot);

export type SidebarProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  isOpen: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  headerClass?: string;
  bodyClass?: string;
  footerClass?: string;
  onClose?: () => void;
  onConfirmClose?: () => void;
};

const SidebarComponent: React.FC<SidebarProps> = ({
  isOpen,
  header,
  footer,
  children,
  onClose,
  onConfirmClose,
  bodyClass = '',
  headerClass = '',
  ...attrs
}) => {
  const hotSidebar = useRef<HotSidebarElement | null>(null);

  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    sidebarRoot.appendChild(el);

    return () => {
      sidebarRoot.removeChild(el);
    };
  }, [el]);

  useEffect(() => {
    const sidebar = hotSidebar?.current;
    if (sidebar && onConfirmClose) {
      (sidebar as HotSidebarElement).onConfirmClose = onConfirmClose;
    }

    if (sidebar && onClose) {
      sidebar.addEventListener('close', onClose);
    }

    return () => {
      if (sidebar && onClose) {
        sidebar.removeEventListener('close', onClose);
      }
    };
  }, [onClose, onConfirmClose]);

  return ReactDOM.createPortal(
    <Container>
      <hot-sidebar
        ref={hotSidebar}
        open={isOpen === true || undefined}
        {...attrs}
        id="sidebar"
      >
        {header && (
          <hot-sidebar-header
            className={classNames('hot-sidebar-header', headerClass)}
          >
            {header}
          </hot-sidebar-header>
        )}

        <hot-sidebar-body className={classNames('hot-sidebar-body', bodyClass)}>
          {children}
        </hot-sidebar-body>

        {footer && <Footer>{footer}</Footer>}
      </hot-sidebar>
    </Container>,
    el
  );
};

export const Sidebar = memo(SidebarComponent);
