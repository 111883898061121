import styled from 'styled-components';

export const ColorPickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 32px;
`;

export const Picker = styled.div`
  width: calc(50% - 32px);
`;
