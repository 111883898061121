import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';

import { HTMLAttributes, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { updateConfig } from '@store/slices/pageConfig';
import { Label } from '@components/Label';
import Toast from '@services/toast';
import Files from '@services/files';
import { containInvalidEmoji, isInvalidEmoji } from '@common/utils';
import { Container, Title, Button, TextArea } from './styles';

export type FacebookOptions = {
  pixelId?: boolean;
  accessToken?: boolean;
  eventName?: boolean;
  testEventCode?: boolean;
};

export const FacebookPixel: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const config = useSelector((state: State) => state.pageConfig);
  const uuid = useSelector((state: State) => state.pageInfo.uuid);

  const [isSaving, setIsSaving] = useState(false);
  const [updatedConfig, setUpdatedConfig] = useState(config.facebook);
  const [invalidEmoji, setInvalidEmoji] = useState<FacebookOptions>({});

  const handleValueChange = useCallback((value: string, key: string) => {
    setInvalidEmoji((state) => ({
      ...state,
      [key]: isInvalidEmoji(value),
    }));

    setUpdatedConfig((state) => ({
      ...state,
      [key]: value,
    }));
  }, []);

  const handleSave = useCallback(async () => {
    if (containInvalidEmoji(invalidEmoji)) {
      Toast.error('toastMessages.general.error.saved');
      return;
    }

    try {
      const configToSave = { ...config, facebook: updatedConfig };

      setIsSaving(true);
      dispatch(updateConfig(configToSave));

      await Files.saveConfigFile(uuid, configToSave);
      Toast.success('toastMessages.general.success.saved');
    } catch {
      Toast.error('toastMessages.general.error.saved');
    } finally {
      setIsSaving(false);
    }
  }, [config, dispatch, invalidEmoji, updatedConfig, uuid]);

  return (
    <Container {...attrs}>
      <Title>{t('facebook.pixel.title')}</Title>

      <div className="hot-form">
        <label className="hot-form__label" htmlFor="pixelID">
          {t('facebook.pixel.id')}
        </label>

        <input
          id="pixelID"
          className={classNames('hot-form__input hot-form__input--sm', {
            'is-invalid': invalidEmoji.pixelId,
          })}
          value={updatedConfig.pixelId}
          onChange={(e) => handleValueChange(e.target.value, 'pixelId')}
        />

        {invalidEmoji.pixelId && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}
      </div>

      <div className="hot-form">
        <label className="hot-form__label" htmlFor="pixelToken">
          {t('facebook.pixel.token')}
        </label>

        <TextArea
          id="pixelToken"
          className={classNames('hot-form__input hot-form__textarea-sm', {
            'is-invalid': invalidEmoji.accessToken,
          })}
          value={updatedConfig.accessToken}
          rows={3}
          onChange={(e) => handleValueChange(e.target.value, 'accessToken')}
        />
        {invalidEmoji.accessToken && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}
      </div>

      <div className="hot-form">
        <Label
          htmlFor="pixelEvents"
          label={t('facebook.pixel.events')}
          infoTooltip={{
            title: 'facebook.pixel.eventsTooltip.title',
            description: 'facebook.pixel.eventsTooltip.description',
            displayMode: 'always',
          }}
        />

        <input
          id="pixelEvents"
          className={classNames('hot-form__input hot-form__input--sm', {
            'is-invalid': invalidEmoji.eventName,
          })}
          value={updatedConfig.eventName}
          onChange={(e) => handleValueChange(e.target.value, 'eventName')}
        />
        {invalidEmoji.eventName && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}
      </div>

      <div className="hot-form">
        <label className="hot-form__label" htmlFor="codeEvents">
          {t('facebook.pixel.codeEvents')}
        </label>
        <input
          id="codeEvents"
          className={classNames('hot-form__input hot-form__input--sm', {
            'is-invalid': invalidEmoji.testEventCode,
          })}
          value={updatedConfig.testEventCode}
          onChange={(e) => handleValueChange(e.target.value, 'testEventCode')}
        />
        {invalidEmoji.testEventCode && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}
      </div>

      <Button
        onClick={handleSave}
        className={classNames(
          'hot-button hot-button--sm  hot-button--primary',
          {
            'hot-button--loading': isSaving,
          }
        )}
      >
        {t('facebook.pixel.button')}
      </Button>
    </Container>
  );
};
