import {
  EditorEngineManager,
  ElementMetadata,
  FontsConfig,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import { v1 as uuidV1 } from 'uuid';
import Http from '@services/http';
import { getPageChildrenFirstName, getPageRoute } from '@common/utils';
import { rebrand } from '@config/rebrand';

export default class Files {
  private static http = new Http();

  private static defaultSection = {
    uid: 'ls-section',
    component: 'ls-section',
    children: [
      {
        uid: 'ls-row',
        component: 'ls-row',
        children: [
          {
            uid: 'ls-section',
            component: 'ls-column',
          },
        ],
      },
    ],
  };

  static createFiles(
    id: string,
    data: { name: string; content: any }[],
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    return this.http.post<void>(
      this.getUrl(id, mode, templateManagementEnabled),
      data
    );
  }

  static getFiles(
    id: string,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    return this.http.get<PageFile[]>(
      this.getUrl(id, mode, templateManagementEnabled)
    );
  }

  static getTemplatePreviewFiles(id: string) {
    return this.http.get<PageFile[]>(`/v1/public/templates/${id}/files`);
  }

  static generateChecksum(files: ZipContent) {
    return this.http.post<{ checksum: string }>('/v1/checksum', {
      string_hash: JSON.stringify(files),
    });
  }

  static async savePageFile(
    id: string,
    page: Template,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `${this.getUrl(id, mode, templateManagementEnabled)}/page`;
    return this.http.put(url, { content: page });
  }

  static saveScriptsFile(
    id: string,
    scripts: Partial<PageScriptsFileState>,
    hasScriptFile?: boolean,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `${this.getUrl(id, mode, templateManagementEnabled)}/scripts`;

    return hasScriptFile
      ? this.http.patch(url, { content: scripts })
      : this.createFiles(
          id,
          [
            {
              name: 'scripts',
              content: scripts,
            },
          ],
          mode,
          templateManagementEnabled
        );
  }

  static saveConfigFile(
    id: string,
    config: Record<string, unknown>,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `/${this.getUrl(id, mode, templateManagementEnabled)}/config`;
    return this.http.patch(url, { content: config });
  }

  static savePopupsFile(
    id: string,
    popups: Popup[],
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `/${this.getUrl(id, mode, templateManagementEnabled)}/popups`;
    return this.http.patch(url, { content: { pagePopups: popups } });
  }

  static replaceConfigFile(
    id: string,
    config: Record<string, unknown>,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `/${this.getUrl(id, mode, templateManagementEnabled)}/config`;
    return this.http.put(url, { content: config });
  }

  static saveMetadataFile(
    id: string,
    page: Template,
    hasMetadata?: boolean,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const sections = page.children?.map(({ metadata = {} }) => ({
      templateId: metadata.templateId || null,
      templateName: metadata.templateName || null,
    }));

    return hasMetadata
      ? this.updateMetadataFile(
          id,
          { sections },
          mode,
          templateManagementEnabled
        )
      : this.createFiles(
          id,
          [
            {
              name: 'metadata',
              content: {
                sections,
              },
            },
          ],
          mode,
          templateManagementEnabled
        );
  }

  static saveFontsConfigFile(
    id: string,
    fontsConfig: FontsConfig,
    hasFontsConfig?: boolean,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    if (hasFontsConfig) {
      return this.updateFontsConfigFile(
        id,
        fontsConfig,
        mode,
        templateManagementEnabled
      );
    }

    return this.createFiles(
      id,
      [
        {
          name: 'fontsConfig',
          content: fontsConfig,
        },
      ],
      mode,
      templateManagementEnabled
    );
  }

  static normalizePageConfig(
    configContent?: PageConfigState,
    scriptsContent?: PageScriptsFileState
  ): [PageConfigState, PageScriptsFileState] {
    const scripts = this.normalizePageScripts(
      configContent?.scripts,
      scriptsContent
    );

    const config: PageConfigState = {
      mobileFirst: false,
      showMadeWithKlickpages: true,
      favicon: `${rebrand.urls.EDITOR_URL}/favicon.ico`,
      description: '',
      keywords: '',
      shareUrl: '',
      shareImage: '',
      preventIndexation: false,
      title: '',
      version: 1,
      pageVersion: 1,
      draft: false,
      urlParams: [],
      ...configContent,
      facebook: this.normalizePageConfigFacebook(configContent?.facebook),
    };

    if (config.scripts) {
      config.scripts = undefined;
    }

    return [config, scripts];
  }

  static clearTabletProp(element: Template) {
    const cleanTabletElement = { ...element };

    cleanTabletElement.mobile = {
      ...cleanTabletElement.tablet,
      ...cleanTabletElement.mobile,
    };
    cleanTabletElement.tablet = {};
    if (cleanTabletElement.children?.length) {
      cleanTabletElement.children = cleanTabletElement.children.map((child) =>
        this.clearTabletProp(child)
      );
    }

    return cleanTabletElement;
  }

  static normalizePage({
    page,
    pageVersion,
    mode = 'page',
    mobileFirst,
  }: NormalizePageConfig): Template {
    const metadata = {
      label: getPageChildrenFirstName('ls-section'),
    };

    if (!page) {
      return this.updateElementMetadata({
        element: this.getDefaultPage(metadata),
        mobileFirst,
      });
    }

    if (!page.children?.length) {
      return this.updateElementMetadata({
        mobileFirst,
        element: {
          ...page,
          children: [this.getDefaultSection(metadata)],
        },
      });
    }

    if (!pageVersion || mode === 'template') {
      return this.updateElementMetadata({ element: page, mobileFirst });
    }
    const cleanTabletPage = this.clearTabletProp(page);

    return cleanTabletPage;
  }

  static updateElementMetadata({
    element,
    clearUnlinkedProps = false,
    mobileFirst = false,
    updateImageMetadata = false,
  }: UpdateElementMetadataConfig) {
    let metadata;

    if (clearUnlinkedProps) {
      metadata = {
        ...(element.metadata || {}),
        unlinkedProps: {
          desktop: {},
          tablet: {},
          mobile: {},
        },
      };
    } else {
      metadata = this.updateMetadataWithUnlinkedProps(element, 'tablet');
      metadata = this.updateMetadataWithUnlinkedProps(element, 'mobile');
    }

    if (updateImageMetadata && element.component === 'ls-image') {
      metadata = {
        ...metadata,
        recommendedImageSize: this.getRecommendedImageSize(
          element,
          mobileFirst
        ),
      };
    }

    const template: Template = {
      ...element,
      metadata,
    };

    if (template.children && template.children.length) {
      template.children = this.updateChildrenMetadata({
        element: template.children,
        clearUnlinkedProps,
        mobileFirst,
        updateImageMetadata,
      });
    }

    return template;
  }

  private static getRecommendedImageSize(
    element: Template,
    mobileFirst?: boolean
  ) {
    const { height, 'max-width': width } = element;
    const tabletWidth = element.tablet?.['max-width'] || width;
    const tabletHeight = element.tablet?.height || height;

    if (mobileFirst) {
      const desktopWidth = element.desktop?.['max-width'] || tabletWidth;
      const desktopHeight = element.desktop?.height || tabletHeight;

      return {
        default: `${width} x ${height}`,
        tablet: `${tabletWidth} x ${tabletHeight}`,
        desktop: `${desktopWidth} x ${desktopHeight}`,
      };
    }

    const mobileWidth = element.mobile?.['max-width'] || tabletWidth;
    const mobileHeight = element.mobile?.height || tabletHeight;

    return {
      default: `${width} x ${height}`,
      tablet: `${tabletWidth} x ${tabletHeight}`,
      mobile: `${mobileWidth} x ${mobileHeight}`,
    };
  }

  private static updateMetadataWithUnlinkedProps(
    element: Template,
    position: 'tablet' | 'mobile'
  ) {
    const metadata = { ...(element.metadata || {}) };
    const entries = Object.keys(element[position] || {});

    if (entries.length) {
      const unlinkedPropsByPosition = entries.reduce<Record<string, boolean>>(
        (finalObject, propName) => ({
          ...finalObject,
          [propName]: true,
        }),
        {}
      );

      if (!metadata.unlinkedProps) {
        metadata.unlinkedProps = { [position]: unlinkedPropsByPosition };
      } else {
        metadata.unlinkedProps[position] = unlinkedPropsByPosition;
      }
    }

    return metadata;
  }

  private static updateChildrenMetadata({
    element: elements,
    clearUnlinkedProps,
    mobileFirst,
    updateImageMetadata,
  }: UpdateElementMetadataConfig<Template[]>) {
    return elements.map((element) =>
      this.updateElementMetadata({
        element,
        clearUnlinkedProps,
        mobileFirst,
        updateImageMetadata,
      })
    );
  }

  private static getUrl(
    id: string,
    mode: EditorMode = 'page',
    templateManagementEnabled: boolean = false
  ) {
    return `${getPageRoute(mode, templateManagementEnabled)}/${id}/files`;
  }

  private static updateMetadataFile(
    id: string,
    metadata: MetadataFile,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `${this.getUrl(id, mode, templateManagementEnabled)}/metadata`;
    return this.http.patch(url, { content: metadata });
  }

  private static updateFontsConfigFile(
    id: string,
    fontsConfig: FontsConfig,
    mode?: EditorMode,
    templateManagementEnabled: boolean = false
  ) {
    const url = `${this.getUrl(
      id,
      mode,
      templateManagementEnabled
    )}/fontsConfig`;
    return this.http.put(url, { content: fontsConfig });
  }

  private static normalizePageScripts(
    configScripts?: ScriptConfigV0 | ScriptConfigV1,
    scriptsFile?: PageScriptsFileState
  ) {
    const defaultScripts: PageScriptsFileState = {
      head: { start: [], end: [] },
      body: { start: [], end: [] },
      supportChatScript: '',
    };

    if (!configScripts || !Object.keys(configScripts).length) {
      return scriptsFile || defaultScripts;
    }

    const normalizedScripts = scriptsFile || defaultScripts;

    return this.normalizeScriptConfig(configScripts, normalizedScripts);
  }

  private static normalizeScriptConfig(
    scripts: ScriptConfigV0 | ScriptConfigV1,
    normalizedScripts: PageScriptsFileState
  ) {
    this.normalizeScriptProp(scripts, normalizedScripts, 'head');
    this.normalizeScriptProp(scripts, normalizedScripts, 'body');

    return normalizedScripts;
  }

  private static normalizeScriptProp(
    scripts: ScriptConfigV0 | ScriptConfigV1,
    normalizedScripts: PageScriptsFileState,
    prop: 'body' | 'head'
  ) {
    const propValue = scripts[prop];

    if (propValue) {
      const script = typeof propValue === 'string' ? propValue : propValue.end;

      if (script) {
        normalizedScripts[prop].end.push({
          id: uuidV1(),
          name: 'Script',
          script: script || '',
        });
      }
    }
  }

  private static normalizePageConfigFacebook(
    facebookConfig?: FacebookConfigState
  ): FacebookConfigState {
    const defaultConfig = {
      accessToken: '',
      pixelId: '',
      eventName: '',
      testEventCode: '',
    };

    return facebookConfig
      ? { ...defaultConfig, ...facebookConfig }
      : defaultConfig;
  }

  private static getDefaultPage(metadata: ElementMetadata) {
    return EditorEngineManager.getTemplateWithDefaults({
      uid: 'ls-page',
      component: 'ls-page',
      children: [{ ...this.defaultSection, metadata }],
    });
  }

  private static getDefaultSection(metadata: ElementMetadata) {
    return EditorEngineManager.getTemplateWithDefaults({
      ...this.defaultSection,
      metadata,
    });
  }
}
