import CASTokenStrategy from '../casTokenStrategy';
import ColaboratorTokenStrategy from '../colaboratorTokenStrategy';
import JWTTokenStrategy from '../klickartAccessTokenStrategy';
import KlickauthTokenStrategy from '../klickAuthTokenStrategy';
import TokenStrategyInterface from '../tokenStrategyInterface';

type tokenKinds = 'jwt' | 'klickauth' | 'cas' | 'colaborator';

export default class TokenStrategyFactory {
  private static tokenStrategies: Record<tokenKinds, TokenStrategyInterface> = {
    jwt: new JWTTokenStrategy(),
    klickauth: new KlickauthTokenStrategy(),
    cas: new CASTokenStrategy(),
    colaborator: new ColaboratorTokenStrategy(),
  };

  static getTokenStrategy(tokenKind: tokenKinds): TokenStrategyInterface {
    return this.tokenStrategies[tokenKind];
  }
}
