import styled from 'styled-components';

export const Actions = styled.div`
  color: var(--gray-500);
  display: flex;
  align-items: center;
  gap: 8px;
  display: none;
`;

export const Container = styled.div`
  background: white;
  border-bottom: 1px solid var(--gray-200);
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  :hover ${Actions} {
    display: flex;
  }
`;

export const Url = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--gray-500);
  cursor: pointer;
`;

export const Icon = styled.div`
  cursor: pointer;

  :hover {
    color: var(--blue);
  }
`;
