import {
  HtmlHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from '@components/Block';
import debounce from 'lodash.debounce';
import { CosmosButton } from '@components/CosmosButton';
import { AiButtonWrapper, StyledTextInput } from './styles';

export type TextInputsValues = {
  title: string;
  subtitle: string;
};

export type TextInputsProps = HtmlHTMLAttributes<HTMLDivElement> &
  TextInputsValues & {
    onAiButtonClicked: () => void;
    onTextChanged: (value: TextInputsValues) => void;
  };

const TextInputsComponent: React.FC<TextInputsProps> = ({
  title,
  subtitle,
  onAiButtonClicked,
  onTextChanged,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [currentTexts, setCurrentTexts] = useState<TextInputsValues>({
    title,
    subtitle,
  });

  const debouncedOnValueChange = useRef(debounce(onTextChanged, 500));

  const handleChange = useCallback(
    (e: React.ChangeEvent, inputType: string) => {
      const { value } = e.target as HTMLInputElement;

      setCurrentTexts({ ...currentTexts, [inputType]: value });

      debouncedOnValueChange.current({
        ...currentTexts,
        [inputType]: value,
      });
    },
    [currentTexts]
  );

  useEffect(() => setCurrentTexts({ title, subtitle }), [title, subtitle]);

  return (
    <Block
      label={t('pageWizard.salesPage.paidWithProduct.presentProduct.textTitle')}
      showSeparator
      {...attrs}
    >
      {Object.keys(currentTexts).map((inputType) => (
        <StyledTextInput
          key={`${inputType}-text`}
          id={`${inputType}-text`}
          name={inputType}
          removeEmoji
          placeholder={t(
            `pageWizard.salesPage.paidWithProduct.presentProduct.${inputType}Placeholder`
          )}
          size="md"
          value={currentTexts[inputType as keyof TextInputsValues]}
          onChange={(e) => handleChange(e, inputType)}
          inputType="textarea"
          rows={inputType === 'title' ? 2 : 4}
          label={t(
            `pageWizard.salesPage.paidWithProduct.presentProduct.${inputType}Label`
          )}
        />
      ))}
      <AiButtonWrapper>
        <CosmosButton
          label={t('pageWizard.salesPage.ai.aiButtonLabel')}
          iconClass="fal fa-bolt"
          iconSide="right"
          type="secondary"
          onButtonClicked={onAiButtonClicked}
          size="md"
        />
      </AiButtonWrapper>
    </Block>
  );
};

export const TextInputs = memo(TextInputsComponent);
