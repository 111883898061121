import styled, { css } from 'styled-components';

export type StyledMenuItemLabel = {
  hasIcon?: boolean;
  breakLine?: boolean;
};

export type StyledMenuItemWrapper = {
  hasIcon?: boolean;
};

export const StyledMenuItem = styled.div`
  display: flex;
  flex: 2;
`;

export const StyledBlockedIcon = styled.i`
  font-size: 20px;
`;

export const MenuItemLabel = styled.span<StyledMenuItemLabel>`
  ${({ hasIcon, breakLine }) => css`
    white-space: ${breakLine ? 'break-spaces' : 'unset'};
    padding-left: ${hasIcon ? '24px' : '0px'};
  `}
  width: 100%;
`;

export const MenuItemWrapper = styled.div<StyledMenuItemWrapper>`
  :hover {
    ${MenuItemLabel} {
      color: var(--blue-dark);
    }
    ${({ hasIcon }) => css`
      background-color: ${hasIcon ? 'unset' : 'var(--blue-lightest)'};
    `}

    ${StyledBlockedIcon} {
      color: var(--blue-dark);
    }
  }
`;
