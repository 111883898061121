import { ExternalLink } from '@components/ExternalLink';
import styled from 'styled-components';

export const Description = styled.div`
  font-size: 12px;
  color: var(--gray-500);
  margin-bottom: 16px;
`;

export const Link = styled(ExternalLink)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export const AddButton = styled.button`
  width: 180px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 100%;
`;

export const AddIcon = styled.i`
  font-size: 12px;
  margin-right: 6px;
`;

export const UrlParamsList = styled.div`
  margin-top: 16px;
`;

export const UrlParam = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 6px 0px;
  min-height: 40px;

  &:hover {
    .icon-group {
      display: flex;
    }

    .section-icon * {
      fill: var(--blue);
    }
  }
`;

export const UrlParamName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
`;

export const IconGroup = styled.span`
  z-index: 1;
  cursor: pointer;
  display: none;
  color: var(--gray-600);

  :hover {
    color: var(--blue);
  }
`;

export const EmptyList = styled.div`
  margin: 24px;
  text-align: center;
  font-size: 12px;
  color: var(--gray-400);
`;
