import { HtmlHTMLAttributes, memo, useCallback } from 'react';
import { CosmosSwitch } from '@components/CosmosSwitch';
import { Block } from '@components/Block';
import { TextInputs } from '../../../TextInputs';

type ButtonConfigProps = HtmlHTMLAttributes<HTMLDivElement> & {
  buttonText: string;
  buttonLink: string;
  hasButton: boolean;
  onShowButtonChange: (value: boolean) => void;
  onTextChanged: (value: UseCaseModifierChange) => void;
};

const ButtonConfigComponent: React.FC<ButtonConfigProps> = ({
  buttonText,
  buttonLink,
  hasButton,
  onShowButtonChange,
  onTextChanged,
  ...attrs
}) => {
  const handleEnableButton = useCallback(
    (value: boolean) => {
      onShowButtonChange?.(value);
    },
    [onShowButtonChange]
  );

  const handleProtocolChange = useCallback(
    (textChange: UseCaseModifierChange) => {
      if (textChange.prop === 'buttonLink') {
        const text = textChange.value as string;
        onTextChanged({
          prop: 'buttonLink',
          value:
            !text || text.startsWith('https://') || text.startsWith('http://')
              ? text
              : `https://${text}`,
        });
      }
    },
    [onTextChanged]
  );

  return (
    <Block
      label="pageWizard.funnelCaptureThanksPage.buttonBlockTitle"
      {...attrs}
    >
      <CosmosSwitch
        id="form-personalize-tag"
        isChecked={hasButton}
        label="pageWizard.funnelCaptureThanksPage.enableButton"
        onSwitchChanged={handleEnableButton}
      />
      {hasButton && (
        <TextInputs
          texts={[
            { inputType: 'buttonText', value: buttonText },
            {
              inputType: 'buttonLink',
              value: buttonLink,
              placeholder:
                'pageWizard.funnelCaptureThanksPage.buttonLinkPlaceholder',
            },
          ]}
          onTextChanged={onTextChanged}
          onInputBlurred={handleProtocolChange}
        />
      )}
    </Block>
  );
};

export const ButtonConfig = memo(ButtonConfigComponent);
