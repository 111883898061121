import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import {
  HTMLAttributes,
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import { OfferTable } from '@components/OfferTable';
import { ProductTable } from '@components/ProductTable';
import HotmartProductService from '@services/hotmartProduct';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ButtonsContainer,
  IconWrapper,
  LeftStepContent,
  RightStepContent,
  StepsContainer,
  FirstIcon,
  SecondIcon,
  FirstStepText,
  SecondStepText,
  Title,
} from './styles';

type HotmartProductModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  selectedProductId?: string;
  selectedOfferId?: string;
  showOfferKey?: boolean;
  modalTitle: string;
  onClose?: () => void;
  onProductDataSelected?: (
    product: HotmartProduct,
    offer: Partial<HotmartProduct>
  ) => void;
};

const HotmartProductModalComponent: React.FC<HotmartProductModalProps> = ({
  isOpen,
  selectedProductId: selectedProductIdProp = '',
  selectedOfferId: selectedOfferIdProp = '',
  showOfferKey = false,
  modalTitle,
  onClose,
  onProductDataSelected,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [products, setProducts] = useState<HotmartProduct[]>([]);
  const [selectedProductId, setSelectedProductId] = useState(
    selectedProductIdProp
  );
  const [selectedOfferId, setSelectedOfferId] = useState(selectedOfferIdProp);
  const [selectedProduct, setSelectedProduct] = useState<HotmartProduct>();
  const [selectedProductOffer, setSelectedProductOffer] =
    useState<Partial<ProductProps>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [offers, setOffers] = useState<HotmartProductOffer[]>([]);

  const stepStates = useMemo(
    () => [
      {
        first: ['far', 'dot-circle'],
        second: ['far', 'circle'],
      },
      {
        first: faCircle,
        second: ['far', 'dot-circle'],
      },
    ],
    []
  );

  const getOffers = useCallback(async () => {
    try {
      setLoading(true);

      const offersData = await HotmartProductService.getOffersByProductId(
        selectedProductId
      );

      setOffers(offersData);
    } finally {
      setLoading(false);
    }
  }, [selectedProductId]);

  const handleFirstButtonClick = useCallback(
    () => (currentStep === 1 ? onClose?.() : setCurrentStep(currentStep - 1)),
    [currentStep, onClose]
  );

  const handleProductChange = useCallback((product: HotmartProduct) => {
    setSelectedProduct(product);
    setSelectedProductId(product.id);
  }, []);

  const handleOfferChange = useCallback((offer: Partial<ProductProps>) => {
    setSelectedProductOffer(offer);
    setSelectedOfferId(offer.offerId as string);
  }, []);

  const handleNext = useCallback(() => {
    if (currentStep === 1) {
      if (selectedProductId !== selectedProductIdProp) {
        setSelectedOfferId('');
      }
      getOffers();
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 2) {
      onProductDataSelected?.(
        selectedProduct as HotmartProduct,
        selectedProductOffer as Partial<ProductProps>
      );
      setCurrentStep(1);
    }
  }, [
    currentStep,
    getOffers,
    onProductDataSelected,
    selectedProductId,
    selectedProduct,
    selectedProductIdProp,
    selectedProductOffer,
  ]);

  const getProducts = useCallback(async () => {
    try {
      setLoading(true);

      const productsData = await HotmartProductService.getProducts();
      setProducts(productsData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      getProducts();
    } else {
      setCurrentStep(1);
      setSelectedOfferId(selectedOfferIdProp || '');
      setSelectedProductId(selectedProductIdProp || '');
    }
  }, [getProducts, isOpen, selectedOfferIdProp, selectedProductIdProp]);

  useEffect(() => {
    if (selectedOfferIdProp) {
      setSelectedOfferId(selectedOfferIdProp);
    }

    if (selectedProductIdProp) {
      setSelectedProductId(selectedProductIdProp);
    }
  }, [selectedOfferIdProp, selectedProductIdProp]);

  const handleClose = useCallback(() => {
    setCurrentStep(1);
    onClose?.();
  }, [onClose]);

  return (
    <BaseModal
      fixedSize="600px"
      divider
      isOpen={isOpen}
      onClose={handleClose}
      header={<Title>{modalTitle}</Title>}
      footer={
        <ButtonsContainer>
          <button
            className="hot-button hot-button--secondary"
            onClick={handleFirstButtonClick}
          >
            {t(
              `modals.hotmartProductModal.${
                currentStep === 1 ? 'cancel' : 'back'
              }`
            )}
          </button>
          <button
            className="hot-button hot-button--primary"
            disabled={
              !selectedProductId || (currentStep === 2 && !selectedOfferId)
            }
            onClick={handleNext}
          >
            {t('modals.hotmartProductModal.continue')}
          </button>
        </ButtonsContainer>
      }
      {...attrs}
    >
      <StepsContainer>
        <LeftStepContent step={currentStep}>
          <IconWrapper>
            <FirstIcon
              icon={stepStates[currentStep - 1].first as IconProp}
              step={currentStep}
            />
          </IconWrapper>
          <FirstStepText step={currentStep}>
            {t('modals.hotmartProductModal.firstStep')}
          </FirstStepText>
        </LeftStepContent>

        <RightStepContent step={currentStep}>
          <IconWrapper>
            <SecondIcon
              icon={stepStates[currentStep - 1].second as IconProp}
              step={currentStep}
            />
          </IconWrapper>
          <SecondStepText step={currentStep}>
            {t('modals.hotmartProductModal.secondStep')}
          </SecondStepText>
        </RightStepContent>
      </StepsContainer>

      {currentStep === 1 ? (
        <ProductTable
          itemsPerPage={4}
          searchBarSize="100%"
          textSize="14px"
          products={products}
          loading={loading}
          onSelectProduct={handleProductChange}
          selectedProductId={selectedProductId}
          hideColumns={{ name: true, id: true, format: true }}
        />
      ) : (
        <OfferTable
          itemsPerPage={4}
          searchBarSize="100%"
          loading={loading}
          hideColumn
          offers={offers}
          selectedOfferId={selectedOfferId}
          showOfferKey={showOfferKey}
          onSelectOffer={handleOfferChange}
        />
      )}
    </BaseModal>
  );
};

export const HotmartProductModal = memo(HotmartProductModalComponent);
