import styled, { css } from 'styled-components';

type StyledLoaderWrapperProps = {
  fixed?: boolean;
};

export const LoaderWrapper = styled.div<StyledLoaderWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ fixed }) => css`
    ${fixed
      ? css`
          position: fixed;
          inset: 0;
          z-index: 100;
          background: white;
        `
      : css`
          width: 100%;
          height: 100%;
        `}
  `}
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 300;
  line-height: 28.8px;
  text-align: center;
  width: 425px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
`;
