import i18next from 'i18next';

export const getTemplate = () => ({
  background: 'none',
  padding: '0px 0px 0px 0px',
  desktop: {},
  tablet: {},
  mobile: {},
  uid: 'ls-sNUpBuqVQKqZRyNTw847X9',
  component: 'ls-page',
  children: [
    {
      background: '#4BAEC3',
      tag: 'section',
      'is-fixed': false,
      'end-date': '2023-02-28T23:52:42',
      timezone: '-03:00_utcMinus0300MainCities',
      'time-on-page': '5',
      'time-on-page-unit': 'seconds',
      'delay-type': 'disabled',
      desktop: {},
      tablet: {},
      mobile: {},
      uid: 'ls-jh1praBxFksiq6mY6s8HYS',
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: 'ls-w7NLZn3yzFfQmkwWrrnZKi',
          component: 'ls-row',
          children: [
            {
              padding: '115px 0px 300px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '72px 0px 280px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '56px 0px 56px 0px',
              },
              uid: 'ls-am98CLD9NkeRwsfp9Khsqo',
              component: 'ls-column',
              metadata: {
                unlinkedProps: {
                  mobile: {},
                  tablet: {},
                  desktop: {},
                },
              },
              children: [
                {
                  padding: '10px 10px 50px 0px',
                  'border-radius': '31px 31px 31px 31px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '759px',
                  margin: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: {
                    'border-radius': '31px 31px 31px 31px',
                    margin: '10px 25px 10px 25px',
                    padding: '24px 10px 48px 0px',
                    'max-width': '80%',
                  },
                  mobile: {
                    padding: '0px 0px 24px 0px',
                    margin: '0px 0px 0px 0px',
                    'max-width': '80%',
                    'border-radius': '20px 20px 20px 20px',
                  },
                  uid: 'ls-4JvWVfxJhBGXqQQdgY8Mzh',
                  component: 'ls-group',
                  children: [
                    {
                      text: '<h1 style="text-align: center; line-height: 1.15;"><strong style="color: rgb(123, 122, 122); font-family: Montserrat, sans-serif; font-size: 28px; background-color: rgba(0, 0, 0, 0);">Baixe grátis o [Nome do Conteúdo] e conheça o método que vai te ajudar a [Solução do Problema] sem precisar de [Dor comum]</strong></h1>',
                      'max-width': '80%',
                      padding: '39px 39px 39px 39px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><strong style="color: rgb(123, 122, 122); font-family: Montserrat, sans-serif; font-size: 24px; background-color: rgba(0, 0, 0, 0);">Baixe grátis o [Nome do <br> Conteúdo] e conheça o método que vai te ajudar a [Solução do Problema] sem precisar de [Dor comum]</strong></p>',
                        padding: '35px 10px 40px 10px',
                        'max-width': '85%',
                      },
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="color: rgb(123, 122, 122); font-family: Montserrat, sans-serif; font-size: 16px; background-color: rgba(0, 0, 0, 0);">Baixe grátis o [Nome do Conteúdo] e conheça o método que vai te ajudar a [Solução do Problema] sem precisar de [Dor Comum].</strong></p>',
                        'max-width': '88%',
                        padding: '35px 0px 20px 0px',
                      },
                      uid: 'ls-1m5KkKu8sXYdEKYDUYsDQA',
                      component: 'ls-text',
                      metadata: {
                        unlinkedProps: {
                          mobile: {},
                          tablet: {},
                          desktop: {},
                        },
                      },
                    },
                    {
                      height: '100%',
                      margin: '0px 0px 0px 0px',
                      'max-width': '75%',
                      padding: '0px 16px 16px 16px',
                      'link-gdpr':
                        '{"uid":"ls-fjN6ZXaGcxTwW5tXiz1hoJ-text-link","component":"ls-text","text":"<p style=\\"text-align: center;\\"><strong style=\\"font-family: Montserrat, sans-serif; font-size: 14px; color: rgb(123, 122, 122);\\">100% livre de spam</strong></p>","max-width":"100%","padding":"0px 0px 1px 0px","tablet":{"padding":"0px 0px 0px 0px"},"mobile":{},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{},"tablet":{},"desktop":{}}}}',
                      'text-gdpr':
                        '{"uid":"ls-fjN6ZXaGcxTwW5tXiz1hoJ-text","component":"ls-text","text":"<p style=\\"text-align: center;\\"><span style=\\"font-family: Raleway, sans-serif; color: rgb(123, 122, 122); font-size: 14px;\\">Entre com seu melhor email!</span></p>","max-width":"100%","padding":"10px 0px 0px 0px","tablet":{},"mobile":{"text":"<p style=\\"text-align: center;\\"><span style=\\"font-family: Raleway, sans-serif; color: rgb(123, 122, 122); font-size: 14px;\\">Entre com seu melhor email!</span></p>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{},"tablet":{},"desktop":{}}}}',
                      checkbox: `{"uid":"ls-fjN6ZXaGcxTwW5tXiz1hoJ-checkbox","component":"ls-checkbox","accent-color":"#818181","checked":false,"color":"#7B7A7A","disabled":false,"font-size":"18px","font-family":"Montserrat, sans-serif","margin":"0px 0px 14px 0px","max-width":"100%","padding":"0px 0px 0px 122px","readonly":false,"required":true,"name":"gdpr","type":"gdpr","label": "${i18next.t(
                        'pageWizard.publishLeadCapturePage.form.checkboxLabel'
                      )}","value": "${i18next.t(
                        'pageWizard.publishLeadCapturePage.form.checkboxLabel'
                      )}","tablet":{"padding":"0px 0px 0px 62px","accent-color":"#818181"},"mobile":{"padding":"0px 0px 0px 0px","font-size":"13px","color":"#7B7A7A"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{},"tablet":{},"desktop":{}}}}`,
                      'focus-color': '',
                      'recaptcha-script-link':
                        'https://send.klickpages.com.br/static/js/recaptcha.min.js',
                      desktop: {},
                      tablet: {
                        'max-width': '85%',
                      },
                      mobile: {
                        'max-width': '90%',
                        height: '100%',
                      },
                      uid: 'ls-fjN6ZXaGcxTwW5tXiz1hoJ',
                      component: 'ls-klicksend-form',
                      button:
                        '{"uid":"ls-fjN6ZXaGcxTwW5tXiz1hoJ-button","component":"ls-button","action":"link","color":"#FFFFFF","background":"#8BC34A","border-color":"#EF4E23","border-radius":"24px 24px 24px 24px","border-style":"none","border-top-width":"2px","border-right-width":"2px","border-bottom-width":"2px","border-left-width":"2px","button-class":"","end-date":"2023-02-28T23:54:32","timezone":"-03:00_utcMinus0300MainCities","time-on-page":"5","time-on-page-unit":"seconds","delay-type":"disabled","font-family":"Montserrat, sans-serif","font-size":"24px","hover-background":"none","hover-color":"none","has-icon":true,"has-text":true,"href":"","icon-position":"left","icon":"fas fa-arrow-right","is-bold":"true","is-italic":false,"is-underline":false,"is-strike":false,"label":"Enviar","margin":"10px 0px 0px 0px","max-width":"100%","padding":"23px 32px 23px 32px","target":"_blank","tablet":{"font-size":"20px","border-radius":"20px 20px 20px 20px","padding":"20px 32px 20px 32px"},"mobile":{"font-size":"18px","border-radius":"16px 16px 16px 16px","padding":"20px 32px 20px 32px"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{},"tablet":{},"desktop":{}}}}',
                      'is-bold': true,
                      'form-id': 'QXuPG2v',
                      fields:
                        '{"uid":"ls-fjN6ZXaGcxTwW5tXiz1hoJ-inputs","component":"ls-klicksend-form-inputs","background":"#FFFFFF","border-color":"#989898","border-radius":"9px 9px 9px 9px","border-style":"solid","border-top-width":" 1px","border-right-width":"1px","border-bottom-width":"1px","border-left-width":"1px","color":"#191C1F","font-family":"Montserrat, sans-serif","font-size":"18px","has-label":false,"label-color":"#191C1F","input-type":"text","label-font-size":"15px","label-is-bold":false,"label-is-italic":false,"placeholder-color":"#707780","has-icon":false,"icon-fill":"#707780","outline":"none","label-text-decoration":"none","height":"100%","max-width":"100%","fields":"[{\\"name\\":\\"email\\",\\"type\\":\\"email\\",\\"label\\":\\"Email\\",\\"value\\":\\"\\",\\"required\\":true},{\\"name\\":\\"first_name\\",\\"type\\":\\"text\\",\\"label\\":\\"Primeiro Nome\\",\\"value\\":\\"\\",\\"required\\":false},{\\"name\\":\\"gdpr\\",\\"type\\":\\"gdpr\\",\\"label\\":\\"Concordo em receber os e-mails\\",\\"value\\":\\"Concordo em receber os e-mails\\",\\"required\\":true}]","tablet":{"label-font-size":"16px","font-size":"18px"},"mobile":{"font-size":"14px","border-radius":"8px 8px 8px 8px","font-family":"Montserrat, sans-serif","has-label":false},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{},"tablet":{},"desktop":{}}}}',
                      'recaptcha-enabled': false,
                      'submit-link':
                        'https://handler.klicksend.com.br/subscription/QXuPG2v',
                      metadata: {
                        unlinkedProps: {
                          mobile: {},
                          tablet: {},
                          desktop: {},
                        },
                      },
                    },
                  ],
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
              ],
            },
          ],
          metadata: {
            unlinkedProps: {
              mobile: {},
              tablet: {},
              desktop: {},
            },
          },
        },
      ],
      metadata: {
        label: 'Seção 1',
      },
    },
  ],
  metadata: {},
});

export const getFontsConfig = () => ({
  Montserrat: {
    regular: [400, 700],
    italic: [400, 700],
  },
  Raleway: {
    regular: [400, 700],
    italic: [400, 700],
  },
});
