import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0px;
`;

export const Title = styled.label`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const Content = styled.div`
  margin-bottom: 16px;
  align-items: flex-start;
  display: flex;
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  margin-right: 16px;
  height: 54px;
  min-width: 54px;
  color: var(--gray-400);
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
`;

export const Description = styled.label`
  color: var(--gray-500);
  font-size: 12px;
  margin-bottom: 0;
`;

export const Label = styled.label`
  margin-bottom: 0;
  width: 100%;
`;

export const ButtonIcon = styled.i`
  font-size: 16px;
  margin-right: 8px;
`;

export const Input = styled.input`
  display: none;
`;
