import { HTMLAttributes, memo, useCallback } from 'react';
import { OfferInfo } from './components/OfferInfo';
import { PurchaseButton } from './components/PurchaseButton';
import { SalesUseCase } from '../../salesUseCase';

export type UpdateOfferProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    product: ProductProps;
    updatedProduct: UpdatedProductProps;
    useCase: SalesUseCase;
  };

const UpdateOfferComponent: React.FC<UpdateOfferProps> = ({
  product,
  updatedProduct,
  useCase,
  onModifierChange,
  ...attrs
}) => {
  const handleOfferChange = useCallback(
    (updatedOffer: Partial<ProductProps>) => {
      useCase.modifyByCategory('offerLink', updatedOffer.offerLink);
      useCase.modifyByCategory('offerPrice', updatedOffer.offerPrice);
      useCase.modifyByCategory(
        'offerInstallments',
        updatedOffer.offerMaxInstallments
      );

      onModifierChange({
        prop: 'product',
        value: {
          ...product,
          ...updatedOffer,
        },
      });
    },
    [onModifierChange, product, useCase]
  );

  const handleButtonTextChange = useCallback(
    (updatedProductProps: Partial<UpdatedProductProps>) => {
      useCase.modifyByCategory(
        'purchaseButton',
        updatedProductProps.purchaseButton
      );

      onModifierChange({
        prop: 'updatedProduct',
        value: {
          ...updatedProduct,
          ...updatedProductProps,
        },
      });
    },
    [onModifierChange, updatedProduct, useCase]
  );

  return (
    <div {...attrs}>
      <OfferInfo product={product} onOfferChange={handleOfferChange} />
      <PurchaseButton
        updatedProduct={updatedProduct}
        onButtonTextChange={handleButtonTextChange}
      />
    </div>
  );
};

export const UpdateOffer = memo(UpdateOfferComponent);
