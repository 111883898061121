import {
  Routes as ReactRoutes,
  Route,
  matchRoutes,
  RouteMatch,
  RouteObject,
} from 'react-router-dom';
import { UseCasesPage } from '@pages/UseCases';
import {
  PUBLISH_PAGE_ROUTE_PREFIX,
  PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX,
  SALES_PAGE_PAID_WITH_PRODUCT_ROUTE_PREFIX,
  SALES_PAGE_PAID_WITHOUT_PRODUCT_ROUTE_PREFIX,
  FUNNEL_CAPTURE_PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX,
  FUNNEL_CAPTURE_THANKS_PAGE_ROUTE_PREFIX,
  HOTMART_JOURNEY_SALES_PAGE_WITH_PRODUCT_PREFIX,
} from '@common/constants';
import { StartLogoutPage } from '@pages/StartLogout';
import { LogoutPage } from '@pages/Logout';
import { AuthLogin } from '@pages/AuthLogin';
import { Editor } from '../pages/Editor';
import { NotFound } from '../pages/NotFound';
import { Surrogate } from '../pages/Surrogate';
import { Pages } from '../pages/Pages';
import { Templates } from '../pages/Templates';
import { RouteInterceptor } from '../components/RouteInterceptor';
import { acceptedLanguages } from '../i18n';
import { EditorPreview } from '../pages/EditorPreview';
import { PageWizard } from '../pages/PageWizard';

enum RoutePermissions {
  templateManagement = 'templateManagementEnabled',
  templateUserManagement = 'userTemplateManagementEnabled',
}

type AppRoute = {
  path: string;
  page: JSX.Element;
  permissions?: RoutePermissions[];
  screenAccess?: string;
};

type AppRouteMatch = RouteMatch<string> & {
  route: RouteObject & AppRoute;
};

const notMatchedRoute: AppRoute = {
  path: '*',
  page: <NotFound />,
};

const notFoundRoute: AppRoute = {
  path: '/404',
  page: <NotFound />,
};

export const routes: AppRoute[] = [
  {
    path: '/',
    page: <Pages />,
  },
  {
    path: '/templates',
    page: <Templates />,
    permissions: [
      RoutePermissions.templateManagement,
      RoutePermissions.templateUserManagement,
    ],
  },
  {
    path: '/templates/:id',
    page: <Editor mode="template" />,
    permissions: [
      RoutePermissions.templateManagement,
      RoutePermissions.templateUserManagement,
    ],
  },
  {
    path: '/templates/:id/preview',
    page: <EditorPreview mode="template" />,
  },
  {
    path: '/:id',
    page: <Editor />,
    screenAccess: 'editor-pages',
  },
  {
    path: `${PUBLISH_PAGE_ROUTE_PREFIX}/:id`,
    page: <PageWizard type="publish_page" />,
    screenAccess: 'link-page',
  },
  {
    path: `${PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX}/:id`,
    page: <PageWizard type="publish_lead_capture_page" />,
    screenAccess: 'capture',
  },
  {
    path: `${SALES_PAGE_PAID_WITH_PRODUCT_ROUTE_PREFIX}/:id`,
    page: <PageWizard type="sales_page_paid_with_product" />,
    screenAccess: 'sales-paid-with-product',
  },
  {
    path: `${SALES_PAGE_PAID_WITHOUT_PRODUCT_ROUTE_PREFIX}/:id`,
    page: <PageWizard type="sales_page_paid_without_product" />,
    screenAccess: 'sales-paid-without-product',
  },
  {
    path: `${FUNNEL_CAPTURE_PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX}/:id`,
    page: <PageWizard type="funnel_capture_publish_lead_capture_page" />,
    screenAccess: 'funnel-capture-publish-lead-capture-page',
  },
  {
    path: `${FUNNEL_CAPTURE_THANKS_PAGE_ROUTE_PREFIX}/:id`,
    page: <PageWizard type="funnel_capture_thanks_page" />,
    screenAccess: 'funnel-capture-thanks-page',
  },
  {
    path: `${HOTMART_JOURNEY_SALES_PAGE_WITH_PRODUCT_PREFIX}/:id`,
    page: <PageWizard type="hotmart_journey_sales_page_with_product" />,
    screenAccess: 'hotmart-journey-sales-page-with-product',
  },
  {
    path: '/use-cases',
    page: <UseCasesPage />,
  },
  {
    path: '/auth/start-logout',
    page: <StartLogoutPage />,
  },
  {
    path: '/auth/logout',
    page: <LogoutPage />,
  },
  {
    path: '/auth/surrogate',
    page: <Surrogate />,
  },
  {
    path: '/auth/login',
    page: <AuthLogin />,
  },
];

const routesWithI18n: AppRoute[] = [];

routes.forEach((route) => {
  routesWithI18n.push(route);

  acceptedLanguages.forEach((language) => {
    routesWithI18n.push({
      ...route,
      path: `/${language}${route.path}`,
    });
  });
});

routesWithI18n.push(notFoundRoute);
routesWithI18n.push(notMatchedRoute);

export const Routes: React.FC = ({ ...attrs }) => (
  <ReactRoutes {...attrs}>
    {routesWithI18n.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={<RouteInterceptor>{route.page}</RouteInterceptor>}
      />
    ))}
  </ReactRoutes>
);

export const getMatchRoute = (location: string | Partial<Location>) => {
  const matchedRoutes = matchRoutes(routesWithI18n, location);

  if (matchedRoutes && matchedRoutes[0].route.path !== '*') {
    const [matchedRoute] = matchedRoutes;
    return matchedRoute as AppRouteMatch;
  }

  return null;
};

export const isValidRoutePermission = (userPermissions: UserPermissions) => {
  const matchRoute = getMatchRoute(window.location);

  if (matchRoute && matchRoute.route.path !== '*') {
    const { route } = matchRoute;
    const { permissions } = route;

    return permissions
      ? permissions.some((permission) => userPermissions[permission])
      : true;
  }

  return false;
};
