import { v4 as uuid } from 'uuid';

export const showcaseSections: PageFragmentListItem[] = [
  {
    id: 'showcase-8',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5369046/bg-captura_assets_template-nath-arcuri.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {
        background:
          "url('//static-public.klickpages.com.br/uploads/media/file/5368144/bg-captura_assets_template-nath-arcuri.png') center/cover",
      },
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '97px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '48px 0px 0px 0px',
          },
          mobile: {
            'flex-direction': 'column',
            padding: '40px 0px 0px 0px',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 56px 0px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '0px 0px 48px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h2 style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(255, 255, 255);">Descubra como...</strong></h2><h2 style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(255, 255, 255);">(conte qual é a transformação do seu produto)</strong></h2>',
                  'max-width': '100%',
                  padding: '0px 10px 32px 10px',
                  desktop: {},
                  tablet: {
                    text: '<h2 style="line-height: 1.15; text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(255, 255, 255);">Descubra como...</strong></h2><h2 style="line-height: 1.15; text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(255, 255, 255);">(conte qual é a transformação do seu produto)</strong></h2>',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 32px; color: rgb(255, 255, 255);">Descubra como...</strong></p><p style="line-height: 1; text-align: center;"><br></p><p style="line-height: 1; text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 13px; color: rgb(255, 255, 255);">(conte qual é a transformação do seu produto)</strong></p>',
                    'max-width': '90%',
                    padding: '0px 10px 24px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">Use esse espaço para atrair a atenção do possível comprador contando algum grande resultado. Ex: “Padeiros já estão lucrando 3x com o método Pão de Ouro, chegou a sua vez!” </span></p>',
                  'max-width': '80%',
                  padding: '10px 10px 40px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-size: 16px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">Use esse espaço para atrair a atenção do possível comprador contando algum grande resultado. Ex: “Padeiros já estão lucrando 3x com o método Pão de Ouro, chegou a sua vez!” </span></p>',
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="font-size: 14px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">Use esse espaço para atrair a atenção do possível comprador contando algum grande resultado. Ex: “Padeiros já estão lucrando 3x com o método Pão de Ouro, chegou a sua vez!” </span></p>',
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#615B9B',
                  background: '#FCDC4B',
                  'border-color': '#EF4E23',
                  'border-radius': '16px 16px 16px 16px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '24px',
                  'hover-background': '#FFE675',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO APRENDER!',
                  margin: '24px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {
                    'max-width': '250px',
                    'font-size': '18px',
                    margin: '16px 0px 0px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'end',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '550px',
                  href: '',
                  'max-width': '600px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '450px',
                    'max-width': '500px',
                  },
                  mobile: {
                    'max-width': '320px',
                    height: '296px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5368642/foto_nath-arcuri.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5368642/thumb_foto_nath-arcuri.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Showcase',
        templateId: 'showcase-1',
        templateName: 'Showcase',
      },
    },
  },
  {
    id: 'showcase-9',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5392635/bg-vendas_assets_template-albertoni.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 2',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '10px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(106, 255, 185); font-size: 44px; background-color: rgba(0, 0, 0, 0);">GANHE DE 1.000 A 2.500 REAIS</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 44px; background-color: rgba(0, 0, 0, 0);"> PRESTANDO SERVIÇOS</strong></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '95%',
                    text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(106, 255, 185); font-size: 40px; background-color: rgba(0, 0, 0, 0);">GANHE DE 1.000 A 2.500 REAIS</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 40px; background-color: rgba(0, 0, 0, 0);"> PRESTANDO SERVIÇOS</strong></p>',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(106, 255, 185); font-size: 20px; background-color: rgba(0, 0, 0, 0);">GANHE DE 1.000 A 2.500 REAIS</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 20px; background-color: rgba(0, 0, 0, 0);"> PRESTANDO SERVIÇOS</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 16px;">Descubra o jeito mais rápido e mais fácil de começar a faturar no mercado digital hoje assistindo o vídeo abaixo:</span></p>',
                  'max-width': '95%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 16px;">Descubra o jeito mais rápido e mais fácil de começar a faturar no mercado digital hoje assistindo o vídeo abaixo:</span></p>',
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '90%',
                    padding: '10px 10px 10px 10px',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Descubra o jeito mais rápido e mais fácil de começar a faturar no mercado digital hoje assistindo o vídeo abaixo:</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  'border-color': '#FFFFFF',
                  'border-radius': '0px 0px 0px 0px',
                  'border-style': 'solid',
                  'border-top-width': '4px',
                  'border-right-width': '4px',
                  'border-bottom-width': '4px',
                  'border-left-width': '4px',
                  height: '315px',
                  margin: '50px 0px 50px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/dtd2U0-ZOJg',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                    margin: '24px 0px 24px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
                {
                  color: '#141436',
                  background: '#6AFFB9',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO COMEÇAR AGORA!',
                  margin: '10px 0px 10px 0px',
                  'max-width': '380px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '300px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '70px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '45px',
                    height: '45px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5394660/icone-1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5394660/thumb_icone-1.png',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">7 dias de garantia</span></p>',
                  'max-width': '60%',
                  padding: '10px 0px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '20%',
                  },
                  mobile: {
                    'max-width': '40%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '80px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '50px',
                    'max-width': '50px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5394667/icone-2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5394667/thumb_icone-2.png',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Acesso imediato à área de membros</span></p>',
                  'max-width': '60%',
                  padding: '10px 0px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '37%',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Acesso imediato à área de membros</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 40px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '70px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '50px',
                    'max-width': '50px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5394669/icone-3.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5394669/thumb_icone-3.png',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Compra segura</span></p>',
                  'max-width': '60%',
                  padding: '10px 0px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '20%',
                  },
                  mobile: {
                    'max-width': '35%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '75px',
                  href: '',
                  'max-width': '75px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '50px',
                    height: '50px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5394670/icone-5.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5394670/thumb_icone-5.png',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Um ano de acesso e suporte</span></p>',
                  'max-width': '72%',
                  padding: '10px 0px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '30%',
                  },
                  mobile: {
                    'max-width': '60%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '50px 0px 70px 0px',
              },
              mobile: {
                padding: '40px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">[Descrição do principal argumento de vendas do produto. Coloque aqui o motivo pelo qual esse produto existe. Mostre a oportunidade que está disponível para quem comprar.]</span></p>',
                  'max-width': '65%',
                  padding: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">[Descrição do principal argumento de vendas do produto. Coloque aqui o motivo pelo qual esse produto existe. Mostre a oportunidade que está disponível para quem comprar.]</span></p>',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'showcase-10',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5355859/bg-vendas_assets_template-erico-rocha.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Vitrine',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '100px 0px 100px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '50px 60px 0px 60px',
              },
              mobile: {
                padding: '30px 20px 0px 20px',
                'align-items': 'start',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 43px;">Eu vou te mostrar como (nome do curso) vai te ajudar a (promessa do produto)</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">Eu vou te mostrar como (nome do curso) vai te ajudar a (promessa do produto)</strong></p>',
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '100%',
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 26px;">Eu vou te mostrar como (nome do curso) vai te ajudar a (promessa do produto)</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Você vai ter mais resultados aplicando o método que vem transformando o mercado digital!</span></p><p><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Te interessa ter um resultado assim?</span></p>',
                  'max-width': '70%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '90%',
                    text: '<p><span style="color: rgb(255, 255, 255); font-size: 17px; font-family: &quot;Nunito Sans&quot;, sans-serif; background-color: rgb(42, 41, 41);">Você vai ter mais resultados aplicando o método que vem transformando o mercado digital!</span></p><p><span style="color: rgb(255, 255, 255); font-size: 17px; font-family: &quot;Nunito Sans&quot;, sans-serif; background-color: rgb(42, 41, 41);">Te interessa ter um resultado assim?</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#FFFFFF',
                  background: '#FF5F33',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'EU QUERO!',
                  margin: '40px 0px 40px 10px',
                  'max-width': '350px',
                  mobile: {
                    'font-size': '16px',
                    'max-width': '300px',
                    padding: '13px 16px 13px 16px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    margin: '30px 0px 30px 10px',
                  },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '70px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'end',
                padding: '50px 0px 0px 0px',
              },
              mobile: {
                padding: '30px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '700px',
                  href: '',
                  'max-width': '700px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '450px',
                    'max-width': '400px',
                  },
                  mobile: {
                    height: '300px',
                    'max-width': '250px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5356004/img-vendas_assets_template-erico-rocha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5356004/thumb_img-vendas_assets_template-erico-rocha.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'showcase-11',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5455378/bg-captura_assets_template-gi-isquierdo.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 2',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '200px 0px 200px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '72px 0px 56px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '48px 0px 32px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#FFFFFF',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '1px',
                  'border-right-width': '1px',
                  'border-bottom-width': '1px',
                  'border-left-width': '1px',
                  height: '315px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/UglacxhJZXs',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 40px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 88px 0px',
              },
              mobile: {
                padding: '0px 0px 56px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1.15;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 42px;">AGORA VOCÊ PODE</strong></p><p style="line-height: 1.15;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 59, 113); font-size: 42px;">(PROMESSA DO SEU PRODUTO)</strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 42px;">.</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 42px;">AGORA VOCÊ PODE</strong></p><p style="line-height: 1.15; text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 59, 113); font-size: 42px;">(PROMESSA DO SEU PRODUTO)</strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 42px;">.</strong></p>',
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 24px;">AGORA VOCÊ PODE</strong></p><p style="line-height: 1.15; text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 59, 113); font-size: 24px;">(PROMESSA DO SEU PRODUTO)</strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 24px;">.</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;">E o melhor: eu vou te mostrar passo a passo aqui no (NOME DO CURSO)!</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;">E o melhor: eu vou te mostrar passo a passo aqui no (NOME DO CURSO)!</span></p>',
                  },
                  mobile: {
                    'max-width': '85%',
                    padding: '05px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#14133A',
                  background: '#29FF89',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': '#FF3B71',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO APRENDER COMO (PROMESSA DO PRODUTO)',
                  margin: '32px 0px 0px 10px',
                  'max-width': '550px',
                  mobile: {
                    margin: '24px 0px 0px 0px',
                    'max-width': '300px',
                    'font-size': '16px',
                    padding: '12px 16px 12px 16px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'showcase-1',
    name: 'Showcase',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '24px 0px 48px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch2\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003eDescubra como...\u003c/strong\u003e\u003c/h2\u003e\u003ch2\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003e(conte qual é a transformação do seu produto)\u003c/strong\u003e\u003c/h2\u003e',
                  'max-width': '100%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eUse esse espaço para atrair a atenção do possível comprador contando algum grande resultado. Ex: “Padeiros já estão lucrando 3x com o método Pão de Ouro, chegou a sua vez!” \u003c/span\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#424242',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Nunito Sans, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Quero aprender!',
                  margin: '24px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '484px',
                  href: '',
                  'max-width': '556px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5224556/group_76.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5224556/thumb_group_76.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 1' },
      background: '#F5F5F5',
    },
  },
  {
    id: 'showcase-2',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5288018/bg-hero.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '110px 0px 90px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '50px 50px 50px 50px',
          },
          mobile: {
            'flex-direction': 'column',
            padding: '50px 30px 50px 30px',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 40px 0px 0px',
              'justify-content': 'start',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 00px 0px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '95%',
                  href: '',
                  'max-width': '80%',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '90%',
                    'max-width': '70%',
                    'object-fit': 'contain',
                  },
                  mobile: { height: '100%', 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285952/ilustracao_hero_pg-venda.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285952/thumb_ilustracao_hero_pg-venda.png',
                },
              ],
            },
            {
              padding: '0px 50px 0px 10px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '10px 20px 0px 20px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '20px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch2 style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 52px;"\u003eDescubra como... (conte qual é a transformação do seu produto)\u003c/strong\u003e\u003c/h2\u003e',
                  'max-width': '100%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003ch2 style="line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 36px;"\u003eDescubra como... (conte qual é a transformação do seu produto)\u003c/strong\u003e\u003c/h2\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-size: 16px; font-family: Raleway, sans-serif; color: rgb(28, 28, 28);"\u003eUse esse espaço para atrair a atenção do possível comprador contando algum grande resultado. Ex: “Padeiros já estão lucrando 3x com o método Pão de Ouro, chegou a sua vez!” \u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#000000',
                  background: '#79E0BB',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Quero aprender!',
                  margin: '24px 0px 0px 0px',
                  'max-width': '280px',
                  mobile: { 'max-width': '100%' },
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 1' },
    },
  },
  {
    id: 'showcase-3',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5278636/bg-captura_assets_template-empreend-ladeira.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Captura' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  href: '',
                  'max-width': '600px',
                  margin: '70px 0px 70px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '300px',
                    'max-width': '500px',
                    'object-fit': 'contain',
                    margin: '20px 0px 15px 0px',
                  },
                  mobile: {
                    height: '195px',
                    'object-fit': 'contain',
                    margin: '20px 0px 20px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5278580/bg-captura_assets_template-empreend-ladeira.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5278580/thumb_bg-captura_assets_template-empreend-ladeira.png',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 50px 0px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 74, 18); font-size: 45px;"\u003eBaixe grátis!\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '0px 10px 10px 60px',
                  desktop: {},
                  tablet: {
                    text: '\u003ch1 style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 74, 18); font-size: 40px;"\u003eBaixe grátis!\u003c/strong\u003e\u003c/h1\u003e',
                    padding: '0px 20px 10px 20px',
                  },
                  mobile: {
                    text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 74, 18); font-size: 30px;"\u003eBaixe grátis!\u003c/strong\u003e\u003c/p\u003e',
                    padding: '0px 0px 10px 0px',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;"\u003eDescubra como (transformação da sua isca) nesse (guia, planilha, evento) gratuito.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 60px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 22px;"\u003eDescubra como (transformação da sua isca) nesse (guia, planilha, evento) gratuito.\u003c/strong\u003e\u003c/p\u003e',
                    padding: '10px 0px 10px 0px',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eDescubra como (transformação da sua isca) nesse (guia, planilha, evento) gratuito.\u003c/strong\u003e\u003c/p\u003e',
                    padding: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eO (Nome da isca digital) é totalmente gratuito e pensado para você que (dor ou desafio que seu cliente está passando). Baixe (ou inscreva-se) agora antes que essa página saia do ar.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 60px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eO (Nome da isca digital) é totalmente gratuito e pensado para você que (dor ou desafio que seu cliente está passando). Baixe (ou inscreva-se) agora antes que essa página saia do ar.\u003c/span\u003e\u003c/p\u003e',
                    padding: '10px 25px 10px 25px',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eO (Nome da isca digital) é totalmente gratuito e pensado para você que (dor ou desafio que seu cliente está passando). Baixe (ou inscreva-se) agora antes que essa página saia do ar.\u003c/span\u003e\u003c/p\u003e',
                    padding: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#FF4A12',
                  'border-color': '#08014E',
                  'border-radius': '10px 2px 10px 2px',
                  'border-style': 'none',
                  'border-top-width': '4px',
                  'border-right-width': '4px',
                  'border-bottom-width': '4px',
                  'border-left-width': '4px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO APROVEITAR AGORA',
                  margin: '40px 0px 0px 60px',
                  'max-width': '350px',
                  mobile: {
                    margin: '25px 20px 50px 20px',
                    'max-width': '350px',
                    padding: '12px 16px 12px 14px',
                  },
                  padding: '12px 16px 12px 16px',
                  tablet: {
                    padding: '12px 0px 12px 0px',
                    margin: '40px 0px 0px 0px',
                  },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'showcase-4',
    name: 'Showcase',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '70px',
                  href: '',
                  'max-width': '200px',
                  margin: '0px 0px 20px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { margin: '0px 0px 10px 0px' },
                  mobile: { 'max-width': '150px', height: '50px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-size: 30px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eEntenda por que tanta gente comum está (promessa do seu curso) com essa oportunidade.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 10px 8px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-size: 26px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eEntenda por que tanta gente comum está (promessa do seu curso) com essa oportunidade.\u003c/strong\u003e\u003c/p\u003e',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 19px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eEntenda por que tanta gente comum está (promessa do seu curso) com essa oportunidade.\u003c/strong\u003e\u003c/p\u003e',
                    padding: '10px 10px 14px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-size: 14px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eAssista o vídeo abaixo para entender como funciona o método (nome do seu método).\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '90%' },
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="font-size: 14px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eAssista o vídeo abaixo para entender como funciona o método (nome do seu método).\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '281px',
                  margin: '30px 0px 0px 0px',
                  'max-width': '500px',
                  src: 'https://www.youtube.com/embed/rvDAIith64A',
                  'thumb-url': '',
                  desktop: {},
                  tablet: { height: '225px', 'max-width': '400px' },
                  mobile: { 'max-width': '300px', height: '169px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                },
              ],
              padding: '40px 0px 50px 0px',
              'align-items': 'center',
              'justify-content': 'center',
              'flex-direction': 'column',
              tablet: { padding: '30px 0px 40px 0px' },
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 94px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'row',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 10px 0px',
              },
              mobile: {
                padding: '0px 0px 0px 70px',
                'align-items': 'center',
                'justify-content': 'start',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '80px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '50px', 'max-width': '60px' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5286398/acesso.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5286398/thumb_acesso.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eAcesso imediato\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eAcesso imediato\u003c/span\u003e\u003c/p\u003e',
                    'max-width': '30%',
                    padding: '10px 0px 10px 20px',
                  },
                  mobile: { 'max-width': '50%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '0px 80px 30px 80px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                'flex-direction': 'row',
                padding: '10px 0px 10px 0px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'start',
                padding: '10px 0px 10px 60px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '80px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '50px',
                    'max-width': '70px',
                    margin: '0px 10px 0px 0px',
                  },
                  mobile: { margin: '0px 0px 0px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5286400/garantia.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5286400/thumb_garantia.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eGarantia de 15 dias\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 10px 10px 7px',
                  desktop: {},
                  tablet: {
                    'max-width': '30%',
                    text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eGarantia de 15 dias\u003c/span\u003e\u003c/p\u003e',
                    padding: '10px 10px 10px 15px',
                  },
                  mobile: { 'max-width': '60%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '0px 94px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'row',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 0px 10px 50px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'start',
                padding: '0px 0px 10px 40px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '80px',
                  href: '',
                  'max-width': '80px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '50px', 'max-width': '60px' },
                  mobile: { margin: '0px 0px 0px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5286402/suporte.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5286402/thumb_suporte.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eSuporte 7 dias por semana\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '70%',
                  padding: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eSuporte 7 dias por semana\u003c/span\u003e\u003c/p\u003e',
                    'max-width': '40%',
                    padding: '10px 0px 10px 23px',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '10px 0px 10px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Vendas – Vídeo' },
      background: '#272AAD',
    },
  },
  {
    id: 'showcase-5',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5315986/bg-hero.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 40px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '50px 30px 40px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 36px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eGuia completo: aprenda como implementar em apenas X dias (promessa do seu produto).\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 28px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eGuia completo: aprenda como implementar em apenas X dias (promessa do seu produto).\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '100%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 16px;"\u003eEnquanto a maioria das pessoas levam X dias para (promessa do produto), aplicando a nossa metodologia exclusiva, você pode fazer isso em (x tempo). Dê o play no vídeo para entender melhor. \u003c/span\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 30px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 15px;"\u003eEnquanto a maioria das pessoas levam X dias para (promessa do produto), aplicando a nossa metodologia exclusiva, você pode fazer isso em (x tempo). Dê o play no vídeo para entender melhor. \u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '350px',
                  margin: '20px 10px 20px 10px',
                  'max-width': '600px',
                  src: 'https://www.youtube.com/embed/EueTOrJlAbw',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '400px', height: '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                },
                {
                  color: '#FFFFFF',
                  background: '#E5154A',
                  'border-color': '#EF4E23',
                  'border-radius': '27px 27px 27px 27px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'SIM! QUERO COMEÇAR',
                  margin: '30px 0px 20px 0px',
                  'max-width': '300px',
                  mobile: {},
                  padding: '14px 16px 14px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '40px',
                  href: '',
                  'max-width': '40px',
                  margin: '20px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5311739/setas.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5311739/thumb_setas.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 2' },
    },
  },
  {
    id: 'showcase-6',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5312128/bg-vendas_assets_template-orange-chic.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Vitrine' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 20px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '30px 0px 20px 0px',
              },
              mobile: { padding: '20px 0px 20px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5280678/seu-logo-aqui.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5280678/thumb_seu-logo-aqui.png',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 150px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '30px 0px 20px 0px',
              },
              mobile: { padding: '20px 0px 20px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '315px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/iYak8Wb3_cM',
                  'thumb-url': '',
                  desktop: {},
                  tablet: { 'max-width': '560px', height: '315px' },
                  mobile: { 'max-width': '300px', height: '169px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                },
              ],
            },
            {
              padding: '90px 0px 150px 60px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '30px 0px 30px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '10px 0px 30px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 40px;"\u003eO curso de [TEMA DO SEU CURSO OU PRODUTO] pensado para você!\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 36px;"\u003eO curso de [TEMA DO SEU CURSO OU PRODUTO] pensado para você!\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 22px;"\u003eO curso de [TEMA DO SEU CURSO OU PRODUTO] pensado para você!\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#222222',
                  background: '#00FFFA',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 100px 100px 100px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '19px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO CONHECER',
                  margin: '40px 0px 20px 10px',
                  'max-width': '460px',
                  mobile: {
                    margin: '30px 0px 30px 10px',
                    'max-width': '80%',
                    'font-size': '18px',
                  },
                  padding: '15px 15px 15px 15px',
                  tablet: { margin: '60px 0px 40px 10px' },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'showcase-7',
    name: 'Showcase',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5334634/bg-vendas_assets_template-navy-blue.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Vitrine' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#D3DBFC',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'solid',
                  'border-top-width': '16px',
                  'border-right-width': '16px',
                  'border-bottom-width': '16px',
                  'border-left-width': '16px',
                  height: '315px',
                  margin: '90px 0px 120px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/nM6QqfrR5zg',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {
                    margin: '50px 0px 60px 0px',
                    'max-width': '500px',
                    height: '281px',
                  },
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                    'border-top-width': '10px',
                    'border-right-width': '10px',
                    'border-bottom-width': '10px',
                    'border-left-width': '10px',
                    margin: '30px 0px 40px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                },
              ],
            },
            {
              padding: '0px 0px 40px 40px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 60px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 40px;"\u003eO curso de [TEMA DO SEU CURSO OU PRODUTO] pensado para você!\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 30px;"\u003eO curso de [TEMA DO SEU CURSO OU PRODUTO] pensado para você!\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '70%',
                  },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#FF5959',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'COMEÇAR AGORA',
                  margin: '30px 0px 0px 10px',
                  'max-width': '350px',
                  mobile: {
                    'max-width': '70%',
                    'font-size': '15px',
                    padding: '15px 16px 15px 16px',
                  },
                  padding: '18px 16px 18px 16px',
                  tablet: {
                    margin: '30px 0px 0px 0px',
                    'max-width': '55%',
                  },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
