import React, { HTMLAttributes, memo } from 'react';
import { Container } from './styles';
import { MediaList } from './components/MediaList';
import { SearchMedia } from './components/SearchMedia';

export type MediaProps = HTMLAttributes<HTMLDivElement> & {
  isSelectable?: boolean;
  selectedMediaId?: string;
  displayMode?: MediaCenterDisplayMode;
  onSelectedImageChange?: (media: Media) => void;
};

const MediaComponent: React.FC<MediaProps> = ({
  isSelectable,
  selectedMediaId,
  displayMode = 'submenu',
  onSelectedImageChange,
  ...attrs
}) => (
  <Container {...attrs}>
    <SearchMedia />
    <MediaList
      isSelectable={isSelectable}
      selectedMediaId={selectedMediaId}
      displayMode={displayMode}
      onSelectedImageChange={onSelectedImageChange}
    />
  </Container>
);

export const Media = memo(MediaComponent);
