import { Add } from './add';
import { Adjustments } from './adjustments';
import { AlignLeft } from './align-left';
import { Bookmark } from './bookmark';
import { Button } from './button';
import { Checkout } from './checkout';
import { ChevronLeft } from './chevron-left';
import { Column } from './column';
import { ObjectGroup } from './object-group';
import { Paragraph } from './paragraph';
import { Image } from './image';
import { HTML } from './html';
import { Media } from './media';
import { Pencil } from './pencil';
import { Popup } from './popup';
import { Row } from './row';
import { Section } from './section';
import { SectionsElement } from './sections-element';
import { SectionsTemplate } from './sections-template';
import { Sections } from './sections';
import { Settings } from './settings';
import { Text } from './text';
import { Video } from './video';
import { Accordion } from './accordion';
import { Timer } from './timer';
import { Icon } from './icon';
import { Whatsapp } from './whatsapp';
import { Telegram } from './telegram';
import { Facebook } from './facebook';
import { VisibilityOn } from './visibility-on';
import { VisibilityOff } from './visibility-off';

export const icons = {
  accordion: Accordion,
  add: Add,
  adjustments: Adjustments,
  'align-left': AlignLeft,
  bookmark: Bookmark,
  button: Button,
  checkout: Checkout,
  'chevron-left': ChevronLeft,
  column: Column,
  facebook: Facebook,
  'object-group': ObjectGroup,
  paragraph: Paragraph,
  image: Image,
  html: HTML,
  icon: Icon,
  media: Media,
  pencil: Pencil,
  popup: Popup,
  'visibility-on': VisibilityOn,
  'visibility-off': VisibilityOff,
  row: Row,
  section: Section,
  'sections-element': SectionsElement,
  'sections-template': SectionsTemplate,
  sections: Sections,
  settings: Settings,
  text: Text,
  timer: Timer,
  telegram: Telegram,
  video: Video,
  whatsapp: Whatsapp,
};

export type Icons = keyof typeof icons;
