import React, { HTMLAttributes, useCallback } from 'react';
import { MenuItem } from '@components/MenuItem';

export type ModelCategoryListProps = HTMLAttributes<HTMLDivElement> & {
  categories: Record<string, string>[];
  selectedCategoryId?: string;
  onSelectedCategory?: (id: string) => void;
};

export const ModelCategoryList: React.FC<ModelCategoryListProps> = ({
  categories,
  selectedCategoryId,
  onSelectedCategory,
}) => {
  const handleClick = useCallback(
    (id: string) => {
      onSelectedCategory?.(id);
    },
    [onSelectedCategory]
  );

  return (
    <>
      {categories.map((item: any) => (
        <MenuItem
          key={item.id}
          id={item.id}
          label={item.label}
          onClick={() => handleClick(item.id)}
          active={item.id === selectedCategoryId}
        />
      ))}
    </>
  );
};
