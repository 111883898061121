import styled from 'styled-components';

export const Title = styled.p`
  font-size: 32px;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 20px;
  margin: 0;
`;

export const FooterButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;
