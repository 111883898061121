import { v4 as uuid } from 'uuid';

export const getFormFragment = () => {
  const uid = `ls-${uuid()}`;

  return {
    height: '100%',
    margin: '0px 0px 0px 0px',
    'max-width': '360px',
    padding: '16px 16px 16px 16px',
    checkbox: `{"uid":"${uid}-checkbox","component":"ls-form-checkbox","accent-color":"#5981e3","checked":false,"color":"#191C1F","disabled":false,"font-size":"14px","font-family":"Raleway, sans-serif","margin":"8px 0px 8px 0px","max-width":"100%","padding":"0px 0px 0px 0px","readonly":false,"required":false,"checkbox":"[]","mobile":{},"tablet":{},"desktop":{},"metadata":{}}`,
    desktop: {},
    tablet: {},
    mobile: {},
    metadata: {},
    uid,
    component: 'ls-form',
    fields: `{"uid":"${uid}-inputs","component":"ls-form-inputs","background":"#FFFFFF","border-color":"#C9CED4","border-radius":"4px 4px 4px 4px","border-style":"solid","border-top-width":" 1px","border-right-width":"1px","border-bottom-width":"1px","border-left-width":"1px","color":"#191C1F","font-family":"Raleway, sans-serif","font-size":"16px","has-label":true,"label-color":"#191C1F","input-type":"text","label-font-size":"16px","label-is-bold":false,"label-is-italic":false,"placeholder-color":"#707780","has-icon":false,"icon-fill":"#707780","outline":"none","label-text-decoration":"none","height":"100%","max-width":"100%","fields":"[{\\"id\\":\\"email\\",\\"name\\":\\"email\\",\\"label\\":\\"E-mail\\",\\"value\\":\\"\\",\\"required\\":false,\\"defaultValue\\":\\"\\",\\"type\\":\\"text\\",\\"placeholder\\":\\"Seu e-mail aqui\\"}]","mobile":{},"tablet":{},"desktop":{},"metadata":{}}`,
    button: `{"uid":"${uid}-button","component":"ls-button","action":"link","color":"#FFFFFF","background":"#191C1F","border-color":"#EF4E23","border-radius":"24px 24px 24px 24px","border-style":"none","border-top-width":"2px","border-right-width":"2px","border-bottom-width":"2px","border-left-width":"2px","button-class":"","end-date":"2024-06-12T15:04:55","timezone":"-03:00_utcMinus0300MainCities","time-on-page":"5","time-on-page-unit":"seconds","delay-type":"disabled","font-family":"Montserrat, sans-serif","font-size":"16px","hover-background":"none","hover-color":"none","has-icon":false,"has-text":true,"href":"","icon-position":"right","icon":"fas fa-arrow-right","is-bold":"true","is-italic":false,"is-underline":false,"is-strike":false,"label":"Enviar","margin":"8px 0px 0px 0px","max-width":"100%","padding":"8px 32px 8px 32px","popup-id":"","target":"_blank","mobile":{},"tablet":{},"desktop":{},"metadata":{}}`,
    'simple-form': false,
  };
};
