import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Section: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M0 3.93333C0 3.41787 0.447715 3 1 3V8C0.447715 8 0 7.58213 0 7.06667V3.93333Z"
      fill={color}
    />
    <path
      d="M1 3.93333V3L13 3C13.5523 3 14 3.41787 14 3.93333L1 3.93333Z"
      fill={color}
    />
    <path d="M1 8L1 7.06667L13 7.06667V8L1 8Z" fill={color} />
    <path
      d="M13 3.93333L14 3.93333V7.06667C14 7.58213 13.5523 8 13 8V3.93333Z"
      fill={color}
    />
    <rect y="10" width="14" height="1" fill={color} />
    <rect width="14" height="1" fill={color} />
  </StyledSVG>
);
