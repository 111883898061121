import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';

import React, { HTMLAttributes, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateConfig } from '@store/slices/pageConfig';
import Toast from '@services/toast';
import Files from '@services/files';
import { Container, Content, Label, Description } from './styles';

export const Indexation: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uuid = useSelector((state: State) => state.pageInfo.uuid);

  const preventIndexation = useSelector(
    (state: State) => state.pageConfig.preventIndexation
  );

  const handleChange = useCallback(
    async (e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      const config = {
        preventIndexation: target.checked,
      };

      dispatch(updateConfig(config));

      try {
        await Files.saveConfigFile(uuid, config);
        Toast.success('toastMessages.general.success.saved');
      } catch {
        Toast.error('toastMessages.general.error.saved');
      }
    },
    [uuid, dispatch]
  );

  return (
    <Container {...attrs}>
      <Content className="hot-form hot-form--custom hot-form--switch">
        <input
          type="checkbox"
          className="hot-form__input"
          id="indexationSwitch"
          onChange={handleChange}
          checked={preventIndexation}
        />
        <Label className="hot-form__label" htmlFor="indexationSwitch">
          {t('seo.indexation.title')}
        </Label>
      </Content>
      <Description>{t('seo.indexation.description')}</Description>
    </Container>
  );
};
