import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 16px;
`;

export const SwitchContainer = styled.div`
  display: flex;

  :hover {
    hot-tooltip {
      display: inline-block;
    }
  }

  hot-tooltip {
    margin-left: auto;
    display: none;
    margin: auto 0 auto auto;
  }
`;

export const Switch = styled.div`
  width: 212px;
`;

export const ContentAction = styled.div`
  margin-top: 16px;
`;
