import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCasesExtraReducers } from '@store/thunks/useCases';

export const useCasesInitialState: UseCasesState = {
  properties: undefined,
  loading: false,
  isPublishing: false,
  loadingAI: false,
  footerRightButton: {
    disabled: false,
    errorMessage: '',
  },
};

const useCases = createSlice({
  name: 'useCases',
  initialState: useCasesInitialState,
  reducers: {
    setCurrentStep(state, { payload }: PayloadAction<number>) {
      state.currentStep = payload;
    },
    setProperties(state, { payload }: PayloadAction<any>) {
      state.properties = payload;
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setFinishedInFirstAccess(state, { payload }: PayloadAction<boolean>) {
      state.finishedInFirstAccess = payload;
    },
    setIsPublishing(state, { payload }: PayloadAction<boolean>) {
      state.isPublishing = payload;
    },
    setFooterRightButtonConfig(
      state,
      { payload }: PayloadAction<Partial<FooterRightButtonConfig>>
    ) {
      state.footerRightButton = { ...state.footerRightButton, ...payload };
    },
    updateProperties(state, { payload }: PayloadAction<any>) {
      state.properties = { ...state.properties, ...payload };
    },
  },
  // eslint-disable-next-line react-hooks/rules-of-hooks
  extraReducers: (builder) => useCasesExtraReducers(builder),
});

export const {
  setCurrentStep,
  setLoading,
  setIsPublishing,
  setFinishedInFirstAccess,
  setProperties,
  setFooterRightButtonConfig,
  updateProperties,
} = useCases.actions;

export default useCases.reducer;
