import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  HTMLAttributes,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { templateToHtml } from '@hotmart-org-ca/saas-pages-engine-utils';
import { useDetectBrowser } from '@hooks/useDetectBrowser';
import { CosmosLoader } from '@components/CosmosLoader';
import { Icon } from '@components/Icon';
import {
  DraggableContainer,
  FragmentWrapper,
  Fragment,
  FragmentContent,
  FragmentToolbar,
  Label,
  LabelIcon,
  StyledLsEditorDraggable,
  Description,
  FragmentList,
} from './styles';
import { getFormFragment } from './form-fragment';

export type PageFragmentListProps = HTMLAttributes<HTMLDivElement> & {
  groupName: 'addableSections' | 'addablePopups';
  items: PageFragmentListItem[];
  bookmarkTooltip?: string;
  description?: string;
  hideActions?: boolean;
  hideBorder?: boolean;
  hideToolbar?: boolean;
  loading?: boolean;
  selectionMode?: boolean;
  selectedFragment?: string;
  onBookmarkClick?: (id: string) => void;
  onNameChange?: (id: string, label: string) => void;
  onFragmentChange?: (item: PageFragmentListItem) => void;
};

export const PageFragmentList: React.FC<PageFragmentListProps> = ({
  items: itemsProp,
  groupName,
  bookmarkTooltip = '',
  description = '',
  hideActions = false,
  hideBorder = false,
  hideToolbar = false,
  loading = false,
  selectionMode = false,
  selectedFragment = '',
  onBookmarkClick,
  onNameChange,
  onFragmentChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const { isFirefox } = useDetectBrowser();

  const fragment = useRef<Record<string, HTMLElement>>({});
  const fragmentContent = useRef<Record<string, HTMLElement>>({});
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [styles, setStyles] = useState<Record<string, string>>({});
  const [currentItem, setCurrentItem] = useState('');
  const [items, setItems] = useState<
    (PageFragmentListItem & { html: string })[]
  >([]);

  const isPopup = useMemo(() => groupName === 'addablePopups', [groupName]);

  const fragmentsStyles = useMemo(
    () => Object.values(styles).join(''),
    [styles]
  );

  const saveItemName = useCallback(
    (id: string, label: string) => {
      onNameChange?.(id, label);
      setCurrentItem('');
    },
    [onNameChange]
  );

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>, id: string) => {
      if (event.key === 'Escape') {
        setCurrentItem('');
      } else if (event.key === 'Enter') {
        const target = event.target as HTMLInputElement;
        const label = target.value;

        if (label) {
          saveItemName(id, label);
        }
      }
    },
    [saveItemName]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        inputRef.current.value
      ) {
        saveItemName(currentItem, inputRef.current.value);
      }
    },
    [currentItem, saveItemName]
  );

  const handleInputDoubleClick = useCallback(
    (id: string) => {
      if (!hideActions) {
        setCurrentItem(id);
      }
    },
    [hideActions]
  );

  const handleFragmentSelect = useCallback(
    (element: PageFragmentListItem) => {
      if (!selectionMode) {
        return;
      }

      onFragmentChange?.(element);
    },
    [onFragmentChange, selectionMode]
  );

  const updateListItemsHeight = useCallback(() => {
    setTimeout(() => {
      Object.keys(fragmentContent.current).forEach((uid) => {
        if (!selectionMode) {
          const height = fragmentContent.current[uid].clientHeight / 5 + 2;
          fragment.current[uid].style.height = `${height}px`;
        }
        fragmentContent.current[uid].style.maxHeight = '1px';
      });
    }, 300);
  }, [selectionMode]);

  const replaceChildrenEmptyFormToForm = useCallback((template: Template) => {
    if (!template.children?.length) {
      return [];
    }

    return template.children.map((child) => {
      const updatedChild: Template = { ...child };

      if (child.children?.length) {
        updatedChild.children = replaceChildrenEmptyFormToForm(updatedChild);
      }

      if (child.component === 'ls-empty-form') {
        return { ...updatedChild, ...getFormFragment() };
      }

      return updatedChild;
    });
  }, []);

  useEffect(() => {
    if (isFirefox) {
      updateListItemsHeight();
    }
  }, [isFirefox, updateListItemsHeight]);

  useEffect(() => {
    setItems(
      itemsProp.map((item) => {
        const template = {
          ...item.template,
          component: 'ls-section',
          children: replaceChildrenEmptyFormToForm(item.template),
        };
        const { html, styles: templateStyle } = templateToHtml({ template });

        setStyles((state) => ({ ...state, ...templateStyle }));

        return {
          ...item,
          html,
        };
      })
    );
  }, [itemsProp, replaceChildrenEmptyFormToForm]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div {...attrs} role="list">
      {description && <Description>{t(description)}</Description>}

      {fragmentsStyles && (
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: fragmentsStyles }} />
        </>
      )}
      {loading && <CosmosLoader />}

      {!loading && Boolean(items.length) && (
        <FragmentList>
          {items.map((element) => (
            <FragmentWrapper
              key={element.id}
              isSelectable={selectionMode}
              onClick={() => handleFragmentSelect(element)}
              role="listitem"
            >
              <Fragment
                isSelected={selectedFragment === element.id}
                isSelectable={selectionMode}
                hideBorder={hideBorder}
                isPopup={isPopup}
                isFirefox={isFirefox}
                ref={(el: HTMLDivElement) => {
                  fragment.current[element.template.uid] = el;
                }}
              >
                <FragmentContent
                  isFirefox={isFirefox}
                  isPopup={isPopup}
                  isSelectable={selectionMode}
                  ref={(el: HTMLDivElement) => {
                    fragmentContent.current[element.template.uid] = el;
                  }}
                  dangerouslySetInnerHTML={{
                    __html: element.html,
                  }}
                />
                {!selectionMode && (
                  <StyledLsEditorDraggable
                    uid={`ls-${element.id}`}
                    groupName={groupName}
                    template={element.template}
                  >
                    <DraggableContainer />
                  </StyledLsEditorDraggable>
                )}
              </Fragment>

              {!hideToolbar && (
                <FragmentToolbar
                  onDoubleClick={() => handleInputDoubleClick(element.id)}
                >
                  {currentItem === element.id ? (
                    <input
                      ref={inputRef}
                      className="hot-form__input hot-form__input--sm"
                      defaultValue={element.name}
                      maxLength={50}
                      onKeyDown={(event) => handleKeyDown(event, element.id)}
                    />
                  ) : (
                    <Label title={element.name}>{element.name}</Label>
                  )}

                  {!hideActions && (
                    <LabelIcon>
                      <hot-tooltip content={t(bookmarkTooltip)} position="top">
                        <Icon
                          name="bookmark"
                          size={16}
                          color="#191C1F"
                          onClick={() => onBookmarkClick?.(element.id)}
                        />
                      </hot-tooltip>
                    </LabelIcon>
                  )}
                </FragmentToolbar>
              )}
            </FragmentWrapper>
          ))}
        </FragmentList>
      )}
    </div>
  );
};
