import { useCallback, useMemo } from 'react';
import { useMenu } from '@hooks/index';
import { Submenu } from '@components/Submenu';
import { PageFragmentList } from '@components/PageFragmentList';
import { getAddableSectionsByCategory } from './addable-sections';

export const SectionsListSubmenu: React.FC = ({ ...attrs }) => {
  const { activeSectionTemplate, setActiveSectionTemplate } = useMenu();

  const items = useMemo(
    () =>
      activeSectionTemplate
        ? getAddableSectionsByCategory(
            activeSectionTemplate as AddableSectionCategory
          )
        : [],

    [activeSectionTemplate]
  );

  const handleGoBack = useCallback(() => {
    setActiveSectionTemplate('');
  }, [setActiveSectionTemplate]);

  return (
    <Submenu
      title={`sideMenu.sectionsTemplate.categories.${activeSectionTemplate}`}
      onGoBack={handleGoBack}
      {...attrs}
    >
      <PageFragmentList hideToolbar groupName="addableSections" items={items} />
    </Submenu>
  );
};
