import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedPopups from '@services/savedPopups';

export const deleteUserPopup = createAsyncThunk<
  void,
  string,
  {
    state: LocalState;
    SerializedError: {
      name: string;
      stack: string;
      code: string;
    };
  }
>('pageFragments/deleteUserPopup', async (id) => {
  try {
    await SavedPopups.delete(id);
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const deleteUserPopupBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(deleteUserPopup.pending, (state: PageFragmentsState) => {
    state.popupLoading = true;
  });
  builder.addCase(
    deleteUserPopup.fulfilled,
    (state: PageFragmentsState, action) => {
      const id = action.meta.arg;
      const userPopups = state.userPopups.filter((item) => item.id !== id);

      state.userPopups = userPopups;
      state.popupLoading = false;
    }
  );
  builder.addCase(deleteUserPopup.rejected, (state: PageFragmentsState) => {
    state.popupLoading = false;
  });
};
