import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes, useCallback, useState } from 'react';
import { useMediaCenter } from '../../../../../../hooks/useMediaCenter';
import Toast from '../../../../../../services/toast';
import { SelectAll } from './components/SelectAll';
import {
  Actions,
  ActionsContainer,
  AddFolderContainer,
  ArrowIcon,
  Button,
  ToolbarContainer,
} from './styles';

export const ToolBar: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const {
    search,
    loading,
    breadcrumbs,
    breadcrumbsRedo,
    navigateBackFolder,
    navigateForwardFolder,
    createFolder,
  } = useMediaCenter();

  const [isLoading, setIsLoading] = useState(false);

  const handleBackFolder = useCallback(async () => {
    if (breadcrumbs.length) {
      navigateBackFolder({ to: 'FOLDER' });
    }
  }, [breadcrumbs.length, navigateBackFolder]);

  const handleForwardFolder = useCallback(async () => {
    if (breadcrumbsRedo.length) {
      navigateForwardFolder();
    }
  }, [breadcrumbsRedo.length, navigateForwardFolder]);

  const handleAddFolder = useCallback(async () => {
    setIsLoading(true);
    try {
      await createFolder();
      Toast.success('toastMessages.media_folder.success.saved');
    } catch (error) {
      Toast.error('toastMessages.media_folder.error.saved');
    } finally {
      setIsLoading(false);
    }
  }, [createFolder]);

  return (
    <ToolbarContainer {...attrs}>
      <ActionsContainer>
        <Actions>
          <ArrowIcon
            icon={['fal', 'angle-left']}
            disabled={loading || !breadcrumbs.length}
            onClick={handleBackFolder}
          />
          <ArrowIcon
            icon={['fal', 'angle-right']}
            disabled={loading || !breadcrumbsRedo.length}
            onClick={handleForwardFolder}
          />
        </Actions>

        <Actions>
          <SelectAll />
        </Actions>
      </ActionsContainer>

      <AddFolderContainer>
        <Button
          disabled={isLoading || Boolean(search) || loading}
          onClick={handleAddFolder}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={['fad', 'spinner-third']} spin />
          ) : (
            <FontAwesomeIcon icon={['fal', 'folder-plus']} />
          )}
        </Button>
      </AddFolderContainer>
    </ToolbarContainer>
  );
};
