import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/custom.css';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-checkbox.css';

import React, { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMedia } from '@store/slices/mediaCenter';
import { Container, Button, Icon } from './styles';

export type MediaOptionsProps = HTMLAttributes<HTMLDivElement> & {
  onRemoveCurrentMedia: (id: string) => void;
  onChangeCurrentMedia: (id: string) => void;
  media: UploadingMedia<Image> | UploadingMedia<Folder>;
};

const MediaOptionsComponent: React.FC<MediaOptionsProps> = ({
  onRemoveCurrentMedia,
  onChangeCurrentMedia,
  media,
  ...attrs
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const selectedMedia = useSelector(
    (state: State) => state.mediaCenter.selectedMedia
  );

  const mediaKey = useMemo(
    () => `${media.id}-${media.type}`,
    [media.id, media.type]
  );

  const isChecked = useMemo(
    () => Boolean(selectedMedia[mediaKey]),
    [mediaKey, selectedMedia]
  );

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(setSelectedMedia({ ...selectedMedia, [mediaKey]: media }));
      } else {
        const updatedSelectedMedia = { ...selectedMedia };
        delete updatedSelectedMedia[mediaKey];
        dispatch(setSelectedMedia(updatedSelectedMedia));
      }
    },
    [dispatch, media, mediaKey, selectedMedia]
  );

  return (
    <Container {...attrs}>
      <div className="hot-form hot-form--custom hot-form--checkbox">
        <input
          type="checkbox"
          className="hot-form__input valid"
          id={mediaKey}
          checked={isChecked}
          onChange={handleCheck}
        />
        <label
          aria-valuetext="media-checkbox"
          className="hot-form__label"
          htmlFor={mediaKey}
        />
      </div>

      <hot-tooltip content={t('mediaCenter.mediaOptions.rename')}>
        <Button
          onClick={() => onChangeCurrentMedia(media.id)}
          className="hot-button"
        >
          <Icon className="fal fa-pen" />
        </Button>
      </hot-tooltip>

      <hot-tooltip content={t('mediaCenter.mediaOptions.delete')}>
        <Button
          onClick={() => onRemoveCurrentMedia(media.id)}
          className="hot-button"
        >
          <Icon className="fal fa-trash" />
        </Button>
      </hot-tooltip>
    </Container>
  );
};

export const MediaOptions = memo(MediaOptionsComponent);
