import { Submenu } from '@components/Submenu';
import { PageFragmentList } from '@components/PageFragmentList';
import { addablePopups } from './addable-popups';

export const PopupsListSubmenu: React.FC = ({ ...attrs }) => (
  <Submenu title="sideMenu.popupsTemplate.templates" {...attrs}>
    <PageFragmentList
      hideToolbar
      groupName="addablePopups"
      items={addablePopups()}
    />
  </Submenu>
);
