// import { SerializedError } from '@reduxjs/toolkit';
// import User from '@services/user';
import i18n from 'i18next';

/* eslint-disable no-param-reassign */
const getSettings = ({ name, email, apiKey, createdAt, plan }: UserProfile) =>
  // {
  //   firstCreatedPageAt,
  //   lastCreatedPageAt,
  //   firstPublishedPageAt,
  //   lastPublishedPageAt,
  // }: UserPagesInfo
  ({
    username: email,
    name,
    email,
    external_id: apiKey,
    created_at: createdAt?.toString(),
    plan_name: plan.name,
    // firstCreatedPageAt,
    // lastCreatedPageAt,
    // firstPublishedPageAt,
    // lastPublishedPageAt,
  });

const createProductFruitScript = () =>
  ((w, d, u) => {
    w.$productFruits = w.$productFruits || [];
    w.productFruits = w.productFruits || {};
    w.productFruits.scrV = '2';

    const a = d.getElementsByTagName('head')[0];
    const r = d.createElement('script');

    r.async = true;
    r.src = u;

    a.appendChild(r);
  })(window, document, 'https://app.productfruits.com/static/script.js');

// const fetchUserPageInfo = () => {
//   try {
//     return User.getUserPagesInfo();
//   } catch (error) {
//     const serializedError = error as SerializedError;
//     throw serializedError;
//   }
// };

export const initializeProductFruits = async (userProfile: UserProfile) => {
  createProductFruitScript();

  const { language } = i18n;
  const normalizedLanguage = language === 'pt-BR' ? 'br' : language;
  // const userPageInfo = await fetchUserPageInfo();

  window.$productFruits.push([
    'init',
    'QaPm9Dc29N18z3UY',
    normalizedLanguage,
    getSettings(userProfile),
  ]);
};
