import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';
import Files from '@services/files';
import {
  addScript,
  setPageScripts,
  updateScript,
} from '@store/slices/pageScripts';
import { savePageMetadata } from '@store/thunks/pageInfo/savePageMetadata';

export const saveScript = createAsyncThunk<
  void,
  ScriptFormConfig,
  {
    state: LocalState;
  }
>(
  'pageScripts/saveScript',
  async ({ type, position, scriptSelected }, { getState, dispatch }) => {
    const {
      pageScripts: { head: currentHead, body: currentBody },
    } = getState();

    if (!scriptSelected.id) {
      scriptSelected.id = uuidV4();
      dispatch(addScript({ type, position, scriptSelected }));
    } else {
      dispatch(updateScript({ type, position, scriptSelected }));
    }

    const {
      pageScripts: { head, body },
      pageInfo: { uuid },
    } = getState();

    try {
      await Files.saveScriptsFile(uuid, { head, body }, true);

      await dispatch(savePageMetadata());
    } catch (error) {
      dispatch(setPageScripts({ head: currentHead, body: currentBody }));
      // todo - create error handling process
      const serializedError = error as SerializedError;
      throw serializedError;
    }
  }
);

export const saveScriptBuilder = (
  builder: ActionReducerMapBuilder<PageScriptsState>
) => {
  builder.addCase(saveScript.pending, (state: PageScriptsState) => {
    state.scriptForm.loading = true;
  });
  builder.addCase(saveScript.fulfilled, (state: PageScriptsState) => {
    state.scriptForm.loading = false;
  });
  builder.addCase(saveScript.rejected, (state: PageScriptsState) => {
    state.scriptForm.loading = false;
  });
};
