import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  hot-loading {
    margin: 0 auto;
  }

  table {
    background-color: var(--white);
    border-collapse: collapse;
    border-spacing: 0;
    color: var(--gray-700);
    margin-bottom: 24px;

    thead {
      font-size: 12px;

      tr:first-child th:first-child {
        padding: 8px;
      }

      th {
        border-right: none;
        border-left: none;
      }
    }

    th,
    td {
      vertical-align: middle;
      text-align: left;
    }

    td {
      border-right: none;
      border-left: none;
      padding: 16px 12px;

      :first-child {
        padding: 16px 12px 16px 24px;
      }

      :last-child {
        padding: 16px 24px 16px 12px;
      }
    }

    label {
      margin-bottom: 0;
    }
  }
`;

export const InstallmentColumn = styled.td`
  width: 200px;

  label {
    cursor: pointer;
  }
`;

export const PriceColumn = styled.td`
  width: 150px;

  label {
    cursor: pointer;
  }
`;

export const RadioGroup = styled.div`
  margin-bottom: 0;
  padding-left: 32px;
  display: inline-flex;
`;

export const RadioInput = styled.input`
  margin-right: 16px;
  margin-left: 8px;
`;

export const RadioLabel = styled.label`
  height: 24px;
  padding-left: 0px;
`;

export const RadioColumn = styled.td`
  width: 68px;
`;

type SearchBarSizeProps = {
  searchBarSize?: string;
};

export const InputGroup = styled.div<SearchBarSizeProps>`
  position: relative;
  margin-bottom: 16px;
  ${({ searchBarSize }) =>
    css`
      width: ${searchBarSize || '424px'};
    `}
`;

export const Icon = styled.i`
  position: absolute;
  right: 16px;
  top: 12px;
  z-index: 10;
  color: var(--gray-500);
`;
