import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import Files from '@services/files';

export const saveSupportChatScript = createAsyncThunk<
  string,
  string,
  {
    state: LocalState;
  }
>('pageScripts/saveSupportChatScript', async (script, { getState }) => {
  const {
    pageInfo: { uuid },
  } = getState();

  try {
    await Files.saveScriptsFile(uuid, { supportChatScript: script }, true);
    return script;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const saveSupportChatScriptBuilder = (
  builder: ActionReducerMapBuilder<PageScriptsState>
) => {
  builder.addCase(
    saveSupportChatScript.fulfilled,
    (state: PageScriptsState, action) => {
      state.supportChatScript = action.payload;
    }
  );
};
