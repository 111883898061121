import '@hotmart-org-ca/cosmos/dist/popover';

import {
  useEffect,
  HTMLAttributes,
  PropsWithChildren,
  useState,
  useMemo,
  CSSProperties,
  useRef,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import { Trigger } from './styles';

const popoverRoot = document.createElement('div');
popoverRoot.setAttribute('id', 'popover-portal');
document.body.appendChild(popoverRoot);

type BodyPopoverPosition = 'top' | 'right' | 'bottom' | 'left';

export type BodyPopoverProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    elementSelector: string;
    id?: string;
    position?: BodyPopoverPosition;
    distanceFromElement?: number;
    dismissible?: boolean;
    openOnRender?: boolean;
    preventTriggerClick?: boolean;
    arrowCustomStyle?: string;
    popoverCustomStyle?: string;
    zIndex?: number;
    onTriggerClick?: (open: boolean) => void;
  }>;

export const BodyPopover: React.FC<BodyPopoverProps> = ({
  children,
  elementSelector,
  id,
  distanceFromElement = 0,
  dismissible = false,
  preventTriggerClick = false,
  openOnRender = false,
  popoverCustomStyle,
  arrowCustomStyle,
  position = 'bottom',
  zIndex = 20,
  onTriggerClick,
}) => {
  const popoverRef = useRef<HotPopoverElement | null>(null);

  const [currentRect, setCurrentRect] = useState({
    bottom: '',
    left: '',
    right: '',
    top: '',
    height: '',
    width: '',
  });

  const el = useMemo(() => document.createElement('div'), []);

  const popoverStyle = useMemo<CSSProperties>(
    () => ({
      ...currentRect,
      pointerEvents: preventTriggerClick ? 'none' : 'all',
      position: 'fixed',
      zIndex,
    }),
    [currentRect, preventTriggerClick, zIndex]
  );

  const openPopover = useCallback(
    (isTriggerClick = true) => {
      if (popoverRef.current) {
        if (popoverRef.current._show) {
          const popoverConcrete = popoverRef.current.shadowRoot?.getElementById(
            'popover-concrete'
          ) as HTMLElement;

          if (popoverCustomStyle) {
            popoverConcrete.setAttribute('style', popoverCustomStyle);
          }

          const popoverArrow = popoverConcrete?.shadowRoot?.querySelector(
            '.arrow'
          ) as HTMLElement;

          if (arrowCustomStyle) {
            popoverArrow.setAttribute('style', arrowCustomStyle);
          }
        }

        if (isTriggerClick) {
          onTriggerClick?.(popoverRef.current._show);
        }
      }
    },
    [arrowCustomStyle, popoverCustomStyle, onTriggerClick]
  );

  const getRectWithDistanceFromElement = useCallback(
    (rect: number, rectOposite: BodyPopoverPosition) =>
      `${rect + (position === rectOposite ? distanceFromElement : 0)}px`,
    [distanceFromElement, position]
  );

  useEffect(() => {
    const element = document.querySelector(elementSelector);

    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();

    setCurrentRect({
      top: getRectWithDistanceFromElement(rect.top, 'bottom'),
      right: getRectWithDistanceFromElement(rect.right, 'left'),
      bottom: getRectWithDistanceFromElement(rect.bottom, 'top'),
      left: getRectWithDistanceFromElement(rect.left, 'right'),
      height: `${rect.height}px`,
      width: `${rect.width}px`,
    });

    if (openOnRender) {
      const interval = setInterval(() => {
        if (popoverRef.current) {
          popoverRef.current.openPopover();
          openPopover(false);
          clearInterval(interval);
        }
      }, 50);
    }
  }, [
    elementSelector,
    getRectWithDistanceFromElement,
    openOnRender,
    openPopover,
  ]);

  useEffect(() => {
    popoverRoot.appendChild(el);

    return () => {
      popoverRoot.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(
    <hot-popover
      id={id}
      ref={popoverRef}
      dismissible={dismissible || null}
      position={position}
      style={popoverStyle}
    >
      <Trigger
        slot="button"
        style={{
          ...popoverStyle,
          cursor: preventTriggerClick ? 'default' : 'pointer',
        }}
        onClick={openPopover}
      />
      {children}
    </hot-popover>,
    el
  );
};
