import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CloseIcon = styled.div`
  padding: 0 4.5px;
  display: flex;
  cursor: pointer;
  position: absolute;
  top: -16px;
  right: -16px;
  font-size: 24px;
  color: var(--gray-400);
`;

export const Content = styled.div`
  display: flex;
  gap: 16px;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 324px;
`;

export const Title = styled.h6`
  font-weight: 300;
  font-size: var(--text-4);
  color: var(--gray-700);
  margin-bottom: 0;

  hot-tag {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-left: 8px;
  }
`;

export const Description = styled.div`
  color: var(--gray-500);
  margin-bottom: 16px;
`;

export const Items = styled.div`
  column-count: 2;
  column-gap: 24px;
  height: 100px;
  margin-bottom: auto;
`;

export const Item = styled.div`
  break-inside: avoid;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--blue);
  margin-bottom: 4px;
  cursor: pointer;
`;

export const AllStepsButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
`;
