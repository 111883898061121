import { HTMLAttributes, useMemo } from 'react';
import { SelectMultiple } from '@components/SelectMultiple';

export type TemplateCategoryProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  categories?: TemplateCategory[];
  selectedCategories?: string[];
  onChange?: (values: string[]) => void;
};

export const TemplateCategory: React.FC<TemplateCategoryProps> = ({
  categories = [],
  selectedCategories = [],
  onChange,
  ...attrs
}) => {
  const options = useMemo(
    () =>
      categories.map((category) => ({
        id: category.uuid,
        value: category.uuid,
        label: category.slug,
      })),
    [categories]
  );

  return (
    <div {...attrs}>
      <SelectMultiple
        id="template-categories"
        label="templateCategorization.category.title"
        options={options}
        selectedOptions={selectedCategories}
        onChange={onChange}
      />
    </div>
  );
};
