import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EDITOR_APP_URL, EDITOR_PAGE_LIST_URL } from '@common/constants';
import KlickartAccessTokenStrategy from '@auth/tokenStrategies/klickartAccessTokenStrategy/index';

export const Surrogate = () => {
  const [urlSearchParams] = useSearchParams();
  const query = useRef(urlSearchParams);

  const isValidRedirect = useCallback((redirect: string | null) => {
    if (!redirect) {
      return false;
    }

    return true;
  }, []);

  const redirectToSurrogateSession = useCallback(() => {
    const redirectTo = query.current.get('redirect_to');

    if (isValidRedirect(redirectTo)) {
      const [basePath, queryString] = redirectTo!.split('?');
      const redirectToParams = queryString
        ? new URLSearchParams(queryString)
        : new URLSearchParams();

      query.current.forEach((value, key) => {
        if (key !== 'redirect_to') {
          redirectToParams.set(key, value);
        }
      });

      const finalQueryString = redirectToParams.toString();
      const urlToRedirect = finalQueryString
        ? `${EDITOR_APP_URL}/${basePath}?${finalQueryString}`
        : `${EDITOR_APP_URL}/${redirectTo}`;

      window.location.href = urlToRedirect;
    } else {
      window.location.href = EDITOR_PAGE_LIST_URL;
    }
  }, [isValidRedirect]);

  const removeJWTToken = useCallback(() => {
    const klickartAccessTokenStrategy = new KlickartAccessTokenStrategy();
    klickartAccessTokenStrategy.removeToken();
  }, []);

  useEffect(() => {
    removeJWTToken();

    sessionStorage.setItem('surrogate-enabled', 'true');

    redirectToSurrogateSession();
  }, [removeJWTToken, redirectToSurrogateSession]);

  return null;
};
