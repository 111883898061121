import axios, { AxiosInstance } from 'axios';
import { rebrand } from '@config/rebrand';
import Token, { TokenNames } from '../token';

export default class Api {
  private static instance: AxiosInstance;

  private static klickInstance: AxiosInstance;

  private static publicInstance: AxiosInstance;

  private static casInstance: AxiosInstance;

  private static colaboratorInstance: AxiosInstance;

  private static defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  public static getCancelSource() {
    return axios.CancelToken.source();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = this.buildInstanceByTokenName(
        rebrand.urls.API_URL,
        TokenNames.api
      );
    }

    return this.instance;
  }

  public static getKlickInstance() {
    if (!this.klickInstance) {
      this.klickInstance = this.buildInstanceByTokenName(
        rebrand.urls.KLICKART_URL,
        TokenNames.klickApi
      );
    }

    return this.klickInstance;
  }

  public static getPublicInstance() {
    if (!this.publicInstance) {
      this.publicInstance = axios.create({
        headers: this.defaultHeaders,
      });
    }

    return this.publicInstance;
  }

  public static getCasInstance() {
    if (!this.casInstance) {
      this.casInstance = this.buildInstanceByTokenName(
        rebrand.urls.API_URL,
        TokenNames.cas
      );
    }

    return this.casInstance;
  }

  public static getColaboratorInstance() {
    if (!this.casInstance) {
      this.casInstance = this.buildInstanceByTokenName(
        rebrand.urls.API_URL,
        TokenNames.colaborator
      );
    }

    return this.casInstance;
  }

  public static getInstanceByTokenName(tokenName: TokenNames) {
    switch (tokenName) {
      case TokenNames.cas:
        return this.getCasInstance();
      case TokenNames.colaborator:
        return this.getColaboratorInstance();
      default:
        return this.getInstance();
    }
  }

  private static buildInstanceByTokenName(
    baseURL: string | undefined,
    tokenName: TokenNames
  ) {
    const buildedInstance = axios.create({
      baseURL,
      headers: this.defaultHeaders,
      withCredentials: true,
    });

    const interceptor = Token.interceptor(tokenName);
    const errorHandler = Token.errorHandler(buildedInstance, tokenName);

    buildedInstance.interceptors.request.use(interceptor);

    buildedInstance.interceptors.response.use(
      (response) => response,
      errorHandler
    );

    return buildedInstance;
  }
}
