import { memo, useCallback, useMemo } from 'react';
import { FirstStep } from './components/FirstStep';
import { SecondStep } from './components/SecondStep';

export type LinkTreeProps = {
  currentStep: number;
  properties: LinkTreeProperties;
  onPropertiesChange: (props: Partial<LinkTreeProperties>) => void;
};

const LinkTreeComponent: React.FC<LinkTreeProps> = ({
  currentStep,
  properties: { name, imgSrc, colorsConfig, links },
  onPropertiesChange,
}) => {
  const handleChange = useCallback(
    ({ prop, value }: UseCaseModifierChange) => {
      onPropertiesChange({ [prop]: value });
    },
    [onPropertiesChange]
  );

  const steps = useMemo(
    () => [
      <FirstStep
        name={name}
        src={imgSrc}
        colors={colorsConfig}
        onModifierChange={handleChange}
      />,
      <SecondStep links={links} onModifierChange={handleChange} />,
    ],
    [colorsConfig, handleChange, imgSrc, links, name]
  );

  return steps[currentStep] || steps[1];
};

export const LinkTree = memo(LinkTreeComponent);
