import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  hot-loading {
    margin: 0 auto;
  }

  table {
    background-color: var(--white);
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 24px;

    thead {
      font-size: 12px;

      tr:first-child th:first-child {
        padding: 8px 54px;
      }

      th {
        border-right: none;
        border-left: none;
      }
    }

    th,
    td {
      vertical-align: middle;
      text-align: left;
    }

    td {
      border-right: none;
      border-left: none;
    }

    .spacing {
      padding: 12px 0px;
    }
  }
`;
