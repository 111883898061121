import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const Button = styled.button`
  width: 100%;
`;

export const Image = styled.div`
  justify-content: center;
  display: flex;
`;

export const Title = styled.div`
  font-size: 24px;
  text-align: center;
  color: var(--gray-900);
  font-weight: 300;
  line-height: 28.8px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: var(--gray-500);
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  margin-top: 12px;
  padding: 0 48px;
`;

export const ActionsContainer = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
