import { useTranslation } from 'react-i18next';
import { ChangeEvent, HTMLAttributes, memo } from 'react';
import { CheckBoxesWrapper, InputWrapper } from './styles';
import allInputs from './inputs';

export type KlicksendFormInputsProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'onChange'
> & {
  selectedInputs: KlicksendFormField[];
  onChange: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
};

const KlicksendFormInputsComponent: React.FC<KlicksendFormInputsProps> = ({
  selectedInputs,
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  return (
    <CheckBoxesWrapper {...attrs}>
      {allInputs.map((checkboxInput) => (
        <InputWrapper
          key={`${checkboxInput.name}-checkbox-wrapper`}
          className="hot-form hot-form--custom hot-form--checkbox"
        >
          <input
            type="checkbox"
            id={`${checkboxInput.name}-modal-checkbox`}
            disabled={checkboxInput.name === 'email'}
            className="hot-form__input valid"
            checked={Boolean(
              selectedInputs.find(
                (selectedInput) => selectedInput.name === checkboxInput.name
              )
            )}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e, checkboxInput.name)
            }
          />
          <label
            htmlFor={`${checkboxInput.name}-modal-checkbox`}
            className="hot-form__label"
          >
            {t(`${checkboxInput.label}`)}
          </label>
        </InputWrapper>
      ))}
    </CheckBoxesWrapper>
  );
};

export const KlicksendFormInputs = memo(KlicksendFormInputsComponent);
