import styled, { css } from 'styled-components';

type StyledLoaderProps = {
  isLoading?: boolean;
};

export const StyledLoader = styled.div<StyledLoaderProps>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all ease 0.3s;
  background: var(--white);
  inset: 0;
  opacity: 0;
  z-index: 20;

  ${({ isLoading }) => css`
    ${isLoading &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  `}
`;
