import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18next';
import MediaCenter from '@services/mediaCenter';
import {
  addUploadingMedia,
  updateUploadingMedia,
} from '@store/slices/mediaCenter';
import { sleep } from '@common/utils';

export const createFolder = createAsyncThunk<
  Media[],
  void,
  { state: LocalState; fulfilledMeta: string[]; rejectedMeta: string[] }
>(
  'mediaCenter/createFolder',
  async (_, { rejectWithValue, getState, dispatch, fulfillWithValue }) => {
    const {
      mediaCenter: { breadcrumbs },
    } = getState();
    const currentFolder: string | undefined = [...breadcrumbs]
      .pop()
      ?.id.toString();
    const uploadingFolderId = uuidv4();

    const uploadingFolder: UploadingMedia<Folder> = {
      id: uploadingFolderId,
      name: i18n.t('mediaCenter.toolBar.newFolder'),
      type: 'media_folder',
      parentId: null,
    };

    const uploadedFolder = {
      ...uploadingFolder,
      loading: false,
      error: false,
      uploaded: true,
    };

    const failedUploadingFolder = {
      ...uploadingFolder,
      loading: false,
      error: true,
      uploaded: false,
    };
    try {
      dispatch(addUploadingMedia(uploadingFolder));
      await MediaCenter.createFolder(uploadingFolder.name, currentFolder);
      dispatch(updateUploadingMedia(uploadedFolder));
      const response: Media[] = currentFolder
        ? await MediaCenter.getFolderMedia(currentFolder)
        : await MediaCenter.getRootMedia();
      await sleep(500);
      return fulfillWithValue(response, [uploadingFolderId]);
    } catch (error) {
      const serializedError = error as SerializedError;
      dispatch(updateUploadingMedia(failedUploadingFolder));
      await sleep(500);
      return rejectWithValue(serializedError, [uploadingFolderId]);
    }
  }
);

export const createFolderBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(createFolder.fulfilled, (state: MediaCenterState, action) => {
    const returnedMediaList = action.payload;
    const uploadingFolderId = action.meta[0];
    state.uploadingMedia = state.uploadingMedia.filter(
      (media) => media.id !== uploadingFolderId
    );
    state.media = returnedMediaList;
  });
  builder.addCase(createFolder.rejected, (state: MediaCenterState, action) => {
    const uploadingFolderId = action.meta[0];
    state.uploadingMedia = state.uploadingMedia.filter(
      (media) => media.id !== uploadingFolderId
    );
  });
};
