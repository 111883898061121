import { MenuItem } from '@components/MenuItem';
import { Submenu } from '@components/Submenu';
import { useMenu } from '@hooks/useMenu';

import React, { useCallback, useMemo } from 'react';

export const ModelsSubmenu: React.FC = () => {
  const { setActiveSubmenu } = useMenu();

  const modelCategories = useMemo<{ id: string; label: string }[]>(
    () => [
      { id: 'sections-template', label: 'sideMenu.sectionsTemplate.title' },
      { id: 'popups-submenu', label: 'sideMenu.popupsTemplate.title' },
    ],
    []
  );

  const handleClick = useCallback(
    (id: string) => {
      setActiveSubmenu(id);
    },
    [setActiveSubmenu]
  );

  return (
    <Submenu title="sideMenu.models.title" padding="24px 8px">
      {modelCategories.map((item) => (
        <MenuItem
          key={item.id}
          id={item.id}
          label={item.label}
          onClick={() => handleClick(item.id)}
        />
      ))}
    </Submenu>
  );
};
