import styled, { css } from 'styled-components';
import { Icon } from '../Icon';

export type SubmenuTitleProps = {
  activeComponent?: string;
};

export const SubmenuHeader = styled.div`
  display: flex;
  line-height: var(--line-height-lg);
  user-select: none;
  align-items: center;
  border-bottom: var(--structure-border-width, 0.0625rem) solid
    var(--structure-menu-border-color, #e6e9ed);
  padding: 0 var(--spacer-5, 1.5rem);
  min-height: var(--structure-header-height, 70px);
  position: relative;

  hot-breadcrumb {
    margin-top: 16px;
    margin-bottom: 6px;
    border: 0;
    display: flex;
    font-size: 12px;
    color: var(--blue);

    &-item {
      &::after {
        color: var(--gray-400);
        content: '>';
        margin: 0 var(--spacer-2) 0 var(--spacer-2);
      }
    }

    > hot-breadcrumb-item {
      &:not(:first-child) {
        padding-right: 0;
      }

      &:last-child::after {
        content: '>';
        margin: 0 var(--spacer-2) 0 var(--spacer-2);
      }
    }
  }
`;

export const Anchor = styled.span`
  color: var(--blue);
  cursor: pointer;
`;

export const SubmenuTitle = styled.h3<SubmenuTitleProps>`
  color: var(--gray-700);
  line-height: var(--line-height-lg);
  font-size: 1em;
  font-weight: normal;
  ${({ activeComponent }) => css`
    margin-bottom: ${Boolean(!activeComponent) ||
    activeComponent === 'ls-section'
      ? '0'
      : '8px'};
  `}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseSubmenu = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 24px;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 16px;
`;
