import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/index';
import { pageConfigThunks } from '@store/thunks';
import * as pageConfigSlice from '@store/slices/pageConfig';

export const usePageConfig = () => {
  const dispatch = useAppDispatch();

  const deleteUrlParam = useCallback(
    () => dispatch(pageConfigThunks.deleteUrlParam()).unwrap(),
    [dispatch]
  );

  const saveUrlParam = useCallback(
    (urlParam: UrlParam) =>
      dispatch(pageConfigThunks.saveUrlParam(urlParam)).unwrap(),
    [dispatch]
  );

  const updateConfig = useCallback(
    (config: Partial<PageConfigState>) =>
      dispatch(pageConfigSlice.updateConfig(config)),
    [dispatch]
  );

  const urlParams = useSelector((state: State) => state.pageConfig.urlParams);

  const mobileFirst = useSelector(
    (state: State) => state.pageConfig.mobileFirst
  );

  const pageConfig = useSelector((state: State) => state.pageConfig);

  const findUrlParamByName = useCallback(
    (paramName: string) =>
      urlParams.find((urlParam) => urlParam.name === paramName),
    [urlParams]
  );

  const showMadeWithKlickpages = useSelector(
    (state: State) => state.pageConfig.showMadeWithKlickpages
  );

  return {
    deleteUrlParam,
    findUrlParamByName,
    saveUrlParam,
    updateConfig,
    urlParams,
    mobileFirst,
    pageConfig,
    showMadeWithKlickpages,
  };
};
