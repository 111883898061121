import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';

import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import classNames from 'classnames';
import { ComponentState } from '@hotmart-org-ca/saas-pages-engine';
import { useEngine } from '@hooks/useEngine';
import { useUserInfo } from '@hooks/useUserInfo';
import { useCheckout } from '@hooks/useCheckout';
import { Button, Container } from './styles';
import { CheckoutModifierModalSet } from '../CheckoutModifierModalSet';

export type CheckoutLinkModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps;

const CheckoutLinkModifierComponent: React.FC<CheckoutLinkModifierProps> = ({
  uid,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { updateElement } = useEngine();
  const { isExtensions } = useUserInfo();
  const { initializeCheckoutIntegration } = useCheckout();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const elementState = useSelector<State, ComponentState>(
    (state) => state[uid] as ComponentState
  );

  const actionChangeEventName = useMemo(
    () => `ls-modifier-link-anchor-action-${uid}`,
    [uid]
  );
  const propertiesChangeEventName = useMemo(
    () => `ls-modifier-link-anchor-change-props-${uid}`,
    [uid]
  );

  const handlePropsChange = useCallback(
    (detail: Record<string, unknown>) => {
      const event = new CustomEvent(propertiesChangeEventName, { detail });
      setTimeout(() => window.dispatchEvent(event), 100);
    },
    [propertiesChangeEventName]
  );

  const handleOpenModal = useCallback(async () => {
    setButtonLoading(true);

    const { isProductListEmpty } = await initializeCheckoutIntegration();

    if (!isExtensions || isProductListEmpty) {
      setShowAlertModal(true);
    } else {
      setShowSelectProductModal(true);
    }
    setButtonLoading(false);
  }, [initializeCheckoutIntegration, isExtensions]);

  useEffect(() => {
    const actionListener = async (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      const action = customEvent.detail;

      const actionType: Record<string, string> = {
        section: 'Anchor Section',
        link: 'External Link',
        'link-checkout': 'Hotmart Link',
        'popup-on-click': 'Pop up',
      };

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_button_config_action_type',
          event_details: actionType[action],
        },
      });

      if (action !== 'link-checkout') {
        updateElement(uid, { metadata: { checkout: {} } }, false);

        return;
      }

      setButtonLoading(true);

      const { selectedOffer, url, selectedProduct } =
        await initializeCheckoutIntegration();

      if (url) {
        const props = { href: url };
        const metadata = {
          checkout: { productId: selectedProduct, offerId: selectedOffer },
        };

        updateElement(uid, { metadata, props }, false);
      }

      setButtonLoading(false);
    };

    window.addEventListener(actionChangeEventName, actionListener);

    return () => {
      window.removeEventListener(actionChangeEventName, actionListener);
    };
  }, [
    actionChangeEventName,
    initializeCheckoutIntegration,
    uid,
    updateElement,
  ]);

  useEffect(() => {
    const { action, href } = elementState.props;

    if (action !== 'link-checkout') {
      handlePropsChange({
        linkConfig: {
          disabled: false,
          hide: false,
        },
      });
      return;
    }

    handlePropsChange({
      linkConfig: {
        disabled: true,
        hide: !href,
      },
    });
  }, [elementState, handlePropsChange]);

  useEffect(() => {
    handlePropsChange({
      additionalOptions: [
        {
          value: 'link-checkout',
          label: t('modifiers.checkout.linkOption'),
          position: 1,
        },
      ],
    });
  }, [handlePropsChange, t]);

  if (elementState.props.action !== 'link-checkout') {
    return null;
  }

  return (
    <Container {...attrs}>
      <Button
        className={classNames(
          'hot-button hot-button--secondary hot-button--sm',
          {
            'hot-button--loading': buttonLoading,
          }
        )}
        onClick={handleOpenModal}
      >
        {t('modifiers.checkout.selectProduct')}
      </Button>

      <CheckoutModifierModalSet
        uid={uid}
        modifierType="checkoutLink"
        isAlertModalOpen={showAlertModal}
        isProductModalOpen={showSelectProductModal}
        onAlertModalClose={() => setShowAlertModal(false)}
        onProductModalClose={() => setShowSelectProductModal(false)}
      />
    </Container>
  );
};

export const CheckoutLinkModifier = memo(CheckoutLinkModifierComponent);
