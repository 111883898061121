import '@hotmart-org-ca/cosmos/dist/dropdown';
import '@hotmart-org-ca/cosmos/dist/select';
import '@hotmart-org-ca/cosmos/dist/form/input-validate.css';
import { rebrand } from '@config/rebrand';
import React, {
  HTMLAttributes,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import slugify from 'slugify';
import classNames from 'classnames';
import { SelectOption } from '@components/Select';
import { CosmosLoader } from '@components/CosmosLoader';
import Klickart from '@services/klickart';
import { updateInfo } from '@store/slices/pageInfo';
import PagesService from '@services/pages';
import Toast from '@services/toast';
import { Label } from '@components/Label';
import { ExternalLink } from '@components/ExternalLink';
import { UrlDescription } from './components/UrlDescription';
import {
  Container,
  Path,
  PrependDomain,
  PrependPath,
  Button,
  ErrorMessage,
  LinkDomain,
  StyledSelect,
} from './styles';

export const UrlConfig: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uuid = useSelector((state: State) => state.pageInfo.uuid);
  const currentDomain = useSelector((state: State) => state.pageInfo.domain);
  const currentPath = useSelector((state: State) => state.pageInfo.path);

  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [domain, setDomain] = useState(currentDomain);
  const [path, setPath] = useState(currentPath);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [invalidPath, setInvalidPath] = useState(false);

  const getDomains = useCallback(async () => {
    const domainsKlickart = await Klickart.getDomains();
    const domainOptions = domainsKlickart.map(
      (domainKlickart: KlickartDomainsData): SelectOption => ({
        id: domainKlickart.host,
        label: domainKlickart.host,
        value: domainKlickart.host,
      })
    );

    setSelectOptions(domainOptions);
    setLoading(false);
  }, []);

  useEffect(() => {
    getDomains();
  }, [getDomains]);

  const handleSelectDomain = useCallback(
    async (value: string) => {
      if (value === currentDomain) return;
      setInvalidPath(false);
      setDomain(value);
    },
    [currentDomain]
  );

  const handlePathChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setInvalidPath(false);
      const { value } = e.target;
      setPath(value);
    },
    []
  );

  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true);

      if (currentDomain !== domain) {
        await PagesService.updatePage(uuid, { domain });
        dispatch(updateInfo({ domain }));
      }

      const slug = slugify(path, { strict: true, lower: true });
      const pathData = { path: slug };
      setPath(slug);

      await PagesService.updatePage(uuid, pathData);
      dispatch(updateInfo(pathData));

      Toast.success('toastMessages.general.success.saved');
    } catch (e) {
      const error = e as ErrorResponse;
      if (error.response?.status === 422) {
        setInvalidPath(true);
      } else {
        Toast.error('toastMessages.page.error.url');
      }
    } finally {
      setIsSaving(false);
    }
  }, [currentDomain, dispatch, domain, path, uuid]);

  const prependDomain = useMemo(
    () => <PrependDomain>https://</PrependDomain>,
    []
  );

  return (
    <Container {...attrs}>
      {loading ? (
        <CosmosLoader />
      ) : (
        <>
          <StyledSelect
            id="domains"
            label={t('urlConfig.domain.label')}
            infoTooltip={{
              title: 'labelTooltip.settings.domain.domainUrl.title',
              description: 'labelTooltip.settings.domain.domainUrl.description',
              displayMode: 'always',
            }}
            prepend={prependDomain}
            placeholder={t('urlConfig.domain.placeholder')}
            options={selectOptions}
            onChange={handleSelectDomain}
            selectedOption={domain}
          />
          <LinkDomain>
            <ExternalLink
              href={`${rebrand.urls.KLICKART_URL}/domains`}
              text={t('urlConfig.linkDomain')}
              openInNewTab
            />
          </LinkDomain>
          <Path className="hot-form">
            <Label
              label={t('urlConfig.path.title')}
              infoTooltip={{
                title: 'labelTooltip.settings.domain.path.title',
                description: 'labelTooltip.settings.domain.path.description',
                displayMode: 'always',
              }}
            />

            <hot-input-group>
              <div className="hot-input-group__prepend hot-input-group__prepend--sm">
                <PrependPath>/</PrependPath>{' '}
              </div>

              <input
                id="path"
                className={classNames('hot-form__input hot-form__input--sm', {
                  'is-invalid': invalidPath,
                })}
                placeholder={t('urlConfig.path.placeholder')}
                value={path}
                onChange={handlePathChange}
              />
            </hot-input-group>
            {invalidPath && (
              <ErrorMessage className="invalid-feedback">
                {t('urlConfig.path.invalidPath')}
              </ErrorMessage>
            )}
          </Path>

          <UrlDescription domain={domain} path={path} />

          <Button
            onClick={handleSave}
            className={classNames(
              'hot-button hot-button--sm hot-button--primary',
              {
                'hot-button--loading': isSaving,
              }
            )}
          >
            <span>{t('seo.save')}</span>
          </Button>
        </>
      )}
    </Container>
  );
};
