import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const Description = styled.div`
  color: var(--gray-500);
  font-size: 12px;
`;
