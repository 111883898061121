import { v1 as uuidV1 } from 'uuid';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import i18n from 'i18next';

const getPlaceholderInput = () => i18n.t('form.simpleForm.placeholder');

export const inputs = JSON.stringify([
  {
    id: 'email',
    name: 'email',
    label: 'E-mail',
    value: '',
    required: true,
    defaultValue: '',
    type: 'text',
    placeholder: getPlaceholderInput(),
  },
]);

const getCheckboxInputs = () =>
  JSON.stringify([
    {
      id: 'checkbox-simpleForm1',
      name: 'checkbox-simpleForm1',
      label: i18n.t('form.simpleForm.label'),
      required: true,
      defaultValue: '',
      type: 'checkbox',
    },
  ]);

const getSubmitTextSimpleForm = () => i18n.t('form.simpleForm.submitText');

const getInputLabel = () => i18n.t('form.simpleForm.inputLabel');

const getSimpleFormSubmitText = (i18nKey: string) => i18n.t(i18nKey);

export const getDefaultSimpleForm: () => Template = () => ({
  uid: 'simple-form',
  'form-id': `ls-form-${uuidV1()}`,
  component: 'ls-form',
  html: `<form><label for="email">${getInputLabel()}</label><input id="email" name="email" placeholder=${getPlaceholderInput()} /><button type="submit">${getSubmitTextSimpleForm()}</button></form>`,
  action: '',
  checkbox: JSON.stringify({
    checkbox: getCheckboxInputs(),
    color: '#191C1F',
    'font-family': 'Raleway, sans-serif',
    'font-size': '14px',
    margin: '8px 0px 8px 0px',
  }),
  description: JSON.stringify({
    text: `<p><span style="font-size: 14px; font-family: Raleway, sans-serif; color: #191C1F;">${getSimpleFormSubmitText(
      'form.simpleForm.gdpr.description'
    )} </span><a style="font-size: 14px; font-family: Raleway, sans-serif;" target="_blank" href="https://klickpages.com.br/politica-de-privacidade/">${getSimpleFormSubmitText(
      'form.simpleForm.gdpr.policy'
    )} </a></p>`,
  }),
  fields: JSON.stringify({
    fields: `${inputs}`,
    color: '#191C1F',
    'font-family': 'Raleway, sans-serif',
    'has-label': true,
  }),
  button: JSON.stringify({
    uid: 'ls-button',
    component: 'ls-button',
    label: 'Enviar',
    background: '#191C1F',
    padding: '8px 32px 8px 32px',
    'border-radius': '24px 24px 24px 24px',
    'font-family': 'Montserrat, sans-serif',
    'is-bold': true,
    margin: '0px 0px 0px 0px',
  }),
  'simple-form': true,
  ownCode: false,
  'redirect-url': process.env.REACT_APP_SIMPLE_FORM_REDIRECT_URL,
});
