import { v4 as uuid } from 'uuid';

export const warrantySections: PageFragmentListItem[] = [
  {
    id: 'warranty-5',
    name: 'Warranty',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'CTA',
        templateId: 'cta-2',
        templateName: 'CTA',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 100px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 48px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(97, 91, 155);">7 DIAS DE GARANTIA!</strong></h1>',
                  'max-width': '100%',
                  padding: '80px 10px 8px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '90%',
                    padding: '56px 10px 8px 10px',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 32px; color: rgb(97, 91, 155);">7 DIAS DE GARANTIA!</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(119, 119, 119);">Seu dinheiro de volta sem perguntas nos primeiros 7 dias.</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 20px; color: rgb(119, 119, 119);">Seu dinheiro de volta sem perguntas nos primeiros 7 dias.</span></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 16px; color: rgb(119, 119, 119);">Seu dinheiro de volta sem perguntas nos primeiros 7 dias.</span></p>',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 72px; color: rgb(97, 91, 155);">R$ XX,xx</strong></h1>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 64px; color: rgb(97, 91, 155);">R$ XX,xx</strong></h1>',
                    'max-width': '90%',
                    padding: '24px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#615B9B',
                  background: '#FADA4A',
                  'border-color': '#EF4E23',
                  'border-radius': '16px 16px 16px 16px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '24px',
                  'hover-background': '#FFE675',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO APROVEITAR!',
                  margin: '32px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '250px',
                    margin: '24px 0px 16px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'warranty-6',
    name: 'Warranty',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 14',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '70px 0px 60px 0px',
              },
              mobile: {
                padding: '40px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '280px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 30px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '230px',
                    height: '230px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5399326/garantia-vendas_assets_template-albertoni.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5399326/thumb_garantia-vendas_assets_template-albertoni.png',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 24px;">Você tem </span><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 24px;">7 dias</strong><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 24px;"> de garantia. Se decidir não se tornar um profissional da internet dentro desse prazo, é só nos avisar que </span><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 24px;">devolveremos 100% do seu dinheiro</strong><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 24px;">, sem burocracia.</span></p>',
                  'max-width': '63%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '78%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 20px;">Você tem </span><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 20px;">7 dias</strong><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 20px;"> de garantia. Se decidir não se tornar um profissional da internet dentro desse prazo, é só nos avisar que </span><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 20px;">devolveremos 100% do seu dinheiro</strong><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 20px;">, sem burocracia.</span></p>',
                    'max-width': '88%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#141436',
                  background: '#61F5B0',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO COMEÇAR AGORA!',
                  margin: '40px 0px 20px 0px',
                  'max-width': '450px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '300px',
                    margin: '30px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'warranty-7',
    name: 'Warranty',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5365174/bg3-vendas_assets_template-erico-rocha.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Garantia',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '70px 0px 40px 0px',
              },
              mobile: {
                padding: '50px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '250px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '200px',
                    height: '220px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5357614/garantia-vendas_assets_template-erico-rocha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5357614/thumb_garantia-vendas_assets_template-erico-rocha.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '70px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '10px 0px 70px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '10px 25px 50px 25px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 60px;">GARANTIA</strong></p>',
                  'max-width': '60%',
                  padding: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="line-height: 1; text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 60px;">GARANTIA</strong></p>',
                    'max-width': '50%',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="line-height: 1; text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">GARANTIA</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="color: rgb(255, 255, 255); font-size: 20px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Esse método fez os meus alunos faturaram juntos mais de 1 bilhão de reais e agora você pode entrar para esse time! Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.</span></p>',
                  'max-width': '85%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 20px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Esse método fez os meus alunos faturaram juntos mais de 1 bilhão de reais e agora você pode entrar para esse time! Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.</span></p>',
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '100%',
                    text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-size: 17px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Esse método fez os meus alunos faturaram juntos mais de 1 bilhão de reais e agora você pode entrar para esse time! Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.</span></p>',
                    padding: '20px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'warranty-8',
    name: 'Warranty',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 8',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '160px 0px 160px 20px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '88px 0px 80px 0px',
              },
              mobile: {
                padding: '64px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '300px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '300px',
                    'max-width': '300px',
                  },
                  mobile: {
                    height: '230px',
                    'max-width': '230px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5486815/garantia-vendas_assets_template-gi-isquierdo.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5486815/thumb_garantia-vendas_assets_template-gi-isquierdo.png',
                  metadata: {},
                },
                {
                  padding: '10px 0px 10px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '56%',
                  margin: '10px 0px 10px 64px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    margin: '40px 0px 10px 0px',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  mobile: {
                    margin: '16px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Oswald, sans-serif; font-size: 36px; color: rgb(20, 19, 58);">GARANTIA </strong><strong style="font-family: Oswald, sans-serif; font-size: 36px; color: rgb(20, 19, 58); background-color: rgb(255, 59, 113);"> INCONTESTÁVEL </strong><strong style="font-family: Oswald, sans-serif; font-size: 36px; color: rgb(20, 19, 58);"> DE 7 DIAS</strong></p>',
                      'max-width': '94%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 24px; color: rgb(20, 19, 58);">GARANTIA</strong></p><p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 24px; color: rgb(20, 19, 58); background-color: rgb(255, 59, 113);"> INCONTESTÁVEL </strong></p><p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 24px; color: rgb(20, 19, 58);">DE 7 DIAS</strong></p>',
                        'max-width': '80%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 18px;">Milhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 18px;">Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.</span></p>',
                      'max-width': '88%',
                      padding: '24px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 18px;">Milhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.</span></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 18px;">Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.</span></p>',
                      },
                      mobile: {
                        padding: '16px 0px 10px 0px',
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">Milhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.</span></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'warranty-1',
    name: 'Warranty',
    template: {
      background: '#E4F7FF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Garantia' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '20px 0px 20px 0px',
                'align-items': 'center',
                'justify-content': 'center',
                'flex-direction': 'column',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '250px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 10px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { height: '180px', 'max-width': '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5292249/garantia-vendas_assets_template-empreend-ladeira.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5292249/thumb_garantia-vendas_assets_template-empreend-ladeira.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '535px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '370px' },
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 10px 10px 10px',
                    'max-width': '100%',
                    'flex-direction': 'column',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 30px; color: rgb(39, 42, 173);"\u003eGarantia de 7 dias\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        padding: '0px 10px 10px 0px',
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 25px; color: rgb(39, 42, 173);"\u003eGarantia de 7 dias\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '80%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="color: rgb(70, 70, 70); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eMilhares de pessoas comuns estão mudando a suas realidades com o poder do digital. Você também pode. Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cspan style="color: rgb(70, 70, 70); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eMilhares de pessoas comuns estão mudando a suas realidades com o poder do digital. Você também pode. Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        padding: '0px 0px 0px 0px',
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(70, 70, 70); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eMilhares de pessoas comuns estão mudando a suas realidades com o poder do digital. Você também pode. Mas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '80%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'warranty-2',
    name: 'Warranty',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Dispositivos' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 10px 0px',
              },
              mobile: { padding: '10px 0px 10px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '550px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    'max-width': '90%',
                  },
                  mobile: { 'max-width': '80%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(48, 216, 229); font-size: 40px;"\u003eDisponível para qualquer dispositivo\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(48, 216, 229); font-size: 30px;"\u003eDisponível para qualquer dispositivo\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(48, 216, 229); font-size: 24px;"\u003eDisponível para qualquer dispositivo\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 24px;"\u003e_____________________________________\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 24px;"\u003e___________________________\u003c/strong\u003e\u003c/p\u003e',
                        padding: '0px 10px 15px 10px',
                      },
                      mobile: {
                        text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 24px;"\u003e________________\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(126, 126, 126); font-size: 24px;"\u003eVeja o meu conteúdo onde for melhor para você.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(126, 126, 126); font-size: 21px;"\u003eVeja o meu conteúdo onde for melhor para você.\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '90%',
                      },
                      mobile: {
                        'max-width': '100%',
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(126, 126, 126); font-size: 18px;"\u003eVeja o meu conteúdo onde for melhor para você.\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '70px 0px 70px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 50px 0px' },
              mobile: { padding: '10px 0px 40px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '430px',
                  href: '',
                  'max-width': '500px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '380px', 'max-width': '450px' },
                  mobile: { 'max-width': '300px', height: '250px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5292308/dispositivos-vendas_assets_template-empreend-ladeira.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5292308/thumb_dispositivos-vendas_assets_template-empreend-ladeira.png',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'warranty-3',
    name: 'Warranty',
    template: {
      background: '#222222',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 1' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '40px 0px 0px 0px' },
              mobile: { padding: '20px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '300px',
                  href: '',
                  'max-width': '350px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '250px', height: '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5320881/garantia-vendas_assets_template-orange-chic.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5320881/thumb_garantia-vendas_assets_template-orange-chic.png',
                },
              ],
            },
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 0px 70px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '10px 0px 50px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 30px;"\u003eGARANTIA INCONTESTÁVEL DE 7 DIAS\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 30px;"\u003eGARANTIA INCONTESTÁVEL DE 7 DIAS\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 24px;"\u003eGARANTIA INCONTESTÁVEL DE 7 DIAS\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eMilhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eMas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eMilhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;"\u003eMas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/strong\u003e\u003c/p\u003e',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eMilhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eMas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'warranty-4',
    name: 'Warranty',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Garantia' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '70px 0px 0px 0px' },
              mobile: { padding: '60px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '300px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { height: '200px', 'max-width': '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5336425/garantia-vendas_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5336425/thumb_garantia-vendas_assets_template-navy-blue.png',
                },
              ],
            },
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 0px 50px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '10px 0px 40px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 0px 10px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  mobile: { padding: '0px 0px 10px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 28px;"\u003eGarantia incontestável de 7 dias\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 28px;"\u003eGarantia incontestável de 7 dias\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '70%',
                      },
                      mobile: {
                        'max-width': '50%',
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 24px;"\u003eGarantia incontestável de 7 dias\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 18px;"\u003eMilhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 18px;"\u003eMas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 18px;"\u003eMilhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 18px;"\u003eMas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '70%',
                      },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 15px;"\u003eMilhares de pessoas comuns como você estão mudando suas realidades com o poder do digital. Isso significa que você também pode mudar a sua vida para melhor.\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(9, 32, 76); font-size: 15px;"\u003eMas caso não goste do que encontrar, devolvo seu dinheiro de volta sem perguntas até 7 dias após a compra.\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '80%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
