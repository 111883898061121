import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Row: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="40"
    height="20"
    viewBox="0 0 40 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M37 0H3C1.3125 0 0 1.375 0 3V17C0 18.6875 1.3125 20 3 20H37C38.625 20 40 18.6875 40 17V3C40 1.375 38.625 0 37 0ZM38 17C38 17.5625 37.5 18 37 18H3C2.4375 18 2 17.5625 2 17V3C2 2.5 2.4375 2 3 2H37C37.5 2 38 2.5 38 3V17Z"
      fill={color}
    />
  </StyledSVG>
);
