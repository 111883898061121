import { useMemo } from 'react';

export const useDetectBrowser = () => {
  const isFirefox = useMemo(
    () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
    []
  );

  return { isFirefox };
};
