const isDomainRebrand =
  window.location.hostname === process.env.REACT_APP_EDITOR_DOMAIN_REBRAND;

const klickpagesDomainsUrls = {
  API_URL: process.env.REACT_APP_API_URL,
  KLICKSEND_API_URL: process.env.REACT_APP_KLICKSEND_API_URL,
  KLICKSEND_HANDLER_URL: process.env.REACT_APP_KLICKSEND_HANDLER_URL,
  KLICKSEND_URL: process.env.REACT_APP_KLICKSEND_URL,
  KLICKART_URL: process.env.REACT_APP_KLICKART_URL,
  PAGE_MANAGER_URL: process.env.REACT_APP_PAGE_MANAGER_URL,
  ANALYTICS_URL: process.env.REACT_APP_ANALYTICS_URL,
  EDITOR_URL: process.env.REACT_APP_EDITOR_URL,
};

const hotmartDomainsUrls = {
  API_URL: process.env.REACT_APP_API_URL_REBRAND,
  KLICKSEND_API_URL: process.env.REACT_APP_KLICKSEND_API_URL_REBRAND,
  KLICKSEND_HANDLER_URL: process.env.REACT_APP_KLICKSEND_HANDLER_URL_REBRAND,
  KLICKSEND_URL: process.env.REACT_APP_KLICKSEND_URL_REBRAND,
  KLICKART_URL: process.env.REACT_APP_KLICKART_URL_REBRAND,
  PAGE_MANAGER_URL: process.env.REACT_APP_PAGE_MANAGER_URL_REBRAND,
  ANALYTICS_URL: process.env.REACT_APP_ANALYTICS_URL_REBRAND,
  EDITOR_URL: process.env.REACT_APP_EDITOR_URL_REBRAND,
};

const domainsUrls = isDomainRebrand
  ? hotmartDomainsUrls
  : klickpagesDomainsUrls;

export const rebrand = {
  urls: { ...domainsUrls },
};
