import { HTMLAttributes, memo } from 'react';
import { SalesUseCase } from '@pages/PageWizard/useCases/Sales/salesUseCase';
import { Block } from '@components/Block';
import { PersonalizeColors } from '../../../PersonalizeColors';
import { StyledSelectTemplate } from './styles';

export type PersonalizeProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    template?: SalesPageTemplateType;
    properties: SalesPageProperties;
    useCase: SalesUseCase;
    onTemplateChange: (props: SalesPageProperties) => void;
  };

export const PersonalizeComponent: React.FC<PersonalizeProps> = ({
  properties,
  useCase,
  onTemplateChange,
  onModifierChange,
  ...attrs
}) => (
  <Block
    label="pageWizard.salesPage.hotmartJourneySalesPageWithProduct.title"
    description="pageWizard.salesPage.hotmartJourneySalesPageWithProduct.description"
    width="100%"
    {...attrs}
  >
    <StyledSelectTemplate
      preventNextClick
      hideThumb
      label="pageWizard.salesPage.hotmartJourneySalesPageWithProduct.model.title"
      properties={properties}
      onTemplateChange={onTemplateChange}
    />
    <PersonalizeColors
      width="100%"
      isReducedThemes
      colors={properties.colors}
      useCase={useCase!}
      onModifierChange={onModifierChange}
    />
  </Block>
);

export const Personalize = memo(PersonalizeComponent);
