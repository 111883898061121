import styled from 'styled-components';

export const Container = styled.div`
  .hot-popover__body {
    box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 12%);
  }
`;

export const Counter = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--gray-500);
`;

export const Description = styled.div`
  margin: 12px 0;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

export const Button = styled.button`
  width: 100%;
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 25;
`;

export const CloseIcon = styled.div`
  padding: 0 4.5px;
  display: flex;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  color: var(--gray-400);
`;
