import { useEffect, useState } from 'react';
import { initializeProductFruits } from '@common/product-fruits';
import {
  ModifierConfig,
  bootstrap,
  setElementsConfig,
  setFeatures,
} from '@hotmart-org-ca/saas-pages-engine';
import { getBootstrapConfig } from '@common/bootstrap-config';
import { Loader } from './components/Loader';
import { Routes } from './routes';
import { Toast } from './components/Toast';
import { i18nInit } from './i18n';
import { useApplication } from './hooks/useApplication';
import { useUserInfo } from './hooks/useUserInfo';
import { getModifiersConfig } from './common/modifiers-config';

function App() {
  const { appLoading } = useApplication();
  const { updateInfo } = useUserInfo();

  const [render, setRender] = useState(false);

  useEffect(() => {
    const userInfoListener = (event: Event) => {
      const customEvent = event as CustomEvent<User>;
      const { userProfile } = customEvent.detail;

      setElementsConfig({
        modifiers: getModifiersConfig() as Record<string, ModifierConfig>,
      });

      setFeatures({
        plusButtonAddThroughEvent: true,
      });

      updateInfo(customEvent.detail);
      initializeProductFruits(userProfile);
    };

    window.addEventListener('userInfo', userInfoListener);

    return () => {
      window.removeEventListener('userInfo', userInfoListener);
    };
  }, [updateInfo]);

  useEffect(() => {
    const init = async () => {
      await i18nInit();
      bootstrap(getBootstrapConfig());
      setRender(true);
    };
    init();
  }, []);

  return render ? (
    <>
      <Routes />
      <Toast />
      <Loader loading={appLoading} />
    </>
  ) : null;
}

export default App;
