import { HTMLAttributes, memo, useMemo } from 'react';
import { usePageInfo } from '@hooks/usePageInfo';
import { useApplication } from '@hooks/useApplication';
import { useUserInfo } from '@hooks/useUserInfo';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { Preview } from './components/Preview';
import { BaseHeader } from '../Header';
import { Back } from './components/Back';
import { Logo } from './components/Logo';
import { PageName } from './components/PageName';
import { UndoRedo } from './components/UndoRedo';
import { SavingStatus } from './components/SavingStatus';
import { TemplateEnabledSwitch } from './components/TemplateEnabledSwitch';
import { Publish } from './components/Publish';
import { PreviewDraft } from './components/PreviewDraft';
import { BackToEditButton } from './components/BackToEditButton';
import { SelectModelButton } from './components/SelectModelButton';

export type EditorHeaderProps = HTMLAttributes<HTMLDivElement> & {
  isTemplatePreview?: boolean;
};

const EditorHeaderComponent: React.FC<EditorHeaderProps> = ({
  isTemplatePreview = false,
  ...attrs
}) => {
  const { mode, published, createdByFunnel } = usePageInfo();
  const { isPreviewMode } = useApplication();
  const { templateManagementEnabled } = useUserInfo();
  const { editFunnelPagesEnabled } = useFeatureFlags();

  const isTemplateManagementEnabled = useMemo(
    () => mode === 'template' && templateManagementEnabled,
    [mode, templateManagementEnabled]
  );

  const isCommonPage = useMemo(
    () => mode === 'page' && !createdByFunnel,
    [createdByFunnel, mode]
  );

  const isPublishedFunnelPage = useMemo(
    () => createdByFunnel && published,
    [createdByFunnel, published]
  );

  const renderLeftSlot = useMemo(
    () => (
      <>
        <Back isTemplatePreview={isTemplatePreview} />
        <Logo />
        <PageName />
      </>
    ),
    [isTemplatePreview]
  );

  const renderEditorRightSlot = useMemo(
    () =>
      createdByFunnel && !editFunnelPagesEnabled ? undefined : (
        <>
          {isPreviewMode ? (
            <BackToEditButton />
          ) : (
            <>
              <PreviewDraft />
              <UndoRedo />
              {isTemplateManagementEnabled && <TemplateEnabledSwitch />}
              <SavingStatus />
            </>
          )}
          {(isTemplateManagementEnabled ||
            isCommonPage ||
            isPublishedFunnelPage) && <Publish />}
        </>
      ),
    [
      createdByFunnel,
      editFunnelPagesEnabled,
      isCommonPage,
      isPreviewMode,
      isPublishedFunnelPage,
      isTemplateManagementEnabled,
    ]
  );
  const renderTemplatePreviewRightSlot = useMemo(
    () => <SelectModelButton />,
    []
  );
  return (
    <BaseHeader
      {...attrs}
      leftSlot={renderLeftSlot}
      centerSlot={<Preview />}
      centerSlotMaxWidth="132px"
      rightSlot={
        isTemplatePreview
          ? renderTemplatePreviewRightSlot
          : renderEditorRightSlot
      }
    />
  );
};

export const EditorHeader = memo(EditorHeaderComponent);
