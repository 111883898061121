import { rebrand } from '@config/rebrand';
import KlickHttp from '../klick-http';

type CreateFormParam = {
  name: string;
  fields: KlicksendFormField[];
  tag: number;
  dataInfo: string;
};

type UpdateFormParam = {
  id: string;
  singleOptIn: boolean;
  terms: boolean;
  fields?: KlicksendFormField[];
  name?: string;
  tag?: number;
};
export default class Klicksend {
  private static klickHttp = new KlickHttp();

  public static getForms(): Promise<KlicksendFormData> {
    return this.klickHttp.get<KlicksendFormData>(
      `${rebrand.urls.KLICKSEND_API_URL}/forms/?field=updated_at&order=desc&per_page=300`
    );
  }

  public static getFormById(id: string): Promise<KlicksendFormPreview> {
    return this.klickHttp.get<KlicksendFormPreview>(
      `${rebrand.urls.KLICKSEND_API_URL}/forms/${id}`
    );
  }

  public static getTags() {
    return this.klickHttp.get<KlicksendTagData>(
      `${rebrand.urls.KLICKSEND_API_URL}/tags/?field=updated_at&order=desc`
    );
  }

  public static createKlicksendTag(name: string) {
    return this.klickHttp.post<KlicksendTagPreview>(
      `${process.env.REACT_APP_KLICKSEND_API_URL}/tags`,
      {
        name,
      }
    );
  }

  public static async createForm({
    name,
    fields,
    tag,
    dataInfo,
  }: CreateFormParam) {
    return this.klickHttp.post<KlicksendFormPreview>(
      `${process.env.REACT_APP_KLICKSEND_API_URL}/forms`,
      {
        tag_id: tag,
        name,
        fields,
        gdpr_enabled: true,
        gdpr_info: {
          data_info: dataInfo,
          privacy_policy:
            'https://hotmart.com/pt-br/legal/privacidade-de-dados',
        },
      }
    );
  }

  public static updateForm({
    id,
    singleOptIn,
    terms,
    fields,
    tag,
    name,
  }: UpdateFormParam): Promise<KlicksendFormPreview> {
    return this.klickHttp.put<KlicksendFormPreview>(
      `${process.env.REACT_APP_KLICKSEND_API_URL}/forms/${id}`,
      {
        accept_terms: terms,
        single_optin: singleOptIn,
        fields,
        name,
        tag_id: tag,
      }
    );
  }
}
