import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/index';
import * as menuSlice from '@store/slices/menu';

export const useMenu = () => {
  const dispatch = useAppDispatch();

  const clearUrlParamForm = useCallback(() => {
    dispatch(menuSlice.clearUrlParamFormMenu());
  }, [dispatch]);

  const closeMenu = useCallback(() => {
    dispatch(menuSlice.closeMenu());
  }, [dispatch]);

  const goBack = useCallback(() => {
    dispatch(menuSlice.goBack());
  }, [dispatch]);

  const setUrlParamForm = useCallback(
    (config: Partial<UrlParamFormConfig>) => {
      dispatch(menuSlice.setUrlParamFormMenu(config));
    },
    [dispatch]
  );

  const setSelectedPopup = useCallback(
    (popup: Popup) => {
      dispatch(menuSlice.setPopupsMenu({ selectedPopup: popup }));
    },
    [dispatch]
  );

  const setActiveSubmenu = useCallback(
    (menuId: string) => {
      dispatch(menuSlice.setActiveSubmenu(menuId));
    },
    [dispatch]
  );

  const setActiveSectionTemplate = useCallback(
    (section: string) => {
      dispatch(menuSlice.setActiveSectionTemplate(section));
    },
    [dispatch]
  );

  const setActiveMenu = useCallback(
    (menu: string) => {
      dispatch(menuSlice.setActiveMenu(menu));
    },
    [dispatch]
  );

  const selectedUrlParam = useSelector(
    (state: State) => state.menu.urlParamFormMenu.selectedUrlParam
  );

  const selectedPopup = useSelector(
    (state: State) => state.menu.popupsMenu.selectedPopup
  );

  const urlParamMenuLoading = useSelector(
    (state: State) => state.menu.urlParamFormMenu.loading
  );

  const popupsMenuLoading = useSelector(
    (state: State) => state.menu.popupsMenu.loading
  );

  const submenuLoading = useSelector(
    (state: State) => state.menu.submenuLoading
  );

  const activeSectionTemplate = useSelector(
    (state: State) => state.menu.activeSectionTemplate
  );

  const { activeMenu, activeSubmenu } = useSelector((state: State) => ({
    activeMenu: state.menu.activeMenu,
    activeSubmenu: state.menu.activeSubmenu,
  }));

  return {
    activeMenu,
    activeSectionTemplate,
    activeSubmenu,
    popupsMenuLoading,
    selectedPopup,
    selectedUrlParam,
    submenuLoading,
    urlParamMenuLoading,
    clearUrlParamForm,
    closeMenu,
    goBack,
    setActiveMenu,
    setActiveSectionTemplate,
    setActiveSubmenu,
    setSelectedPopup,
    setUrlParamForm,
  };
};
