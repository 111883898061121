import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import Files from '@services/files';
import {
  deleteScript as removeScript,
  setPageScripts,
} from '@store/slices/pageScripts';
import { savePageMetadata } from '@store/thunks/pageInfo/savePageMetadata';

export const deleteScript = createAsyncThunk<
  void,
  ScriptFormConfig,
  {
    state: LocalState;
  }
>(
  'pageScripts/deleteScript',
  async ({ type, position, scriptSelected }, { getState, dispatch }) => {
    const {
      pageScripts: { head: currentHead, body: currentBody },
    } = getState();

    dispatch(removeScript({ type, position, scriptSelected }));

    const {
      pageScripts: { head, body },
      pageInfo: { uuid },
    } = getState();

    try {
      await Files.saveScriptsFile(uuid, { head, body }, true);

      await dispatch(savePageMetadata());
    } catch (error) {
      dispatch(setPageScripts({ head: currentHead, body: currentBody }));
      // todo - create error handling process
      const serializedError = error as SerializedError;
      throw serializedError;
    }
  }
);

export const deleteScriptBuilder = (
  builder: ActionReducerMapBuilder<PageScriptsState>
) => {
  builder.addCase(deleteScript.pending, (state: PageScriptsState) => {
    state.scriptForm.loading = true;
  });
  builder.addCase(deleteScript.fulfilled, (state: PageScriptsState) => {
    state.scriptForm.loading = false;
  });
  builder.addCase(deleteScript.rejected, (state: PageScriptsState) => {
    state.scriptForm.loading = false;
  });
};
