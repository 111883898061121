import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0;
`;

export const FieldsWrapper = styled.div`
  margin-bottom: 16px;
`;

export const FieldItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;
