import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.label`
  color: var(--gray-900);
`;

export const InputGroup = styled.div`
  position: relative;
`;

export const Input = styled.input`
  &.hot-form__input.hot-form__input--sm {
    padding-right: 32px;
  }
`;

export const Icon = styled.i`
  position: absolute;
  right: 8px;
  top: 6px;
  z-index: 10;
  color: var(--gray-500);
`;
