import styled, { css } from 'styled-components';

type StyledHotFormProps = {
  width?: string;
};

export const StyledHotForm = styled.div<StyledHotFormProps>`
  margin: 0;
  max-width: 100%;

  textarea {
    resize: none;
  }

  ${({ width }) => css`
    width: ${width || '300px'};
  `}
`;

export const Label = styled.label`
  display: flex;
  gap: 8px;

  hot-tooltip {
    display: inline-flex;
    align-items: center;
  }
`;

export const Helper = styled.div`
  color: var(--gray-500);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 8px;
`;
