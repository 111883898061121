import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';

export const updateMediaList = createAsyncThunk<
  OpenFolderResponse | Media[],
  void,
  {
    state: LocalState;
    fulfilledMeta: string[];
  }
>('mediaCenter/updateMediaList', async (_, { getState, fulfillWithValue }) => {
  const {
    mediaCenter: { breadcrumbs, search },
  } = getState();
  const currentFolder = [...breadcrumbs].pop()?.id.toString();
  try {
    if (currentFolder) {
      const response = await MediaCenter.openFolder(currentFolder);
      return fulfillWithValue(response, ['folder']);
    }
    if (search) {
      const response = await MediaCenter.getMediaBySearchString(search);
      return fulfillWithValue(response, ['media']);
    }
    const response = await MediaCenter.getRootMedia();
    return fulfillWithValue(response, ['media']);
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const updateMediaListBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(updateMediaList.pending, (state: MediaCenterState) => {
    state.loading = true;
  });
  builder.addCase(
    updateMediaList.fulfilled,
    (state: MediaCenterState, action) => {
      const responseType = action.meta[0];
      state.breadcrumbs =
        responseType === 'folder'
          ? (action.payload as OpenFolderResponse).breadcrumbs
          : [];
      state.media =
        responseType === 'folder'
          ? (action.payload as OpenFolderResponse).folderMedia
          : (action.payload as Media[]);
      state.loading = false;
    }
  );
  builder.addCase(updateMediaList.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
