import React, { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import folderImg from './assets/folder.svg';
import { MediaOptions } from '../MediaOptions';
import { Container, FolderImg, Progress } from './styles';
import { useMediaCenter } from '../../../../../../hooks/useMediaCenter';

export type MediaFolderProps = HTMLAttributes<HTMLDivElement> & {
  folder: UploadingMedia<Folder>;
  isSelectable?: boolean;
  onChangeCurrentFolderName?: (id: string) => void;
  onRemoveCurrentFolder?: (id: string) => void;
};

const MediaFolderComponent: React.FC<MediaFolderProps> = ({
  folder,
  isSelectable,
  onChangeCurrentFolderName,
  onRemoveCurrentFolder,
  ...attrs
}) => {
  const memoizedStatus = useMemo(() => {
    if (folder.loading) {
      return <FontAwesomeIcon icon={['fad', 'spinner-third']} spin />;
    }
    if (folder.error) {
      return <FontAwesomeIcon icon={['fal', 'times-circle']} />;
    }
    if (folder.uploaded) {
      return <FontAwesomeIcon icon={['fal', 'check-circle']} />;
    }
    return null;
  }, [folder.loading, folder.error, folder.uploaded]);

  const isUploading = useCallback(
    (media: UploadingMedia) => media.loading || media.uploaded || media.error,
    []
  );

  const { selectedMedia, openFolder } = useMediaCenter();

  const handleDoubleClick = useCallback(async () => {
    if (!isUploading(folder)) {
      openFolder(folder.id);
    }
  }, [folder, isUploading, openFolder]);

  const mediaKey = useMemo(
    () => `${folder.id}-${folder.type}`,
    [folder.id, folder.type]
  );

  const isSelectedFolder = useMemo(
    () => Boolean(selectedMedia[mediaKey]),

    [mediaKey, selectedMedia]
  );

  return (
    <Container
      onDoubleClick={handleDoubleClick}
      isSelectable={isSelectable}
      isSelectedFolder={isSelectedFolder}
      {...attrs}
    >
      <FolderImg src={folderImg} alt="folder" />
      {!isUploading(folder) && !isSelectable && (
        <MediaOptions
          media={folder}
          onChangeCurrentMedia={() => onChangeCurrentFolderName?.(folder.id)}
          onRemoveCurrentMedia={() => onRemoveCurrentFolder?.(folder.id)}
        />
      )}
      <Progress
        isLoading={folder.loading}
        isUploaded={folder.uploaded}
        isError={folder.error}
      >
        {memoizedStatus}
      </Progress>
    </Container>
  );
};

export const MediaFolder = memo(MediaFolderComponent);
