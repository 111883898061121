import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';

import { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { updateConfig } from '@store/slices/pageConfig';
import MediaCenter from '@services/mediaCenter';
import Toast from '@services/toast';
import Files from '@services/files';
import { useUserInfo } from '@hooks/useUserInfo';
import i18n from '@i18n/index';
import {
  Container,
  Title,
  Description,
  Preview,
  Image,
  ButtonIcon,
  Label,
  Input,
} from './styles';

export const ShareImage: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uuid = useSelector((state: State) => state.pageInfo.uuid);
  const shareImage = useSelector((state: State) => state.pageConfig.shareImage);

  const [loading, setLoading] = useState(false);
  const { isFreePlan, userMediaMaxSize } = useUserInfo();

  const handleShareImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { files } = target;

      if (!files) return;

      const file = files[0];

      target.value = '';

      if (!MediaCenter.isValidMedia(file, ['.jpg', '.png'])) {
        Toast.error('toastMessages.media.error.unsupportedFormat');
        return;
      }
      if (
        !MediaCenter.isValidMediaSize({
          size: file.size,
          isFreePlan,
        })
      ) {
        Toast.error(
          i18n.t('toastMessages.media.error.maximumSize', {
            count: userMediaMaxSize,
          })
        );
        return;
      }

      try {
        setLoading(true);
        const { url } = await MediaCenter.uploadMedia(file);
        const config = { shareImage: `https:${url}` };

        await Files.saveConfigFile(uuid, config);
        dispatch(updateConfig(config));

        Toast.success('toastMessages.general.success.saved');
      } catch {
        Toast.error('toastMessages.general.error.saved');
      } finally {
        setLoading(false);
      }
    },
    [dispatch, isFreePlan, userMediaMaxSize, uuid]
  );

  return (
    <Container {...attrs}>
      <Title>{t('seo.shareImage.title')}</Title>

      <Preview>
        {shareImage ? (
          <Image src={shareImage} alt={t('seo.shareImage.title')} />
        ) : (
          <div>
            <i className="fal fa-image" />
          </div>
        )}
      </Preview>

      <Label
        className={classNames('hot-button hot-button--sm hot-button--primary', {
          'hot-button--loading': loading,
        })}
      >
        <ButtonIcon className="fal fa-upload" />
        {t('seo.shareImage.uploadImage')}
        <Input
          type="file"
          name="file"
          id="file"
          aria-hidden
          accept="image/*"
          onChange={(event) => handleShareImageChange(event)}
        />
      </Label>

      <Description>{t('seo.shareImage.description')}</Description>
    </Container>
  );
};
