import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-file.css';

import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import JSZip from 'jszip';
import slugify from 'slugify';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import { Submenu } from '@components/Submenu';
import Files from '@services/files';
import { useAppDispatch } from '@store/index';
import { userThunks } from '@store/thunks';
import {
  Description,
  Divider,
  Export,
  ExportButton,
  FileInputContainer,
  FileInputLabel,
  Import,
  ImportButton,
} from './styles';
import { ImportModal } from './components/ImportModal';

const extensionRegex = /(\.[^/.]+$)/;

export type ImportExportSubmenuProps = HTMLAttributes<HTMLDivElement>;

export const ImportExportSubmenu: React.FC<ImportExportSubmenuProps> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isExporting, setIsExporting] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [filesToImport, setFilesToImport] = useState<ZipContent>();
  const [importErrorMessage, setImportErrorMessage] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');

  const pageUuid = useSelector((state: State) => state.pageInfo.uuid);
  const pageName = useSelector((state: State) => state.pageInfo.name);
  const pageConfig = useSelector((state: State) => state.pageConfig);
  const userInfo = useSelector((state: State) => state.userInfo);
  const importPageEnabled = useSelector(
    (state: State) => state.userInfo.permissions.importPageEnabled
  );

  const exportPageEnabled = useSelector(
    (state: State) => state.userInfo.permissions.exportPageEnabled
  );

  const metadata = useMemo<ZipMetadata>(
    () => ({
      importedFrom: {
        pageUuid,
        userPublicId: userInfo.userProfile.publicId,
      },
    }),
    [pageUuid, userInfo.userProfile.publicId]
  );

  const config = useMemo<Partial<PageConfigState>>(
    () => ({
      mobileFirst: pageConfig.mobileFirst,
      favicon: pageConfig.favicon,
      description: pageConfig.description,
      keywords: pageConfig.keywords,
      shareUrl: pageConfig.shareUrl,
      shareImage: pageConfig.shareImage,
      preventIndexation: pageConfig.preventIndexation,
      title: pageConfig.title,
      version: pageConfig.version,
    }),
    [pageConfig]
  );

  const downloadZipFile = useCallback((filename: string, content: string) => {
    const element = document.createElement('a');

    element.setAttribute('href', `data:application/zip;base64,${content}`);
    element.setAttribute('download', filename);
    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, []);

  const getZip = useCallback(async () => {
    const zip = new JSZip();
    const { page, fontsConfig = {} } =
      EditorEngineManager.getFinalFiles() || {};

    const zipContent: ZipContent = {
      page,
      config,
      fontsConfig,
      metadata,
    };

    if (!zipContent.metadata.importedFrom.userPublicId) {
      const publicId = await dispatch(userThunks.getPublicId()).unwrap();
      zipContent.metadata.importedFrom.userPublicId = publicId;
    }

    const { checksum } = await Files.generateChecksum(zipContent);
    zipContent.checksum = checksum;

    zip.file('klickpages.page', JSON.stringify(zipContent));

    return zip.generateAsync({ type: 'base64' });
  }, [config, dispatch, metadata]);

  const handleExport = useCallback(async () => {
    setIsExporting(true);

    const content = await getZip();
    const slug = slugify(pageName, { strict: true, lower: true });

    downloadZipFile(`klickpages-${slug}.pages`, content);
    setIsExporting(false);
  }, [downloadZipFile, getZip, pageName]);

  const isKlickpagesFile = useCallback((file: File) => {
    const match = file.name.match(extensionRegex);

    if (match) {
      const [, extension] = match;
      return extension === '.pages';
    }

    return false;
  }, []);

  const isValidKlickpagesFile = useCallback(async (zipContent: ZipContent) => {
    const {
      checksum,
      page,
      config: configImported,
      fontsConfig,
      metadata: metadataImported,
    } = zipContent;

    const { checksum: contentChecksum } = await Files.generateChecksum({
      page,
      config: configImported,
      fontsConfig,
      metadata: metadataImported,
    });

    return checksum === contentChecksum;
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setImportErrorMessage('');
      setSelectedFileName('');

      const { target } = event;
      const { files } = target;

      if (!files || !files.length) {
        target.value = '';
        return;
      }

      const file = files[0];

      target.value = '';

      if (!isKlickpagesFile(file)) {
        setImportErrorMessage('importPage.import.invalidFile');
        return;
      }

      const zip = new JSZip();
      const zipFiles = await zip.loadAsync(file, { base64: true });
      const zipContentInstance = zipFiles.files['klickpages.page'];

      if (!zipContentInstance) {
        setImportErrorMessage('importPage.import.invalidFile');
        return;
      }

      const zipContent = JSON.parse(
        await zipContentInstance.async('string')
      ) as ZipContent;

      const isValidChecksum = await isValidKlickpagesFile(zipContent);

      if (!isValidChecksum) {
        setImportErrorMessage('importPage.import.invalidFile');
        return;
      }

      setSelectedFileName(file.name);
      setFilesToImport(zipContent);
    },
    [isValidKlickpagesFile, isKlickpagesFile]
  );

  const handleImport = useCallback(() => {
    setImportModalIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setImportModalIsOpen(false);
    setSelectedFileName('');
    setFilesToImport(undefined);
  }, []);

  return (
    <Submenu title="sideMenu.settings.importPage" padding="16px 0px" {...attrs}>
      {importPageEnabled && (
        <Import>
          <Description>{t('importPage.import.description')}</Description>

          <FileInputContainer className="hot-form hot-form--custom hot-form--file">
            <input
              type="file"
              className={classNames('hot-form__input hot-form__input', {
                'is-invalid': importErrorMessage,
              })}
              id="upload-pages-file"
              onChange={handleFileChange}
            />
            <FileInputLabel
              className="hot-form__label"
              htmlFor="upload-pages-file"
            >
              {selectedFileName || t('importPage.import.selectFile')}
            </FileInputLabel>

            {importErrorMessage && (
              <div className="invalid-feedback">{t(importErrorMessage)}</div>
            )}
          </FileInputContainer>

          <ImportButton
            disabled={!selectedFileName}
            className={classNames(
              'hot-button hot-button--sm hot-button--primary',
              {
                'hot-button--disabled': !selectedFileName,
              }
            )}
            onClick={handleImport}
          >
            {t('importPage.import.button')}
          </ImportButton>
        </Import>
      )}

      {importPageEnabled && exportPageEnabled && <Divider />}

      {exportPageEnabled && (
        <Export>
          <Description>{t('importPage.export.description')}</Description>

          <ExportButton
            className={classNames(
              'hot-button hot-button--sm hot-button--primary',
              {
                'hot-button--loading': isExporting,
              }
            )}
            onClick={handleExport}
          >
            {t('importPage.export.button')}
          </ExportButton>
        </Export>
      )}

      {filesToImport && (
        <ImportModal
          isOpen={importModalIsOpen}
          onClose={handleCloseModal}
          onImportSuccess={handleCloseModal}
          filesToImport={filesToImport}
        />
      )}
    </Submenu>
  );
};
