import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkoutExtraReducers } from '@store/thunks/checkout';

export const checkoutInitialState: CheckoutState = {
  selectedOffer: '',
  selectedProduct: '',
  url: '',
  isProductListEmpty: true,
};

const checkout = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: {
    setSelectedOffer(state, action: PayloadAction<string>) {
      state.selectedOffer = action.payload;
    },
    setSelectedProduct(state, action: PayloadAction<string>) {
      state.selectedProduct = action.payload;
    },
    setUrl(state, action: PayloadAction<string>) {
      state.url = action.payload;
    },
  },
  extraReducers: (builder) => checkoutExtraReducers(builder),
});

export const { setSelectedOffer, setSelectedProduct, setUrl } =
  checkout.actions;

export default checkout.reducer;
