/* eslint-disable no-param-reassign */
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { produce } from 'immer';

type ModifyTemplateAttributes = {
  template: Template;
  uid: string;
  propName: string;
  newValue: string | number;
  newTabletValue?: string | number;
  newMobileValue?: string | number;
};

type RemoveChildByIdAttributes = {
  template: Template;
  parentUid: string;
  uid: string;
};

export const modifyTemplate = ({
  template,
  uid,
  propName,
  newValue,
  newTabletValue,
  newMobileValue,
}: ModifyTemplateAttributes) =>
  produce(template, (draft) => {
    const findAndModify = (obj: Template) => {
      if (obj.uid === uid) {
        obj[propName] = newValue;
        if (newTabletValue) {
          obj.tablet![propName] = newTabletValue;
        }
        if (newMobileValue) {
          obj.mobile![propName] = newMobileValue;
        }
        return;
      }

      if (obj.children && obj.children.length > 0) {
        obj.children.forEach((child) => findAndModify(child));
      }
    };

    findAndModify(draft);
  });

export const removeChildById = ({
  template,
  parentUid,
  uid,
}: RemoveChildByIdAttributes) =>
  produce(template, (draft) => {
    const findParentAndRemoveChild = (obj: Template) => {
      if (!obj.children || obj.children.length === 0) {
        return;
      }
      if (obj.uid === parentUid) {
        obj.children = obj.children.filter((child) => child.uid !== uid);
        return;
      }
      obj.children.forEach((child) => findParentAndRemoveChild(child));
    };

    findParentAndRemoveChild(draft);
  });
