import styled, { css } from 'styled-components';

export type StyledContainerProps = {
  displayMode: MediaCenterDisplayMode;
};

const containerModifiers = {
  submenu: css`
    position: fixed;
    left: 70px;
    width: 299px;
  `,
  modal: css`
    position: absolute;
    width: 100%;
    left: 0;
  `,
};

export const Container = styled.div<StyledContainerProps>`
  background: white;
  bottom: 0;
  border-top: 0.0625rem solid var(--gray-200);
  font-size: 12px;
  z-index: 5;

  ${({ displayMode }) => css`
    ${containerModifiers[displayMode]}
  `}
`;
