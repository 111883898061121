import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
`;

export const CloseIcon = styled.div`
  padding: 0 4.5px;
  display: flex;
  cursor: pointer;
  position: absolute;
  top: -16px;
  right: -16px;
  font-size: 24px;
  color: var(--gray-400);
`;

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 294px;
`;

export const CheckboxLabel = styled.label`
  color: var(--gray-500) !important;
`;

export const ActionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h6`
  font-weight: 300;
  font-size: var(--text-4);
  color: var(--gray-700);
  margin-bottom: 0;

  hot-tag {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-left: 8px;
  }
`;

export const ActionDescription = styled.div`
  flex: 1;
  color: var(--gray-500);
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

export const StartButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
`;
