import '@hotmart-org-ca/cosmos/dist/tooltip';

import { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useUseCases } from '@hooks/useUseCases';
import { Button, ButtonIcon, Step, Stepper, StyledFooter } from './styles';

export type FooterProps = HTMLAttributes<HTMLDivElement> & {
  currentStep: number;
  stepsAmount: number;
  backButtonConfig?: {
    label?: string;
    hide?: boolean;
  };
  nextButtonConfig?: {
    label?: string;
  };
  type: UseCaseType;
  progress?: UseCaseIFrameProgress;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  onPublishPage?: () => void;
  onSavePage?: () => void;
};

export const Footer: React.FC<FooterProps> = ({
  currentStep,
  stepsAmount,
  backButtonConfig = {
    hide: false,
    label: '',
  },
  nextButtonConfig = {
    label: '',
  },
  type,
  progress = {},
  onPreviousClick,
  onNextClick,
  onPublishPage,
  onSavePage,
}) => {
  const { t } = useTranslation();

  const { isPublishing, footerRightButton } = useUseCases();

  const showProgress = useMemo(() => Boolean(progress.show), [progress.show]);

  const leftButton = useMemo(
    () =>
      backButtonConfig.hide && currentStep < 1 ? null : (
        <Button
          onClick={isPublishing ? undefined : onPreviousClick}
          className={classNames('hot-button hot-button--secondary', {
            'hot-button--disabled': isPublishing,
          })}
        >
          {backButtonConfig.label ? (
            <span>{t(backButtonConfig.label)}</span>
          ) : (
            <>
              <span>
                <ButtonIcon icon="left" className="fal fa-arrow-left" />
              </span>
              <span>{t('pageWizard.footer.previousButton')}</span>
            </>
          )}
        </Button>
      ),
    [
      backButtonConfig.hide,
      backButtonConfig.label,
      currentStep,
      isPublishing,
      onPreviousClick,
      t,
    ]
  );

  const rightButtonAction = useMemo(() => {
    if (currentStep === stepsAmount - 1) {
      if (type.startsWith('funnel')) {
        return { text: t('pageWizard.footer.saveButton'), onClick: onSavePage };
      }
      return {
        text: t(nextButtonConfig.label || 'pageWizard.footer.publishButton'),
        onClick: onPublishPage,
      };
    }
    return { text: t('pageWizard.footer.nextButton'), onClick: onNextClick };
  }, [
    currentStep,
    stepsAmount,
    t,
    onNextClick,
    type,
    nextButtonConfig.label,
    onPublishPage,
    onSavePage,
  ]);

  const rightButton = useMemo(
    () => (
      <>
        {showProgress && progress.steps && progress.currentStep && (
          <Stepper>
            {Array.from(Array(progress.steps).keys()).map((step) => (
              <Step
                key={step}
                width={`${100 / (progress.steps as number)}%`}
                isFinished={step + 1 < (progress.currentStep as number)}
                isCurrent={step + 1 === (progress.currentStep as number)}
              />
            ))}
          </Stepper>
        )}
        <hot-tooltip
          position="top"
          content={t(footerRightButton.errorMessage || '')}
        >
          <Button
            onClick={
              !footerRightButton.disabled
                ? rightButtonAction.onClick
                : undefined
            }
            isDisabled={footerRightButton.disabled}
            className={classNames('hot-button hot-button--primary', {
              'hot-button--loading': isPublishing,
              'hot-button--disabled': footerRightButton.disabled,
            })}
          >
            <span>{rightButtonAction.text}</span>
            {currentStep < stepsAmount - 1 && (
              <span>
                <ButtonIcon icon="right" className="fal fa-arrow-right" />
              </span>
            )}
          </Button>
        </hot-tooltip>
      </>
    ),
    [
      showProgress,
      progress.steps,
      progress.currentStep,
      t,
      footerRightButton.errorMessage,
      footerRightButton.disabled,
      rightButtonAction.onClick,
      rightButtonAction.text,
      isPublishing,
      currentStep,
      stepsAmount,
    ]
  );

  return (
    <StyledFooter
      leftSlot={leftButton}
      rightSlot={rightButton}
      padding="0"
      fixed
    />
  );
};
