import { Template } from '@hotmart-org-ca/saas-pages-engine';
import clone from 'clone';
import i18next from 'i18next';
import { modifyTemplate, removeChildById } from '../../common/utils';

const getTitleText = (
  text: string,
  color: string,
  isMobile: boolean = false
) => {
  const tag = isMobile ? 'p' : 'h1';
  return `<${tag} style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: ${color}; font-size: ${
    isMobile ? '35' : '48'
  }px;">${text}</strong></${tag}>`;
};

const getSubtitleText = (
  text: string,
  color: string,
  isMobile: boolean = false
) =>
  `<p style="text-align: center;"><span style="color: ${color}; font-family: Montserrat, sans-serif; font-size: ${
    isMobile ? '18' : '20'
  }px;">${text}</span></p>`;

const getFooterText = (color: string, isMobile: boolean = false) =>
  `<p style="text-align: center;"><span style="color: ${color}; font-family: Montserrat, sans-serif; font-size: 12px;">${i18next.t(
    'pageWizard.funnelCaptureThanksPage.template.footer.copyright'
  )}</span></p><p style="text-align: center;"><span style="color: ${color}; font-family: Montserrat, sans-serif; font-size: ${
    isMobile ? '12' : '14'
  }px;">${i18next.t(
    'pageWizard.funnelCaptureThanksPage.template.footer.rights'
  )}</span></p>`;

const getModificationsConfigMap = (): ThanksPageModificationConfigMap => ({
  title: {
    propName: 'text',
    elementUid: 'ls-aYWkfcXAYXTWZ4bPkEyaNF',
    getText: (text, color) => getTitleText(text, color),
    getMobileText: (text, color) => getTitleText(text, color, true),
  },
  subtitle: {
    propName: 'text',
    elementUid: 'ls-pV1B8vXXj77Bm29Ciqd8uZ',
    getText: (text, color) => getSubtitleText(text, color),
    getMobileText: (text, color) => getSubtitleText(text, color, true),
  },
  buttonText: {
    propName: 'label',
    elementUid: 'ls-jxqkombS2N44cxakLUf4Vm',
  },
  buttonLink: {
    propName: 'href',
    elementUid: 'ls-jxqkombS2N44cxakLUf4Vm',
  },
  colors: [
    {
      property: 'backgroundColor',
      propName: 'background',
      elementUid: 'ls-36a90a00-49a6-11ed-8e02-c9471a46a5ea',
      defaultValue: '#FFFFFF',
    },
    {
      property: 'secondaryTextColor',
      propName: 'color',
      elementUid: 'ls-jxqkombS2N44cxakLUf4Vm',
      defaultValue: '#FFFFFF',
    },
    {
      property: 'primaryColor',
      propName: 'background',
      elementUid: 'ls-jxqkombS2N44cxakLUf4Vm',
      defaultValue: '#4CAF50',
    },
    {
      property: 'primaryColor',
      propName: 'background',
      elementUid: 'ls-g3suWvoKVRpYRczVLZWSZm',
      defaultValue: '#4CAF50',
    },
    {
      property: 'secondaryTextColor',
      propName: 'text',
      elementUid: 'ls-cgKcezRK2ZeM1msi71t7Ud',
      defaultValue: '#7B7A7A',
      getTextWithColor: (color) => getFooterText(color),
      getMobileTextWithColor: (color) => getFooterText(color, true),
    },
  ],
});

const configMapKeys = Object.keys(
  getModificationsConfigMap()
) as (keyof ThanksPageModificationConfigMap)[];

let defaultValues: ThanksPageProperties;

export const getDefaultProperties = (): ThanksPageProperties => {
  if (!defaultValues) {
    defaultValues = {
      pageName: i18next.t('pageWizard.funnelCaptureThanksPage.pageName'),
      hasButton: false,
      title: i18next.t('pageWizard.funnelCaptureThanksPage.template.title'),
      subtitle: i18next.t(
        'pageWizard.funnelCaptureThanksPage.template.subtitle'
      ),
      buttonText: i18next.t(
        'pageWizard.funnelCaptureThanksPage.template.buttonText'
      ),
      buttonLink: '',
      colors: {
        backgroundColor: '#FFFFFF',
        primaryTextColor: '#3a3a3a',
        primaryColor: '#4CAF50',
        secondaryTextColor: '#FFFFFF',
      },
    };
  }

  return defaultValues;
};

export const getTemplateWithProps = (
  template: Template,
  properties: ThanksPageProperties
) => {
  let updatedTemplate: Template = clone(template);
  const config = getModificationsConfigMap();

  configMapKeys.forEach((key) => {
    let storeValue;
    let newValue: string;
    let newTabletValue: string | undefined;
    let newMobileValue: string | undefined;

    if (key !== 'colors') {
      storeValue = properties[key];
      newValue = storeValue;
      const propConfig = config[key];

      if (propConfig.propName === 'text') {
        const value = (storeValue || '').toString();
        newValue =
          propConfig.getText?.(
            (newValue || '').toString(),
            properties.colors.primaryTextColor
          ) || storeValue;
        newTabletValue = propConfig.getTabletText?.(
          value,
          properties.colors.primaryTextColor
        );
        newMobileValue = propConfig.getMobileText?.(
          value,
          properties.colors.primaryTextColor
        );
      }
      updatedTemplate = modifyTemplate({
        template: updatedTemplate,
        uid: propConfig.elementUid,
        propName: propConfig.propName,
        newValue,
        newTabletValue,
        newMobileValue,
      });

      return;
    }

    config.colors.forEach((colorPropConfig) => {
      storeValue = (properties.colors[colorPropConfig.property] ||
        colorPropConfig.defaultValue) as string;
      newValue = storeValue;

      if (colorPropConfig.propName === 'text') {
        const value = (storeValue || '').toString();
        newValue = colorPropConfig.getTextWithColor!(newValue as string);
        newTabletValue = colorPropConfig.getTabletTextWithColor?.(value);
        newMobileValue = colorPropConfig.getMobileTextWithColor?.(value);
      }

      updatedTemplate = modifyTemplate({
        template: updatedTemplate,
        uid: colorPropConfig.elementUid,
        propName: colorPropConfig.propName,
        newValue,
        newTabletValue,
        newMobileValue,
      });
    });
  });

  if (!properties.hasButton) {
    updatedTemplate = removeChildById({
      template: updatedTemplate,
      parentUid: 'ls-36a90a02-49a6-11ed-8e02-c9471a46a5ea',
      uid: 'ls-jxqkombS2N44cxakLUf4Vm',
    });
  }

  return updatedTemplate;
};

const updateFunnelColors = (
  currentStep: number,
  properties: ThanksPageProperties
) => {
  if (currentStep === 0 && properties.colorPallete) {
    const updatedProperties = {
      ...properties,
      colors: {
        ...properties.colors,
        primaryColor: properties.colorPallete[1],
      },
    };

    return updatedProperties;
  }

  return properties;
};

export const getPropertiesBeforeInit = async (
  useCase: KlickartUseCasesData
) => {
  const updatedProperties = updateFunnelColors(
    useCase.currentStep,
    useCase.properties
  );

  return updatedProperties;
};
