import { rebrand } from '@config/rebrand';
import { useEffect } from 'react';

export const LogoutPage = () => {
  useEffect(() => {
    window.location.assign(`${rebrand.urls.KLICKART_URL}/crossapp/logout`);
  });

  return null;
};
