import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { dispatchOpenPopup, sleep } from '@common/utils';
import { useEngine } from '@hooks/useEngine';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import { getRootAddableTemplate } from '@components/AddToRootButton/helpers';
import { useHigherNameByElement } from '@hooks/useHigherNameByElement';
import { TemplateModelsModal } from '@components/TemplateModelsModal';
import {
  Container,
  Image,
  Title,
  Description,
  ActionsContainer,
  Button,
} from './styles';

export type PopupOptionsModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps;

const PopupOptionsModifierComponent: React.FC<PopupOptionsModifierProps> = ({
  uid,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { page, addElement, setActiveComponent, elementState } = useEngine({
    elementUid: uid,
  });

  const { higherName } = useHigherNameByElement('ls-popup');

  const [openModal, setOpenModal] = useState(false);
  const [showTemplateModelsModal, setShowTemplateModelsModal] = useState(false);

  const handleOpenModal = useCallback(async () => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleAddPopup = useCallback(async () => {
    if (!page) {
      return;
    }
    const popupId = addElement(
      getRootAddableTemplate({
        elementType: 'popup',
        metadataLabel: higherName,
      }),
      page.uid,
      page.children?.length || 0
    );

    handleCloseModal();
    await sleep(200);
    setActiveComponent({ uid: popupId, component: 'ls-popup' });
    dispatchOpenPopup(popupId);
  }, [addElement, handleCloseModal, higherName, page, setActiveComponent]);

  const handleOpenTemplatesModelModal = useCallback(() => {
    setShowTemplateModelsModal(true);
    handleCloseModal();
  }, [handleCloseModal]);

  useEffect(() => {
    if (elementState) {
      const { action } = elementState.props;

      const hasPopup = page?.children?.some(
        (item: any) => item.component === 'ls-popup'
      );
      if (action === 'popup-on-click' && !hasPopup) {
        handleOpenModal();
      }
    }
  }, [elementState, handleOpenModal, page, page?.children]);

  if (elementState?.props?.action !== 'popup-on-click') {
    return null;
  }

  return (
    <Container {...attrs}>
      <BaseModal
        fixedSize="502px"
        isOpen={openModal}
        header={
          <Image>
            <img src="/images/emptyPopup.svg" alt="alert" />
          </Image>
        }
        footerClass="modal-warning-footer"
        footer={
          <ActionsContainer>
            <Button
              className="hot-button hot-button--secondary"
              onClick={handleAddPopup}
            >
              {t('modifiers.popupOptions.emptyModal.addPopupButton')}
            </Button>
            <Button
              className="hot-button hot-button--secondary"
              onClick={handleOpenTemplatesModelModal}
            >
              {t('modifiers.popupOptions.emptyModal.popupModelsButton')}
            </Button>
          </ActionsContainer>
        }
        onClose={handleCloseModal}
      >
        <Title>{t('modifiers.popupOptions.emptyModal.title')}</Title>
        <Description>
          {t('modifiers.popupOptions.emptyModal.description')}
        </Description>
      </BaseModal>
      <TemplateModelsModal
        isOpen={showTemplateModelsModal}
        elementType="popup"
        activeElementAfterAdd
        onClose={() => setShowTemplateModelsModal(false)}
      />
    </Container>
  );
};

export const PopupOptionsModifier = memo(PopupOptionsModifierComponent);
