import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Adjustments: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M23.625 18H9V16.125C9 15.5156 8.48438 15 7.875 15H5.625C4.96875 15 4.5 15.5156 4.5 16.125V18H0.375C0.140625 18 0 18.1875 0 18.375V19.125C0 19.3594 0.140625 19.5 0.375 19.5H4.5V21.375C4.5 22.0312 4.96875 22.5 5.625 22.5H7.875C8.48438 22.5 9 22.0312 9 21.375V19.5H23.625C23.8125 19.5 24 19.3594 24 19.125V18.375C24 18.1875 23.8125 18 23.625 18ZM7.5 21H6V16.5H7.5V21ZM23.625 4.5H12V2.625C12 2.01562 11.4844 1.5 10.875 1.5H8.625C7.96875 1.5 7.5 2.01562 7.5 2.625V4.5H0.375C0.140625 4.5 0 4.6875 0 4.875V5.625C0 5.85938 0.140625 6 0.375 6H7.5V7.875C7.5 8.53125 7.96875 9 8.625 9H10.875C11.4844 9 12 8.53125 12 7.875V6H23.625C23.8125 6 24 5.85938 24 5.625V4.875C24 4.6875 23.8125 4.5 23.625 4.5ZM10.5 7.5H9V3H10.5V7.5ZM23.625 11.25H19.5V9.375C19.5 8.76562 18.9844 8.25 18.375 8.25H16.125C15.4688 8.25 15 8.76562 15 9.375V11.25H0.375C0.140625 11.25 0 11.4375 0 11.625V12.375C0 12.6094 0.140625 12.75 0.375 12.75H15V14.625C15 15.2812 15.4688 15.75 16.125 15.75H18.375C18.9844 15.75 19.5 15.2812 19.5 14.625V12.75H23.625C23.8125 12.75 24 12.6094 24 12.375V11.625C24 11.4375 23.8125 11.25 23.625 11.25ZM18 14.25H16.5V9.75H18V14.25Z"
      fill={color}
    />
  </StyledSVG>
);
