import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useAppDispatch } from '@store/index';
import * as applicationSlice from '@store/slices/application';

export const useApplication = () => {
  const dispatch = useAppDispatch();

  const setAppLoading = useCallback(
    (isAppLoading: boolean) => {
      dispatch(applicationSlice.setAppLoading(isAppLoading));
    },
    [dispatch]
  );

  const setIsPreviewMode = useCallback(
    (isPreviewMode: boolean) => {
      dispatch(applicationSlice.setIsPreviewMode(isPreviewMode));
    },
    [dispatch]
  );

  const appLoading = useSelector(
    (state: State) => state.application.appLoading
  );

  const isPreviewMode = useSelector(
    (state: State) => state.application.isPreviewMode
  );

  return { setAppLoading, setIsPreviewMode, appLoading, isPreviewMode };
};
