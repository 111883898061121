import React, { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LsEditorDraggable } from '@hotmart-org-ca/saas-pages-engine-react';
import { useHigherNameByElement } from '@hooks/useHigherNameByElement';
import { Icon } from '@components/Icon';
import {
  DraggableContent,
  Container,
  IconBox,
  Title,
  StyledLabel,
} from './styles';

export type DraggableItemProps =
  HTMLAttributes<HTMLLsEditorDraggableElement> & {
    element: AddableDraggableElement;
  };

const addableElementWithTooltip = [
  'addableSections',
  'addableRows',
  'addableColumns',
  'addableGroups',
  'addableTimer',
];

const DraggableElementComponent: React.FC<DraggableItemProps> = ({
  element,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { getHigherNameByElement } = useHigherNameByElement();

  const renderTooltip = useMemo(
    () => addableElementWithTooltip.includes(element.id),
    [element]
  );

  const getRootElementMetadata = useCallback(
    (id: string) => {
      if (id === 'addableSections') {
        return {
          label: getHigherNameByElement('ls-section'),
        };
      }

      if (id === 'addablePopups') {
        return {
          label: getHigherNameByElement('ls-popup'),
        };
      }

      return {};
    },
    [getHigherNameByElement]
  );

  const template = useMemo(
    () => ({
      ...element.template,
      metadata: getRootElementMetadata(element.id),
    }),
    [element.id, element.template, getRootElementMetadata]
  );

  return (
    <Container data-onboarding={element.onboardingKey}>
      {renderTooltip && (
        <StyledLabel
          infoTooltip={{
            title: `${`labelTooltip.${element.label}.title`}`,
            description: `${`labelTooltip.${element.label}.description`}`,
          }}
        />
      )}
      <LsEditorDraggable
        uid={element.id}
        key={element.name}
        groupName={element.name}
        template={template}
        {...attrs}
      >
        {element.isNewTag && (
          <hot-tag class="hot-tag--green">
            {t(`sideMenu.addElements.new`)}
          </hot-tag>
        )}

        <DraggableContent>
          <IconBox>
            <Icon name={element.icon} size={32} color="var(--gray-700)" />
          </IconBox>
          <Title>{t(element.label)}</Title>
        </DraggableContent>
      </LsEditorDraggable>
    </Container>
  );
};

export const DraggableElement = memo(DraggableElementComponent);
