import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const menuInitialState: MenuState = {
  history: [],
  activeSubmenu: '',
  activeMenu: '',
  activeSectionTemplate: '',
  urlParamFormMenu: {
    loading: false,
    selectedUrlParam: {
      id: '',
      name: '',
      value: '',
    },
  },
  popupsMenu: {
    loading: false,
    selectedPopup: {
      id: '',
      name: '',
      template: {},
    },
  },
  submenuLoading: false,
};

const menu = createSlice({
  name: 'menu',
  initialState: menuInitialState,
  reducers: {
    clearUrlParamFormMenu(state) {
      state.urlParamFormMenu = {
        loading: false,
        selectedUrlParam: {
          id: '',
          name: '',
          value: '',
        },
      };
    },
    closeMenu(state) {
      state.history = [];
      state.activeSubmenu = '';
      state.activeMenu = '';
      state.activeSectionTemplate = '';
    },
    setActiveSectionTemplate(state, action: PayloadAction<string>) {
      state.activeSectionTemplate = action.payload;
    },
    setActiveSubmenu(state, action: PayloadAction<string>) {
      state.history.push(state.activeSubmenu);
      state.activeSubmenu = action.payload;
    },
    setActiveMenu(state, action: PayloadAction<string>) {
      state.history = [];
      state.activeMenu = action.payload;
      state.activeSubmenu = action.payload;
    },
    goBack(state) {
      if (state.history.length) {
        state.activeSubmenu = state.history.pop() as string;
      } else {
        state.activeSubmenu = '';
        state.activeMenu = '';
      }
    },
    setUrlParamFormMenu(
      state,
      { payload }: PayloadAction<Partial<UrlParamFormConfig>>
    ) {
      state.urlParamFormMenu = { ...state.urlParamFormMenu, ...payload };
    },
    setPopupsMenu(state, { payload }: PayloadAction<Partial<PopupsMenu>>) {
      state.popupsMenu = { ...state.popupsMenu, ...payload };
    },
    setSubmenuLoading(state, { payload }: PayloadAction<boolean>) {
      state.submenuLoading = payload;
    },
  },
});

export const {
  clearUrlParamFormMenu,
  closeMenu,
  setActiveMenu,
  setActiveSubmenu,
  setActiveSectionTemplate,
  goBack,
  setUrlParamFormMenu,
  setPopupsMenu,
  setSubmenuLoading,
} = menu.actions;

export default menu.reducer;
