import i18next from 'i18next';
import { hexToRgb } from '@common/utils';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import Klicksend from '@services/klicksend';
import clone from 'clone';
import deepmerge from 'deepmerge';

type GetTextAttributes = {
  title: string;
  color: string;
  fontSize: number;
  tag?: 'p' | 'h1';
};

const getText = ({ title, color, fontSize, tag = 'p' }: GetTextAttributes) =>
  `<${tag} style="text-align: center; line-height: 1.15;"><strong style="color: ${color}; font-family: Montserrat, sans-serif; font-size: ${fontSize}px; background-color: rgba(0, 0, 0, 0);">${title}</strong></${tag}>`;

const getTextGdprForm = (color: string) =>
  `<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; color: ${color}; font-size: 14px;">${i18next.t(
    'pageWizard.publishLeadCapturePage.form.textGdpr'
  )}</span></p>`;

const getLinkGdprForm = (color: string) =>
  `<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 14px; color: ${color};">${i18next.t(
    'pageWizard.publishLeadCapturePage.form.linkGdpr'
  )}</strong></p>`;

const getFormButton = (
  buttonBackgroundColor: string,
  buttonColor: string,
  buttonText: string,
  defaultButton: string
) =>
  JSON.stringify({
    ...JSON.parse(defaultButton),
    color: buttonColor,
    background: buttonBackgroundColor,
    label: buttonText,
  });

const getFormFields = (newFields: any[], templateFields: string) => {
  const parsedFields = JSON.parse(templateFields);
  const test = JSON.stringify({
    ...parsedFields,
    fields: JSON.stringify([
      ...newFields.map((field) => ({
        ...field,
        label: i18next.t(`${field.label}`),
      })),
      {
        name: 'gdpr',
        type: 'gdpr',
        label: 'Concordo em receber os e-mails',
        value: 'Concordo em receber os e-mails',
        required: true,
      },
    ]),
  });

  return test;
};

export const getDefaultProperties = () => ({
  pageName: i18next.t('pageWizard.publishLeadCapturePage.header'),
  title: i18next.t('pageWizard.publishLeadCapturePage.title.defaultValue'),
  buttonText: i18next.t(
    'pageWizard.publishLeadCapturePage.buttonText.defaultValue'
  ),
  colorsConfig: [
    {
      id: 'sectionBackground',
      name: 'background',
      label: 'pageWizard.publishLeadCapturePage.colors.sectionBackground',
      color: '#4BAEC3',
    },
    {
      id: 'groupBackground',
      name: 'background',
      label: 'pageWizard.publishLeadCapturePage.colors.groupBackground',
      color: '#FFFFFF',
    },
    {
      id: 'textColor',
      name: 'color',
      label: 'pageWizard.publishLeadCapturePage.colors.textColor',
      color: '#7b7a7a',
    },
    {
      id: 'buttonBackground',
      name: 'background',
      label: 'pageWizard.publishLeadCapturePage.colors.buttonBackground',
      color: '#8BC34A',
    },
    {
      id: 'buttonColor',
      name: 'color',
      label: 'pageWizard.publishLeadCapturePage.colors.buttonColor',
      color: '#FFFFFF',
    },
  ],
  formName: i18next.t(
    'pageWizard.publishLeadCapturePage.form.name.placeholder'
  ),
  formFields: [
    {
      name: 'email',
      type: 'email',
      label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.email',
      value: '',
      required: true,
    },
    {
      name: 'first_name',
      type: 'text',
      label:
        'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.firstName',
      value: '',
      required: false,
    },
  ],
  formSigleOptIn: false,
  formTerms: false,
  formTag: undefined,
  personalizeTag: false,
});

export const getTemplateWithProps = (
  template: Template,
  properties: CaptureProperties
) => {
  const updatedTemplate = clone(template);
  const { colorsConfig, buttonText, title, formFields, formId } = properties;
  const { children } =
    updatedTemplate.children![0].children![0].children![0].children![0];
  const colors: Record<string, string> = colorsConfig.reduce(
    (acc, current) => ({ ...acc, [current.id]: current.color }),
    {}
  );
  const elements: Template[] = [];
  const defaultTitle = children!.find(
    (element) => element.component === 'ls-text'
  ) as Template;
  const defaultForm = children!.find(
    (element) => element.component === 'ls-klicksend-form'
  ) as Template;

  updatedTemplate.children![0].background = colors.sectionBackground;
  updatedTemplate.children![0].children![0].children![0].children![0].background =
    colors.groupBackground;

  const titleColor = hexToRgb(colors.textColor || '#FFFFFF');

  elements.push({
    ...defaultTitle,
    text: getText({
      title,
      color: titleColor,
      fontSize: 28,
      tag: 'h1',
    }),
    tablet: {
      ...defaultTitle.tablet,
      text: getText({
        title,
        color: titleColor,
        fontSize: 24,
      }),
    },
    mobile: {
      ...defaultTitle.mobile,
      text: getText({
        title,
        color: titleColor,
        fontSize: 16,
      }),
    },
  });
  elements.push({
    ...defaultForm,
    'text-gdpr': JSON.stringify({
      ...JSON.parse(defaultForm['text-gdpr']),
      text: getTextGdprForm(colors.textColor),
      mobile: { text: getTextGdprForm(colors.textColor) },
    }),
    'link-gdpr': JSON.stringify({
      ...JSON.parse(defaultForm['link-gdpr']),
      text: getLinkGdprForm(colors.textColor),
    }),
    button: getFormButton(
      colors.buttonBackground || '#8BC34A',
      colors.buttonColor || '#FFFFFF',
      buttonText,
      defaultForm.button
    ),
    checkbox: JSON.stringify({
      ...JSON.parse(defaultForm.checkbox),
      color: colors.textColor,
      'accent-color': colors.textColor,
      mobile: {
        ...JSON.parse(defaultForm.checkbox).mobile,
        color: colors.textColor,
      },
      tablet: {
        ...JSON.parse(defaultForm.checkbox).tablet,
        'accent-color': colors.textColor,
      },
    }),
    fields: getFormFields(formFields, defaultForm.fields),
    'form-id': formId || defaultForm['form-id'],
    'submit-link': formId
      ? `${process.env.REACT_APP_KLICKSEND_HANDLER_URL}/${formId}`
      : defaultForm['submit-link'],
  });

  updatedTemplate.children![0].children![0].children![0].children![0].children =
    elements;

  return updatedTemplate;
};

const getCheckboxLabelForm = () =>
  i18next.t('pageWizard.publishLeadCapturePage.form.checkboxLabel');

export const getTemplateBeforePublish = async (
  template: Template,
  properties: CaptureProperties
) => {
  const updatedTemplate = getTemplateWithProps(template, properties);
  const { formName, formSigleOptIn = false, formTerms = false } = properties;
  const { children } =
    updatedTemplate.children![0].children![0].children![0].children![0];

  const { fields } = children!.find(
    (element) => element.component === 'ls-klicksend-form'
  ) as Template;

  const parsedFields = JSON.parse(JSON.parse(fields).fields);

  const tag =
    properties.personalizeTag && properties.formTag
      ? +properties.formTag
      : (await Klicksend.createKlicksendTag(properties.pageName)).id;

  const formData = await Klicksend.createForm({
    name: formName,
    fields: parsedFields,
    dataInfo: i18next.t('pageWizard.publishLeadCapturePage.form.dataInfo'),
    tag,
  });

  if (formSigleOptIn && formTerms) {
    await Klicksend.updateForm({
      id: formData.id,
      singleOptIn: formSigleOptIn,
      terms: formTerms,
    });
  }

  const formTemplate =
    updatedTemplate.children![0].children![0].children![0].children![0]
      .children![1];

  const klicksendForm: Template = {
    ...formTemplate,
    'form-id': formData.id,
    'submit-link': `${process.env.REACT_APP_KLICKSEND_HANDLER_URL}/${formData.id}`,
    fields: JSON.stringify({
      ...JSON.parse(formTemplate.fields),
      fields: JSON.stringify(formData.fields),
    }),
    checkbox: JSON.stringify({
      ...JSON.parse(formTemplate.checkbox),
      label: getCheckboxLabelForm(),
    }),
  };

  updatedTemplate.children![0].children![0].children![0].children![0].children![1] =
    klicksendForm;

  return updatedTemplate;
};

const updateFunnelColors = (
  currentStep: number,
  properties: CaptureProperties
) => {
  if (currentStep === 0 && properties.colorPallete) {
    const updatedProperties = deepmerge(properties, {});

    const [firstColor, secondColor] = properties.colorPallete;
    updatedProperties.colorsConfig[0].color = firstColor;
    updatedProperties.colorsConfig[3].color = secondColor;

    return updatedProperties;
  }

  return properties;
};

const updateFunnelTitle = (
  currentStep: number,
  properties: CaptureProperties
) => {
  if (currentStep === 0 && properties.title) {
    return {
      ...properties,
      title: properties.title[0],
    };
  }

  return properties;
};

const updateFunnelForm = async (
  currentStep: number,
  properties: CaptureProperties
) => {
  if (currentStep === 0 && properties.formId) {
    const klicksendForm = await Klicksend.getFormById(properties.formId);

    if (
      JSON.stringify(properties.formFields) !==
      JSON.stringify(klicksendForm.fields)
    ) {
      return {
        ...properties,
        formId: klicksendForm.id,
        formName: klicksendForm.name,
        formFields: klicksendForm.fields,
        formSigleOptIn: klicksendForm.single_optin,
        formTerms: klicksendForm.accept_terms,
        formTag: klicksendForm.tag_id.toString(),
      };
    }
  }

  return properties;
};

export const getPropertiesBeforeInit = async (
  useCase: KlickartUseCasesData
) => {
  let updatedProperties = updateFunnelColors(
    useCase.currentStep,
    useCase.properties
  );
  updatedProperties = updateFunnelTitle(useCase.currentStep, updatedProperties);
  updatedProperties = await updateFunnelForm(
    useCase.currentStep,
    updatedProperties
  );

  return updatedProperties;
};
