import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { minify } from '@common/utils';
import { tooltipStyle } from '@styles/global';
import { LinkedSwitchInfo, SwitchLabel } from './styles';

type CosmosSwitchProps = HTMLAttributes<HTMLDivElement> & {
  isChecked: boolean;
  label: string;
  id: string;
  infoLink?: string;
  infoTooltip?: {
    displayMode?: 'hover' | 'always';
    title?: string;
    description?: string;
  };
  tooltipPosition?: 'top' | 'right';
  onSwitchChanged: (isChecked: boolean) => void;
};

const CosmosSwitchComponent: React.FC<CosmosSwitchProps> = ({
  isChecked,
  label,
  id,
  infoLink,
  infoTooltip,
  tooltipPosition = 'right',
  onSwitchChanged,
  ...attrs
}) => {
  const { t } = useTranslation();

  const getTooltipContent = useCallback(
    () =>
      minify(
        `<style>${tooltipStyle}</style>
        <div class="custom-tooltip__label">${t(`${infoTooltip!.title}`)}</div>
        <div class="custom-tooltip__description">${t(
          `${infoTooltip!.description}`
        )}</div>`
      ),
    [infoTooltip, t]
  );

  const renderTooltip = useCallback(() => {
    if (infoLink) {
      return (
        <LinkedSwitchInfo target="_blank" rel="noreferrer" href={infoLink}>
          <i className="fal fa-info-circle" />
        </LinkedSwitchInfo>
      );
    }
    return infoTooltip ? (
      <hot-tooltip content={getTooltipContent()} position={tooltipPosition}>
        <i className="fal fa-info-circle" />
      </hot-tooltip>
    ) : null;
  }, [getTooltipContent, infoLink, infoTooltip, tooltipPosition]);

  return (
    <div className="hot-form hot-form--custom hot-form--switch" {...attrs}>
      <input
        id={id}
        type="checkbox"
        className="hot-form__input"
        checked={isChecked}
        onChange={(event) => onSwitchChanged(event.target.checked)}
      />
      <SwitchLabel className="hot-form__label" htmlFor={id}>
        {t(label)}
        {renderTooltip()}
      </SwitchLabel>
    </div>
  );
};

export const CosmosSwitch = memo(CosmosSwitchComponent);
