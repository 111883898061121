import { getFontsConfig, getTemplate } from './default';
import { getClassicFontsConfig, getClassicTemplate } from './classic';
import {
  getDarkPrimaryFontsConfig,
  getDarkPrimaryTemplate,
} from './dark-primary';
import {
  getDarkSecondaryFontsConfig,
  getDarkSecondaryTemplate,
} from './dark-secondary';

const templatesByType = {
  default: {
    getTemplate,
    getFontsConfig,
  },
  classic: {
    getTemplate: getClassicTemplate,
    getFontsConfig: getClassicFontsConfig,
  },
  'dark-primary': {
    getTemplate: getDarkPrimaryTemplate,
    getFontsConfig: getDarkPrimaryFontsConfig,
  },
  'dark-secondary': {
    getTemplate: getDarkSecondaryTemplate,
    getFontsConfig: getDarkSecondaryFontsConfig,
  },
};

export const getTemplateByType = (type: SalesPageTemplateType = 'default') =>
  templatesByType[type].getTemplate();

export const getFontsConfigByType = (type: SalesPageTemplateType = 'default') =>
  templatesByType[type].getFontsConfig();
