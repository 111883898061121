import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from '@components/Block';
import debounce from 'lodash.debounce';
import { useUseCases } from '@hooks/useUseCases';
import { Inputs, StyledTextInput } from './styles';

type WriteAboutValues = {
  title: string;
  description: string;
};

type WriteAboutComponentProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    author: AuthorProps;
    product: ProductProps;
  };

export const WriteAboutComponent: React.FC<WriteAboutComponentProps> = ({
  author,
  product,
  onModifierChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { setFooterRightButtonConfig } = useUseCases();
  const [productInfo, setProductInfo] = useState<WriteAboutValues>({
    title: '',
    description: '',
  });
  const [aboutAuthor, setAboutAuthor] = useState<string>('');
  const debouncedOnAuthorChange = useRef(debounce(onModifierChange, 500));
  const debouncedOnProductChange = useRef(debounce(onModifierChange, 500));

  const handleChange = useCallback(
    (
      e: React.ChangeEvent,
      inputName: keyof WriteAboutValues | 'aboutAuthor'
    ) => {
      const { value } = e.target as HTMLInputElement;

      if (inputName === 'aboutAuthor') {
        setAboutAuthor(value);

        return debouncedOnAuthorChange.current({
          prop: 'author',
          value: { ...author, description: value },
        });
      }

      const state = { ...productInfo, [inputName]: value };
      setProductInfo(state);

      return debouncedOnProductChange.current({
        prop: 'product',
        value: { ...product, ...state },
      });
    },
    [author, product, productInfo]
  );

  useEffect(() => {
    const disabled = Boolean(
      !product.description || !product.title || !author.description
    );

    setProductInfo({ description: product.description, title: product.title });
    setAboutAuthor(author.description);
    setFooterRightButtonConfig({
      disabled,
      errorMessage: disabled
        ? 'pageWizard.salesPage.paidWithoutProduct.writeAbout.errorMessage'
        : '',
    });
  }, [
    author.description,
    product.description,
    product.title,
    setFooterRightButtonConfig,
  ]);

  return (
    <Block
      label="pageWizard.salesPage.paidWithoutProduct.writeAbout.title"
      description="pageWizard.salesPage.paidWithoutProduct.writeAbout.description"
      width="100%"
      {...attrs}
    >
      <Inputs>
        {['title', 'description', 'aboutAuthor'].map((key) => {
          const inputName = key as keyof WriteAboutValues | 'aboutAuthor';

          return (
            <StyledTextInput
              key={`userTyped-${inputName}-text`}
              id={`userTyped-${inputName}-text`}
              name={`userTyped-${inputName}-text`}
              removeEmoji
              size="md"
              label={t(
                `pageWizard.salesPage.paidWithoutProduct.writeAbout.${
                  key === 'aboutAuthor' ? 'author' : 'product'
                }.${inputName}.label`
              )}
              placeholder={t(
                `pageWizard.salesPage.paidWithoutProduct.writeAbout.${
                  key === 'aboutAuthor' ? 'author' : 'product'
                }.${inputName}.placeholder`
              )}
              onChange={(e) => handleChange(e, inputName)}
              value={
                key === 'aboutAuthor'
                  ? aboutAuthor
                  : productInfo[inputName as keyof WriteAboutValues]
              }
              inputType={inputName === 'title' ? 'input' : 'textarea'}
            />
          );
        })}
      </Inputs>
    </Block>
  );
};

export const WriteAbout = memo(WriteAboutComponent);
