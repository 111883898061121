import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  min-height: 70px;
  position: sticky;
  z-index: 10;
  top: 0;
`;

export const TitleWrapper = styled.div`
  padding: 48px 40px 40px;
  width: 1460px;
  margin: 0 auto;

  @media screen and (max-width: 1461px) {
    width: 1296px;
  } ;
`;

type StyledMainProps = {
  hidePreview?: boolean;
  hideMenu?: boolean;
  hidePageTitle?: boolean;
  previewDevice?: UseCasePreviewDevice;
  isFirefox?: boolean;
};

const previewDeviceSize: Record<UseCasePreviewDevice, string> = {
  desktop: '520px',
  mobile: '375px',
};

const getGridTemplateAreas = (hidePreview?: boolean, hideMenu?: boolean) => {
  let templateAreas = 'modifiers';

  if (!hidePreview) {
    templateAreas = `${templateAreas} preview`;
  }

  if (!hideMenu) {
    templateAreas = `menu ${templateAreas}`;
  }

  return templateAreas;
};

const getGridTemplateColumns = (
  previewDevice: UseCasePreviewDevice,
  hidePreview?: boolean,
  hideMenu?: boolean
) => {
  let templateColumns = '1fr';

  if (!hidePreview) {
    templateColumns = `${templateColumns} ${previewDeviceSize[previewDevice]}`;
  }

  if (!hideMenu) {
    templateColumns = `200px ${templateColumns}`;
  }

  return templateColumns;
};

export const Main = styled.div<StyledMainProps>`
  max-width: 1460px;
  width: 100%;
  display: grid;
  gap: 36px;
  flex: 1;
  margin: 0 auto;
  > * {
    min-width: 0;
  }

  ${({ hidePageTitle, hidePreview, hideMenu, previewDevice = 'mobile' }) => css`
    padding: ${hidePageTitle ? '40px 40px 0' : '0 40px'};
    grid-template-columns: ${getGridTemplateColumns(
      previewDevice,
      hidePreview,
      hideMenu
    )};
    grid-template-areas: '${getGridTemplateAreas(hidePreview, hideMenu)}';
    ${hidePreview && 'gap: 104px;'}
  `}

  @media screen and (max-width: 1461px) {
    max-width: 1296px;
  } ;
`;

export const StepsMenuWrapper = styled.div`
  grid-area: menu;
`;

export const ModifiersWrapper = styled.div`
  grid-area: modifiers;
`;

type StyledPreviewWrapperProps = {
  isFirefox?: boolean;
  previewDevice?: UseCasePreviewDevice;
};

export const PreviewWrapper = styled.div<StyledPreviewWrapperProps>`
  grid-area: preview;

  ${({ isFirefox, previewDevice }) => css`
    ${isFirefox && previewDevice === 'desktop' && 'height: 388px;'}
  `}
`;

export const PreviewTitle = styled.div`
  color: var(--gray-900);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 16px;
`;
