import { createAsyncThunk } from '@reduxjs/toolkit';
import UseCases from '@services/useCases';
import { AppError } from '@common/app-error';
import { setCurrentStep, setProperties } from '@store/slices/useCases';
import { validateEmoji } from '@common/utils';

export const saveUseCase = createAsyncThunk<
  void,
  {
    id: number;
    properties?: any;
    currentStep?: number;
  },
  {
    state: LocalState;
    rejectedMeta: string[];
  }
>(
  'useCases/saveUseCase',
  async (
    { id, properties: props, currentStep },
    { getState, dispatch, rejectWithValue }
  ) => {
    const { useCases } = getState();
    const properties = { ...useCases.properties, ...props };
    const step =
      currentStep !== undefined
        ? currentStep
        : (useCases.currentStep as number);

    try {
      validateEmoji(properties);
      dispatch(setCurrentStep(step));
      dispatch(setProperties(properties));
      await UseCases.update(id, { properties, currentStep });
    } catch (e) {
      const error = e as Error;

      if (error instanceof AppError) {
        throw rejectWithValue(error.message, [error.message]);
      }

      const appError = new AppError('toastMessages.general.error.saved');
      throw rejectWithValue(appError.message, [appError.message]);
    }
  }
);
