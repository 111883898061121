import { rebrand } from '@config/rebrand';

type UseCaseRedirectTarget = '_self' | '_blank';

const redirectParamByType: Record<UseCaseType, string> = {
  publish_page: '?first_page=true',
  publish_lead_capture_page: '?first_capturepage=true',
  sales_page_paid_with_product: '?salespage=true',
  sales_page_paid_without_product: '?salespage=true',
  funnel_capture_publish_lead_capture_page: '?funnel=true',
  funnel_capture_thanks_page: '?funnel=true',
  hotmart_journey_sales_page_with_product: '?hotmart-journey=true',
};

const redirectToAudience = (target: UseCaseRedirectTarget = '_self') => {
  window.open(
    `${process.env.REACT_APP_HOTMART_URL}/tools/list/producer/talk_audience`,
    target
  );
};

const redirectToSend = (
  type: UseCaseType,
  isPublished?: Boolean,
  target: UseCaseRedirectTarget = '_self'
) => {
  window.open(
    `${rebrand.urls.KLICKSEND_URL}${
      isPublished ? redirectParamByType[type] : ''
    }`,
    target
  );
};

const redirectToPageManager = (
  type: UseCaseType,
  isPublished?: Boolean,
  target: UseCaseRedirectTarget = '_self'
) => {
  window.open(
    `${rebrand.urls.PAGE_MANAGER_URL}${
      isPublished ? redirectParamByType[type] : ''
    }`,
    target
  );
};

export const redirectBySource: Record<string, (...args: any[]) => void> = {
  pages: redirectToPageManager,
  send: redirectToSend,
  audience_page: redirectToAudience,
  default: redirectToAudience,
};

export const redirectToCaptureFunnel = () =>
  window.open(`${rebrand.urls.PAGE_MANAGER_URL}/funnels/capture`, '_self');

export const redirectToSource = (source: string | null) => {
  if (source) {
    const redirect = redirectBySource[source];

    if (redirect) {
      redirect();
      return;
    }
  }

  redirectBySource.default();
};
