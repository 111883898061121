import styled, { css } from 'styled-components';

type StyledUrlProps = {
  isPublished?: boolean;
};

export const Container = styled.div``;

export const Description = styled.label`
  color: var(--gray-500);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Button = styled.button`
  margin-right: 8px;
`;

export const ContainerLink = styled.div<StyledUrlProps>`
  margin-top: 12px;
  margin-bottom: 16px;
  display: block;

  ${({ isPublished }) => css`
    ${!isPublished &&
    css`
      cursor: not-allowed;
    `}
  `}
`;

export const Link = styled.a<StyledUrlProps>`
  font-size: 16px;
  word-break: break-word;

  ${({ isPublished }) => css`
    color: ${isPublished ? 'var(--blue)' : 'var(--gray-500)'};
    ${!isPublished && 'pointer-events: none'};
  `}
`;

export const ExternalLink = styled.a<StyledUrlProps>`
  color: inherit;

  &:hover {
    color: inherit;
  }

  ${({ isPublished }) => css`
    ${!isPublished &&
    css`
      pointer-events: none;
    `}
  `}
`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  height: 22px;

  hot-tag {
    font-size: 12px;
  }

  :hover {
    hot-tooltip {
      display: inline-block;
    }
  }
`;
