import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';

import { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Toast from '@services/toast';
import { Submenu } from '@components/Submenu';
import { useMenu } from '@hooks/useMenu';
import { usePageConfig } from '@hooks/usePageConfig';
import { containInvalidEmoji, isInvalidEmoji } from '@common/utils';
import { Button, Description, ErrorMessage, Label } from './styles';

export type UrlParamsProps = {
  name?: boolean;
  value?: boolean;
};

export const UrlParamsFormSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();

  const { goBack, selectedUrlParam, urlParamMenuLoading } = useMenu();

  const { findUrlParamByName } = usePageConfig();

  const { saveUrlParam } = usePageConfig();

  const [urlParam, setUrlParam] = useState(selectedUrlParam);
  const [invalidName, setInvalidName] = useState('');
  const [invalidValue, setInvalidValue] = useState(false);
  const [invalidEmoji, setInvalidEmoji] = useState<UrlParamsProps>({});

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      type: 'name' | 'value'
    ) => {
      if (type === 'name') {
        setInvalidName('');
      } else {
        setInvalidValue(false);
      }

      setInvalidEmoji((state) => ({
        ...state,
        [type]: isInvalidEmoji(event.target.value),
      }));

      setUrlParam({
        ...urlParam,
        [type]: event.target.value,
      });
    },
    [urlParam]
  );

  const isValidForm = useCallback(() => {
    let isValidName = true;
    let isValidEmoji = true;

    if (!urlParam.name) {
      setInvalidName('required');
      isValidName = false;
    }

    if (findUrlParamByName(urlParam.name) && isValidName) {
      setInvalidName('repeated');
      isValidName = false;
    }

    setInvalidValue(!urlParam.value);

    if (containInvalidEmoji(invalidEmoji)) {
      Toast.error('toastMessages.general.error.saved');
      isValidEmoji = false;
    }

    return isValidName && urlParam.value && isValidEmoji;
  }, [findUrlParamByName, invalidEmoji, urlParam.name, urlParam.value]);

  const handleSave = useCallback(async () => {
    try {
      if (!isValidForm()) {
        return;
      }

      await saveUrlParam(urlParam);
      goBack();

      Toast.success('toastMessages.general.success.saved');
    } catch (error) {
      Toast.error('toastMessages.general.error.saved');
    }
  }, [goBack, isValidForm, saveUrlParam, urlParam]);

  return (
    <Submenu title="sideMenu.settings.scripts" {...attrs}>
      <div className="hot-form">
        <Label className="hot-form__label" htmlFor="name">
          {t('urlParams.form.name.label')}
        </Label>

        <input
          id="name"
          className={classNames('hot-form__input hot-form__input--sm', {
            'is-invalid': invalidName || invalidEmoji.name,
          })}
          value={urlParam.name}
          onChange={(e) => handleInputChange(e, 'name')}
        />

        {invalidEmoji.name && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}

        {invalidName && (
          <ErrorMessage className="invalid-feedback">
            {t(`urlParams.form.name.${invalidName}`)}
          </ErrorMessage>
        )}
        <Description>{t('urlParams.form.name.description')}</Description>
      </div>

      <div className="hot-form">
        <Label className="hot-form__label" htmlFor="value">
          {t('urlParams.form.value.label')}
        </Label>

        <input
          id="value"
          className={classNames('hot-form__input hot-form__input--sm', {
            'is-invalid': invalidValue || invalidEmoji.value,
          })}
          value={urlParam.value}
          onChange={(e) => handleInputChange(e, 'value')}
        />
        {invalidEmoji.value && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}

        {invalidValue && (
          <ErrorMessage className="invalid-feedback">
            {t('urlParams.form.value.required')}
          </ErrorMessage>
        )}
      </div>

      <Button
        className={classNames('hot-button hot-button--sm hot-button--primary', {
          'hot-button--loading': urlParamMenuLoading,
        })}
        onClick={handleSave}
      >
        <span>{t('scripts.form.save')}</span>
      </Button>
    </Submenu>
  );
};
