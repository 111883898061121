import { rebrand } from '@config/rebrand';

export const START_ONBOARDING_EVENT_NAME = 'start-onboarding';

export const PUBLISH_PAGE_ROUTE_PREFIX = '/link-page/crie-sua-pagina';

export const PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX =
  '/capture/crie-sua-pagina';

export const SALES_PAGE_PAID_WITH_PRODUCT_ROUTE_PREFIX =
  '/sales-paid-with-product/crie-sua-pagina';

export const SALES_PAGE_PAID_WITHOUT_PRODUCT_ROUTE_PREFIX =
  '/sales-paid-without-product/crie-sua-pagina';

export const FUNNEL_CAPTURE_PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX =
  '/funnel-capture/capture/crie-sua-pagina';

export const FUNNEL_CAPTURE_THANKS_PAGE_ROUTE_PREFIX =
  '/funnel-capture/thanks/crie-sua-pagina';

export const HOTMART_JOURNEY_SALES_PAGE_WITH_PRODUCT_PREFIX =
  '/hotmart-journey/sales-page-with-product/crie-sua-pagina';

export const EDITOR_PAGE_LIST_URL = `${rebrand.urls.PAGE_MANAGER_URL}/new-editor`;

export const CAPTURE_FUNNEL_HOME_URL = `${rebrand.urls.PAGE_MANAGER_URL}/funnels/capture`;

export const LOGOUT_PATH = `/auth/logout`;

export const EDITOR_APP_URL = rebrand.urls.EDITOR_URL;

export const USE_CASE_IFRAME_CONFIG: UseCaseIFrameConfig = {
  header: true,
  progress: {},
  modal: {},
  nextButton: {},
  backButton: {},
};
