import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Paragraph: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="25"
    height="29"
    viewBox="0 0 25 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M24.5 0.0600586H11H10.9375C5.4375 0.0600586 0.9375 4.56006 0.9375 10.0601C0.9375 10.2476 1 10.5601 1 10.7476C1.3125 16.0601 6 20.0601 11.3125 20.0601H13V27.5601C13 27.8726 13.1875 28.0601 13.5 28.0601H14.5C14.75 28.0601 15 27.8726 15 27.5601V2.06006H19V27.5601C19 27.8726 19.1875 28.0601 19.5 28.0601H20.5C20.75 28.0601 21 27.8726 21 27.5601V2.06006H24.5C24.75 2.06006 25 1.87256 25 1.56006V0.560059C25 0.310059 24.75 0.0600586 24.5 0.0600586ZM13 18.0601H11C6.5625 18.0601 3 14.4976 3 10.0601C3 5.68506 6.5625 2.06006 11 2.06006H13V18.0601Z"
      fill={color}
    />
  </StyledSVG>
);
