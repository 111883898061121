import { useSelector } from 'react-redux';

export const useFeatureFlags = () => {
  const transparentCheckoutEnabled = useSelector((state: State) =>
    Boolean(state.userInfo.featureFlags.transparentCheckoutEnabled)
  );

  const trialEventsEnabled = useSelector((state: State) =>
    Boolean(state.userInfo.featureFlags.trialEventsEnabled)
  );

  const rewriteTextEnabled = useSelector((state: State) =>
    Boolean(state.userInfo.featureFlags.rewriteTextEnabled)
  );

  const userAbTest = useSelector((state: State) =>
    Boolean(state.userInfo.featureFlags.userAbTest)
  );

  const zendeskMessengerEnabled = useSelector((state: State) =>
    Boolean(state.userInfo.featureFlags.zendeskMessengerEnabled)
  );

  const editFunnelPagesEnabled = useSelector((state: State) =>
    Boolean(state.userInfo.featureFlags.editFunnelPagesEnabled)
  );

  return {
    transparentCheckoutEnabled,
    rewriteTextEnabled,
    trialEventsEnabled,
    userAbTest,
    zendeskMessengerEnabled,
    editFunnelPagesEnabled,
  };
};
