import {
  HtmlHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import debounce from 'lodash.debounce';
import { Block } from '@components/Block';
import { TextInput } from '@components/TextInput';
import { AiButtonWrapper } from './styles';

type DescriptionInputValue = {
  description: string;
};

type DescriptionInputProps = HtmlHTMLAttributes<HTMLDivElement> &
  DescriptionInputValue & {
    onAiButtonClicked: () => void;
    onTextChanged: (value: string) => void;
  };

const DescriptionInputComponent: React.FC<DescriptionInputProps> = ({
  onTextChanged,
  description,
  onAiButtonClicked,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [valueDescription, setValueDescription] = useState(description);
  const debouncedOnValueChange = useRef(debounce(onTextChanged, 500));

  const handleChange = useCallback((e: React.ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setValueDescription(value);

    debouncedOnValueChange.current(value);
  }, []);

  useEffect(() => {
    setValueDescription(description);
  }, [description]);

  return (
    <Block
      label={t(
        'pageWizard.salesPage.paidWithProduct.description.descriptionTitle'
      )}
      showSeparator
      {...attrs}
    >
      <TextInput
        removeEmoji
        inputType="textarea"
        id="description"
        name="description"
        width="100%"
        size="lg"
        placeholder={t(
          'pageWizard.salesPage.paidWithProduct.description.descriptionPlaceholder'
        )}
        rows={6}
        value={valueDescription}
        onChange={(e) => handleChange(e)}
      />
      <AiButtonWrapper>
        <CosmosButton
          label={t(
            'pageWizard.salesPage.paidWithProduct.description.aiButtonLabel'
          )}
          id="ai-button"
          iconClass="fal fa-bolt"
          iconSide="right"
          type="secondary"
          onButtonClicked={onAiButtonClicked}
          size="md"
        />
      </AiButtonWrapper>
    </Block>
  );
};

export const DescriptionInput = memo(DescriptionInputComponent);
