import React, { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setScriptForm } from '@store/slices/pageScripts';
import { setActiveSubmenu } from '@store/slices/menu';
import { Submenu } from '@components/Submenu';
import {
  AddButton,
  AddIcon,
  Description,
  EmptyList,
  IconGroup,
  Link,
  Script,
  ScriptList,
  ScriptName,
} from './styles';
import { RemoveScriptModal } from './components/RemoveScriptModal';
import { ScriptsSwitch } from './components/ScriptsSwitch';

export type ScriptsSubmenuProps = HTMLAttributes<HTMLDivElement>;

export const ScriptsSubmenu: React.FC<ScriptsSubmenuProps> = ({ ...attrs }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stateScripts = useSelector((state: State) => state.pageScripts);

  const selectedType = useMemo(
    () => stateScripts.scriptForm.type,
    [stateScripts.scriptForm.type]
  );
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [currentScript, setCurrentScript] = useState<ScriptFormConfig>({
    type: 'head',
    position: 'end',
    scriptSelected: {
      id: '',
      name: '',
      script: '',
    },
  });

  const scriptList = useMemo(
    () => [
      ...stateScripts[selectedType].start,
      ...stateScripts[selectedType].end,
    ],
    [stateScripts, selectedType]
  );

  const handleAddScript = useCallback(() => {
    dispatch(
      setScriptForm({
        type: selectedType,
        position: 'end',
        scriptSelected: {
          id: '',
          name: '',
          script: '',
        },
      })
    );
    dispatch(setActiveSubmenu('scripts-form'));
  }, [dispatch, selectedType]);

  const handleEditScript = useCallback(
    (script: PageScript) => {
      dispatch(
        setScriptForm({
          type: selectedType,
          position: 'end',
          scriptSelected: script,
        })
      );
      dispatch(setActiveSubmenu('scripts-form'));
    },
    [dispatch, selectedType]
  );

  const handleRemoveScript = useCallback(
    (script: PageScript) => {
      setCurrentScript({
        type: selectedType,
        position: 'end',
        scriptSelected: script,
      });
      setShowRemoveModal(true);
    },
    [selectedType]
  );

  const handleSwitchClick = useCallback(
    (type: ScriptType) => {
      dispatch(
        setScriptForm({
          type,
          position: 'end',
        })
      );
    },
    [dispatch]
  );

  return (
    <Submenu title="sideMenu.settings.scripts" {...attrs}>
      <ScriptsSwitch
        selected={stateScripts.scriptForm.type}
        onItemClick={(item) => handleSwitchClick(item)}
      />

      <Description>
        <span>
          {t('scripts.description')} <Link href="/" text={t('scripts.info')} />
        </span>
      </Description>

      <AddButton
        className="hot-button hot-button--secondary hot-button--sm"
        onClick={handleAddScript}
      >
        <AddIcon className="fal fa-plus" />
        <span>{t('scripts.addScript', { type: selectedType })}</span>
      </AddButton>

      <ScriptList>
        {scriptList.map((script) => (
          <Script key={script.id}>
            <ScriptName>{script.name}</ScriptName>

            <hot-tooltip position="top" content={t('scripts.editScript')}>
              <IconGroup
                className="icon-group"
                onClick={() => handleEditScript(script)}
              >
                <i className="fal fa-pen" />
              </IconGroup>
            </hot-tooltip>

            <hot-tooltip position="top" content={t('scripts.deleteScript')}>
              <IconGroup
                className="icon-group"
                onClick={() => handleRemoveScript(script)}
              >
                <i className="fal fa-trash-alt" />
              </IconGroup>
            </hot-tooltip>
          </Script>
        ))}
      </ScriptList>

      {!scriptList.length && <EmptyList>{t('scripts.emptyList')}</EmptyList>}

      <RemoveScriptModal
        isOpen={showRemoveModal}
        scriptFormConfig={currentScript}
        onClose={() => setShowRemoveModal(false)}
      />
    </Submenu>
  );
};
