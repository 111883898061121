import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-radio.css';
import '@hotmart-org-ca/cosmos/dist/table/table.css';
import '@hotmart-org-ca/cosmos/dist/table/variations/bordered.css';

import {
  ChangeEvent,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Pagination from '@pages/PageWizard/useCases/Sales/components/Pagination';
import {
  Container,
  InputGroup,
  InstallmentColumn,
  PriceColumn,
  RadioColumn,
  RadioGroup,
  RadioInput,
  RadioLabel,
  Icon,
} from './styles';

export type OfferTableProps = HTMLAttributes<HTMLDivElement> & {
  hideColumn?: boolean;
  itemsPerPage?: number;
  loading?: boolean;
  offers: HotmartProductOffer[];
  selectedOfferId?: string;
  searchBarSize?: string;
  showOfferKey?: boolean;
  onSelectOffer?: (updatedOffer: Partial<ProductProps>) => void;
};

export const OfferTable: React.FC<OfferTableProps> = ({
  itemsPerPage = 8,
  loading,
  offers,
  selectedOfferId,
  searchBarSize,
  showOfferKey = false,
  onSelectOffer,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOffers = useMemo(
    () =>
      offers.filter((offer) => {
        const { key, price } = offer;
        const searchTermLowerCase = searchTerm.toLowerCase();
        const columnsToSearch = [price];

        if (showOfferKey) {
          columnsToSearch.push(key);
        }

        return columnsToSearch.some((value) =>
          value.toLowerCase().includes(searchTermLowerCase)
        );
      }),
    [offers, searchTerm, showOfferKey]
  );
  const totalPages = useMemo(
    () => Math.ceil(filteredOffers.length / itemsPerPage),
    [filteredOffers.length, itemsPerPage]
  );
  const indexOfLastItem = useMemo(
    () => currentPage * itemsPerPage,
    [currentPage, itemsPerPage]
  );
  const indexOfFirstItem = useMemo(
    () => indexOfLastItem - itemsPerPage,
    [indexOfLastItem, itemsPerPage]
  );
  const currentItems = useMemo(
    () => filteredOffers.slice(indexOfFirstItem, indexOfLastItem),
    [indexOfFirstItem, indexOfLastItem, filteredOffers]
  );

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleSelectOffer = useCallback(
    (offer: HotmartProductOffer) => {
      onSelectOffer?.({
        offerId: offer.id,
        offerKey: offer.key,
        offerPrice: offer.price,
        offerLink: offer.link,
        offerMaxInstallments: offer.maxInstallments,
      });
    },
    [onSelectOffer]
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  }, []);

  return (
    <Container {...attrs}>
      <InputGroup searchBarSize={searchBarSize}>
        <input
          id="productSearch"
          className="hot-form__input hot-form__input--lg"
          placeholder={t(
            'pageWizard.salesPage.paidWithProduct.product.searchPlaceholder'
          )}
          type="text"
          onChange={handleSearch}
          data-prevent-delete
        />
        <Icon className="fal fa-search" />
      </InputGroup>

      {loading ? (
        <hot-loading />
      ) : (
        <table className="hot-table hot-table--bordered">
          <thead>
            <tr>
              <th> </th>
              {showOfferKey && (
                <th>
                  {t('pageWizard.salesPage.paidWithProduct.offer.keyColumn')}
                </th>
              )}
              <th>
                {t('pageWizard.salesPage.paidWithProduct.offer.priceColumn')}
              </th>
              <th>
                {t('pageWizard.salesPage.paidWithProduct.offer.paymentColumn')}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((offer) => (
              <tr key={offer.id}>
                <RadioColumn>
                  <RadioGroup className="hot-form hot-form--custom hot-form--radio">
                    <RadioInput
                      type="radio"
                      id={`selectOffer-${offer.id}`}
                      className="hot-form__input"
                      checked={selectedOfferId === offer.id}
                      onChange={() => handleSelectOffer(offer)}
                    />
                    <RadioLabel
                      className="hot-form__label"
                      htmlFor={`selectOffer-${offer.id}`}
                    />
                  </RadioGroup>
                </RadioColumn>
                {showOfferKey && (
                  <PriceColumn>
                    <label htmlFor={`selectOffer-${offer.id}`}>
                      {offer.key}
                    </label>
                  </PriceColumn>
                )}
                <PriceColumn>
                  <label htmlFor={`selectOffer-${offer.id}`}>
                    {offer.price}
                  </label>
                </PriceColumn>

                <InstallmentColumn>
                  <label htmlFor={`selectOffer-${offer.id}`}>
                    <Trans
                      i18nKey="pageWizard.salesPage.paidWithProduct.offer.paymentColumn"
                      components={{ b: <b /> }}
                      values={{ count: offer.maxInstallments }}
                    />
                  </label>
                </InstallmentColumn>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
