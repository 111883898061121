import PublicHttp from '@services/public-http';
import { removeEmojis, sleep } from '@common/utils';
import { AppError } from '@common/app-error';
import i18n from 'i18next';

type FromAIProps = {
  prompt: string;
  context: Record<string, string>;
  token?: CancelToken;
};

export type HotmartAIPromptResult = {
  uuid: string;
  text: string;
  executionTime: number;
};

export default class HotmartAIService {
  private static publicHttp = new PublicHttp();

  private static prefixByType = {
    object: '{"output": {',
  };

  private static maxRetryCount = 60;

  private static createCompletions(
    prompt: string,
    context: Record<string, string> = {},
    token?: CancelToken
  ) {
    return this.publicHttp.post<{ uuid: string }>(
      `${process.env.REACT_APP_HOTMART_AI_API}/v1/completions`,
      { prompt, context, cancelToken: token }
    );
  }

  public static async getFromAI<T>({
    prompt,
    context,
    token,
  }: FromAIProps): Promise<T> {
    const updatedContext = {
      ...Object.entries(context).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.replace(/"/g, '\\"') }),
        {}
      ),
      language: i18n.language,
    };

    const { uuid } = await this.createCompletions(
      prompt,
      updatedContext,
      token
    );

    const { text } = await this.getPromptResult(uuid, token);

    try {
      const prefix = this.prefixByType.object;
      const fixedText = text.startsWith(prefix) ? text : `${prefix}${text}`;

      const match = fixedText.match(/(\{.*?\}?\})/s);

      if (match) {
        const [output] = match;

        const fixedOutput = output.match(/}\s*}$/s) ? output : `${output}}`;
        return removeEmojis(JSON.parse(fixedOutput));
      }

      throw new AppError('hotmart_ai_invalid_json');
    } catch {
      throw new AppError('hotmart_ai_invalid_json');
    }
  }

  private static async getPromptResult(
    uuid: string,
    token?: CancelToken,
    retryCount: number = 1
  ): Promise<HotmartAIPromptResult> {
    if (retryCount > this.maxRetryCount) {
      throw new AppError('hotmart_ai_result_fail');
    }

    const result = await this.fetchPromptResult(uuid, token);

    if (!result) {
      await sleep(1000);
      return this.getPromptResult(uuid, token, retryCount + 1);
    }

    return result[0];
  }

  private static fetchPromptResult(uuid: string, token?: CancelToken) {
    return this.publicHttp.get<HotmartAIPromptResult[] | null>(
      `${process.env.REACT_APP_HOTMART_AI_API}/v1/completions?uuid=${uuid}`,
      { cancelToken: token }
    );
  }
}
