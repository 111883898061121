import { CosmosLoader } from '@components/CosmosLoader';
import styled from 'styled-components';

export const Container = styled.div``;

export const Loader = styled(CosmosLoader)`
  padding: 0 16px 16px;
`;

export const ContentWrapper = styled.div`
  padding: 0 16px;
`;

export const Divider = styled.hr`
  margin: 16px 0;
  display: block;
  width: 100%;
`;

export const LinkDescription = styled.p`
  font-size: 12px;
  color: var(--gray-500);
`;

export const Button = styled.button`
  background: transparent !important;
  border: none;
  color: var(--gray-700) !important;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-right: 4px;
  padding: 0;

  .spin {
    animation: spin 1s linear;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
