import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedPopups from '@services/savedPopups';

export const updateUserPopup = createAsyncThunk<
  void,
  Omit<PageFragmentListItem, 'template'>,
  {
    state: LocalState;
    SerializedError: {
      name: string;
      stack: string;
      code: string;
    };
  }
>('pageFragments/updateUserPopup', async ({ id, name }) => {
  try {
    await SavedPopups.update(id, { name });
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const updateUserPopupBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(updateUserPopup.pending, (state: PageFragmentsState) => {
    state.popupLoading = true;
  });
  builder.addCase(
    updateUserPopup.fulfilled,
    (state: PageFragmentsState, action) => {
      const { id, name } = action.meta.arg;
      const userPopups = state.userPopups.map((popup) => {
        if (popup.id === id) {
          return { ...popup, name };
        }
        return popup;
      });

      state.userPopups = userPopups;
      state.popupLoading = false;
    }
  );
  builder.addCase(updateUserPopup.rejected, (state: PageFragmentsState) => {
    state.popupLoading = false;
  });
};
