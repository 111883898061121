import { HTMLAttributes, memo, useCallback } from 'react';
import { Textbox } from '@pages/PageWizard/components/Textbox';
import { useTranslation } from 'react-i18next';

export type PurchaseButtonProps = HTMLAttributes<HTMLDivElement> & {
  updatedProduct: UpdatedProductProps;
  onButtonTextChange?: (offer: Partial<UpdatedProductProps>) => void;
};

const PurchaseButtonComponent: React.FC<PurchaseButtonProps> = ({
  updatedProduct,
  onButtonTextChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleButtonTextChange = useCallback(
    (value: string) => {
      onButtonTextChange?.({
        ...updatedProduct,
        purchaseButton: value,
      });
    },
    [onButtonTextChange, updatedProduct]
  );

  return (
    <Textbox
      id="purchase-button"
      name="purchase-button"
      value={
        updatedProduct.purchaseButton || t('pageWizard.salesPage.template.CTA')
      }
      label="pageWizard.salesPage.paidWithProduct.updateOffer.purchaseButton"
      onNameChange={handleButtonTextChange}
      showSeparator={false}
      {...attrs}
    />
  );
};

export const PurchaseButton = memo(PurchaseButtonComponent);
