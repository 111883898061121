import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';

import React, { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { Block } from '@components/Block';
import { useTranslation } from 'react-i18next';
import { Themes } from './components/Themes';
import { Theme, getThemeById } from './components/Themes/themes';
import { SalesUseCase } from '../../salesUseCase';
import { StyledColors } from './styles';

export type PersonalizeColorsProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    colors: SalesPageColors;
    useCase: SalesUseCase;
    width?: string;
    isReducedThemes?: boolean;
  };

const PersonalizeColorsComponent: React.FC<PersonalizeColorsProps> = ({
  colors: colorsProp,
  useCase,
  width = '',
  isReducedThemes = false,
  onModifierChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const { isCustomColors, selectedTheme, ...colors } = useMemo(
    () => colorsProp,
    [colorsProp]
  );

  const colorsConfig = useMemo(
    () =>
      Object.entries(colors).map(([key, value]) => ({
        id: key,
        name: key,
        label: `pageWizard.salesPage.paidWithProduct.personalize.${key}`,
        color: value,
      })),
    [colors]
  );

  const handleThemeChange = useCallback(
    (theme: Theme) => {
      useCase.modifyByCategory('primaryColor', theme.primaryColor);
      useCase.modifyByCategory('secondaryColor', theme.secondaryColor);

      onModifierChange({
        prop: 'colors',
        value: {
          ...colorsProp,
          selectedTheme: theme.id,
          primaryColor: theme.primaryColor,
          secondaryColor: theme.secondaryColor,
        },
      });
    },
    [colorsProp, onModifierChange, useCase]
  );

  const handleCustomColorsChange = useCallback(
    (checked: boolean) => {
      const value = { ...colorsProp, isCustomColors: checked };

      if (!checked) {
        const theme = getThemeById(selectedTheme);

        if (theme) {
          value.primaryColor = theme.primaryColor;
          value.secondaryColor = theme.secondaryColor;

          useCase.modifyByCategory('primaryColor', theme.primaryColor);
          useCase.modifyByCategory('secondaryColor', theme.secondaryColor);
        }
      }
      onModifierChange({ prop: 'colors', value });
    },
    [colorsProp, onModifierChange, selectedTheme, useCase]
  );

  const handleColorsChange = useCallback(
    (value: UseCaseColors[]) => {
      const updatedColors: { primaryColor?: string; secondaryColor?: string } =
        value.reduce(
          (acc, current) => ({ ...acc, [current.id]: current.color }),
          {}
        );

      if (updatedColors.primaryColor) {
        useCase.modifyByCategory('primaryColor', updatedColors.primaryColor);
      }

      if (updatedColors.secondaryColor) {
        useCase.modifyByCategory(
          'secondaryColor',
          updatedColors.secondaryColor
        );
      }

      onModifierChange({
        prop: 'colors',
        value: { ...colorsProp, ...updatedColors },
      });
    },
    [colorsProp, onModifierChange, useCase]
  );

  return (
    <Block
      label="pageWizard.salesPage.paidWithProduct.personalize.title"
      description="pageWizard.salesPage.paidWithProduct.personalize.description"
      width={width}
      {...attrs}
    >
      <Themes
        selectedTheme={selectedTheme}
        onThemeChange={handleThemeChange}
        disabled={isCustomColors}
        isReducedThemes={isReducedThemes}
      />

      <div className="hot-form hot-form--custom hot-form--switch">
        <input
          id="form-single-confirmation"
          type="checkbox"
          className="hot-form__input"
          checked={isCustomColors}
          onChange={(event) => handleCustomColorsChange(event.target.checked)}
        />
        <label className="hot-form__label" htmlFor="form-single-confirmation">
          {t('pageWizard.salesPage.paidWithProduct.personalize.customColors')}
        </label>
      </div>

      {isCustomColors && (
        <StyledColors
          colors={colorsConfig}
          onColorsChange={handleColorsChange}
        />
      )}
    </Block>
  );
};

export const PersonalizeColors = memo(PersonalizeColorsComponent);
