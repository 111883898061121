const vimeoRegExp = new RegExp(/.*?vimeo\.com.*/g);

const youTubeShortenedRegExp = new RegExp(/.*?youtu\.be.*/g);

const vimeoEmbedRegExp = new RegExp(/.*?player\.vimeo\.com.*/g);

const youTubeRegExp = new RegExp(/.*?youtube\.com.*/g);

const youTubeEmbedRegExp = new RegExp(/.*?youtube\.com\/embed.*/g);

const validUrlRegExp = new RegExp(
  /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/
);

const isVimeoSource = (src: string) => !!src.match(vimeoRegExp);

const isYouTubeShortened = (src: string) => !!src.match(youTubeShortenedRegExp);

const isYouTubeSource = (src: string) => !!src.match(youTubeRegExp);

const isValidEmbedSource = (src: string) =>
  !!src.match(vimeoEmbedRegExp) || !!src.match(youTubeEmbedRegExp);

const isValidUrl = (src: string) => !!src.match(validUrlRegExp);

export const getVideoIdFromSrc = (src: string) => {
  if (
    src === '' ||
    (!isYouTubeSource(src) &&
      !isVimeoSource(src) &&
      !isYouTubeShortened(src)) ||
    !isValidUrl(src)
  ) {
    return '';
  }
  const url = new URL(src);
  const splitPath = url.pathname.split('/');
  return url.searchParams.get('v') || splitPath[splitPath.length - 1];
};

const getEmbedSource = (src: string) => {
  if (src === '') {
    return '';
  }

  if (!isValidUrl(src)) {
    return null;
  }

  const videoId = getVideoIdFromSrc(src);

  try {
    if (isYouTubeSource(src)) {
      const url = new URL(src);
      return url.searchParams.get('v')
        ? `https://www.youtube.com/embed/${videoId}`
        : null;
    }

    if (isYouTubeShortened(src)) {
      const url = new URL(src);
      return !url.pathname.split('/').includes('be')
        ? `https://www.youtube.com/embed/${videoId}`
        : null;
    }

    if (isVimeoSource(src)) {
      const url = new URL(src);
      return !url.pathname.split('/').includes('player')
        ? `https://player.vimeo.com/video/${videoId}`
        : null;
    }
  } catch (e) {
    return null;
  }
  return null;
};

const getUpdatedSources = (src: string) => {
  if (isValidEmbedSource(src)) {
    return { embedSrc: src, isValidSrc: true };
  }

  const embedSrc = getEmbedSource(src);

  if (embedSrc === null) {
    return { embedSrc: src, isValidSrc: false };
  }

  return { embedSrc, isValidSrc: true };
};

export default getUpdatedSources;
