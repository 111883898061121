import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

export const getAddableLayouts: () => AddableDraggableElement[] = () => [
  {
    id: 'addableSections',
    name: 'addableSections',
    icon: 'section',
    label: 'sideMenu.addElements.section',
    onboardingKey: 'addable-section',
    template: {
      uid: 'section',
      component: 'ls-section',
      'background-is-webp': true,
      children: [
        {
          uid: 'ls-row',
          component: 'ls-row',
          children: [
            {
              uid: 'ls-column',
              component: 'ls-column',
            },
          ],
        },
      ],
    },
  },
  {
    id: 'addableRows',
    name: 'addableRows',
    icon: 'row',
    label: 'sideMenu.addElements.row',
    onboardingKey: 'addable-row',
    template: {
      uid: 'row',
      component: 'ls-row',
      'background-is-webp': true,
      children: [
        {
          uid: 'column',
          component: 'ls-column',
          'justify-content': 'center',
          'background-is-webp': true,
        },
      ],
    },
  },
  {
    id: 'addableColumns',
    name: 'addableColumns',
    icon: 'column',
    label: 'sideMenu.addElements.column',
    onboardingKey: 'addable-column',
    template: {
      uid: 'column',
      component: 'ls-column',
      'justify-content': 'center',
      'background-is-webp': true,
    },
  },
  {
    id: 'addableGroups',
    name: 'addableGroups',
    icon: 'object-group',
    label: 'sideMenu.addElements.group',
    onboardingKey: 'addable-group',
    template: {
      uid: 'group',
      component: 'ls-group',
      'justify-content': 'center',
      'background-is-webp': true,
    },
  },
];

export const getAddableElements = ({
  blockedItems = {},
  hiddenItems = [],
}: AddableElementsConfig = {}) => {
  const elements: AddableDraggableElement[] = [
    {
      id: 'addableTitle',
      name: 'addableElements',
      icon: 'text',
      label: 'sideMenu.addElements.title',
      onboardingKey: 'addable-title',
      template: {
        uid: 'text',
        component: 'ls-text',
        'font-size': '40px',
        text: `<h1><span style="font-family: Montserrat, sans-serif; color: #191C1F">${i18next.t(
          'sideMenu.addElements.titleContent'
        )}</span></h1>`,
        align: 'center',
      },
    },
    {
      id: 'addableParagraph',
      name: 'addableElements',
      icon: 'paragraph',
      label: 'sideMenu.addElements.paragraph',
      onboardingKey: 'addable-paragraph',
      template: {
        uid: 'text',
        component: 'ls-text',
        'font-size': '16px',
        text: `<span style="font-family: Raleway, sans-serif; color:#191C1F;">${i18next.t(
          'sideMenu.addElements.paragraphContent'
        )}</span>`,
        align: 'left',
      },
    },
    {
      id: 'addableButton',
      name: 'addableElements',
      icon: 'button',
      label: 'sideMenu.addElements.button',
      onboardingKey: 'addable-button',
      template: {
        uid: 'button',
        component: 'ls-button',
        label: i18next.t('sideMenu.addElements.buttonContent') as string,
        background: '#191C1F',
        padding: '8px 32px 8px 32px',
        'border-radius': '24px 24px 24px 24px',
        'font-family': 'Montserrat, sans-serif',
        'is-bold': true,
      },
    },
    {
      id: 'addableEmptyForm',
      name: 'addableElements',
      icon: 'align-left',
      label: 'sideMenu.addElements.emptyForm',
      onboardingKey: 'addable-form',
      template: {
        uid: 'form',
        component: 'ls-empty-form',
        'max-width': '360px',
      },
    },
    {
      id: 'addableImage',
      name: 'addableElements',
      icon: 'image',
      label: 'sideMenu.addElements.image',
      onboardingKey: 'addable-image',
      template: {
        uid: 'image',
        component: 'ls-image',
        src: 'https://via.placeholder.com/460x320?text=460x320+Image+Sample',
        'max-width': '460px',
        height: '320px',
        'is-webp': true,
      },
    },
    {
      id: 'addableVideo',
      name: 'addableElements',
      icon: 'video',
      label: 'sideMenu.addElements.video',
      onboardingKey: 'addable-video',
      template: { uid: 'video', component: 'ls-video' },
    },
    {
      id: 'addableHTML',
      name: 'addableElements',
      icon: 'html',
      isBlocked: blockedItems.html,
      label: 'sideMenu.addElements.html',
      onboardingKey: 'addable-html',
      template: {
        uid: 'html',
        component: 'ls-html',
        html: `<h3 style="text-align: center; margin-bottom: 0;">${i18next.t(
          'sideMenu.addElements.htmlContent'
        )}</h3>`,
      },
    },
    {
      id: 'addableIcon',
      name: 'addableElements',
      icon: 'icon',
      label: 'sideMenu.addElements.icon',
      onboardingKey: 'addable-icon',
      template: {
        uid: 'icon',
        component: 'ls-icon',
        icon: 'far fa-smile',
      },
    },
    {
      id: 'addableTimer',
      name: 'addableElements',
      icon: 'timer',
      label: 'sideMenu.addElements.timer',
      onboardingKey: 'addable-timer',
      template: {
        uid: 'timer',
        component: 'ls-timer',
        type: 'timeOnPage',
        timezone: '-03:00_utcMinus0300MainCities',
        'end-date': (() => {
          const date = new Date();
          date.setDate(date.getDate() + 1);
          return date.toISOString().split('.')[0];
        })(),
        'time-on-page': JSON.stringify({
          days: 0,
          hours: 0,
          minutes: 5,
          seconds: 0,
        }),
        'has-action': false,
        'items-visibility': JSON.stringify({
          days: true,
          hours: true,
          minutes: true,
          seconds: true,
          labels: true,
        }),
        background: '#191C1F',
        'font-family': 'Montserrat, sans-serif',
        'border-radius': '8px 8px 8px 8px',
        'number-size': '40px',
        'label-size': '16px',
        'items-width': '100px',
        'items-height': '108px',
      },
    },
    {
      id: 'addableAccordion',
      name: 'addableElements',
      icon: 'accordion',
      label: 'FAQ',
      onboardingKey: 'addable-icon',
      isNewTag: false,
      template: {
        uid: 'accordion',
        component: 'ls-accordion',
        margin: '6px 6px 6px 6px',
        padding: '6px 6px 6px 6px',
        items: JSON.stringify([
          {
            id: uuidv4(),
            title: {
              text: `<h3><span style="font-family: Montserrat, sans-serif; color: #191C1F">${i18next.t(
                'sideMenu.addElements.titleContentAccordion'
              )}</span></h3>`,
              align: 'left',
              padding: '12px 16px 12px 16px',
            },
            content: {
              text: `${i18next.t('sideMenu.addElements.textContentAccordion')}`,
              align: 'left',
              padding: '12px 16px 12px 16px',
            },
          },
        ]),
      },
    },
    {
      id: 'addablePopups',
      name: 'addablePopups',
      icon: 'popup',
      label: 'sideMenu.addElements.popup',
      onboardingKey: 'addable-popup',
      isNewTag: true,
      template: {
        uid: 'popup',
        component: 'ls-popup',
        children: [
          {
            uid: 'ls-row',
            component: 'ls-row',
            children: [
              {
                uid: 'ls-column',
                component: 'ls-column',
              },
            ],
          },
        ],
      },
    },
    {
      id: 'addableTransparentCheckout',
      name: 'addableElements',
      icon: 'checkout',
      label: 'sideMenu.addElements.transparentCheckout',
      onboardingKey: 'addable-icon',
      isNewTag: true,
      template: {
        uid: 'transparent-checkout',
        component: 'ls-transparent-checkout',
        'offer-key': '',
        'max-width': '100%',
      },
    },
  ];

  return hiddenItems.length
    ? elements.filter((element) => !hiddenItems.includes(element.id))
    : elements;
};
