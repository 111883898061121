import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Add: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M20.625 10.875H13.125V3.375C13.125 3.1875 12.9375 3 12.75 3H11.25C11.0156 3 10.875 3.1875 10.875 3.375V10.875H3.375C3.14062 10.875 3 11.0625 3 11.25V12.75C3 12.9844 3.14062 13.125 3.375 13.125H10.875V20.625C10.875 20.8594 11.0156 21 11.25 21H12.75C12.9375 21 13.125 20.8594 13.125 20.625V13.125H20.625C20.8125 13.125 21 12.9844 21 12.75V11.25C21 11.0625 20.8125 10.875 20.625 10.875Z"
      fill={color}
    />
  </StyledSVG>
);
