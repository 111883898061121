import { HTMLAttributes, useCallback, useState } from 'react';
import { UpgradeModal } from '@components/UpgradeModal';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ElementIcon,
  IconBox,
  LockContainer,
  LockIcon,
  Title,
} from './styles';

export type BlockedElementProps = HTMLAttributes<HTMLDivElement> & {
  element: AddableDraggableElement;
};

export const BlockedElement: React.FC<BlockedElementProps> = ({
  element,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const handleInteraction = useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <Container data-onboarding={element.onboardingKey} {...attrs}>
      <LockContainer
        onDrag={handleInteraction}
        onClick={handleInteraction}
        id="lock-container"
        draggable
      >
        <IconBox>
          <LockIcon className="fal fa-lock" />
          <ElementIcon name={element.icon} size={32} color="var(--gray-700)" />
        </IconBox>
        <Title>{t(element.label)}</Title>
      </LockContainer>

      <UpgradeModal
        id="upgrade-modal"
        isOpen={showModal}
        feature="html"
        onClose={() => setShowModal(false)}
      />
    </Container>
  );
};
