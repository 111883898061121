import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import { UseCaseLoader } from '@pages/PageWizard/components/UseCaseLoader';
import { StyledTextInput } from './styles';
import { Suggestions } from './components/Suggestions';

type AiSidebarBodyProps = HTMLAttributes<HTMLDivElement> & {
  aiGeneratedDescriptionSuggestions?: string[];
  isLoading: boolean;
  selectedSuggestion: string;
  userTypedDescription: string;
  onGenerateButtonClicked: () => void;
  onUserTypedDescription: (value: string) => void;
  onSelectedSuggestion: (suggestion: string) => void;
};

const AiSidebarBodyComponent: React.FC<AiSidebarBodyProps> = ({
  aiGeneratedDescriptionSuggestions,
  isLoading = false,
  selectedSuggestion,
  userTypedDescription,
  onGenerateButtonClicked,
  onUserTypedDescription,
  onSelectedSuggestion,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: React.ChangeEvent) => {
      const { value } = e.target as HTMLInputElement;

      onUserTypedDescription(value);
    },
    [onUserTypedDescription]
  );

  if (isLoading) {
    return (
      <UseCaseLoader
        title={t('pageWizard.salesPage.ai.loader.title')}
        subtitle={t('pageWizard.salesPage.ai.loader.subtitle')}
      />
    );
  }

  return (
    <div {...attrs}>
      {aiGeneratedDescriptionSuggestions?.length ? (
        <Suggestions
          suggestions={aiGeneratedDescriptionSuggestions}
          onSuggestionSelected={(suggestion) =>
            onSelectedSuggestion(suggestion)
          }
          onGenerateNewButtonClicked={onGenerateButtonClicked}
          selectedSuggestion={selectedSuggestion}
        />
      ) : (
        <>
          <StyledTextInput
            key="userTypedDescription-text"
            id="userTypedDescription-text"
            name="userTypedDescription-text"
            size="md"
            placeholder={t(
              'pageWizard.salesPage.ai.sidebar.describeProduct.placeholder'
            )}
            value={userTypedDescription}
            onChange={handleChange}
            label={t(
              `pageWizard.salesPage.ai.sidebar.describeProduct.userTypedDescriptionLabel`
            )}
            inputType="textarea"
            rows={6}
          />
          <CosmosButton
            onButtonClicked={onGenerateButtonClicked}
            type="primary"
            label={t('pageWizard.salesPage.ai.sidebar.generateButton')}
            width="auto"
            size="md"
            disabled={!userTypedDescription}
          />
        </>
      )}
    </div>
  );
};

export const AiSidebarBody = memo(AiSidebarBodyComponent);
