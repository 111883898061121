import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/custom.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/danger.css';

import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Toast from '@services/toast';
import Pages from '@services/pages';
import { BaseModal } from '@components/BaseModal';
import { Description, ModalTitle, CancelButton } from './styles';

type RemoveTemplateModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  uuid?: string;
  onClose?: () => void;
  onDelete?: () => void;
};

const RemoveTemplateModalComponent: React.FC<RemoveTemplateModalProps> = ({
  isOpen,
  uuid,
  onClose,
  onDelete,
  ...attrs
}) => {
  const [loading, setLoading] = useState(false);
  const templateManagementEnabled = useSelector(
    (state: State) => state.userInfo.permissions.templateManagementEnabled
  );

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDeleteMedia = useCallback(async () => {
    try {
      if (uuid) {
        setLoading(true);
        await Pages.deleteTemplate(uuid, templateManagementEnabled);
        onDelete?.();
      }
    } catch (error) {
      Toast.error('Ocorreu um erro ao tentar excluir o template');
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  }, [handleCloseModal, onDelete, templateManagementEnabled, uuid]);

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      header={
        <ModalTitle>Ao excluir, esta ação não poderá ser desfeita</ModalTitle>
      }
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            Cancelar
          </CancelButton>
          <button
            onClick={handleDeleteMedia}
            className={classNames('hot-button hot-button--danger', {
              'hot-button--loading': loading,
            })}
          >
            Excluir template
          </button>
        </>
      }
    >
      <Description>Tem certeza que deseja excluir o template?</Description>
    </BaseModal>
  );
};

export const RemoveTemplateModal = memo(RemoveTemplateModalComponent);
