import { thankYouSections } from './thank-you';
import { headerSections } from './header';
import { footerSections } from './footer';
import { captureSections } from './capture';
import { videoSections } from './video';
import { listSections } from './list';
import { ctaSections } from './cta';
import { aboutSections } from './about';
import { showcaseSections } from './showcase';
import { faqSections } from './faq';
import { depositionsSections } from './depositions';
import { bonusSections } from './bonus';
import { linkInBioSections } from './link-in-bio';
import { contentSections } from './content';
import { warrantySections } from './warranty';

const addableSections: Record<AddableSectionCategory, PageFragmentListItem[]> =
  {
    header: headerSections,
    showcase: showcaseSections,
    capture: captureSections,
    list: listSections,
    cta: ctaSections,
    about: aboutSections,
    faq: faqSections,
    depositions: depositionsSections,
    thankYou: thankYouSections,
    bonus: bonusSections,
    footer: footerSections,
    video: videoSections,
    linkInBio: linkInBioSections,
    content: contentSections,
    warranty: warrantySections,
  };

export const getAddableSectionsByCategory = (
  category: AddableSectionCategory
) => addableSections[category] || [];
