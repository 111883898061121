import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';

export const openFolder = createAsyncThunk<OpenFolderResponse, string>(
  'mediaCenter/openFolder',
  async (folderId) => {
    try {
      const response = await MediaCenter.openFolder(folderId);
      return response;
    } catch (error) {
      // todo - create error handling process
      const serializedError = error as SerializedError;
      throw serializedError;
    }
  }
);

export const openFolderBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(openFolder.pending, (state: MediaCenterState) => {
    state.media = [];
    state.breadcrumbsRedo = [];
    state.loading = true;
  });
  builder.addCase(openFolder.fulfilled, (state: MediaCenterState, action) => {
    state.media = action.payload.folderMedia;
    state.breadcrumbs = action.payload.breadcrumbs;
    state.loading = false;
  });
  builder.addCase(openFolder.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
