import { KlickSendFormTerms } from '@components/KlicksendFormTerms';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 64px;
`;

export const StyledFormTerms = styled(KlickSendFormTerms)`
  margin-top: 24px;
`;
