import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import HotmartProductService from '@services/hotmartProduct';

export const initializeCheckoutIntegration = createAsyncThunk<
  CheckoutState,
  void,
  {
    state: LocalState;
  }
>('checkout/initializeCheckoutIntegration', async (_, { getState }) => {
  try {
    const checkoutState = { ...getState().checkout };
    let products: HotmartProduct[] = [];
    const { isExtensions } = getState().userInfo.userProfile;

    if (isExtensions) {
      products = await HotmartProductService.getProducts();
      checkoutState.isProductListEmpty = products.length === 0;

      if (products.length === 1) {
        const selectedProduct = products[0].id;
        const offers = await HotmartProductService.getOffersByProductId(
          selectedProduct
        );

        if (offers.length === 1) {
          const selectedOffer = offers[0].id;
          const url = offers[0].link;

          return {
            ...checkoutState,
            selectedProduct,
            selectedOffer,
            url,
            isProductListEmpty: false,
          };
        }
      }
    }

    return checkoutState;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const initializeCheckoutIntegrationBuilder = (
  builder: ActionReducerMapBuilder<CheckoutState>
) => {
  builder.addCase(
    initializeCheckoutIntegration.fulfilled,
    (_, { payload }: PayloadAction<CheckoutState>) => payload
  );
};
