import styled, { createGlobalStyle, css } from 'styled-components';

export const ModalFooterStyle = createGlobalStyle`
  .modal-warning-footer {
    justify-content: center;
  }
`;

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  text-align: center;
  color: var(--gray-900);
  font-weight: 300;
  line-height: 28.8px;
  font-family: Nunito Sans;
`;

export const PlanContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
`;

export const PlanTag = styled.div``;

export const PlanLabel = styled.div`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  margin-right: 12px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: var(--gray-500);
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  margin-top: 12px;
`;

export const ActionsContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

type StyledButtonsProps = {
  minWidth?: string;
};

export const PrimaryButton = styled.button<StyledButtonsProps>`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;

  ${({ minWidth }) => css`
    min-width: ${minWidth || '100%'};
  `}
`;

export const SecondaryButton = styled.button<StyledButtonsProps>`
  ${({ minWidth }) => css`
    min-width: ${minWidth || '100%'};
  `}
`;

export const ServiceListTitle = styled.div`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
`;

export const ServiceList = styled.div`
  margin-top: 32px;
`;

export const BodyContainer = styled.div`
  padding: 0 14px;
`;

export const ServiceListItem = styled.div`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 12px;
  color: var(--gray-500);

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ServiceIcon = styled.i`
  margin-right: 8px;
  color: var(--gray-600);
`;

export const Divider = styled.hr`
  margin: var(--spacer-4) 0;
  border-color: var(--gray-200);
`;
