import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/success.css';
import classNames from 'classnames';

import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import { Icon, StyledUploadMedia, StyledMedia, ModalBodyStyle } from './styles';

type MediaModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen?: boolean;
  displayMode?: 'background' | 'image';
  onClose?: () => void;
  onImageSelectionChange?: (media: Image) => void;
};

const MediaModalComponent: React.FC<MediaModalProps> = ({
  isOpen = false,
  displayMode = 'background',
  onClose,
  onImageSelectionChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [currentMedia, setCurrentMedia] = useState<Media | null>(null);

  const handleMediaSelection = useCallback(() => {
    if (currentMedia) {
      onImageSelectionChange?.(currentMedia as Image);
    }
  }, [currentMedia, onImageSelectionChange]);

  const footer = useMemo(
    () => (
      <button
        onClick={handleMediaSelection}
        className={classNames('hot-button hot-button--success', {
          'hot-button--disabled': !currentMedia,
        })}
      >
        <Icon className="fal fa-check" />

        <span>{t(`modifiers.${displayMode}.confirmSelection`)}</span>
      </button>
    ),
    [currentMedia, displayMode, handleMediaSelection, t]
  );

  const handleImageChange = useCallback((media: Media) => {
    setCurrentMedia(media);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setCurrentMedia(null);
    }
  }, [isOpen]);

  return (
    <>
      <ModalBodyStyle />
      <BaseModal
        divider
        isOpen={isOpen}
        onClose={onClose}
        header={<StyledUploadMedia />}
        footer={footer}
        size="large"
        bodyClass="media-modal__body"
        {...attrs}
      >
        {isOpen && (
          <StyledMedia
            isSelectable
            selectedMediaId={currentMedia?.id || ''}
            displayMode="modal"
            onSelectedImageChange={handleImageChange}
          />
        )}
      </BaseModal>
    </>
  );
};

export const MediaModal = memo(MediaModalComponent);
