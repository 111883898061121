import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { getHigherChildLabel } from '@common/utils';

export const useHigherNameByElement = (type?: PageChildType) => {
  const { state } = useSelector((store: State) => ({ state: store }));

  const higherName = useMemo(
    () => getHigherChildLabel(state, type),
    [state, type]
  );

  const getHigherNameByElement = useCallback(
    (elementType: PageChildType) => getHigherChildLabel(state, elementType),
    [state]
  );

  return { higherName, getHigherNameByElement };
};
