import capitalize from 'lodash.capitalize';
import { rebrand } from '@config/rebrand';

import HotmartAIService from '@services/hotmartAI';
import Http from '../http';

export default class UseCases {
  private static api = new Http();

  private static headers = {
    headers: { 'Content-Type': 'application/json' },
  };

  static createUseCase(type: string, properties: Record<string, any> = {}) {
    return this.api.post<KlickartUseCasesData>(
      `${rebrand.urls.KLICKART_URL}/v1/api/use_cases`,
      {
        use_case_config_identifier: type,
        properties,
      },
      this.headers
    );
  }

  static get(id: number) {
    return this.api.get<KlickartUseCasesData>(
      `${rebrand.urls.KLICKART_URL}/v1/api/use_cases/${id}`,
      this.headers
    );
  }

  static update(id: number, body: Partial<KlickartUseCasesData>) {
    return this.api.patch<KlickartUseCasesData>(
      `${rebrand.urls.KLICKART_URL}/v1/api/use_cases/${id}`,
      body,
      this.headers
    );
  }

  static getProductFromAI(productTitle: string, productDescription: string) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<
        UseCaseAIOutput<UseCaseProductAIOutput>
      >({
        prompt: process.env.REACT_APP_SALES_PAGE_PRODUCT_PROMPT as string,
        context: {
          product_name: productTitle,
          product_description: productDescription,
        },
        token: source?.token,
      }),
      source,
    };
  }

  static getTitleTextsFromAi(productName: string, productDescription: string) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<
        UseCaseAIOutput<UseCaseTitleSubtitleAIOutput>
      >({
        prompt: process.env
          .REACT_APP_SALES_PAGE_TITLE_SUBTITLE_PROMPT as string,
        context: {
          product_name: productName,
          product_description: productDescription,
        },
        token: source?.token,
      }),
      source,
    };
  }

  static getProductDescriptionsFromAI(
    productTitle: string,
    productDescription: string
  ) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<
        UseCaseAIOutput<{ description: string[] }>
      >({
        prompt: process.env.REACT_APP_SALES_PAGE_DESCRIPTION_PROMPT as string,
        context: {
          product_name: productTitle,
          product_description: productDescription,
        },
        token: source?.token,
      }),
      source,
    };
  }

  static getAboutTheAuthorFromAI(about: string) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<UseCaseAIOutput<{ about: string[] }>>(
        {
          prompt: process.env.REACT_APP_SALES_PAGE_ABOUT_PROMPT as string,
          context: { about_description: about || '' },
          token: source?.token,
        }
      ),
      source,
    };
  }

  static getCapturePageDescriptionFromAI(
    type: string,
    benefit: string,
    previousOptions?: UseCaseOptions
  ) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<UseCaseAIOutput<{ title: string[] }>>(
        {
          prompt: previousOptions
            ? (process.env
                .REACT_APP_CAPTURE_PAGE_DESCRIPTION_NEW_OPTIONS_PROMPT as string)
            : (process.env.REACT_APP_CAPTURE_PAGE_DESCRIPTION_PROMPT as string),
          context: {
            product_benefit: benefit,
            product_type: capitalize(type),
            ...(previousOptions && {
              first_option: previousOptions.firstOption,
              second_option: previousOptions.secondOption,
              third_option: previousOptions.thirdOption,
              fourth_option: previousOptions.fourthOption,
            }),
          },
          token: source?.token,
        }
      ),
      source,
    };
  }
}
