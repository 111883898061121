import styled, { css } from 'styled-components';

type ButtonProps = {
  active: boolean;
};

export const Button = styled.button<ButtonProps>`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 2px;
  padding: 8px;
  width: 40px;

  ${({ active }) => css`
    color: ${active ? 'var(--blue-dark)' : 'var(--gray-500) !important'};
  `};
`;

export const popoverStyle = css`
  .preview-popover__label {
    font-size: 0.875rem;
    font-weight: var(--font-weight);
    margin-bottom: 0.5rem;
    color: var(--gray-500);
  }

  .preview-popover__description {
    font-size: var(--text-2);
    font-weight: var(--font-weight);
    color: var(--gray-700);
  }
`;

export const innerPopoverStyle = css`
  :host {
    max-width: 16rem;
    box-shadow: 0px 2px 16px -6px rgb(0 0 0 / 25%);
    padding: var(--spacer-4);
  }

  :host(.hot-popover--bottom.show) .arrow::before {
    filter: drop-shadow(1px -2px 1px rgba(0, 0, 0, 0.05));
  }
`;
