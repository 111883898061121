import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';
import { removeFolderFromBreadcrumbRedo } from '@store/slices/mediaCenter';
import { updateMediaList } from '../updateMediaList';

export const deleteMedia = createAsyncThunk<
  void,
  Record<string, Media>,
  {
    state: LocalState;
    SerializedError: {
      name: string;
      stack: string;
      code: string;
    };
  }
>('mediaCenter/deleteMedia', async (itemsToDelete, { dispatch }) => {
  try {
    const promises: Promise<unknown>[] = [];
    const removedMedia: Media[] = [];

    const itemsToDeleteEntries = Object.entries(itemsToDelete);

    itemsToDeleteEntries.forEach(([key, value]) => {
      promises.push(MediaCenter.deleteItem(key, value.type));
    });

    const finishedPromises = await Promise.allSettled(promises);

    finishedPromises.forEach((promiseReturn, index) => {
      if (promiseReturn.status === 'fulfilled') {
        removedMedia.push(itemsToDeleteEntries[index][1]);
      }
    });

    if (!removedMedia.length) {
      throw new Error('no media deleted');
    }

    const removedIds = removedMedia.reduce<string[]>(
      (accumulator, item) =>
        item.type === 'media_folder' ? [...accumulator, item.id] : accumulator,
      []
    );

    if (removedMedia.length < itemsToDeleteEntries.length) {
      dispatch(removeFolderFromBreadcrumbRedo(removedIds));
      dispatch(updateMediaList());
      throw new Error('some media could not be deleted');
    }
    dispatch(removeFolderFromBreadcrumbRedo(removedIds));
    dispatch(updateMediaList());
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const deleteMediaBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(deleteMedia.pending, (state: MediaCenterState) => {
    state.loading = true;
  });
  builder.addCase(deleteMedia.fulfilled, (state: MediaCenterState) => {
    state.selectedMedia = {};
    state.loading = false;
  });
  builder.addCase(deleteMedia.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
