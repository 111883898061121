import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { Textbox } from '@pages/PageWizard/components/Textbox';
import { CosmosButton } from '@components/CosmosButton';
import { AiSidebar } from './components/AiSidebar';
import { ButtonWrapper, StepContainer } from './styles';

type CaptureFirstStepProps = UseCaseStepProps & {
  buttonText: string;
  title: string;
  type: UseCaseType;
};

const FirstStepComponent: React.FC<CaptureFirstStepProps> = ({
  buttonText,
  title,
  type,
  onModifierChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const handleCreateWithAI = useCallback(() => {
    const eventType: Record<
      string,
      { customEvent: string; eventDetails: string | undefined }
    > = {
      funnel_capture_publish_lead_capture_page: {
        customEvent: 'click_generate_with_ai_capture_funnel',
        eventDetails: undefined,
      },
      publish_lead_capture_page: {
        customEvent: 'click_pages_capture_create_title_ai',
        eventDetails: 'Publish_lead_capture_page',
      },
    };

    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: eventType[type].customEvent,
        event_details: eventType[type].eventDetails,
      },
    });

    setIsSideBarOpen(true);
  }, [type]);

  const generateButtonType: Record<any, string> = {
    funnel_capture_publish_lead_capture_page: 'funnelCapture',
    publish_lead_capture_page: 'capture',
  };

  return (
    <StepContainer {...attrs}>
      <Textbox
        id="capture-title"
        name="username"
        label="pageWizard.publishLeadCapturePage.title.label"
        placeholder="pageWizard.publishLeadCapturePage.title.placeholder"
        width="100%"
        value={title}
        rows={4}
        onNameChange={(value) => onModifierChange({ prop: 'title', value })}
        inputType="textarea"
      >
        <ButtonWrapper>
          <CosmosButton
            label={t(
              `pageWizard.publishLeadCapturePage.ai.generateText.${generateButtonType[type]}`
            )}
            iconClass="fal fa-bolt"
            iconSide="right"
            type="secondary"
            size="md"
            onButtonClicked={handleCreateWithAI}
          />
        </ButtonWrapper>

        <AiSidebar
          isOpen={isSideBarOpen}
          onClosed={() => setIsSideBarOpen(false)}
          type={type}
          onUseAiDescription={(value) =>
            onModifierChange({ prop: 'title', value })
          }
        />
      </Textbox>

      <Textbox
        id="capture-button-text"
        name="buttonText"
        label="pageWizard.publishLeadCapturePage.buttonText.label"
        placeholder="pageWizard.publishLeadCapturePage.buttonText.placeholder"
        width="100%"
        value={buttonText}
        onNameChange={(value) =>
          onModifierChange({ prop: 'buttonText', value })
        }
        showSeparator={false}
      />
    </StepContainer>
  );
};

export const FirstStep = memo(FirstStepComponent);
