import { getPageRoute } from '@common/utils';
import Klickart from '@services/klickart';
import Token from '@services/token';

import Http from '../http';

type PublishPageValidations =
  | 'canCreatePage'
  | 'canPublishPage'
  | 'hasSinglePage'
  | 'withinDomainLimit';

export default class Pages {
  private static http = new Http();

  static createPage(
    data: CreatePageProps,
    mode?: EditorMode,
    templateManagementEnabled?: boolean
  ) {
    return this.http.post<PageCreated>(
      getPageRoute(mode, templateManagementEnabled),
      data
    );
  }

  static deletePage(id: string) {
    return this.http.post(`${this.getUrl(id)}/trash`);
  }

  static deleteTemplate(id: string, templateManagementEnabled?: boolean) {
    return this.http.delete(
      this.getUrl(id, 'template', templateManagementEnabled)
    );
  }

  static updatePage<T = Page>(
    id: string,
    data: UpdatePageProps | UpdateTemplateProps,
    mode?: EditorMode,
    templateManagementEnabled?: boolean
  ) {
    return this.http.patch<T>(
      this.getUrl(id, mode, templateManagementEnabled),
      data
    );
  }

  static getPage(
    id: string,
    mode?: EditorMode,
    templateManagementEnabled?: boolean
  ) {
    const url = `${this.getUrl(id, mode, templateManagementEnabled)}`;
    return this.http.get<Page>(url);
  }

  static getPages() {
    return this.http.get<PageItem[]>(getPageRoute('page'));
  }

  static getTemplatePreview(id: string) {
    const url = `v1/public/templates/${id}`;
    return this.http.get<Page>(url);
  }

  static async getTemplates(templateManagementEnabled: boolean) {
    const { data } = await this.http.get<TemplatesResponse>(
      getPageRoute('template', templateManagementEnabled)
    );

    return data.sort((templateA, templateB) => {
      if (templateA.name.toLowerCase() > templateB.name.toLowerCase()) {
        return 1;
      }

      if (templateA.name.toLowerCase() < templateB.name.toLowerCase()) {
        return -1;
      }

      return 0;
    });
  }

  static getTemplateCategories() {
    return this.http.get<TemplateCategory[]>('/v1/template-categories');
  }

  static async validateUserLimits(): Promise<
    Record<PublishPageValidations, boolean>
  > {
    const token = Token.getToken();
    const { permissions } = Token.decodeToken<User>(`${token}`);
    const totalDomains = (await Klickart.getDomains()).filter((domain) =>
      Boolean(domain.id)
    ).length;
    const { activePagesCount } = await Klickart.getTotalPages();

    const hasSinglePage = Number(activePagesCount) === 1;

    const canCreatePage =
      permissions.pageLimit === undefined ||
      Number(activePagesCount) < Number(permissions.pageLimit);

    const canPublishPage =
      permissions.pageLimit === undefined ||
      Number(activePagesCount) <= Number(permissions.pageLimit);

    const withinDomainLimit =
      permissions.domainLimit === undefined ||
      (Number(permissions.domainLimit) >= 0 &&
        Number(totalDomains) <= Number(permissions.domainLimit));

    return {
      canCreatePage,
      canPublishPage,
      withinDomainLimit,
      hasSinglePage,
    };
  }

  static async validateUserPageLimit() {
    const token = Token.getToken();
    const { permissions } = Token.decodeToken<User>(`${token}`);
    const { activePagesCount } = await Klickart.getTotalPages();

    if (
      permissions.pageLimit !== undefined &&
      Number(activePagesCount) >= Number(permissions.pageLimit)
    ) {
      throw new Error('pageLimit');
    }
  }

  private static async validatePublishingPage(
    validates: PublishPageValidations[]
  ) {
    if (!validates.length) {
      return;
    }
    const userLimits = await this.validateUserLimits();

    const validations: Partial<Record<PublishPageValidations, boolean>> =
      validates.reduce(
        (acc, current) => ({ ...acc, [current]: userLimits[current] }),
        {}
      );

    if (!validations.canPublishPage) {
      throw new Error('pageLimit');
    }
    if (!validations.withinDomainLimit) {
      throw new Error('domainLimit');
    }
  }

  static async publishPage(
    id: string,
    props: PublishPageProps = {},
    validates: PublishPageValidations[] = [
      'canPublishPage',
      'withinDomainLimit',
    ]
  ) {
    const { mode, ...body } = props;
    const url = `${this.getUrl(id, mode, true)}`;

    if (this.isPageMode(mode)) {
      await this.validatePublishingPage(validates);
      await this.http.post(`${url}/schedules_publish`, body);

      return this.getPublishingStatus(id);
    }

    return this.http.post(`${url}/publish`);
  }

  private static getPublishingStatus(id: string) {
    return new Promise((resolve, reject) => {
      const getStatus = setInterval(async () => {
        const page = await this.getPage(id);

        if (page.lastPublishingStatus === 'success') {
          clearInterval(getStatus);
          resolve(page);
        } else if (page.lastPublishingStatus === 'fail') {
          clearInterval(getStatus);
          reject(new Error(page.lastPublishingMessage));
        }
      }, 2000);
    });
  }

  private static isPageMode(mode: EditorMode = 'page') {
    return mode === 'page';
  }

  private static getUrl(
    id: string,
    mode: EditorMode = 'page',
    templateManagementEnabled: boolean = false
  ) {
    return `${getPageRoute(mode, templateManagementEnabled)}/${id}`;
  }
}
