import styled, { css } from 'styled-components';

type StyledTemplateModels = {
  centered?: boolean;
};

export const Container = styled.div<StyledTemplateModels>`
  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  border-top: 1px solid #ccc;
  overflow: auto;
  max-height: 496px;
  height: 100%;
  padding: 24px;
`;
