export class AppError extends Error {
  private internalMessage = '';

  constructor(message: string) {
    super(message);
    this.internalMessage = message;
  }

  public get message() {
    return this.internalMessage;
  }
}
