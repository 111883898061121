import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Media: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M25.25 1.5H5.75C4.48438 1.5 3.5 2.53125 3.5 3.75V4.5H2.75C1.48438 4.5 0.5 5.53125 0.5 6.75V20.25C0.5 21.5156 1.48438 22.5 2.75 22.5H22.25C23.4688 22.5 24.5 21.5156 24.5 20.25V19.5H25.25C26.4688 19.5 27.5 18.5156 27.5 17.25V3.75C27.5 2.53125 26.4688 1.5 25.25 1.5ZM23 20.25C23 20.6719 22.625 21 22.25 21H2.75C2.32812 21 2 20.6719 2 20.25V6.75C2 6.375 2.32812 6 2.75 6H3.5V17.25C3.5 18.5156 4.48438 19.5 5.75 19.5H23V20.25ZM26 17.25C26 17.6719 25.625 18 25.25 18H5.75C5.32812 18 5 17.6719 5 17.25V3.75C5 3.375 5.32812 3 5.75 3H25.25C25.625 3 26 3.375 26 3.75V17.25ZM8.75 9.375C10.1562 9.375 11.375 8.20312 11.375 6.75C11.375 5.34375 10.1562 4.125 8.75 4.125C7.29688 4.125 6.125 5.34375 6.125 6.75C6.125 8.20312 7.29688 9.375 8.75 9.375ZM8.75 5.625C9.35938 5.625 9.875 6.14062 9.875 6.75C9.875 7.40625 9.35938 7.875 8.75 7.875C8.09375 7.875 7.625 7.40625 7.625 6.75C7.625 6.14062 8.09375 5.625 8.75 5.625ZM20 6.75C19.5781 6.28125 18.875 6.28125 18.4531 6.75L14 11.2031L12.5 9.75C12.0781 9.28125 11.375 9.28125 10.9531 9.75L6.82812 13.875C6.64062 14.0156 6.5 14.3906 6.5 14.625V15.9375C6.5 16.2656 6.73438 16.5 7.0625 16.5H23.9375C24.2188 16.5 24.5 16.2656 24.5 15.9375V11.625C24.5 11.3438 24.3594 11.0625 24.125 10.875L20 6.75ZM23 15H8V14.8125L11.75 11.0625L14 13.3125L19.25 8.0625L23 11.8125V15Z"
      fill={color}
    />
  </StyledSVG>
);
