import React, {
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import camelcase from 'camelcase';
import { useTranslation } from 'react-i18next';
import {
  ActiveComponentState,
  EditorEngineManager,
  Parent,
} from '@hotmart-org-ca/saas-pages-engine';
import { useMenu, useDetectBrowser } from '@hooks/index';
import {
  SubmenuHeader,
  SubmenuTitle,
  CloseSubmenu,
  StyledIcon,
  Anchor,
} from './styles';
import '@hotmart-org-ca/cosmos/dist/breadcrumb/breadcrumb.css';

export type SubmenuProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    title: string;
    padding?: string;
    activeComponent?: ActiveComponentState;
    onGoBack?: () => void;
  }
>;

const SubmenuComponent = React.forwardRef<HTMLElement, SubmenuProps>(
  (
    {
      children,
      title: titleProp,
      padding,
      activeComponent,
      onGoBack,
      ...attrs
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { isFirefox } = useDetectBrowser();
    const { goBack, activeMenu, activeSubmenu, closeMenu } = useMenu();

    const title = useMemo(() => t(titleProp), [t, titleProp]);

    const handleIconClick = useCallback(() => {
      goBack();
      onGoBack?.();
    }, [goBack, onGoBack]);

    const handleActiveParent = useCallback((item: Parent) => {
      EditorEngineManager.setActive(item);
    }, []);

    const handleCloseSubmenu = useCallback(() => {
      EditorEngineManager.clearActive();
      closeMenu();
    }, [closeMenu]);

    const breadcrumbItems = useMemo(() => {
      const result: Parent[] = [];

      if (activeComponent) {
        result.unshift(activeComponent);

        let elements: Parent = activeComponent;

        while (elements.parent) {
          elements = elements.parent;
          result.unshift(elements);
        }
      }

      return result.slice(-3);
    }, [activeComponent]);

    useEffect(() => {
      const menu = document.querySelector('hot-content-menu');
      const subMenu =
        menu?.shadowRoot?.querySelector<HTMLElement>('._hot-scroll');

      if (subMenu) {
        subMenu.style.overflow = 'auto';
        (subMenu as HTMLElement).style.padding = padding || '16px';
      }
    }, [isFirefox, padding]);

    return (
      <hot-content-menu
        slot="submenu"
        role="menu"
        data-title={title}
        {...attrs}
        ref={ref}
      >
        <SubmenuHeader slot="content-menu-header">
          {activeMenu !== activeSubmenu && (
            <StyledIcon
              onClick={handleIconClick}
              name="chevron-left"
              color="var(--gray-700)"
            />
          )}

          <div>
            {Boolean(activeComponent) &&
              activeComponent?.component !== 'ls-section' && (
                <hot-breadcrumb>
                  {breadcrumbItems.map(
                    (item) =>
                      item.component !== 'ls-page' &&
                      item.uid !== activeComponent?.uid && (
                        <hot-breadcrumb-item
                          key={`${item.uid}-${item.component}`}
                          className="breadcrumb"
                          onClick={() => handleActiveParent(item)}
                        >
                          <Anchor className="breadcrumb">
                            {t(
                              `sideMenu.adjustments.title.${camelcase(
                                item.component
                              )}`
                            )}
                          </Anchor>
                        </hot-breadcrumb-item>
                      )
                  )}
                </hot-breadcrumb>
              )}

            <SubmenuTitle
              activeComponent={activeComponent?.component}
              data-onboarding="submenu-title"
            >
              {title}
            </SubmenuTitle>
          </div>
          <CloseSubmenu onClick={handleCloseSubmenu}>
            <i className="fal fa-times" />
          </CloseSubmenu>
        </SubmenuHeader>
        {children}
      </hot-content-menu>
    );
  }
);

export const Submenu = memo(SubmenuComponent);
