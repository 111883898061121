import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import '@hotmart-org-ca/cosmos/dist/form/variations/small.css';
import '@hotmart-org-ca/cosmos/dist/alert';
import '@hotmart-org-ca/cosmos/dist/alert/variations/info.css';

import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ComponentState,
  EditorEngineManager,
  Sortable,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import { useAppDispatch } from '@store/index';
import { updateInfo } from '@store/slices/pageInfo';
import { Submenu } from '@components/Submenu';
import { AddToRootButton } from '@components/AddToRootButton';
import { dispatchOpenPopup, scrollToSection } from '@common/utils';
import { useEngine } from '@hooks/useEngine';
import { usePageConfig } from '@hooks/usePageConfig';
import Files from '@services/files';
import { usePageInfo } from '@hooks/usePageInfo';
import { useUserInfo } from '@hooks/useUserInfo';
import TagManager from 'react-gtm-module';
import {
  Content,
  Label,
  Message,
  Child,
  IconGroup,
  AddButtonsWrapper,
  Link,
  LinkIcon,
  LinkWrapper,
  Title,
} from './styles';

export const PageStructureSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { duplicateElement, removeElement, openedPopup, page, dragging } =
    useEngine();
  const { updateConfig } = usePageConfig();
  const { uuid, mode } = usePageInfo();
  const { templateManagementEnabled } = useUserInfo();

  const { state } = useSelector((store: State) => ({
    state: store,
  }));

  const sectionContentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentItem, setCurrentItem] = useState('');
  const [popups, setPopups] = useState<Template[]>([]);
  const [sections, setSections] = useState<Template[]>([]);

  const handleDuplicateSection = useCallback(
    (item: Template) => {
      if (page) {
        duplicateElement(item.uid, page.uid);
      }
    },
    [duplicateElement, page]
  );

  const handleRemoveSection = useCallback(
    (uid: string, component: string, itemType: string) => {
      removeElement(uid);

      if (itemType === 'popups') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            custom_event_name: 'click_manage_pop_up',
            event_details: 'Remove',
          },
        });
      }

      if (component === 'ls-popup') {
        updateConfig({ exitPopup: '' });
      }
    },
    [removeElement, updateConfig]
  );

  const saveItemName = useCallback(
    async (uid: string, label: string) => {
      const payload = { metadata: { label } };

      EditorEngineManager.updateElement(uid, payload, false);

      const finalPage = EditorEngineManager.getFinalPage() as Template;

      setCurrentItem('');
      await Files.savePageFile(
        uuid,
        finalPage,
        mode,
        templateManagementEnabled
      );
      dispatch(updateInfo({ hasUnsavedChanges: false }));
    },
    [dispatch, mode, templateManagementEnabled, uuid]
  );

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>, uid: string) => {
      if (event.key === 'Escape') {
        setCurrentItem('');
      } else if (event.key === 'Enter') {
        const target = event.target as HTMLInputElement;
        const label = target.value;

        if (label) {
          saveItemName(uid, label);
        }
      }
    },
    [saveItemName]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        inputRef.current.value
      ) {
        saveItemName(currentItem, inputRef.current.value);
      }
    },
    [currentItem, saveItemName]
  );

  const handleActiveItem = useCallback(
    (uid: string, component: string, itemType: string) => {
      if (itemType === 'popups') {
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            custom_event_name: 'click_manage_pop_up',
            event_details: 'Edit',
          },
        });
      }
      if (currentItem !== uid) {
        const isPopup = component === 'ls-popup';

        if (isPopup) {
          dispatchOpenPopup(uid, openedPopup);
        } else {
          scrollToSection(uid);
        }

        EditorEngineManager.setActive({
          uid,
          component,
          hideDelete: isPopup,
          hideDuplicate: isPopup,
        });
      }
    },
    [currentItem, openedPopup]
  );

  const getItemName = useCallback(
    (uid: string) =>
      (state[uid] as ComponentState)?.metadata?.label ||
      t('sideMenu.pageStructure.sections.section'),
    [state, t]
  );

  const handleInputDoubleClick = useCallback((section: string) => {
    setCurrentItem(section);
  }, []);

  const renderContentItems = useCallback(
    (items: Template[]) =>
      items?.map((item: Template) => {
        const itemType = item.component === 'ls-popup' ? 'popups' : 'sections';

        return (
          <Child
            key={item.uid}
            onDoubleClick={() => handleInputDoubleClick(item.uid)}
            itemType={itemType}
            isActive={openedPopup === item.uid}
            onClick={() => {
              if (itemType === 'sections') {
                scrollToSection(item.uid, true);
              }
            }}
          >
            {currentItem === item.uid ? (
              <input
                data-testid={`elementLabel-${item.uid}`}
                ref={inputRef}
                className="hot-form__input hot-form__input--sm"
                defaultValue={getItemName(item.uid)}
                maxLength={50}
                onKeyDown={(event) => handleKeyDown(event, item.uid)}
              />
            ) : (
              <Label>{getItemName(item.uid)}</Label>
            )}

            {!dragging && !currentItem && (
              <>
                <hot-tooltip
                  position="top"
                  content={t(`sideMenu.pageStructure.adjust`)}
                >
                  <IconGroup
                    onClick={() =>
                      handleActiveItem(item.uid, item.component, itemType)
                    }
                  >
                    <i className="fal fa-pencil-alt" />
                  </IconGroup>
                </hot-tooltip>

                {itemType !== 'popups' && (
                  <hot-tooltip
                    position="top"
                    content={t(`sideMenu.pageStructure.duplicate`)}
                  >
                    <IconGroup onClick={() => handleDuplicateSection(item)}>
                      <i className="fal fa-clone" />
                    </IconGroup>
                  </hot-tooltip>
                )}

                {(itemType === 'popups' || (items && items.length > 1)) && (
                  <hot-tooltip
                    position="top"
                    content={t(`sideMenu.pageStructure.delete`)}
                  >
                    <IconGroup
                      onClick={() =>
                        handleRemoveSection(item.uid, item.component, itemType)
                      }
                    >
                      <i className="fal fa-trash" />
                    </IconGroup>
                  </hot-tooltip>
                )}
              </>
            )}
          </Child>
        );
      }),
    [
      currentItem,
      dragging,
      getItemName,
      handleActiveItem,
      handleDuplicateSection,
      handleInputDoubleClick,
      handleKeyDown,
      handleRemoveSection,
      openedPopup,
      t,
    ]
  );

  useEffect(() => {
    const currentPopups: Template[] = [];
    const currentSections =
      page?.children?.filter((child) => {
        if (child.component === 'ls-popup') {
          currentPopups.push(child);
          return false;
        }
        return true;
      }) || [];

    setSections(currentSections);
    setPopups(currentPopups);
  }, [dispatch, page?.children]);

  useEffect(() => {
    if (page && sectionContentRef.current) {
      Sortable.create(sectionContentRef.current, {
        group: 'sections',
        animation: 0,
        onStart: () => {
          EditorEngineManager.setDragging(true);
        },
        onEnd: (e: Sortable.SortableEvent) => {
          if (e.oldIndex !== e.newIndex) {
            EditorEngineManager.reorderElement(
              page.uid,
              e.oldIndex as number,
              e.newIndex as number
            );
          }
          EditorEngineManager.setDragging(false);
        },
      });
    }
  }, [page]);

  useEffect(() => {
    if (currentItem && inputRef.current) {
      inputRef.current.focus();
      scrollToSection(currentItem, true);
    }
  }, [currentItem]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Submenu title="sideMenu.pageStructure.title" padding="24px" {...attrs}>
      <Message>
        <span>{t('sideMenu.pageStructure.description')}</span>
      </Message>

      <Title>{t('sideMenu.pageStructure.popups.title')}</Title>

      <Message>
        {popups?.length ? (
          <span>{t('sideMenu.pageStructure.popups.description')}</span>
        ) : (
          <span>{t('sideMenu.pageStructure.popups.empty')}</span>
        )}
      </Message>

      <Content>{renderContentItems(popups)}</Content>

      <AddButtonsWrapper>
        <AddToRootButton
          data-onboarding="add-section-menu-button"
          addableElementType="popup"
          sendMetrifyOptions={{ group: 'popup', name: 'add_button_click' }}
        />
      </AddButtonsWrapper>

      {/* This element is hidden because it dont have a url */}
      {false && (
        <LinkWrapper>
          <Link target="_blank" href="/">
            {t('sideMenu.pageStructure.popups.externalLink')}
            <LinkIcon className="far fa-external-link" />
          </Link>
        </LinkWrapper>
      )}

      <Title>{t('sideMenu.pageStructure.sections.title')}</Title>

      <Message>
        {sections?.length ? (
          <span>{t('sideMenu.pageStructure.sections.description')}</span>
        ) : (
          <span>{t('sideMenu.pageStructure.sections.empty')}</span>
        )}
      </Message>

      <Content ref={sectionContentRef}>{renderContentItems(sections!)}</Content>

      <AddButtonsWrapper>
        <AddToRootButton
          data-onboarding="add-section-menu-button"
          addableElementType="section"
        />
      </AddButtonsWrapper>
    </Submenu>
  );
};
