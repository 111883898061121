import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Container, Title, Description, Step } from './styles';

const StepMenuComponent: React.FC<UseCaseStepMenuProps> = ({
  stepsConfig,
  currentStep,
  onStepClick,
  ...attrs
}) => {
  const { t } = useTranslation();

  const isShowAlways = useCallback(
    (step: UseCaseStep) => typeof step.showAfterStep === 'undefined',
    []
  );

  const isShowAfter = useCallback(
    (step: UseCaseStep) =>
      Boolean(
        typeof step.showAfterStep === 'number' &&
          step.showAfterStep < currentStep
      ),
    [currentStep]
  );

  const isHideAfter = useCallback(
    (step: UseCaseStep) =>
      Boolean(
        typeof step.hideAfterStep === 'number' &&
          step.hideAfterStep < currentStep
      ),
    [currentStep]
  );

  return (
    <Container {...attrs}>
      {stepsConfig.map(
        (step, index) =>
          (isShowAlways(step) || isShowAfter(step)) &&
          !isHideAfter(step) && (
            <Step onClick={() => onStepClick?.(index)} key={uuidv4()}>
              <Title
                finished={currentStep > index}
                active={currentStep === index}
              >
                {t(step.title as string)}
              </Title>
              <Description finished={currentStep > index}>
                {t(step.description as string)}
              </Description>
            </Step>
          )
      )}
    </Container>
  );
};

export const StepMenu = memo(StepMenuComponent);
