import { HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { rebrand } from '@config/rebrand';
import { Container } from './styles';

export type SimpleFormConfigProps = HTMLAttributes<HTMLDivElement> & {
  pageId: string;
};

const Component: React.FC<SimpleFormConfigProps> = ({ pageId, ...attrs }) => {
  const { t } = useTranslation();

  return (
    <Container {...attrs}>
      <span>
        {t('form.simpleForm.statisticMessage')} {'>'}{' '}
        <a
          href={`${rebrand.urls.ANALYTICS_URL}/${pageId}/emails`}
          target="_blank"
          rel="noreferrer"
        >
          {t('form.simpleForm.conversion')}
        </a>
      </span>
    </Container>
  );
};

export const SimpleFormConfig = memo(Component);
