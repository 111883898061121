import { HTMLAttributes, memo } from 'react';
import { Block } from '@components/Block';
import { TextInputs } from '../TextInputs';
import { ButtonConfig } from './components/ButtonConfig';

type ThanksSecondStepProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & { properties: ThanksPageProperties };

const SecondStepComponent: React.FC<ThanksSecondStepProps> = ({
  properties: { title, subtitle, buttonLink, buttonText, hasButton },
  onModifierChange,
  ...attrs
}) => (
  <>
    <Block
      label="pageWizard.funnelCaptureThanksPage.textBlockTitle"
      showSeparator
      {...attrs}
    >
      <TextInputs
        texts={[
          { inputType: 'title', value: title },
          { inputType: 'subtitle', value: subtitle, type: 'textarea' },
        ]}
        onTextChanged={onModifierChange}
      />
    </Block>
    <ButtonConfig
      buttonLink={buttonLink}
      buttonText={buttonText}
      hasButton={hasButton}
      onShowButtonChange={(value) =>
        onModifierChange({ prop: 'hasButton', value })
      }
      onTextChanged={onModifierChange}
    />
  </>
);

export const SecondStep = memo(SecondStepComponent);
