import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { pageScriptsThunks } from '../../store/thunks';

export const usePageScripts = () => {
  const dispatch = useAppDispatch();

  const head = useSelector((state: State) => state.pageScripts.head);

  const body = useSelector((state: State) => state.pageScripts.body);

  const supportChatScript = useSelector(
    (state: State) => state.pageScripts.supportChatScript
  );

  const saveSupportChatScript = useCallback(
    (script: string) =>
      dispatch(pageScriptsThunks.saveSupportChatScript(script)).unwrap(),
    [dispatch]
  );

  return {
    supportChatScript,
    saveSupportChatScript,
    head,
    body,
  };
};
