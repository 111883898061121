import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { CosmosSwitch } from '@components/CosmosSwitch';
import { Select, SelectOption } from '@components/Select';
import { useTranslation } from 'react-i18next';

type KlicksendFormTagsProps = HTMLAttributes<HTMLDivElement> & {
  tags: KlicksendTag[];
  selectedTag?: string;
  personalizeTag?: boolean;
  onTagSelected: (value: string) => void;
  onPersonalizeChange?: (value: boolean) => void;
};

const KlicksendFormTagsComponent: React.FC<KlicksendFormTagsProps> = ({
  tags,
  selectedTag,
  personalizeTag: personalizeTagProp = false,
  onTagSelected,
  onPersonalizeChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [personalizeTag, setPersonalizeTag] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  const handlePersonalizeTag = useCallback(
    (value: boolean) => {
      setPersonalizeTag(value);
      onPersonalizeChange?.(value);
    },
    [onPersonalizeChange]
  );

  useEffect(() => {
    setSelectOptions(
      tags.map((tag: KlicksendTag) => ({
        id: `${tag.id}`,
        label: tag.name,
        value: `${tag.id}`,
      }))
    );
  }, [tags]);

  useEffect(() => {
    setPersonalizeTag(personalizeTagProp);
  }, [personalizeTagProp]);

  return (
    <div {...attrs}>
      <CosmosSwitch
        id="form-personalize-tag"
        isChecked={personalizeTag}
        label="form.klicksend.formCreation.personalizeTag"
        onSwitchChanged={handlePersonalizeTag}
        infoTooltip={{
          title: 'form.klicksend.formCreation.tagTooltip.title',
          description: 'form.klicksend.formCreation.tagTooltip.description',
          displayMode: 'hover',
        }}
        tooltipPosition="top"
      />
      {personalizeTag && (
        <Select
          id="klicksend-tags"
          options={selectOptions}
          label={t('form.klicksend.formCreation.tagSelectLabel')}
          placeholder={t('form.klicksend.formCreation.tagSelectPlaceholder')}
          onChange={(value) => onTagSelected(value)}
          size="md"
          selectedOption={selectedTag}
        />
      )}
    </div>
  );
};

export const KlicksendFormTags = memo(KlicksendFormTagsComponent);
