import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  padding-right: 16px;
  font-size: 18px;
  color: var(--gray-500);
`;

export const Container = styled.div`
  hot-dropdown {
    position: absolute;
  }

  hot-dropdown-menu-header {
    font-size: 16px;
    cursor: default;
  }

  hot-dropdown-menu-header:last-of-type {
    margin-top: 16px;
  }

  hot-dropdown-menu-item {
    display: flex;
    align-items: center;
  }

  u {
    color: var(--gray-500);
  }
`;

export const Comment = styled.div`
  padding: 8px 16px;
  color: var(--gray-500);
  cursor: default;
`;

export const Loading = styled.div`
  position: absolute;
  pointer-events: all;
  cursor: default;
  top: 0;
  background: var(--white);
  width: 36px;
  height: 32px;
`;

export const Feedback = styled.span`
  cursor: pointer;
`;
