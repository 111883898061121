import styled from 'styled-components';

export const Container = styled.div`
  hot-breadcrumb {
    margin-top: 24px;
    border: 0;
    display: flex;
    font-size: 12px;

    .breadcrumb__back {
      color: var(--gray-900);
      cursor: pointer;
    }

    .breadcrumb__current {
      color: var(--gray-500);
    }
  }
`;

export const HeaderTitle = styled.div`
  padding: 0;
  color: var(--gray-700);
  font-size: 24px;
  font-weight: 300;
`;

export const HeaderSubtitle = styled.div`
  margin-top: 12px;
  color: var(--gray-500);
  font-size: 16px;
`;

export const Anchor = styled.a``;
