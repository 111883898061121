import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import Files from '@services/files';
import { setUrlParamFormMenu } from '@store/slices/menu';

export const deleteUrlParam = createAsyncThunk<
  UrlParam[],
  void,
  {
    state: LocalState;
  }
>('pageConfig/deleteUrlParam', async (_, { getState, dispatch }) => {
  const {
    pageConfig,
    pageInfo: { uuid },
    menu: {
      urlParamFormMenu: { selectedUrlParam },
    },
  } = getState();

  try {
    dispatch(setUrlParamFormMenu({ loading: true }));
    if (selectedUrlParam.id === '') {
      throw new Error('Url param has no id');
    }

    const updatedUrlParams = [...pageConfig.urlParams].filter(
      (urlParamOnConfig) => urlParamOnConfig.id !== selectedUrlParam.id
    );

    if (pageConfig.urlParams.length === updatedUrlParams.length) {
      throw new Error('No param deleted');
    }

    await Files.saveConfigFile(uuid, {
      ...pageConfig,
      urlParams: updatedUrlParams,
    });

    return updatedUrlParams;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  } finally {
    dispatch(setUrlParamFormMenu({ loading: false }));
  }
});

export const deleteUrlParamBuilder = (
  builder: ActionReducerMapBuilder<PageConfigState>
) => {
  builder.addCase(
    deleteUrlParam.fulfilled,
    (state: PageConfigState, { payload }: PayloadAction<UrlParam[]>) => {
      state.urlParams = payload;
    }
  );
};
