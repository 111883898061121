import '@hotmart-org-ca/cosmos/dist/modal';
import '@hotmart-org-ca/cosmos/dist/modal/modal-header';
import '@hotmart-org-ca/cosmos/dist/modal/modal-body';
import '@hotmart-org-ca/cosmos/dist/modal/modal-footer';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/custom.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/danger.css';

import { HTMLAttributes, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Description, ModalTitle, BackButton } from './styles';

type PublishModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;
  pageUrl: string;
};

const PublishModalComponent: React.FC<PublishModalProps> = ({
  isOpen,
  onClose,
  pageUrl,
  ...attrs
}) => {
  const { t } = useTranslation();
  const hotModal = useRef<any>(null) as any;

  const handleCloseModal = () => {
    hotModal.current.closeModal();
  };

  const handlePageAccessClick = () => {
    window.open(pageUrl, '_blank');
  };

  useEffect(() => {
    const modal = hotModal?.current;
    modal.addEventListener('close', onClose);

    return () => {
      modal.removeEventListener('close', onClose);
    };
  }, [onClose, hotModal]);

  return (
    <Container>
      <hot-modal
        ref={hotModal}
        open={isOpen === true || undefined}
        position="centered"
        id="hot-modal-publish"
        {...attrs}
      >
        <hot-modal-header>
          <ModalTitle>{t('modals.publish.title')}</ModalTitle>
        </hot-modal-header>
        <hot-modal-body>
          <Description>
            {t('modals.publish.description')}
            <a rel="noreferrer" target="_blank" href={pageUrl}>
              {pageUrl}
            </a>
          </Description>
        </hot-modal-body>
        <hot-modal-footer>
          <BackButton
            className="hot-button hot-button--secondary"
            onClick={handleCloseModal}
          >
            {t('modals.publish.backButton')}
          </BackButton>
          <button
            className="hot-button hot-button--primary"
            onClick={handlePageAccessClick}
          >
            {`${t('modals.publish.linkButton')} `}
            <i className="far fa-external-link" />
          </button>
        </hot-modal-footer>
      </hot-modal>
    </Container>
  );
};

export const PublishModal = memo(PublishModalComponent);
