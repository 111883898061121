import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';

import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { Input, Icon, Container, Title, InputGroup } from './styles';
import { useMediaCenter } from '../../../../hooks/useMediaCenter';

export const SearchMedia: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const [searchValue, setSearchValue] = useState('');
  const { search, searchMedia } = useMediaCenter();

  useEffect(() => {
    if (search === '') {
      setSearchValue('');
    }
  }, [search]);

  const { t } = useTranslation();

  const onSetSearchValue = useCallback(
    (value: string) => {
      searchMedia(value);
    },
    [searchMedia]
  );
  const debounceSearch = useRef(debounce(onSetSearchValue, 300));

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchValue(value);
      debounceSearch.current(value);
    },
    [debounceSearch]
  );

  return (
    <Container {...attrs}>
      <Title className="hot-form__label">
        {t('mediaCenter.searchImage.title')}
      </Title>
      <InputGroup>
        <Input
          id="searchMedia"
          className="hot-form__input hot-form__input--sm"
          placeholder={t('mediaCenter.searchImage.placeholder')}
          value={searchValue}
          onChange={(event) => handleInputChange(event)}
          data-prevent-delete
        />
        <Icon className="fal fa-search" />
      </InputGroup>
    </Container>
  );
};
