import { v4 as uuid } from 'uuid';

export const listSections: PageFragmentListItem[] = [
  {
    id: 'list-16',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'List',
        templateId: 'list-2',
        templateName: 'List',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '160px 0px 160px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
                padding: '80px 0px 80px 0px',
              },
              mobile: {
                padding: '48px 0px 48px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F2F2F2',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '350px',
                    padding: '24px 10px 24px 10px',
                    margin: '10px 10px 32px 10px',
                  },
                  mobile: {
                    'max-width': '300px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '140px',
                      href: '',
                      'max-width': '140px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410657/icone-8.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410657/thumb_icone-8.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 15px;">Conte algumas vantagens do método do seu produto.</span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '90%',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F2F2F2',
                  'max-width': '250px',
                  margin: '10px 30px 10px 30px',
                  desktop: {},
                  tablet: {
                    'max-width': '350px',
                    margin: '10px 30px 32px 30px',
                  },
                  mobile: {
                    'max-width': '300px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '140px',
                      href: '',
                      'max-width': '140px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410659/icone-9.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410659/thumb_icone-9.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 15px;">Conte algumas vantagens do método do seu produto.</span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '90%',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F2F2F2',
                  'max-width': '250px',
                  margin: '10px 30px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '350px',
                    margin: '10px 10px 32px 10px',
                  },
                  mobile: {
                    'max-width': '300px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '140px',
                      href: '',
                      'max-width': '140px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410665/icone-10.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410665/thumb_icone-10.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 15px;">Conte algumas vantagens do método do seu produto.</span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '90%',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F2F2F2',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '350px',
                  },
                  mobile: {
                    'max-width': '300px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '140px',
                      href: '',
                      'max-width': '140px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410666/icone-11.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410666/thumb_icone-11.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 15px;">Conte algumas vantagens do método do seu produto.</span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '90%',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-17',
    name: 'List',
    template: {
      background: '#D1BEDD',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'List',
        templateId: 'list-3',
        templateName: 'List',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(97, 91, 155);">Como funciona o (método)?</strong></h1>',
                  'max-width': '100%',
                  padding: '64px 10px 24px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                    text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 40px; color: rgb(97, 91, 155);">Como funciona o (método)?</strong></h1>',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 32px; color: rgb(97, 91, 155);">Como funciona o (método)?</strong></p>',
                    'max-width': '80%',
                    padding: '48px 10px 24px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '32px 0px 32px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
                padding: '32px 0px 48px 0px',
              },
              mobile: {
                padding: '24px 0px 24px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '024px 0px 24px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'transparent',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    padding: '0px 0px 0px 0px',
                    margin: '10px 10px 32px 10px',
                  },
                  mobile: {
                    margin: '10px 10px 24px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '160px',
                      href: '',
                      'max-width': '160px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '120px',
                        'max-width': '120px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410326/icone-5.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410326/thumb_icone-5.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(255, 255, 255); background-color: rgb(97, 91, 155);"> PASSO 1 </strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 8px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 16px;">Tente explicar o caminho que seu cliente irá fazer enquanto consome o produto.</span></p>',
                      'max-width': '100%',
                      padding: '8px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px;">Tente explicar o caminho que seu cliente irá fazer enquanto consome o produto.</span></p>',
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 0px 24px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'transparent',
                  'max-width': '350px',
                  margin: '10px 30px 10px 30px',
                  desktop: {},
                  tablet: {
                    padding: '0px 0px 0px 0px',
                    margin: '10px 0px 32px 0px',
                  },
                  mobile: {
                    margin: '10px 0px 24px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '160px',
                      href: '',
                      'max-width': '160px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '120px',
                        'max-width': '120px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410332/icone-6.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410332/thumb_icone-6.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(255, 255, 255); background-color: rgb(97, 91, 155);"> PASSO 2 </strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 8px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 16px; background-color: rgba(0, 0, 0, 0);">Dividir o seu conteúdo em passos ajuda o cliente entender melhor o que está comprando.</span></p>',
                      'max-width': '100%',
                      padding: '8px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px; background-color: rgba(0, 0, 0, 0);">Dividir o seu conteúdo em passos ajuda o cliente entender melhor o que está comprando.</span></p>',
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 0px 24px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'transparent',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    padding: '0px 0px 0px 0px',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '160px',
                      href: '',
                      'max-width': '160px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '120px',
                        'max-width': '120px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410339/icone-7.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410339/thumb_icone-7.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(255, 255, 255); background-color: rgb(97, 91, 155);"> PASSO 3 </strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 8px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 16px; background-color: rgba(0, 0, 0, 0);">Dessa forma, você aumenta suas chances de venda e entendimento do cliente!</span></p>',
                      'max-width': '100%',
                      padding: '8px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 14px; background-color: rgba(0, 0, 0, 0);">Dessa forma, você aumenta suas chances de venda e entendimento do cliente!</span></p>',
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 56px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#615B9B',
                  background: '#FADA4A',
                  'border-color': '#000000',
                  'border-radius': '16px 16px 16px 16px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '24px',
                  'hover-background': '#FFE675',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO FAZER PARTE!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {
                    'max-width': '250px',
                    'font-size': '18px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-18',
    name: 'List',
    template: {
      background: '#F7B9D4',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'List',
        templateId: 'list-4',
        templateName: 'List',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 48px; color: rgb(97, 91, 155);">Para quem funciona?</strong></h1>',
                  'max-width': '100%',
                  padding: '64px 10px 48px 10px',
                  desktop: {},
                  tablet: {
                    text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 40px; color: rgb(97, 91, 155);">Para quem funciona?</strong></h1>',
                    padding: '56px 10px 48px 10px',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 32px; color: rgb(97, 91, 155);">Para quem funciona?</strong></p>',
                    'max-width': '80%',
                    padding: '40px 10px 32px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '24px 24px 24px 24px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '480px',
                  margin: '10px 24px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410233/icone-1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410233/thumb_icone-1.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 1</strong></p>',
                      'max-width': '100%',
                      padding: '16px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 1</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119);">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15; text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119); font-size: 14px;">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                        padding: '16px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 24px 24px 24px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '480px',
                  margin: '10px 10px 10px 24px',
                  desktop: {},
                  tablet: {
                    margin: '34px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410240/icone-2.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410240/thumb_icone-2.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 2</strong></p>',
                      'max-width': '100%',
                      padding: '16px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 2</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119);">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119); font-size: 14px;">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                        padding: '16px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '24px 0px 88px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
                padding: '24px 0px 80px 0px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 0px 48px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '24px 24px 24px 24px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '480px',
                  margin: '10px 24px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: {
                    margin: '24px 10px 10px 10px',
                    'max-width': '80%',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410244/icone-4.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410244/thumb_icone-4.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 3</strong></p>',
                      'max-width': '100%',
                      padding: '16px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 3</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119);">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15; text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119); font-size: 14px;">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                        padding: '16px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 24px 24px 24px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '480px',
                  margin: '10px 10px 10px 24px',
                  desktop: {},
                  tablet: {
                    margin: '34px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '80%',
                    'align-items': 'center',
                    'justify-content': 'center',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '100px',
                      margin: '0px 0px 0px 10px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5410243/icone-3.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5410243/thumb_icone-3.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 4</strong></p>',
                      'max-width': '100%',
                      padding: '16px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(97, 91, 155);">Buyer Persona 4</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119);">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(119, 119, 119); font-size: 14px;">Conte um pouco das características que mostram que essa persona precisa da sua solução. </span></p>',
                        padding: '16px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-19',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 8',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '40px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 30px;">Veja aqui o que você irá receber ao fazer sua compra HOJE:</strong></p>',
                  'max-width': '45%',
                  padding: '10px 10px 30px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 24px;">Veja aqui o que você irá receber ao fazer sua compra HOJE:</strong></p>',
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif;">[Descreva os BENEFÍCIOS e RESULTADOS que a pessoa terá em cada parte, cada módulo, cada página do seu produto…]</span></p>',
                  'max-width': '85%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '85%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif; font-size: 14px;">[Descreva os BENEFÍCIOS e RESULTADOS que a pessoa terá em cada parte, cada módulo, cada página do seu produto…]</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '300px 300px 300px 300px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#3B44B7',
                  'max-width': '75px',
                  margin: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 35px;">1</strong></p>',
                      'max-width': '120%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 0px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '64%',
                  margin: '10px 10px 10px 16px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MÓDULO 1</strong></p>',
                      'max-width': '70%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="line-height: 1.15;"><span style="font-size: 14px; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      'max-width': '90%',
                      padding: '0px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-size: 13px; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '32px 0px 32px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '300px 300px 300px 300px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#3B44B7',
                  'max-width': '75px',
                  margin: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 35px;">2</strong></p>',
                      'max-width': '120%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 0px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '64%',
                  margin: '10px 10px 10px 16px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MÓDULO 2</strong></p>',
                      'max-width': '70%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="line-height: 1.15;"><span style="font-size: 14px; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      'max-width': '90%',
                      padding: '0px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-size: 13px; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '300px 300px 300px 300px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#3B44B7',
                  'max-width': '75px',
                  margin: '10px 0px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 35px;">3</strong></p>',
                      'max-width': '120%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 0px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '64%',
                  margin: '10px 10px 10px 16px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MÓDULO 3</strong></p>',
                      'max-width': '70%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="line-height: 1.15;"><span style="font-size: 14px; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      'max-width': '90%',
                      padding: '0px 0px 10px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-size: 13px; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '40px 0px 60px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#141436',
                  background: '#61F5B0',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO COMEÇAR AGORA!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '300px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-20',
    name: 'List',
    template: {
      background: '#F3F5FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 9',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '40px 0px 70px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 46px;">VEJA POR QUE VOCÊ DEVE COMEÇAR AGORA</strong></p>',
                  'max-width': '55%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 24px;">VEJA POR QUE VOCÊ DEVE COMEÇAR AGORA</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 18px;">[Insira de 6 a 8 motivos pelos quais a pessoa deve começar agora].</span></p>',
                  'max-width': '60%',
                  padding: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 16px;">[Insira de 6 a 8 motivos pelos quais a pessoa deve começar agora].</span></p>',
                    padding: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '64%',
                  margin: '35px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MOTIVO 1</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '64%',
                  margin: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MOTIVO 2</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '64%',
                  margin: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MOTIVO 3</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '64%',
                  margin: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MOTIVO 4</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#DDE3EF',
                  'max-width': '64%',
                  margin: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    'max-width': '80%',
                    margin: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">MOTIVO 5</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 25px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#141436',
                  'max-width': '85%',
                  margin: '60px 10px 00px 10px',
                  desktop: {},
                  tablet: {
                    padding: '24px 20px 25px 20px',
                  },
                  mobile: {
                    'max-width': '90%',
                    margin: '50px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '90px',
                      href: '',
                      'max-width': '90px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '70px',
                        'max-width': '70px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5394670/icone-5.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5394670/thumb_icone-5.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 25px;">S U P O R T E</strong></p>',
                      'max-width': '50%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '70%',
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 22px;">S U P O R T E</strong></p>',
                        padding: '0px 10px 15px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">[Explique como será seu suporte, passe confiança de que ela será atendida caso precise de ajuda. Descreva aqui todas as formas de suporte que você oferece ao produto.]</span></p>',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '90%',
                      },
                      mobile: {
                        'max-width': '100%',
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">[Explique como será seu suporte, passe confiança de que ela será atendida caso precise de ajuda. Descreva aqui todas as formas de suporte que você oferece ao produto.]</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#141436',
                      background: '#6AFFB9',
                      'border-color': '#EF4E23',
                      'border-radius': '15px 15px 15px 15px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '21px',
                      'hover-background': '#363889',
                      'hover-color': '#6AFFB9',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'QUERO MEU ACESSO AGORA!',
                      margin: '40px 0px 20px 0px',
                      'max-width': '450px',
                      mobile: {
                        'font-size': '18px',
                        'max-width': '277px',
                        margin: '30px 0px 20px 0px',
                        padding: '16px 16px 16px 16px',
                      },
                      padding: '16px 16px 16px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-21',
    name: 'List',
    template: {
      background: 'linear-gradient(#ffffff, #f7fcf5)',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Lista',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '70px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                padding: '50px 0px 50px 0px',
              },
              mobile: {
                padding: '40px 20px 30px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 34px; color: rgb(57, 57, 57);">O que você vai aprender neste curso?</span></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 28px; color: rgb(57, 57, 57);">O que você vai aprender neste curso?</span></p>',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 25px; color: rgb(57, 57, 57);">O que você vai aprender neste curso?</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '200px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '150px',
                    'max-width': '150px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5356340/icone-01-vendas_assets_template-erico-rocha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5356340/thumb_icone-01-vendas_assets_template-erico-rocha.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 18px;">Módulo 1</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 17px;">Módulo 1</strong></p>',
                        'max-width': '60%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(119, 125, 157);">(Um breve resumo do módulo 1 do curso)</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '50px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '200px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '150px',
                    'max-width': '150px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5356352/icone-02-vendas_assets_template-erico-rocha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5356352/thumb_icone-02-vendas_assets_template-erico-rocha.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 18px;">Módulo 2</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 17px;">Módulo 2</strong></p>',
                        'max-width': '60%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(119, 125, 157);">(Um breve resumo do módulo 2 do curso)</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '50px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '200px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '150px',
                    'max-width': '150px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5356360/icone-03-vendas_assets_template-erico-rocha.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5356360/thumb_icone-03-vendas_assets_template-erico-rocha.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 18px;">Módulo 3</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 17px;">Módulo 3</strong></p>',
                        'max-width': '60%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(119, 125, 157);">(Um breve resumo do módulo 3 do curso)</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '20px 20px 50px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 30px; color: rgb(57, 57, 57); background-color: rgb(227, 241, 222);">(Insira aqui um texto de autoridade citando os resultados que os seus alunos já alcançaram usando o seu método)</span></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; font-size: 28px; color: rgb(57, 57, 57); background-color: rgb(227, 241, 222);">(Insira aqui um texto de autoridade citando os resultados que os seus alunos já alcançaram usando o seu método)</span></p>',
                  },
                  mobile: {
                    'max-width': '100%',
                    text: '<p><span style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(57, 57, 57); background-color: rgb(227, 241, 222);">(Insira aqui um texto de autoridade citando os resultados que os seus alunos já alcançaram usando o seu método)</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-22',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 3',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '70px 0px 60px 0px',
              },
              mobile: {
                padding: '40px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 13px;">[Explique ONDE, QUANDO e COMO acontecerá seu evento, ou como pessoa receberá o que foi prometido.]</span></p>',
                  'max-width': '70%',
                  padding: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '95%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(59, 68, 183); font-family: Montserrat, sans-serif; font-size: 12px;">[Explique ONDE, QUANDO e COMO acontecerá seu evento, ou como pessoa receberá o que foi prometido.]</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 27px; color: rgb(20, 20, 54);">UM EVENTO </strong><strong style="font-family: Montserrat, sans-serif; font-size: 27px; color: rgb(20, 20, 54); background-color: rgb(106, 255, 185);"> 100% ONLINE E 100% GRATUITO </strong><strong style="font-family: Montserrat, sans-serif; font-size: 27px; color: rgb(20, 20, 54);">, ONDE VOCÊ VAI APRENDER:</strong></p>',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(20, 20, 54);">UM EVENTO </strong><strong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(20, 20, 54); background-color: rgb(106, 255, 185);"> 100% ONLINE E 100% GRATUITO </strong><strong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(20, 20, 54);">, ONDE VOCÊ VAI APRENDER:</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 30px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '90%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    padding: '10px 10px 22px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '150px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '120px',
                        'max-width': '120px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5397324/icone-11.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5397324/thumb_icone-11.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿</span></strong><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 20px;">ARGUMENTO 1</strong></p>',
                      'max-width': '70%',
                      padding: '10px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿</span></strong><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 17px;">ARGUMENTO 1</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿</span></strong><span style="color: rgb(110, 110, 110); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 14px;">Mostre o principal benefício de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.</span></p>',
                      'max-width': '85%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '100%',
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿</span></strong><span style="color: rgb(110, 110, 110); background-color: transparent; font-family: Montserrat, sans-serif; font-size: 13px;">Mostre o principal benefício de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 47px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '90%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '150px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '120px',
                        'max-width': '120px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5397338/icone-12.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5397338/thumb_icone-12.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿</span></strong><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 20px;">ARGUMENTO 2</strong></p>',
                      'max-width': '70%',
                      padding: '10px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿</span></strong><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 17px;">ARGUMENTO 2</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿﻿</span></strong><span style="color: rgb(110, 110, 110); background-color: transparent; font-size: 14px; font-family: Montserrat, sans-serif;">Mostre a facilidade de consumir esse conteúdo. Fale do formato dele: será PDF, áudio, Ao vivo? Conte os benefícios desse tipo de formato. Atenção: não escreva mais de 4 linhas. </span></p>',
                      'max-width': '85%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '90%',
                      },
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿﻿</span></strong><span style="color: rgb(110, 110, 110); background-color: transparent; font-size: 13px; font-family: Montserrat, sans-serif;">Mostre a facilidade de consumir esse conteúdo. Fale do formato dele: será PDF, áudio, Ao vivo? Conte os benefícios desse tipo de formato. Atenção: não escreva mais de 4 linhas. </span></p>',
                        'max-width': '100%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 30px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '90%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    padding: '10px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '150px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '120px',
                        'max-width': '120px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5397342/icone-13.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5397342/thumb_icone-13.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿﻿</span></strong><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 20px;">ARGUMENTO 3</strong></p>',
                      'max-width': '70%',
                      padding: '10px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿﻿</span></strong><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 17px;">ARGUMENTO 3</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿﻿﻿</span></strong><span style="color: rgb(110, 110, 110); background-color: transparent; font-size: 14px; font-family: Montserrat, sans-serif;">Mostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.</span></p>',
                      'max-width': '85%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '100%',
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54);"><span class="ql-cursor">﻿﻿﻿﻿﻿</span></strong><span style="color: rgb(110, 110, 110); background-color: transparent; font-size: 13px; font-family: Montserrat, sans-serif;">Mostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-23',
    name: 'List',
    template: {
      background: '#E4E3FF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 4',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '72px 0px 48px 0px',
              },
              mobile: {
                padding: '48px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 40px; color: rgb(20, 19, 58);">COM O (NOME DO CURSO) VOCÊ PODE (BENEFÍCIO DO PRODUTO)</strong></p>',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 32px; color: rgb(20, 19, 58);">COM O (NOME DO CURSO) VOCÊ PODE (BENEFÍCIO DO PRODUTO)</strong></p>',
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 24px; color: rgb(20, 19, 58);">COM O (NOME DO CURSO) VOCÊ PODE (BENEFÍCIO DO PRODUTO)</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '24px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '130px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5475451/foguete.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5475451/thumb_foguete.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">&nbsp;[INSERIR AQUI SUA PERSONA] Ex:</strong></p><p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">EMPREENDEDORES</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 16px 10px',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">Breve descrição de como o produto pode ajudar este nicho/persona.</span></p>',
                      'max-width': '90%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '24px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '32px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '130px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5475456/megaphone.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5475456/thumb_megaphone.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '0px 10px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">&nbsp;[INSERIR AQUI SUA PERSONA] Ex:</strong></p><p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">AGÊNCIAS</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 16px 10px',
                      },
                      mobile: {
                        padding: '5px 10px 16px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">Breve descrição de como o produto pode ajudar este nicho/persona.</span></p>',
                      'max-width': '90%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '24px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '32px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '130px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5475528/telefone.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5475528/thumb_telefone.png',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    margin: '10px 10px 0px 10px',
                  },
                  mobile: {
                    padding: '05px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">&nbsp;[INSERIR AQUI SUA PERSONA] Ex:</strong></p><p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 16px;">INFLUENCERS</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        padding: '10px 10px 16px 10px',
                      },
                      mobile: {
                        padding: '0px 10px 16px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">Breve descrição de como o produto pode ajudar este nicho/persona.</span></p>',
                      'max-width': '90%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 88px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '24px 0px 88px 0px',
              },
              mobile: {
                padding: '24px 0px 72px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#14133A',
                  background: '#29FF89',
                  'border-color': '#1EE276',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'solid',
                  'border-top-width': '1px',
                  'border-right-width': '1px',
                  'border-bottom-width': '1px',
                  'border-left-width': '1px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': '#FF3B71',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'COMEÇAR AGORA',
                  margin: '32px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {
                    'font-size': '16px',
                    'max-width': '300px',
                    padding: '16px 16px 16px 16px',
                    margin: '0px 0px 0px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    margin: '24px 0px 0px 0px',
                  },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-24',
    name: 'List',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5455378/bg-captura_assets_template-gi-isquierdo.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 7',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '88px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '80px 0px 40px 0px',
              },
              mobile: {
                padding: '48px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 40px;">COMO O CURSO ESTÁ ORGANIZADO?</strong></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 32px;">COMO O CURSO ESTÁ ORGANIZADO?</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 48px 30px 48px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 48px 20px 48px',
              },
              mobile: {
                padding: '20px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 40px 30px 40px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    padding: '30px 20px 30px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 32px; color: rgb(255, 59, 113);">MÓDULO 01</strong></p>',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 30px; color: rgb(255, 59, 113);">MÓDULO 01</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                      'max-width': '100%',
                      padding: '24px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        padding: '10px 0px 10px 0px',
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '20px 48px 30px 48px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 48px 20px 48px',
              },
              mobile: {
                padding: '20px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 40px 30px 40px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    padding: '30px 20px 30px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 32px; color: rgb(255, 59, 113);">MÓDULO 02</strong></p>',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 30px; color: rgb(255, 59, 113);">MÓDULO 02</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                      'max-width': '100%',
                      padding: '24px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                        padding: '10px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '30px 48px 116px 48px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 48px 20px 48px',
              },
              mobile: {
                padding: '20px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 40px 30px 40px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    padding: '30px 20px 30px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 32px; color: rgb(255, 59, 113);">MÓDULO 03</strong></p>',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 30px; color: rgb(255, 59, 113);">MÓDULO 03</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                      'max-width': '100%',
                      padding: '24px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                        padding: '10px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '30px 48px 116px 48px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 48px 88px 48px',
              },
              mobile: {
                padding: '20px 0px 64px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 40px 30px 40px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    padding: '30px 20px 30px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 32px; color: rgb(255, 59, 113);">MÓDULO 04</strong></p>',
                      'max-width': '80%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                        text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; font-size: 30px; color: rgb(255, 59, 113);">MÓDULO 04</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58);">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                      'max-width': '100%',
                      padding: '24px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 19, 58); font-family: Montserrat, sans-serif; font-size: 14px;">[Breve descrição do conteúdo que não que não passe de 4 linhas.] </span><span style="font-family: Montserrat, sans-serif; color: rgb(20, 19, 58); font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</span></p>',
                        padding: '10px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'list-1',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '48px 0px 48px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 16px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eTópico 2 do conteúdo gratuito\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eTópico 3 do conteúdo gratuito\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eTópico 4 do conteúdo gratuito\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '484px',
                  href: '',
                  'max-width': '556px',
                  margin: '1px 0px 0px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5224556/group_76.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5224556/thumb_group_76.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 2' },
    },
  },
  {
    id: 'list-2',
    name: 'List',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 2' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '48px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F1F1F1',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F1F1F1',
                  'max-width': '250px',
                  margin: '11px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F1F1F1',
                  'max-width': '250px',
                  margin: '11px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F1F1F1',
                  'max-width': '250px',
                  margin: '11px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-3',
    name: 'List',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 3' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003eComo funciona o (método)?\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '48px 10px 24px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 24px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'transparent',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003ePasso 1\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 8px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eTente explicar o caminho que seu cliente irá fazer enquanto consome o produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'transparent',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003ePasso 2\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 8px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eDividir o seu conteúdo em passos ajuda o cliente entender melhor o que está comprando.\u003cspan class="ql-cursor"\u003e﻿﻿\u003c/span\u003e\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'transparent',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003ePasso 3\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 8px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eDessa forma, você aumenta suas chances de venda e entendimento do cliente!\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 48px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#FFFFFF',
                  background: '#424242',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Nunito Sans, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Quero fazer parte!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-4',
    name: 'List',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 4' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003ePara quem funciona?\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '48px 10px 24px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '480px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003eBuyer Persona 1\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução\u003c/span\u003e\u003cspan style="color: rgb(28, 28, 28);"\u003e. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '480px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003eBuyer Persona 2\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução\u003c/span\u003e\u003cspan style="color: rgb(28, 28, 28);"\u003e. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '480px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003eBuyer Persona 3\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução\u003c/span\u003e\u003cspan style="color: rgb(28, 28, 28);"\u003e. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '480px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'none',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5224585/group_80.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5224585/thumb_group_80.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003eBuyer Persona 4\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '8px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução\u003c/span\u003e\u003cspan style="color: rgb(28, 28, 28);"\u003e. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '16px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-5',
    name: 'List',
    template: {
      background: '#FF735C',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '80px 0px 50px 0px' },
              mobile: { padding: '50px 20px 30px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '8px 10px 8px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '460px',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 7px 0px 7px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5288249/icone_graduation-cap.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5288249/thumb_icone_graduation-cap.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 22px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 14px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 20px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 20px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '8px 10px 8px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '460px',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 15px 0px 15px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5288248/icone_file-alt.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5288248/thumb_icone_file-alt.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 22px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 14px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 20px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 20px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '8px 10px 8px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '460px',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 11px 0px 11px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5288250/icone_pencil.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5288250/thumb_icone_pencil.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 22px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 14px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 20px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 20px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 19px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '8px 10px 8px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '460px',
                  margin: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '65px',
                      href: '',
                      'max-width': '65px',
                      margin: '0px 15px 0px 15px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5288246/icone_book-alt.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5288246/thumb_icone_book-alt.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 22px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 14px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 20px;"\u003eTópico 1 do conteúdo gratuito\u003c/strong\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 20px;"\u003e \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 18px;"\u003eConte o que você ensina nesse material\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 50px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'start',
                padding: '0px 0px 50px 50px',
              },
              mobile: { padding: '0px 40px 50px 40px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '400px',
                  href: '',
                  'max-width': '400px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5288302/ilustra_captura.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5288302/thumb_ilustra_captura.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 2' },
    },
  },
  {
    id: 'list-6',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 120px 0px 120px' },
              mobile: { padding: '0px 31px 0px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 25px 30px 25px',
                  'border-radius': '5px 5px 5px 5px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#7676B7',
                  'max-width': '100%',
                  margin: '20px 30px 20px 30px',
                  desktop: {},
                  tablet: {
                    'flex-direction': 'row',
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 30px 10px 30px',
                  },
                  mobile: {
                    'flex-direction': 'column',
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '20px 25px 20px 25px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { height: '120px' },
                      mobile: { height: '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282895/icone_alvo.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282895/thumb_icone_alvo.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '20px 10px 20px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '50px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '5px 120px 5px 120px' },
              mobile: { padding: '5px 30px 5px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 25px 30px 25px',
                  'border-radius': '5px 5px 5px 5px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#7676B7',
                  'max-width': '100%',
                  margin: '20px 30px 20px 30px',
                  desktop: {},
                  tablet: {
                    'flex-direction': 'row',
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 30px 10px 30px',
                  },
                  mobile: {
                    'flex-direction': 'column',
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '20px 25px 20px 25px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { height: '120px' },
                      mobile: { height: '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282899/icone_foguete.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282899/thumb_icone_foguete.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '20px 10px 20px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '50px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '5px 120px 5px 120px' },
              mobile: { padding: '5px 30px 5px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 25px 30px 25px',
                  'border-radius': '5px 5px 5px 5px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#7676B7',
                  'max-width': '100%',
                  margin: '20px 30px 20px 30px',
                  desktop: {},
                  tablet: {
                    'flex-direction': 'row',
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 30px 10px 30px',
                  },
                  mobile: {
                    'flex-direction': 'column',
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '20px 25px 20px 25px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { height: '120px' },
                      mobile: { height: '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282900/icone_lampada.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282900/thumb_icone_lampada.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '20px 10px 20px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '50px 20px 90px 20px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '5px 120px 50px 120px' },
              mobile: { padding: '5px 30px 50px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 25px 30px 25px',
                  'border-radius': '5px 5px 5px 5px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#7676B7',
                  'max-width': '100%',
                  margin: '20px 30px 20px 30px',
                  desktop: {},
                  tablet: {
                    'flex-direction': 'row',
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 30px 10px 30px',
                  },
                  mobile: {
                    'flex-direction': 'column',
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '20px 25px 20px 25px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '100px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { height: '120px' },
                      mobile: { height: '110px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282902/icone-estrela.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282902/thumb_icone-estrela.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '20px 10px 20px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003eConte algumas vantagens do método do seu produto.\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 2' },
    },
  },
  {
    id: 'list-7',
    name: 'List',
    template: {
      background: '#EDF0F4',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 30px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 30px 0px' },
              mobile: { padding: '50px 20px 30px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 40px;"\u003eComo funciona o (método)?\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '10px 10px 10px 10px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '10px 30px 10px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 30px 40px 30px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '20px 30px 20px 30px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282986/icone_01.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282986/thumb_icone_01.png',
                    },
                    {
                      text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003ePasso 1\u003c/strong\u003e\u003c/h1\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eTente explicar o caminho que seu cliente irá fazer enquanto consome o produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '10px 10px 10px 10px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '10px 30px 10px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 30px 40px 30px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '20px 30px 20px 30px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282990/icone_02.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282990/thumb_icone_02.png',
                    },
                    {
                      text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003ePasso 2\u003c/strong\u003e\u003c/h1\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eDividir o seu conteúdo em passos ajuda o cliente entender melhor o que está comprando.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '10px 10px 10px 10px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '10px 30px 10px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 30px 40px 30px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '350px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '20px 30px 20px 30px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '150px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '100px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5282991/icone_03.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5282991/thumb_icone_03.png',
                    },
                    {
                      text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003ePasso 3\u003c/strong\u003e\u003c/h1\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eDessa forma, você aumenta suas chances de venda e entedimento do cliente!\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 90px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '20px 0px 50px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#000000',
                  background: '#79E0BB',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Quero fazer parte!\n',
                  margin: '0px 0px 0px 0px',
                  'max-width': '300px',
                  mobile: {},
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 3' },
    },
  },
  {
    id: 'list-8',
    name: 'List',
    template: {
      background: '#FFC673',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 30px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '40px 0px 30px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-size: 40px; font-family: Raleway, sans-serif;"\u003ePara quem funciona?\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 14px 90px 14px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 100px 10px 100px' },
              mobile: { padding: '20px 60px 20px 60px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '35px',
                  href: '',
                  'max-width': '60px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284872/icone_s_book.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284872/thumb_icone_s_book.png',
                },
                {
                  text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003eBuyer Persona 1\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 14px 90px 14px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 100px 10px 100px' },
              mobile: { padding: '20px 60px 20px 60px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '35px',
                  href: '',
                  'max-width': '60px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284890/icone_s_usd-circle.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284890/thumb_icone_s_usd-circle.png',
                },
                {
                  text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003eBuyer Persona 2\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 14px 90px 14px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 100px 10px 100px' },
              mobile: { padding: '20px 60px 20px 60px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '35px',
                  href: '',
                  'max-width': '60px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284888/icone_s_bullhorn.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284888/thumb_icone_s_bullhorn.png',
                },
                {
                  text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003eBuyer Persona 3\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 14px 90px 14px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 100px 50px 100px' },
              mobile: { padding: '20px 60px 50px 60px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '35px',
                  href: '',
                  'max-width': '60px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284889/icone_s_smile.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284889/thumb_icone_s_smile.png',
                },
                {
                  text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 26px; font-family: Raleway, sans-serif;"\u003eBuyer Persona 4\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eConte um pouco das características que mostram que essa persona precisa da sua solução.\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 4' },
    },
  },
  {
    id: 'list-9',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Benefícios' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 45px;"\u003eO que você vai aprender?\u003c/span\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '50px 10px 30px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003ch1 style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 35px;"\u003eO que você vai aprender?\u003c/span\u003e\u003c/h1\u003e',
                    padding: '20px 20px 15px 20px',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 25px;"\u003eO que você vai aprender?\u003c/span\u003e\u003c/p\u003e',
                    padding: '35px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '10px 20px 0px 20px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 10px 0px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 5px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '100px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'contain',
                    'max-width': '250px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5282063/livros.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5282063/thumb_livros.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '10px 10px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 16px;"\u003eArgumento 1\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: { padding: '10px 10px 10px 15px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px; color: rgb(126, 126, 126);"\u003eMostre o principal benefício de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 12px; color: rgb(126, 126, 126);"\u003eMostre o principal benefício de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                        padding: '0px 0px 0px 15px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '20px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 20px 0px 20px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                background: 'transparent',
                padding: '0px 10px 0px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '100px',
                    'object-fit': 'contain',
                    margin: '0px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5282065/lampada.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5282065/thumb_lampada.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    padding: '10px 10px 0px 0px',
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 16px;"\u003eArgumento 2\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: { padding: '10px 10px 10px 15px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 15px; font-family: Montserrat, sans-serif; color: rgb(126, 126, 126);"\u003eMostre a facilidade de consumir esse conteúdo. Fale do formato dele: será PDF, áudio, ao vivo? Conte os benefícios desse tipo de formato. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 12px; font-family: Montserrat, sans-serif; color: rgb(126, 126, 126);"\u003eMostre a facilidade de consumir esse conteúdo. Fale do formato dele: será PDF, áudio, Ao vivo? Conte os benefícios desse tipo de formato . Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                        padding: '0px 0px 0px 15px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 70px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 20px 0px 20px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 10px 0px 010px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { height: '100px', 'object-fit': 'contain' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5282069/computador.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5282069/thumb_computador.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '10px 10px 10px 10px',
                    padding: '10px 10px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eArgumento 3\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: { padding: '10px 10px 10px 15px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px; color: rgb(126, 126, 126);"\u003eMostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 12px; color: rgb(126, 126, 126);"\u003eMostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                        padding: '0px 0px 0px 15px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '60px 0px 70px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 20px 50px 20px',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 10px 25px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { height: '100px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5282071/pessoas.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5282071/thumb_pessoas.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '10px 10px 0px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eArgumento 4\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: { padding: '10px 10px 10px 15px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 15px; font-family: Montserrat, sans-serif; color: rgb(126, 126, 126);"\u003eUse a urgência do conteúdo, mostrando que essa página pode sair a qualquer momento do ar. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 12px; font-family: Montserrat, sans-serif; color: rgb(126, 126, 126);"\u003eUse a urgência do conteúdo, mostrando que essa página pode sair a qualquer momento do ar. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                        padding: '0px 00px 10px 15px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-10',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Persona' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '30px 0px 15px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289133/persona-1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289133/thumb_persona-1.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eFeito para\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003e\u003cspan class="ql-cursor"\u003e﻿\u003c/span\u003e(Persona 1)\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '15px 0px 15px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289174/persona-2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289174/thumb_persona-2.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eFeito para\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003e\u003cspan class="ql-cursor"\u003e﻿﻿\u003c/span\u003e(Persona 2)\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '15px 0px 30px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5289180/persona-3.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5289180/thumb_persona-3.png',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eFeito para\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003e\u003cspan class="ql-cursor"\u003e﻿﻿\u003c/span\u003e(Persona 3)\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-11',
    name: 'List',
    template: {
      background: '#F7F9FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 50px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '90px 30px 50px 30px' },
              mobile: { padding: '50px 30px 20px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-size: 32px; font-family: Montserrat, sans-serif;"\u003eComo é possível alcançar esses resultados?\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-size: 28px; font-family: Montserrat, sans-serif;"\u003eComo é possível alcançar esses resultados?\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eVocê vai precisar de três pontos:\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '20px 10px 20px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eVocê vai precisar de três pontos:\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5314079/ilustra_grafico.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5314079/thumb_ilustra_grafico.png',
                },
              ],
            },
            {
              padding: '90px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '30px 0px 50px 0px' },
              mobile: { padding: '20px 30px 20px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '5px 10px 5px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '50px',
                        'object-fit': 'contain',
                        'max-width': '40px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5312087/numero_01.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5312087/thumb_numero_01.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif;"\u003ePasso número 1\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eTente explicar o caminho que seu cliente irá fazer enquanto consome o produto.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003ePasso número 1\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eTente explicar o caminho que seu cliente irá fazer enquanto consome o produto.\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '5px 10px 5px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'object-fit': 'contain',
                        'max-width': '40px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5312088/numero_02.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5312088/thumb_numero_02.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif;"\u003ePasso número 2\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eDividir o seu conteúdo em passos ajuda o cliente entender melhor o que está comprando.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003ePasso número 2\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eDividir o seu conteúdo em passos ajuda o cliente entender melhor o que está comprando.\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '500px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '5px 10px 5px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '10px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'object-fit': 'contain',
                        'max-width': '40px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5312089/numero_03.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5312089/thumb_numero_03.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif;"\u003ePasso número 3\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eDessa forma, você aumenta suas chances de venda e entendimento do cliente!\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 20px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003ePasso número 3\u003c/strong\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eDessa forma, você aumenta suas chances de venda e entendimento do cliente!\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 3' },
    },
  },
  {
    id: 'list-12',
    name: 'List',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 30px 0px 30px' },
              mobile: {
                background: '#FFFFFF',
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
                padding: '50px 30px 0px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F2F6F8',
                  'max-width': '350px',
                  margin: '20px 20px 20px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '200px',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '45px',
                      href: '',
                      'max-width': '50px',
                      margin: '0px 10px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314190/icon_book.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314190/thumb_icon_book.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eArgumento 1\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '5px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eArgumento 1\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-size: 15px; font-family: Montserrat, sans-serif;"\u003eMostre o principal benefício de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 13px; font-family: Montserrat, sans-serif;"\u003eMostre o principal benefício de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F2F6F8',
                  'max-width': '350px',
                  margin: '20px 20px 20px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '200px',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '0px 5px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314191/icon_bullhorn.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314191/thumb_icon_bullhorn.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eArgumento 2\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '5px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eArgumento 2\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px;"\u003eMostre a facilidade de consumir esse conteúdo. Fale do formato dele: será PDF, áudio, Ao vivo? Conte os benefícios desse tipo de formato . Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 13px;"\u003eMostre a facilidade de consumir esse conteúdo. Fale do formato dele: será PDF, áudio, Ao vivo? Conte os benefícios desse tipo de formato . Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F2F6F8',
                  'max-width': '350px',
                  margin: '20px 20px 20px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '200px',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '0px 5px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314192/icon_bullseye-arrow.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314192/thumb_icon_bullseye-arrow.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eArgumento 3\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '5px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eArgumento 3\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px;"\u003eMostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 13px;"\u003eMostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 30px 50px 30px' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'start',
                background: '#FFFFFF',
                padding: '0px 30px 50px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F2F6F8',
                  'max-width': '350px',
                  margin: '20px 20px 20px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '200px',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '0px 12px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314193/icon_lightbulb.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314193/thumb_icon_lightbulb.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eArgumento 4\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '5px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eArgumento 4\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px;"\u003eUse a urgência do conteúdo, mostrando que essa página pode sair a qualquer momento do ar. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 13px;"\u003eUse a urgência do conteúdo, mostrando que essa página pode sair a qualquer momento do ar. Atenção: não escreva mais de 4 linhas. \u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F2F6F8',
                  'max-width': '350px',
                  margin: '20px 20px 20px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '200px',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '0px 2px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314195/icon_rocket.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314195/thumb_icon_rocket.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eArgumento 5\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '5px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eArgumento 5\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px;"\u003eMostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 13px;"\u003eMostre o benefício secundário de baixar sua isca ou participar do seu evento. Fale especificamente qual será o assunto abordado e como isso ajuda na vida do seu público. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'start',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: '#F2F6F8',
                  'max-width': '350px',
                  margin: '20px 20px 24px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '200px',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '50px',
                      margin: '0px 5px 0px 10px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5314199/icon_usd-circle.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5314199/thumb_icon_usd-circle.png',
                    },
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eArgumento 6\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '5px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 16px;"\u003eArgumento 6\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 15px;"\u003eUse a urgência do conteúdo, mostrando que essa página pode sair a qualquer momento do ar. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 13px;"\u003eUse a urgência do conteúdo, mostrando que essa página pode sair a qualquer momento do ar. Atenção: não escreva mais de 4 linhas.\u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp\u003e\u003cbr\u003e\u003c/p\u003e',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 5' },
    },
  },
  {
    id: 'list-13',
    name: 'List',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5307674/bg-obrigado_assets_template-orange-chic.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Para quem é' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 40px;"\u003ePara quem é o produto\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '60%',
                  padding: '80px 10px 50px 10px',
                  desktop: {},
                  tablet: { padding: '50px 10px 50px 10px' },
                  mobile: {
                    'max-width': '90%',
                    padding: '30px 10px 20px 10px',
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 26px;"\u003ePara quem é o produto\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'row',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '0px 10px 0px 10px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { 'max-width': '200px' },
                  mobile: { height: '120px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315175/foguete.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315175/thumb_foguete.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    padding: '10px 0px 10px 0px',
                    margin: '10px 0px 10px 0px',
                    'max-width': '60%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 17px;"\u003e[INSERIR AQUI PERSONA Ex: empreendedores]\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 17px;"\u003e[INSERIR AQUI PERSONA Ex: empreendedores]\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 15px;"\u003e[INSERIR AQUI PERSONA Ex: empreendedores]\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'row',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 20px 0px',
              },
              mobile: { padding: '0px 10px 0px 10px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { 'max-width': '200px' },
                  mobile: { height: '120px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315185/megafone.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315185/thumb_megafone.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '60%',
                    padding: '10px 0px 10px 0px',
                    margin: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 17px;"\u003e[INSERIR AQUI PERSONA Ex: agências]\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 17px;"\u003e[INSERIR AQUI PERSONA Ex: agências]\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 15px;"\u003e[INSERIR AQUI PERSONA Ex: agências]\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'flex-direction': 'row',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '0px 10px 0px 10px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { 'max-width': '200px' },
                  mobile: { height: '120px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315228/telefone.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315228/thumb_telefone.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '250px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '60%',
                    padding: '10px 0px 10px 0px',
                    margin: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 17px;"\u003e[INSERIR AQUI PERSONA Ex: influencers]\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 17px;"\u003e[INSERIR AQUI PERSONA Ex: influencers]\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(255, 124, 53); font-size: 15px;"\u003e[INSERIR AQUI PERSONA Ex: influencers]\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;"\u003eBreve descrição de como o produto pode ajudar este nicho/persona\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '20px 0px 40px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#222222',
                  background: '#00FFFA',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 100px 100px 100px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '19px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'COMEÇAR AGORA',
                  margin: '20px 0px 20px 10px',
                  'max-width': '460px',
                  mobile: { 'max-width': '80%', 'font-size': '18px' },
                  padding: '15px 15px 15px 15px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-14',
    name: 'List',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Lista' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '80%',
                  margin: '60px 10px 40px 10px',
                  desktop: {},
                  tablet: { 'max-width': '70%' },
                  mobile: {
                    'max-width': '80%',
                    margin: '30px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 30px;"\u003eO que você vai aprender com o(a) [NOME DO AUTOR]:\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 21px;"\u003eO que você vai aprender com o(a) [NOME DO AUTOR]:\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 60px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '90%',
                    padding: '0px 0px 20px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '180px',
                      href: '',
                      'max-width': '200px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '120px', 'max-width': '120px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5334935/icone-1-vendas_assets_template-navy-blue.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5334935/thumb_icone-1-vendas_assets_template-navy-blue.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eMódulo 1\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eTema do módulo\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cspan style="color: rgb(141, 158, 187); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 12px;"\u003eAqui é o momento de você detalhar um pouco deste módulo, mas com uma linguagem comercial e que gere curiosidade. Não entregue tudo, o ideal é instigar a curiosidade da pessoa.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: { 'max-width': '50%' },
                      mobile: { 'max-width': '80%' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 60px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { padding: '0px 0px 60px 0px' },
                  mobile: {
                    'max-width': '90%',
                    padding: '0px 0px 20px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '180px',
                      href: '',
                      'max-width': '200px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '120px', 'max-width': '120px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5334954/icone-2-vendas_assets_template-navy-blue.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5334954/thumb_icone-2-vendas_assets_template-navy-blue.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eMódulo 2\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eTema do módulo\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cspan style="color: rgb(141, 158, 187); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 12px;"\u003eAqui é o momento de você detalhar um pouco deste módulo, mas com uma linguagem comercial e que gere curiosidade. Não entregue tudo, o ideal é instigar a curiosidade da pessoa.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: { 'max-width': '50%' },
                      mobile: { 'max-width': '80%' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '20px 0px 80px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { padding: '0px 0px 80px 0px' },
                  mobile: {
                    padding: '0px 0px 20px 0px',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '180px',
                      href: '',
                      'max-width': '200px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '120px', 'max-width': '120px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5334976/icone-3-vendas_assets_template-navy-blue.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5334976/thumb_icone-3-vendas_assets_template-navy-blue.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eMódulo 3\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eTema do módulo\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cspan style="color: rgb(141, 158, 187); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 12px;"\u003eAqui é o momento de você detalhar um pouco deste módulo, mas com uma linguagem comercial e que gere curiosidade. Não entregue tudo, o ideal é instigar a curiosidade da pessoa.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: { 'max-width': '50%' },
                      mobile: { 'max-width': '80%' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '20px 0px 80px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { padding: '0px 0px 60px 0px' },
                  mobile: { padding: '0px 0px 40px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '180px',
                      href: '',
                      'max-width': '200px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: { height: '120px', 'max-width': '120px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5334979/icone-4-vendas_assets_template-navy-blue.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5334979/thumb_icone-4-vendas_assets_template-navy-blue.png',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eMódulo 4\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(106, 129, 229); font-size: 15px;"\u003eTema do módulo\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15; text-align: center;"\u003e\u003cspan style="color: rgb(141, 158, 187); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 12px;"\u003eAqui é o momento de você detalhar um pouco deste módulo, mas com uma linguagem comercial e que gere curiosidade. Não entregue tudo, o ideal é instigar a curiosidade da pessoa.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: { 'max-width': '50%' },
                      mobile: { 'max-width': '80%' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'list-15',
    name: 'List',
    template: {
      background: '#6A81E5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Bônus' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 30px;"\u003eFazendo parte do [INSERIR NOME DO CURSO] você receberá totalmente grátis o acesso para esses presentes exclusivos:\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '80px 10px 60px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 24px;"\u003eFazendo parte do [INSERIR NOME DO CURSO] você receberá totalmente grátis o acesso para esses presentes exclusivos:\u003c/strong\u003e\u003c/p\u003e',
                    padding: '70px 10px 60px 10px',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="color: rgb(255, 255, 255); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 21px;"\u003eFazendo parte do [INSERIR NOME DO CURSO] você receberá totalmente grátis o acesso para esses presentes exclusivos:\u003c/strong\u003e\u003c/p\u003e',
                    padding: '50px 10px 50px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 250px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 0px 180px' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5336238/bonus-1-vendas_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5336238/thumb_bonus-1-vendas_assets_template-navy-blue.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '70%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 10px 10px 10px',
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003e Bônus 1\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '40%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 16px;"\u003e Bônus 1\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '70%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255);"\u003eBreve descrição do bônus que não passe de 4 linhas\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: { padding: '10px 10px 10px 15px' },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 14px;"\u003eBreve descrição do bônus que não passe de 4 linhas\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '100%',
                        padding: '0px 10px 10px 15px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 0px 50px 250px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 50px 180px' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '30px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 20px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { margin: '0px 0px 20px 0px' },
                  mobile: { 'max-width': '150px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5336258/bonus-2-vendas_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5336258/thumb_bonus-2-vendas_assets_template-navy-blue.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '70%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003e Bônus 2\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '40%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 16px;"\u003e Bônus 2\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '70%',
                        padding: '0px 10px 10px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255);"\u003eBreve descrição do bônus que não passe de 4 linhas\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: { padding: '10px 10px 10px 15px' },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 14px;"\u003eBreve descrição do bônus que não passe de 4 linhas\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '100%',
                        padding: '0px 10px 10px 15px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 80px 250px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 80px 180px' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5336270/bonus-3-vendas_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5336270/thumb_bonus-3-vendas_assets_template-navy-blue.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'start',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '70%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;"\u003e Bônus 3\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '40%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 16px;"\u003e Bônus 3\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '70%',
                        padding: '0px 10px 10px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255);"\u003eBreve descrição do bônus que não passe de 4 linhas\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: { padding: '10px 10px 10px 15px' },
                      mobile: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 14px;"\u003eBreve descrição do bônus que não passe de 4 linhas\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '100%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
