import { HTMLAttributes, memo } from 'react';
import { Container, Item } from './styles';

export type ScriptsSwitchProps = HTMLAttributes<HTMLDivElement> & {
  selected?: ScriptType;
  onItemClick?: (type: ScriptType) => void;
};

const ScriptsSwitchComponent: React.FC<ScriptsSwitchProps> = ({
  selected = 'head',
  onItemClick,
  ...attrs
}) => (
  <Container {...attrs}>
    <Item selected={selected === 'head'} onClick={() => onItemClick?.('head')}>
      Head
    </Item>
    <Item
      selected={selected === 'body'}
      showLeftBorder
      onClick={() => onItemClick?.('body')}
    >
      Body
    </Item>
  </Container>
);

export const ScriptsSwitch = memo(ScriptsSwitchComponent);
