import { HTMLAttributes, memo } from 'react';
import { Icon } from './styles';

export type ExternalLinkComponentProps = HTMLAttributes<HTMLAnchorElement> & {
  href: string;
  openInNewTab?: boolean;
  text: string;
};

const ExternalLinkComponent: React.FC<ExternalLinkComponentProps> = ({
  href,
  text,
  openInNewTab,
  ...attrs
}) => (
  <>
    <a
      href={href}
      {...(openInNewTab && { target: '_blank', rel: 'noreferrer' })}
      {...attrs}
    >
      {text}
      <Icon className="far fa-external-link" />
    </a>
    .
  </>
);
export const ExternalLink = memo(ExternalLinkComponent);
