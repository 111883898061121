import axios from 'axios';
import imageCompression, { Options } from 'browser-image-compression';
import { rebrand } from '@config/rebrand';
import Http from '../http';

const SIZE_1_MB = 1 * 1024 * 1024;
const SIZE_10_MB = 10 * 1024 * 1024;

export default class MediaCenter {
  private static api = new Http();

  private static extensionRegex = /(\.[^/.]+$)/;

  private static s3UploadFileConfig = {
    withCredentials: false,
    headers: {
      'Cache-Control': 'must-revalidate,public,max-age=86400',
    },
  };

  public static async getRootMedia(): Promise<Media[]> {
    const { media_folder_items: media } = await this.api.get<RootMediaResponse>(
      `${rebrand.urls.KLICKART_URL}/api/media_center`
    );

    return this.parseMediaResponse(media);
  }

  public static async getRootFolders() {
    const folderData = await this.api.get<FolderData[]>(
      `${rebrand.urls.KLICKART_URL}/api/media_folders`
    );

    const folders: Folder[] = folderData.map((folder) => ({
      ...folder,
      id: folder.id.toString(),
      type: 'media_folder',
      parentId: folder.media_folder_id
        ? folder.media_folder_id.toString()
        : null,
    }));

    return folders;
  }

  public static moveItem(type: MediaType, id: string, destinationFolder?: any) {
    const data = new FormData();
    const route = type === 'media' ? 'medias' : 'media_folders';
    const parseMediaResponse =
      destinationFolder === 'home' ? null : destinationFolder;

    if (type === 'media') {
      data.append('media[media_folder_id]', parseMediaResponse || '');
    }
    if (type === 'media_folder') {
      data.append('media_folder[media_folder_id]', parseMediaResponse || '');
    }
    return this.api.patch(
      `${rebrand.urls.KLICKART_URL}/api/${route}/${id}`,
      data
    );
  }

  public static async getFolderMedia(folderId: string): Promise<Media[]> {
    const { media_folder_items: media } = await this.api.get<RootMediaResponse>(
      `${rebrand.urls.KLICKART_URL}/api/media_folders/${folderId}`
    );

    return this.parseMediaResponse(media);
  }

  public static async openFolder(
    folderId: string
  ): Promise<OpenFolderResponse> {
    const folderMedia = await this.getFolderMedia(folderId);
    const breadcrumbs = await this.getBreadcrumbs(folderId);

    return { folderMedia, breadcrumbs };
  }

  public static async getMediaBySearchString(search: string) {
    const routePath = `${rebrand.urls.KLICKART_URL}/api/media_items/search?search_string=${search}`;
    const media = await this.api.get<SearchMediaResponse>(routePath);

    return this.parseMediaResponse(media);
  }

  private static parseMediaResponse(media: Media[]) {
    return media.map((item) => {
      if (item.type === 'media') {
        const match = item.name.match(this.extensionRegex);

        if (match) {
          const [, extension] = match;
          const name = item.name.replace(this.extensionRegex, '');
          return { ...item, id: item.id.toString(), name, extension };
        }
      }

      return { ...item, id: item.id.toString() };
    });
  }

  private static fileToBlob(file: File) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const binary = atob((reader?.result as string).split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i += 1) {
          array.push(binary.charCodeAt(i));
        }

        const blobData = new Blob([new Uint8Array(array)], { type: 'image/*' });
        resolve(blobData);
      };
    });
  }

  private static async getMediaBlobs(file: File) {
    const compressedThumb = await this.compressImage(file, {
      initialQuality: 0.1,
      maxSizeMB: 5,
    });
    const compressedImage = await this.compressImage(file, {
      initialQuality: 1,
      maxSizeMB: 5,
    });

    const imageBlobData = await this.fileToBlob(compressedImage);
    const thumbBlobData = await this.fileToBlob(compressedThumb);

    return [imageBlobData, thumbBlobData];
  }

  private static async getFileUploadData(filename: string, folderId?: string) {
    const mediaData = new FormData();

    mediaData.append('files[0][name]', filename);

    if (folderId) {
      mediaData.append('files[0][media_folder_id]', folderId as string);
    }

    const [fileData] = await this.api.post<MediaFileData>(
      `${rebrand.urls.KLICKART_URL}/api/medias`,
      mediaData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    return fileData;
  }

  public static async uploadMedia(file: File, folderId?: string) {
    const [imageBlob, thumbBlob] = await this.getMediaBlobs(file);
    const fileData = await this.getFileUploadData(file.name, folderId);
    const { presigned_url: imageUrl, presigned_url_thumb: thumbUrl } = fileData;

    const promises = [
      axios.put(imageUrl, imageBlob, this.s3UploadFileConfig),
      axios.put(thumbUrl, thumbBlob, this.s3UploadFileConfig),
    ];

    await Promise.all(promises);

    return fileData;
  }

  public static createFolder(folderName: string, parentFolder?: string) {
    const folderData = new FormData();

    folderData.append('media_folder[name]', folderName);
    if (parentFolder !== undefined) {
      folderData.append('media_folder[media_folder_id]', parentFolder);
    }

    return this.api.post<FolderFileData>(
      `${rebrand.urls.KLICKART_URL}/api/media_folders`,
      folderData
    );
  }

  public static async getBreadcrumbs(folderId: string): Promise<Breadcrumb[]> {
    const breadCrumbData = await this.api.get<BreadcrumbData>(
      `${rebrand.urls.KLICKART_URL}/api/media_folders/breadcrumbs/${folderId}`
    );

    const breadCrumbsArray = [];
    let parent: Breadcrumbs | null = breadCrumbData.breadcrumbs;

    while (parent !== null) {
      breadCrumbsArray.push({ id: parent.id, name: parent.name });
      parent = parent.parent;
    }

    return breadCrumbsArray.reverse();
  }

  public static isValidMedia(file: File, extensions: string[] = []) {
    const extensionRegex = /(\.[^/.]+$)/;
    const match = file.name.match(extensionRegex);
    const allowedExtensions = extensions.length
      ? new RegExp(`(${extensions.join('|')})$`, 'i')
      : /(\.jpg|\.png|\.jpeg|\.webp|\.svg)$/i;

    if (match) {
      const [, extension] = match;
      return Boolean(allowedExtensions.exec(extension));
    }

    return false;
  }

  public static isValidMediaSize(media: MediaFile) {
    return media.isFreePlan
      ? media.size <= SIZE_1_MB
      : media.size <= (media.compareSize || SIZE_10_MB);
  }

  private static compressImage(image: File, options: Options): Promise<File> {
    return imageCompression(image, options);
  }

  public static updateItemName(id: string, type: MediaType, newName: string) {
    return type === 'media'
      ? this.updateMediaName(id, newName)
      : this.updateFolderName(id, newName);
  }

  private static updateMediaName(id: string, name: string) {
    return this.api.put(
      `${rebrand.urls.KLICKART_URL}/api/medias/${id}`,
      { media: { name } },
      {
        withCredentials: false,
      }
    );
  }

  private static updateFolderName(id: string, name: string) {
    const formData = new FormData();
    formData.append('media_folder[name]', name);

    return this.api.patch(
      `${rebrand.urls.KLICKART_URL}/api/media_folders/${id}`,
      formData,
      {
        withCredentials: false,
      }
    );
  }

  public static deleteItem(id: string, type: MediaType) {
    const route = type === 'media' ? 'medias' : 'media_folders';

    return this.api.delete(`${rebrand.urls.KLICKART_URL}/api/${route}/${id}`);
  }

  public static deleteFolder(id: string) {
    return this.api.delete(
      `${rebrand.urls.KLICKART_URL}/api/media_folders/${id}`
    );
  }
}
