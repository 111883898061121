import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { changeLanguage } from '@hotmart-org-ca/saas-pages-engine';
import i18n, {
  acceptedLanguages,
  defaultLanguage,
  getLocaleLink,
} from '@i18n/index';
import User from '@services/user';
import Token from '@services/token';
import { useUserInfo } from '@hooks/useUserInfo';
import { setIsPreviewMode } from '@store/slices/application';
import { useDataHub } from '@hooks/useDataHub';
import { getMatchRoute } from '@routes/index';
import { casEnabled, bootstrap } from 'src/auth/authService';
import CASTokenStrategy from 'src/auth/tokenStrategies/casTokenStrategy';

const RouteInterceptorComponent: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userProfile } = useUserInfo();
  const { sendUserFrequencyEvent } = useDataHub();
  const [render, setRender] = useState(false);
  const [previousPathname, setPreviousPathname] = useState('');
  const [urlSearchParams] = useSearchParams();
  const query = useRef(urlSearchParams);

  const languageRegExp = useMemo(
    () => new RegExp(`^/(${acceptedLanguages.join('|')})`),
    []
  );
  const reservedKeys = useMemo(() => ['redirect', 'token', 'preview'], []);

  const getLanguage = useCallback(() => {
    const cookieLang = Cookies.get('klickpages_locale');

    if (cookieLang) {
      return cookieLang;
    }

    const browserLang =
      navigator.language === 'pt-BR'
        ? navigator.language
        : navigator.language.split('-')[0];

    if (acceptedLanguages.includes(browserLang)) {
      return browserLang;
    }

    return defaultLanguage;
  }, []);

  const getURLSearch = useCallback(() => {
    const search: string[] = [];

    query.current.forEach((value, key) => {
      if (!reservedKeys.includes(key)) {
        search.push(`${key}=${value}`);
      }
    });

    return search.length ? `?${search.join('&')}` : '';
  }, [reservedKeys]);

  const getPathname = useCallback(
    (match: RegExpMatchArray | null, language: string) => {
      const redirect = query.current.get('redirect');

      if (!match && language !== defaultLanguage) {
        const { pathname } = redirect ? new URL(redirect) : window.location;
        return getLocaleLink(pathname, language);
      }

      if (redirect) {
        return new URL(redirect).pathname;
      }

      return window.location.pathname;
    },
    []
  );

  const handleToken = useCallback(async () => {
    const token = query.current.get('token') || (await User.refreshAuth());
    Token.updateUserData(token);
  }, []);

  const finishSetup = useCallback(async () => {
    const isAppPreview = query.current.get('preview') === 'true';
    const match = window.location.pathname.match(languageRegExp);
    const language = match ? match[1] : getLanguage();
    const pathname = getPathname(match, language);
    const search = getURLSearch();

    i18n.changeLanguage(language);
    await changeLanguage(language);
    dispatch(setIsPreviewMode(isAppPreview));
    navigate({ pathname, search }, { replace: true });
    setRender(true);
  }, [
    dispatch,
    getLanguage,
    getPathname,
    getURLSearch,
    languageRegExp,
    navigate,
  ]);

  useEffect(() => {
    const init = async () => {
      if (!render) {
        const pathName = window.location.pathname;
        const publicRoutes = ['/auth/logout', '/auth/surrogate'];

        if (publicRoutes.includes(pathName)) {
          bootstrap.stop();
          setRender(true);
        }

        Token.remove();
        if (casEnabled()) {
          bootstrap.subscribe({
            next: async (casUser) => {
              new CASTokenStrategy().setToken(casUser.id_token);
              await handleToken();
              await finishSetup();
            },
          });
          bootstrap.start();
        } else {
          await handleToken();
          await finishSetup();
        }
      }
    };

    init();
  }, [handleToken, finishSetup, render]);

  useEffect(() => {
    if (userProfile.id && location.pathname !== previousPathname) {
      const matchRoute = getMatchRoute(location.pathname);
      if (matchRoute && matchRoute.route.screenAccess) {
        setPreviousPathname(location.pathname);
        sendUserFrequencyEvent(matchRoute.route.screenAccess, userProfile);
      }
    }
  }, [
    location.pathname,
    previousPathname,
    sendUserFrequencyEvent,
    userProfile,
  ]);

  return <>{render && children}</>;
};

export const RouteInterceptor = memo(RouteInterceptorComponent);
