import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { v1 as uuidV1 } from 'uuid';
import { inputs } from '../SimpleFormConfig/simpleFormUtils';

export const getLabelText = (label: HTMLLabelElement | null) =>
  label?.textContent || '';

export const normalizeId = (id: string) => {
  const queryPattern = /\w+\[\w+\]/gi;

  if (!id.match(queryPattern)) {
    return id;
  }
  return id.replace('[', '-').replace(']', '-').replace('--', '-');
};

export const getId = (id: string) => normalizeId(id) || '';

export const normalizePlaceholder = (name: string) =>
  name.replace('[', ' ').replace(']', ' ').replace('_', ' ');

export const getDefaultHtmlForm: () => Template = () => ({
  uid: 'form',
  'form-id': `${uuidV1()}`,
  component: 'ls-form',
  action: '',
  description: '',
  checkbox: '',
  html: '<form><label for="email">E-mail</label><input id="email" name="email" placeholder="Seu e-mail aqui" /><button type="submit">Enviar</button></form>',
  button: JSON.stringify({
    uid: 'ls-button',
    component: 'ls-button',
    label: 'Enviar',
    background: '#191C1F',
    padding: '8px 32px 8px 32px',
    'border-radius': '24px 24px 24px 24px',
    'font-family': 'Montserrat, sans-serif',
    'is-bold': true,
    margin: '8px 0px 0px 0px',
  }),
  fields: JSON.stringify({
    fields: `${inputs}`,
    color: '#191C1F',
    'font-family': 'Raleway, sans-serif',
    'has-label': true,
  }),
  ownCode: false,
  'simple-form': false,
});
