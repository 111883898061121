import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';
import '@hotmart-org-ca/cosmos/dist/tooltip';

import { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageInfo } from '@hooks/usePageInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SaveStatus } from './styles';

export const SavingStatus: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const { savingStatus, savingStatusMessage } = usePageInfo();

  const message = useMemo(
    () => t(`header.savingStatus.${savingStatus}`),
    [savingStatus, t]
  );

  const statusElement = useMemo(
    () => ({
      loading: <FontAwesomeIcon icon={['far', 'spinner-third']} spin />,
      success: <FontAwesomeIcon icon={['far', 'check']} />,
      error: (
        <FontAwesomeIcon
          color="var(--red-darker)"
          icon={['far', 'times-circle']}
        />
      ),
    }),
    []
  );

  return (
    <SaveStatus {...attrs} isError={savingStatus === 'error'}>
      <hot-tooltip position="bottom" content={t(savingStatusMessage || '')}>
        {savingStatus && statusElement[savingStatus]}
        {message}
      </hot-tooltip>
    </SaveStatus>
  );
};
