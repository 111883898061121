import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UseCases from '@services/useCases';
import { useCaseConfigByType } from '@pages/PageWizard/useCaseConfigByType';
import {
  PUBLISH_PAGE_ROUTE_PREFIX,
  PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX,
  SALES_PAGE_PAID_WITH_PRODUCT_ROUTE_PREFIX,
  SALES_PAGE_PAID_WITHOUT_PRODUCT_ROUTE_PREFIX,
  FUNNEL_CAPTURE_PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX,
  FUNNEL_CAPTURE_THANKS_PAGE_ROUTE_PREFIX,
  HOTMART_JOURNEY_SALES_PAGE_WITH_PRODUCT_PREFIX,
} from '@common/constants';
import { redirectToSource } from '@pages/PageWizard/common';
import { getURLSearch } from '@common/utils';

const routeByType: Record<UseCaseType, string> = {
  publish_page: PUBLISH_PAGE_ROUTE_PREFIX,
  publish_lead_capture_page: PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX,
  sales_page_paid_with_product: SALES_PAGE_PAID_WITH_PRODUCT_ROUTE_PREFIX,
  sales_page_paid_without_product: SALES_PAGE_PAID_WITHOUT_PRODUCT_ROUTE_PREFIX,
  funnel_capture_publish_lead_capture_page:
    FUNNEL_CAPTURE_PUBLISH_LEAD_CAPTURE_PAGE_ROUTE_PREFIX,
  funnel_capture_thanks_page: FUNNEL_CAPTURE_THANKS_PAGE_ROUTE_PREFIX,
  hotmart_journey_sales_page_with_product:
    HOTMART_JOURNEY_SALES_PAGE_WITH_PRODUCT_PREFIX,
};
const reservedKeys = ['id', 'type'];

export const UseCasesPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const query = useRef(searchParams);

  const id = query.current.get('id');
  const type = query.current.get('type');
  const source = query.current.get('source');

  const getUseCaseQueryString = useCallback(
    () => getURLSearch(query.current, reservedKeys),
    []
  );

  const createNewUseCase = useCallback(
    async (routeType: UseCaseType) => {
      try {
        // @todo review use case config location
        const { getDefaultProperties } = useCaseConfigByType[routeType];
        const properties = getDefaultProperties();
        const data = await UseCases.createUseCase(routeType, properties);
        const queryString = getUseCaseQueryString();
        const pathname = `${routeByType[routeType]}/${data.id}${queryString}`;

        navigate({ pathname }, { replace: true });
      } catch {
        redirectToSource(source);
      }
    },
    [getUseCaseQueryString, navigate, source]
  );

  const openUseCase = useCallback(
    async (useCaseType: UseCaseType) => {
      try {
        const useCaseId = id as string;
        const { currentStep, maxStep } = await UseCases.get(+useCaseId);

        if (maxStep === currentStep) {
          await createNewUseCase(useCaseType);
        } else {
          const queryString = getUseCaseQueryString();
          const pathname = `${routeByType[useCaseType]}/${useCaseId}${queryString}`;

          navigate({ pathname }, { replace: true });
        }
      } catch {
        redirectToSource(source);
      }
    },
    [createNewUseCase, getUseCaseQueryString, id, navigate, source]
  );

  useEffect(() => {
    const useCaseType = type as UseCaseType | null;

    if ((!id && !useCaseType) || (id && !useCaseType)) {
      redirectToSource(source);
      return;
    }

    if (id && useCaseType) {
      openUseCase(useCaseType);
      return;
    }

    createNewUseCase(useCaseType as UseCaseType);
  }, [createNewUseCase, id, openUseCase, source, type]);

  return null;
};
