import { BaseModal } from '@components/BaseModal';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-checkbox.css';
import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KlicksendFormInputs } from '@components/KlicksendFormInputs';
import allInputs from '@components/KlicksendFormInputs/inputs';
import { Footer, ModalTitle } from './styles';

type KlicksendFormsInputsModalProps = {
  formInputs: KlicksendFormField[];
  isOpen: boolean;
  onClose: () => void;
  onFormInputsChange: (inputs: KlicksendFormField[]) => void;
};
const KlicksendFormsInputsModalComponent: React.FC<KlicksendFormsInputsModalProps> =
  ({ isOpen, formInputs, onClose, onFormInputsChange, ...attrs }) => {
    const { t } = useTranslation();

    const [selectedInputs, setSelectedInputs] = useState(formInputs);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>, name: string) => {
        const clickedInput = allInputs.find((input) => input.name === name);

        const updatedSelectedInputs =
          e.target.checked === true
            ? [...selectedInputs].concat(clickedInput!)
            : [...selectedInputs].filter((input) => input.name !== name);
        setSelectedInputs(updatedSelectedInputs);
      },
      [selectedInputs]
    );

    const handleSave = useCallback(() => {
      onFormInputsChange(selectedInputs);
      onClose();
    }, [onClose, onFormInputsChange, selectedInputs]);

    const handleClose = useCallback(() => {
      onClose();
      setSelectedInputs(formInputs);
    }, [formInputs, onClose]);

    const footer = useMemo(
      () => (
        <Footer>
          <button
            className="hot-button hot-button--tertiary"
            onClick={handleClose}
          >
            {t('pageWizard.publishLeadCapturePage.form.inputs.modal.close')}
          </button>
          <button
            className="hot-button hot-button--primary"
            onClick={handleSave}
          >
            {t('pageWizard.publishLeadCapturePage.form.inputs.modal.save')}
          </button>
        </Footer>
      ),
      [handleClose, handleSave, t]
    );

    return (
      <BaseModal
        isOpen={isOpen}
        onClose={handleClose}
        header={
          <ModalTitle>
            {t('pageWizard.publishLeadCapturePage.form.inputs.label')}
          </ModalTitle>
        }
        footer={footer}
        {...attrs}
      >
        <span>
          {t('pageWizard.publishLeadCapturePage.form.inputs.modal.description')}
        </span>
        <KlicksendFormInputs
          selectedInputs={selectedInputs}
          onChange={handleChange}
        />
      </BaseModal>
    );
  };

export const KlicksendFormsInputsModal = memo(
  KlicksendFormsInputsModalComponent
);
