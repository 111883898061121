import styled from 'styled-components';

export const Content = styled.div`
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const DraggableContent = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  color: var(--gray-700);
  font-weight: bold;
  font-size: 16px;
`;

export const Divider = styled.hr`
  display: block;
  width: 300px;
  margin: 0;
`;
