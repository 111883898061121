import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useApplication } from '@hooks/useApplication';
import HotmartProductService from '@services/hotmartProduct';
import { useUseCases } from '@hooks/useUseCases';
import { SalesUseCase } from '../../salesUseCase';
import { Personalize } from './components/Personalize';
import { ProductNotFound } from './styles';

export type HotmartJourneyWithProductProps = {
  currentStep: number;
  properties: SalesPageProperties;
  isPostMessageBehavior?: boolean;
  onPropertiesChange: (props: Partial<SalesPageProperties>) => void;
  onBackClick?: () => void;
};

const HotmartJourneyWithProductComponent: React.FC<HotmartJourneyWithProductProps> =
  ({
    currentStep,
    properties,
    isPostMessageBehavior,
    onPropertiesChange,
    onBackClick,
  }) => {
    const [urlSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const { setAppLoading } = useApplication();
    const { generateAiProperties } = useUseCases();

    const useCaseInitialProps = useRef(properties);
    const query = useRef(urlSearchParams);

    const [useCase, setUseCase] = useState<SalesUseCase>();
    const [showProductNotFound, setShowProductNotFound] = useState(false);

    const productId = useMemo(
      () => query.current.get('productId') || query.current.get('product_id'),
      []
    );
    const offerId = useMemo(
      () => query.current.get('offerId') || query.current.get('offer_id'),
      []
    );

    const handleChange = useCallback(
      ({ prop, value }: UseCaseModifierChange) => {
        onPropertiesChange({ [prop]: value });
      },
      [onPropertiesChange]
    );

    const handleTemplateChange = useCallback(
      (props: SalesPageProperties) => {
        useCase?.replaceTemplate(props);

        onPropertiesChange(props);
      },
      [onPropertiesChange, useCase]
    );

    const updatePropertiesWithProductAndOffer = useCallback(async () => {
      const initialProperties = useCaseInitialProps.current;
      const products = await HotmartProductService.getProducts();

      if (!products.length) {
        return initialProperties;
      }

      const selectedProduct =
        products.find((product) => product.id === productId) || products[0];
      const offers = await HotmartProductService.getOffersByProductId(
        selectedProduct.id
      );

      if (!offers.length) {
        return initialProperties;
      }

      const selectedOffer =
        offers.find((offer) => offer.id === offerId) ||
        offers.find((offer) => offer.principal) ||
        offers[0];

      if (!selectedOffer) {
        return initialProperties;
      }

      const selectedTemplate = initialProperties.template || 'default';
      const defaultImage = SalesUseCase.imagesByTemplate[selectedTemplate];
      const updatedProperties: SalesPageProperties = {
        ...initialProperties,
        pageName: selectedProduct.name,
        product: {
          ...initialProperties.product,
          id: selectedProduct.id,
          title: selectedProduct.name,
          description: selectedProduct.description,
          imgSrc: selectedProduct.imgSrc || defaultImage,
          videoSrc: initialProperties.product.videoSrc,
          offerId: selectedOffer.id,
          offerKey: selectedOffer.key,
          offerPrice: selectedOffer.price,
          offerLink: selectedOffer.link,
          offerMaxInstallments: selectedOffer.maxInstallments,
        },
        author: {
          ...initialProperties.author,
          id: selectedProduct.userId,
          imgSrc: selectedProduct.userImgSrc || defaultImage,
        },
      };

      onPropertiesChange(updatedProperties);

      return updatedProperties;
    }, [offerId, onPropertiesChange, productId]);

    const initializeUseCase = useCallback(async () => {
      let props = useCaseInitialProps.current;

      if (showProductNotFound) {
        window.parent?.postMessage(
          {
            eventName: 'use-case-error',
            data: { error: 'product-not-found' },
          },
          '*'
        );
        setAppLoading(false);
        return;
      }

      if (!props.product.id || (productId && productId !== props.product.id)) {
        const updatedProps = await updatePropertiesWithProductAndOffer();

        if (
          updatedProps.product.id &&
          updatedProps.product.id !== props.product.id
        ) {
          const generatePromise = generateAiProperties();

          setAppLoading(false);

          props = { ...props, ...updatedProps, ...(await generatePromise) };

          onPropertiesChange(props);
        } else {
          setShowProductNotFound(true);
        }
      }

      if (isPostMessageBehavior) {
        window.parent?.postMessage(
          { eventName: 'use-case-loaded', data: true },
          '*'
        );
      }

      setUseCase(new SalesUseCase(props));
      setAppLoading(false);
    }, [
      generateAiProperties,
      isPostMessageBehavior,
      onPropertiesChange,
      productId,
      setAppLoading,
      showProductNotFound,
      updatePropertiesWithProductAndOffer,
    ]);

    useEffect(() => {
      initializeUseCase();
    }, [initializeUseCase]);

    const steps = useMemo(
      () => [
        <Personalize
          properties={properties}
          useCase={useCase!}
          onTemplateChange={handleTemplateChange}
          onModifierChange={handleChange}
        />,
      ],
      [handleChange, handleTemplateChange, properties, useCase]
    );

    const renderedStep = useMemo(
      () => steps[currentStep] || steps[steps.length - 1],
      [currentStep, steps]
    );

    const rendered = useMemo(
      () => (!useCase ? null : renderedStep),
      [renderedStep, useCase]
    );

    return showProductNotFound ? (
      <ProductNotFound>
        <img src="/images/planlimit.svg" width={256} height={256} alt="alert" />
        <div>
          {t(
            'pageWizard.salesPage.hotmartJourneySalesPageWithProduct.productNotFound'
          )}
        </div>
        <button
          className="hot-button hot-button--secondary"
          onClick={onBackClick}
        >
          <span>{t('pageWizard.footer.previousButton')}</span>
        </button>
      </ProductNotFound>
    ) : (
      rendered
    );
  };

export const HotmartJourneyWithProduct = memo(
  HotmartJourneyWithProductComponent
);
