import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Timer: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="28"
    height="33"
    viewBox="0 0 28 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M24.5625 8.93506L25.625 7.81006C25.9375 7.56006 25.9375 7.06006 25.625 6.74756L25.3125 6.43506C25 6.12256 24.5 6.12256 24.25 6.43506L23.125 7.49756C20.875 5.56006 18.0625 4.31006 14.875 4.12256V2.06006H16.4375C16.875 2.06006 17.1875 1.74756 17.1875 1.31006V0.810059C17.1875 0.435059 16.875 0.0600586 16.4375 0.0600586H11.4375C11.0625 0.0600586 10.6875 0.435059 10.6875 0.810059V1.31006C10.6875 1.74756 11.0625 2.06006 11.4375 2.06006H12.875V4.12256C5.6875 4.68506 0 10.6851 0 18.0601C0 25.8101 6.25 32.0601 14 32.0601C21.6875 32.0601 28 25.8101 28 18.0601C28 14.5601 26.6875 11.3726 24.5625 8.93506ZM14 30.0601C7.3125 30.0601 2 24.7476 2 18.0601C2 11.4351 7.3125 6.06006 14 6.06006C20.625 6.06006 26 11.4351 26 18.0601C26 24.7476 20.625 30.0601 14 30.0601ZM14.25 22.0601C14.625 22.0601 15 21.7476 15 21.3101V10.8101C15 10.4351 14.625 10.0601 14.25 10.0601H13.75C13.3125 10.0601 13 10.4351 13 10.8101V21.3101C13 21.7476 13.3125 22.0601 13.75 22.0601H14.25Z"
      fill={color}
    />
  </StyledSVG>
);
