import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import MediaCenter from '@services/mediaCenter';
import {
  addUploadingMedia,
  updateUploadingMedia,
} from '@store/slices/mediaCenter';
import { sleep } from '@common/utils';

export const uploadMedia = createAsyncThunk<
  Media[],
  File,
  { fulfilledMeta: string[]; rejectedMeta: string[]; state: LocalState }
>(
  'mediaCenter/uploadMedia',
  async (file, { rejectWithValue, dispatch, fulfillWithValue, getState }) => {
    const uploadingMediaId = uuidv4();

    const uploadingMedia: Image = {
      id: uploadingMediaId,
      name: file.name,
      extension: '',
      thumb: '',
      type: 'media',
      url: URL.createObjectURL(file),
    };

    const uploadedMedia = {
      ...uploadingMedia,
      loading: false,
      error: false,
      uploaded: true,
    };

    const failedUploadingMedia = {
      ...uploadingMedia,
      loading: false,
      error: true,
      uploaded: false,
    };
    const {
      mediaCenter: { breadcrumbs },
    } = getState();
    const currentFolder: string | undefined = [...breadcrumbs]
      .pop()
      ?.id.toString();
    try {
      dispatch(addUploadingMedia(uploadingMedia));
      await MediaCenter.uploadMedia(file, currentFolder);
      dispatch(updateUploadingMedia(uploadedMedia));
      const response = currentFolder
        ? await MediaCenter.getFolderMedia(currentFolder)
        : await MediaCenter.getRootMedia();
      await sleep(500);
      return fulfillWithValue(response, [uploadingMediaId]);
    } catch (error) {
      const serializedError = error as SerializedError;
      dispatch(updateUploadingMedia(failedUploadingMedia));
      await sleep(500);
      throw rejectWithValue(serializedError, [uploadingMediaId]);
    }
  }
);

export const uploadMediaBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(uploadMedia.fulfilled, (state: MediaCenterState, action) => {
    const returnedMediaList = action.payload;
    const uploadingMediaId = action.meta[0];
    state.uploadingMedia = state.uploadingMedia.filter(
      (media) => media.id !== uploadingMediaId
    );
    state.media = returnedMediaList;
  });
  builder.addCase(uploadMedia.rejected, (state: MediaCenterState, action) => {
    const uploadingMediaId = action.meta[0];
    state.uploadingMedia = state.uploadingMedia.filter(
      (media) => media.id !== uploadingMediaId
    );
  });
};
