import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import HotmartProductService from '@services/hotmartProduct';
import UseCases from '@services/useCases';
import User from '@services/user';

export const generateAiProperties = createAsyncThunk<
  Omit<SalesPageProperties, 'colors' | 'pageName'>,
  void,
  {
    state: LocalState;
  }
>('useCases/generateAiProperties', async (_, { getState }) => {
  try {
    const {
      useCases: {
        properties: { author: authorState, product: productState },
      },
    } = getState();

    const product: ProductProps = { ...productState };
    const author: AuthorProps = { ...authorState };

    if (product.id) {
      const videoLink = await HotmartProductService.getProductVideoLink(
        product.id
      );
      product.videoSrc = videoLink || product.videoSrc;

      if (author.id) {
        const user = await User.getUserPublicProfile(author.id);

        if (user) {
          author.name = user.name;
          author.description = user.biography;
          author.imgSrc = user.avatarFinal || author.imgSrc;
        }
      }
    }

    const [aiAbout, aiProduct] = await Promise.all([
      UseCases.getAboutTheAuthorFromAI(author.description).promise,
      UseCases.getProductFromAI(product.title, product.description).promise,
    ]);

    const updatedAuthor: UpdatedAuthorProps = {
      description: aiAbout.output.about[0],
    };
    const updatedProduct: UpdatedProductProps = {
      title: aiProduct.output.title,
      subtitle: aiProduct.output.subtitle,
      description: aiProduct.output.description,
    };

    return {
      author,
      product,
      updatedAuthor,
      updatedProduct,
    };
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const generateAiBuilder = (
  builder: ActionReducerMapBuilder<UseCasesState>
) => {
  builder.addCase(generateAiProperties.pending, (state: UseCasesState) => {
    state.loadingAI = true;
  });
  builder.addCase(
    generateAiProperties.fulfilled,
    (state: UseCasesState, action: PayloadAction<AISalesPageProperties>) => {
      state.loadingAI = false;
      state.properties = { ...state.properties, ...action.payload };
    }
  );
  builder.addCase(generateAiProperties.rejected, (state: UseCasesState) => {
    state.loadingAI = false;
  });
};
