import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import classNames from 'classnames';

import React, { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Description } from './styles';

export type KeywordsProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  value?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
};

const KeywordsComponent: React.FC<KeywordsProps> = ({
  value = '',
  error,
  errorMessage,
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleKeywordsChange = useCallback(
    async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div {...attrs} className="hot-form">
      <Title htmlFor="keywords">{t('seo.keywords.title')}</Title>
      <textarea
        id="keywords"
        className={classNames('hot-form__input  hot-form__textarea--sm', {
          'is-invalid': error,
        })}
        placeholder={t('seo.keywords.placeholder')}
        defaultValue={value}
        onChange={handleKeywordsChange}
        rows={3}
      />

      {error && <div className="invalid-feedback">{t(`${errorMessage}`)}</div>}

      <Description>{t('seo.keywords.description')}</Description>
    </div>
  );
};

export const Keywords = memo(KeywordsComponent);
