import styled, { css } from 'styled-components';

type StyledTitleProps = {
  active?: boolean;
  finished?: boolean;
};

type StyledDescriptionProps = {
  finished?: boolean;
};

export const Container = styled.div`
  font-size: 16px;
  margin-bottom: 48px;
  color: var(--gray-500);
  position: sticky;
  top: 84px;
`;

export const Step = styled.a`
  display: block;
  text-decoration: none;
  color: currentColor;

  &:focus,
  &:active {
    text-decoration: none;
    color: currentColor;
  }
`;

export const Title = styled.h1<StyledTitleProps>`
  font-size: 16px;
  padding-left: 31px;
  position: relative;
  line-height: 1;
  margin: 0;

  &:before {
    content: '';
    position: absolute;
    top: 8px;
    transform: translateY(-50%);
    left: 4px;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 100%;
    border: 1px solid currentColor;
  }

  ${({ active, finished }) => css`
    ${finished &&
    css`
      :before {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    `}

    ${active &&
    css`
      color: var(--gray-700);
      font-weight: 700;

      :before {
        top: 8px;
        left: 0em;
        width: 16px;
        height: 16px;
        border-color: var(--primary);
      }

      &:after {
        content: '';
        position: absolute;
        top: 8px;
        transform: translateY(-50%);
        left: 6px;
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 100%;
        background-color: var(--primary);
      }
    `}
  `}
`;

export const Description = styled.p<StyledDescriptionProps>`
  padding-left: 23px;
  margin: 10px 0 16px 8px;
  font-size: 12px;

  ${({ finished }) => css`
    border-left: 1px solid ${finished ? 'var(--primary)' : 'currentColor'};
  `}
`;
