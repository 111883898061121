import '@hotmart-org-ca/cosmos/dist/loading';
import { HTMLAttributes, memo, useMemo } from 'react';
import { LoaderWrapper, Subtitle, Title } from './styles';

type UseCaseLoaderProps = HTMLAttributes<HTMLDivElement> & {
  title?: string;
  subtitle?: string;
  fixed?: boolean;
};

const UseCaseLoaderComponent: React.FC<UseCaseLoaderProps> = ({
  title,
  subtitle,
  fixed = false,
  ...attrs
}) => {
  const TitleElement = useMemo(() => <Title>{title}</Title>, [title]);
  const SubtitleElement = useMemo(
    () => <Subtitle>{subtitle}</Subtitle>,
    [subtitle]
  );

  return (
    <LoaderWrapper {...attrs} fixed={fixed}>
      <hot-loading />
      {title && TitleElement}
      {subtitle && SubtitleElement}
    </LoaderWrapper>
  );
};

export const UseCaseLoader = memo(UseCaseLoaderComponent);
