import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  width: 228px;
`;

export const Label = styled.label`
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
`;

export const Icon = styled.i`
  font-size: 24px;
  color: var(--gray-400);
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 228px;
  max-width: 228px;
  background: var(--gray-100);
  margin-bottom: 16px;
  border: 1px solid var(--gray-300);
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
