import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ActiveComponentState } from '@hotmart-org-ca/saas-pages-engine';
import ReactDOM from 'react-dom';
import camelcase from 'camelcase';
import { v1 as uuid } from 'uuid';
import { LsEditorElementModifiers } from '@hotmart-org-ca/saas-pages-engine-react';
import { useEngine, usePageInfo, useMenu } from '@hooks/index';
import { Submenu } from '@components/Submenu';
import { getModifiersConfig } from '@common/modifiers-config';
import { persistentQuerySelector } from '@common/utils';

export type AdjustmentsSubmenuProps = HTMLAttributes<HTMLDivElement>;

export const AdjustmentsSubmenu: React.FC<AdjustmentsSubmenuProps> = ({
  ...attrs
}) => {
  const { goBack } = useMenu();
  const { activeComponent, getElement, setActiveComponent } = useEngine();
  const { isFunnelCapturePage } = usePageInfo();

  const [portals, setPortals] = useState<React.ReactPortal[]>([]);

  const isPopupActive = useMemo(
    () => activeComponent && activeComponent.component === 'ls-popup',
    [activeComponent]
  );

  const closePopupEventName = useMemo(
    () => `closePopup-${activeComponent?.uid}`,
    [activeComponent?.uid]
  );

  const modifiersConfig = useMemo(() => getModifiersConfig(), []);

  const title = useMemo(() => {
    const prefix = 'sideMenu.adjustments.title.';

    if (activeComponent) {
      const [name] = activeComponent.component.split('-editable');
      const formattedName = camelcase(name);
      return `${prefix}${formattedName}`;
    }

    return `${prefix}default`;
  }, [activeComponent]);

  const customModifiers = useMemo(() => {
    if (activeComponent) {
      const config = modifiersConfig[activeComponent.component];

      return config?.customModifiers || {};
    }
    return {};
  }, [activeComponent, modifiersConfig]);

  const actionSlots = useMemo(() => {
    if (activeComponent) {
      const config = modifiersConfig[activeComponent.component];

      return config?.actionSlots || {};
    }
    return {};
  }, [activeComponent, modifiersConfig]);

  const changeModifierProps = useMemo(() => {
    if (activeComponent) {
      const config = modifiersConfig[activeComponent.component];

      return config?.changeModifierProps || {};
    }
    return {};
  }, [activeComponent, modifiersConfig]);

  const slotConfig = useMemo(
    () =>
      Object.keys(customModifiers).reduce(
        (acc, current) => ({ ...acc, [current]: true }),
        {}
      ),
    [customModifiers]
  );

  const updatePortals = useCallback(async () => {
    const customConfigs = [
      ...Object.entries(customModifiers),
      ...Object.entries(actionSlots),
    ];
    const promises: Promise<Element | null>[] = [];
    const updatedPortals: React.ReactPortal[] = [];

    customConfigs.forEach(([slotName]) => {
      promises.push(persistentQuerySelector(`*[data-slot-name="${slotName}"]`));
    });

    const slots = await Promise.all(promises);

    slots.forEach((slot, index) => {
      if (slot) {
        const portal = ReactDOM.createPortal(
          customConfigs[index][1].map((Modifier) => (
            <Modifier key={uuid()} uid={activeComponent?.uid} />
          )),
          slot
        );
        updatedPortals.push(portal);
      }
    });

    setPortals(updatedPortals);
  }, [actionSlots, activeComponent?.uid, customModifiers]);

  const eventListener = useCallback(() => {
    window.removeEventListener(closePopupEventName, eventListener);
    goBack();
  }, [closePopupEventName, goBack]);

  useEffect(() => {
    if (isPopupActive) {
      window.addEventListener(closePopupEventName, eventListener);
    }

    return () => {
      window.removeEventListener(closePopupEventName, eventListener);
    };
  }, [activeComponent, closePopupEventName, eventListener, isPopupActive]);

  useEffect(() => {
    if (
      Object.keys(customModifiers).length ||
      Object.keys(actionSlots).length
    ) {
      updatePortals();
    } else {
      setPortals([]);
    }
  }, [actionSlots, customModifiers, updatePortals]);

  const updateActiveComponent = useCallback(
    (klicksendActiveComponent: ActiveComponentState) => {
      const newComponentState = {
        ...klicksendActiveComponent,
        hideDelete: true,
        hideDuplicate: true,
        hideVisibility: true,
      };

      if (
        JSON.stringify(klicksendActiveComponent) !==
        JSON.stringify(newComponentState)
      ) {
        setActiveComponent(newComponentState);
      }
    },
    [setActiveComponent]
  );

  useEffect(() => {
    if (!isFunnelCapturePage || !activeComponent) {
      return;
    }

    const klicksendComponent = 'ls-klicksend-form';

    if (
      activeComponent.component === klicksendComponent ||
      activeComponent.parent?.component === klicksendComponent
    ) {
      updateActiveComponent(activeComponent);
      return;
    }

    const element = getElement(activeComponent.uid);
    const containKlicksendForm =
      element && JSON.stringify(element).includes(klicksendComponent);

    if (containKlicksendForm) {
      updateActiveComponent(activeComponent);
    }
  }, [isFunnelCapturePage, activeComponent, updateActiveComponent, getElement]);

  return (
    <Submenu
      title={title}
      padding="0"
      activeComponent={activeComponent}
      {...attrs}
    >
      <LsEditorElementModifiers
        slotConfig={slotConfig}
        changeModifierProps={changeModifierProps}
      >
        {portals.map((portal) => portal)}
      </LsEditorElementModifiers>
    </Submenu>
  );
};
