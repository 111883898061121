import { Footer } from '@components/Footer';
import styled, { css } from 'styled-components';

type StyledNextButtonProps = {
  isDisabled?: boolean;
};

type StyledFooterProps = {
  icon?: 'left' | 'right';
};

export const Button = styled.button<StyledNextButtonProps>`
  min-width: 126px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isDisabled }) => css`
    ${isDisabled && 'pointer-events: none;'}
  `}
`;

export const ButtonIcon = styled.i<StyledFooterProps>`
  ${({ icon }) => css`
    ${icon === 'left' ? 'margin-right: 8px;' : 'margin-left: 8px;'}
  `}
`;

export const StyledFooter = styled(Footer)`
  > div {
    max-width: 1460px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;

    @media screen and (max-width: 1461px) {
      max-width: 1296px;
    }
  }
`;

export const Stepper = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  bottom: 70px;
  left: 0;
  width: 100%;
`;

type StyledStepProps = {
  width: string;
  isFinished: boolean;
  isCurrent: boolean;
};

export const Step = styled.div<StyledStepProps>`
  height: 8px;
  border-radius: 8px;

  ${({ width, isFinished, isCurrent }) => css`
    width: ${width};
    background: ${isFinished ? 'var(--primary)' : 'var(--gray-200)'};

    ${isCurrent &&
    css`
      ::before {
        content: '';
        height: 8px;
        display: block;
        width: 30%;
        border-radius: 8px;
        background: var(--primary);
      }
    `}
  `}
`;
