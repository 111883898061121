import styled from 'styled-components';

export const Container = styled.div`
  .select__input-group {
    width: 100%;
  }

  .select__option {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
