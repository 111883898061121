import * as linkTreeHelpers from './useCases/LinkTree/helpers';
import * as captureHelpers from './useCases/Capture/helpers';
import * as linkTreeTemplate from './useCases/LinkTree/template';
import * as captureTemplates from './useCases/Capture/template';
import * as thanksPage from './useCases/Thanks/helpers';
import * as thanksPageTemplate from './useCases/Thanks/template';
import { SalesUseCase } from './useCases/Sales/salesUseCase';

export const useCaseConfigByType: UseCaseConfigByType = {
  publish_page: {
    getDefaultProperties: linkTreeHelpers.getDefaultProperties,
    getTemplateWithProps: linkTreeHelpers.getTemplateWithProps,
    getTemplate: linkTreeTemplate.getTemplate,
    getFontsConfig: linkTreeTemplate.getFontsConfig,
    steps: [
      {
        title: 'pageWizard.publishPage.steps.first.title',
        description: 'pageWizard.publishPage.steps.first.description',
      },
      {
        title: 'pageWizard.publishPage.steps.second.title',
        description: 'pageWizard.publishPage.steps.second.description',
      },
    ],
  },
  publish_lead_capture_page: {
    getDefaultProperties: captureHelpers.getDefaultProperties,
    getTemplateWithProps: captureHelpers.getTemplateWithProps,
    getTemplate: captureTemplates.getTemplate,
    getFontsConfig: captureTemplates.getFontsConfig,
    getTemplateBeforePublish: captureHelpers.getTemplateBeforePublish,
    keepAppLoader: true,
    steps: [
      {
        title: 'pageWizard.publishLeadCapturePage.steps.first.title',
        description:
          'pageWizard.publishLeadCapturePage.steps.first.description',
      },
      {
        title: 'pageWizard.publishLeadCapturePage.steps.second.title',
        description:
          'pageWizard.publishLeadCapturePage.steps.second.description',
      },
      {
        title: 'pageWizard.publishLeadCapturePage.steps.third.title',
        description:
          'pageWizard.publishLeadCapturePage.steps.third.description',
      },
    ],
  },
  sales_page_paid_with_product: {
    getDefaultProperties: SalesUseCase.getDefaultProperties,
    getTemplateWithProps: SalesUseCase.getTemplateWithProperties,
    getTemplate: SalesUseCase.getTemplate,
    getFontsConfig: SalesUseCase.getFontsConfig,
    steps: [
      {
        title: 'pageWizard.salesPage.steps.template.title',
        description: 'pageWizard.salesPage.steps.template.description',
        hidePreview: true,
        hideAfterStep: 2,
        saveCurrentWhenGoBack: true,
      },
      {
        title: 'pageWizard.salesPage.paidWithProduct.steps.first.title',
        description:
          'pageWizard.salesPage.paidWithProduct.steps.first.description',
        hidePreview: true,
        hideAfterStep: 2,
        saveCurrentWhenGoBack: true,
      },
      {
        title: 'pageWizard.salesPage.paidWithProduct.steps.second.title',
        description:
          'pageWizard.salesPage.paidWithProduct.steps.second.description',
        hidePreview: true,
        hideAfterStep: 2,
        saveCurrentWhenGoBack: true,
        events: {
          beforeNextStep: 'generateAiProperties',
        },
      },
      {
        title: 'pageWizard.salesPage.steps.personalize.title',
        description: 'pageWizard.salesPage.steps.personalize.description',
        previewDevice: 'desktop',
        showAfterStep: 2,
      },
      {
        title: 'pageWizard.salesPage.steps.presentProduct.title',
        description: 'pageWizard.salesPage.steps.presentProduct.description',
        previewDevice: 'desktop',
        showAfterStep: 2,
        scrollPreviewTo: 'title',
      },
      {
        title: 'pageWizard.salesPage.steps.describeProduct.title',
        description: 'pageWizard.salesPage.steps.describeProduct.description',
        previewDevice: 'desktop',
        showAfterStep: 2,
        scrollPreviewTo: 'about-content',
      },
      {
        title: 'pageWizard.salesPage.steps.presentAuthor.title',
        description: 'pageWizard.salesPage.steps.presentAuthor.description',
        previewDevice: 'desktop',
        showAfterStep: 2,
        scrollPreviewTo: 'about-author',
      },
      {
        title: 'pageWizard.salesPage.steps.offer.title',
        description: 'pageWizard.salesPage.steps.offer.description',
        previewDevice: 'desktop',
        showAfterStep: 2,
        scrollPreviewTo: 'offer',
      },
    ],
  },
  sales_page_paid_without_product: {
    getDefaultProperties: SalesUseCase.getDefaultProperties,
    getTemplateWithProps: SalesUseCase.getTemplateWithProperties,
    getTemplate: SalesUseCase.getTemplate,
    getFontsConfig: SalesUseCase.getFontsConfig,
    steps: [
      {
        title: 'pageWizard.salesPage.steps.template.title',
        description: 'pageWizard.salesPage.steps.template.description',
        hidePreview: true,
        saveCurrentWhenGoBack: true,
        hideAfterStep: 1,
      },
      {
        title: 'pageWizard.salesPage.paidWithoutProduct.steps.first.title',
        description:
          'pageWizard.salesPage.paidWithoutProduct.steps.first.description',
        hidePreview: true,
        saveCurrentWhenGoBack: true,
        hideAfterStep: 1,
        events: {
          beforeNextStep: 'generateAiProperties',
        },
      },
      {
        title: 'pageWizard.salesPage.steps.personalize.title',
        description: 'pageWizard.salesPage.steps.personalize.description',
        showAfterStep: 1,
        previewDevice: 'desktop',
      },
      {
        title: 'pageWizard.salesPage.steps.presentProduct.title',
        description: 'pageWizard.salesPage.steps.presentProduct.description',
        showAfterStep: 1,
        previewDevice: 'desktop',
        scrollPreviewTo: 'title',
      },
      {
        title: 'pageWizard.salesPage.steps.describeProduct.title',
        description: 'pageWizard.salesPage.steps.describeProduct.description',
        showAfterStep: 1,
        previewDevice: 'desktop',
        scrollPreviewTo: 'about-content',
      },
      {
        title: 'pageWizard.salesPage.steps.presentAuthor.title',
        description: 'pageWizard.salesPage.steps.presentAuthor.description',
        showAfterStep: 1,
        previewDevice: 'desktop',
        scrollPreviewTo: 'about-author',
      },
      {
        title: 'pageWizard.salesPage.steps.offer.title',
        description: 'pageWizard.salesPage.steps.offer.description',
        showAfterStep: 1,
        previewDevice: 'desktop',
        scrollPreviewTo: 'offer',
      },
    ],
  },
  funnel_capture_thanks_page: {
    getDefaultProperties: thanksPage.getDefaultProperties,
    getTemplateWithProps: thanksPage.getTemplateWithProps,
    getPropertiesBeforeInit: thanksPage.getPropertiesBeforeInit,
    getTemplate: thanksPageTemplate.getTemplate,
    getFontsConfig: thanksPageTemplate.getFontsConfig,
    hideOnboarding: true,
    steps: [
      {
        title: 'pageWizard.funnelCaptureThanksPage.steps.first.title',
        description:
          'pageWizard.funnelCaptureThanksPage.steps.first.description',
      },
      {
        title: 'pageWizard.funnelCaptureThanksPage.steps.second.title',
        description:
          'pageWizard.funnelCaptureThanksPage.steps.second.description',
      },
    ],
  },
  funnel_capture_publish_lead_capture_page: {
    getDefaultProperties: captureHelpers.getDefaultProperties,
    getTemplateWithProps: captureHelpers.getTemplateWithProps,
    getTemplate: captureTemplates.getTemplate,
    getFontsConfig: captureTemplates.getFontsConfig,
    getPropertiesBeforeInit: captureHelpers.getPropertiesBeforeInit,
    hideOnboarding: true,
    steps: [
      {
        title: 'pageWizard.publishLeadCapturePage.steps.first.title',
        description:
          'pageWizard.publishLeadCapturePage.steps.first.description',
      },
      {
        title: 'pageWizard.publishLeadCapturePage.steps.second.title',
        description:
          'pageWizard.publishLeadCapturePage.steps.second.description',
      },
      {
        title: 'pageWizard.publishLeadCapturePage.steps.third.title',
        description:
          'pageWizard.publishLeadCapturePage.steps.third.description',
      },
    ],
    keepAppLoader: true,
  },
  hotmart_journey_sales_page_with_product: {
    getDefaultProperties: SalesUseCase.getDefaultProperties,
    getTemplateWithProps: SalesUseCase.getTemplateWithProperties,
    getTemplate: SalesUseCase.getTemplate,
    getFontsConfig: SalesUseCase.getFontsConfig,
    hideMenu: true,
    hidePageTitle: true,
    hideHeader: true,
    hideOnboarding: true,
    keepAppLoader: true,
    backButton: {
      label:
        'pageWizard.salesPage.hotmartJourneySalesPageWithProduct.backButton',
    },
    steps: [
      {
        previewDevice: 'desktop',
      },
    ],
  },
};
