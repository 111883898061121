import styled, { css } from 'styled-components';

export type StyledIconProps = {
  hasClick?: boolean;
  size: number;
};

export const StyledIcon = styled.span<StyledIconProps>`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;

  ${({ size, hasClick }) => css`
    width: ${size}px;
    height: ${size}px;
    min-width: ${size}px;
    min-height: ${size}px;
    cursor: ${hasClick ? 'pointer' : 'inherit'};
  `}
`;

export const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
`;
