import { TextInput } from '@components/TextInput';
import styled from 'styled-components';

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  color: var(--gray-900);
  font-size: 28px;
  font-weight: 300;
  line-height: 120%;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  color: var(--gray-700);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 16px;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const GenerateAction = styled.div`
  display: flex;
  justify-content: center;
`;
