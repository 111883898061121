import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  generateAiProperties,
  generateAiBuilder,
} from './generateAiProperties';
import { getUseCase, getUseCaseBuilder } from './getUseCase';
import { saveUseCase } from './saveUseCase';
import { saveProperties } from './saveProperties';

export const useCasesThunks = {
  generateAiProperties,
  getUseCase,
  saveUseCase,
  saveProperties,
};

export const useCasesExtraReducers = (
  builder: ActionReducerMapBuilder<UseCasesState>
) => {
  getUseCaseBuilder(builder);
  generateAiBuilder(builder);
};
