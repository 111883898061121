import { LsEditorPage } from '@hotmart-org-ca/saas-pages-engine-react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

type StyledContainerProps = {
  previewDevice: UseCasePreviewDevice;
  isFirefox: boolean;
};

const stylesByPreviewDevice: Record<
  UseCasePreviewDevice | 'desktopFirefox',
  FlattenSimpleInterpolation
> = {
  desktopFirefox: css`
    transform: scale(0.6);
    transform-origin: top left;
    height: 740px;
    width: 960px;
    margin-bottom: 80px;

    @media (max-height: 1020px) {
      height: 684px;
    }

    @media (max-height: 864px) {
      height: 550px;
    }

    @media (max-height: 768px) {
      height: 480px;
    }
  `,
  desktop: css`
    zoom: 37%;
    height: 2000px;
    width: 100%;
    margin-bottom: 128px;
    position: sticky;
    top: 110px;

    @media (max-height: 1020px) {
      height: 1850px;
    }

    @media (max-height: 864px) {
      height: 1486px;
    }

    @media (max-height: 768px) {
      height: 1297px;
    }
  `,
  mobile: css`
    position: sticky;
    top: 84px;
    height: 615px;
    margin-bottom: 48px;
  `,
};

const getPreviewDevice = (
  previewDevice: UseCasePreviewDevice,
  isFirefox: boolean
) => {
  if (isFirefox && previewDevice === 'desktop') {
    return 'desktopFirefox';
  }
  return previewDevice;
};

export const Container = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: center;
  border-radius: 12px;
  overflow-x: auto;
  background: var(--white);
  outline: 1px solid var(--gray-300);

  ${({ previewDevice, isFirefox }) => css`
    ${stylesByPreviewDevice[getPreviewDevice(previewDevice, isFirefox)]}
  `}
`;

export const LsPage = styled(LsEditorPage)`
  width: 100%;
`;
