import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/index';
import { pageFragmentsThunks } from '@store/thunks';

export const useSavedPopups = () => {
  const dispatch = useAppDispatch();

  const userPopups = useSelector(
    (state: State) => state.pageFragments.userPopups
  );

  const loading = useSelector(
    (state: State) => state.pageFragments.popupLoading
  );

  const createUserPopup = useCallback(
    (popup: Omit<PageFragmentListItem, 'id'>) =>
      dispatch(pageFragmentsThunks.createUserPopup(popup)).unwrap(),
    [dispatch]
  );

  const getUserPopups = useCallback(
    () => dispatch(pageFragmentsThunks.getUserPopups()).unwrap(),
    [dispatch]
  );

  const updateUserPopup = useCallback(
    (popup: Omit<PageFragmentListItem, 'template'>) =>
      dispatch(pageFragmentsThunks.updateUserPopup(popup)).unwrap(),
    [dispatch]
  );

  const deleteUserPopup = useCallback(
    (id: string) => dispatch(pageFragmentsThunks.deleteUserPopup(id)).unwrap(),
    [dispatch]
  );

  return {
    loading,
    userPopups,
    createUserPopup,
    deleteUserPopup,
    getUserPopups,
    updateUserPopup,
  };
};
