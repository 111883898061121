import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';

import { HTMLAttributes, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Toast from '@services/toast';
import { Submenu } from '@components/Submenu';
import { saveScript } from '@store/thunks/pageScripts/saveScript';
import { goBack } from '@store/slices/menu';
import { useAppDispatch } from '@store/index';
import { containInvalidEmoji, isInvalidEmoji } from '@common/utils';
import { Button, Description, ErrorMessage, Label } from './styles';

export type ScriptFormSubmenuProps = HTMLAttributes<HTMLDivElement> & {};
export type ScriptFormProps = {
  name?: boolean;
  script?: boolean;
};

export const ScriptFormSubmenu: React.FC<ScriptFormSubmenuProps> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const pageScripts = useSelector((state: State) => state.pageScripts);

  const [script, setScript] = useState(pageScripts.scriptForm.scriptSelected);
  const [invalidTitle, setInvalidTitle] = useState(false);
  const [invalidScript, setInvalidScript] = useState(false);
  const [invalidEmoji, setInvalidEmoji] = useState<ScriptFormProps>({});

  const handleChangeScript = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type: 'name' | 'script'
    ) => {
      if (type === 'name') {
        setInvalidTitle(false);
      } else {
        setInvalidScript(false);
      }

      setInvalidEmoji((state) => ({
        ...state,
        [type]: isInvalidEmoji(event.target.value),
      }));

      setScript({
        ...script,
        [type]: event.target.value,
      });
    },
    [script]
  );

  const isValidForm = useCallback(() => {
    let isValidEmoji = true;

    setInvalidTitle(!script.name);
    setInvalidScript(!script.script);

    if (containInvalidEmoji(invalidEmoji)) {
      isValidEmoji = false;
      Toast.error('toastMessages.general.error.saved');
    }

    return script.name && script.script && isValidEmoji;
  }, [invalidEmoji, script.name, script.script]);

  const handleSave = useCallback(async () => {
    try {
      if (!isValidForm()) {
        return;
      }

      const { type, position } = pageScripts.scriptForm;

      await dispatch(saveScript({ type, position, scriptSelected: script }));
      dispatch(goBack());

      Toast.success('toastMessages.general.success.saved');
    } catch {
      Toast.error('toastMessages.general.error.saved');
    }
  }, [dispatch, isValidForm, pageScripts.scriptForm, script]);

  return (
    <Submenu title="sideMenu.settings.scripts" {...attrs}>
      <div className="hot-form">
        <Label className="hot-form__label" htmlFor="title">
          {t('scripts.form.title.label')}
        </Label>

        <input
          id="scriptHead"
          className={classNames('hot-form__input hot-form__input--sm', {
            'is-invalid': invalidTitle || invalidEmoji.name,
          })}
          placeholder={t('scripts.form.title.placeholder')}
          value={script.name}
          onChange={(e) => handleChangeScript(e, 'name')}
        />

        {invalidEmoji.name && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}

        {invalidTitle && (
          <ErrorMessage className="invalid-feedback">
            {t('scripts.form.title.required')}
          </ErrorMessage>
        )}
        <Description>{t('scripts.form.title.description')}</Description>
      </div>

      <div className="hot-form">
        <Label className="hot-form__label" htmlFor="title">
          <Trans
            i18nKey="scripts.form.script.label"
            components={{ b: <b /> }}
            values={{ type: pageScripts.scriptForm.type }}
          />
        </Label>

        <textarea
          className={classNames('hot-form__input hot-form__textarea--sm', {
            'is-invalid': invalidScript || invalidEmoji.script,
          })}
          id="script"
          placeholder={t('scripts.form.script.placeholder')}
          rows={10}
          value={script.script}
          onChange={(e) => handleChangeScript(e, 'script')}
        />

        {invalidEmoji.script && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}

        {invalidScript && (
          <ErrorMessage className="invalid-feedback">
            {t('scripts.form.script.required')}
          </ErrorMessage>
        )}
      </div>

      <Button
        className={classNames('hot-button hot-button--sm hot-button--primary', {
          'hot-button--loading': pageScripts.scriptForm.loading,
        })}
        onClick={handleSave}
      >
        <span>{t('scripts.form.save')}</span>
      </Button>
    </Submenu>
  );
};
