import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedSections from '@services/savedSections';

export const deleteUserSection = createAsyncThunk<
  void,
  string,
  {
    state: LocalState;
    SerializedError: {
      name: string;
      stack: string;
      code: string;
    };
  }
>('pageFragments/deleteUserSection', async (sectionToDelete) => {
  try {
    await SavedSections.delete(sectionToDelete);
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const deleteUserSectionBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(deleteUserSection.pending, (state: PageFragmentsState) => {
    state.sectionLoading = true;
  });
  builder.addCase(
    deleteUserSection.fulfilled,
    (state: PageFragmentsState, action) => {
      const deletedSectionId = action.meta.arg;
      const userSections = state.userSections.filter(
        (item) => item.id !== deletedSectionId
      );

      state.userSections = userSections;
      state.sectionLoading = false;
    }
  );
  builder.addCase(deleteUserSection.rejected, (state: PageFragmentsState) => {
    state.sectionLoading = false;
  });
};
