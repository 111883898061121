import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';

import React, { HTMLAttributes, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import {
  AllStepsButton,
  CloseIcon,
  Container,
  Content,
  Description,
  Item,
  Items,
  Steps,
  Title,
} from './styles';

export type OnboardingStepsModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose?: () => void;
  onOpenOnboarding?: (step: number) => void;
};

const OnboardingStepsModalComponent: React.FC<OnboardingStepsModalProps> = ({
  isOpen,
  onClose,
  onOpenOnboarding,
  ...attrs
}) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        value: 1,
        label: 'onboarding.modals.steps.device',
      },
      {
        value: 2,
        label: 'onboarding.modals.steps.sectionModels',
      },
      {
        value: 3,
        label: 'onboarding.modals.steps.sections',
      },
      {
        value: 4,
        label: 'onboarding.modals.steps.rowColumns',
      },
      {
        value: 5,
        label: 'onboarding.modals.steps.elements',
      },
      {
        value: 6,
        label: 'onboarding.modals.steps.images',
      },
      {
        value: 7,
        label: 'onboarding.modals.steps.settings',
      },
      {
        value: 8,
        label: 'onboarding.modals.steps.publish',
      },
    ],
    []
  );

  return (
    <BaseModal isOpen={isOpen} fixedSize="648px" {...attrs}>
      <Container>
        <CloseIcon onClick={onClose}>
          <i className="far fa-times" />
        </CloseIcon>

        <Title>{t('onboarding.modals.title')}</Title>

        <Content>
          <Steps>
            <Description>
              {t('onboarding.modals.steps.description')}
            </Description>

            <Items>
              {items.map(({ value, label }) => (
                <Item key={value} onClick={() => onOpenOnboarding?.(value)}>
                  {t(label)}
                </Item>
              ))}
            </Items>

            <AllStepsButton
              className="hot-button hot-button--primary"
              onClick={() => onOpenOnboarding?.(1)}
            >
              <span>{t('onboarding.modals.steps.all')}</span>
              <i className="far fa-arrow-right" />
            </AllStepsButton>
          </Steps>

          <div>
            <img
              src="/images/onboarding.svg"
              width={256}
              height={201}
              alt="Onboarding"
            />
          </div>
        </Content>
      </Container>
    </BaseModal>
  );
};

export const OnboardingStepsModal = memo(OnboardingStepsModalComponent);
