import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import '@hotmart-org-ca/cosmos/dist/collapse';

import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KlicksendFormsInputsModal } from '@components/KlicksendFormInputsModal';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CosmosCollapse } from '@components/CosmosCollapse';
import { CosmosButton } from '@components/CosmosButton';
import { SortableFieldItem } from './components/SortableFieldItem';
import { Container, FieldsWrapper } from './styles';

export type FormFieldsProps = HTMLAttributes<HTMLDivElement> & {
  fields: KlicksendFormField[];
  onFieldsChanged: (value: KlicksendFormField[]) => void;
};

const FormFieldsComponent: React.FC<FormFieldsProps> = ({
  fields,
  onFieldsChanged,
  ...attrs
}) => {
  const { t } = useTranslation();
  const fieldsToRender = fields.filter((field) => field.type !== 'gdpr');
  const gdprField = fields.find((field) => field.type === 'gdpr');
  const fieldLabels = fieldsToRender.map((field) => field.label);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSelectedInputs = useCallback(
    (inputs: KlicksendFormField[]) => {
      const resultingFields = [...fields].filter((field) =>
        inputs.find((input) => input.label === field.label)
      );

      inputs.forEach((input) =>
        !resultingFields.find((field) => field.label === input.label)
          ? resultingFields.push(input)
          : undefined
      );

      if (gdprField) {
        resultingFields.push(gdprField);
      }
      onFieldsChanged(resultingFields);
    },
    [fields, gdprField, onFieldsChanged]
  );

  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const activeIndex = fieldLabels.indexOf(active.id);
        const overIndex = fieldLabels.indexOf(over.id);
        const newArray = arrayMove([...fields], activeIndex, overIndex);
        onFieldsChanged(newArray);
      }
    },
    [fieldLabels, fields, onFieldsChanged]
  );

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <CosmosCollapse
        label={t('form.klicksend.formCreation.fields')}
        active
        {...attrs}
      >
        <Container>
          <FieldsWrapper>
            <SortableContext
              items={fieldLabels}
              strategy={verticalListSortingStrategy}
            >
              {fieldLabels.map((label) => (
                <SortableFieldItem {...{ label, key: label }} />
              ))}
            </SortableContext>
          </FieldsWrapper>
          <CosmosButton
            label="form.klicksend.formCreation.fieldsButton"
            onButtonClicked={() => setIsModalOpen(true)}
            type="secondary"
            role="button"
          />
        </Container>
      </CosmosCollapse>
      <KlicksendFormsInputsModal
        isOpen={isModalOpen}
        formInputs={fieldsToRender}
        onClose={() => setIsModalOpen(false)}
        onFormInputsChange={(inputs) => handleSelectedInputs(inputs)}
      />
    </DndContext>
  );
};

export const FormFields = memo(FormFieldsComponent);
