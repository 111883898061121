import {
  HtmlHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { TextInput } from '@components/TextInput';
import { TextInputsWrapper } from './styles';

type textProp = {
  inputType: string;
  value: string;
  type?: 'textarea' | undefined;
  placeholder?: string;
};

type TextInputsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  texts: textProp[];
  onTextChanged: (value: UseCaseModifierChange) => void;
  onInputBlurred?: (value: UseCaseModifierChange) => void;
};

const TextInputsComponent: React.FC<TextInputsProps> = ({
  texts,
  onTextChanged,
  onInputBlurred,
  ...attrs
}) => {
  const [currentTexts, setCurrentTexts] = useState<textProp[]>(texts);
  const debouncedTextChange = useRef(debounce(onTextChanged, 500));

  const handleChange = useCallback(
    (e: React.ChangeEvent | React.FocusEvent, index: number) => {
      const { value } = e.target as HTMLInputElement;
      const changingTexts = [...texts];
      const currentChangingText = changingTexts[index];

      changingTexts[index] = {
        ...currentChangingText,
        value,
      };
      setCurrentTexts(changingTexts);

      if (e.type === 'blur') {
        debouncedTextChange.current.cancel();
        return onInputBlurred?.({
          prop: changingTexts[index].inputType,
          value: changingTexts[index].value,
        });
      }

      return debouncedTextChange.current({
        prop: changingTexts[index].inputType,
        value: changingTexts[index].value,
      });
    },
    [onInputBlurred, texts]
  );

  const { t } = useTranslation();
  useEffect(() => setCurrentTexts(texts), [texts]);

  return (
    <TextInputsWrapper {...attrs}>
      {currentTexts.map((text, index) => (
        <TextInput
          key={`${text.inputType}-text`}
          id={`${text.inputType}-text`}
          name={text.inputType}
          size="md"
          value={text.value}
          removeEmoji
          onChange={(e) => handleChange(e, index)}
          placeholder={t(text.placeholder || '')}
          inputType={text.type || 'input'}
          rows={text.type === 'textarea' ? 4 : undefined}
          label={t(
            `pageWizard.funnelCaptureThanksPage.${text.inputType}InputLabel`
          )}
          onBlur={(e) => {
            if (onInputBlurred) {
              handleChange(e, index);
            }
          }}
        />
      ))}
    </TextInputsWrapper>
  );
};

export const TextInputs = memo(TextInputsComponent);
