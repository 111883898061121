import { v4 as uuid } from 'uuid';
import i18next from 'i18next';

const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

const contentCreatorTitle = () => {
  const first = i18next.t(
    'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
  );
  const second = i18next.t('pageWizard.salesPage.template.aboutAuthor.created');

  return capitalizeFirstLetter(`${first} ${second}`);
};

export const getClassicTemplate = () => {
  const maxInstallments = i18next.t(
    'pageWizard.salesPage.template.offer.installments',
    {
      maxInstallments: '1',
    }
  );

  return {
    background: 'none',
    'background-is-webp': false,
    padding: '0px 0px 0px 0px',
    desktop: {},
    tablet: {},
    mobile: {},
    uid: `ls-${uuid()}`,
    component: 'ls-page',
    children: [
      {
        background: '#F5F4EC',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-25T13:36:15',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '16px 0px 0px 0px',
        desktop: {},
        tablet: {
          background: '#F5F4EC',
          padding: '32px 0px 0px 0px',
        },
        mobile: {
          padding: '35px 0px 0px 0px',
        },
        uid: `ls-${uuid()}-title`,
        component: 'ls-section',
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '160px 0px 60px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'transparent',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '24px 50px 24px 50px',
                },
                mobile: {
                  padding: '0px 0px 0px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: '<p style="line-height: 1.15;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 48px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.</em></p>',
                    'max-width': '100%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1.15; text-align: center;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 56px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.<span class="ql-cursor">﻿﻿</span></em></p>',
                      'max-width': '100%',
                    },
                    mobile: {
                      text: '<p style="text-align: center; line-height: 1.15;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 28px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit eiusmod.</em></p>',
                      'max-width': '100%',
                      padding: '10px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productTitle: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    text: '<p style="line-height: 1;"><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">____________________________________________________________________</span></p>',
                    'max-width': '94%',
                    padding: '0px 10px 32px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '100%',
                      text: '<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 18px;">___________________________________________________________________</span></p>',
                    },
                    mobile: {
                      text: '<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 14px;">________________________________________________</span></p>',
                      'max-width': '95%',
                      padding: '0px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        primaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    text: '<p><span style="color: rgb(114, 114, 114); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                    'max-width': '90%',
                    padding: '16px 10px 16px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '100%',
                      text: '<p style="text-align: center;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                    },
                    mobile: {
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 12px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                      'max-width': '85%',
                      padding: '10px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productSubtitle: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    action: 'link',
                    color: '#FFFFFF',
                    background: '#FF709B',
                    'border-color': '#EF4E23',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    'button-class': '',
                    desktop: {},
                    'end-date': '2023-05-26T13:48:23',
                    timezone: '-03:00_utcMinus0300MainCities',
                    'time-on-page': '5',
                    'time-on-page-unit': 'seconds',
                    'delay-type': 'disabled',
                    'font-family': 'Open Sans, sans-serif',
                    'font-size': '20px',
                    'hover-background': 'none',
                    'hover-color': 'none',
                    'has-icon': false,
                    'has-text': true,
                    href: '',
                    'icon-position': 'right',
                    icon: 'fas fa-shopping-cart',
                    'is-bold': false,
                    'is-italic': false,
                    'is-underline': false,
                    'is-strike': false,
                    label: i18next.t('pageWizard.salesPage.template.CTA'),
                    margin: '32px 10px 16px 10px',
                    'max-width': '80%',
                    mobile: {
                      'max-width': '70%',
                      'font-size': '16px',
                      margin: '16px 0px 0px 0px',
                      padding: '12px 32px 12px 32px',
                    },
                    padding: '15px 32px 16px 32px',
                    tablet: {
                      margin: '32px 0px 20px 0px',
                      'max-width': '72%',
                      'font-size': '20px',
                    },
                    target: '_blank',
                    uid: `ls-${uuid()}`,
                    component: 'ls-button',
                    metadata: {
                      useCase: {
                        offerLink: {
                          propName: 'href',
                          middleware: 'string',
                        },
                        purchaseButton: {
                          propName: 'label',
                          middleware: 'string',
                        },
                        secondaryColor: {
                          propName: 'background',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
              {
                padding: '100px 10px 0px 0px',
                'justify-content': 'end',
                'align-items': 'end',
                'flex-direction': 'column',
                size: 12,
                background: 'transparent',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '40px 0px 0px 0px',
                },
                mobile: {
                  padding: '48px 0px 0px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                metadata: {},
                children: [
                  {
                    alt: '',
                    'border-color': '#99C1DE',
                    'border-radius': '300px 300px 0px 0px',
                    'border-style': 'solid',
                    'border-top-width': '3px',
                    'border-right-width': '3px',
                    'border-bottom-width': '3px',
                    'border-left-width': '3px',
                    height: '600px',
                    href: '',
                    'is-webp': true,
                    'max-width': '550px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'cover',
                    target: '_blank',
                    desktop: {},
                    tablet: {
                      'max-width': '400px',
                      height: '400px',
                    },
                    mobile: {
                      'max-width': '250px',
                      height: '250px',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/7176939/group_102881.png',
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/7176939/thumb_group_102881.png',
                    metadata: {
                      useCase: {
                        productImage: {
                          propName: 'src',
                          middleware: 'string',
                        },
                        primaryColor: {
                          propName: 'border-color',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
              },
            ],
            metadata: {},
          },
        ],
        metadata: {
          label: 'Seção 1',
        },
      },
      {
        background: '#E5E3D8',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '100px 0px 140px 0px',
        desktop: {},
        tablet: {
          background: '#E5E3D8',
          padding: '80px 0px 80px 0px',
        },
        mobile: {
          background: '#E5E3D8',
          padding: '64px 0px 72px 0px',
        },
        uid: `ls-${uuid()}-about-content`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 2',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '0px 0px 24px 0px',
                },
                mobile: {
                  padding: '0px 20px 0px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15; text-align: center;"><em style="font-family: Georgia, serif; font-size: 80px; color: rgb(78, 78, 78); background-color: rgba(0, 0, 0, 0);">${capitalizeFirstLetter(
                      i18next.t('pageWizard.salesPage.template.aboutProduct')
                    )}</em></p>`,
                    'max-width': '80%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '10px 10px 10px 10px',
                      text: `<p style="line-height: 1.15; text-align: center;"><em style="background-color: rgba(0, 0, 0, 0); color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 64px;">${capitalizeFirstLetter(
                        i18next.t('pageWizard.salesPage.template.aboutProduct')
                      )}</em></p>`,
                      'max-width': '100%',
                    },
                    mobile: {
                      text: `<p style="line-height: 1.15; text-align: center;"><em style="background-color: rgba(0, 0, 0, 0); color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 32px;">${capitalizeFirstLetter(
                        i18next.t('pageWizard.salesPage.template.aboutProduct')
                      )}</em></p>`,
                      'max-width': '100%',
                      padding: '0px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 1;"><strong style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 13px;">__________________________________________________________________________________________________________________________________________________</strong></p>',
                    'max-width': '80%',
                    padding: '0px 0px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 14px;">_______________________________________________________________________________________</strong></p>',
                      'max-width': '100%',
                      padding: '0px 0px 10px 0px',
                    },
                    mobile: {
                      padding: '0px 10px 10px 10px',
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 12px;">____________________________________________________</strong></p>',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        primaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    text: '<p style="text-align: center; line-height: 2;"><span style="color: rgb(114, 114, 114); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 16px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                    'max-width': '76%',
                    padding: '20px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '80%',
                      padding: '32px 10px 10px 10px',
                    },
                    mobile: {
                      text: '<p style="text-align: center;"><span style="color: rgb(114, 114, 114); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p><p style="text-align: center;"><span style="color: rgb(114, 114, 114); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                      'max-width': '100%',
                      padding: '24px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productDescription: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    'border-color': '#99C1DE',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'solid',
                    'border-top-width': '3px',
                    'border-right-width': '3px',
                    'border-bottom-width': '3px',
                    'border-left-width': '3px',
                    height: '366px',
                    margin: '64px 0px 0px 0px',
                    'max-width': '650px',
                    src: 'https://www.youtube.com/embed/wYHO0Wdqw7g',
                    'thumb-url': '',
                    desktop: {},
                    tablet: {
                      'border-style': 'solid',
                    },
                    mobile: {
                      'max-width': '300px',
                      height: '169px',
                      'border-style': 'solid',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      margin: '48px 0px 0px 0px',
                      'border-radius': '0px 0px 0px 0px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-video',
                    metadata: {
                      salesVideo: true,
                      useCase: {
                        videoSrc: {
                          propName: 'src',
                          middleware: 'string',
                        },
                        primaryColor: {
                          propName: 'border-color',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {
                  useCase: {
                    hasVideo: {
                      structureMiddleware: 'hasVideo',
                    },
                  },
                },
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#F5F4EC',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '150px 0px 150px 0px',
        desktop: {},
        tablet: {
          background: '#F5F4EC',
          padding: '100px 0px 64px 0px',
        },
        mobile: {
          background: '#F5F4EC',
          padding: '56px 0px 40px 0px',
        },
        uid: `ls-${uuid()}-about-author`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 3',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': true,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 64px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '0px 0px 56px 0px',
                },
                mobile: {
                  padding: '0px 0px 40px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15;"><em style="background-color: rgba(0, 0, 0, 0); color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 48px;">${contentCreatorTitle()}</em></p>`,
                    'max-width': '100%',
                    padding: '0px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="text-align: center;"><em style="font-family: Georgia, serif; font-size: 36px; color: rgb(78, 78, 78); background-color: rgba(0, 0, 0, 0);">${contentCreatorTitle()}</em></p>`,
                      'max-width': '90%',
                      padding: '0px 10px 0px 10px',
                    },
                    mobile: {
                      text: `<p><em style="background-color: rgba(0, 0, 0, 0); color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 24px;">${contentCreatorTitle()}</em></p>`,
                      'max-width': '90%',
                      padding: '0px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 1;"><strong style="color: rgb(78, 78, 78); font-family: Montserrat, sans-serif; font-size: 13px;">_____________________________________________________________________________________________________________________________________________________________________________________</strong></p>',
                    'max-width': '100%',
                    padding: '16px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Montserrat, sans-serif; font-size: 14px;">______________________________________________________________________________________________</strong></p>',
                      'max-width': '100%',
                      padding: '0px 0px 10px 0px',
                    },
                    mobile: {
                      padding: '0px 10px 10px 10px',
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Montserrat, sans-serif; font-size: 12px;">______________________________________________________</strong></p>',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '64px 0px 0px 10px',
                'justify-content': 'end',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '24px 0px 40px 0px',
                  'align-items': 'center',
                  'justify-content': 'center',
                },
                mobile: {
                  padding: '0px 0px 24px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    alt: '',
                    'border-color': '#5981e3',
                    'border-radius': '300px 300px 0px 0px',
                    'border-style': 'solid',
                    'border-top-width': '3px',
                    'border-right-width': '3px',
                    'border-bottom-width': '3px',
                    'border-left-width': '3px',
                    height: '550px',
                    href: '',
                    'is-webp': true,
                    'max-width': '500px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'cover',
                    target: '_blank',
                    desktop: {},
                    tablet: {
                      'max-width': '450px',
                      height: '450px',
                    },
                    mobile: {
                      'max-width': '250px',
                      height: '250px',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/7176939/group_102881.png',
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/7176939/thumb_group_102881.png',
                    metadata: {
                      useCase: {
                        authorImage: {
                          propName: 'src',
                          middleware: 'string',
                        },
                        primaryColor: {
                          propName: 'border-color',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '24px 72px 40px 72px',
                },
                mobile: {
                  padding: '16px 20px 0px 20px',
                  'align-items': 'center',
                  'justify-content': 'center',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15;"><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 24px;">${i18next.t(
                      'pageWizard.salesPage.template.aboutAuthor.created'
                    )}</span></p>`,
                    'max-width': '100%',
                    padding: '40px 10px 10px 10px',
                    desktop: {},
                    tablet: {},
                    mobile: {
                      'max-width': '100%',
                      text: `<p style="line-height: 1.15;"><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 22px;">${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.created'
                      )}</span></p>`,
                      padding: '24px 10px 08px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        authorName: {
                          propName: 'text',
                          middleware: 'text',
                        },
                        primaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    text: '<p style="line-height: 2;"><em style="color: rgb(114, 114, 114); font-size: 18px; font-family: Georgia, serif;">Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</em></p><p style="line-height: 2;"><br></p><p style="line-height: 2;"><em style="color: rgb(114, 114, 114); font-size: 18px; font-family: Georgia, serif;"><span class="ql-cursor">﻿﻿﻿</span>Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</em></p>',
                    'max-width': '100%',
                    padding: '16px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '100%',
                    },
                    mobile: {
                      text: '<p><em style="color: rgb(114, 114, 114); font-family: Georgia, serif; font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</em></p><p><br></p><p><em style="color: rgb(114, 114, 114); font-size: 14px; font-family: Georgia, serif;">Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</em></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        authorDescription: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#99C1DE',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 4',
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '88px 0px 88px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'row',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '64px 0px 90px 0px',
                },
                mobile: {
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '48px 0px 64px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15; text-align: center;"><em style="color: rgb(255, 255, 255); font-family: Georgia, serif; font-size: 32px;">${capitalizeFirstLetter(
                      i18next.t('pageWizard.salesPage.template.offerEnding')
                    )}</em></p>`,
                    'max-width': '100%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '10px 10px 10px 24px',
                      'max-width': '90%',
                      text: `<p style="text-align: center;"><em style="color: rgb(255, 255, 255); font-family: Georgia, serif; font-size: 28px;">${capitalizeFirstLetter(
                        i18next.t('pageWizard.salesPage.template.offerEnding')
                      )}</em></p>`,
                    },
                    mobile: {
                      text: `<p style="text-align: center; line-height: 1.15;"><em style="color: rgb(255, 255, 255); font-family: Georgia, serif; font-size: 18px;">${capitalizeFirstLetter(
                        i18next.t('pageWizard.salesPage.template.offerEnding')
                      )}</em></p>`,
                      padding: '10px 10px 10px 10px',
                      'max-width': '90%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    action: 'link',
                    color: '#FFFFFF',
                    background: '#FF709B',
                    'border-color': '#F5F4EC',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'solid',
                    'border-top-width': '1px',
                    'border-right-width': '1px',
                    'border-bottom-width': '1px',
                    'border-left-width': '1px',
                    'button-class': '',
                    desktop: {},
                    'end-date': '2023-05-26T13:48:23',
                    timezone: '-03:00_utcMinus0300MainCities',
                    'time-on-page': '5',
                    'time-on-page-unit': 'seconds',
                    'delay-type': 'disabled',
                    'font-family': 'Open Sans, sans-serif',
                    'font-size': '20px',
                    'hover-background': 'none',
                    'hover-color': 'none',
                    'has-icon': false,
                    'has-text': true,
                    href: '',
                    'icon-position': 'right',
                    icon: 'fas fa-shopping-cart',
                    'is-bold': false,
                    'is-italic': false,
                    'is-underline': false,
                    'is-strike': false,
                    label: i18next.t('pageWizard.salesPage.template.CTA'),
                    margin: '40px 0px 0px 0px',
                    'max-width': '40%',
                    mobile: {
                      'max-width': '70%',
                      'font-size': '16px',
                      margin: '16px 0px 0px 0px',
                      padding: '12px 32px 12px 32px',
                    },
                    padding: '15px 32px 16px 32px',
                    tablet: {
                      margin: '24px 0px 0px 0px',
                      'max-width': '64%',
                      'font-size': '20px',
                    },
                    target: '_blank',
                    uid: `ls-${uuid()}`,
                    component: 'ls-button',
                    metadata: {
                      useCase: {
                        offerLink: {
                          propName: 'href',
                          middleware: 'string',
                        },
                        purchaseButton: {
                          propName: 'label',
                          middleware: 'string',
                        },
                        secondaryColor: {
                          propName: 'background',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#F5F4EC',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '160px 0px 160px 0px',
        desktop: {},
        tablet: {
          padding: '80px 0px 80px 0px',
        },
        mobile: {
          padding: '64px 0px 70px 0px',
        },
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 4',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '0px 0px 64px 0px',
                },
                mobile: {
                  padding: '0px 0px 40px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '32px 24px 32px 24px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'end',
                    'flex-direction': 'row',
                    background: '#E5E3D8',
                    'background-is-webp': true,
                    'max-width': '450px',
                    margin: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '40px 24px 40px 24px',
                      'max-width': '450px',
                    },
                    mobile: {
                      padding: '24px 24px 24px 24px',
                      'max-width': '345px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        'font-size': '56px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-medal',
                        color: '#4E4E4E',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '48px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        text: '<p style="line-height: 1; text-align: center;"><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 17px;">______________________________________________</span><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 16px;">__</span></p>',
                        'max-width': '100%',
                        padding: '8px 10px 20px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '100%',
                          text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 18px;">________________________________________</span></p>',
                          padding: '10px 10px 20px 10px',
                        },
                        mobile: {
                          text: '<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 14px;">_______________________________________</span></p>',
                          'max-width': '100%',
                          padding: '5px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          useCase: {
                            primaryColor: {
                              propName: 'text',
                              middleware: 'textColor',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next
                          .t('pageWizard.salesPage.template.guarantee.title', {
                            interpolation: { escapeValue: false },
                          })
                          .toUpperCase()}</span></p>`,
                        'max-width': '100%',
                        padding: '10px 10px 10px 10px',
                        desktop: {},
                        tablet: {
                          text: `<p><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next
                            .t(
                              'pageWizard.salesPage.template.guarantee.title',
                              { interpolation: { escapeValue: false } }
                            )
                            .toUpperCase()}</span></p>`,
                          padding: '0px 10px 10px 10px',
                        },
                        mobile: {
                          'max-width': '100%',
                          text: `<p style="text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;">${i18next
                            .t(
                              'pageWizard.salesPage.template.guarantee.title',
                              { interpolation: { escapeValue: false } }
                            )
                            .toUpperCase()}</strong></p>`,
                          padding: '10px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        text: `<p><em style="color:#4e4e4e;font-family:Georgia,serif;font-size:18px">${i18next.t(
                          'pageWizard.salesPage.template.guarantee.description.noQuestions',
                          { interpolation: { escapeValue: false } }
                        )} </em><em style="color:#4e4e4e;font-family:Georgia,serif;font-size:18px">${i18next.t(
                          'pageWizard.salesPage.template.guarantee.description.time'
                        )}</em></p>`,
                        'max-width': '100%',
                        padding: '10px 10px 10px 10px',
                        desktop: {},
                        tablet: {
                          text: `<p><em style="color:#4e4e4e;font-family:Georgia,serif;font-size:18px">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.description.noQuestions',
                            { interpolation: { escapeValue: false } }
                          )} </em><em style="color:#4e4e4e;font-family:Georgia,serif;font-size:18px">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.description.time'
                          )}</em></p>`,
                        },
                        mobile: {
                          'max-width': '90%',
                          text: `<p style="text-align:center"><em style="color:#4e4e4e;font-family:Georgia,serif;font-size:14px">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.description.noQuestions',
                            { interpolation: { escapeValue: false } }
                          )}</em><em style="color:#4e4e4e;font-family:Georgia,serif;font-size:14px">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.description.time'
                          )}</em></p>`,
                          padding: '0px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {},
                  },
                ],
                metadata: {},
              },
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {},
                mobile: {},
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '32px 24px 32px 24px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'end',
                    'flex-direction': 'row',
                    background: '#E5E3D8',
                    'background-is-webp': true,
                    'max-width': '450px',
                    margin: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '40px 24px 40px 24px',
                    },
                    mobile: {
                      padding: '24px 24px 24px 24px',
                      'max-width': '345px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        'font-size': '48px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-lock',
                        color: '#4E4E4E',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '40px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        text: '<p style="line-height: 1; text-align: center;"><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 17px;">______________________________________________</span><span style="color: rgb(153, 193, 222); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 16px;">__</span></p>',
                        'max-width': '100%',
                        padding: '8px 10px 20px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '100%',
                          text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 18px;">________________________________________</span></p>',
                          padding: '10px 10px 20px 10px',
                        },
                        mobile: {
                          text: '<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 14px;">_______________________________________</span><span style="color: rgb(78, 78, 78); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;"><span class="ql-cursor">﻿﻿</span></span></p>',
                          'max-width': '100%',
                          padding: '5px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          useCase: {
                            primaryColor: {
                              propName: 'text',
                              middleware: 'textColor',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                          'pageWizard.salesPage.template.guarantee.safeBuy.title'
                        )}</span></p>`,
                        'max-width': '100%',
                        padding: '10px 10px 10px 10px',
                        desktop: {},
                        tablet: {
                          text: `<p><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.title'
                          )}</span></p>`,
                        },
                        mobile: {
                          'max-width': '100%',
                          text: `<p style="line-height: 1.15; text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.title'
                          )}</strong></p>`,
                          padding: '10px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        text: `<p><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 18px;">${i18next.t(
                          'pageWizard.salesPage.template.guarantee.safeBuy.description'
                        )}</em></p>`,
                        'max-width': '100%',
                        padding: '10px 10px 10px 10px',
                        desktop: {},
                        tablet: {
                          text: `<p><span style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.description'
                          )}</span></p>`,
                        },
                        mobile: {
                          'max-width': '90%',
                          text: `<p style="text-align: center;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 14px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.description'
                          )}</em></p>`,
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#99C1DE',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '80px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {
          padding: '72px 0px 0px 0px',
        },
        uid: `ls-${uuid()}-offer`,
        component: 'ls-section',
        metadata: {
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: 'none',
            'background-is-webp': true,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 40px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'row',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '0px 0px 24px 0px',
                },
                mobile: {
                  padding: '0px 0px 8px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    alt: '',
                    'border-color': '#5981e3',
                    'border-radius': '4px 4px 4px 4px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    height: '100px',
                    href: '',
                    'is-webp': true,
                    'max-width': '100px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'contain',
                    target: '_blank',
                    desktop: {},
                    tablet: {},
                    mobile: {
                      'max-width': '80px',
                      height: '80px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/6802015/devices.png',
                    metadata: {},
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/6802015/thumb_devices.png',
                  },
                  {
                    text: `<p style="text-align: center;"><span style="font-family: &quot;Open Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 20px;">${i18next.t(
                      'pageWizard.salesPage.template.offer.anyDevice'
                    )}</span></p>`,
                    'max-width': '100%',
                    padding: '32px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '90%',
                      text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                        'pageWizard.salesPage.template.offer.anyDevice'
                      )}</span></p>`,
                      padding: '24px 10px 10px 20px',
                    },
                    mobile: {
                      text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;">${i18next.t(
                        'pageWizard.salesPage.template.offer.anyDevice'
                      )}</span></p>`,
                      padding: '16px 10px 10px 10px',
                      'max-width': '100%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '24px 0px 0px 0px',
                },
                mobile: {
                  padding: '16px 20px 0px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '180px 10px 100px 10px',
                    'border-radius': '500px 500px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'center',
                    'flex-direction': 'column',
                    background: '#F5F4EC',
                    'background-is-webp': true,
                    'max-width': '80%',
                    margin: '10px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      padding: '100px 10px 70px 10px',
                    },
                    mobile: {
                      'max-width': '100%',
                      padding: '48px 10px 56px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 16px;">${i18next
                          .t(
                            'pageWizard.salesPage.template.offer.priceStatement'
                          )
                          .toLowerCase()}</span></p>`,
                        'max-width': '72%',
                        padding: '10px 0px 0px 0px',
                        desktop: {},
                        tablet: {
                          text: `<p style="text-align: center;"><span style="color: rgb(78, 78, 78); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">${i18next
                            .t(
                              'pageWizard.salesPage.template.offer.priceStatement'
                            )
                            .toLowerCase()}</span></p>`,
                        },
                        mobile: {
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;">${i18next
                            .t(
                              'pageWizard.salesPage.template.offer.priceStatement'
                            )
                            .toLowerCase()}</span></p>`,
                          padding: '10px 0px 5px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 120px;">R$ 39,90</strong></p>',
                        'max-width': '100%',
                        padding: '0px 0px 24px 0px',
                        desktop: {},
                        tablet: {
                          'max-width': '100%',
                          text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 88px;">R$ 39,90</strong></p>',
                          padding: '16px 0px 16px 0px',
                        },
                        mobile: {
                          text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 48px;">R$ 39,90</strong></p>',
                          'max-width': '100%',
                          padding: '5px 0px 5px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          useCase: {
                            offerPrice: {
                              propName: 'text',
                              middleware: 'text',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 16px;">${maxInstallments}</span></p>`,
                        'max-width': '72%',
                        padding: '10px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          text: `<p style="text-align: center;"><span style="color: rgb(78, 78, 78); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">${maxInstallments}</span></p>`,
                          padding: '16px 0px 10px 0px',
                        },
                        mobile: {
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;">${maxInstallments}</span></p>`,
                          padding: '20px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          installments: true,
                        },
                      },
                      {
                        action: 'link',
                        color: '#FFFFFF',
                        background: '#FF709B',
                        'border-color': '#F5F4EC',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '1px',
                        'border-right-width': '1px',
                        'border-bottom-width': '1px',
                        'border-left-width': '1px',
                        'button-class': '',
                        desktop: {},
                        'end-date': '2023-05-26T13:48:23',
                        timezone: '-03:00_utcMinus0300MainCities',
                        'time-on-page': '5',
                        'time-on-page-unit': 'seconds',
                        'delay-type': 'disabled',
                        'font-family': 'Open Sans, sans-serif',
                        'font-size': '20px',
                        'hover-background': 'none',
                        'hover-color': 'none',
                        'has-icon': false,
                        'has-text': true,
                        href: '',
                        'icon-position': 'right',
                        icon: 'fas fa-shopping-cart',
                        'is-bold': false,
                        'is-italic': false,
                        'is-underline': false,
                        'is-strike': false,
                        label: i18next.t('pageWizard.salesPage.template.CTA'),
                        margin: '40px 0px 0px 0px',
                        'max-width': '50%',
                        mobile: {
                          'max-width': '90%',
                          'font-size': '16px',
                          margin: '20px 0px 0px 0px',
                          padding: '12px 10px 12px 10px',
                        },
                        padding: '15px 32px 16px 32px',
                        tablet: {
                          margin: '40px 0px 0px 0px',
                          'max-width': '72%',
                          'font-size': '20px',
                        },
                        target: '_blank',
                        uid: `ls-${uuid()}`,
                        component: 'ls-button',
                        metadata: {
                          useCase: {
                            offerLink: {
                              propName: 'href',
                              middleware: 'string',
                            },
                            purchaseButton: {
                              propName: 'label',
                              middleware: 'string',
                            },
                            secondaryColor: {
                              propName: 'background',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 16px;"><em>${i18next
                          .t('pageWizard.salesPage.template.offer.secure')
                          .toLowerCase()}</em></strong></p>`,
                        'max-width': '60%',
                        padding: '24px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          'max-width': '64%',
                          text: `<p style="text-align: center;"><strong style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 14px;"><em>${i18next
                            .t('pageWizard.salesPage.template.offer.secure')
                            .toLowerCase()}</em></strong></p>`,
                          padding: '16px 0px 10px 0px',
                        },
                        mobile: {
                          'max-width': '100%',
                          text: `<p style="text-align: center; line-height: 1.15;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 12px;">${i18next
                            .t('pageWizard.salesPage.template.offer.secure')
                            .toLowerCase()}</em></p>`,
                          padding: '16px 0px 8px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {
                      useCase: {
                        offerInstallments: {
                          propName: 'text',
                          structureMiddleware: 'offerInstallments',
                          props: {
                            parent: 'ls-6CY9dmyy12B2wXn9iJ3RAi',
                          },
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#E5E3D8',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-07-26T21:42:51',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '140px 0px 160px 0px',
        desktop: {},
        tablet: {
          padding: '90px 0px 90px 0px',
        },
        mobile: {
          padding: '64px 0px 64px 0px',
        },
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 7',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'start',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {},
                mobile: {
                  padding: '0px 20px 0px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 64px;">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.title'
                    )}</em></p>`,
                    'max-width': '100%',
                    padding: '10px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="line-height: 1.15;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 64px;">${i18next.t(
                        'pageWizard.salesPage.template.FAQ.title'
                      )}</em></p>`,
                      padding: '10px 48px 10px 48px',
                    },
                    mobile: {
                      padding: '10px 10px 0px 10px',
                      'max-width': '100%',
                      text: `<p style="line-height: 1.15; text-align: center;"><em style="color: rgb(78, 78, 78); font-family: Georgia, serif; font-size: 32px;">${i18next.t(
                        'pageWizard.salesPage.template.FAQ.title'
                      )}</em></p>`,
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 1;"><strong style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 13px;">_____________________________________________________________________________________________________________________________________________________________________________________</strong></p>',
                    'max-width': '100%',
                    padding: '0px 10px 64px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1;"><strong style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 14px;">__________________________________________________________________________________________</strong></p>',
                      'max-width': '90%',
                      padding: '0px 10px 48px 10px',
                    },
                    mobile: {
                      padding: '0px 10px 32px 10px',
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(153, 193, 222); font-family: Montserrat, sans-serif; font-size: 12px;">____________________________________________________</strong></p>',
                      'max-width': '100%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        primaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    margin: '0px 0px 0px 0px',
                    padding: '10px 10px 10px 10px',
                    'max-width': '100%',
                    color: '#FFFFFF',
                    background: 'none',
                    'header-background': '#F5F4EC',
                    'content-background': '#D4D1C1',
                    'icon-color': '#99C1DE',
                    'vertical-spacing': '48px',
                    'initial-behavior': 'close',
                    items: `[{"id":"e5a8d97b-a547-4882-8acd-3071860877be","title":{"text":"<h3><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.question'
                    )}</em></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.question'
                    )}</em></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>","padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>"},"desktop":{},"metadata":{}}},{"id":"ajgXKNXRw7Zt1K37HNzRhi","title":{"text":"<h3><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.question'
                    )}</em></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.question'
                    )}</em></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.answer'
                    )}&nbsp;</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.answer'
                    )}&nbsp;</span></p>"},"desktop":{},"metadata":{}}},{"id":"ds79hyCmsvwBMegfdQbpcF","title":{"text":"<h3><em style=\\"color: rgb(114, 114, 114); font-family: Georgia, serif; font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.question'
                    )}</em></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><em style=\\"color: rgb(114, 114, 114); font-family: Georgia, serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.question'
                    )}</em></h3>","padding":"12px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.answer'
                    )}&nbsp;</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.answer'
                    )}&nbsp;</span></p>"},"desktop":{},"metadata":{}}},{"id":"57RRF23Lrf5bAe5FWkx7fp","title":{"text":"<h3><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.question'
                    )}</em></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.question'
                    )}</em></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.title'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.first'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.second'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.third'
                    )}</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.title'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.first'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.second'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.third'
                    )}</span></p>"},"desktop":{},"metadata":{}}},{"id":"5uUGAukKXSdDWyFjkRcU5d","title":{"text":"<h3><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.question'
                    )}</em></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><em style=\\"font-family: Georgia, serif; color: rgb(114, 114, 114); font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.question'
                    )}</em></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.answer'
                    )}</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(78, 78, 78); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.answer'
                    )}</span></p>"},"desktop":{},"metadata":{}}}]`,
                    desktop: {},
                    tablet: {
                      'max-width': '90%',
                      'vertical-spacing': '48px',
                    },
                    mobile: {
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      'vertical-spacing': '32px',
                      margin: '6px 0px 6px 0px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-accordion',
                    metadata: {
                      useCase: {
                        primaryColor: {
                          propName: 'icon-color',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#F5F4EC',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 6',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '40px 0px 40px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '32px 0px 32px 0px',
                },
                mobile: {
                  padding: '30px 0px 30px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="text-align: center;"><em style="font-family: Georgia, serif; font-size: 14px; color: rgb(78, 78, 78);">Copyright ©</em></p><p style="text-align: center;"><em style="font-family: Georgia, serif; font-size: 14px; color: rgb(78, 78, 78);">${i18next.t(
                      'pageWizard.salesPage.template.copyright'
                    )}</em></p>`,
                    'max-width': '40%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '50%',
                      text: `<p style="text-align: center; line-height: 2;"><em style="font-family: Georgia, serif; font-size: 14px; color: rgb(78, 78, 78);">Copyright ©</em></p><p style="text-align: center; line-height: 2;"><em style="font-family: Georgia, serif; font-size: 14px; color: rgb(78, 78, 78);">${i18next.t(
                        'pageWizard.salesPage.template.copyright'
                      )}</em></p>`,
                    },
                    mobile: {
                      text: `<p style="text-align: center;"><em style="font-family: Georgia, serif; font-size: 14px; color: rgb(78, 78, 78);">Copyright ©</em></p><p style="text-align: center;"><em style="font-family: Georgia, serif; font-size: 14px; color: rgb(78, 78, 78);">${i18next.t(
                        'pageWizard.salesPage.template.copyright'
                      )}</em></p>`,
                      'max-width': '80%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
    ],
    metadata: {},
  };
};

export const getClassicFontsConfig = () => ({
  Montserrat: {
    regular: [400, 700],
    italic: [400, 700],
  },
  'Open Sans': {
    regular: [400, 700],
    italic: [400, 700],
  },
  'Nunito Sans': {
    regular: [400, 700],
    italic: [400, 700],
  },
});
