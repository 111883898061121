import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-default.css';

import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import clone from 'clone';
import debounce from 'lodash.debounce';
import { removeEmojis } from '@common/utils';
import { TextInput } from '@components/TextInput';
import { Block } from '@components/Block';
import { Icon, Button, LinkConfig, Container } from './styles';

type LinksProps = {
  linkList: LinkTreeLinksConfig[];
  onLinksValueChange: (value: LinkTreeLinksConfig[]) => void;
};

const LinksComponent: React.FC<LinksProps> = ({
  linkList,
  onLinksValueChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [links, setLinks] = useState(linkList);
  const debouncedOnValueChange = useRef(debounce(onLinksValueChange, 500));

  const handleChange = useCallback(
    (e: React.ChangeEvent, index: number) => {
      const { value, name } = e.target as HTMLInputElement;
      const prop = name as keyof LinkTreeLinksConfig;
      const newLinks = clone(links);
      newLinks[index][prop] = value;
      setLinks(newLinks);
      debouncedOnValueChange.current(newLinks);
    },
    [links]
  );

  const handleProtocolChange = useCallback(
    (e: React.ChangeEvent, index: number) => {
      const { value, name } = e.target as HTMLInputElement;
      const prop = name as keyof LinkTreeLinksConfig;
      const newLinks = clone(links);
      const valueWithProtocol =
        !value || value.startsWith('https://') || value.startsWith('http://')
          ? value
          : `https://${value}`;
      const valueWithoutEmojis = removeEmojis(valueWithProtocol);

      newLinks[index][prop] = valueWithoutEmojis;
      setLinks(newLinks);
      debouncedOnValueChange.current(newLinks);
    },
    [links]
  );

  const handleDeleteLink = (index: number) => {
    if (links.length === 1) {
      return;
    }
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
    onLinksValueChange(newLinks);
  };

  const handleCreateLink = useCallback(() => {
    const newLinks = [...links];
    newLinks.push({
      ...newLinks[0],
      id: `ls-${uuid()}`,
      label: t(`pageWizard.publishPage.links.new`),
      href: '',
    });
    setLinks(newLinks);
    onLinksValueChange(newLinks);
  }, [links, onLinksValueChange, t]);

  return (
    <Block label="pageWizard.publishPage.links.label">
      <Container {...attrs}>
        {links.map(({ label, href, id }, index) => (
          <LinkConfig key={id}>
            <TextInput
              removeEmoji
              id={`text-${id}`}
              label="Text"
              name="label"
              placeholder={t(
                'pageWizard.publishPage.links.inputs.textPlaceholder'
              )}
              size="md"
              value={label}
              onChange={(e) => handleChange(e, index)}
            />
            <TextInput
              removeEmoji
              id={`href-${id}`}
              label="Link"
              name="href"
              placeholder={t(
                'pageWizard.publishPage.links.inputs.hrefPlaceholder'
              )}
              size="md"
              value={href}
              onChange={(e) => handleChange(e, index)}
              onBlur={(e) => handleProtocolChange(e, index)}
            />
            <Button
              className="hot-button"
              onClick={() => handleDeleteLink(index)}
              disabled={links.length === 1}
            >
              <Icon className="fal fa-trash" />
            </Button>
          </LinkConfig>
        ))}
        <button
          className="hot-button hot-button--tertiary"
          onClick={handleCreateLink}
        >
          {t('pageWizard.publishPage.links.buttons.add')}
        </button>
      </Container>
    </Block>
  );
};

export const Links = memo(LinksComponent);
