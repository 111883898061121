import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import User from '@services/user';
import { updateUserProfile } from '@store/slices/userInfo';

export const getPublicId = createAsyncThunk<
  string,
  void,
  {
    state: LocalState;
  }
>('user/getPublicId', async (_, { dispatch }) => {
  try {
    const { public_id: publicId } = await User.getUserPublicId();
    dispatch(updateUserProfile({ publicId }));

    return publicId;
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});
