import { HTMLAttributes, memo } from 'react';
import { CollapseWrapper, Label } from './style';

type CosmosCollapseProps = HTMLAttributes<HTMLDivElement> & {
  label: string;
  active?: boolean;
};

const CosmosCollapseComponent: React.FC<CosmosCollapseProps> = ({
  label,
  children,
  active = false,
  ...attrs
}) => (
  <CollapseWrapper {...attrs}>
    <hot-collapse className="hot-form" active={active}>
      <Label>{label}</Label>
      <div slot="collapsed">{children}</div>
    </hot-collapse>
  </CollapseWrapper>
);

export const CosmosCollapse = memo(CosmosCollapseComponent);
