import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.div`
  .hot-button--secondary {
    margin-right: 16px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
  margin-bottom: 16px;
  background-color: white;
`;

type IconProps = {
  step?: number;
};

export const FirstIcon = styled(FontAwesomeIcon)<IconProps>`
  color: var(--primary);

  ${({ step }) => css`
    height: ${step === 2 ? '8px' : '16px'};
    width: ${step === 2 ? '8px' : '16px'};
  `}
`;

export const SecondIcon = styled(FontAwesomeIcon)<IconProps>`
  ${({ step }) => css`
    height: ${step === 2 ? '16px' : '8px'};
    width: ${step === 2 ? '16px' : '8px'};
    color: ${step === 2 ? 'var(--primary)' : 'var(--gray-300)'};
  `}
`;

export const StepsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 36px 0 24px;
`;

type StepContentProps = {
  step: number;
};

export const LeftStepContent = styled.div<StepContentProps>`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  ${({ step }) => css`
    border-image: linear-gradient(
        to left,
        ${step === 1 ? 'var(--gray-300)' : 'var(--primary)'} 50%,
        white 50%
      )
      100% 1;
  `}
  border-bottom: none;
`;

export const RightStepContent = styled.div<StepContentProps>`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  ${({ step }) => css`
    border-image: linear-gradient(
        to right,
        ${step === 1 ? 'var(--gray-300)' : 'var(--primary)'} 50%,
        white 50%
      )
      100% 1;
  `}
  border-bottom: none;
`;

export const FirstStepText = styled.div<StepContentProps>`
  margin: 0;
  margin-top: -8px;
  ${({ step }) => css`
    font-weight: ${step === 1 ? 'bold' : 'none'};
  `}
`;

export const SecondStepText = styled.div<StepContentProps>`
  margin: 0;
  margin-top: -8px;
  ${({ step }) => css`
    font-weight: ${step === 2 ? 'bold' : 'none'};
  `}
`;
