import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { Icons } from '../Icon/icons';
import {
  MenuItemLabel,
  StyledMenuItem,
  MenuItemWrapper,
  StyledBlockedIcon,
} from './styles';

export type MenuItemProps = {
  active?: boolean;
  icon?: Icons;
  id: string;
  label: string;
  breakLine?: boolean;
  onboardingKey?: string;
  isBlocked?: boolean;
  onClick?: (id: string) => void;
};

const MenuItemComponent: React.FC<MenuItemProps> = ({
  active = false,
  icon,
  id,
  label: labelProp,
  breakLine = true,
  onboardingKey,
  isBlocked,
  onClick,
}) => {
  const { t } = useTranslation();

  const label = useMemo(() => t(labelProp), [labelProp, t]);
  const props = useMemo(() => (active ? { active: true } : {}), [active]);

  const MenuItemIcon = () => {
    const iconProps = active ? { color: 'var(--blue-dark)' } : {};
    return <Icon name={icon as Icons} {...iconProps} />;
  };

  return (
    <MenuItemWrapper
      hasIcon={Boolean(icon)}
      data-onboarding={onboardingKey}
      data-testid={`side-menu-${id}`}
    >
      <hot-menu-item {...props} onClick={() => onClick?.(id)} role="menuitem">
        <StyledMenuItem>
          {icon && <MenuItemIcon />}
          <MenuItemLabel breakLine={breakLine} hasIcon={Boolean(icon)}>
            {label}
          </MenuItemLabel>
          {isBlocked && <StyledBlockedIcon className="fal fa-lock" />}
        </StyledMenuItem>
      </hot-menu-item>
    </MenuItemWrapper>
  );
};

export const MenuItem = memo(MenuItemComponent);
