import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const userInfoInitialState: UserInfoState = {
  permissions: {
    templateManagementEnabled: false,
    userTemplateManagementEnabled: false,
    madeWithManagementEnabled: false,
    pageWithRedirectEnabled: false,
    importPageEnabled: false,
    exportPageEnabled: false,
    domainLimit: null,
    pageLimit: null,
  },
  userProfile: {
    id: '',
    ucode: null,
    isExtensions: false,
    plan: {
      isFree: false,
      name: '',
      lastChangedAt: null,
    },
    email: '',
    name: '',
    username: '',
    publicId: '',
    apiKey: '',
  },
  featureFlags: {
    trialEventsEnabled: false,
    rewriteTextEnabled: false,
    userAbTest: false,
    transparentCheckoutEnabled: false,
    zendeskMessengerEnabled: false,
    editFunnelPagesEnabled: false,
  },
};

const userInfo = createSlice({
  name: 'userInfo',
  initialState: userInfoInitialState,
  reducers: {
    updateInfo(state, action: PayloadAction<UserInfo>) {
      return { ...state, ...action.payload };
    },
    updateUserProfile(state, action: PayloadAction<Partial<UserProfile>>) {
      state.userProfile = { ...state.userProfile, ...action.payload };
    },
  },
});

export const { updateInfo, updateUserProfile } = userInfo.actions;

export default userInfo.reducer;
