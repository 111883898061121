import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '../../../../services/mediaCenter';
import { updateBreadcrumbOnForwardNavigation } from '../../../slices/mediaCenter';

export const navigateForwardFolder = createAsyncThunk<
  Media[],
  void,
  {
    state: LocalState;
  }
>('mediaCenter/navigateForwardFolder', async (_, { getState, dispatch }) => {
  const {
    mediaCenter: { breadcrumbsRedo },
  } = getState();

  dispatch(updateBreadcrumbOnForwardNavigation());

  try {
    const [breadcrumb] = breadcrumbsRedo;
    if (!breadcrumb) {
      throw new Error('no folder to navigate forward to');
    }
    return MediaCenter.getFolderMedia(breadcrumb.id.toString());
  } catch (error) {
    // todo - create error handling process
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const navigateForwardFolderBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(navigateForwardFolder.pending, (state: MediaCenterState) => {
    state.media = [];
    state.loading = true;
  });
  builder.addCase(
    navigateForwardFolder.fulfilled,
    (state: MediaCenterState, action) => {
      state.loading = false;
      state.media = action.payload;
    }
  );
  builder.addCase(navigateForwardFolder.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
