import { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ComponentState } from '@hotmart-org-ca/saas-pages-engine';
import { usePageConfig } from '@hooks/usePageConfig';
import { usePageInfo } from '@hooks/usePageInfo';
import { useEngine } from '@hooks/useEngine';
import { Container } from './styles';

export type RecommendImageSizeModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps;

export const RecommendImageSizeModifier: React.FC<RecommendImageSizeModifierProps> =
  ({ uid, ...attrs }) => {
    const { t } = useTranslation();
    const { mode } = usePageInfo();
    const { mobileFirst } = usePageConfig();
    const { preview } = useEngine();

    const metadata = useSelector(
      (state: State) => (state[uid] as ComponentState)?.metadata || {}
    );

    const size = useMemo(() => {
      const recommendedImageSize = metadata?.recommendedImageSize || {};
      if (mobileFirst) {
        if (preview === 'mobile') {
          return recommendedImageSize.default;
        }
        return recommendedImageSize[preview];
      }

      if (preview === 'desktop') {
        return recommendedImageSize.default;
      }
      return recommendedImageSize[preview];
    }, [metadata?.recommendedImageSize, mobileFirst, preview]);

    return mode === 'page' && size ? (
      <Container {...attrs}>
        {t('modifiers.image.recommendedSize', {
          size,
        })}
      </Container>
    ) : null;
  };
