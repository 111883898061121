import { HTMLAttributes, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  CircleBorder,
  Container,
  FirstDescription,
  Link,
  SecondDescription,
} from './styles';

type EmptyStateProps = HTMLAttributes<HTMLDivElement>;

const EmptyStateComponent: React.FC<EmptyStateProps> = ({ ...attrs }) => {
  const { t } = useTranslation();

  return (
    <Container {...attrs}>
      <CircleBorder>
        <FontAwesomeIcon icon={faBookmark} />
      </CircleBorder>
      <FirstDescription>
        {t('savedSections.emptyState.description.first')}
      </FirstDescription>
      <SecondDescription>
        {t('savedSections.emptyState.description.second')}
      </SecondDescription>
      <Link
        href="https://help.extensoes.hotmart.com/hc/pt-br/articles/20427899373709-Como-usar-a-funcionalidade-de-salvar-se%C3%A7%C3%B5es"
        openInNewTab
        text={t('savedSections.emptyState.link')}
      />
    </Container>
  );
};

export const EmptyState = memo(EmptyStateComponent);
