import styled, { css } from 'styled-components';
import { UploadMedia } from '@components/UploadMedia';

export const Container = styled.div`
  padding: 16px 16px 0px;
`;

export const Title = styled.label`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
`;

type PreviewProps = {
  previewHeight?: string;
};

export const Preview = styled.div<PreviewProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  margin-bottom: 16px;
  width: 100%;
  color: var(--gray-400);
  font-size: 24px;
  background-color: var(--gray-100);

  ${({ previewHeight = '100px' }) => css`
    height: ${previewHeight};
  `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const StyledUploadMedia = styled(UploadMedia)`
  padding: 0;
`;
