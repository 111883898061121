import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const SectionsTemplate: React.FC<BaseIconProps> = ({
  color,
  ...attrs
}) => (
  <StyledSVG
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M21.75 0.5H2.25C0.984375 0.5 0 1.53125 0 2.75V19.25C0 20.5156 0.984375 21.5 2.25 21.5H21.75C22.9688 21.5 24 20.5156 24 19.25V2.75C24 1.53125 22.9688 0.5 21.75 0.5ZM1.5 2.75C1.5 2.375 1.82812 2 2.25 2H4.5V5H1.5V2.75ZM22.5 19.25C22.5 19.6719 22.125 20 21.75 20H2.25C1.82812 20 1.5 19.6719 1.5 19.25V6.5H22.5V19.25ZM22.5 5H6V2H21.75C22.125 2 22.5 2.375 22.5 2.75V5Z"
      fill={color}
    />
  </StyledSVG>
);
