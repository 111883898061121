import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Submenu } from '@components/Submenu';
import { CosmosLoader } from '@components/CosmosLoader';
import { CosmosButton } from '@components/CosmosButton';
import { useTemplateInfo } from '@hooks/useTemplateInfo';
import { useUserInfo } from '@hooks/useUserInfo';
import Pages from '@services/pages';
import Toast from '@services/toast';
import { TemplateCategory } from './components/TemplateCategory';
import { TemplateStatus } from './components/TemplateStatus';
import { Content } from './styles';

export const TemplateCategorizationSubmenu: React.FC<
  HTMLAttributes<HTMLDivElement>
> = ({ ...attrs }) => {
  const { isNew, isExpert, templateCategories, uuid, mode, updateInfo } =
    useTemplateInfo();
  const { templateManagementEnabled } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [categories, setCategories] = useState<TemplateCategory[]>([]);
  const [updatedTemplate, setUpdatedTemplate] = useState({
    isExpert,
    isNew,
    templateCategoryUuids: templateCategories.map((category) => category.uuid),
  });

  const handleStatusChange = useCallback(
    (templateIsNew: boolean, templateIsExpert: boolean) => {
      setUpdatedTemplate((state) => ({
        ...state,
        isNew: templateIsNew,
        isExpert: templateIsExpert,
      }));
    },
    []
  );

  const handleCategoryChange = useCallback((values: string[]) => {
    setUpdatedTemplate((state) => ({
      ...state,
      templateCategoryUuids: values,
    }));
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setSaving(true);
      const template = await Pages.updatePage<TemplatePage>(
        uuid,
        updatedTemplate,
        mode,
        templateManagementEnabled
      );
      updateInfo(template);
      Toast.success('toastMessages.template.success.saved');
    } catch (e) {
      Toast.error('toastMessages.template.error.saved');
    } finally {
      setSaving(false);
    }
  }, [mode, templateManagementEnabled, updateInfo, updatedTemplate, uuid]);

  const fetchCategories = useCallback(async () => {
    try {
      const data = await Pages.getTemplateCategories();
      setCategories(data);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <Submenu title="templateCategorization.title" padding="16px" {...attrs}>
      {loading ? (
        <CosmosLoader />
      ) : (
        <Content>
          <TemplateCategory
            categories={categories}
            selectedCategories={updatedTemplate.templateCategoryUuids}
            onChange={handleCategoryChange}
          />
          <TemplateStatus
            isExpert={updatedTemplate.isExpert}
            isNew={updatedTemplate.isNew}
            onStatusChange={handleStatusChange}
          />
          <CosmosButton
            label="templateCategorization.save"
            type="primary"
            isLoading={saving}
            onButtonClicked={handleSave}
          />
        </Content>
      )}
    </Submenu>
  );
};
