import { createAsyncThunk } from '@reduxjs/toolkit';
import UseCases from '@services/useCases';
import Klicksend from '@services/klicksend';
import { AppError } from '@common/app-error';
import { updateProperties } from '@store/slices/useCases';
import { validateEmoji } from '@common/utils';

export const saveProperties = createAsyncThunk<
  void,
  {
    id: number;
    properties: any;
  },
  {
    state: LocalState;
    rejectedMeta: string[];
  }
>(
  'useCases/saveProperties',
  async (
    { id, properties: props },
    { getState, dispatch, rejectWithValue }
  ) => {
    const { useCases } = getState();
    const properties = { ...useCases.properties, ...props };

    try {
      validateEmoji(properties);

      dispatch(updateProperties(properties));
      await UseCases.update(id, { properties });

      if (
        useCases.type === 'funnel_capture_publish_lead_capture_page' &&
        useCases.currentStep === 3 &&
        properties.formId
      ) {
        await Klicksend.updateForm({
          id: properties.formId,
          singleOptIn: true,
          terms: properties.formTerms,
          fields: properties.formFields,
          name: properties.formName,
          tag: properties.formTag,
        });
      }
    } catch (e) {
      const error = e as Error;

      if (error instanceof AppError) {
        throw rejectWithValue(error.message, [error.message]);
      }

      const appError = new AppError('toastMessages.general.error.saved');
      throw rejectWithValue(appError.message, [appError.message]);
    }
  }
);
