import '@hotmart-org-ca/cosmos/dist/tooltip';

import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import { copyToClipboard } from '../../../../common/utils';
import { setActiveMenu, setActiveSubmenu } from '../../../../store/slices/menu';
import { Actions, Container, Icon, Url } from './styles';

export type PageURLProps = HTMLAttributes<HTMLDivElement> & {
  device: DeviceOptions;
};

const PageURLComponent: React.FC<PageURLProps> = ({ ...attrs }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const domain = useSelector((state: State) => state.pageInfo.domain);
  const path = useSelector((state: State) => state.pageInfo.path);
  const published = useSelector((state: State) => state.pageInfo.published);

  const url = useMemo(() => `${domain}/${path}`, [domain, path]);

  const openUrlSubmenu = useCallback(() => {
    EditorEngineManager.clearActive();
    dispatch(setActiveMenu('settings'));
    dispatch(setActiveSubmenu('url'));
  }, [dispatch]);

  const openPageUrl = useCallback(() => {
    window.open(`//${url}`, '_blank');
  }, [url]);

  return (
    <Container {...attrs}>
      <Url onDoubleClick={openUrlSubmenu}>{url}</Url>
      <Actions>
        <hot-tooltip content={t('seo.path.edit')}>
          <Icon onClick={openUrlSubmenu}>
            <i className="fal fa-pen" />
          </Icon>
        </hot-tooltip>

        <hot-tooltip content={t('seo.path.copy')}>
          <Icon onClick={() => copyToClipboard(url)}>
            <i className="fal fa-copy" />
          </Icon>
        </hot-tooltip>

        {published && (
          <hot-tooltip content={t('seo.path.openPage')}>
            <Icon onClick={openPageUrl}>
              <Icon className="fal fa-external-link" />
            </Icon>
          </hot-tooltip>
        )}
      </Actions>
    </Container>
  );
};

export const PageURL = memo(PageURLComponent);
