import Quill from 'quill';
import {
  EditorEngineManager,
  Template,
  ComponentState,
} from '@hotmart-org-ca/saas-pages-engine';
import { AppError } from '@common/app-error';

export class BaseUseCase {
  protected categories: StringMap<Template[]> = {};

  protected structureMiddlewares: StringMap<UseCaseStructureMiddleware> = {};

  protected middlewares: StringMap<UseCaseMiddleware> = {
    string: BaseUseCase.stringMiddleware,
    text: BaseUseCase.textMiddleware,
    textColor: BaseUseCase.textColorMiddleware,
  };

  startPage(template: Template, properties: StringMap) {
    this.groupElementsByCategory(template);

    EditorEngineManager.clearState();
    EditorEngineManager.setPage(template, true);

    Object.keys(this.categories).forEach((category) => {
      if (properties[category] !== undefined) {
        this.modifyByCategory(category, properties[category], properties);
      }
    });
  }

  modifyByCategory(
    category: string,
    value: unknown,
    additionalProps: StringMap = {}
  ) {
    const elements = this.categories[category];

    if (elements && elements.length) {
      elements.forEach((element) => {
        const useCase = element.metadata?.useCase as MetadataUseCase;
        const {
          middleware = 'string',
          structureMiddleware,
          propName,
          props,
        } = useCase[category];

        const structureMiddlewareMethod =
          this.structureMiddlewares[structureMiddleware];

        if (structureMiddlewareMethod) {
          structureMiddlewareMethod({
            element,
            value,
            propName,
            props: { ...props, ...additionalProps },
          });
          return;
        }

        const middlewareMethod = this.middlewares[middleware];
        const payload = middlewareMethod({ propName, value, element });

        EditorEngineManager.updateElement(element.uid, payload, false);
      });
    }
  }

  updateMiddlewares(middlewares: StringMap<UseCaseMiddleware>) {
    this.middlewares = { ...this.middlewares, ...middlewares };
  }

  updateStructureMiddlewares(
    structureMiddlewares: StringMap<UseCaseStructureMiddleware>
  ) {
    this.structureMiddlewares = {
      ...this.structureMiddlewares,
      ...structureMiddlewares,
    };
  }

  static getTemplateWithProperties() {
    const page = EditorEngineManager.getFinalPage();

    if (page) {
      return page;
    }
    throw new AppError('Final page not found');
  }

  private groupElementsByCategory(template: Template) {
    this.categories = {};
    this.categories = template.children
      ? BaseUseCase.groupByCategory(template.children)
      : {};
  }

  private static groupByCategory(
    templates: Template[],
    currentCategories: StringMap<Template[]> = {}
  ) {
    let categories = { ...currentCategories };

    templates.forEach((element) => {
      if (element.metadata?.useCase) {
        Object.keys(element.metadata.useCase).forEach((category) => {
          if (categories[category]) {
            categories[category].push(element);
          } else {
            categories[category] = [element];
          }
        });
      }

      if (element.children?.length) {
        categories = BaseUseCase.groupByCategory(element.children, categories);
      }
    });

    return categories;
  }

  private static stringMiddleware({ propName, value }: UseCaseMiddlewareProps) {
    return {
      props: { [propName]: value },
    };
  }

  private static textMiddleware({
    propName,
    value: text,
    element,
  }: UseCaseMiddlewareProps) {
    if (!text) {
      return {};
    }

    const state = EditorEngineManager.store.getState() as State;
    const elementState = state[element.uid] as ComponentState;
    const value = BaseUseCase.getUpdatedText(elementState.props.text, text);
    const tabletValue = elementState.tablet?.text
      ? BaseUseCase.getUpdatedText(elementState.tablet.text, text)
      : undefined;
    const mobileValue = elementState.mobile?.text
      ? BaseUseCase.getUpdatedText(elementState.mobile.text, text)
      : undefined;

    const payload = {
      props: { [propName]: value },
      tablet: tabletValue ? { [propName]: tabletValue } : {},
      mobile: mobileValue ? { [propName]: mobileValue } : {},
    };

    return payload;
  }

  private static textColorMiddleware({
    propName,
    value: color,
    element,
  }: UseCaseMiddlewareProps) {
    if (!color) {
      return {};
    }

    const state = EditorEngineManager.store.getState() as State;
    const elementState = state[element.uid] as ComponentState;
    const value = BaseUseCase.getUpdatedTextColor(
      elementState.props.text,
      color
    );
    const tabletValue = elementState.tablet?.text
      ? BaseUseCase.getUpdatedTextColor(elementState.tablet.text, color)
      : undefined;
    const mobileValue = elementState.mobile?.text
      ? BaseUseCase.getUpdatedTextColor(elementState.mobile.text, color)
      : undefined;

    const payload = {
      props: { [propName]: value },
      tablet: tabletValue ? { [propName]: tabletValue } : {},
      mobile: mobileValue ? { [propName]: mobileValue } : {},
    };

    return payload;
  }

  private static getUpdatedText(currentValue: string, newValue: string) {
    const div = document.createElement('div');
    div.style.display = 'none';
    document.body.appendChild(div);

    const quill = new Quill(div, {
      formats: [
        'background',
        'bold',
        'color',
        'font',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'header',
        'align',
        'lineHeight',
      ],
    });

    quill.root.innerHTML = currentValue;

    const { length } = quill.root.textContent as string;
    const matches = currentValue.match(/(<p|<br)/g);
    const lineBreakLength = matches
      ? currentValue.length - matches.length - 1
      : currentValue.length;

    quill.insertText(0, newValue);
    quill.deleteText(newValue.length, length + lineBreakLength);

    const text = quill.root.innerHTML;

    document.body.removeChild(div);

    return text;
  }

  private static getUpdatedTextColor(text: string, color: string) {
    const div = document.createElement('div');
    div.style.display = 'none';
    document.body.appendChild(div);

    const quill = new Quill(div, {
      formats: [
        'background',
        'bold',
        'color',
        'font',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'header',
        'align',
        'lineHeight',
      ],
    });

    quill.root.innerHTML = text;

    const { length } = quill.root.textContent as string;

    quill.formatText(0, length, { color });

    const updatedText = quill.root.innerHTML;

    document.body.removeChild(div);

    return updatedText;
  }
}
