import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PersonalizeColors } from '@pages/PageWizard/useCases/Sales/components/PersonalizeColors';
import { PresentProduct } from '@pages/PageWizard/useCases/Sales/components/PresentProduct';
import { PresentAuthor } from '@pages/PageWizard/useCases/Sales/components/PresentAuthor';
import { UpdateOffer } from '@pages/PageWizard/useCases/Sales/components/UpdateOffer';
import { DescribeProduct } from '@pages/PageWizard/useCases/Sales/components/DescribeProduct';
import { SelectTemplate } from '@pages/PageWizard/useCases/Sales/components/SelectTemplate';
import { Product } from './components/Product';
import { Offer } from './components/Offer';
import { SalesUseCase } from '../../salesUseCase';

export type SalesPaidWithProductProps = {
  currentStep: number;
  properties: SalesPageProperties;
  onPropertiesChange: (props: Partial<SalesPageProperties>) => void;
  onNextClick?: () => void;
};

const PaidWithProductComponent: React.FC<SalesPaidWithProductProps> = ({
  currentStep,
  properties,
  onPropertiesChange,
  onNextClick,
}) => {
  const useCaseInitialProps = useRef(properties);

  const [useCase, setUseCase] = useState<SalesUseCase>();

  const { colors, updatedProduct, product, author, updatedAuthor, template } =
    useMemo(() => properties, [properties]);

  const handleChange = useCallback(
    ({ prop, value }: UseCaseModifierChange) => {
      onPropertiesChange({ [prop]: value });
    },
    [onPropertiesChange]
  );

  const handleTemplateChange = useCallback(
    (props: SalesPageProperties) => {
      useCase?.replaceTemplate(props);

      onPropertiesChange(props);
    },
    [onPropertiesChange, useCase]
  );

  useEffect(() => {
    const instance = new SalesUseCase(useCaseInitialProps.current);

    setUseCase(instance);
  }, []);

  const steps = useMemo(
    () => [
      <SelectTemplate
        properties={properties}
        onTemplateChange={handleTemplateChange}
        onNextClick={onNextClick}
      />,
      <Product
        product={product}
        author={author}
        template={template}
        onModifierChange={handleChange}
      />,
      <Offer product={product} onModifierChange={handleChange} />,
      <PersonalizeColors
        colors={colors}
        useCase={useCase!}
        onModifierChange={handleChange}
      />,
      <PresentProduct
        useCase={useCase!}
        updatedProduct={updatedProduct}
        onModifierChange={handleChange}
        product={product}
      />,
      <DescribeProduct
        useCase={useCase!}
        updatedProduct={updatedProduct}
        onModifierChange={handleChange}
        product={product}
      />,
      <PresentAuthor
        useCase={useCase!}
        onModifierChange={handleChange}
        author={author}
        updatedAuthor={updatedAuthor}
      />,
      <UpdateOffer
        useCase={useCase!}
        product={product}
        updatedProduct={updatedProduct}
        onModifierChange={handleChange}
      />,
    ],
    [
      author,
      colors,
      handleChange,
      handleTemplateChange,
      onNextClick,
      product,
      properties,
      template,
      updatedAuthor,
      updatedProduct,
      useCase,
    ]
  );

  const renderedStep = useMemo(
    () => steps[currentStep] || steps[steps.length - 1],
    [currentStep, steps]
  );

  const rendered = useMemo(
    () => (!useCase ? null : renderedStep),
    [renderedStep, useCase]
  );

  return rendered;
};

export const PaidWithProduct = memo(PaidWithProductComponent);
