import styled, { css } from 'styled-components';

type StyledContainerProps = {
  hasProduct?: boolean;
};

export const Container = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ hasProduct }) => css`
    margin-bottom: ${hasProduct ? '24px' : '0px'};
  `}
`;
