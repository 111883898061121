import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { useUseCases } from '@hooks/useUseCases';
import Pages from '@services/pages';
import Files from '@services/files';
import { EditableName } from '@components/EditableName';
import { useUserInfo } from '@hooks/useUserInfo';
import { useDetectBrowser } from '@hooks/useDetectBrowser';
import { useApplication } from '@hooks/useApplication';
import { getLocaleLink } from '@i18n/index';
import Toast from '@services/toast';
import { addQueryParams, sleep } from '@common/utils';
import { USE_CASE_IFRAME_CONFIG } from '@common/constants';
import { rebrand } from '@config/rebrand';
import { FreeToPaidModalController } from '@components/FreeToPaidModalController';
import {
  HeaderWrapper,
  Container,
  ModifiersWrapper,
  PreviewWrapper,
  StepsMenuWrapper,
  TitleWrapper,
  Main,
  PreviewTitle,
} from './styles';
import { StepMenu } from './components/StepMenu';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Preview } from './components/Preview';
import { Modal } from './components/Modal';
import { UseCaseLoader } from './components/UseCaseLoader';
import { useCaseConfigByType } from './useCaseConfigByType';
import { LinkTree } from './useCases/LinkTree';
import { Capture } from './useCases/Capture';
import {
  PaidWithProduct,
  PaidWithoutProduct,
  HotmartJourneyWithProduct,
} from './useCases/Sales';
import { Thanks } from './useCases/Thanks';
import {
  redirectBySource,
  redirectToCaptureFunnel,
  redirectToSource,
} from './common';

export type PageWizardProps = HTMLAttributes<HTMLDivElement> & {
  type: UseCaseType;
};

export const PageWizard: React.FC<PageWizardProps> = ({ type, ...attrs }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [urlSearchParams] = useSearchParams();

  const { isFirefox } = useDetectBrowser();
  const { setAppLoading } = useApplication();
  const query = useRef(urlSearchParams);
  const {
    currentStep,
    properties,
    loadingAI,
    isLoading,
    maxStep: useCaseMaxStep,
    finishedInFirstAccess,
    getUseCase,
    getStepEvent,
    saveUseCase,
    saveProperties,
    saveCurrentStep,
    setLoading,
    setIsPublishing,
    setFinishedInFirstAccess,
  } = useUseCases();
  const { isExtensions } = useUserInfo();

  const [isModalOpen, setModalOpen] = useState(false);
  const [freeToPaidActivated, setFreeToPaidActivated] = useState(false);
  const [isModalFreeToPaidOpen, setModalFreeToPaidOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<UseCaseModalType>();
  const [itmParams, setItmParams] = useState<ItmParams>({
    itm_source: '',
    itm_medium: '',
    itm_campaign: '',
  });

  const [step, setStep] = useState(1);
  const [pageUrl, setPageUrl] = useState('');
  const [pageUuid, setPageUuid] = useState('');
  const [iframeConfig, setIframeConfig] = useState<UseCaseIFrameConfig>(
    USE_CASE_IFRAME_CONFIG
  );

  const hasProperties = useMemo(
    () => Boolean(properties && Object.keys(properties).length),
    [properties]
  );
  const useCaseId = useMemo(() => +(id as string), [id]);
  const localStep = useMemo(() => step - 1, [step]);
  const {
    getTemplateWithProps,
    getFontsConfig,
    getTemplate,
    getDefaultProperties,
    getTemplateBeforePublish,
    getPropertiesBeforeInit,
    steps,
    keepAppLoader = false,
    hideMenu = false,
    hidePageTitle = false,
    hideHeader: hideHeaderConfig = false,
    hideOnboarding = false,
    backButton = {},
  } = useMemo(() => useCaseConfigByType[type], [type]);
  const stepConfig = useMemo(
    () => steps[localStep] || steps[localStep - 1] || {},
    [localStep, steps]
  );
  const isSalesPage = useMemo(
    () =>
      [
        'sales_page_paid_with_product',
        'sales_page_paid_without_product',
        'hotmart_journey_sales_page_with_product',
      ].includes(type),
    [type]
  );

  const hideHeader = useMemo(
    () => hideHeaderConfig || !iframeConfig.header,
    [hideHeaderConfig, iframeConfig.header]
  );

  const hidePublishedModal = useMemo(
    () => Boolean(iframeConfig.modal?.hidePublishedModal),
    [iframeConfig.modal?.hidePublishedModal]
  );

  const isPostMessageBehavior = useMemo(
    () => iframeConfig.eventBehavior === 'post-message',
    [iframeConfig.eventBehavior]
  );

  const isHotmartJourney = useMemo(
    () => type === 'hotmart_journey_sales_page_with_product',
    [type]
  );

  const modalFixedSizeByType = useMemo<Record<UseCaseType, string>>(
    () => ({
      publish_lead_capture_page: '',
      publish_page: '',
      funnel_capture_publish_lead_capture_page: '',
      funnel_capture_thanks_page: '',
      sales_page_paid_with_product: '500px',
      sales_page_paid_without_product: '500px',
      hotmart_journey_sales_page_with_product: '',
      free_to_paid: '',
    }),
    []
  );

  const source = query.current.get('source');
  const channel = query.current.get('channel');

  const concatParams = useCallback(
    () => `${source}_${channel}_${type}`,
    [channel, source, type]
  );

  const displayModal = useCallback(
    (modalType: UseCaseModalType) => {
      setCurrentModal(modalType);
      if (modalType === 'singlePageLimit') {
        setModalFreeToPaidOpen(true);
        return;
      }
      setModalOpen(true);

      if (modalType === 'published' && isSalesPage) {
        const queryString = 'salespage=true';
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
      }

      const itmParamsByType: Record<string, string> = {
        publish_page: 'uc_captura_link',
        publish_lead_capture_page: 'uc_captura_link',
        sales_page_paid_with_product: 'uc_vendas',
        sales_page_paid_without_product: 'uc_vendas',
        hotmart_journey_sales_page_with_product: 'uc_vendas',
      };

      const itmSource = itmParamsByType[type];

      if (itmSource !== undefined && modalType === 'pageLimit') {
        setItmParams({
          itm_source: itmSource,
          itm_medium: 'modal_publicar_free',
          itm_campaign: 'assinar_novo_plano',
        });
      }
    },
    [isSalesPage, type]
  );

  const hideModal = useCallback(() => {
    setCurrentModal(undefined);
    setModalOpen(false);
  }, []);

  const redirectToSalesPage = useCallback(() => {
    const extensionsUrl = 'https://extensions.hotmart.com/salespage';
    const klickUrl = 'https://klickpages.com.br/monteseuplano';

    let url = klickUrl;

    if (isExtensions) {
      const extensionsSalesUrl = itmParams
        ? addQueryParams(extensionsUrl, itmParams)
        : extensionsUrl;
      url = extensionsSalesUrl;
    }

    window.open(url, '_blank');
  }, [isExtensions, itmParams]);

  const getIframeConfig = useCallback(() => {
    const promise = new Promise<UseCaseIFrameConfig>((resolve) => {
      let messageReceived = false;

      const listener = (
        event: MessageEvent<{ USE_CASE_IFRAME_CONFIG: UseCaseIFrameConfig }>
      ) => {
        const hotmartDomain = /^https?:\/\/([a-z0-9-]+\.)*hotmart\.com(:\d+)?$/;
        const buildstagingDomain =
          /^https?:\/\/([a-z0-9-]+\.)*buildstaging\.com(:\d+)?$/;

        const isHotmartDomain = hotmartDomain.test(event.origin);
        const isBuildstagingDomain = buildstagingDomain.test(event.origin);

        if (
          (isHotmartDomain || isBuildstagingDomain) &&
          event.data?.USE_CASE_IFRAME_CONFIG
        ) {
          messageReceived = true;

          resolve({
            ...USE_CASE_IFRAME_CONFIG,
            ...event.data.USE_CASE_IFRAME_CONFIG,
          });
        }
      };

      window.addEventListener('message', listener);
      window.parent.postMessage('GET_IFRAME_CONFIG', '*');

      sleep(200).then(() => {
        if (!messageReceived) {
          resolve(USE_CASE_IFRAME_CONFIG);
        }
        window.removeEventListener('message', listener);
      });
    });

    return promise;
  }, []);

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const useCase = await getUseCase(useCaseId);
      const { properties: props, currentStep: caseStep, maxStep } = useCase;

      const defaultProps = getDefaultProperties();
      const isStarting = caseStep === 0;
      const savedStep = isStarting ? 1 : caseStep;

      if (maxStep === caseStep) {
        redirectToSource(source);
        return;
      }

      setStep(savedStep);
      setFinishedInFirstAccess(isStarting);

      useCase.properties = { ...defaultProps, ...props };

      if (getPropertiesBeforeInit) {
        useCase.properties = await getPropertiesBeforeInit(useCase);
      }

      if (isStarting && (source || channel)) {
        useCase.properties.createdOrigin = {
          source: source || '',
          channel: channel || '',
        };
      }

      await saveUseCase(useCaseId, useCase.properties, savedStep);

      const iframeConfigValue = await getIframeConfig();

      if (isStarting && !hideOnboarding) {
        const itemKey = `use_case_onboarded_${type}`;
        const useCaseOnboarded = Boolean(localStorage.getItem(itemKey));
        const showOnboardingModal =
          !useCaseOnboarded || !iframeConfigValue.modal?.hideOnboardingModal;

        if (showOnboardingModal) {
          displayModal('onboarding');
          localStorage.setItem(itemKey, 'true');
        }
      }

      if (!keepAppLoader) {
        window.parent?.postMessage(
          { eventName: 'use-case-loaded', data: true },
          '*'
        );
      }
      setIframeConfig(iframeConfigValue);
    } catch (e) {
      redirectToSource(source);
    } finally {
      setLoading(false);
      setAppLoading(keepAppLoader);
    }
  }, [
    setLoading,
    getUseCase,
    useCaseId,
    getDefaultProperties,
    setFinishedInFirstAccess,
    getPropertiesBeforeInit,
    source,
    channel,
    saveUseCase,
    hideOnboarding,
    getIframeConfig,
    type,
    displayModal,
    setAppLoading,
    keepAppLoader,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  const handlePropertiesChange = useCallback(
    (props: any) => {
      saveProperties(useCaseId, props);
    },
    [saveProperties, useCaseId]
  );

  const handleNameChange = useCallback(
    (pageName: string) => {
      handlePropertiesChange({ ...properties, pageName });
    },
    [handlePropertiesChange, properties]
  );

  const handlePreviousClick = useCallback(async () => {
    if (step > 1) {
      const previousStep = step - 1;
      setStep(previousStep);

      const configStep = localStep - 1;

      if (steps[configStep]?.saveCurrentWhenGoBack) {
        await saveCurrentStep(useCaseId, previousStep);
      }

      return;
    }

    if (isPostMessageBehavior) {
      window.parent?.postMessage({ eventName: 'use-case-close-modal' }, '*');
      return;
    }

    if (type.startsWith('funnel')) {
      redirectToCaptureFunnel();
      return;
    }

    redirectToSource(source);
  }, [
    isPostMessageBehavior,
    localStep,
    saveCurrentStep,
    source,
    step,
    steps,
    type,
    useCaseId,
  ]);

  const handleNextClick = useCallback(async () => {
    const { beforeNextStep } = stepConfig.events || {};

    if (beforeNextStep) {
      const eventCallback = getStepEvent(type, beforeNextStep);

      if (eventCallback) {
        try {
          await eventCallback();
        } catch (error) {
          Toast.error('toastMessages.general.error.generic');
          return;
        }
      }
    }

    const stepToSave = step + 1;
    setStep(stepToSave);

    if (stepToSave !== currentStep) {
      if (stepToSave === useCaseMaxStep && (source || channel)) {
        await saveProperties(useCaseId, {
          finishedInFirstAccess,
          finishedOrigin: {
            source: source || '',
            channel: channel || '',
          },
        });
      }

      await saveCurrentStep(useCaseId, stepToSave);
    }
  }, [
    channel,
    currentStep,
    finishedInFirstAccess,
    getStepEvent,
    saveCurrentStep,
    saveProperties,
    source,
    step,
    stepConfig.events,
    type,
    useCaseId,
    useCaseMaxStep,
  ]);

  const publishPage = useCallback(async () => {
    const pageContent: Template =
      (await getTemplateBeforePublish?.(getTemplate(), properties)) ||
      getTemplateWithProps(getTemplate(), properties);

    const { pageName } = properties;
    const { uuid, domain, path } = await Pages.createPage({ name: pageName });
    const [config] = Files.normalizePageConfig();

    config.title = pageName;

    await Files.createFiles(uuid, [
      { name: 'fontsConfig', content: getFontsConfig() },
      {
        name: 'page',
        content: pageContent,
      },
      { name: 'config', content: config },
    ]);
    await Pages.publishPage(uuid, { domain, path, mode: 'page' }, []);
    handleNextClick();

    if (isPostMessageBehavior) {
      const publishedData: UseCasePostMessageData = {
        domain,
        useCaseId,
        editorDomain: rebrand.urls.EDITOR_URL as string,
        pageId: uuid,
        pagePath: path,
      };

      window.parent?.postMessage(
        { eventName: 'use-case-published-data', data: publishedData },
        '*'
      );
    }

    setPageUrl(`//${domain}/${path}`);
    setPageUuid(uuid);
  }, [
    getFontsConfig,
    getTemplate,
    getTemplateBeforePublish,
    getTemplateWithProps,
    handleNextClick,
    isPostMessageBehavior,
    properties,
    useCaseId,
  ]);

  const updatePage = useCallback(
    async (pageContent: Template, pageId: string, pageName: string) => {
      await Files.savePageFile(pageId, pageContent);
      await Pages.updatePage(pageId, { name: pageName });
    },
    []
  );

  const createPage = useCallback(
    async (pageName: string, pageContent: Template) => {
      const { uuid } = await Pages.createPage({
        name: pageName,
        metadata: {
          createdByFunnel: type.startsWith('funnel'),
          origin: 'usecase',
          useCaseType: type,
        },
      });
      const [config] = Files.normalizePageConfig();

      config.title = pageName;

      await Files.createFiles(uuid, [
        { name: 'fontsConfig', content: getFontsConfig() },
        {
          name: 'page',
          content: pageContent,
        },
        { name: 'config', content: config },
      ]);

      return uuid;
    },
    [getFontsConfig, type]
  );

  const savePage = useCallback(async () => {
    const pageContent: Template | undefined =
      (await getTemplateBeforePublish?.(getTemplate(), properties)) ||
      getTemplateWithProps(getTemplate(), properties);

    const { pageName, pageId } = properties;

    // @todo create corresponding thunk in refactoring
    if (pageContent) {
      if (pageId) {
        await updatePage(pageContent, properties.pageId, pageName);
      } else {
        const uuid = await createPage(pageName, pageContent);
        handlePropertiesChange({ ...properties, pageId: uuid });
      }
    }
  }, [
    createPage,
    getTemplate,
    getTemplateBeforePublish,
    getTemplateWithProps,
    handlePropertiesChange,
    properties,
    updatePage,
  ]);

  const deleteSinglePageAndPublish = useCallback(async () => {
    hideModal();
    setIsPublishing(true);
    try {
      const pages = await Pages.getPages();
      await Pages.deletePage(pages[0].uuid);
      await publishPage();
      displayModal('published');
    } catch (e) {
      redirectToSource(source);
    } finally {
      setIsPublishing(false);
    }
  }, [displayModal, hideModal, publishPage, setIsPublishing, source]);

  const handleCloseModal = useCallback(() => {
    if (currentModal !== 'onboarding') {
      if (isPostMessageBehavior) {
        window.parent?.postMessage({ eventName: 'use-case-finished' }, '*');
        return;
      }
    }

    if (currentModal === 'published') {
      if (freeToPaidActivated) {
        window.open(`${rebrand.urls.PAGE_MANAGER_URL}?trial=started`, '_self');
        return;
      }

      redirectBySource.pages(type, true);
      return;
    }

    if (currentModal === 'pageLimit' || currentModal === 'singlePageLimit') {
      redirectToSource(source);
    }
  }, [currentModal, freeToPaidActivated, isPostMessageBehavior, source, type]);

  const handleModalPrimaryButtonClick = useCallback(() => {
    if (currentModal !== 'onboarding' && currentModal !== 'published') {
      redirectToSalesPage();
      return;
    }

    if (currentModal === 'onboarding') {
      hideModal();
      return;
    }

    window.open(pageUrl, '_blank');
  }, [currentModal, hideModal, pageUrl, redirectToSalesPage]);

  const handleModalSecondaryButtonClick = useCallback(async () => {
    if (currentModal === 'singlePageLimit') {
      await deleteSinglePageAndPublish();
      return;
    }
    const origin = source;

    if (currentModal === 'onboarding') {
      hideModal();
      redirectToSource(origin);
      return;
    }

    const shouldRedirectWithParam = currentModal === 'published';

    if (isPostMessageBehavior) {
      window.parent?.postMessage({ eventName: 'use-case-finished' }, '*');
    }

    const target = isPostMessageBehavior ? '_blank' : '_self';

    if (type === 'publish_lead_capture_page' && origin === 'send') {
      redirectBySource.send(type, shouldRedirectWithParam, target);
      return;
    }

    if (isSalesPage) {
      window.open(getLocaleLink(`/${pageUuid}?salespage=true`), target);
      return;
    }

    redirectBySource.pages(type, shouldRedirectWithParam, target);
  }, [
    currentModal,
    deleteSinglePageAndPublish,
    hideModal,
    isPostMessageBehavior,
    isSalesPage,
    pageUuid,
    source,
    type,
  ]);

  const isUserLimited = useCallback(async () => {
    const { canCreatePage, hasSinglePage } = await Pages.validateUserLimits();

    if (!canCreatePage) {
      if (hasSinglePage) {
        displayModal('singlePageLimit');
        return true;
      }

      displayModal('pageLimit');
      return true;
    }
    return false;
  }, [displayModal]);

  const handleSave = useCallback(async () => {
    try {
      setIsPublishing(true);

      if (!type.startsWith('funnel')) {
        const userLimited = await isUserLimited();
        if (userLimited) {
          return;
        }
      }

      await savePage();
      if (type.startsWith('funnel')) {
        redirectToCaptureFunnel();
      }
    } catch (e) {
      Toast.error('toastMessages.general.error.generic');
    } finally {
      setIsPublishing(false);
    }
  }, [isUserLimited, savePage, setIsPublishing, type]);

  const handlePublish = useCallback(async () => {
    try {
      setIsPublishing(true);
      const userLimited = await isUserLimited();
      if (userLimited) {
        return;
      }

      await publishPage();

      if (hidePublishedModal) {
        return;
      }

      displayModal('published');
    } catch (e) {
      redirectToSource(source);
    } finally {
      setIsPublishing(false);
    }
  }, [
    displayModal,
    hidePublishedModal,
    isUserLimited,
    publishPage,
    setIsPublishing,
    source,
  ]);

  const handleTrialActivated = useCallback(() => {
    setFreeToPaidActivated(true);
    handlePublish();
    setModalFreeToPaidOpen(false);
  }, [handlePublish]);

  const handleTrialCloseModal = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  return (
    <Container {...attrs}>
      {!hideHeader && (
        <HeaderWrapper>
          <Header type={type} />
        </HeaderWrapper>
      )}

      {!isLoading && hasProperties && !hidePageTitle && (
        <TitleWrapper>
          <EditableName
            fontSize={28}
            tooltipText={t('header.edit')}
            name={properties.pageName}
            onNameChanged={(name) => handleNameChange(name)}
          />
        </TitleWrapper>
      )}

      {loadingAI && (
        <UseCaseLoader
          fixed
          title={t('pageWizard.salesPage.ai.loader.title')}
          subtitle={t('pageWizard.salesPage.ai.loader.subtitle')}
        />
      )}

      <Main
        hidePreview={Boolean(stepConfig?.hidePreview)}
        previewDevice={stepConfig?.previewDevice}
        hideMenu={hideMenu}
        hidePageTitle={hidePageTitle}
      >
        {!hideMenu && (
          <StepsMenuWrapper>
            <StepMenu currentStep={localStep} stepsConfig={steps} />
          </StepsMenuWrapper>
        )}

        <ModifiersWrapper>
          {!isLoading && hasProperties && type === 'publish_page' && (
            <LinkTree
              properties={properties}
              currentStep={localStep}
              onPropertiesChange={handlePropertiesChange}
            />
          )}

          {!isLoading &&
            hasProperties &&
            (type === 'publish_lead_capture_page' ||
              type === 'funnel_capture_publish_lead_capture_page') && (
              <Capture
                properties={properties}
                currentStep={localStep}
                type={type}
                onPropertiesChange={handlePropertiesChange}
              />
            )}

          {!isLoading &&
            hasProperties &&
            type === 'sales_page_paid_with_product' && (
              <PaidWithProduct
                properties={properties}
                currentStep={localStep}
                onPropertiesChange={handlePropertiesChange}
                onNextClick={handleNextClick}
              />
            )}

          {!isLoading &&
            hasProperties &&
            type === 'sales_page_paid_without_product' && (
              <PaidWithoutProduct
                properties={properties}
                currentStep={localStep}
                onPropertiesChange={handlePropertiesChange}
                onNextClick={handleNextClick}
              />
            )}

          {!isLoading &&
            hasProperties &&
            type === 'funnel_capture_thanks_page' && (
              <Thanks
                properties={properties}
                currentStep={localStep}
                onPropertiesChange={handlePropertiesChange}
              />
            )}

          {!isLoading && hasProperties && isHotmartJourney && (
            <HotmartJourneyWithProduct
              properties={properties}
              currentStep={localStep}
              isPostMessageBehavior={isPostMessageBehavior}
              onPropertiesChange={handlePropertiesChange}
              onBackClick={handlePreviousClick}
            />
          )}
        </ModifiersWrapper>

        {!isLoading && hasProperties && !stepConfig?.hidePreview && (
          <PreviewWrapper
            isFirefox={isFirefox}
            previewDevice={stepConfig?.previewDevice}
          >
            {isPostMessageBehavior && (
              <PreviewTitle>
                {t(
                  'pageWizard.salesPage.hotmartJourneySalesPageWithProduct.previewTitle'
                )}
              </PreviewTitle>
            )}
            <Preview
              template={getTemplate()}
              previewDevice={stepConfig?.previewDevice}
              getTemplateWithProps={getTemplateWithProps}
              scrollPreviewTo={stepConfig.scrollPreviewTo}
              shouldSetPage={
                !type.startsWith('sales') && !type.startsWith('hotmart')
              }
            />
          </PreviewWrapper>
        )}
      </Main>

      {!isLoading && localStep < steps.length && (
        <Footer
          stepsAmount={steps.length}
          currentStep={localStep}
          backButtonConfig={{
            ...backButton,
            ...iframeConfig.backButton,
          }}
          nextButtonConfig={iframeConfig.nextButton}
          onPreviousClick={handlePreviousClick}
          onNextClick={handleNextClick}
          onPublishPage={handlePublish}
          onSavePage={handleSave}
          progress={iframeConfig.progress}
          type={type}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        type={type}
        pageUrl={pageUrl}
        fixedSize={modalFixedSizeByType[type]}
        currentModal={currentModal}
        onClose={handleCloseModal}
        onPrimaryButtonClicked={() => handleModalPrimaryButtonClick()}
        onSecondaryButtonClicked={() => handleModalSecondaryButtonClick()}
      />
      <FreeToPaidModalController
        origin={concatParams()}
        isOpen={isModalFreeToPaidOpen}
        onTrialActivated={handleTrialActivated}
        onClose={() => handleTrialCloseModal()}
      />
    </Container>
  );
};
