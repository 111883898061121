import { v4 as uuid } from 'uuid';
import i18next from 'i18next';

const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

const guaranteeBadgeByLanguage = {
  regular: {
    en: '//static-public.pages.hotmart.com/uploads/media/file/7205594/badge_garantia_en.png',
    es: '//static-public.pages.hotmart.com/uploads/media/file/7205595/badge_garantia_es.png',
    'pt-BR':
      '//static-public.pages.hotmart.com/uploads/media/file/6807740/badge_garantia.png',
  },
  thumb: {
    en: '//static-public.pages.hotmart.com/uploads/media/file/7205594/thumb_badge_garantia_en.png',
    es: '//static-public.pages.hotmart.com/uploads/media/file/7205595/thumb_badge_garantia_es.png',
    'pt-BR':
      '//static-public.pages.hotmart.com/uploads/media/file/6807740/thumb_badge_garantia.png',
  },
};

export const getDarkPrimaryTemplate = () => {
  const maxInstallments = i18next.t(
    'pageWizard.salesPage.template.offer.installments',
    {
      maxInstallments: '1',
    }
  );

  return {
    background: 'none',
    'background-is-webp': false,
    padding: '0px 0px 0px 0px',
    desktop: {},
    tablet: {},
    mobile: {},
    uid: `ls-${uuid()}`,
    component: 'ls-page',
    children: [
      {
        background: '#121212',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-25T13:36:15',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '80px 0px 80px 0px',
        desktop: {},
        tablet: {
          background: '#000000',
          padding: '32px 0px 0px 0px',
        },
        mobile: {
          padding: '35px 0px 0px 0px',
        },
        uid: `ls-${uuid()}-title`,
        component: 'ls-section',
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'transparent',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '24px 50px 48px 50px',
                },
                mobile: {
                  padding: '0px 0px 48px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: '<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 50px;">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT EIUSMOD.</strong></p>',
                    'max-width': '100%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1.15; text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 56px;">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT EIUSMOD.</strong></p>',
                      'max-width': '100%',
                    },
                    mobile: {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 24px;">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING</strong></p><p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 24px;">ELIT EIUSMOD.</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productTitle: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    text: '<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                    'max-width': '90%',
                    padding: '16px 10px 16px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '90%',
                      text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                    },
                    mobile: {
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 12px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                      'max-width': '90%',
                      padding: '16px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productSubtitle: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    action: 'link',
                    color: '#FFFFFF',
                    background: '#38D001',
                    'border-color': '#EF4E23',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    'button-class': '',
                    desktop: {},
                    'end-date': '2023-05-26T13:48:23',
                    timezone: '-03:00_utcMinus0300MainCities',
                    'time-on-page': '5',
                    'time-on-page-unit': 'seconds',
                    'delay-type': 'disabled',
                    'font-family': 'Oswald, sans-serif',
                    'font-size': '20px',
                    'hover-background': 'none',
                    'hover-color': 'none',
                    'has-icon': false,
                    'has-text': true,
                    href: '',
                    'icon-position': 'right',
                    icon: 'fas fa-shopping-cart',
                    'is-bold': true,
                    'is-italic': false,
                    'is-underline': false,
                    'is-strike': false,
                    label: i18next.t('pageWizard.salesPage.template.CTA'),
                    margin: '32px 10px 16px 10px',
                    'max-width': '60%',
                    mobile: {
                      'max-width': '70%',
                      'font-size': '18px',
                      margin: '20px 0px 0px 0px',
                      padding: '12px 32px 12px 32px',
                    },
                    padding: '15px 32px 16px 32px',
                    tablet: {
                      margin: '32px 0px 20px 0px',
                      'max-width': '60%',
                      'font-size': '20px',
                    },
                    target: '_blank',
                    uid: `ls-${uuid()}`,
                    component: 'ls-button',
                    metadata: {
                      useCase: {
                        offerLink: {
                          propName: 'href',
                          middleware: 'string',
                        },
                        purchaseButton: {
                          propName: 'label',
                          middleware: 'string',
                        },
                        secondaryColor: {
                          propName: 'background',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
              {
                padding: '48px 0px 48px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: '#FFFFFF',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '56px 0px 56px 0px',
                  background: '#FFFFFF',
                },
                mobile: {
                  padding: '38px 0px 38px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                metadata: {
                  useCase: {
                    secondaryColor: {
                      propName: 'background',
                      middleware: 'string',
                    },
                  },
                },
                children: [
                  {
                    alt: '',
                    'border-color': '#E186BC',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    height: '500px',
                    href: '',
                    'is-webp': true,
                    'max-width': '500px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'cover',
                    target: '_blank',
                    desktop: {},
                    tablet: {
                      'max-width': '600px',
                      height: '400px',
                    },
                    mobile: {
                      'max-width': '300px',
                      height: '300px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/7176922/group_10288.png',
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/7176922/thumb_group_10288.png',
                    metadata: {
                      useCase: {
                        productImage: {
                          propName: 'src',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
              },
            ],
            metadata: {},
          },
        ],
        metadata: {
          label: 'Seção 1',
        },
      },
      {
        background: '#0C177B',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '140px 0px 140px 0px',
        desktop: {},
        tablet: {
          background: '#0C177B',
          padding: '140px 0px 120px 0px',
        },
        mobile: {
          background: '#0C177B',
          padding: '80px 0px 70px 0px',
        },
        uid: `ls-${uuid()}-about-content`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 2',
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '0px 0px 40px 0px',
                },
                mobile: {},
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    'border-color': '#00000000',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'solid',
                    'border-top-width': '4px',
                    'border-right-width': '4px',
                    'border-bottom-width': '4px',
                    'border-left-width': '4px',
                    height: '309px',
                    margin: '0px 0px 0px 0px',
                    'max-width': '550px',
                    src: 'https://www.youtube.com/embed/wYHO0Wdqw7g',
                    'thumb-url': '',
                    desktop: {},
                    tablet: {
                      'border-style': 'solid',
                    },
                    mobile: {
                      'max-width': '300px',
                      height: '169px',
                      'border-style': 'solid',
                      'border-top-width': '7px',
                      'border-right-width': '7px',
                      'border-bottom-width': '7px',
                      'border-left-width': '7px',
                      margin: '0px 0px 0px 0px',
                      'border-radius': '0px 0px 0px 0px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-video',
                    metadata: {
                      salesVideo: true,
                      useCase: {
                        videoSrc: {
                          propName: 'src',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {
                  useCase: {
                    hasVideo: {
                      structureMiddleware: 'hasVideo',
                    },
                  },
                },
              },
              {
                padding: '0px 0px 0px 40px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '0px 0px 0px 0px',
                },
                mobile: {
                  padding: '0px 30px 0px 30px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 70px;">${i18next.t(
                      'pageWizard.salesPage.template.aboutProduct'
                    )}</strong></p>`,
                    'max-width': '80%',
                    padding: '0px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '10px 10px 10px 10px',
                      text: `<p style="line-height: 1.15; text-align: center;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 64px;">${i18next.t(
                        'pageWizard.salesPage.template.aboutProduct'
                      )}</strong></p>`,
                      'max-width': '100%',
                    },
                    mobile: {
                      text: `<p style="line-height: 1.15;"><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 32px;">${i18next.t(
                        'pageWizard.salesPage.template.aboutProduct'
                      )}</strong></p>`,
                      'max-width': '100%',
                      padding: '0px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p><p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                    'max-width': '90%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '80%',
                      padding: '32px 10px 10px 10px',
                      text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                    },
                    mobile: {
                      text: '<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p><p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                      'max-width': '100%',
                      padding: '24px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productDescription: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#121212',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '100px 0px 160px 0px',
        desktop: {},
        tablet: {
          background: '#000000',
          padding: '100px 0px 72px 0px',
        },
        mobile: {
          background: '#000000',
          padding: '56px 0px 80px 0px',
        },
        uid: `ls-${uuid()}-about-author`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 3',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '24px 72px 40px 72px',
                },
                mobile: {
                  padding: '16px 20px 48px 20px',
                  'align-items': 'start',
                  'justify-content': 'center',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p><strong style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">${i18next.t(
                      'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
                    )} ${i18next.t(
                      'pageWizard.salesPage.template.aboutAuthor.created'
                    )}</strong></p>`,
                    'max-width': '100%',
                    padding: '0px 10px 00px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p><span style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 16px;">${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
                      )} ${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.created'
                      )}</span></p>`,
                      'max-width': '90%',
                      padding: '0px 10px 0px 10px',
                    },
                    mobile: {
                      text: `<p><span style="background-color: rgba(0, 0, 0, 0); color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 11px;">${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
                      )} ${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.created'
                      )}</span></p>`,
                      'max-width': '90%',
                      padding: '0px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 56px;">${i18next
                      .t('pageWizard.salesPage.template.aboutAuthor.name')
                      .toUpperCase()}</strong></p>`,
                    'max-width': '100%',
                    padding: '10px 10px 032px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 64px;">${i18next
                        .t('pageWizard.salesPage.template.aboutAuthor.name')
                        .toUpperCase()}</strong></p>`,
                      padding: '16px 10px 32px 10px',
                    },
                    mobile: {
                      'max-width': '100%',
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 32px;">${i18next
                        .t('pageWizard.salesPage.template.aboutAuthor.name')
                        .toUpperCase()}</strong></p>`,
                      padding: '10px 10px 12px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        authorName: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    text: '<p style="line-height: 2;"><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: Tahoma, Geneva, sans-serif;">Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p><p style="line-height: 2;"><br></p><p style="line-height: 2;"><span style="color: rgb(255, 255, 255); font-size: 18px; font-family: Tahoma, Geneva, sans-serif;"><span class="ql-cursor"></span>Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p>',
                    'max-width': '100%',
                    padding: '16px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '100%',
                    },
                    mobile: {
                      text: '<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 13px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 13px; font-family: Tahoma, Geneva, sans-serif;">Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        authorDescription: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
              {
                padding: '64px 0px 0px 0px',
                'justify-content': 'end',
                'align-items': 'end',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '24px 0px 40px 0px',
                  'align-items': 'center',
                  'justify-content': 'center',
                },
                mobile: {
                  padding: '0px 0px 0px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '10px 10px 10px 10px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'center',
                    'flex-direction': 'column',
                    background: '#FFFFFF',
                    'background-is-webp': true,
                    'max-width': '500px',
                    margin: '0px 0px 0px 0px',
                    desktop: {},
                    tablet: {
                      'max-width': '460px',
                    },
                    mobile: {
                      'max-width': '312px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        alt: '',
                        'border-color': '#00000000',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'solid',
                        'border-top-width': '5px',
                        'border-right-width': '5px',
                        'border-bottom-width': '5px',
                        'border-left-width': '5px',
                        height: '500px',
                        href: '',
                        'is-webp': true,
                        'max-width': '500px',
                        margin: '0px 0px 0px 0px',
                        'object-fit': 'cover',
                        target: '_blank',
                        desktop: {},
                        tablet: {
                          'max-width': '450px',
                          height: '450px',
                        },
                        mobile: {
                          'max-width': '300px',
                          height: '300px',
                          'border-top-width': '5px',
                          'border-right-width': '5px',
                          'border-bottom-width': '5px',
                          'border-left-width': '5px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-image',
                        src: '//static-public.pages.hotmart.com/uploads/media/file/7176922/group_10288.png',
                        'thumb-src':
                          '//static-public.pages.hotmart.com/uploads/media/file/7176922/thumb_group_10288.png',
                        metadata: {
                          useCase: {
                            authorImage: {
                              propName: 'src',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                    ],
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#0C177B',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 4',
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '88px 0px 88px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'row',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '80px 0px 90px 0px',
                },
                mobile: {
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '54px 0px 64px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15; text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 32px;">${i18next.t(
                      'pageWizard.salesPage.template.offerEnding'
                    )}</strong></p>`,
                    'max-width': '100%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '10px 10px 10px 10px',
                      'max-width': '90%',
                      text: `<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 28px;">${i18next.t(
                        'pageWizard.salesPage.template.offerEnding'
                      )}</strong></p>`,
                    },
                    mobile: {
                      text: `<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;">${i18next.t(
                        'pageWizard.salesPage.template.offerEnding'
                      )}</strong></p>`,
                      padding: '10px 10px 10px 10px',
                      'max-width': '95%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    action: 'link',
                    color: '#FFFFFF',
                    background: '#38D001',
                    'border-color': '#EF4E23',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    'button-class': '',
                    desktop: {},
                    'end-date': '2023-05-26T13:48:23',
                    timezone: '-03:00_utcMinus0300MainCities',
                    'time-on-page': '5',
                    'time-on-page-unit': 'seconds',
                    'delay-type': 'disabled',
                    'font-family': 'Oswald, sans-serif',
                    'font-size': '20px',
                    'hover-background': 'none',
                    'hover-color': 'none',
                    'has-icon': false,
                    'has-text': true,
                    href: '',
                    'icon-position': 'right',
                    icon: 'fas fa-shopping-cart',
                    'is-bold': true,
                    'is-italic': false,
                    'is-underline': false,
                    'is-strike': false,
                    label: i18next.t('pageWizard.salesPage.template.CTA'),
                    margin: '24px 10px 16px 10px',
                    'max-width': '40%',
                    mobile: {
                      'max-width': '70%',
                      'font-size': '18px',
                      margin: '16px 0px 0px 0px',
                      padding: '12px 32px 12px 32px',
                    },
                    padding: '15px 32px 16px 32px',
                    tablet: {
                      margin: '32px 0px 0px 0px',
                      'max-width': '60%',
                      'font-size': '20px',
                    },
                    target: '_blank',
                    uid: `ls-${uuid()}`,
                    component: 'ls-button',
                    metadata: {
                      useCase: {
                        offerLink: {
                          propName: 'href',
                          middleware: 'string',
                        },
                        purchaseButton: {
                          propName: 'label',
                          middleware: 'string',
                        },
                        secondaryColor: {
                          propName: 'background',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#121212',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '140px 0px 140px 0px',
        desktop: {},
        tablet: {
          padding: '130px 0px 120px 0px',
        },
        mobile: {
          padding: '90px 0px 95px 0px',
        },
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'GARANTIA',
          templateId: '54974b4d-ccf7-4208-a5a2-a9729e4fb78b',
          templateName: 'GARANTIA',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 90px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '0px 0px 0px 0px',
                },
                mobile: {},
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    alt: '',
                    'border-color': '#5981e3',
                    'border-radius': '4px 4px 4px 4px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    height: '400px',
                    href: '',
                    'is-webp': false,
                    'max-width': '400px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'contain',
                    target: '_blank',
                    desktop: {},
                    tablet: {
                      'max-width': '300px',
                      height: '300px',
                    },
                    mobile: {
                      'max-width': '250px',
                      height: '250px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: guaranteeBadgeByLanguage.regular[
                      i18next.language as 'en' | 'es' | 'pt-BR'
                    ],
                    'thumb-src':
                      guaranteeBadgeByLanguage.thumb[
                        i18next.language as 'en' | 'es' | 'pt-BR'
                      ],
                    metadata: {},
                  },
                ],
                metadata: {},
              },
              {
                padding: '50px 0px 50px 30px',
                'justify-content': 'start',
                'align-items': 'center',
                'flex-direction': 'row',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '32px 0px 0px 0px',
                },
                mobile: {
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '24px 16px 0px 16px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 64px;">${i18next
                      .t('pageWizard.salesPage.template.guarantee.title', {
                        interpolation: { escapeValue: false },
                      })
                      .toUpperCase()}</strong></p>`,
                    'max-width': '400px',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="line-height: 1.15; text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 80px;">${i18next
                        .t('pageWizard.salesPage.template.guarantee.title', {
                          interpolation: { escapeValue: false },
                        })
                        .toUpperCase()}</strong></p>`,
                    },
                    mobile: {
                      'max-width': '300px',
                      text: `<p style="line-height: 1.15; text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 48px;">${i18next
                        .t('pageWizard.salesPage.template.guarantee.title', {
                          interpolation: { escapeValue: false },
                        })
                        .toUpperCase()}</strong></p>`,
                      padding: '10px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 1;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px; background-color: rgba(0, 0, 0, 0);">_______________________________________________</span></p>',
                    'max-width': '100%',
                    padding: '20px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1; text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">_________________________________________________________</span></p>',
                      padding: '22px 10px 10px 10px',
                    },
                    mobile: {
                      text: '<p style="line-height: 1; text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">____________________________________</span></p>',
                      padding: '8px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: `<p><span style="color:#fff;font-family:Tahoma,Geneva,sans-serif;font-size:16px;background-color:rgba(0,0,0,0)">${i18next.t(
                      'pageWizard.salesPage.template.guarantee.description.noQuestions',
                      { interpolation: { escapeValue: false } }
                    )} </span><span style="color:#fff;font-family:Tahoma,Geneva,sans-serif;font-size:16px;background-color:rgba(0,0,0,0)">${i18next.t(
                      'pageWizard.salesPage.template.guarantee.description.time'
                    )}</span></p>`,
                    'max-width': '55%',
                    padding: '30px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="text-align:center"><span style="color:#fff;font-family:Tahoma,Geneva,sans-serif;font-size:20px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.noQuestions',
                        { interpolation: { escapeValue: false } }
                      )} </span><span style="color:#fff;font-family:Tahoma,Geneva,sans-serif;font-size:20px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.time'
                      )}</span></p>`,
                      'max-width': '48%',
                    },
                    mobile: {
                      'max-width': '90%',
                      text: `<p style="text-align:center"><span style="color:#fff;font-family:Tahoma,Geneva,sans-serif;font-size:16px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.noQuestions',
                        { interpolation: { escapeValue: false } }
                      )} </span><span style="color:#fff;font-family:Tahoma,Geneva,sans-serif;font-size:16px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.time'
                      )}</span></p>`,
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#0C177B',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '150px 0px 150px 0px',
        desktop: {},
        tablet: {
          padding: '130px 0px 130px 0px',
        },
        mobile: {
          padding: '72px 0px 72px 0px',
        },
        uid: `ls-${uuid()}-offer`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 5',
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: '#121212',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'end',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'end',
                },
                mobile: {},
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '118px 24px 48px 40px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'start',
                    'align-items': 'end',
                    'flex-direction': 'row',
                    background: 'none',
                    'background-is-webp': true,
                    'max-width': '100%',
                    margin: '10px 00px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '50px 40px 30px 40px',
                      'align-items': 'center',
                      'justify-content': 'start',
                      margin: '10px 10px 0px 10px',
                      'max-width': '80%',
                    },
                    mobile: {
                      'max-width': '90%',
                      padding: '48px 16px 32px 16px',
                      'align-items': 'center',
                      'justify-content': 'start',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        text: `<p style="line-height:1.15"><strong style="font-family:Oswald,sans-serif;color:#fff;font-size:40px">${i18next.t(
                          'pageWizard.salesPage.template.offer.anyDevice'
                        )}</strong></p>`,
                        'max-width': '100%',
                        padding: '10px 0px 10px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '90%',
                          text: `<p><strong style="color:#fff;font-family:Oswald,sans-serif;font-size:40px">${i18next.t(
                            'pageWizard.salesPage.template.offer.anyDevice'
                          )}</strong></p>`,
                          padding: '10px 10px 0px 10px',
                        },
                        mobile: {
                          text: `<p style="line-height:1.15"><strong style="color:#fff;font-family:Oswald,sans-serif;font-size:24px">${i18next.t(
                            'pageWizard.salesPage.template.offer.anyDevice'
                          )}</strong></p>`,
                          padding: '10px 10px 0px 10px',
                          'max-width': '100%',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        text: '<p style="line-height: 1;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;">____________________________________________________________</strong></p>',
                        'max-width': '100%',
                        padding: '0px 0px 20px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '100%',
                          text: '<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 18px;">__________________________________________________</span></p>',
                          padding: '0px 10px 32px 10px',
                        },
                        mobile: {
                          text: '<p style="line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">_________________________________</span></p>',
                          padding: '0px 10px 10px 10px',
                          'max-width': '100%',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        'font-size': '40px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '4px 4px 4px 4px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-lock',
                        color: '#38D001',
                        margin: '5px 0px 15px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '36px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        text: `<p><span class="ql-cursor"></span><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 18px;">${i18next.t(
                          'pageWizard.salesPage.template.guarantee.safeBuy.reducedDescription'
                        )}</span></p>`,
                        'max-width': '90%',
                        padding: '24px 0px 13px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '90%',
                          text: `<p><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.reducedDescription'
                          )}</span></p>`,
                          padding: '16px 10px 10px 20px',
                        },
                        mobile: {
                          text: `<p style="line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.reducedDescription'
                          )}</span></p>`,
                          padding: '12px 0px 10px 5px',
                          'max-width': '87%',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        'font-size': '40px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '4px 4px 4px 4px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-check-double',
                        color: '#38D001',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '36px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        text: `<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 18px;">${capitalizeFirstLetter(
                          i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.title'
                          )
                        )}</span></p>`,
                        'max-width': '90%',
                        padding: '10px 0px 5px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '90%',
                          text: `<p><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${capitalizeFirstLetter(
                            i18next.t(
                              'pageWizard.salesPage.template.guarantee.safeBuy.title'
                            )
                          )}</span></p>`,
                          padding: '16px 10px 10px 20px',
                        },
                        mobile: {
                          text: `<p style="line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">${capitalizeFirstLetter(
                            i18next.t(
                              'pageWizard.salesPage.template.guarantee.safeBuy.title'
                            )
                          )}</span></p>`,
                          padding: '12px 0px 10px 5px',
                          'max-width': '87%',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        'font-size': '40px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '4px 4px 4px 4px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-medal',
                        color: '#38D001',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '36px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        text: `<p><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 18px;">${i18next.t(
                          'pageWizard.salesPage.template.guarantee.safeBuy.moneyBack'
                        )}</span></p>`,
                        'max-width': '90%',
                        padding: '17px 0px 7px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '90%',
                          text: `<p><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.moneyBack'
                          )}</span></p>`,
                          padding: '16px 10px 10px 20px',
                        },
                        mobile: {
                          text: `<p style="line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">${i18next.t(
                            'pageWizard.salesPage.template.guarantee.safeBuy.moneyBack'
                          )}</span></p>`,
                          padding: '12px 0px 10px 5px',
                          'max-width': '87%',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {},
                  },
                ],
                metadata: {},
              },
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '0px 0px 0px 0px',
                  'align-items': 'center',
                  'justify-content': 'center',
                },
                mobile: {
                  padding: '0px 0px 0px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '48px 40px 48px 10px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'end',
                    'align-items': 'center',
                    'flex-direction': 'column',
                    background: 'none',
                    'background-is-webp': true,
                    'max-width': '100%',
                    margin: '10px 10px 10px 0px',
                    desktop: {},
                    tablet: {
                      padding: '40px 10px 56px 10px',
                      'align-items': 'center',
                      'justify-content': 'start',
                      margin: '0px 10px 10px 10px',
                      'max-width': '80%',
                    },
                    mobile: {
                      'max-width': '90%',
                      padding: '10px 10px 48px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">${i18next
                          .t(
                            'pageWizard.salesPage.template.offer.priceStatement'
                          )
                          .toLowerCase()}</span></p>`,
                        'max-width': '72%',
                        padding: '10px 0px 0px 0px',
                        desktop: {},
                        tablet: {
                          text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">${i18next
                            .t(
                              'pageWizard.salesPage.template.offer.priceStatement'
                            )
                            .toLowerCase()}</span></p>`,
                        },
                        mobile: {
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">${i18next
                            .t(
                              'pageWizard.salesPage.template.offer.priceStatement'
                            )
                            .toLowerCase()}</span></p>`,
                          padding: '10px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 120px;">R$ 39,90</strong></p>',
                        'max-width': '100%',
                        padding: '10px 0px 20px 0px',
                        desktop: {},
                        tablet: {
                          'max-width': '100%',
                          text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 100px;">R$ 3<span class="ql-cursor"></span>9,90</strong></p>',
                          padding: '14px 0px 16px 0px',
                        },
                        mobile: {
                          text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 54px;">R$ 39,90</strong></p>',
                          'max-width': '100%',
                          padding: '5px 0px 5px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          useCase: {
                            offerPrice: {
                              propName: 'text',
                              middleware: 'text',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">${maxInstallments}</span></p>`,
                        'max-width': '72%',
                        padding: '10px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 16px;">${maxInstallments}</span></p>`,
                          padding: '10px 0px 10px 0px',
                        },
                        mobile: {
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;">${maxInstallments}</span></p>`,
                          padding: '16px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          installments: true,
                        },
                      },
                      {
                        action: 'link',
                        color: '#FFFFFF',
                        background: '#38D001',
                        'border-color': '#EF4E23',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'button-class': '',
                        desktop: {},
                        'end-date': '2023-05-26T13:48:23',
                        timezone: '-03:00_utcMinus0300MainCities',
                        'time-on-page': '5',
                        'time-on-page-unit': 'seconds',
                        'delay-type': 'disabled',
                        'font-family': 'Oswald, sans-serif',
                        'font-size': '20px',
                        'hover-background': 'none',
                        'hover-color': 'none',
                        'has-icon': false,
                        'has-text': true,
                        href: '',
                        'icon-position': 'right',
                        icon: 'fas fa-shopping-cart',
                        'is-bold': true,
                        'is-italic': false,
                        'is-underline': false,
                        'is-strike': false,
                        label: i18next.t('pageWizard.salesPage.template.CTA'),
                        margin: '24px 10px 16px 10px',
                        'max-width': '80%',
                        mobile: {
                          'max-width': '80%',
                          'font-size': '18px',
                          margin: '24px 0px 0px 0px',
                          padding: '12px 32px 12px 32px',
                        },
                        padding: '15px 32px 16px 32px',
                        tablet: {
                          margin: '32px 0px 20px 0px',
                          'max-width': '72%',
                          'font-size': '20px',
                        },
                        target: '_blank',
                        uid: `ls-${uuid()}`,
                        component: 'ls-button',
                        metadata: {
                          useCase: {
                            offerLink: {
                              propName: 'href',
                              middleware: 'string',
                            },
                            purchaseButton: {
                              propName: 'label',
                              middleware: 'string',
                            },
                            secondaryColor: {
                              propName: 'background',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 16px;">${i18next.t(
                          'pageWizard.salesPage.template.offer.secure'
                        )}</span></p>`,
                        'max-width': '60%',
                        padding: '5px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          'max-width': '64%',
                          text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.secure'
                          )}</span></p>`,
                          padding: '10px 0px 10px 0px',
                        },
                        mobile: {
                          'max-width': '100%',
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 12px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.secure'
                          )}</span></p>`,
                          padding: '16px 0px 8px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {
                      useCase: {
                        offerInstallments: {
                          propName: 'text',
                          structureMiddleware: 'offerInstallments',
                          props: {
                            parent: 'ls-1drUAiY3jpjJ3w3bDkcDMX',
                          },
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#121212',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-07-26T21:42:51',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '140px 0px 160px 0px',
        desktop: {},
        tablet: {
          padding: '90px 0px 90px 0px',
        },
        mobile: {
          padding: '64px 0px 64px 0px',
        },
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 7',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'start',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {},
                mobile: {
                  padding: '0px 20px 0px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 70px;">${i18next
                      .t('pageWizard.salesPage.template.FAQ.title')
                      .toUpperCase()}</strong></p>`,
                    'max-width': '100%',
                    padding: '10px 10px 48px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 64px;">${i18next
                        .t('pageWizard.salesPage.template.FAQ.title')
                        .toUpperCase()}</strong></p>`,
                      padding: '10px 48px 40px 48px',
                    },
                    mobile: {
                      padding: '10px 10px 024px 10px',
                      'max-width': '95%',
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 28px;">${i18next
                        .t('pageWizard.salesPage.template.FAQ.title')
                        .toUpperCase()}</strong></p>`,
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    margin: '0px 0px 0px 0px',
                    padding: '10px 10px 10px 10px',
                    'max-width': '100%',
                    color: '#FFFFFF',
                    background: 'none',
                    'header-background': '#00000000',
                    'content-background': '#00000000',
                    'icon-color': '#FFFFFF',
                    'vertical-spacing': '48px',
                    'initial-behavior': 'close',
                    items: `[{"id":"e5a8d97b-a547-4882-8acd-3071860877be","title":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.first.question')
                      .toUpperCase()}</span></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 16px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.first.question')
                      .toUpperCase()}</span></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{},"desktop":{}}}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>","padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{"text":true},"desktop":{}}}}},{"id":"ajgXKNXRw7Zt1K37HNzRhi","title":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.second.question')
                      .toUpperCase()}</span></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 16px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.second.question')
                      .toUpperCase()}</span></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{},"desktop":{}}}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.answer'
                    )}&nbsp;</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.answer'
                    )}&nbsp;</span></p>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"padding":true},"tablet":{"text":true},"desktop":{}}}}},{"id":"ds79hyCmsvwBMegfdQbpcF","title":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.third.question')
                      .toUpperCase()}</span></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 16px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.third.question')
                      .toUpperCase()}</span></h3>","padding":"12px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{},"desktop":{}}}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.answer'
                    )}&nbsp;</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.answer'
                    )}&nbsp;</span></p>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"padding":true},"tablet":{"text":true},"desktop":{}}}}},{"id":"57RRF23Lrf5bAe5FWkx7fp","title":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.fourth.question')
                      .toUpperCase()}</span></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><span style=\\"font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 16px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.fourth.question')
                      .toUpperCase()}</span></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{},"desktop":{}}}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.title'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.first'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.second'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.third'
                    )}</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.title'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.first'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.second'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.third'
                    )}</span></p>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"padding":true},"tablet":{"text":true},"desktop":{}}}}},{"id":"5uUGAukKXSdDWyFjkRcU5d","title":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 20px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.fifth.question')
                      .toUpperCase()}</span></h3>","padding":"12px 16px 14px 16px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1;\\"><span style=\\"color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 16px;\\">${i18next
                      .t('pageWizard.salesPage.template.FAQ.fifth.question')
                      .toUpperCase()}</span></h3>","padding":"10px 16px 15px 16px"},"tablet":{},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{},"desktop":{}}}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.answer'
                    )}</span></p>","padding":"20px 16px 20px 16px","max-width":"100%","mobile":{"padding":"16px 16px 16px 16px"},"tablet":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: Tahoma, Geneva, sans-serif; font-size: 15px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.answer'
                    )}</span></p>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"padding":true},"tablet":{"text":true},"desktop":{}}}}}]`,
                    desktop: {},
                    tablet: {
                      'max-width': '90%',
                      'vertical-spacing': '48px',
                    },
                    mobile: {
                      'max-width': '95%',
                      padding: '10px 10px 10px 10px',
                      'vertical-spacing': '40px',
                      margin: '6px 0px 6px 0px',
                      'icon-color': '#000000',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-accordion',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#0C177B',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 6',
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '32px 0px 32px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '32px 0px 32px 0px',
                },
                mobile: {
                  padding: '30px 0px 30px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">COPYRIGHT ©</span></p><p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">${i18next
                      .t('pageWizard.salesPage.template.copyright')
                      .toUpperCase()}</span></p>`,
                    'max-width': '40%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '50%',
                      text: `<p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">COPYRIGHT ©</span></p><p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">${i18next
                        .t('pageWizard.salesPage.template.copyright')
                        .toUpperCase()}</span></p>`,
                    },
                    mobile: {
                      text: `<p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">COPYRIGHT ©</span></p><p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: Oswald, sans-serif; font-size: 14px;">${i18next
                        .t('pageWizard.salesPage.template.copyright')
                        .toUpperCase()}</span></p>`,
                      'max-width': '80%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      unlinkedProps: {
                        mobile: {
                          text: true,
                          'max-width': true,
                        },
                        tablet: {
                          'max-width': true,
                          text: true,
                        },
                        desktop: {},
                      },
                    },
                  },
                ],
                metadata: {
                  unlinkedProps: {
                    mobile: {
                      padding: true,
                    },
                    tablet: {
                      padding: true,
                    },
                    desktop: {},
                  },
                },
              },
            ],
            metadata: {
              unlinkedProps: {
                mobile: {
                  'flex-direction': true,
                },
                tablet: {
                  'flex-direction': true,
                },
              },
            },
          },
        ],
      },
    ],
    metadata: {},
  };
};

export const getDarkPrimaryFontsConfig = () => ({
  Oswald: {
    regular: [400, 700],
    italic: [400, 700],
  },
  Montserrat: {
    regular: [400, 700],
    italic: [400, 700],
  },
  'Open Sans': {
    regular: [400, 700],
    italic: [400, 700],
  },
});
