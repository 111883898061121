import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const AlignLeft: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M13.625 0.25H0.875C0.640625 0.25 0.5 0.4375 0.5 0.625V1.375C0.5 1.60938 0.640625 1.75 0.875 1.75H13.625C13.8125 1.75 14 1.60938 14 1.375V0.625C14 0.4375 13.8125 0.25 13.625 0.25ZM21.125 18.25H0.875C0.640625 18.25 0.5 18.4375 0.5 18.625V19.375C0.5 19.6094 0.640625 19.75 0.875 19.75H21.125C21.3125 19.75 21.5 19.6094 21.5 19.375V18.625C21.5 18.4375 21.3125 18.25 21.125 18.25ZM13.625 12.25H0.875C0.640625 12.25 0.5 12.4375 0.5 12.625V13.375C0.5 13.6094 0.640625 13.75 0.875 13.75H13.625C13.8125 13.75 14 13.6094 14 13.375V12.625C14 12.4375 13.8125 12.25 13.625 12.25ZM21.125 6.25H0.875C0.640625 6.25 0.5 6.4375 0.5 6.625V7.375C0.5 7.60938 0.640625 7.75 0.875 7.75H21.125C21.3125 7.75 21.5 7.60938 21.5 7.375V6.625C21.5 6.4375 21.3125 6.25 21.125 6.25Z"
      fill={color}
    />
  </StyledSVG>
);
