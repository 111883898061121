import styled, { css } from 'styled-components';

type StyledBockProps = {
  isShowSeparator?: boolean;
  width?: string;
};

export const Container = styled.div<StyledBockProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  margin: 0 auto 40px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ isShowSeparator, width }) => css`
    ${isShowSeparator && 'border-bottom: 1px solid var(--gray-300);'}
    max-width: ${width || '450px'};
  `}
`;

type StyledLabelGroupProps = {
  hasDescription?: boolean;
};

export const LabelGroup = styled.div<StyledLabelGroupProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ hasDescription }) => css`
    margin-bottom: ${hasDescription ? '16px' : '24px'};
  `}
`;

export const Label = styled.label`
  color: var(--gray-900);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
`;

type StyledDescriptionProps = {
  descriptionMarginBottom: string;
};

export const Description = styled.label<StyledDescriptionProps>`
  color: var(--gray-900);
  font-size: 16px;

  ${({ descriptionMarginBottom }) =>
    css`
      margin-bottom: ${descriptionMarginBottom};
    `}
`;
