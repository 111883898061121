import { Select } from '@components/Select';
import styled from 'styled-components';

export const Container = styled.div``;

const prependStyle = `font-weight: 300;
color: var(--gray-900);`;

export const PrependDomain = styled.span`
  ${prependStyle}
`;

export const PrependPath = styled.div`
  ${prependStyle};
  padding: 0 4px;
`;

export const Path = styled.div`
  display: block;
`;

export const Title = styled.label`
  font-size: 16px;
`;

export const Button = styled.button`
  margin-top: 16px;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  display: block;
`;
export const LinkDomain = styled.div`
  font-size: 12px;
  margin-bottom: 18px;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 8px;
`;
