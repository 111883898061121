import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import { UseCaseLoader } from '@pages/PageWizard/components/UseCaseLoader';
import { StyledTextInput } from './styles';
import { Suggestions } from './components/Suggestions';

type AiSidebarBodyProps = HTMLAttributes<HTMLDivElement> & {
  aiTextSuggestions?: AiTextsSuggestions;
  isLoading: boolean;
  selectedSuggestions: Omit<UpdatedProductProps, 'description'>;
  userTypedTexts: GetTitleTextsFromAIAttributes;
  onGenerateButtonClicked: () => void;
  onGenerateNewSuggestions: () => void;
  onSuggestionSelected: (
    value: Omit<UpdatedProductProps, 'description'>
  ) => void;
  onUserTypedText: (value: GetTitleTextsFromAIAttributes) => void;
};

const AiSidebarBodyComponent: React.FC<AiSidebarBodyProps> = ({
  isLoading = false,
  aiTextSuggestions,
  selectedSuggestions,
  userTypedTexts,
  onGenerateButtonClicked,
  onGenerateNewSuggestions,
  onSuggestionSelected,
  onUserTypedText,
  ...attrs
}) => {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (e: React.ChangeEvent, inputType: string) => {
      const { value } = e.target as HTMLInputElement;

      onUserTypedText({ ...userTypedTexts, [inputType]: value });
    },
    [onUserTypedText, userTypedTexts]
  );

  const handleSuggestionSelected = useCallback(
    (value: Omit<UpdatedProductProps, 'description'>) => {
      const updatedSelected = { ...selectedSuggestions, ...value };
      onSuggestionSelected(updatedSelected);
    },
    [onSuggestionSelected, selectedSuggestions]
  );

  if (isLoading) {
    return (
      <UseCaseLoader
        title={t('pageWizard.salesPage.ai.loader.title')}
        subtitle={t('pageWizard.salesPage.ai.loader.subtitle')}
      />
    );
  }

  return aiTextSuggestions ? (
    <Suggestions
      suggestions={aiTextSuggestions}
      onSuggestionSelected={(value) => handleSuggestionSelected(value)}
      selectedSuggestions={selectedSuggestions}
      onGenerateNewButtonClicked={onGenerateNewSuggestions}
    />
  ) : (
    <div {...attrs}>
      {Object.keys(userTypedTexts).map((inputType) => (
        <StyledTextInput
          key={`${inputType}-text`}
          id={`${inputType}-text`}
          name={`${inputType}-text`}
          size="md"
          placeholder={t(
            `pageWizard.salesPage.ai.sidebar.presentProduct.${inputType}Placeholder`
          )}
          value={
            userTypedTexts[inputType as keyof GetTitleTextsFromAIAttributes]
          }
          onChange={(e) => handleChange(e, inputType)}
          label={t(
            `pageWizard.salesPage.ai.sidebar.presentProduct.${inputType}Label`
          )}
        />
      ))}
      <CosmosButton
        onButtonClicked={onGenerateButtonClicked}
        type="primary"
        label={t('pageWizard.salesPage.ai.sidebar.generateButton')}
        width="auto"
        size="md"
        disabled={
          !userTypedTexts.productName || !userTypedTexts.productDescription
        }
      />
    </div>
  );
};

export const AiSidebarBody = memo(AiSidebarBodyComponent);
