import styled from 'styled-components';

export const CollapseWrapper = styled.div`
  --collapse-padding: 8px 0px;
  --collapse-arrow-margin: 0px;
`;

export const Label = styled.b`
  font-size: 16px;
  --collapse-padding: 0;
  line-height: 150%;
  margin-left: 0;
`;

export const Description = styled.label`
  color: var(--gray-500);
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
`;
