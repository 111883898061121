import '@hotmart-org-ca/cosmos/dist/form/input-validate.css';

import {
  HTMLAttributes,
  useMemo,
  useCallback,
  useState,
  useEffect,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../../Select';
import { Label } from '../../../../../Label';
import { tooltipStyle } from '../../../../../../styles/global';
import { minify } from '../../../../../../common/utils';
import { Container, ContentAction, Switch, SwitchContainer } from './styles';

export type ActionsCounterProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  hasAction?: boolean;
  actionType?: 'redirectUrl' | 'message';
  redirectUrl?: string;
  message?: string;
  onChange?: (props: Record<string, any>) => void;
};

const ActionsComponent: React.FC<ActionsCounterProps> = ({
  hasAction = false,
  actionType,
  redirectUrl: redirectUrlProp,
  message: messageProp,
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(messageProp);
  const [redirectUrl, setRedirectUrl] = useState(redirectUrlProp);

  const selectActionType = useMemo(
    () => [
      {
        id: 'redirectUrl',
        label: t('timerMenu.action.redirectUrl'),
        value: 'redirectUrl',
      },
      {
        id: 'message',
        label: t('timerMenu.action.message'),
        value: 'message',
      },
    ],
    [t]
  );

  const handlePropsChange = useCallback(
    (props: Record<string, any>) => {
      onChange?.(props);
    },
    [onChange]
  );

  const handleMessageChange = useCallback(
    (value: string) => {
      setMessage(value);
      handlePropsChange({ message: value });
    },
    [handlePropsChange]
  );

  const handleRedirectUrlChange = useCallback(
    (value: string) => {
      setRedirectUrl(value);
      handlePropsChange({ 'redirect-url': value });
    },
    [handlePropsChange]
  );

  const handleToggleChange = useCallback(
    (e: Event) => {
      const target = e.target as HTMLInputElement;
      handlePropsChange?.({
        'has-action': target.checked,
      });
    },
    [handlePropsChange]
  );

  useEffect(() => {
    if (messageProp) {
      setMessage(messageProp);
    }

    if (redirectUrlProp) {
      setRedirectUrl(redirectUrlProp);
    }
  }, [messageProp, redirectUrlProp]);

  return (
    <Container {...attrs}>
      <SwitchContainer className="hot-form">
        <Switch className="hot-form--custom hot-form--switch">
          <input
            data-prevent-delete
            type="checkbox"
            className="hot-form__input"
            id="active-action"
            checked={hasAction}
            onChange={(event: any) => handleToggleChange(event)}
          />
          <label htmlFor="active-action" className="hot-form__label">
            {t('timerMenu.action.enableAction')}
          </label>
        </Switch>

        <hot-tooltip
          content={minify(
            `<style>${tooltipStyle}</style>
            <div class="custom-tooltip__label">${t(
              'labelTooltip.timer.action.title'
            )}</div>
            <div class="custom-tooltip__description">${t(
              'labelTooltip.timer.action.description'
            )}</div>`
          )}
          position="right"
        >
          <i className="fal fa-info-circle" />
        </hot-tooltip>
      </SwitchContainer>

      <Select
        id="action-type"
        label={t('timerMenu.action.actionType')}
        placeholder={t('form.placeholder')}
        selectedOption={actionType}
        infoTooltip={{
          title: 'labelTooltip.timer.actionType.title',
          description: 'labelTooltip.timer.actionType.description',
        }}
        options={selectActionType}
        disabled={!hasAction}
        onChange={(type) => handlePropsChange({ 'action-type': type })}
      />
      {actionType === 'message' ? (
        <ContentAction className="hot-form">
          <Label
            htmlFor="message"
            label={t('timerMenu.action.message')}
            className="hot-form__label"
          />

          <textarea
            data-prevent-delete
            id="message"
            className="hot-form__input hot-form__textarea--sm"
            placeholder={t('timerMenu.action.messagePlaceholder')}
            rows={3}
            disabled={!hasAction}
            value={message || ''}
            onChange={(event) => handleMessageChange(event.target.value)}
          />
        </ContentAction>
      ) : (
        <ContentAction className="hot-form">
          <Label
            htmlFor="redirect-url"
            className="hot-form__label"
            label={t('timerMenu.action.url')}
          />
          <input
            data-prevent-delete
            type="text"
            className="hot-form__input hot-form__input--sm"
            id="redirect-url"
            disabled={!hasAction}
            placeholder={t('timerMenu.action.urlPlaceholder')}
            value={redirectUrl || ''}
            onChange={(event) => handleRedirectUrlChange(event.target.value)}
          />
        </ContentAction>
      )}
    </Container>
  );
};

export const Actions = memo(ActionsComponent);
