import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0px;
`;

export const Title = styled.label`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  margin-bottom: 16px;
  height: 100px;
  width: 100%;
  color: var(--gray-400);
  font-size: 24px;
  background-color: var(--gray-100);
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const Description = styled.label`
  color: var(--gray-500);
  font-size: 12px;
  margin-bottom: 0;
`;

export const Label = styled.label`
  margin-bottom: 16px;
  width: 100%;
`;

export const ButtonIcon = styled.i`
  font-size: 16px;
  margin-right: 8px;
`;

export const Input = styled.input`
  display: none;
`;
