import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  color: var(--gray-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`;

export const Description = styled.p`
  color: var(--gray-600, #464b52);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const ModalCustomStyle = createGlobalStyle`
  hot-modal-header {
    padding-bottom: 8px;
  }

  .hot-modal-footer {
    gap: 16px;
  }
`;
