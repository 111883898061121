import { css } from 'styled-components';

export const tooltipStyle = css`
  .custom-tooltip__label {
    font-size: 0.9rem;
    font-weight: var(--font-weight);
    color: var(--gray-500);
    margin-bottom: 0.5rem;
  }

  .custom-tooltip__description {
    font-size: var(--text-2);
    color: var(--gray-700);
    font-weight: var(--font-weight);
  }

  :host(.hot-tooltip--right) .arrow::before,
  :host(.hot-tooltip--top) .arrow::before {
    box-shadow: 0px 2px 16px -6px rgb(0 0 0 / 25%);
  }

  :host(.hot-tooltip--right) .arrow::before {
    border-right-color: var(--white);
  }

  :host(.hot-tooltip--top) .arrow::before {
    border-top-color: var(--white);
  }

  .hot-tooltip__inner {
    padding: 1rem;
    background-color: var(--white);
    max-width: 17.5rem;
    box-shadow: 0px 2px 16px -6px rgb(0 0 0 / 25%);
  }
`;
