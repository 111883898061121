import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0 16px;

  .hot-form {
    margin-bottom: 16px;
  }
`;

export const Button = styled.button`
  width: 100%;
`;
