import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../../../Label';
import { Container, ItemsToggle } from './styles';
import '@hotmart-org-ca/cosmos/dist/form/input-validate.css';

export type ItemsVisibilityProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  itemsVisibility?: ItemsToggleProps;
  onChange?: (props: Record<string, any>) => void;
};

const ItemsVisibilityComponent: React.FC<ItemsVisibilityProps> = ({
  itemsVisibility = {
    days: true,
    hours: true,
    minutes: true,
    seconds: true,
    labels: true,
  },
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const itemsToggle = useMemo<{ id: keyof ItemsToggleProps; label: string }[]>(
    () => [
      {
        id: 'days',
        label: t('timerMenu.itemsVisibility.days'),
      },
      {
        id: 'hours',
        label: t('timerMenu.itemsVisibility.hours'),
      },
      {
        id: 'minutes',
        label: t('timerMenu.itemsVisibility.minutes'),
      },
      {
        id: 'seconds',
        label: t('timerMenu.itemsVisibility.seconds'),
      },
      {
        id: 'labels',
        label: t('timerMenu.itemsVisibility.labels'),
      },
    ],
    [t]
  );

  const updateToggleValues = useCallback(
    (items: ItemsToggleProps) => {
      const itemsVisibilityValue = JSON.stringify(items);
      onChange?.({
        'items-visibility': itemsVisibilityValue,
      });
    },
    [onChange]
  );

  const handleToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const target = e.target as HTMLInputElement;
      const items = { ...itemsVisibility, [id]: target.checked };
      updateToggleValues(items);
    },
    [updateToggleValues, itemsVisibility]
  );

  return (
    <Container {...attrs}>
      <Label label={t('timerMenu.itemsVisibility.title')} />

      {itemsToggle.map((item) => (
        <ItemsToggle
          key={item.id}
          className="hot-form hot-form--custom hot-form--switch"
        >
          <input
            data-prevent-delete
            type="checkbox"
            className="hot-form__input"
            id={`${item.id}-visibility`}
            checked={itemsVisibility[item.id]}
            onChange={(e) => handleToggleChange(e, item.id)}
          />
          <label htmlFor={`${item.id}-visibility`} className="hot-form__label">
            {item.label}
          </label>
        </ItemsToggle>
      ))}
    </Container>
  );
};

export const ItemsVisibility = memo(ItemsVisibilityComponent);
