import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageConfigExtraReducers } from '@store/thunks/pageConfig';

export const pageConfigInitialState: PageConfigState = {
  description: '',
  draft: false,
  facebook: {
    accessToken: '',
    pixelId: '',
    eventName: '',
    testEventCode: '',
  },
  favicon: '',
  keywords: '',
  mobileFirst: false,
  preventIndexation: false,
  shareImage: '',
  shareUrl: '',
  showMadeWithKlickpages: true,
  title: '',
  urlParams: [],
  version: 1,
  pageVersion: undefined,
  exitPopup: undefined,
  userRedirectScript: undefined,
};

const pageConfig = createSlice({
  name: 'pageConfig',
  initialState: pageConfigInitialState,
  reducers: {
    updateConfig(state, action: PayloadAction<Partial<PageConfigState>>) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => pageConfigExtraReducers(builder),
});

export const { updateConfig } = pageConfig.actions;

export default pageConfig.reducer;
