import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  top: 4px;
  right: 4px;
  opacity: 0;
  z-index: 4;
  transition: opacity 0.35s ease;
  margin-bottom: 10px;
  width: 70px;

  .hot-form--checkbox {
    margin: 0;
    padding: 0;
    flex: 1;

    .hot-form__label {
      &:before,
      &:after {
        top: 2px;
        left: 2px;
      }
    }
  }
`;

export const Icon = styled.i`
  font-size: 12px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 4px;
  background-color: transparent;
  border: none;

  :hover {
    background-color: var(--blue-lightest);
  }
`;
