import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';
import { updateMediaList } from '../updateMediaList';

export const moveMedia = createAsyncThunk<
  void,
  string | undefined,
  {
    state: LocalState;
    SerializedError: {
      name: string;
      stack: string;
      code: string;
    };
  }
>('mediaCenter/moveMedia', async (selectedFolderId, { dispatch, getState }) => {
  try {
    const {
      mediaCenter: { selectedMedia },
    } = getState();
    const promises: Promise<unknown>[] = [];
    let rejectedPromisesCount: number = 0;

    Object.values(selectedMedia).forEach((media) => {
      promises.push(
        MediaCenter.moveItem(media.type, media.id, selectedFolderId)
      );
    });

    const finishedPromises = await Promise.allSettled(promises);

    await dispatch(updateMediaList());

    finishedPromises.forEach((finishedPromise) => {
      if (finishedPromise.status === 'rejected') {
        rejectedPromisesCount += 1;
      }
    });
    if (rejectedPromisesCount === finishedPromises.length) {
      throw new Error('no media were moved');
    }
    if (rejectedPromisesCount > 0) {
      throw new Error('some media were not moved');
    }
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const moveMediaBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(moveMedia.pending, (state: MediaCenterState) => {
    state.loading = true;
  });
  builder.addCase(moveMedia.fulfilled, (state: MediaCenterState) => {
    state.selectedMedia = {};
    state.loading = false;
  });
  builder.addCase(moveMedia.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
