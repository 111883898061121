import { HTMLAttributes, memo } from 'react';
import { Colors } from '@pages/PageWizard/components/Colors';

type ThanksFirstStepProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    colors: UseCaseColors[];
  };

const FirstStepComponent: React.FC<ThanksFirstStepProps> = ({
  colors,
  onModifierChange,
}) => (
  <Colors
    label="pageWizard.publishPage.colors.title"
    colors={colors}
    onColorsChange={(value) => onModifierChange({ prop: 'colors', value })}
  />
);

export const FirstStep = memo(FirstStepComponent);
