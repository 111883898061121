import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Text: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M28 1C28 0.5 27.5 0 27 0H1C0.4375 0 0 0.5 0 1V5.5C0 5.8125 0.1875 6 0.5 6H1.5C1.75 6 2 5.8125 2 5.5V2H13V26H8.5C8.1875 26 8 26.25 8 26.5V27.5C8 27.8125 8.1875 28 8.5 28H19.5C19.75 28 20 27.8125 20 27.5V26.5C20 26.25 19.75 26 19.5 26H15V2H26V5.5C26 5.8125 26.1875 6 26.5 6H27.5C27.75 6 28 5.8125 28 5.5V1Z"
      fill={color}
    />
  </StyledSVG>
);
