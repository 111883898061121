import styled, { css } from 'styled-components';

type ContainerProps = {
  fullWidth?: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${({ fullWidth }) => css`
    ${fullWidth ? 'width: 100%;' : ''};
  `}
`;

type LabelProps = {
  fullWidth?: boolean;
};

export const Label = styled.label<LabelProps>`
  margin-bottom: 0;
  height: 40px;
  ${({ fullWidth }) => css`
    width: ${fullWidth ? '100%' : '200px'};
  `}
`;

export const ButtonIcon = styled.i`
  font-size: 16px;
  margin-right: 8px;
`;

export const Input = styled.input`
  display: none;
`;
