import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { usePageInfo } from '@hooks/usePageInfo';
import Klicksend from '@services/klicksend';
import Toast from '@services/toast';
import { CosmosLoader } from '@components/CosmosLoader';
import { FormName } from './components/FormName';
import { ContentWrapper, Divider } from '../../styles';
import { FormFields } from './components/FormFields';
import { FormCreationButtons } from './components/FormCreationButtons';
import { FormConfig } from './components/FormConfig';
import { defaultFormFields, defaultFormTerms } from './defaultFormProps';

type FormCreationProps = {
  selectedForm?: KlicksendForm;
  onCancelled: () => void;
  onSaved: (formId: string) => void;
};

const FormCreationComponent: React.FC<FormCreationProps> = ({
  selectedForm,
  onCancelled,
  onSaved,
}) => {
  const selectedFormTerms = useMemo(
    () =>
      selectedForm
        ? {
            singleOptIn: selectedForm?.single_optin,
            formTerms: selectedForm?.single_optin,
          }
        : undefined,
    [selectedForm]
  );

  const { t } = useTranslation();
  const { pageName } = usePageInfo();
  const [formName, setFormName] = useState<string>(
    selectedForm?.name || pageName
  );
  const [selectedTag, setSelectedTag] = useState<KlicksendTag | null>(null);
  const [tags, setTags] = useState<KlicksendTag[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [fields, setFields] = useState<KlicksendFormField[]>(
    selectedForm?.fields || defaultFormFields
  );
  const [formTerms, setFormTerms] = useState<KlicksendFormTerms>(
    selectedFormTerms || defaultFormTerms
  );

  useEffect(() => {
    if (selectedForm && tags) {
      setSelectedTag(
        tags.find((tag) => tag.id === selectedForm.tag_id) || null
      );
    }
  }, [selectedForm, tags]);

  const getTags = useCallback(async () => {
    setIsLoading(true);
    const tagData = await Klicksend.getTags();
    if (tagData.items.length > 0) {
      setTags(tagData.items);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const handleFormTerms = useCallback(
    (value, prop: 'singleOptIn' | 'formTerms') =>
      prop === 'formTerms'
        ? setFormTerms({ ...formTerms, formTerms: value })
        : setFormTerms({
            singleOptIn: value,
            formTerms: value === false ? false : formTerms.formTerms,
          }),
    [formTerms]
  );

  const outcome = useCallback(
    (type: 'success' | 'error', formId?: string) => {
      Toast[type](`toastMessages.formCreation.saved.${type}`);
      setIsLoading(false);
      return type === 'success' ? onSaved(formId!) : null;
    },
    [onSaved]
  );

  const translateFields = useCallback(
    (translateGdprValue?: boolean) =>
      fields.map((field) => ({
        ...field,
        label: t(field.label),
        ...(field.type === 'gdpr' && translateGdprValue
          ? { value: t(field.value) }
          : {}),
      })),
    [fields, t]
  );

  const handleSaveForm = useCallback(async () => {
    setIsLoading(true);
    try {
      const { id: tagId } =
        selectedTag || (await Klicksend.createKlicksendTag(pageName));

      if (selectedForm) {
        await Klicksend.updateForm({
          id: selectedForm.id,
          singleOptIn: formTerms.singleOptIn,
          terms: formTerms.formTerms,
          fields: translateFields(),
          tag: selectedForm.tag_id || tagId,
          name: formName,
        });

        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            custom_event_name: 'click_editor_form_save',
            event_details: 'Edit form',
          },
        });

        return outcome('success', selectedForm.id);
      }

      const formData = await Klicksend.createForm({
        name: formName,
        fields: translateFields(true),
        dataInfo: t('form.klicksend.formCreation.dataInfo'),
        tag: tagId,
      });

      if (formTerms.singleOptIn && formTerms.formTerms) {
        await Klicksend.updateForm({
          id: formData.id,
          singleOptIn: formTerms.singleOptIn,
          terms: formTerms.formTerms,
        });
      }

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_editor_form_save',
          event_details: 'New form',
        },
      });

      return outcome('success', formData.id);
    } catch (error) {
      return outcome('error');
    }
  }, [
    selectedTag,
    pageName,
    selectedForm,
    formName,
    translateFields,
    t,
    formTerms.singleOptIn,
    formTerms.formTerms,
    outcome,
  ]);

  return loading ? (
    <CosmosLoader />
  ) : (
    <>
      <ContentWrapper>
        <FormName
          onNameChanged={(value) => setFormName(value)}
          name={formName}
          error={!formName}
          errorMessage="form.klicksend.formCreation.formNameError"
        />
      </ContentWrapper>
      <Divider />
      <ContentWrapper>
        <FormFields
          fields={fields}
          onFieldsChanged={(value) => setFields(value)}
        />
      </ContentWrapper>
      <Divider />
      <ContentWrapper>
        <FormConfig
          tags={tags}
          singleOptIn={formTerms.singleOptIn}
          formTerms={formTerms.formTerms}
          onFormTermsChanged={(value) => handleFormTerms(value, 'formTerms')}
          onFormSingleOptInChanged={(value) =>
            handleFormTerms(value, 'singleOptIn')
          }
          onFormTagChanged={(value) => setSelectedTag(value)}
          selectedTag={`${selectedForm?.tag_id}`}
        />
      </ContentWrapper>
      <FormCreationButtons
        onCancelClicked={onCancelled}
        onSaveClicked={handleSaveForm}
        canSave={
          formTerms.singleOptIn === false ||
          (formTerms.singleOptIn === true && formTerms.formTerms === true) ||
          !formName
        }
      />
    </>
  );
};

export const FormCreation = memo(FormCreationComponent);
