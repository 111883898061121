export const getTemplate = () => ({
  background: 'none',
  padding: '0px 0px 0px 0px',
  desktop: {},
  tablet: {},
  mobile: {},
  uid: 'ls-9EHfR88hGkv6RBXRxfKYhf',
  component: 'ls-page',
  children: [
    {
      background: '#FF00B2',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: 'ls-buaP3b9UwmQFV7WtuaJV3a',
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: 'ls-aWhty1r7AFKj3Y1H7qHsJh',
          component: 'ls-row',
          children: [
            {
              padding: '56px 0px 160px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '56px 0px 240px 0px',
              },
              mobile: {
                padding: '84px 0px 64px 0px',
              },
              uid: 'ls-mVHTBTA6AbKsxYu3Zppoym',
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#FFFFFF',
                  'border-radius': '100px 100px 100px 100px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '150px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 10px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '100px',
                    'max-width': '100px',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                  },
                  uid: 'ls-gbGufoEMr3Whu6vdMCkAJk',
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5310958/foto-03.jpg',
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 24px;">Seu nome</span></p>',
                  'max-width': '50%',
                  padding: '10px 10px 40px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">Seu nome</span></p>',
                    padding: '10px 10px 32px 10px',
                    'max-width': '80%',
                  },
                  uid: 'ls-vtN13StFbyUGDG2ZvsV26v',
                  component: 'ls-text',
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
                {
                  action: 'link',
                  color: '#00B1CC',
                  background: '#FFFFFF',
                  'border-color': '#323232',
                  'border-radius': '8px 8px 8px 8px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2023-02-14T19:54:13',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 01',
                  margin: '0px 0px 24px 0px',
                  'max-width': '40%',
                  mobile: {
                    'max-width': '80%',
                    'font-size': '16px',
                    margin: '0px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    'max-width': '60%',
                  },
                  target: '_blank',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  uid: 'ls-nbksFsAqHnbkxaG6jaUFfN',
                  component: 'ls-button',
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
                {
                  action: 'link',
                  color: '#00B1CC',
                  background: '#FFFFFF',
                  'border-color': '#323232',
                  'border-radius': '8px 8px 8px 8px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2023-02-14T19:54:13',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 02',
                  margin: '0px 0px 24px 0px',
                  'max-width': '40%',
                  mobile: {
                    'max-width': '80%',
                    'font-size': '16px',
                    margin: '0px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    'max-width': '60%',
                  },
                  target: '_blank',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  uid: 'ls-fbWgoPex2JidiuBfXazXYB',
                  component: 'ls-button',
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
                {
                  action: 'link',
                  color: '#00B1CC',
                  background: '#FFFFFF',
                  'border-color': '#323232',
                  'border-radius': '8px 8px 8px 8px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2023-02-14T19:54:13',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 03',
                  margin: '0px 0px 24px 0px',
                  'max-width': '40%',
                  mobile: {
                    'max-width': '80%',
                    'font-size': '16px',
                    margin: '0px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    'max-width': '60%',
                  },
                  target: '_blank',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  uid: 'ls-oz4bvojBUn8qtdAiDhyJsV',
                  component: 'ls-button',
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
                {
                  action: 'link',
                  color: '#00B1CC',
                  background: '#FFFFFF',
                  'border-color': '#323232',
                  'border-radius': '8px 8px 8px 8px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2023-02-14T19:54:13',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 04',
                  margin: '0px 0px 24px 0px',
                  'max-width': '40%',
                  mobile: {
                    'max-width': '80%',
                    'font-size': '16px',
                    margin: '0px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    'max-width': '60%',
                  },
                  target: '_blank',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  uid: 'ls-rXc1q8C7awGwXgqkk4fLEB',
                  component: 'ls-button',
                  metadata: {
                    unlinkedProps: {
                      mobile: {},
                      tablet: {},
                      desktop: {},
                    },
                  },
                },
              ],
              metadata: {
                unlinkedProps: {
                  mobile: {},
                  tablet: {},
                  desktop: {},
                },
              },
            },
          ],
          metadata: {
            unlinkedProps: {
              desktop: {},
              tablet: {},
              mobile: {},
            },
          },
        },
      ],
      metadata: {
        label: 'Seção 1',
        unlinkedProps: {
          mobile: {},
          tablet: {},
          desktop: {},
        },
      },
    },
  ],
  metadata: {
    unlinkedProps: {
      desktop: {},
      tablet: {},
      mobile: {},
    },
  },
});

export const getFontsConfig = () => ({
  Raleway: {
    regular: [400, 700],
    italic: [400, 700],
  },
  Montserrat: {
    regular: [400, 700],
    italic: [400, 700],
  },
});
