import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import camelcase from 'camelcase';
import { AlertModal } from '@components/AlertModal';

type PageWizardModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  currentModal?: UseCaseModalType;
  pageUrl?: string;
  type: UseCaseType;
  fixedSize?: string;
  onClose?: () => void;
  onPrimaryButtonClicked: () => void;
  onSecondaryButtonClicked: () => void;
};

const ModalComponent: React.FC<PageWizardModalProps> = ({
  isOpen,
  currentModal,
  pageUrl,
  type: typeProp,
  fixedSize,
  onClose,
  onPrimaryButtonClicked,
  onSecondaryButtonClicked,
  ...attrs
}) => {
  const formattedType = useMemo(() => camelcase(typeProp), [typeProp]);

  const isUpgrade = useMemo(
    () => currentModal !== 'onboarding' && currentModal !== 'published',
    [currentModal]
  );

  const iconByType = useMemo<Record<UseCaseType, string>>(
    () => ({
      publish_lead_capture_page: '',
      publish_page: '',
      funnel_capture_publish_lead_capture_page: '',
      funnel_capture_thanks_page: '',
      sales_page_paid_with_product: 'fal fa-bolt',
      sales_page_paid_without_product: 'fal fa-bolt',
      hotmart_journey_sales_page_with_product: '',
    }),
    []
  );

  const imagePaths = useMemo<Record<string, string>>(
    () => ({
      onboarding: '/images/pageWizardOnboarding.svg',
      published: '/images/pageWizardPublished.svg',
      planLimit: '/images/planlimit.svg',
    }),
    []
  );

  const imagePath = useMemo(
    () =>
      isUpgrade
        ? imagePaths.planLimit
        : currentModal && imagePaths[currentModal],
    [currentModal, imagePaths, isUpgrade]
  );

  const tSubPath = useMemo(
    () => (isUpgrade ? 'upgrade' : `useCases.${formattedType}`),
    [formattedType, isUpgrade]
  );

  const shareUrl = useMemo(
    () => (currentModal === 'published' ? `https:${pageUrl}` : undefined),
    [currentModal, pageUrl]
  );

  const primaryButtonIcon = useMemo(
    () => (currentModal === 'onboarding' ? iconByType[typeProp] : ''),
    [currentModal, iconByType, typeProp]
  );

  const getText = useCallback(
    (key: string) => `modals.${tSubPath}.${currentModal}.${key}`,
    [currentModal, tSubPath]
  );

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      imageSrc={imagePath}
      fixedSize={fixedSize}
      socialShareUrl={
        typeProp === 'hotmart_journey_sales_page_with_product' ? '' : shareUrl
      }
      title={getText('title')}
      description={
        currentModal === 'published' &&
        typeProp !== 'hotmart_journey_sales_page_with_product'
          ? undefined
          : getText('description')
      }
      primaryButtonLabel={getText('firstButton')}
      primaryButtonIcon={primaryButtonIcon}
      secondaryButtonLabel={getText('secondButton')}
      onPrimaryButtonClick={onPrimaryButtonClicked}
      onSecondaryButtonClick={onSecondaryButtonClicked}
      {...attrs}
    />
  );
};

export const Modal = memo(ModalComponent);
