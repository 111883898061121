import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMenu } from '@hooks/useMenu';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { CosmosButton } from '@components/CosmosButton';
import { AddToRootButton } from '@components/AddToRootButton';
import {
  Actions,
  CircleBorder,
  Container,
  FirstDescription,
  SecondDescription,
} from './styles';

export type EmptyStateProps = HTMLAttributes<HTMLDivElement> & {
  hideActions?: boolean;
};

const EmptyStateComponent: React.FC<EmptyStateProps> = ({
  hideActions,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { setActiveSubmenu, goBack } = useMenu();

  const openPopupTemplatesSubmenu = useCallback(() => {
    goBack();
    setActiveSubmenu('popups-templates');
  }, [goBack, setActiveSubmenu]);

  return (
    <Container {...attrs}>
      <CircleBorder>
        <FontAwesomeIcon icon={faBookmark} />
      </CircleBorder>

      <FirstDescription>
        {t('savedPopups.emptyState.description.first')}
      </FirstDescription>

      <SecondDescription>
        {t('savedPopups.emptyState.description.second')}
      </SecondDescription>

      {!hideActions && (
        <Actions>
          <CosmosButton
            type="secondary"
            label={t('savedPopups.emptyState.selectModel')}
            onButtonClicked={openPopupTemplatesSubmenu}
          />
          <AddToRootButton
            activeElementAfterAdd
            forceAddEmptyElement
            addableElementType="popup"
            label="savedPopups.emptyState.addEmptyPopup"
          />
        </Actions>
      )}
    </Container>
  );
};

export const EmptyState = memo(EmptyStateComponent);
