import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import capitalize from 'lodash.capitalize';
import TagManager from 'react-gtm-module';
import { Trans, useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import { Select } from '@components/Select';
import { UseCaseLoader } from '@pages/PageWizard/components/UseCaseLoader';
import {
  GenerateAction,
  Label,
  RadioGroup,
  StyledTextInput,
  Title,
} from './styles';
import { getTypeOptions } from './type-options';

export type CaptureAiInputValues = {
  benefit: string;
  type: string;
  customType: string;
};

export type AiSidebarBodyProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  aiInputValues: CaptureAiInputValues;
  isLoading?: boolean;
  generatedOptions?: string[];
  selectedOption?: string;
  type: UseCaseType;
  onGenerate?: (generatedOptions?: UseCaseOptions) => void;
  onChange?: (values: CaptureAiInputValues) => void;
  onSelectedChange?: (selectedOption: string) => void;
};

const AiSidebarBodyComponent: React.FC<AiSidebarBodyProps> = ({
  aiInputValues,
  isLoading = false,
  generatedOptions = [],
  selectedOption = '',
  type,
  onGenerate,
  onChange,
  onSelectedChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const isDisabled = useMemo(
    () =>
      Boolean(
        !aiInputValues.type ||
          (aiInputValues.type === 'other' && !aiInputValues.customType) ||
          !aiInputValues.benefit
      ),
    [aiInputValues]
  );

  const handleChange = useCallback(
    (key: keyof CaptureAiInputValues, value: string) => {
      const updatedValues = { ...aiInputValues, [key]: value };
      if (key !== 'type') {
        onChange?.(updatedValues);
        return;
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_pages_capture_reward_type',
          event_details: capitalize(value),
        },
      });
      onChange?.({
        ...updatedValues,
        customType: value !== 'other' ? '' : updatedValues.customType,
      });
    },
    [aiInputValues, onChange]
  );

  const handleGenerate = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: 'click_pages_capture_generate_title_ai',
      },
    });
    onGenerate?.();
  }, [onGenerate]);

  const handleGenerateNewOptions = useCallback(() => {
    const eventType: Record<string, string> = {
      funnel_capture_publish_lead_capture_page:
        'click_generate_ai_options_capture_funnel',
      publish_lead_capture_page: 'click_generate_ai_options_capture_use_case',
    };
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: eventType[type],
        event_details: undefined,
      },
    });
    const [firstOption, secondOption, thirdOption, fourthOption] =
      generatedOptions;
    onGenerate?.({ firstOption, secondOption, thirdOption, fourthOption });
  }, [generatedOptions, onGenerate, type]);

  if (isLoading) {
    return (
      <UseCaseLoader
        title={t('pageWizard.salesPage.ai.loader.title')}
        subtitle={t(
          'pageWizard.publishLeadCapturePage.ai.sidebar.loader.subtitle'
        )}
      />
    );
  }

  return (
    <div {...attrs}>
      {!generatedOptions.length ? (
        <>
          <Select
            id="type"
            options={getTypeOptions()}
            size="md"
            selectedOption={aiInputValues.type}
            onChange={(value) => handleChange('type', value)}
            placeholder={t(
              'pageWizard.publishLeadCapturePage.ai.sidebar.type.placeholder'
            )}
            label={t('pageWizard.publishLeadCapturePage.ai.sidebar.type.title')}
          />

          {aiInputValues.type === 'other' && (
            <StyledTextInput
              id="customType"
              name="customType"
              size="md"
              value={aiInputValues.customType}
              onChange={(event) =>
                handleChange(
                  'customType',
                  (event.target as HTMLInputElement).value
                )
              }
              label={t(
                'pageWizard.publishLeadCapturePage.ai.sidebar.type.custom'
              )}
            />
          )}

          <StyledTextInput
            id="benefit"
            name="benefit"
            size="md"
            inputType="textarea"
            placeholder={t(
              `pageWizard.publishLeadCapturePage.ai.sidebar.benefit.${
                aiInputValues.type || 'other'
              }`
            )}
            helper={t(
              `pageWizard.publishLeadCapturePage.ai.sidebar.benefit.${
                aiInputValues.type || 'other'
              }`
            )}
            value={aiInputValues.benefit}
            onChange={(event) =>
              handleChange('benefit', (event.target as HTMLInputElement).value)
            }
            label={
              <span>
                <Trans
                  i18nKey="pageWizard.publishLeadCapturePage.ai.sidebar.benefit.title"
                  components={{ b: <b />, br: <br /> }}
                />
              </span>
            }
          />

          <CosmosButton
            onButtonClicked={handleGenerate}
            type="primary"
            label={t(
              'pageWizard.salesPage.paidWithProduct.sidebar.generateButton'
            )}
            width="auto"
            size="md"
            disabled={isDisabled}
          />
        </>
      ) : (
        <>
          <Title>
            {t(
              'pageWizard.publishLeadCapturePage.ai.sidebar.description.title'
            )}
          </Title>
          <Label>
            {t(
              'pageWizard.publishLeadCapturePage.ai.sidebar.description.label'
            )}
          </Label>

          <RadioGroup className="hot-form hot-form--custom hot-form--radio">
            {generatedOptions.map((description, index) => (
              <div key={`${description}-${index + 1}`}>
                <input
                  id={`${description}-${index + 1}`}
                  type="radio"
                  className="hot-form__input"
                  checked={description === selectedOption}
                  onChange={() => onSelectedChange?.(description)}
                />
                <label
                  className="hot-form__label"
                  htmlFor={`${description}-${index + 1}`}
                >
                  {description}
                </label>
              </div>
            ))}
          </RadioGroup>

          <GenerateAction>
            <CosmosButton
              type="tertiary"
              size="md"
              width="auto"
              onButtonClicked={handleGenerateNewOptions}
              label={t(
                'pageWizard.salesPage.paidWithProduct.sidebar.presentProduct.suggestions.newSuggestions'
              )}
            />
          </GenerateAction>
        </>
      )}
    </div>
  );
};

export const AiSidebarBody = memo(AiSidebarBodyComponent);
