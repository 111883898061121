import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Accordion: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg%22%3E"
    {...attrs}
  >
    <path
      d="M27.625 3.18506C27.8125 3.18506 28 3.06006 28 2.81006V1.31006C28 1.12256 27.8125 0.935059 27.625 0.935059H0.375C0.125 0.935059 0 1.12256 0 1.31006V2.81006C0 3.06006 0.125 3.18506 0.375 3.18506H27.625ZM27.625 13.1851C27.8125 13.1851 28 13.0601 28 12.8101V11.3101C28 11.1226 27.8125 10.9351 27.625 10.9351H0.375C0.125 10.9351 0 11.1226 0 11.3101V12.8101C0 13.0601 0.125 13.1851 0.375 13.1851H27.625ZM27.625 23.1851C27.8125 23.1851 28 23.0601 28 22.8101V21.3101C28 21.1226 27.8125 20.9351 27.625 20.9351H0.375C0.125 20.9351 0 21.1226 0 21.3101V22.8101C0 23.0601 0.125 23.1851 0.375 23.1851H27.625Z"
      fill={color}
    />
  </StyledSVG>
);
