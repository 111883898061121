import React, { useCallback, useState } from 'react';
import { useMenu } from '@hooks/index';
import { useSavedSections } from '@hooks/useSavedSections';
import { Submenu } from '@components/Submenu';
import { PageFragmentList } from '@components/PageFragmentList';
import { RemoveSectionModal } from './components/RemoveSectionModal';
import { EmptyState } from './components/EmptyState';

export const SavedSectionsSubmenu: React.FC = ({ ...attrs }) => {
  const { userSections, updateUserSection, loading } = useSavedSections();
  const { setActiveSectionTemplate } = useMenu();
  const [sectionToRemove, setSectionToRemove] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleGoBack = useCallback(() => {
    setActiveSectionTemplate('');
  }, [setActiveSectionTemplate]);

  const handleRemoveSectionClick = useCallback((sectionId: string) => {
    setSectionToRemove(sectionId);
    setOpenModal(true);
  }, []);

  const saveItemName = useCallback(
    async (id: string, name: string) => {
      await updateUserSection({ id, name });
    },
    [updateUserSection]
  );

  return (
    <Submenu
      title="sideMenu.sectionsTemplate.savedSections"
      onGoBack={handleGoBack}
      padding="24px"
      {...attrs}
    >
      {userSections.length ? (
        <PageFragmentList
          hideBorder
          groupName="addableSections"
          description="savedSections.title"
          bookmarkTooltip="savedSections.removeSectionTooltip"
          items={userSections}
          loading={loading}
          onBookmarkClick={handleRemoveSectionClick}
          onNameChange={saveItemName}
        />
      ) : (
        <EmptyState />
      )}

      <RemoveSectionModal
        isOpen={openModal}
        sectionId={sectionToRemove}
        onClose={() => setOpenModal(false)}
      />
    </Submenu>
  );
};
