import '@hotmart-org-ca/cosmos/dist/header';

import { HTMLAttributes } from 'react';
import { BaseHeader } from '../Header';
import { Logo } from './styles';

export const ListHeader: React.FC<HTMLAttributes<HTMLDivElement>> = () => (
  <BaseHeader
    leftSlot={<Logo src="/images/hotmartPages.svg" alt="Logo Hotmart Pages" />}
  />
);
