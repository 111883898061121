import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';

import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActiveComponentState,
  EditorEngineManager,
} from '@hotmart-org-ca/saas-pages-engine';
import { closeMenu, setActiveMenu, setActiveSubmenu } from '@store/slices/menu';
import { sleep } from '@common/utils';
import { START_ONBOARDING_EVENT_NAME } from '@common/constants';
import { BodyPopover } from '@components/BodyPopover';
import { steps } from './steps';
import {
  Actions,
  Button,
  CloseIcon,
  Container,
  Counter,
  Description,
  Overlay,
} from './styles';

const popoverTriggerId = 'step-trigger';

export type OnboardingStepsProps = HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
  onFinish?: () => void;
};

export const OnboardingSteps: React.FC<OnboardingStepsProps> = ({
  onClose,
  onFinish,
  ...attrs
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeComponent = useSelector(
    (state: State) => state.lsEditor.activeComponent
  );

  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState<OnboardingStep>();
  const [renderPopover, setRenderPopover] = useState(false);
  const [currentActiveComponent, setCurrentActiveComponent] =
    useState<ActiveComponentState>();

  const closePopover = useCallback(() => {
    const element = document.getElementById(
      popoverTriggerId
    ) as HotPopoverElement;
    element.closePopover();
  }, []);

  const executeActions = useCallback(
    (actions: OnboardingStepActions) =>
      new Promise<void>((resolve) => {
        actions.forEach((action) => {
          if (action.type === 'OPEN_MENU') {
            const { menu, submenu } = action;

            if (submenu) {
              dispatch(setActiveSubmenu(submenu));
            }
            dispatch(setActiveMenu(menu));
          } else {
            EditorEngineManager.setPreview(action.device);
          }
        });

        setTimeout(() => {
          resolve();
        }, 100);
      }),
    [dispatch]
  );

  const rollbackActions = useCallback(() => {
    dispatch(closeMenu());

    return sleep(300);
  }, [dispatch]);

  const toggleHighlightClass = useCallback((highlightHover: string[]) => {
    highlightHover.forEach((selector) => {
      const elements = document.querySelectorAll(selector);

      elements.forEach((element) => {
        element.classList.toggle('onboarding-highlight');
      });
    });
  }, []);

  const openStep = useCallback(
    async (nextStep: number) => {
      setRenderPopover(false);

      const currentStepConfig = steps[step - 1];
      const currentActions = currentStepConfig.actions || [];
      const nextStepConfig = steps[nextStep - 1];
      const nextActions = nextStepConfig.actions || [];
      const isNewActions =
        JSON.stringify(currentActions) !== JSON.stringify(nextActions);

      if (step !== nextStep && currentStepConfig.highlightHover?.length) {
        toggleHighlightClass(currentStepConfig.highlightHover);
      }

      if (isNewActions && step !== nextStep && currentActions.length) {
        await rollbackActions();
      }

      if ((isNewActions || step === nextStep) && nextActions.length) {
        await executeActions(nextActions);
      }

      if (nextStepConfig.highlightHover?.length) {
        toggleHighlightClass(nextStepConfig.highlightHover);
      }

      setStep(nextStep);
      setCurrentStep(nextStepConfig);
      setRenderPopover(true);
    },
    [executeActions, rollbackActions, step, toggleHighlightClass]
  );

  const goToStep = useCallback(
    (value: number) => {
      closePopover();

      setTimeout(() => {
        openStep(value);
      }, 200);
    },
    [closePopover, openStep]
  );

  const startOnboarding = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent<number>;
      const startStep = customEvent.detail || 1;

      setCurrentActiveComponent(activeComponent);
      setStep(startStep);
      openStep(startStep);

      EditorEngineManager.clearActive();
      dispatch(closeMenu());
    },
    [activeComponent, dispatch, openStep]
  );

  const handleFinish = useCallback(
    (emitEvent?: () => void) => {
      closePopover();
      rollbackActions();

      setTimeout(() => {
        setRenderPopover(false);
        setStep(1);
        setCurrentStep(undefined);
        emitEvent?.();

        if (currentActiveComponent) {
          EditorEngineManager.setActive(currentActiveComponent);
        }
      }, 200);
    },
    [closePopover, currentActiveComponent, rollbackActions]
  );

  useEffect(() => {
    window.addEventListener(START_ONBOARDING_EVENT_NAME, startOnboarding);

    return () => {
      window.removeEventListener(START_ONBOARDING_EVENT_NAME, startOnboarding);
    };
  }, [startOnboarding]);

  return (
    <Container {...attrs}>
      {renderPopover && (
        <>
          <BodyPopover
            id={popoverTriggerId}
            openOnRender
            preventTriggerClick
            elementSelector={currentStep?.elementSelector as string}
            position={currentStep?.position}
            distanceFromElement={currentStep?.distanceFromElement}
            arrowCustomStyle={currentStep?.arrowStyle}
            popoverCustomStyle={currentStep?.popoverStyle}
            zIndex={25}
          >
            <div slot="body" className="hot-popover__body">
              <CloseIcon onClick={() => handleFinish(onClose)}>
                <i className="far fa-times" />
              </CloseIcon>

              <Counter>
                {t('onboarding.steps.hint', { step, total: steps.length })}
              </Counter>

              <Description>
                <Trans
                  i18nKey={currentStep?.description}
                  components={{ b: <b />, br: <br /> }}
                />
              </Description>

              <Actions>
                {step > 1 && (
                  <Button
                    className="hot-button hot-button--tertiary"
                    onClick={() => goToStep(step - 1)}
                  >
                    {t('onboarding.steps.back')}
                  </Button>
                )}

                {step < steps.length && (
                  <Button
                    className="hot-button hot-button--primary"
                    onClick={() => goToStep(step + 1)}
                  >
                    {t('onboarding.steps.next')}
                  </Button>
                )}

                {step === steps.length && (
                  <Button
                    className="hot-button hot-button--primary"
                    onClick={() => handleFinish(onFinish)}
                  >
                    {t('onboarding.steps.finish')}
                  </Button>
                )}
              </Actions>
            </div>
          </BodyPopover>

          <Overlay />
        </>
      )}
    </Container>
  );
};
