import { HTMLAttributes, memo } from 'react';
import { Container, Text } from './styles';

export type MadeWithProps = HTMLAttributes<HTMLAnchorElement>;

const MadeWithComponent: React.FC<MadeWithProps> = ({ ...attrs }) => (
  <Container
    id="hotmart-made-with"
    href="https://art.pages.hotmart.com/public/redirects/made-with-extensions"
    target="__blank"
    {...attrs}
  >
    <Text>Feito com</Text>
    <img
      height="18.52"
      width="96"
      alt="Hotmart Pages Logo"
      src="/images/hotmartPagesWhite.svg"
    />
  </Container>
);

export const MadeWith = memo(MadeWithComponent);
