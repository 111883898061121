import { Submenu } from '@components/Submenu';
import { useMenu } from '@hooks/useMenu';

import React, { useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { ModelCategoryList } from '@components/ModelCategoryList';
import { popupsCategories } from './popupsCategories';

export const PopupsSubmenu: React.FC = () => {
  const { setActiveSubmenu } = useMenu();

  const handleClick = useCallback(
    (menuId: string) => {
      const eventType: Record<string, string> = {
        'saved-popups': 'saved_popups',
        'popups-templates': 'templates',
      };

      setActiveSubmenu(menuId);
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_add_pop_up',
          event_details: eventType[menuId],
        },
      });
    },
    [setActiveSubmenu]
  );

  return (
    <Submenu title="sideMenu.popupsTemplate.title" padding="24px 8px">
      <ModelCategoryList
        categories={popupsCategories}
        onSelectedCategory={(id) => handleClick(id)}
      />
    </Submenu>
  );
};
