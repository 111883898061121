import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';
import '@hotmart-org-ca/cosmos/dist/table/variations/bordered.css';
import '@hotmart-org-ca/cosmos/dist/table/table.css';

import React, {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import HotmartProductService from '@services/hotmartProduct';
import { Block } from '@components/Block';
import { useUseCases } from '@hooks/useUseCases';
import { ProductTable } from '@components/ProductTable';
import { Container } from './styles';
import { SalesUseCase } from '../../../../salesUseCase';

export type ProductsPropsComponent = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    product: ProductProps;
    author: AuthorProps;
    template?: SalesPageTemplateType;
  };

const ProductComponent: React.FC<ProductsPropsComponent> = ({
  onModifierChange,
  product: productProp,
  author: authorProp,
  template = 'default',
  ...attrs
}) => {
  const { setFooterRightButtonConfig } = useUseCases();

  const [products, setProducts] = useState<HotmartProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(productProp.id);

  const getProducts = useCallback(async () => {
    try {
      setLoading(true);

      const productsData = await HotmartProductService.getProducts();

      setProducts(productsData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setSelectedProductId(productProp.id);
  }, [productProp.id]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const handleSelectProduct = useCallback(
    (product: HotmartProduct) => {
      setSelectedProductId(product.id);
      onModifierChange({
        prop: 'product',
        value: {
          ...productProp,
          title: product.name,
          description: product.description,
          id: product.id,
          imgSrc: product.imgSrc || SalesUseCase.imagesByTemplate[template],
          offerId: '',
          offerPrice: '',
          offerLink: '',
          offerKey: '',
          offerMaxInstallments: 1,
        },
      });
      onModifierChange({
        prop: 'author',
        value: {
          ...authorProp,
          id: product.userId,
          imgSrc: product.userImgSrc || SalesUseCase.imagesByTemplate[template],
        },
      });
    },
    [authorProp, onModifierChange, productProp, template]
  );

  useEffect(() => {
    const disabled = Boolean(!selectedProductId);
    setFooterRightButtonConfig({
      disabled,
    });
  }, [selectedProductId, setFooterRightButtonConfig]);

  return (
    <Container {...attrs}>
      <Block
        label="pageWizard.salesPage.paidWithProduct.product.title"
        showSeparator={false}
        width="100%"
        descriptionMarginBottom="16px"
        description="pageWizard.salesPage.paidWithProduct.product.description"
        noPreview
      >
        <ProductTable
          onSelectProduct={handleSelectProduct}
          products={products}
          loading={loading}
          selectedProductId={selectedProductId}
          searchBarSize="100%"
        />
      </Block>
    </Container>
  );
};

export const Product = memo(ProductComponent);
