import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const ToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

export const AddFolderContainer = styled.div`
  border-left: 1px solid var(--gray-200);
  padding-left: 16px;
  display: flex;
`;

type ArrowIconProps = {
  disabled?: boolean;
};

export const ArrowIcon = styled(FontAwesomeIcon)<ArrowIconProps>`
  cursor: pointer;
  padding: 0 5px;
  color: var(--gray-500);

  ${({ disabled }) => css`
    ${disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
  `}
`;

export const Button = styled.button`
  color: var(--gray-500);
  padding: 0;
  cursor: pointer;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
