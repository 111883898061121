import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { ImageUpload } from '@pages/PageWizard/useCases/Sales/components/ImageUpload';
import { TextInputs } from './components/TextInputs';
import { AiSidebar } from './components/AiSidebar';
import { SalesUseCase } from '../../salesUseCase';

type PresentAuthorProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    author: AuthorProps;
    updatedAuthor: UpdatedAuthorProps;
    useCase: SalesUseCase;
  };

const PresentAuthorComponent: React.FC<PresentAuthorProps> = ({
  author,
  updatedAuthor,
  useCase,
  onModifierChange,
}) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  const handleTextChange = useCallback(
    (text, inputType) => {
      const isDescription = inputType === 'description';
      const category = isDescription ? 'authorDescription' : 'authorName';
      const prop = isDescription ? 'updatedAuthor' : 'author';
      const value = isDescription
        ? { ...updatedAuthor, description: text }
        : { ...author, name: text };

      useCase.modifyByCategory(category, text);

      onModifierChange({ prop, value });
      setIsSideBarOpen(false);
    },
    [author, onModifierChange, updatedAuthor, useCase]
  );

  const handleImageChange = useCallback(
    (imgSrc: string) => {
      useCase.modifyByCategory('authorImage', imgSrc);

      onModifierChange({
        prop: 'author',
        value: { ...author, imgSrc },
      });
    },
    [author, onModifierChange, useCase]
  );

  return (
    <>
      <TextInputs
        description={updatedAuthor.description}
        name={author.name}
        onTextChanged={(value, inputType) => handleTextChange(value, inputType)}
        onAiButtonClicked={() => setIsSideBarOpen(true)}
      />
      <ImageUpload
        src={author.imgSrc}
        onImageChange={(value) => handleImageChange(value)}
        blockTitle="pageWizard.salesPage.paidWithProduct.presentAuthor.photoTitle"
        label="pageWizard.salesPage.paidWithProduct.presentAuthor.photoLabel"
      />
      <AiSidebar
        author={author}
        isOpen={isSideBarOpen}
        onClosed={() => setIsSideBarOpen(false)}
        onUseAiDescription={(aiGeneratedDescription) =>
          handleTextChange(aiGeneratedDescription, 'description')
        }
      />
    </>
  );
};

export const PresentAuthor = memo(PresentAuthorComponent);
