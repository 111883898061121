import styled from 'styled-components';

export const Title = styled.span`
  font-size: var(--text-2);
  color: var(--gray-500);
  cursor: pointer;
  display: flex;
  line-height: 0;
`;

export const Container = styled.div`
  width: 1460px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 40px;
  gap: 16px;

  @media screen and (max-width: 1461px) {
    width: 1296px;
  } ;
`;
