import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Video: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M33.9375 2C33.5625 2 33.1875 2.125 32.8125 2.375L26 6.75V3C26 1.375 24.5 0 22.75 0H3.1875C1.4375 0 0 1.375 0 3V21.0625C0 22.6875 1.4375 24 3.1875 24H22.75C24.5 24 26 22.6875 26 21.0625V17.3125L32.8125 21.6875C33.1875 21.9375 33.5625 22 33.9375 22C35 22 35.9375 21.1875 35.9375 20.0625V4C36 2.8125 35 2 33.9375 2ZM24 21.0625C24 21.5625 23.375 22 22.75 22H3.1875C2.5625 22 2 21.5625 2 21.0625V3C2 2.5 2.5625 2 3.1875 2H22.75C23.375 2 24 2.5 24 3V21.0625ZM34 20.0625L33.875 20L26 14.9375V9.125L34 4V20.0625Z"
      fill={color}
    />
  </StyledSVG>
);
