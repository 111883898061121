import styled from 'styled-components';

export const ModalTitle = styled.label`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 0;
  color: var(--gray-700);
`;

export const Description = styled.span`
  font-size: 16px;
  color: var(--gray-500);
`;

export const CancelButton = styled.button`
  margin-right: 16px;
`;
