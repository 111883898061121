import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedPopups from '@services/savedPopups';

export const createUserPopup = createAsyncThunk<
  PageFragmentListItem[],
  Omit<PageFragmentListItem, 'id'>,
  {
    state: LocalState;
  }
>('pageFragments/createUserPopup', async (popup) => {
  try {
    await SavedPopups.create(popup);

    const userPopups = await SavedPopups.get();

    return userPopups;
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const createUserPopupBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(createUserPopup.pending, (state: PageFragmentsState) => {
    state.popupLoading = true;
  });
  builder.addCase(
    createUserPopup.fulfilled,
    (state: PageFragmentsState, action) => {
      state.userPopups = action.payload;
      state.popupLoading = false;
    }
  );
  builder.addCase(createUserPopup.rejected, (state: PageFragmentsState) => {
    state.popupLoading = false;
  });
};
