import { AlertModal } from '@components/AlertModal';
import { useEngine } from '@hooks/useEngine';
import TagManager from 'react-gtm-module';
import React, {
  ComponentState,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HotmartProductModal } from '../HotmartProductModal';

export type CheckoutAlertModalProps = {
  isAlertModalOpen: boolean;
  isProductModalOpen: boolean;
  uid: string;
  modifierType: 'transparentCheckout' | 'checkoutLink';
  onAlertModalClose: () => void;
  onProductModalClose: () => void;
};

const CheckoutModifierModalSetComponent: React.FC<CheckoutAlertModalProps> = ({
  isAlertModalOpen,
  isProductModalOpen,
  modifierType,
  uid,
  onAlertModalClose,
  onProductModalClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { updateElement } = useEngine();
  const [offerId, setOfferId] = useState('');
  const [productId, setProductId] = useState('');
  const elementState = useSelector<State, ComponentState>(
    (state) => state[uid] as ComponentState
  );

  useEffect(() => {
    if (isProductModalOpen && elementState.metadata.checkout) {
      const { productId: productValue, offerId: offerValue } =
        elementState.metadata.checkout;

      setProductId(productValue);
      setOfferId(offerValue);
    }
  }, [elementState.metadata.checkout, isProductModalOpen]);

  const handleManageProducts = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_HOTMART_URL}/products/producer?itm_source=new-editor_pages&itm_medium=link_hotmart`,
      '_blank'
    );
    onAlertModalClose();
  }, [onAlertModalClose]);

  const emitTagManagerEvent = useCallback(
    () =>
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_config_checkout_link_continue',
          event_details: undefined,
        },
      }),
    []
  );

  const handleSelectProduct = useCallback(
    (selectedProduct: HotmartProduct, selectedOffer: Partial<ProductProps>) => {
      setProductId(selectedProduct.id);
      setOfferId(selectedOffer.offerId as string);
      const props =
        modifierType === 'checkoutLink'
          ? { href: selectedOffer.offerLink }
          : { 'offer-key': selectedOffer.offerKey };

      const metadata = {
        checkout: {
          productId: selectedProduct.id,
          offerId: selectedOffer.offerId,
          ...(modifierType === 'transparentCheckout'
            ? {
                productName: selectedProduct.name,
                offerUrl: selectedOffer.offerLink,
              }
            : {}),
        },
      };

      updateElement(uid, {
        metadata,
        props,
      });
      if (modifierType === 'checkoutLink') {
        emitTagManagerEvent();
      }
      onProductModalClose();
    },
    [emitTagManagerEvent, modifierType, onProductModalClose, uid, updateElement]
  );

  return (
    <div {...attrs}>
      <HotmartProductModal
        isOpen={isProductModalOpen}
        selectedOfferId={offerId}
        selectedProductId={productId}
        modalTitle={t(
          `modals.hotmartProductModal.${modifierType}ModifierTitle`
        )}
        onProductDataSelected={handleSelectProduct}
        onClose={onProductModalClose}
        showOfferKey
      />

      <AlertModal
        isOpen={isAlertModalOpen}
        title={
          <Trans
            i18nKey="modifiers.checkout.alertModal.title"
            components={{ b: <b /> }}
          />
        }
        description={
          <Trans
            i18nKey="modifiers.checkout.alertModal.description"
            components={{ b: <b /> }}
          />
        }
        primaryButtonLabel={t('modifiers.checkout.alertModal.confirm')}
        secondaryButtonLabel={t('modifiers.checkout.alertModal.manageProducts')}
        imageSrc="/images/exclamation.svg"
        imageSize={{ width: 100, height: 100 }}
        onPrimaryButtonClick={onAlertModalClose}
        onSecondaryButtonClick={handleManageProducts}
        onClose={onAlertModalClose}
        buttonsMinWidth="180px"
        closeIconAbsolute
      />
    </div>
  );
};

export const CheckoutModifierModalSet = memo(CheckoutModifierModalSetComponent);
