import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Sections: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M24 12.0469C24 11.5781 23.7188 11.1562 23.2969 10.9688L18.6094 8.90625L23.2969 6.89062C23.7188 6.70312 24 6.28125 24 5.8125C24 5.34375 23.7188 4.92188 23.2969 4.78125L12.7031 0.1875C12.2344 -0.046875 11.7188 -0.046875 11.25 0.1875L0.65625 4.78125C0.234375 4.92188 0 5.34375 0 5.8125C0 6.28125 0.234375 6.70312 0.65625 6.89062L5.34375 8.90625L0.65625 10.9688C0.234375 11.1562 0 11.5781 0 12.0469C0 12.4688 0.234375 12.8906 0.65625 13.0781L5.34375 15.1406L0.65625 17.1562C0.234375 17.3438 0 17.7656 0 18.2344C0 18.7031 0.234375 19.125 0.65625 19.2656L11.25 23.8594C11.4375 23.9531 11.7656 24 11.9531 24C12.2344 24 12.4688 23.9531 12.7031 23.8594L23.2969 19.2656C23.7188 19.125 23.9531 18.7031 23.9531 18.2344C23.9531 17.7656 23.7188 17.3438 23.2969 17.1562L18.6094 15.1406L23.2969 13.0781C23.7188 12.8906 24 12.4688 24 12.0469ZM2.10938 5.8125L11.8594 1.59375C11.9531 1.54688 12 1.54688 12.0938 1.59375L21.8438 5.8125L12.0938 10.0781C12 10.0781 11.9531 10.0781 11.8594 10.0781L2.10938 5.8125ZM21.8438 18.2344L12.0938 22.4531C12 22.5 11.9531 22.5 11.8594 22.4531L2.10938 18.2344L7.26562 15.9844L11.25 17.6719C11.4375 17.7656 11.7656 17.8125 12 17.8125C12.2344 17.8125 12.4688 17.7656 12.7031 17.6719L16.6875 15.9844L21.8438 18.2344ZM12.0938 16.2656C12 16.3125 11.9531 16.3125 11.8594 16.2656L2.10938 12L7.3125 9.75L11.25 11.4844C11.4375 11.5781 11.7656 11.625 12 11.625C12.2344 11.625 12.4688 11.5781 12.7031 11.4844L16.6875 9.75L21.8438 12.0469L12.0938 16.2656Z"
      fill={color}
    />
  </StyledSVG>
);
