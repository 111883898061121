import styled from 'styled-components';

export const Container = styled.a`
  width: 116px;
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10;
  border: 0px solid whitesmoke;
  background: radial-gradient(
    63.16% 63.16% at 50% 50%,
    rgba(0, 0, 0, 0.75) 0%,
    #000000 100%
  );
  border-top-left-radius: 10px;
`;

export const Text = styled.span`
  color: white;
  font-family: 'Lato';
  font-size: 10px;
  font-weight: 400;
`;
