import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getAuthTokenMode } from 'src/auth/authManager';

import Api from '../api';

export default class Http {
  private apiInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance | null = null) {
    if (axiosInstance === null) {
      const tokenMode = getAuthTokenMode();
      this.apiInstance = Api.getInstanceByTokenName(tokenMode);
      return;
    }

    this.apiInstance = axiosInstance;
  }

  static getCancelSource() {
    return Api.getCancelSource();
  }

  async delete<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.delete<T>(url, config);
    return data;
  }

  async get<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.get<T>(url, config);
    return data;
  }

  async patch<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.patch<T>(url, body, config);
    return data;
  }

  async post<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.post<T>(url, body, config);
    return data;
  }

  async put<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.put<T>(url, body, config);
    return data;
  }
}
