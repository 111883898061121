import {
  ComponentState,
  CustomBootstrapConfig,
  EditorEngineManager,
} from '@hotmart-org-ca/saas-pages-engine';
import debounce from 'lodash.debounce';
import { savePageFiles } from '@store/thunks/pageInfo/savePageFiles';
import { goBack, setActiveMenu } from '@store/slices/menu';
import { updateInfo } from '@store/slices/pageInfo';
import store, { rootReducer } from '@store/index';
import Toast from '@services/toast';
import { savePageMetadata } from '@store/thunks/pageInfo/savePageMetadata';
import { dispatchOpenPopup, getHigherChildLabel } from './utils';

const debouncedSavePageFiles = debounce(async () => {
  try {
    const pageUuid = store.getState().pageInfo.uuid;

    if (pageUuid) {
      await store.dispatch(savePageFiles()).unwrap();
      await store.dispatch(savePageMetadata()).unwrap();
    }
  } catch (error) {
    Toast.error('toastMessages.page.error.saved');
  }
}, 1000);

export const getBootstrapConfig: () => CustomBootstrapConfig = () => ({
  storeOptions: {
    store,
    reducers: rootReducer,
  },
  events: {
    onRemoveElement: () => {
      store.dispatch(goBack());
    },
    onPageChange: () => {
      store.dispatch(
        updateInfo({
          hasUnsavedChanges: true,
          savingStatus: 'loading',
        })
      );
      debouncedSavePageFiles();
    },
    onSectionAdded: (uid: string) => {
      const state = store.getState() as State;
      const { metadata } = state[uid] as ComponentState;

      if (!metadata?.label) {
        const sectionName = getHigherChildLabel(state);
        EditorEngineManager.updateElement(
          uid,
          { metadata: { label: sectionName } },
          false
        );
      }
    },
    onPopupAdded: (uid: string) => {
      const state = store.getState() as State;

      setTimeout(() => {
        dispatchOpenPopup(uid, state.lsEditor.openedPopup);
      }, 100);
    },
    onAddElementColumnEmptyStateClick: () => {
      EditorEngineManager.clearActive();
      store.dispatch(setActiveMenu('add'));
    },
  },
});
