import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { FirstStep } from './components/FirstStep';
import { SecondStep } from './components/SecondStep';

export type ThanksProps = HTMLAttributes<HTMLDivElement> & {
  currentStep: number;
  properties: ThanksPageProperties;
  onPropertiesChange: (props: Partial<ThanksPageProperties>) => void;
};

const ThanksComponent: React.FC<ThanksProps> = ({
  properties,
  currentStep,
  onPropertiesChange,
}) => {
  const colorsConfig = useMemo(
    () =>
      Object.entries(properties.colors).map(([key, value]) => ({
        id: key,
        name: key,
        label: `pageWizard.funnelCaptureThanksPage.colors.${key}`,
        color: value,
      })),
    [properties.colors]
  );

  const handleColorChange = useCallback(
    ({ prop, value: colorsConfigValue }: UseCaseModifierChange) => {
      const formattedColors = (colorsConfigValue as typeof colorsConfig).reduce(
        (rootAcc, config) => ({ ...rootAcc, [config.name]: config.color }),
        {}
      );
      onPropertiesChange({ [prop]: formattedColors });
    },
    [onPropertiesChange]
  );

  const handleChange = useCallback(
    ({ prop, value }: UseCaseModifierChange) => {
      onPropertiesChange({ [prop]: value });
    },
    [onPropertiesChange]
  );

  const steps = useMemo(
    () => [
      <FirstStep onModifierChange={handleColorChange} colors={colorsConfig} />,
      <SecondStep onModifierChange={handleChange} properties={properties} />,
    ],
    [colorsConfig, handleChange, handleColorChange, properties]
  );

  return steps[currentStep] || steps[1];
};

export const Thanks = memo(ThanksComponent);
