import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import packageJson from '../../package.json';

const initSentry = () => {
  if (process.env.REACT_APP_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://de15be93952f476c96ad8173a75671a7@o49094.ingest.sentry.io/6200409',
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_ENV,
      release: `saas-pages-web-editor@${packageJson.version}`,
      tracesSampleRate: 0.01,
      denyUrls: [
        // Google
        /google.com/i,
        /gtm.js/i,
        /googletagmanager.com/i,

        // Facebook
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,

        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,

        // Firefox extensions
        /^resource:\/\//i,
        /^moz-extension:\/\//i,
      ],
    });
  }
};

export { initSentry };
