import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 70px;
  flex: 1;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: var(--text-6);
  font-weight: var(--font-weight-light);
  color: var(--gray-800);
  margin: 0;
`;

export const EmptyState = styled.span`
  font-size: var(--text-2);
  color: var(--gray-500);
  margin: 24px auto;
`;

export const Loader = styled.div`
  margin: 24px auto;
`;

export const Table = styled.table`
  td {
    vertical-align: middle;
    padding: 16px;
  }
`;

export const TemplateName = styled.span`
  color: var(--gray-700);
  font-size: 0.875rem;
  font-weight: var(--font-weight-bold);
`;

export const TemplateAttr = styled.span`
  align-items: center;
  color: var(--gray-700);
  font-size: var(--text-1);
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export const EditButton = styled.button`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

export const SearchInput = styled.input`
  margin: 24px 0 16px;
`;
