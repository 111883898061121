import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApplication } from '@hooks/useApplication';
import Klicksend from '@services/klicksend';
import { FirstStep } from './components/FirstStep';
import { SecondStep } from './components/SecondStep';
import { ThirdStep } from './components/ThirdStep';

export type CaptureProps = HTMLAttributes<HTMLDivElement> & {
  currentStep: number;
  properties: CaptureProperties;
  type: UseCaseType;
  onPropertiesChange: (props: Partial<CaptureProperties>) => void;
};

const CaptureComponent: React.FC<CaptureProps> = ({
  currentStep,
  properties,
  onPropertiesChange,
  type,
  ...attrs
}) => {
  const { setAppLoading } = useApplication();

  const [tags, setTags] = useState<KlicksendTag[]>([]);

  const handleChange = useCallback(
    ({ prop, value }: UseCaseModifierChange) => {
      onPropertiesChange({ [prop]: value });
    },
    [onPropertiesChange]
  );

  const getTags = useCallback(async () => {
    if (!type.startsWith('funnel')) {
      const tagData = await Klicksend.getTags();
      setTags(tagData.items);
    }
    setAppLoading(false);
  }, [setAppLoading, type]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const steps = useMemo(
    () => [
      <FirstStep
        title={properties.title}
        buttonText={properties.buttonText}
        onModifierChange={(change) => handleChange(change)}
        type={type}
        {...attrs}
      />,
      <SecondStep
        colors={properties.colorsConfig}
        onModifierChange={(change) => handleChange(change)}
        {...attrs}
      />,
      <ThirdStep
        tags={tags}
        formFields={properties.formFields}
        formName={properties.formName}
        formSigleOptIn={properties.formSigleOptIn}
        formTerms={properties.formTerms}
        formTag={properties.formTag}
        personalizeTag={properties.personalizeTag}
        onModifierChange={(change) => handleChange(change)}
        type={type}
        {...attrs}
      />,
    ],
    [
      attrs,
      handleChange,
      properties.buttonText,
      properties.colorsConfig,
      properties.formFields,
      properties.formName,
      properties.formSigleOptIn,
      properties.formTag,
      properties.formTerms,
      properties.personalizeTag,
      properties.title,
      tags,
      type,
    ]
  );

  return steps[currentStep] || steps[steps.length - 1];
};

export const Capture = memo(CaptureComponent);
