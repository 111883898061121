import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const applicationInitialState: ApplicationState = {
  appLoading: true,
  isPreviewMode: false,
};

const application = createSlice({
  name: 'application',
  initialState: applicationInitialState,
  reducers: {
    setAppLoading(state, action: PayloadAction<boolean>) {
      state.appLoading = action.payload;
    },
    setIsPreviewMode(state, action: PayloadAction<boolean>) {
      state.isPreviewMode = action.payload;
    },
  },
});

export const { setAppLoading, setIsPreviewMode } = application.actions;

export default application.reducer;
