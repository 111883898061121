import '@hotmart-org-ca/cosmos/dist/header';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/popover';

import { HTMLAttributes, memo, useCallback, useRef } from 'react';
import { addInnerPopoverStyle } from './helpers';

export type HoverPopoverComponentProps = HTMLAttributes<HTMLElement> & {
  bodyContent: React.ReactElement;
  buttonElement: React.ReactElement<HTMLButtonElement>;
  position: 'top' | 'bottom' | 'left' | 'right';
};

const HoverPopoverComponent: React.FC<HoverPopoverComponentProps> = ({
  bodyContent,
  buttonElement,
  position,
  ...attrs
}) => {
  const popoverRef = useRef<HotPopoverElement | null>(null);

  const handleMouseover = useCallback(() => {
    const popoverElement = popoverRef.current as HotPopoverElement;
    popoverElement.openPopover();
    addInnerPopoverStyle(popoverElement);
  }, [popoverRef]);

  const handleMouseLeave = useCallback(() => {
    const popoverElement = popoverRef.current as HotPopoverElement;
    popoverElement.closePopover();
  }, [popoverRef]);

  return (
    <hot-popover
      position={position}
      ref={popoverRef}
      onMouseLeave={() => handleMouseLeave()}
      onBlur={() => handleMouseLeave()}
      {...attrs}
    >
      <div slot="body">{bodyContent}</div>
      <div
        slot="button"
        onMouseOver={() => handleMouseover()}
        onFocus={() => handleMouseover()}
      >
        {buttonElement}
      </div>
    </hot-popover>
  );
};

export const HoverPopover = memo(HoverPopoverComponent);
