import styled from 'styled-components';

export const Button = styled.button`
  background: transparent !important;
  border: none !important;
  color: var(--gray-500) !important;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  outline: none !important;
  padding: 0;
  width: 20px;
`;
