import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import '@hotmart-org-ca/cosmos/dist/collapse';

import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosCollapse } from '@components/CosmosCollapse';
import { KlicksendFormTags } from '@components/KlicksendFormTags';
import { Container, StyledFormTerms } from './styles';

export type FormConfigProps = HTMLAttributes<HTMLDivElement> & {
  singleOptIn: boolean;
  formTerms: boolean;
  tags?: KlicksendTag[];
  selectedTag?: string;
  onFormTermsChanged: (value: boolean) => void;
  onFormSingleOptInChanged: (value: boolean) => void;
  onFormTagChanged: (value: KlicksendTag) => void;
};

const FormConfigComponent: React.FC<FormConfigProps> = ({
  singleOptIn,
  formTerms,
  tags = [],
  selectedTag,
  onFormTermsChanged,
  onFormSingleOptInChanged,
  onFormTagChanged,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleSelectChange = useCallback(
    (tagId: string) => {
      const tagToSave = tags.find((tag) => tag.id === +tagId)!;
      onFormTagChanged(tagToSave);
    },
    [onFormTagChanged, tags]
  );

  return (
    <Container {...attrs}>
      <CosmosCollapse label={t('form.klicksend.formCreation.optIn')} active>
        <StyledFormTerms
          formTerms={formTerms}
          formSigleOptIn={singleOptIn}
          onTermsChange={(value) => onFormTermsChanged(value)}
          onSingleOptInChange={(value) => onFormSingleOptInChanged(value)}
        />
        {tags && tags.length > 0 && (
          <KlicksendFormTags
            onTagSelected={(value) => handleSelectChange(value)}
            selectedTag={selectedTag}
            tags={tags}
          />
        )}
      </CosmosCollapse>
    </Container>
  );
};

export const FormConfig = memo(FormConfigComponent);
