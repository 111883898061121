import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import Files from '@services/files';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import { validateEmoji } from '@common/utils';
import { updateConfig } from '@store/slices/pageConfig';
import { AppError } from '@common/app-error';

export const savePageFiles = createAsyncThunk<
  void,
  void,
  {
    state: LocalState;
    rejectedMeta: string[];
  }
>(
  'pageInfo/savePageFiles',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      userInfo: {
        permissions: { templateManagementEnabled },
      },
      pageInfo: { mode, uuid, hasFontsConfig },
      pageConfig: { exitPopup: exitPopupState },
    } = getState();

    const { page, fontsConfig } = EditorEngineManager.getFinalFiles() || {};

    if (page) {
      try {
        validateEmoji(page);
        let exitPopup = exitPopupState;

        exitPopup =
          page.children?.find(
            (child) =>
              child.component === 'ls-popup' &&
              child['when-display'] === 'exitPopup'
          )?.uid || '';

        const config = {
          exitPopup,
          draft: true,
        };

        await Files.saveConfigFile(
          uuid,
          config,
          mode,
          templateManagementEnabled
        );
        await Files.savePageFile(uuid, page, mode, templateManagementEnabled);
        await Files.saveFontsConfigFile(
          uuid,
          fontsConfig || {},
          hasFontsConfig,
          mode,
          templateManagementEnabled
        );

        dispatch(updateConfig(config));
      } catch (error) {
        const appError =
          error instanceof AppError
            ? error
            : new AppError(`toastMessages.${mode}.error.saved`);
        throw rejectWithValue(appError.message, [appError.message]);
      }
    }
  }
);

export const savePageFilesBuilder = (
  builder: ActionReducerMapBuilder<PageInfoState>
) => {
  builder.addCase(savePageFiles.fulfilled, (state: PageInfoState) => {
    state.hasUnsavedChanges = false;
    state.savingStatus = 'success';
    state.savingStatusMessage = '';
  });
  builder.addCase(savePageFiles.rejected, (state: PageInfoState, action) => {
    const savingStatusMessage = action.meta[0];
    state.savingStatus = 'error';
    state.savingStatusMessage = savingStatusMessage;
  });
};
