import EventAgent from '@hotmart/event-agent-js';
import { useLocation } from 'react-router-dom';
import User from '@services/user';
import Klickart from '@services/klickart';
import { useUserInfo } from '@hooks/useUserInfo';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useCallback } from 'react';

EventAgent.mode =
  process.env.REACT_APP_ENV === 'development'
    ? 'staging'
    : (process.env.REACT_APP_ENV as 'production' | 'staging');

export const useDataHub = () => {
  const { trialEventsEnabled } = useFeatureFlags();
  const path = useLocation().pathname;
  const { ucode } = useUserInfo().userProfile;

  const sendMetrify = useCallback(
    ({ element, group, name, params, system }: SendMetrifyOptions) => {
      EventAgent.sendMetrify(
        {
          system: system || 'app_saas_editor_web',
          event: {
            group,
            name,
            path,
            params,
            queryParams: '',
          },
        },
        element
      );
    },
    [path]
  );

  const sendTrialMetrify = useCallback(
    async (group: string, name: string, params: string) => {
      const { used, alreadyPayingUser } =
        await Klickart.getUserTrialSponsorInfo();
      if (!trialEventsEnabled || !ucode || used || alreadyPayingUser) {
        return;
      }
      EventAgent.sendMetrify({
        system: 'lead_solutions',
        event: {
          group,
          name,
          path,
          params,
          queryParams: '',
          user: { ucode },
        },
      });
    },
    [path, trialEventsEnabled, ucode]
  );

  const sendUserFrequencyEvent = useCallback(
    async (currentPage: string, userProfile: UserProfile) => {
      const { id: userId, ucode: userUcode } = userProfile;

      if (!userUcode) {
        return;
      }

      const frequencyEvent: UserFrequencyEvent = {
        userId,
        userUcode,
        currentPage,
        system: 'Editor',
      };

      const { paymentInfo, subscription } =
        await User.getUserFrequencyAccessInfo();

      if (paymentInfo) {
        frequencyEvent.paymentInfo = paymentInfo;
      }

      if (subscription) {
        frequencyEvent.subscription = subscription;
      }

      const sendEventParams: SendUserFrequencyEvent = {
        system: 'api_pro',
        entity: 'access_frequency',
        action: 'create',
        event_version: '1.1',
        event: frequencyEvent,
      };

      EventAgent.send(sendEventParams);
    },
    []
  );

  return { sendTrialMetrify, sendMetrify, sendUserFrequencyEvent };
};
