import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import { CosmosButton } from '@components/CosmosButton';
import { Description, FooterButtonsWrapper, Title } from './styles';

type BaseModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onCancelButtonClicked: () => void;
  onClose: () => void;
};

const CancelAiModalComponent: React.FC<BaseModalProps> = ({
  isOpen,
  onCancelButtonClicked,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const hotSidebar = document.querySelector('hot-sidebar') as HotSidebarElement;

  const handleCancelButtonClick = useCallback(() => {
    hotSidebar!.onConfirmClose = () => true;
    onCancelButtonClicked();
    onClose();
  }, [hotSidebar, onCancelButtonClicked, onClose]);

  const handleContinueButtonClick = useCallback(() => {
    hotSidebar!.onConfirmClose = () => false;
    onClose();
  }, [hotSidebar, onClose]);

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      header={<Title> {t('pageWizard.salesPage.ai.cancelModal.title')}</Title>}
      footer={
        <FooterButtonsWrapper>
          <CosmosButton
            label={t('pageWizard.salesPage.ai.cancelModal.cancelButton')}
            onButtonClicked={handleCancelButtonClick}
            type="secondary"
            size="md"
          />
          <CosmosButton
            label={t('pageWizard.salesPage.ai.cancelModal.continueButton')}
            onButtonClicked={handleContinueButtonClick}
            type="primary"
            size="md"
          />
        </FooterButtonsWrapper>
      }
      {...attrs}
    >
      <Description>
        {t('pageWizard.salesPage.ai.cancelModal.description')}
      </Description>
    </BaseModal>
  );
};

export const CancelAiModal = memo(CancelAiModalComponent);
