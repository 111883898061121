import { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import MediaCenter from '@services/mediaCenter';
import Toast from '@services/toast';
import { Container, ButtonIcon, Label, Input } from './styles';

export type ImageUploadButtonProps = HTMLAttributes<HTMLDivElement> & {
  buttonType?: 'primary' | 'secondary' | 'tertiary';
  fullWidth?: boolean;
  label: string;
  onImageChange?: (url: string) => void;
};

export const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
  buttonType = 'primary',
  fullWidth,
  label,
  onImageChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleImageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { files } = target;

      if (!files) return;
      const file = files[0];

      target.value = '';

      if (!MediaCenter.isValidMedia(file)) {
        Toast.error('toastMessages.media.error.unsupportedFormat');
        return;
      }

      try {
        setLoading(true);

        const { url } = await MediaCenter.uploadMedia(file);
        onImageChange?.(url);

        Toast.success('toastMessages.general.success.saved');
      } catch {
        Toast.error('toastMessages.general.error.saved');
      } finally {
        setLoading(false);
      }
    },
    [onImageChange]
  );

  return (
    <Container fullWidth={fullWidth} {...attrs}>
      <Label
        fullWidth={fullWidth}
        className={classNames(`hot-button hot-button--${buttonType}`, {
          'hot-button--loading': loading,
        })}
      >
        <ButtonIcon className="fal fa-upload" />
        {t(label)}
        <Input
          type="file"
          name="file"
          id="file"
          aria-hidden
          accept="image/*"
          onChange={(event) => handleImageChange(event)}
        />
      </Label>
    </Container>
  );
};
