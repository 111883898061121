import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar } from '@components/Sidebar';
import { useUseCases } from '@hooks/useUseCases';
import Toast from '@services/toast';
import { CancelAiModal } from '@pages/PageWizard/components/CancelAiModal';
import { AiSideBarHeader } from '@pages/PageWizard/components/AiSidebarHeader';
import { AiSidebarFooter } from '@pages/PageWizard/components/AiSidebarFooter';
import {
  AiSidebarBody,
  CaptureAiInputValues,
} from './components/AiSidebarBody';

type AiSidebarProps = HTMLAttributes<HTMLElement> & {
  isOpen: boolean;
  type: UseCaseType;
  onClosed: () => void;
  onUseAiDescription: (generatedDescription: string) => void;
};

const AiSidebarComponent: React.FC<AiSidebarProps> = ({
  isOpen,
  onClosed,
  type,
  onUseAiDescription,
}) => {
  const { generateCapturePageAiDescription, properties } = useUseCases();
  const { t } = useTranslation();

  const [aiInputValues, setAiInputValues] = useState<CaptureAiInputValues>({
    benefit: '',
    customType: '',
    type: '',
  });
  const [aiLoading, setAiLoading] = useState<boolean>(false);
  const [generatedDescriptions, setGeneratedDescriptions] = useState<string[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [cancelSource, setCancelSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const clearStates = useCallback(() => {
    setCancelSource(undefined);
    setAiInputValues({
      benefit: '',
      customType: '',
      type: '',
    });
    setGeneratedDescriptions([]);
    setSelectedOption('');
  }, []);

  const handleGenerate = useCallback(
    async (values: CaptureAiInputValues, generatedOptions?: UseCaseOptions) => {
      try {
        const { source, promise } = generateCapturePageAiDescription(
          values.type,
          values.benefit,
          generatedOptions
        );

        setAiLoading(true);
        setCancelSource(source);

        const { output } = await promise;

        setGeneratedDescriptions(output.title);
        setSelectedOption(output.title[0]);
      } catch (e) {
        const error = e as Error;

        if (error.message !== 'request_canceled_by_user') {
          Toast.error(t('toastMessages.general.error.generic'));
        }
      } finally {
        setAiLoading(false);
      }
    },
    [generateCapturePageAiDescription, t]
  );

  const handleRecreate = useCallback(() => {
    setGeneratedDescriptions([]);
    setSelectedOption('');
  }, []);

  const handleAiGeneratedDescription = useCallback(() => {
    onUseAiDescription(selectedOption);
    clearStates();
    onClosed();
  }, [onUseAiDescription, selectedOption, clearStates, onClosed]);

  const handleCancelProcess = useCallback(() => {
    cancelSource?.cancel('request_canceled_by_user');
    clearStates();
    onClosed();
  }, [clearStates, onClosed, cancelSource]);

  const handleConfirmClose = useCallback(() => {
    if (aiLoading) {
      setIsModalOpen(true);
      return false;
    }
    return true;
  }, [aiLoading]);

  const handleClose = useCallback(() => {
    onClosed();
    clearStates();
  }, [clearStates, onClosed]);

  const handleChange = useCallback((values: CaptureAiInputValues) => {
    setAiInputValues(values);
  }, []);

  const handleSelectedOption = useCallback((selected: string) => {
    setSelectedOption(selected);
  }, []);

  const generateTitlesFunnelProperties = useCallback(() => {
    const { rewardDescription, rewardKind } = properties;

    if (
      rewardDescription &&
      rewardKind &&
      rewardDescription !== aiInputValues.benefit &&
      rewardKind !== aiInputValues.type
    ) {
      handleGenerate({
        benefit: rewardDescription,
        type: rewardKind,
        customType: '',
      });
    }
  }, [aiInputValues.benefit, aiInputValues.type, handleGenerate, properties]);

  useEffect(() => {
    if (!aiLoading && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [aiLoading, isModalOpen]);

  useEffect(() => {
    if (isOpen && type === 'funnel_capture_publish_lead_capture_page') {
      generateTitlesFunnelProperties();
    }
  }, [isOpen, generateTitlesFunnelProperties, type]);

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        onConfirmClose={handleConfirmClose}
        onClose={handleClose}
        header={<AiSideBarHeader />}
        footer={
          !aiLoading && (
            <AiSidebarFooter
              hideRecreateButton={
                type === 'funnel_capture_publish_lead_capture_page'
              }
              onRecreateButtonClicked={() => handleRecreate()}
              onUseButtonClicked={() => handleAiGeneratedDescription()}
              disabledButtons={{
                recreate: !selectedOption,
                use: !selectedOption,
              }}
            />
          )
        }
      >
        <AiSidebarBody
          aiInputValues={aiInputValues}
          generatedOptions={generatedDescriptions}
          selectedOption={selectedOption}
          isLoading={aiLoading}
          onSelectedChange={handleSelectedOption}
          onGenerate={(generatedOptions) =>
            handleGenerate(aiInputValues, generatedOptions)
          }
          onChange={handleChange}
          type={type}
        />
      </Sidebar>

      <CancelAiModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancelButtonClicked={() => handleCancelProcess()}
      />
    </>
  );
};

export const AiSidebar = memo(AiSidebarComponent);
