import styled from 'styled-components';

export const TextInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  & * {
    width: 100%;
  }
`;
