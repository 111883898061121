import '@hotmart-org-ca/cosmos/dist/modal';
import '@hotmart-org-ca/cosmos/dist/modal/modal-header';
import '@hotmart-org-ca/cosmos/dist/modal/modal-body';
import '@hotmart-org-ca/cosmos/dist/modal/modal-footer';

import {
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal-portal');
document.body.appendChild(modalRoot);

type ModalSize = 'large' | 'medium' | 'small';

export type ModalProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  isOpen: boolean;
  header?: React.ReactNode;
  headerClass?: string;
  footer?: React.ReactNode;
  divider?: boolean;
  contentScroll?: boolean;
  bodyClass?: string;
  footerClass?: string;
  size?: ModalSize;
  fixedSize?: string;
  closeIconAbsolute?: boolean;
  onClose?: () => void;
};

const BaseModalComponent: React.FC<ModalProps> = ({
  isOpen,
  header,
  headerClass,
  footer,
  children,
  onClose,
  divider = false,
  contentScroll = false,
  bodyClass = '',
  footerClass = '',
  size = 'small',
  fixedSize,
  closeIconAbsolute = false,
  ...attrs
}) => {
  const hotModal = useRef<HTMLElement | null>(null);

  const el = useMemo(() => document.createElement('div'), []);
  const modalSize = useMemo<Record<ModalSize, string>>(
    () => ({
      small: '500px',
      medium: '848px',
      large: '1196px',
    }),
    []
  );

  useEffect(() => {
    modalRoot.appendChild(el);

    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  useEffect(() => {
    if (isOpen) {
      const modal = hotModal?.current;
      const modalConcrete =
        modal?.shadowRoot?.querySelector<HTMLElement>('hot-modal-concrete');

      const modalDialog =
        modalConcrete?.shadowRoot?.querySelector<HTMLElement>(
          '.hot-modal__dialog'
        );

      if (modalDialog) {
        modalDialog.style.maxWidth = fixedSize || modalSize[size];
      }
    }
  }, [fixedSize, isOpen, modalSize, size]);

  useEffect(() => {
    const modal = hotModal?.current;
    const modalHeader = modal?.querySelector('hot-modal-header');
    const closeIcon =
      modalHeader?.shadowRoot?.querySelector<HTMLElement>('.hot-modal__close');

    if (closeIcon) {
      if (closeIconAbsolute) {
        closeIcon.style.position = 'absolute';
        closeIcon.style.right = '24px';
        closeIcon.style.top = '24px';
      } else {
        closeIcon.style.marginBottom = 'auto';
        closeIcon.style.marginLeft = 'auto';
      }
    }
  }, [closeIconAbsolute]);

  useEffect(() => {
    const modal = hotModal?.current;

    if (modal && onClose) {
      modal.addEventListener('close', onClose);
    }

    return () => {
      if (modal && onClose) {
        modal.removeEventListener('close', onClose);
      }
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <hot-modal
      ref={hotModal}
      open={isOpen === true || undefined}
      position="centered"
      {...attrs}
    >
      {header && (
        <hot-modal-header
          class={classNames(headerClass, {
            'hot-modal-header--divider': divider,
          })}
        >
          {header}
        </hot-modal-header>
      )}

      <hot-modal-body
        class={classNames([
          {
            'hot-modal-body--scroll': contentScroll,
            'hot-modal-body--no-divider': !divider,
            'hot-modal-body--no-header': !header,
            'hot-modal-body--no-footer': !footer,
          },
          bodyClass,
        ])}
      >
        {children}
      </hot-modal-body>

      {footer && (
        <hot-modal-footer
          class={classNames('hot-modal-footer', footerClass, {
            'hot-modal-footer--divider': divider,
          })}
        >
          {footer}
        </hot-modal-footer>
      )}
    </hot-modal>,
    el
  );
};

export const BaseModal = memo(BaseModalComponent);
