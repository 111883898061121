import { v4 as uuid } from 'uuid';

export const footerSections: PageFragmentListItem[] = [
  {
    id: 'footer-6',
    name: 'Footer',
    template: {
      background: '#000000',
      'background-is-webp': false,
      tag: 'section',
      'is-fixed': false,
      'end-date': '2023-07-21T15:02:50',
      timezone: '-03:00_utcMinus0300MainCities',
      'time-on-page': '5',
      'time-on-page-unit': 'seconds',
      'delay-type': 'disabled',
      padding: '0px 0px 0px 0px',
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Rodapé',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': false,
              desktop: {},
              tablet: {
                padding: '30px 0px 35px 0px',
              },
              mobile: {
                padding: '20px 0px 24px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Copyright ©</span></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Todos os direitos reservados.</span></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Política de Privacidade | Termos de Uso</span></p>',
                  'max-width': '50%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Copyright ©</span></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Todos os direitos reservados.</span></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Política de Privacidade | Termos de Uso</span></p>',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 12px;">Copyright ©</span></p><p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 12px;">Todos os direitos reservados.</span></p><p style="text-align: center; line-height: 1.15;"><br></p><p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 12px;">Política de Privacidade | Termos de Uso</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'footer-7',
    name: 'Footer',
    template: {
      background: '#393939',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 1',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '25px 0px 20px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '30px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '30px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Copyright © 2022</span></p><p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Todos os direitos reservados.</span></p>',
                  'max-width': '40%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 12px;">Copyright © 2022</span></p><p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 12px;">Todos os direitos reservados.</span></p>',
                    'max-width': '60%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'footer-8',
    name: 'Footer',
    template: {
      background: '#F2F2F2',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '56px 0px 56px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '56px 0px 0px 0px',
              },
              mobile: {
                padding: '32px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '40px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '280px',
                    height: '35px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5367466/2-fantastica-fabrica-da-renda-extra.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5367466/thumb_2-fantastica-fabrica-da-renda-extra.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 0px 40px 0px',
              },
              mobile: {
                padding: '10px 0px 22px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: right;"><span style="font-family: Montserrat, sans-serif; color: rgb(101, 95, 159); font-size: 14px;">Copyright © 2022</span></p><p style="text-align: right;"><span style="font-family: Montserrat, sans-serif; color: rgb(101, 95, 159); font-size: 14px;">Todos os direitos reservados.</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(101, 95, 159); font-size: 14px;">Copyright © 2022</span></p><p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(101, 95, 159); font-size: 14px;">Todos os direitos reservados.</span></p>',
                    'max-width': '70%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Footer',
        templateId: 'footer-2',
        templateName: 'Footer',
      },
    },
  },
  {
    id: 'footer-9',
    name: 'Footer',
    template: {
      background: '#14133A',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 9',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '30px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '0px 0px 0px 0px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '30px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                  metadata: {},
                },
                {
                  padding: '5px 0px 0px 8px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '40%',
                  margin: '05px 0px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '35px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 8px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '30px',
                        'max-width': '35px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399481/instagram.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399481/thumb_instagram.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '35px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 8px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '30px',
                        'max-width': '35px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399483/facebook.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399483/thumb_facebook.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '35px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 8px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '30px',
                        'max-width': '35px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399485/twitter.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399485/thumb_twitter.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '35px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 8px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '30px',
                        'max-width': '35px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399487/youtube.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399487/thumb_youtube.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '35px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 8px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '30px',
                        'max-width': '35px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399489/linkedin.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399489/thumb_linkedin.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '35px',
                      href: '',
                      'max-width': '40px',
                      margin: '0px 8px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '30px',
                        'max-width': '35px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399490/email.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399490/thumb_email.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="font-size: 12px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">Copyright © 2022  –  Todos os direitos reservados.</span></p>',
                  'max-width': '40%',
                  padding: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '90%',
                    padding: '0px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'footer-5',
    name: 'Footer',
    template: {
      background: '#141436',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 16',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '30px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '30px 0px 0px 0px',
              },
              mobile: {
                padding: '35px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '30px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5285939/seu-logo-aqui-branco.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5285939/thumb_seu-logo-aqui-branco.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 10px 0px',
              },
              mobile: {
                padding: '0px 0px 24px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'end',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '450px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'align-items': 'center',
                    'justify-content': 'center',
                    margin: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '240px',
                    padding: '0px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: 'instagram.com/',
                      'max-width': '40px',
                      margin: '0px 5px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '30px',
                        height: '30px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399481/instagram.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399481/thumb_instagram.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: 'facebook.com/',
                      'max-width': '40px',
                      margin: '0px 5px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '30px',
                        height: '30px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399483/facebook.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399483/thumb_facebook.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: 'twitter.com/',
                      'max-width': '40px',
                      margin: '0px 5px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '30px',
                        height: '30px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399485/twitter.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399485/thumb_twitter.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: 'youtube.com/',
                      'max-width': '40px',
                      margin: '0px 5px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '30px',
                        height: '30px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399487/youtube.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399487/thumb_youtube.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: 'linkedin.com/',
                      'max-width': '40px',
                      margin: '0px 5px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '30px',
                        height: '30px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399489/linkedin.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399489/thumb_linkedin.png',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: 'mailto:',
                      'max-width': '40px',
                      margin: '0px 5px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '30px',
                        height: '30px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5399490/email.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5399490/thumb_email.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'footer-4',
    name: 'Footer',
    template: {
      background: '#615B9B',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '40px 0px 10px 0px',
              },
              mobile: {
                padding: '32px 0px 10px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '40px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '30px',
                    'max-width': '250px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5367466/2-fantastica-fabrica-da-renda-extra.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5367466/thumb_2-fantastica-fabrica-da-renda-extra.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: right;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Copyright © 2022</span></p><p style="text-align: right;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Todos os direitos reservados.</span></p>',
                  'max-width': '100%',
                  padding: '10px 0px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Copyright © 2022</span></p><p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">Todos os direitos reservados.</span></p>',
                    'max-width': '60%',
                  },
                  mobile: {
                    padding: '10px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'Footer',
        templateId: 'footer-2',
        templateName: 'Footer',
      },
    },
  },
  {
    id: 'footer-1',
    name: 'Footer',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '22px 142px 22px 142px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'start',
                'flex-direction': 'row',
                padding: '22px 22px 22px 22px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '18px',
                  'max-width': '123px',
                  margin: '0px 0px 0px 0px',
                  src: '//static-public.klickpages.com.br/uploads/media/file/4816514/ativo_1_1.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '<p style="text-align: right; font-family: Roboto, sans-serif; font-size: 14px; line-height: 1.15;"><span style="font-family: Roboto, sans-serif; font-size: 14px;">Copyright</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'footer-2',
    name: 'Footer',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5224516/group_74.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5224516/thumb_group_74.png',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: right;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eCopyright [nome]\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 12' },
    },
  },
  {
    id: 'footer-3',
    name: 'Footer',
    template: {
      background: '#F7F9FA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'row' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 30px 20px' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '160px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5280678/seu-logo-aqui.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5280678/thumb_seu-logo-aqui.png',
                },
              ],
            },
            {
              padding: '20px 0px 30px 0px',
              'justify-content': 'start',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 20px 30px 0px' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: right;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eCopyright © 2022\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: right;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eTodos os direitos reservados.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eCopyright © 2022\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px;"\u003eTodos os direitos reservados.\u003c/span\u003e\u003c/p\u003e',
                    padding: '0px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 4' },
    },
  },
];
