import React, { HTMLAttributes, memo, useMemo } from 'react';
import { Icons, icons } from './icons';
import { StyledIcon } from './styles';

export type IconProps = HTMLAttributes<HTMLSpanElement> & {
  name: Icons;
  color?: string;
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
};

const IconComponent: React.FC<IconProps> = ({
  color = 'var(--gray-500)',
  name,
  size = 24,
  onClick,
  ...attrs
}) => {
  const IconTag = useMemo(() => icons[name], [name]);

  return (
    <StyledIcon
      hasClick={Boolean(onClick)}
      onClick={onClick}
      size={size}
      {...attrs}
    >
      <IconTag color={color} role="img" />
    </StyledIcon>
  );
};

export const Icon = memo(IconComponent);
