import { v4 as uuid } from 'uuid';

export const linkInBioSections: PageFragmentListItem[] = [
  {
    id: 'link-in-bio-1',
    name: 'Link in Bio',
    template: {
      background: '#B3ECE8',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background:
            "url('https://static-public.klickpages.com.br/uploads/media/file/4888407/fundo-cabealho.png') center/cover",
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '42px 10px 10px 10px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {},
              mobile: {
                background:
                  'url(https://static-public.klickpages.com.br/uploads/media/file/4888407/fundo-cabealho.png) center/cover',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#FFFFFF',
                  'border-radius': '80px 80px 80px 80px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '155px',
                  'max-width': '155px',
                  margin: '0px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4885585/perfil.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '\u003ch3 style="text-align: center; font-size: 27px;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 26px;"\u003eProfile Title\u003c/strong\u003e\u003c/h3\u003e',
                  'max-width': '97%',
                  padding: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003ch3 style="text-align: center; font-size: 21px; font-weight: 500;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 21px;"\u003eBio\u003c/span\u003e\u003c/h3\u003e',
                  'max-width': '97%',
                  padding: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '10px 0px 10px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301640/pngitem_502903.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301640/thumb_pngitem_502903.png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301621/pngitem_300674_(1).png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301621/thumb_pngitem_300674_(1).png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301634/pngitem_202417.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301634/thumb_pngitem_202417.png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '30px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'cover',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301637/pngitem_14050.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301637/thumb_pngitem_14050.png',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#3C3C3C',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '20px',
                  'hover-background': '#DFDFDF',
                  'hover-color': '#3C3C3C',
                  'has-icon': false,
                  href: 'www.instagram.com',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 1',
                  margin: '10px 0px 10px 0px',
                  'max-width': '348px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#3C3C3C',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '20px',
                  'hover-background': '#DFDFDF',
                  'hover-color': '#3C3C3C',
                  'has-icon': false,
                  href: 'www.instagram.com',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 2',
                  margin: '10px 0px 10px 0px',
                  'max-width': '348px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#3C3C3C',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '20px',
                  'hover-background': '#DFDFDF',
                  'hover-color': '#3C3C3C',
                  'has-icon': false,
                  href: 'www.instagram.com',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 3',
                  margin: '10px 0px 10px 0px',
                  'max-width': '348px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#3C3C3C',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '20px',
                  'hover-background': '#DFDFDF',
                  'hover-color': '#3C3C3C',
                  'has-icon': false,
                  href: 'www.instagram.com',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 4',
                  margin: '10px 0px 10px 0px',
                  'max-width': '348px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '90px',
                  'max-width': '226px',
                  margin: '62px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4890066/made-in-klickpage2.png',
                  'object-fit': 'none',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'link-in-bio-2',
    name: 'Link in Bio',
    template: {
      background: '#BEFDA7',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '12px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#4FD254',
                  'border-radius': '80px 80px 80px 80px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '155px',
                  'max-width': '155px',
                  margin: '0px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4889411/perfil_2.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '\u003cp style="text-align: center; font-family: Oswald, sans-serif; font-size: 20px; font-weight: 500;"\u003e\u003cspan style="font-family: Oswald, sans-serif; font-size: 19px;"\u003eProfile Title\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '8px 0px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '16px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '30px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301640/pngitem_502903.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301640/thumb_pngitem_502903.png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301621/pngitem_300674_(1).png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301621/thumb_pngitem_300674_(1).png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301634/pngitem_202417.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301634/thumb_pngitem_202417.png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 0px 0px 16px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301637/pngitem_14050.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301637/thumb_pngitem_14050.png',
                    },
                  ],
                },
                {
                  text: '\u003cp style="text-align: center; font-family: Oswald, sans-serif; font-size: 20px; font-weight: 500;"\u003eBio\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '28px 0px 7px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 80px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 20px 0px 20px' },
              mobile: { padding: '0px 16px 0px 16px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '21px 40px 40px 21px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#000000',
                  'max-width': '747px',
                  margin: '0px 0px 32px 0px',
                  desktop: {},
                  tablet: { background: '#000000' },
                  mobile: { background: '#000000' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '52px',
                      'max-width': '84px',
                      margin: '0px 0px 0px 0px',
                      src: 'https://static-public.klickpages.com.br/uploads/media/file/4898677/icone-1.png',
                      'object-fit': 'none',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                    },
                    {
                      color: '#8EE291',
                      background: '#00000000',
                      'border-color': '#7CD17F',
                      'border-radius': '21px 21px 21px 21px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Oswald, sans-serif',
                      'font-size': '24px',
                      'hover-background': '#FFFFFF57',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'LINK 1',
                      margin: '0px 0px 0px 0px',
                      'max-width': '663px',
                      mobile: { padding: '6px 0px 6px 0px' },
                      padding: '6px 0px 6px 0px',
                      tablet: { padding: '6px 74px 6px 0px' },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '21px 21px 21px 21px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#8EE291',
                  'max-width': '687px',
                  margin: '0px 0px 20px 0px',
                  desktop: {},
                  tablet: { background: '#8EE291' },
                  mobile: { background: '#8EE291' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '52px',
                      'max-width': '84px',
                      margin: '0px 0px 0px 0px',
                      src: 'https://static-public.klickpages.com.br/uploads/media/file/4898679/icone-3.png',
                      'object-fit': 'none',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                    },
                    {
                      color: '#000000',
                      background: '#00000000',
                      'border-color': '#000000',
                      'border-radius': '21px 21px 21px 21px',
                      'border-style': 'solid',
                      'border-top-width': '1px',
                      'border-right-width': '1px',
                      'border-bottom-width': '1px',
                      'border-left-width': '1px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Oswald, sans-serif',
                      'font-size': '24px',
                      'hover-background': '#FFFFFF57',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'LINK 2',
                      margin: '0px 0px 0px 0px',
                      'max-width': '600px',
                      mobile: { padding: '6px 0px 6px 0px' },
                      padding: '6px 0px 6px 0px',
                      tablet: { padding: '6px 74px 6px 0px' },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '21px 21px 21px 21px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#8EE291',
                  'max-width': '687px',
                  margin: '0px 0px 20px 0px',
                  desktop: {},
                  tablet: { background: '#8EE291' },
                  mobile: { background: '#8EE291' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '52px',
                      'max-width': '84px',
                      margin: '0px 0px 0px 0px',
                      src: 'https://static-public.klickpages.com.br/uploads/media/file/4898681/icone-4.png',
                      'object-fit': 'none',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                    },
                    {
                      color: '#000000',
                      background: '#00000000',
                      'border-color': '#000000',
                      'border-radius': '21px 21px 21px 21px',
                      'border-style': 'solid',
                      'border-top-width': '1px',
                      'border-right-width': '1px',
                      'border-bottom-width': '1px',
                      'border-left-width': '1px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Oswald, sans-serif',
                      'font-size': '24px',
                      'hover-background': '#FFFFFF57',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'LINK 3',
                      margin: '0px 0px 0px 0px',
                      'max-width': '600px',
                      mobile: { padding: '6px 0px 6px 0px' },
                      padding: '6px 0px 6px 0px',
                      tablet: { padding: '6px 74px 6px 0px' },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '21px 21px 21px 12px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#8EE291',
                  'max-width': '687px',
                  margin: '0px 0px 20px 0px',
                  desktop: {},
                  tablet: {
                    background: '#8EE291',
                    'border-radius': '21px 21px 21px 21px',
                  },
                  mobile: { background: '#8EE291' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '52px',
                      'max-width': '84px',
                      margin: '0px 0px 0px 0px',
                      src: 'https://static-public.klickpages.com.br/uploads/media/file/4898683/icone-5.png',
                      'object-fit': 'none',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                    },
                    {
                      color: '#000000',
                      background: '#00000000',
                      'border-color': '#000000',
                      'border-radius': '21px 21px 21px 21px',
                      'border-style': 'solid',
                      'border-top-width': '1px',
                      'border-right-width': '1px',
                      'border-bottom-width': '1px',
                      'border-left-width': '1px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Oswald, sans-serif',
                      'font-size': '24px',
                      'hover-background': '#FFFFFF57',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'LINK 4',
                      margin: '0px 0px 0px 0px',
                      'max-width': '600px',
                      mobile: { padding: '6px 0px 6px 0px' },
                      padding: '6px 0px 6px 0px',
                      tablet: { padding: '6px 74px 6px 0px' },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '21px 21px 21px 21px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#8EE291',
                  'max-width': '687px',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: { background: '#8EE291' },
                  mobile: { background: '#8EE291' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '52px',
                      'max-width': '84px',
                      margin: '0px 0px 0px 0px',
                      src: 'https://static-public.klickpages.com.br/uploads/media/file/4898678/icone-2.png',
                      'object-fit': 'none',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                    },
                    {
                      color: '#000000',
                      background: '#00000000',
                      'border-color': '#000000',
                      'border-radius': '21px 21px 21px 21px',
                      'border-style': 'solid',
                      'border-top-width': '1px',
                      'border-right-width': '1px',
                      'border-bottom-width': '1px',
                      'border-left-width': '1px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Oswald, sans-serif',
                      'font-size': '24px',
                      'hover-background': '#FFFFFF57',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'LINK 5',
                      margin: '0px 0px 0px 0px',
                      'max-width': '600px',
                      mobile: { padding: '6px 0px 6px 0px' },
                      padding: '6px 0px 6px 0px',
                      tablet: { padding: '6px 74px 6px 0px' },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '10px 10px 10px 10px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background:
                'url(https://static-public.klickpages.com.br/uploads/media/file/4889510/fundo-abstrato.png) center/cover',
              desktop: {},
              tablet: {
                background:
                  'url(https://static-public.klickpages.com.br/uploads/media/file/4889510/fundo-abstrato.png) center/cover',
                'align-items': 'center',
                'justify-content': 'end',
              },
              mobile: {
                background:
                  'url(https://static-public.klickpages.com.br/uploads/media/file/4889510/fundo-abstrato.png) center/cover',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '90px',
                  'max-width': '226px',
                  margin: '62px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4890066/made-in-klickpage2.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'link-in-bio-3',
    name: 'Link in Bio',
    template: {
      background: '#FFBA78',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '10px 10px 0px 10px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#FF6300',
                  'border-radius': '80px 80px 80px 80px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '155px',
                  'max-width': '155px',
                  margin: '0px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891510/perfil-3.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '\u003cp style="text-align: center; font-size: 22px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-weight: 700;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 23px;"\u003eProfile Title\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; font-size: 18px; font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif;"\u003eBio\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '4px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: 'none',
                  'max-width': '250px',
                  margin: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301640/pngitem_502903.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301640/thumb_pngitem_502903.png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301621/pngitem_300674_(1).png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301621/thumb_pngitem_300674_(1).png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301634/pngitem_202417.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301634/thumb_pngitem_202417.png',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '30px',
                      href: '',
                      'max-width': '46px',
                      margin: '0px 16px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5301637/pngitem_14050.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5301637/thumb_pngitem_14050.png',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '12px 0px 12px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  autoplay: true,
                  'border-color': '#FF6300',
                  'border-radius': '18px 18px 18px 18px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '315px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/04WvfNSImsE',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '8px 0px 8px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#000000',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '25px 25px 25px 25px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '19px',
                  'hover-background': '#FFA13B',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 1',
                  margin: '5px 0px 8px 0px',
                  'max-width': '320px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#000000',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '25px 25px 25px 25px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '19px',
                  'hover-background': '#FFA13B',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 2',
                  margin: '5px 0px 8px 0px',
                  'max-width': '320px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#000000',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '25px 25px 25px 25px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '19px',
                  'hover-background': '#FFA13B',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 3',
                  margin: '5px 0px 8px 0px',
                  'max-width': '320px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#000000',
                  background: '#FFFFFF',
                  'border-color': '#EF4E23',
                  'border-radius': '25px 25px 25px 25px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '19px',
                  'hover-background': '#FFA13B',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 4',
                  margin: '5px 0px 8px 0px',
                  'max-width': '320px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '90px',
                  'max-width': '226px',
                  margin: '62px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4890066/made-in-klickpage2.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'link-in-bio-4',
    name: 'Link in Bio',
    template: {
      background: '#DFDFDF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '26px 0px 10px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#FFFFFF',
                  'border-radius': '80px 80px 80px 80px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '155px',
                  'max-width': '155px',
                  margin: '0px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891951/perfil_4.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-size: 16px; font-family: Montserrat, sans-serif;"\u003eProfile Title\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-size: 16px; font-family: Montserrat, sans-serif;"\u003eBio\u003c/span\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cstrong style="font-size: 16px; font-family: Montserrat, sans-serif;"\u003eSOBRE MIM:\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '63%',
                  padding: '17px 0px 53px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 20px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '3px 3px 3px 3px',
                  'border-radius': '50px 50px 50px 50px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background: '#FFFFFF',
                  'max-width': '198px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '0px 0px 0px 0px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      'max-width': '34px',
                      margin: '0px 11px 0px 0px',
                      src: 'https://static-public.klickpages.com.br/uploads/media/file/4899102/instagram_2.png',
                      'object-fit': 'none',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                    },
                    {
                      color: '#000000',
                      background: '#EF4E2300',
                      'border-color': '#EF4E23',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '20px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'INSTAGRAM',
                      margin: '0px 0px 0px 0px',
                      'max-width': '125px',
                      mobile: {},
                      padding: '0px 0px 0px 0px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  'max-width': '200px',
                  margin: '5px 5px 5px 5px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891944/feed-rede-social-1.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  'max-width': '200px',
                  margin: '5px 5px 5px 5px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891945/feed-rede-social-2.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  'max-width': '200px',
                  margin: '5px 5px 5px 5px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891948/feed-rede-social-5.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  'max-width': '200px',
                  margin: '5px 5px 5px 5px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891947/feed-rede-social-4.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  'max-width': '200px',
                  margin: '5px 5px 5px 5px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891946/feed-rede-social-3.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  'max-width': '200px',
                  margin: '5px 5px 5px 5px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4891950/feed-rede-social-6.png',
                  'object-fit': 'cover',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '12px 0px 12px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#000000',
                  background: '#43C2FB',
                  'border-color': '#EF4E23',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': '#AFAFAF',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 1',
                  margin: '0px 3px 0px 3px',
                  'max-width': '148px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#000000',
                  background: '#4CAF50',
                  'border-color': '#EF4E23',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': '#AFAFAF',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 2',
                  margin: '0px 3px 0px 3px',
                  'max-width': '148px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#000000',
                  background: '#F7BBFF',
                  'border-color': '#EF4E23',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': '#AFAFAF',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 3',
                  margin: '0px 3px 0px 3px',
                  'max-width': '148px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
                {
                  color: '#000000',
                  background: '#FDD638',
                  'border-color': '#EF4E23',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': '#AFAFAF',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'LINK 4',
                  margin: '0px 3px 0px 3px',
                  'max-width': '148px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '90px',
                  'max-width': '226px',
                  margin: '62px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4890066/made-in-klickpage2.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'link-in-bio-5',
    name: 'Link in Bio',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5301720/fundo-moca_(1).png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '61px 10px 10px 10px',
              'justify-content': 'end',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: { padding: '0px 10px 10px 10px' },
              mobile: {
                padding: '0px 10px 10px 10px',
                'flex-direction': 'row',
                'align-items': 'start',
                'justify-content': 'end',
                background: 'transparent',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 19px; font-weight: 500; color: rgb(255, 253, 253);"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 60px; color: rgb(255, 255, 255);"\u003eTITLE\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '63%',
                  padding: '131px 10px 191px 10px',
                  desktop: {},
                  tablet: { padding: '74px 10px 130px 10px' },
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 13px; font-weight: 500;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 30px;"\u003eTÍTULO\u003c/strong\u003e\u003c/p\u003e',
                    padding: '39px 0px 75px 94px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px;"\u003e\u003cstrong style="font-size: 21px;"\u003eSUBTITLE\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px; font-weight: 500; color: rgb(0, 0, 0);"\u003e\u003cspan style="color: rgb(0, 0, 0);"\u003eLorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '24px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px;"\u003e\u003cstrong style="font-size: 21px;"\u003eSUBTITLE\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px; font-weight: 500; color: rgb(0, 0, 0);"\u003e\u003cspan style="color: rgb(0, 0, 0); font-size: 15px;"\u003eLorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {
                    text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px;"\u003e\u003cstrong style="font-size: 21px;"\u003eSUBTITLE\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px; font-weight: 500; color: rgb(118, 118, 118); line-height: 1.15;"\u003e\u003cspan style="color: rgb(0, 0, 0); font-size: 15px;"\u003eLorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#F7D0BA',
                  background: '#000000',
                  'border-color': '#EF4E23',
                  'border-radius': '13px 4px 13px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '23px',
                  'hover-background': '#FFFFFF',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'CONTINUE READING',
                  margin: '21px 0px 0px 0px',
                  'max-width': '290px',
                  mobile: { 'font-size': '19px' },
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '8px 0px 8px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '34px',
                  'max-width': '51px',
                  margin: '0px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4899589/calendario.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: { 'object-fit': 'contain' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px; color: rgb(0, 0, 0); font-weight: 500;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);"\u003e01/01/2022\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '10%',
                  padding: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: { 'max-width': '15%' },
                  mobile: {
                    text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 18px; color: rgb(0, 0, 0); font-weight: 500;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(0, 0, 0); font-size: 19px;"\u003e01/01/2022\u003c/span\u003e\u003c/p\u003e',
                    'max-width': '29%',
                    padding: '2px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '8px 0px 8px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '32px',
                  'max-width': '32px',
                  margin: '0px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4899590/relogio.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: { 'object-fit': 'contain' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
                {
                  text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 21px; color: rgb(0, 0, 0); font-weight: 500;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(0, 0, 0);"\u003e21:30h\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '10%',
                  padding: '0px 0px 0px 0px',
                  desktop: {},
                  tablet: { 'max-width': '15%' },
                  mobile: {
                    text: '\u003cp style="text-align: center; font-family: Montserrat, sans-serif; font-size: 18px; color: rgb(0, 0, 0); font-weight: 500;"\u003e21:30h\u003c/p\u003e',
                    'max-width': '29%',
                    padding: '2px 0px 0px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '90px',
                  'max-width': '226px',
                  margin: '62px 0px 0px 0px',
                  src: 'https://static-public.klickpages.com.br/uploads/media/file/4890066/made-in-klickpage2.png',
                  'object-fit': 'contain',
                  desktop: {},
                  tablet: {},
                  mobile: { margin: '27px 0px 0px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção' },
    },
  },
];
