import styled from 'styled-components';

export const MerlinChat = styled.div`
  width: auto;
  height: 500px;
  position: absolute;
  right: 0px;
  top: 70px;
  background: #fff;
  z-index: 20;
`;
