import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { useUserInfo, useDataHub } from '@hooks/index';
import { FreeToPaidModal } from '@components/FreeToPaidModal';
import Klickart from '@services/klickart';
import { UserLimitModal } from '@components/UserLimitModal';
import Toast from '@services/toast';

type FreeToPaidModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  origin: string;
  onTrialActivated: () => void;
  onClose: () => void;
};

const FreeToPaidModalComponent: React.FC<FreeToPaidModalProps> = ({
  isOpen,
  origin,
  onTrialActivated,
  onClose,
}) => {
  const { isExtensions } = useUserInfo();
  const { sendTrialMetrify } = useDataHub();

  const [openFreeToPaidModal, setOpenFreeToPaidModal] = useState(false);
  const [onUpgradeModal, setOnUpgradeModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleTrialActivated = useCallback(() => {
    setOpenFreeToPaidModal(false);
    onTrialActivated?.();
  }, [onTrialActivated]);

  const handleNewPlansButtonClicked = useCallback(async () => {
    await sendTrialMetrify('trial_test_1_control', 'modal_1_click', origin);
  }, [origin, sendTrialMetrify]);

  const validateUserModal = useCallback(async () => {
    try {
      const validSegmentations = ['SEED', 'SMALL', 'MEDIUM', 'LARGE'];
      const extensionsUserInfo = await Klickart.getUserTrialSponsorInfo();
      const userInfo = await Klickart.getExtensionsInfo();

      if (
        isExtensions &&
        !extensionsUserInfo?.used &&
        !extensionsUserInfo?.alreadyPayingUser &&
        validSegmentations.includes(userInfo.segmentation)
      ) {
        setOnUpgradeModal(false);
        setOpenFreeToPaidModal(true);
      } else {
        setOpenFreeToPaidModal(false);
        setOnUpgradeModal(true);
      }
    } catch (error) {
      Toast.error('toastMessages.general.error.generic');
    }
  }, [isExtensions]);

  useEffect(() => {
    if (onUpgradeModal && openFreeToPaidModal === false) {
      sendTrialMetrify('trial_test_1_control', 'modal_1_view', origin);
    }
  }, [onUpgradeModal, openFreeToPaidModal, origin, sendTrialMetrify]);

  useEffect(() => {
    if (isOpen) {
      validateUserModal();
    }
  }, [isOpen, validateUserModal]);

  return (
    <>
      <UserLimitModal
        isOpen={onUpgradeModal}
        onClose={handleCloseModal}
        onPrimaryButtonClicked={handleNewPlansButtonClicked}
        error="freeToPaid"
        closeIconAbsolute
      />
      <FreeToPaidModal
        origin={origin}
        onClose={handleCloseModal}
        onTrialActivated={handleTrialActivated}
        isOpen={openFreeToPaidModal}
        buttonsMinWidth="300px"
        closeIconAbsolute
      />
    </>
  );
};

export const FreeToPaidModalController = memo(FreeToPaidModalComponent);
