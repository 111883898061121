import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/index';
import { mediaCenterThunks } from '@store/thunks';

export const useMediaCenter = () => {
  const dispatch = useAppDispatch();

  const breadcrumbs = useSelector(
    (state: State) => state.mediaCenter.breadcrumbs
  );

  const breadcrumbsRedo = useSelector(
    (state: State) => state.mediaCenter.breadcrumbsRedo
  );

  const createFolder = useCallback(
    () => dispatch(mediaCenterThunks.createFolder()).unwrap(),
    [dispatch]
  );

  const currentFolder = useSelector((state: State) =>
    [...state.mediaCenter.breadcrumbs].pop()?.id.toString()
  );

  const deleteMedia = useCallback(
    (itemData: Record<string, Media>) =>
      dispatch(mediaCenterThunks.deleteMedia(itemData)).unwrap(),
    [dispatch]
  );

  const getFolderMedia = useCallback(
    (folderId: string) =>
      dispatch(mediaCenterThunks.getFolderMedia(folderId)).unwrap(),
    [dispatch]
  );

  const loading = useSelector((state: State) => state.mediaCenter.loading);

  const media = useSelector((state: State) => state.mediaCenter.media);

  const moveMedia = useCallback(
    (selectedFolderId: string | undefined) =>
      dispatch(mediaCenterThunks.moveMedia(selectedFolderId)).unwrap(),
    [dispatch]
  );

  const navigateBackFolder = useCallback(
    (config: NavigateBackFolderConfig) =>
      dispatch(mediaCenterThunks.navigateBackFolder(config)),
    [dispatch]
  );
  const navigateForwardFolder = useCallback(
    () => dispatch(mediaCenterThunks.navigateForwardFolder()),
    [dispatch]
  );

  const openFolder = useCallback(
    (folderId: string) =>
      dispatch(mediaCenterThunks.openFolder(folderId)).unwrap(),
    [dispatch]
  );

  const openRootFolder = useCallback(
    () => dispatch(mediaCenterThunks.openRootFolder()),
    [dispatch]
  );

  const search = useSelector((state: State) => state.mediaCenter.search);

  const searchMedia = useCallback(
    (searchQuery: string) =>
      dispatch(mediaCenterThunks.searchMedia(searchQuery)).unwrap(),
    [dispatch]
  );

  const mediaListLength = useSelector(
    (state: State) => state.mediaCenter.media.length
  );

  const selectedMedia = useSelector(
    (state: State) => state.mediaCenter.selectedMedia
  );

  const selectedMediaLength = useSelector(
    (state: State) => Object.keys(state.mediaCenter.selectedMedia).length
  );

  const showInfoBar = useSelector((state: State) =>
    Boolean(
      state.mediaCenter.search ||
        Object.keys(state.mediaCenter.selectedMedia).length ||
        currentFolder
    )
  );

  const updateMediaList = useCallback(
    () => dispatch(mediaCenterThunks.updateMediaList()).unwrap(),
    [dispatch]
  );

  const uploadingMedia = useSelector(
    (state: State) => state.mediaCenter.uploadingMedia
  );

  const uploadMedia = useCallback(
    (file: File) => dispatch(mediaCenterThunks.uploadMedia(file)).unwrap(),
    [dispatch]
  );

  return {
    breadcrumbs,
    breadcrumbsRedo,
    createFolder,
    currentFolder,
    deleteMedia,
    getFolderMedia,
    loading,
    media,
    moveMedia,
    navigateBackFolder,
    navigateForwardFolder,
    openFolder,
    openRootFolder,
    search,
    searchMedia,
    mediaListLength,
    selectedMedia,
    selectedMediaLength,
    showInfoBar,
    updateMediaList,
    uploadingMedia,
    uploadMedia,
  };
};
