import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Pencil: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M16.4062 1.78125L15.2188 0.59375C14.8438 0.21875 14.3125 0 13.8125 0C13.3125 0 12.7812 0.21875 12.4062 0.59375L10.0625 2.9375L9 4L1.375 11.625L1 15.1875C0.9375 15.625 1.28125 16 1.71875 16C1.75 16 1.78125 16 1.8125 16L5.375 15.625L13 8L14.0625 6.9375L16.4062 4.59375C17.1875 3.8125 17.1875 2.5625 16.4062 1.78125ZM4.6875 14.1875L2.59375 14.4062L2.8125 12.3125L10.0312 5.0625L11 4.09375L12.9062 6L11.9375 6.96875L4.6875 14.1875ZM15.3438 3.53125L13.9688 4.9375L12.0625 3.03125L13.4688 1.65625C13.5938 1.53125 13.75 1.5 13.8125 1.5C13.875 1.5 14.0312 1.53125 14.1562 1.65625L15.3438 2.84375C15.5312 3.03125 15.5312 3.34375 15.3438 3.53125Z"
      fill={color}
    />
  </StyledSVG>
);
