import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useMenu } from '@hooks/useMenu';
import TagManager from 'react-gtm-module';
import { ModelCategoryList } from '@components/ModelCategoryList';
import { Submenu } from '../../../Submenu';
import { getSortedCategories } from './sectionsCategories';

export const SectionsTemplateSubmenu: React.FC = () => {
  const { t } = useTranslation();
  const { setActiveSubmenu, setActiveSectionTemplate } = useMenu();

  const handleSavedSectionsClick = useCallback(
    (id: string) => {
      setActiveSubmenu('saved-sections');
      setActiveSectionTemplate(id);
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_sections_template_list',
          event_details: t('sideMenu.sectionsTemplate.savedSections', {
            lng: 'en',
          }),
        },
      });
    },
    [setActiveSectionTemplate, setActiveSubmenu, t]
  );

  const handleClick = useCallback(
    (id: string) => {
      if (id === 'savedSections') {
        handleSavedSectionsClick(id);
        return;
      }
      setActiveSubmenu('sections-list');
      setActiveSectionTemplate(id);

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_sections_template_list',
          event_details: t(`sideMenu.sectionsTemplate.categories.${id}`, {
            lng: 'en',
          }),
        },
      });
    },
    [handleSavedSectionsClick, setActiveSectionTemplate, setActiveSubmenu, t]
  );

  return (
    <Submenu title="sideMenu.sectionsTemplate.title" padding="24px 8px">
      <ModelCategoryList
        categories={getSortedCategories()}
        onSelectedCategory={(id) => handleClick(id)}
      />
    </Submenu>
  );
};
