import '@hotmart-org-ca/cosmos/dist/modal';
import '@hotmart-org-ca/cosmos/dist/modal/modal-header';
import '@hotmart-org-ca/cosmos/dist/modal/modal-body';
import '@hotmart-org-ca/cosmos/dist/modal/modal-footer';

import {
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ModelCategoryList } from '@components/ModelCategoryList';
import { dispatchOpenPopup, scrollToSection, sleep } from '@common/utils';
import { popupsCategories } from '@components/Submenus/components/PopupsSubmenu/popupsCategories';
import { useEngine, useHigherNameByElement } from '@hooks/index';
import { getSortedCategories } from '@components/Submenus/components/SectionsTemplateSubmenu/sectionsCategories';
import { Template } from '@hotmart-org-ca/saas-pages-engine';

import {
  AddIcon,
  ActionButton,
  AddButton,
  CategoriesButtonContainer,
  CategoriesColumn,
  CategoriesList,
  ModalContent,
  ModalFooterStyle,
  PreviewColumn,
  Title,
} from './styles';
import { getRootAddableTemplate } from './helpers';
import { PreviewCards } from './components/PreviewCards';

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'templates-models-portal');
document.body.appendChild(modalRoot);

export type TemplateModelsModalProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement>
> & {
  isOpen: boolean;
  elementType: 'section' | 'popup';
  replaceUid?: string;
  elementPosition?: number;
  onClose?: () => void;
  activeElementAfterAdd?: boolean;
};

const TemplateModelsModalComponent: React.FC<TemplateModelsModalProps> = ({
  isOpen,
  elementType,
  activeElementAfterAdd = false,
  replaceUid,
  elementPosition,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const hotModal = useRef<HTMLElement | null>(null);
  const { addElement, openedPopup, page, replaceTemplate } = useEngine();
  const { higherName } = useHigherNameByElement(`ls-${elementType}`);

  const el = useMemo(() => document.createElement('div'), []);
  const isSection = useMemo(() => elementType === 'section', [elementType]);

  const [activeTemplateId, setActiveTemplateId] = useState('');
  const [selectedFragment, setSelectedFragment] =
    useState<PageFragmentListItem>();

  const closeAndDirect = useCallback(
    async (uid: string) => {
      onClose?.();

      await sleep(200);

      if (isSection) {
        scrollToSection(uid, true);
      } else {
        dispatchOpenPopup(uid, openedPopup);
      }

      if (activeElementAfterAdd) {
        document.getElementById(uid)?.click();
      }
    },
    [activeElementAfterAdd, isSection, onClose, openedPopup]
  );

  const getLabelById = useCallback(
    (id: string) => {
      const categories = isSection ? getSortedCategories() : popupsCategories;
      const element = categories.find((child) => child.id === id);
      return t(`${element?.label}`);
    },
    [isSection, t]
  );

  const handleAddTemplate = useCallback(
    async (template?: Template) => {
      const uid = addElement(
        template ||
          getRootAddableTemplate({
            elementType,
            metadataLabel: higherName,
          }),
        page?.uid || '',
        Math.min(elementPosition!, page?.children?.length!) || 0
      );

      await closeAndDirect(uid);
    },
    [
      addElement,
      elementType,
      higherName,
      page?.uid,
      page?.children?.length,
      elementPosition,
      closeAndDirect,
    ]
  );

  const handleReplaceTemplate = useCallback(
    async (template?: Template) => {
      if (!template) {
        return;
      }
      replaceTemplate(replaceUid!, template);

      await closeAndDirect(replaceUid!);
    },
    [closeAndDirect, replaceTemplate, replaceUid]
  );

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  useEffect(() => {
    if (isOpen) {
      const modalDialog = hotModal?.current?.shadowRoot
        ?.querySelector<HTMLElement>('hot-modal-concrete')
        ?.shadowRoot?.querySelector<HTMLElement>('.hot-modal__dialog');

      if (modalDialog) {
        modalDialog.style.maxWidth = '1190px';
      }

      setActiveTemplateId(isSection ? 'bonus' : 'popups-templates');
    } else {
      setSelectedFragment(undefined);
      setActiveTemplateId('');
    }
  }, [isOpen, isSection]);

  useEffect(() => {
    const closeIcon = hotModal?.current
      ?.querySelector('hot-modal-header')
      ?.shadowRoot?.querySelector<HTMLElement>('.hot-modal__close');

    if (closeIcon) {
      Object.assign(closeIcon.style, {
        position: 'absolute',
        right: '12px',
        top: '12px',
        width: '12px',
        height: '12px',
      });
    }
  }, []);

  useEffect(() => {
    const modal = hotModal?.current;

    if (modal && onClose) {
      modal.addEventListener('close', onClose);
    }

    return () => {
      if (modal && onClose) {
        modal.removeEventListener('close', onClose);
      }
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <hot-modal
      ref={hotModal}
      open={isOpen || undefined}
      position="centered"
      id="hot-modal-models"
      {...attrs}
    >
      <ModalFooterStyle />
      <ModalContent>
        <CategoriesColumn>
          <CategoriesList isReplaceMode={Boolean(replaceUid)}>
            <ModelCategoryList
              categories={isSection ? getSortedCategories() : popupsCategories}
              onSelectedCategory={setActiveTemplateId}
              selectedCategoryId={activeTemplateId}
            />
          </CategoriesList>
          {!replaceUid && (
            <CategoriesButtonContainer>
              <AddButton
                className="hot-button hot-button--secondary"
                onClick={() => handleAddTemplate()}
              >
                <span>
                  {t(`modals.templateModels.addBlankElement.${elementType}`)}
                </span>
                <AddIcon className="fal fa-plus" />
              </AddButton>
            </CategoriesButtonContainer>
          )}
        </CategoriesColumn>

        <PreviewColumn>
          <hot-modal-header class={classNames('headerClass')}>
            <Title>{getLabelById(activeTemplateId)}</Title>
          </hot-modal-header>
          <PreviewCards
            onTemplateSelected={(item) => setSelectedFragment(item)}
            elementType={elementType}
            selectedFragmentId={selectedFragment?.id}
            activeTemplateId={activeTemplateId}
          />
          <ActionButton>
            <AddButton
              onClick={() =>
                replaceUid
                  ? handleReplaceTemplate(selectedFragment?.template)
                  : handleAddTemplate(selectedFragment?.template)
              }
              className={classNames('hot-button hot-button--secondary', {
                'hot-button--disabled': !selectedFragment?.id,
              })}
              disabled={!selectedFragment}
            >
              <span>
                {t(
                  `modals.templateModels.${
                    replaceUid
                      ? 'replaceElementButton'
                      : 'addSelectedElementButton'
                  }`
                )}
              </span>
              <i className="fal fa-arrow-right" />
            </AddButton>
          </ActionButton>
        </PreviewColumn>
      </ModalContent>
    </hot-modal>,
    el
  );
};

export const TemplateModelsModal = memo(TemplateModelsModalComponent);
