import { HTMLAttributes, memo, useCallback } from 'react';
import { Block } from '@components/Block';
import { OfferInputs } from './components/OfferInputs';
import { ChangeOffer } from './components/ChangeOffer';

export type OfferInfoProps = HTMLAttributes<HTMLDivElement> & {
  product: ProductProps;
  onOfferChange?: (offer: Partial<ProductProps>) => void;
};

const OfferInfoComponent: React.FC<OfferInfoProps> = ({
  product,
  onOfferChange,
  ...attrs
}) => {
  const handleOfferChange = useCallback(
    (updatedOffer: Partial<ProductProps>) => {
      onOfferChange?.({
        ...product,
        ...updatedOffer,
      });
    },
    [onOfferChange, product]
  );

  return (
    <Block
      label="pageWizard.salesPage.paidWithProduct.updateOffer.title"
      showSeparator
      {...attrs}
    >
      <OfferInputs
        productId={product.id}
        offerLink={product.offerLink}
        offerPrice={product.offerPrice}
        onOfferChange={handleOfferChange}
      />

      {product.id && (
        <ChangeOffer
          productId={product.id}
          offerId={product.offerId}
          onOfferChange={handleOfferChange}
        />
      )}
    </Block>
  );
};

export const OfferInfo = memo(OfferInfoComponent);
