import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { ImageUpload } from '@pages/PageWizard/useCases/Sales/components/ImageUpload';
import { TextInputs, TextInputsValues } from './components/TextInputs';
import { AiSidebar } from './components/AiSidebar';
import { SalesUseCase } from '../../salesUseCase';

type PresentProductProps = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    updatedProduct: UpdatedProductProps;
    product: ProductProps;
    useCase: SalesUseCase;
  };

const PresentProductComponent: React.FC<PresentProductProps> = ({
  product,
  updatedProduct,
  onModifierChange,
  useCase,
}) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  const handleUseSuggestions = useCallback(
    (value: Omit<UpdatedProductProps, 'description'>) => {
      useCase.modifyByCategory('productTitle', value.title);
      useCase.modifyByCategory('productSubtitle', value.subtitle);

      onModifierChange({
        prop: 'updatedProduct',
        value: { ...updatedProduct, ...value },
      });
      setIsSideBarOpen(false);
    },
    [onModifierChange, updatedProduct, useCase]
  );

  const handleTextChange = useCallback(
    (value: TextInputsValues) => {
      useCase.modifyByCategory('productTitle', value.title);
      useCase.modifyByCategory('productSubtitle', value.subtitle);

      onModifierChange({
        prop: 'updatedProduct',
        value: { ...updatedProduct, ...value },
      });
    },
    [onModifierChange, updatedProduct, useCase]
  );

  const handleImageChange = useCallback(
    (url: string) => {
      useCase.modifyByCategory('productImage', url);

      onModifierChange({
        prop: 'product',
        value: { ...product, imgSrc: url },
      });
    },
    [onModifierChange, product, useCase]
  );

  return (
    <>
      <TextInputs
        title={updatedProduct.title}
        subtitle={updatedProduct.subtitle}
        onTextChanged={handleTextChange}
        onAiButtonClicked={() => setIsSideBarOpen(true)}
      />
      <ImageUpload
        src={product.imgSrc}
        onImageChange={handleImageChange}
        blockTitle="pageWizard.salesPage.paidWithProduct.presentProduct.photoTitle"
        label="pageWizard.salesPage.paidWithProduct.presentProduct.photoLabel"
      />
      <AiSidebar
        product={product}
        isOpen={isSideBarOpen}
        onClosed={() => setIsSideBarOpen(false)}
        onUseSuggestions={(selectedSuggestions) =>
          handleUseSuggestions(selectedSuggestions)
        }
      />
    </>
  );
};

export const PresentProduct = memo(PresentProductComponent);
