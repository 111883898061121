import styled, { createGlobalStyle } from 'styled-components';

export const ModalBodyStyle = createGlobalStyle`
  hot-list-group {
    width: 100%;
  }

  hot-list-group-item[active] {
    background-color: var(--blue-lightest);
  }

  hot-list-group-item {
    padding: var(--spacer-4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: none;
    border-right: none;
    border-top: var(--component-border-width) solid var(--component-border-color);
    border-bottom: var(--component-border-width) solid var(--component-border-color);
  }

  .move-media-modal__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
  }
`;

export const FolderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

export const Icon = styled.i`
  padding: 4px 8px;
`;

export const FolderName = styled.div`
  flex: 1;
`;

export const Arrow = styled.span`
  display: flex;
  cursor: pointer;
`;
