import styled from 'styled-components';

export const HeaderTitle = styled.span`
  font-size: 24px;
  font-weight: 300;
  color: var(--gray-700);
`;

export const Description = styled.span`
  color: var(--gray-500);
`;

export const BackButton = styled.button`
  margin-right: 16px;
`;
