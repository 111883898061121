import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import { getLocaleLink } from '@i18n/index';

import { HTMLAttributes, memo, useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  EDITOR_PAGE_LIST_URL,
  CAPTURE_FUNNEL_HOME_URL,
} from '@common/constants';
import { usePageInfo } from '@hooks/usePageInfo';
import TagManager from 'react-gtm-module';
import { Button } from './styles';

export type BackProps = HTMLAttributes<HTMLButtonElement> & {
  isTemplatePreview?: boolean;
};

const BackComponent: React.FC<BackProps> = ({
  isTemplatePreview,
  ...attrs
}) => {
  const { mode, createdByFunnel } = usePageInfo();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const query = useRef(urlSearchParams);

  const callEvent = () =>
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: 'click_navbar_editor',
        event_details: 'Back',
      },
    });

  const goBack = useCallback(() => {
    if (isTemplatePreview) {
      callEvent();
      window.close();
    } else if (mode === 'template') {
      callEvent();
      navigate(getLocaleLink('/templates'));
    } else if (createdByFunnel) {
      callEvent();
      window.open(CAPTURE_FUNNEL_HOME_URL, '_self');
    } else {
      callEvent();

      const qsName = 'finished-use-case';
      const finishedUseCase = query.current.get(qsName);
      const url = finishedUseCase
        ? `${EDITOR_PAGE_LIST_URL}?${qsName}=${finishedUseCase}`
        : EDITOR_PAGE_LIST_URL;

      window.open(url, '_self');
    }
  }, [createdByFunnel, isTemplatePreview, mode, navigate]);

  return (
    <Button
      onClick={goBack}
      className="hot-button hot-button--tertiary"
      {...attrs}
    >
      <i className="far fa-chevron-left" />
    </Button>
  );
};

export const Back = memo(BackComponent);
