import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.label`
  color: var(--gray-900);
`;
