import styled, { css } from 'styled-components';
import { Container as ButtonContainer } from '../MediaOptions/styles';

type StyledLoadingProps = {
  isLoading?: boolean;
  isError?: boolean;
  isUploaded?: boolean;
  active?: boolean;
};

type StyledFolderProps = {
  isSelectable?: boolean;
  isSelectedFolder?: boolean;
};

export const Container = styled.div<StyledFolderProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  ${({ isSelectable }) => css`
    ${isSelectable
      ? css`
          width: 150px;
          height: 150px;
          cursor: pointer;
        `
      : css`
          width: 100%;
          height: 80px;
        `}
  `}

  :after {
    content: '';
    pointer-events: none;
    position: absolute;
    inset: 0;
    width: 100%;
    ${({ isSelectedFolder }) => css`
      ${isSelectedFolder
        ? 'background-color: rgba(190, 209, 255, 0.5);'
        : 'background-color: rgba(0, 0, 0, 0);'}
    `}
    transition: opacity 0.35s ease;
    border-radius: 4px;
  }

  :hover::after {
    border: 1.5px solid var(--blue);
    background-color: rgba(190, 209, 255, 0.5);
  }

  ${({ isSelectedFolder }) => css`
    ${isSelectedFolder
      ? `${ButtonContainer} {
          opacity: 1;
        }`
      : 'background-color: rgba(0, 0, 0, 0);'}
  `}

  :hover ${ButtonContainer} {
    opacity: 1;
  }
`;

export const Progress = styled.div<StyledLoadingProps>`
  position: absolute;
  justify-content: center;
  align-items: center;
  inset: 0;
  border-radius: 4px;
  background-color: rgba(240, 244, 255, 0.8);
  font-size: 24px;

  ${({ isLoading, isUploaded, isError }) => css`
    display: ${isLoading || isUploaded || isError ? 'flex' : 'none'};
    ${isUploaded && 'color: var(--green);'}
    ${isError && 'color: var(--red);'}
  `}
`;

export const FolderImg = styled.img`
  max-height: 90%;
  max-width: 90%;
  height: 90%;
  width: 90%;
  display: block;
  border-radius: 4px;
`;
