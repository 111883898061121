import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 0 24px;
`;

export const Description = styled.label`
  color: var(--gray-600);
  font-size: 12px;
  margin-bottom: 0;
`;

export const Label = styled.label`
  margin-bottom: 0;
`;

export const Icon = styled.i`
  font-size: 16px;
  margin-right: 8px;
`;

export const Input = styled.input`
  display: none;
`;
