import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeBreadcrumbById } from '@common/utils';
import { mediaCenterExtraReducers } from '@store/thunks/mediaCenter';

export const mediaCenterInitialState: MediaCenterState = {
  media: [],
  uploadingMedia: [],
  loading: false,
  search: '',
  breadcrumbs: [],
  breadcrumbsRedo: [],
  selectedMedia: {},
};

const mediaCenter = createSlice({
  name: 'mediaCenter',
  initialState: mediaCenterInitialState,
  reducers: {
    setMedia(state, action: PayloadAction<Media[]>) {
      state.media = action.payload;
    },
    setSelectedMedia(state, action: PayloadAction<Record<string, Media>>) {
      state.selectedMedia = action.payload;
    },
    addUploadingMedia(state, action: PayloadAction<UploadingMedia>) {
      const payload = { ...action.payload, loading: true };
      state.uploadingMedia = [...state.uploadingMedia, payload];
    },
    removeUploadingMedia(state, action: PayloadAction<string>) {
      state.uploadingMedia = state.uploadingMedia.filter(
        (media) => media.id !== action.payload
      );
    },
    updateUploadingMedia(state, action: PayloadAction<UploadingMedia>) {
      state.uploadingMedia = state.uploadingMedia.map((media) => {
        if (media.id === action.payload.id) {
          return { ...media, ...action.payload };
        }
        return media;
      });
    },
    updateBreadcrumbOnBackNavigation(
      state,
      action: PayloadAction<NavigateBackFolderConfig>
    ) {
      const { to } = action.payload;

      if (to === 'ROOT_FOLDER') {
        state.breadcrumbsRedo = [
          ...state.breadcrumbs,
          ...state.breadcrumbsRedo,
        ];
        state.breadcrumbs = [];
        return;
      }

      const { folderIndex } = action.payload;

      if (folderIndex === undefined) {
        const breadcrumb = state.breadcrumbs.pop() as Breadcrumb;
        state.breadcrumbsRedo.unshift(breadcrumb);
      } else {
        const breadcrumbsRedo = state.breadcrumbs.splice(folderIndex + 1);
        state.breadcrumbsRedo = [...breadcrumbsRedo, ...state.breadcrumbsRedo];
      }
    },
    updateBreadcrumbOnForwardNavigation(state) {
      if (state.breadcrumbsRedo.length) {
        state.media = [];
        const breadcrumb = state.breadcrumbsRedo.shift() as Breadcrumb;
        state.breadcrumbs.push(breadcrumb);
      }
    },
    removeFolderFromBreadcrumbRedo(
      state,
      action: PayloadAction<string | string[]>
    ) {
      if (Array.isArray(action.payload)) {
        for (let i = 0; i < action.payload.length; i += 1) {
          const idToRemove = action.payload[i];
          const removed = removeBreadcrumbById(
            state.breadcrumbsRedo,
            idToRemove
          );

          if (removed) {
            break;
          }
        }

        return;
      }

      removeBreadcrumbById(state.breadcrumbsRedo, action.payload);
    },
  },
  extraReducers: (builder) => mediaCenterExtraReducers(builder),
});

export const {
  setMedia,
  setSelectedMedia,
  addUploadingMedia,
  removeUploadingMedia,
  updateUploadingMedia,
  updateBreadcrumbOnBackNavigation,
  updateBreadcrumbOnForwardNavigation,
  removeFolderFromBreadcrumbRedo,
} = mediaCenter.actions;

export default mediaCenter.reducer;
