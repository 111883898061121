import { AxiosInstance, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { rebrand } from '@config/rebrand';
import TokenStrategyFactory from 'src/auth/tokenStrategies/tokenStrategyFactory';
import { casEnabled } from 'src/auth/authService';
import Toast from '@services/toast';

const { KLICKART_URL } = rebrand.urls;

export enum TokenNames {
  api = 'user-auth-token',
  klickApi = 'user-klickauth-token',
  cas = 'cas',
  colaborator = 'colaborator',
}

export enum TokenKinds {
  'user-auth-token' = 'jwt',
  'user-klickauth-token' = 'klickauth',
  'cas' = 'cas',
  'colaborator' = 'colaborator',
}

export default class Token {
  static refreshTokenUrl = casEnabled()
    ? `${KLICKART_URL}/v1/api/tokens`
    : `${KLICKART_URL}/users/token`;

  private static crossAppUrl = `${KLICKART_URL}/crossapp/editor`;

  static decodeToken<T>(token: string): T {
    return jwtDecode<T>(token);
  }

  static getTokenKind(name: TokenNames) {
    return TokenKinds[name];
  }

  static updateUserData(token: string) {
    try {
      const parsedToken = token.replace(/ /g, '+');
      const { permissions, userProfile, featureFlags } =
        this.decodeToken<User>(parsedToken);

      this.setToken(parsedToken);
      this.setSurvicateVariables(userProfile);
      window.dispatchEvent(
        new CustomEvent('userInfo', {
          detail: {
            permissions,
            userProfile,
            featureFlags,
          },
        })
      );
    } catch {
      window.dispatchEvent(
        new CustomEvent('userInfo', {
          detail: {
            permissions: {
              templateManagementEnabled: false,
              madeWithManagementEnabled: false,
              importPageEnabled: false,
              exportPageEnabled: false,
            },
          },
        })
      );
    }
  }

  static getTokenByName(name: TokenNames) {
    const tokenStrategy = this.getTokenStrategyByName(name);
    return tokenStrategy.getToken();
  }

  static getTokenStrategyByName(name: TokenNames) {
    const tokenKind = TokenKinds[name];
    return TokenStrategyFactory.getTokenStrategy(tokenKind);
  }

  static setTokenByName(name: TokenNames, token: string) {
    const tokenStrategy = this.getTokenStrategyByName(name);
    tokenStrategy.setToken(token);
  }

  static getToken() {
    return this.getTokenByName(TokenNames.api);
  }

  static setToken(token: string) {
    Cookies.set(TokenNames.api, token);
  }

  static getKlickToken() {
    return this.getTokenByName(TokenNames.klickApi);
  }

  static setKlickToken(token: string) {
    Cookies.set(TokenNames.klickApi, token);
  }

  static remove() {
    Cookies.remove(TokenNames.api);
    Cookies.remove(TokenNames.klickApi);
  }

  static async refreshToken(instance: AxiosInstance, tokenName: TokenNames) {
    const { data } = await this.fetchToken(instance, TokenKinds[tokenName]);
    const { token } = data;
    if (tokenName === TokenNames.api) {
      this.updateUserData(token);
    } else {
      this.setTokenByName(tokenName, token);
    }
  }

  static errorHandler(instance: AxiosInstance, tokenName: TokenNames) {
    return async (error: ErrorResponse) => {
      if (tokenName === TokenNames.cas) {
        return Promise.reject(error);
      }

      if (tokenName === TokenNames.colaborator) {
        Toast.error('toastMessages.general.error.generic');

        const url = process.env.REACT_APP_HOTMART_URL;

        setTimeout(() => {
          window.location.href = url!;
        }, 500);
      }

      if (!error.response) {
        return Promise.reject(error);
      }

      const { config, response } = error;

      if (response.status === 401) {
        if (config.url === this.refreshTokenUrl || config.updateToken) {
          this.redirectToLogin();
        } else {
          config.updateToken = true;
          await this.refreshToken(instance, tokenName);

          return instance(config);
        }
      }

      return Promise.reject(error);
    };
  }

  static interceptor(tokenName: TokenNames) {
    return (config: AxiosRequestConfig) => {
      const token = this.getTokenByName(tokenName);

      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }

      return config;
    };
  }

  static fetchToken(instance: AxiosInstance, tokenKind: TokenKinds) {
    return instance.post<{ token: string }>(this.refreshTokenUrl, {
      application: 'editor',
      token_kind: tokenKind,
    });
  }

  private static redirectToLogin() {
    this.remove();
    const url = `${this.crossAppUrl}?redirect=${window.location.href}`;
    window.location.assign(url);
  }

  private static setSurvicateVariables({ name, email }: UserProfile) {
    const splittedName = name.split(' ');
    const firstName = splittedName.shift() || '';
    const lastName = splittedName.pop() || '';
    const retry = 5;

    let retryCount = 0;

    const interval = setInterval(() => {
      retryCount += 1;

      if (retryCount > retry) {
        clearInterval(interval);
      } else if (window._sva?.setVisitorTraits) {
        clearInterval(interval);
        window._sva.setVisitorTraits({
          email,
          first_name: firstName,
          last_name: lastName,
        });
      }
    }, 200);
  }
}
