import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Checkout: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg%22%3E"
    {...attrs}
  >
    <path
      d="M21.9 5.20001C20.7 5.20001 19.7 6.10001 19.5 7.20001H10.7C10.5 6.10001 9.50005 5.20001 8.30005 5.20001C6.90005 5.20001 5.80005 6.30001 5.80005 7.70001C5.80005 9.10001 6.90005 10.2 8.30005 10.2C9.50005 10.2 10.5 9.30001 10.7 8.20001H19.5C19.7 9.30001 20.7 10.2 21.9 10.2C23.3 10.2 24.4001 9.10001 24.4001 7.70001C24.4001 6.30001 23.3 5.20001 21.9 5.20001ZM8.40005 15.6H21.7C22.2 15.6 22.5 15.3 22.5 14.8C22.5 14.3 22.1 14 21.7 14H8.40005C7.90005 14 7.60005 14.3 7.60005 14.8C7.60005 15.3 8.00005 15.6 8.40005 15.6Z"
      fill={color}
    />
    <path
      d="M25.1 0H5.1C2.3 0 0 2.3 0 5.1V24.7C0 27.5 2.3 29.8 5.1 29.8H25.1C27.9 29.8 30.2 27.5 30.2 24.8V5.1C30.2 2.3 27.9 0 25.1 0ZM28.1 24.7C28.1 26.3 26.8 27.7 25.1 27.7H5.1C3.5 27.7 2.1 26.4 2.1 24.7V5.1C2.1 3.5 3.8 2.1 5.1 2.1C6.4 2.1 25.1 2.1 25.1 2.1C26.7 2.1 28.1 3.4 28.1 5.1V24.7Z"
      fill={color}
    />
    <path
      d="M22.4001 19.1H7.80005C6.70005 19.1 5.80005 20 5.80005 21.1V23C5.80005 24.1 6.70005 25 7.80005 25H22.4001C23.5001 25 24.4001 24.1 24.4001 23V21.2C24.4001 20.1 23.5001 19.1 22.4001 19.1ZM20.6 22.8H9.50005C9.10005 22.8 8.70005 22.5 8.70005 22C8.70005 21.6 9.00005 21.2 9.50005 21.2H20.6C21 21.2 21.4 21.5 21.4 22C21.4 22.5 21 22.8 20.6 22.8Z"
      fill={color}
    />
  </StyledSVG>
);
