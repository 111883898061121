import styled, { css } from 'styled-components';

type StyledSaveStatusProps = {
  isError?: boolean;
};

export const SaveStatus = styled.span<StyledSaveStatusProps>`
  color: var(--primary);
  width: 152px;

  ${({ isError }) => css`
    color: ${isError ? 'var(--red-darker)' : 'var(--primary)'};
  `}

  hot-tooltip {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
`;
