import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageInfo } from '@hooks/usePageInfo';
import { useEngine } from '@hooks/useEngine';
import { Container } from './styles';
import Page from '../../../../services/pages';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';
import Toast from '../../../../services/toast';

export const TemplateEnabledSwitch: React.FC = () => {
  const { t } = useTranslation();
  const {
    updateInfo,
    uuid,
    mode,
    hasBeenPublished,
    enabled: storeEnabled,
  } = usePageInfo();

  const { getFinalPage } = useEngine();

  const [enabled, setEnabled] = useState(false);
  const [isEnabling, setIsEnabling] = useState(false);

  useEffect(() => {
    setEnabled(storeEnabled);
  }, [storeEnabled]);

  const handleChange = useCallback(async () => {
    const template = getFinalPage();

    if (template) {
      const data = {
        enabled: !enabled,
      };
      try {
        setIsEnabling(true);

        await Page.updatePage(uuid, data, mode, true);

        Toast.success(
          `toastMessages.${mode}.success.${enabled ? 'disabled' : 'enabled'}`
        );
        setIsEnabling(false);
        setEnabled(data.enabled);
        updateInfo(data);
      } catch {
        Toast.error(
          `toastMessages.${mode}.error.${enabled ? 'disabled' : 'enabled'}`
        );
        setIsEnabling(false);
      }
    }
  }, [getFinalPage, enabled, uuid, mode, updateInfo]);

  const disabled = useMemo(
    () => isEnabling || !hasBeenPublished,
    [isEnabling, hasBeenPublished]
  );

  return (
    <Container className="hot-form hot-form--custom hot-form--switch">
      <input
        type="checkbox"
        className="hot-form__input"
        id="TemplateEnabler"
        checked={enabled}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor="TemplateEnabler" className="hot-form__label">
        {t('header.enabled')}
      </label>
    </Container>
  );
};
