import { v4 as uuid } from 'uuid';
import i18next from 'i18next';

export const getDarkSecondaryTemplate = () => {
  const maxInstallments = i18next.t(
    'pageWizard.salesPage.template.offer.installments',
    {
      maxInstallments: '1',
    }
  );

  return {
    background: 'none',
    'background-is-webp': false,
    padding: '0px 0px 0px 0px',
    desktop: {},
    tablet: {},
    mobile: {},
    uid: `ls-${uuid()}`,
    component: 'ls-page',
    children: [
      {
        background: '#161616',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-25T13:36:15',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '16px 0px 16px 0px',
        desktop: {},
        tablet: {
          background: '#161616',
          padding: '56px 0px 72px 0px',
        },
        mobile: {
          padding: '32px 0px 54px 0px',
        },
        uid: `ls-${uuid()}-title`,
        component: 'ls-section',
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '60px 0px 60px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'transparent',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '24px 0px 24px 0px',
                },
                mobile: {
                  padding: '16px 0px 16px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                metadata: {},
                children: [
                  {
                    alt: '',
                    'border-color': '#FFFFFF',
                    'border-radius': '250px 250px 250px 250px',
                    'border-style': 'none',
                    'border-top-width': '40px',
                    'border-right-width': '40px',
                    'border-bottom-width': '40px',
                    'border-left-width': '40px',
                    height: '100%',
                    href: '',
                    'is-webp': true,
                    'max-width': '85%',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'contain',
                    target: '_blank',
                    desktop: {},
                    tablet: {
                      'max-width': '400px',
                      height: '400px',
                    },
                    mobile: {
                      'max-width': '200px',
                      height: '200px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/6912702/wepik-export-20231009212715nkik.png',
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/6912702/thumb_wepik-export-20231009212715nkik.png',
                    metadata: {
                      useCase: {
                        productImage: {
                          propName: 'src',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
              },
              {
                padding: '0px 0px 0px 32px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'transparent',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '24px 0px 0px 0px',
                },
                mobile: {
                  padding: '0px 0px 0px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: '<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 40px;">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT EIUSMOD.</strong></p>',
                    'max-width': '100%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 40px;">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT EIUSMOD.</strong></p>',
                      'max-width': '80%',
                    },
                    mobile: {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 20px;">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT EIUSMOD.</strong></p>',
                      'max-width': '88%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productTitle: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    text: '<p><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                    'max-width': '90100%',
                    padding: '16px 10px 16px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '72%',
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p><p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                    },
                    mobile: {
                      text: '<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 12px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</span></p>',
                      'max-width': '95%',
                      padding: '10px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productSubtitle: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    action: 'link',
                    color: '#FFFFFF',
                    background: '#AD23E8',
                    'border-color': '#EF4E23',
                    'border-radius': '0px 00px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    'button-class': '',
                    desktop: {},
                    'end-date': '2023-05-26T13:48:23',
                    timezone: '-03:00_utcMinus0300MainCities',
                    'time-on-page': '5',
                    'time-on-page-unit': 'seconds',
                    'delay-type': 'disabled',
                    'font-family': 'Raleway, sans-serif',
                    'font-size': '20px',
                    'hover-background': 'none',
                    'hover-color': 'none',
                    'has-icon': false,
                    'has-text': true,
                    href: '',
                    'icon-position': 'right',
                    icon: 'fas fa-arrow-right',
                    'is-bold': true,
                    'is-italic': false,
                    'is-underline': false,
                    'is-strike': false,
                    label: i18next.t('pageWizard.salesPage.template.CTA'),
                    margin: '32px 10px 16px 10px',
                    'max-width': '90%',
                    mobile: {
                      'max-width': '80%',
                      'font-size': '18px',
                      margin: '16px 0px 0px 0px',
                      padding: '14px 32px 14px 32px',
                    },
                    padding: '16px 32px 16px 32px',
                    tablet: {
                      margin: '40px 0px 20px 0px',
                      'max-width': '64%',
                      'font-size': '20px',
                    },
                    target: '_blank',
                    uid: `ls-${uuid()}`,
                    component: 'ls-button',
                    metadata: {
                      useCase: {
                        offerLink: {
                          propName: 'href',
                          middleware: 'string',
                        },
                        purchaseButton: {
                          propName: 'label',
                          middleware: 'string',
                        },
                        secondaryColor: {
                          propName: 'background',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
        metadata: {
          label: 'Seção 1',
        },
      },
      {
        background: '#303030',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '100px 0px 160px 0px',
        desktop: {},
        tablet: {
          background: '#303030',
          padding: '64px 0px 64px 0px',
        },
        mobile: {
          background: '#303030',
          padding: '40px 0px 64px 0px',
        },
        uid: `ls-${uuid()}-about-content`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 2',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  'align-items': 'start',
                  'justify-content': 'center',
                  padding: '0px 0px 24px 72px',
                },
                mobile: {
                  padding: '0px 20px 0px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15;"><strong style="font-family: Raleway, sans-serif; font-size: 70px; color: rgb(255, 255, 255); background-color: rgba(0, 0, 0, 0);">${i18next.t(
                      'pageWizard.salesPage.template.aboutProduct'
                    )}</strong></p>`,
                    'max-width': '80%',
                    padding: '10px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      padding: '10px 10px 0px 10px',
                      text: `<p style="line-height: 1.15;"><strong style="font-family: Raleway, sans-serif; font-size: 72px; color: rgb(255, 255, 255); background-color: rgba(0, 0, 0, 0);">${i18next.t(
                        'pageWizard.salesPage.template.aboutProduct'
                      )}</strong></p>`,
                    },
                    mobile: {
                      text: `<p style="line-height: 1.15;"><strong style="font-family: Raleway, sans-serif; font-size: 32px; color: rgb(255, 255, 255); background-color: rgba(0, 0, 0, 0);">${i18next.t(
                        'pageWizard.salesPage.template.aboutProduct'
                      )}</strong></p>`,
                      'max-width': '100%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 1;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif; font-size: 18px;">____________________________________________________</strong></p>',
                    'max-width': '85%',
                    padding: '0px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '77%',
                    },
                    mobile: {
                      text: '<p style="line-height: 1;"><span style="color: rgb(106, 175, 255); font-family: Montserrat, sans-serif; font-size: 20px;">____________________________</span></p>',
                      'max-width': '92%',
                      padding: '0px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        secondaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    text: '<p><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                    'max-width': '76%',
                    padding: '24px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '77%',
                    },
                    mobile: {
                      text: '<p><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.</span></p>',
                      'max-width': '92%',
                      padding: '10px 10px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        productDescription: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'end',
                'align-items': 'end',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '32px 0px 30px 0px',
                },
                mobile: {
                  padding: '24px 0px 16px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    'border-color': '#6AAFFF',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '5px',
                    'border-right-width': '5px',
                    'border-bottom-width': '5px',
                    'border-left-width': '5px',
                    height: '326px',
                    margin: '0px 0px 0px 0px',
                    'max-width': '580px',
                    src: 'https://www.youtube.com/embed/wYHO0Wdqw7g',
                    'thumb-url': '',
                    desktop: {},
                    tablet: {
                      'border-style': 'solid',
                    },
                    mobile: {
                      'max-width': '300px',
                      height: '169px',
                      'border-style': 'solid',
                      'border-top-width': '3px',
                      'border-right-width': '3px',
                      'border-bottom-width': '3px',
                      'border-left-width': '3px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-video',
                    metadata: {
                      salesVideo: true,
                      useCase: {
                        videoSrc: {
                          propName: 'src',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {
                  useCase: {
                    hasVideo: {
                      structureMiddleware: 'hasVideo',
                    },
                  },
                },
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#161616',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 4',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '88px 0px 88px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '64px 0px 88px 0px',
                },
                mobile: {
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '48px 0px 48px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 32px;">${i18next.t(
                      'pageWizard.salesPage.template.offerEnding'
                    )}</strong></p>`,
                    'max-width': '60%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      padding: '10px 10px 10px 24px',
                      'max-width': '90%',
                      text: `<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 28px;">${i18next.t(
                        'pageWizard.salesPage.template.offerEnding'
                      )}</strong></p>`,
                    },
                    mobile: {
                      text: `<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 24px;">${i18next.t(
                        'pageWizard.salesPage.template.offerEnding'
                      )}</strong></p>`,
                      padding: '10px 10px 10px 10px',
                      'max-width': '80%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    action: 'link',
                    color: '#FFFFFF',
                    background: '#AD23E8',
                    'border-color': '#000000',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '1px',
                    'border-right-width': '1px',
                    'border-bottom-width': '1px',
                    'border-left-width': '1px',
                    'button-class': '',
                    desktop: {},
                    'end-date': '2023-05-26T13:48:23',
                    timezone: '-03:00_utcMinus0300MainCities',
                    'time-on-page': '5',
                    'time-on-page-unit': 'seconds',
                    'delay-type': 'disabled',
                    'font-family': 'Raleway, sans-serif',
                    'font-size': '20px',
                    'hover-background': 'none',
                    'hover-color': 'none',
                    'has-icon': false,
                    'has-text': true,
                    href: '',
                    'icon-position': 'right',
                    icon: 'fas fa-arrow-right',
                    'is-bold': true,
                    'is-italic': false,
                    'is-underline': false,
                    'is-strike': false,
                    label: i18next.t('pageWizard.salesPage.template.CTA'),
                    margin: '24px 0px 0px 0px',
                    'max-width': '40%',
                    mobile: {
                      'max-width': '80%',
                      'font-size': '18px',
                      padding: '14px 32px 14px 32px',
                      margin: '16px 0px 18px 0px',
                    },
                    padding: '16px 32px 16px 32px',
                    tablet: {
                      'max-width': '72%',
                    },
                    target: '_blank',
                    uid: `ls-${uuid()}`,
                    component: 'ls-button',
                    metadata: {
                      useCase: {
                        offerLink: {
                          propName: 'href',
                          middleware: 'string',
                        },
                        purchaseButton: {
                          propName: 'label',
                          middleware: 'string',
                        },
                        secondaryColor: {
                          propName: 'background',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#303030',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '100px 0px 160px 0px',
        desktop: {},
        tablet: {
          background: '#303030',
          padding: '90px 0px 64px 0px',
        },
        mobile: {
          background: '#303030',
          padding: '72px 0px 72px 0px',
        },
        uid: `ls-${uuid()}-about-author`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 3',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '64px 80px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '0px 0px 40px 0px',
                  'align-items': 'center',
                  'justify-content': 'center',
                },
                mobile: {
                  padding: '0px 0px 24px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    alt: '',
                    'border-color': '#5981e3',
                    'border-radius': '0px 0px 0px 0px',
                    'border-style': 'none',
                    'border-top-width': '2px',
                    'border-right-width': '2px',
                    'border-bottom-width': '2px',
                    'border-left-width': '2px',
                    height: '100%',
                    href: '',
                    'is-webp': true,
                    'max-width': '550px',
                    margin: '0px 0px 0px 0px',
                    'object-fit': 'contain',
                    target: '_blank',
                    desktop: {},
                    tablet: {
                      'max-width': '400px',
                      height: '400px',
                    },
                    mobile: {
                      'max-width': '250px',
                      height: '250px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-image',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/6912702/wepik-export-20231009212715nkik.png',
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/6912702/thumb_wepik-export-20231009212715nkik.png',
                    metadata: {
                      useCase: {
                        authorImage: {
                          propName: 'src',
                          middleware: 'string',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'center',
                'align-items': 'start',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': true,
                desktop: {},
                tablet: {
                  padding: '40px 72px 40px 72px',
                },
                mobile: {
                  padding: '16px 20px 0px 20px',
                  'align-items': 'center',
                  'justify-content': 'center',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="line-height: 1.15;"><span style="font-family: Raleway, sans-serif; font-size: 16px; color: rgb(255, 255, 255); background-color: rgba(0, 0, 0, 0);">${i18next.t(
                      'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
                    )} ${i18next.t(
                      'pageWizard.salesPage.template.aboutAuthor.created'
                    )}</span></p>`,
                    'max-width': '100%',
                    padding: '0px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p><span style="font-family: Raleway, sans-serif; font-size: 16px; color: rgb(255, 255, 255); background-color: rgba(0, 0, 0, 0);">${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
                      )} ${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.created'
                      )}</span></p>`,
                      'max-width': '100%',
                      padding: '0px 10px 0px 10px',
                    },
                    mobile: {
                      text: `<p style="line-height: 1.15; text-align: center;"><span style="font-family: Raleway, sans-serif; font-size: 13px; color: rgb(255, 255, 255); background-color: rgba(0, 0, 0, 0);">${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.getToKnowWho'
                      )} ${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.created'
                      )}</span></p>`,
                      'max-width': '100%',
                      padding: '0px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 1;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">__________________________________________________________</strong></p>',
                    'max-width': '80%',
                    padding: '0px 0px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif; font-size: 14px;">_______________________________________________________________________________________</strong></p>',
                      'max-width': '100%',
                      padding: '0px 0px 10px 0px',
                    },
                    mobile: {
                      padding: '0px 10px 10px 10px',
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif; font-size: 12px;">____________________________________________________</strong></p>',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        secondaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 70px;">${i18next.t(
                      'pageWizard.salesPage.template.aboutAuthor.name'
                    )}</strong></p>`,
                    'max-width': '80%',
                    padding: '40px 10px 16px 10px',
                    desktop: {},
                    tablet: {},
                    mobile: {
                      'max-width': '100%',
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 40px;">${i18next.t(
                        'pageWizard.salesPage.template.aboutAuthor.name'
                      )}</strong></p>`,
                      padding: '24px 10px 08px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        authorName: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                  {
                    text: '<p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 16px; font-family: &quot;Nunito Sans&quot;, sans-serif;"><span class="ql-cursor">﻿﻿</span>Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p>',
                    'max-width': '85%',
                    padding: '16px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '90%',
                    },
                    mobile: {
                      text: '<p><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></p><p><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;">Ut enim ad minim veniam, quis nostrud exercitation ullamco.</span></p><p><br></p><p><span style="color: rgb(255, 255, 255); font-size: 14px; font-family: &quot;Nunito Sans&quot;, sans-serif;">Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></p>',
                      'max-width': '100%',
                      padding: '10px 24px 10px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        authorDescription: {
                          propName: 'text',
                          middleware: 'text',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#161616',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '48px 0px 48px 0px',
        desktop: {},
        tablet: {
          padding: '080px 0px 56px 0px',
        },
        mobile: {
          padding: '48px 0px 24px 0px',
        },
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 4',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '50px 0px 50px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'row',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '0px 0px 0px 0px',
                },
                mobile: {
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  padding: '8px 20px 8px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '10px 10px 10px 10px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'end',
                    'flex-direction': 'row',
                    background: 'none',
                    'background-is-webp': true,
                    'max-width': '250px',
                    margin: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {},
                    mobile: {
                      padding: '0px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        'font-size': '40px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-star',
                        color: '#AD23E8',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '40px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {
                          useCase: {
                            secondaryColor: {
                              propName: 'color',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                      {
                        'font-size': '64px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-star',
                        color: '#AD23E8',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '56px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {
                          useCase: {
                            secondaryColor: {
                              propName: 'color',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                      {
                        'font-size': '40px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-star',
                        color: '#AD23E8',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '40px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {
                          useCase: {
                            secondaryColor: {
                              propName: 'color',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                    ],
                    metadata: {},
                  },
                  {
                    text: `<p style="text-align: center; line-height: 1;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 80px;">${i18next.t(
                      'pageWizard.salesPage.template.guarantee.darkSecondary.title',
                      { interpolation: { escapeValue: false } }
                    )}</strong></p>`,
                    'max-width': '100%',
                    padding: '5px 10px 05px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="text-align: center; line-height: 1;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 64px;">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.darkSecondary.title',
                        { interpolation: { escapeValue: false } }
                      )}</strong></p>`,
                      padding: '0px 10px 0px 10px',
                      'max-width': '90%',
                    },
                    mobile: {
                      'max-width': '100%',
                      text: `<p style="text-align: center; line-height: 1;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 32px;">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.darkSecondary.title',
                        { interpolation: { escapeValue: false } }
                      )}</strong></p>`,
                      padding: '5px 10px 0px 10px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 2; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">____________________________________________________________________________________________________________________________</strong></p>',
                    'max-width': '85%',
                    padding: '0px 0px 20px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">________________________________________________________________________________</strong></p>',
                      padding: '0px 0px 24px 0px',
                    },
                    mobile: {
                      padding: '0px 0px 10px 0px',
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">_________________________________________</strong></p>',
                      'max-width': '100%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        secondaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                  {
                    text: `<p style="line-height: 1.15; text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 64px;">${i18next.t(
                      'pageWizard.salesPage.template.guarantee.darkSecondary.description'
                    )}</strong></p>`,
                    'max-width': '90%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 40px;">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.darkSecondary.description'
                      )}</strong></p>`,
                    },
                    mobile: {
                      'max-width': '100%',
                      text: `<p style="text-align:center"><strong style="color:rgb(255, 255, 255);font-family:Raleway,sans-serif;font-size:28px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.darkSecondary.description'
                      )}</strong></p>`,
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: `<p style="line-height:2;text-align:center"><span style="color:#fff;font-family:'Nunito Sans',sans-serif;font-size:24px">${i18next.t(
                      'pageWizard.salesPage.template.guarantee.description.noQuestions',
                      { interpolation: { escapeValue: false } }
                    )} ${i18next.t(
                      'pageWizard.salesPage.template.guarantee.description.time'
                    )}</span></p>`,
                    'max-width': '80%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="line-height:2;text-align:center"><span style="color:#fff;font-family:'Nunito Sans',sans-serif;font-size:20px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.noQuestions',
                        { interpolation: { escapeValue: false } }
                      )} ${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.time'
                      )}</span></p>`,
                    },
                    mobile: {
                      'max-width': '100%',
                      text: `<p style="text-align:center"><span style="color:#fff;font-family:'Nunito Sans',sans-serif;font-size:18px">${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.noQuestions',
                        { interpolation: { escapeValue: false } }
                      )} ${i18next.t(
                        'pageWizard.salesPage.template.guarantee.description.time'
                      )}</span></p>`,
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    text: '<p style="line-height: 2; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">____________________________________________________________________________________________________________________________</strong></p>',
                    'max-width': '85%',
                    padding: '10px 0px 0px 10px',
                    desktop: {},
                    tablet: {
                      text: '<p style="line-height: 1;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">________________________________________________________________________________</strong></p>',
                      padding: '20px 0px 0px 10px',
                    },
                    mobile: {
                      padding: '5px 0px 10px 0px',
                      text: '<p style="line-height: 1; text-align: center;"><strong style="color: rgb(173, 35, 232); font-family: Montserrat, sans-serif;">_________________________________________<span class="ql-cursor">﻿﻿</span></strong></p>',
                      'max-width': '100%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {
                      useCase: {
                        secondaryColor: {
                          propName: 'text',
                          middleware: 'textColor',
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#DB2C8C',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}-offer`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 5',
          useCase: {
            primaryColor: {
              propName: 'background',
              middleware: 'string',
            },
          },
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 120px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {
                  padding: '24px 0px 120px 0px',
                },
                mobile: {
                  padding: '16px 20px 56px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    padding: '0px 32px 32px 32px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'end',
                    'flex-direction': 'row',
                    background: 'transparent',
                    'background-is-webp': true,
                    'max-width': '100%',
                    margin: '100px 10px 0px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '80%',
                      margin: '48px 10px 0px 10px',
                      padding: '40px 32px 16px 32px',
                    },
                    mobile: {
                      padding: '24px 32px 10px 32px',
                      margin: '24px 10px 0px 10px',
                      'max-width': '90%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        'font-size': '48px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '100px 100px 100px 100px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-tablet-alt',
                        color: '#FFFFFF',
                        margin: '0px 20px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '38px',
                          margin: '0px 8px 0px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        'font-size': '64px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '4px 4px 4px 4px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-laptop',
                        color: '#FFFFFF',
                        margin: '0px 0px 0px 0px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '50px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        'font-size': '48px',
                        background: 'none',
                        'border-color': '#EF4E23',
                        'border-radius': '4px 4px 4px 4px',
                        'border-style': 'none',
                        'border-top-width': '2px',
                        'border-right-width': '2px',
                        'border-bottom-width': '2px',
                        'border-left-width': '2px',
                        'is-square': true,
                        icon: 'fas fa-mobile-alt',
                        color: '#FFFFFF',
                        margin: '0px 0px 0px 10px',
                        desktop: {},
                        tablet: {},
                        mobile: {
                          'font-size': '38px',
                          margin: '0px 0px 0px 4px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-icon',
                        metadata: {},
                      },
                      {
                        text: `<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 20px;">${i18next.t(
                          'pageWizard.salesPage.template.offer.anyDevice'
                        )}</span></p>`,
                        'max-width': '100%',
                        padding: '10px 10px 10px 10px',
                        desktop: {},
                        tablet: {
                          'max-width': '90%',
                          text: `<p><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.anyDevice'
                          )}</span></p>`,
                          padding: '10px 10px 10px 20px',
                        },
                        mobile: {
                          text: `<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255); font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.anyDevice'
                          )}</span></p>`,
                          padding: '8px 10px 10px 10px',
                          'max-width': '100%',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {},
                  },
                  {
                    padding: '32px 10px 10px 10px',
                    'border-radius': '0px 0px 0px 0px',
                    'justify-content': 'center',
                    'align-items': 'center',
                    'flex-direction': 'column',
                    background: '#161616',
                    'background-is-webp': true,
                    'max-width': '55%',
                    margin: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '70%',
                    },
                    mobile: {
                      'max-width': '90%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-group',
                    children: [
                      {
                        text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                          'pageWizard.salesPage.template.offer.priceStatement'
                        )}</span></p>`,
                        'max-width': '72%',
                        padding: '10px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.priceStatement'
                          )}</span></p>`,
                        },
                        mobile: {
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.priceStatement'
                          )}</span></p>`,
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                      {
                        text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">${maxInstallments}</span></p>`,
                        'max-width': '72%',
                        padding: '16px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          text: `<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;">${maxInstallments}</span></p>`,
                        },
                        mobile: {
                          text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;">${maxInstallments}</span></p>`,
                          padding: '20px 0px 10px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          installments: true,
                        },
                      },
                      {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 120px;">R$ 39,90</strong></p>',
                        'max-width': '80%',
                        padding: '0px 0px 24px 0px',
                        desktop: {},
                        tablet: {
                          'max-width': '90%',
                          text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 100px;">R$ 39,90</strong></p>',
                          padding: '16px 0px 16px 0px',
                        },
                        mobile: {
                          text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 60px;">R$ 39,90</strong></p>',
                          'max-width': '100%',
                          padding: '5px 0px 5px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {
                          useCase: {
                            offerPrice: {
                              propName: 'text',
                              middleware: 'text',
                            },
                          },
                        },
                      },
                      {
                        action: 'link',
                        color: '#FFFFFF',
                        background: '#AD23E8',
                        'border-color': '#000000',
                        'border-radius': '0px 0px 0px 0px',
                        'border-style': 'none',
                        'border-top-width': '1px',
                        'border-right-width': '1px',
                        'border-bottom-width': '1px',
                        'border-left-width': '1px',
                        'button-class': '',
                        desktop: {},
                        'end-date': '2023-05-26T13:48:23',
                        timezone: '-03:00_utcMinus0300MainCities',
                        'time-on-page': '5',
                        'time-on-page-unit': 'seconds',
                        'delay-type': 'disabled',
                        'font-family': 'Raleway, sans-serif',
                        'font-size': '20px',
                        'hover-background': 'none',
                        'hover-color': 'none',
                        'has-icon': false,
                        'has-text': true,
                        href: '',
                        'icon-position': 'right',
                        icon: 'fas fa-arrow-right',
                        'is-bold': true,
                        'is-italic': false,
                        'is-underline': false,
                        'is-strike': false,
                        label: i18next.t('pageWizard.salesPage.template.CTA'),
                        margin: '24px 0px 0px 0px',
                        'max-width': '40%',
                        mobile: {
                          'max-width': '80%',
                          'font-size': '18px',
                          padding: '14px 32px 14px 32px',
                          margin: '24px 0px 10px 0px',
                        },
                        padding: '16px 32px 16px 32px',
                        tablet: {
                          'max-width': '72%',
                        },
                        target: '_blank',
                        uid: `ls-${uuid()}`,
                        component: 'ls-button',
                        metadata: {
                          useCase: {
                            offerLink: {
                              propName: 'href',
                              middleware: 'string',
                            },
                            purchaseButton: {
                              propName: 'label',
                              middleware: 'string',
                            },
                            secondaryColor: {
                              propName: 'background',
                              middleware: 'string',
                            },
                          },
                        },
                      },
                      {
                        text: `<p style="text-align: center;"><strong style="font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 12px;">${i18next.t(
                          'pageWizard.salesPage.template.offer.secure'
                        )}</strong></p>`,
                        'max-width': '60%',
                        padding: '24px 0px 10px 0px',
                        desktop: {},
                        tablet: {
                          'max-width': '64%',
                        },
                        mobile: {
                          'max-width': '94%',
                          text: `<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 10px;">${i18next.t(
                            'pageWizard.salesPage.template.offer.secure'
                          )}</strong></p>`,
                          padding: '0px 0px 8px 0px',
                        },
                        uid: `ls-${uuid()}`,
                        component: 'ls-text',
                        metadata: {},
                      },
                    ],
                    metadata: {
                      useCase: {
                        offerInstallments: {
                          propName: 'text',
                          structureMiddleware: 'offerInstallments',
                          props: {
                            parent: 'ls-suoq4kYGejqhM6YxnCvAVf',
                          },
                        },
                      },
                    },
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#303030',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-07-26T21:42:51',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '110px 0px 140px 0px',
        desktop: {},
        tablet: {
          padding: '90px 0px 90px 0px',
        },
        mobile: {
          padding: '56px 0px 56px 0px',
        },
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 7',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '0px 0px 0px 0px',
                'justify-content': 'start',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {},
                mobile: {
                  padding: '0px 20px 0px 20px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 80px;">${i18next
                      .t('pageWizard.salesPage.template.FAQ.title')
                      .toUpperCase()}</strong></p>`,
                    'max-width': '100%',
                    padding: '10px 10px 48px 10px',
                    desktop: {},
                    tablet: {
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 80px;">${i18next
                        .t('pageWizard.salesPage.template.FAQ.title')
                        .toUpperCase()}</strong></p>`,
                      padding: '10px 48px 40px 48px',
                    },
                    mobile: {
                      padding: '10px 10px 20px 10px',
                      'max-width': '100%',
                      text: `<p style="line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-family: Raleway, sans-serif; font-size: 40px;">${i18next
                        .t('pageWizard.salesPage.template.FAQ.title')
                        .toUpperCase()}</strong></p>`,
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                  {
                    margin: '6px 6px 6px 6px',
                    padding: '10px 10px 10px 10px',
                    'max-width': '90%',
                    color: '#FFFFFF',
                    background: 'none',
                    'header-background': '#303030',
                    'content-background': '#303030',
                    'icon-color': '#FFFFFF',
                    'vertical-spacing': '40px',
                    'initial-behavior': 'close',
                    items: `[{"id":"e5a8d97b-a547-4882-8acd-3071860877be","title":{"text":"<h3><strong style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255);\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.question'
                    )}</strong></h3>","padding":"16px 24px 16px 24px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1.15;\\"><strong style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.question'
                    )}</strong></h3>","padding":"10px 10px 010px 10px"},"tablet":{"text":"<h3><strong style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.question'
                    )}</strong></h3>"},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>","padding":"24px 24px 24px 24px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.first.answer'
                    )}&nbsp;</span></p>","padding":"18px 10px 18px 10px"},"tablet":{},"desktop":{},"metadata":{}}},{"id":"ajgXKNXRw7Zt1K37HNzRhi","title":{"text":"<h3><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255);\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.question'
                    )}</span></h3>","padding":"16px 24px 16px 24px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1.15;\\"><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.question'
                    )}</span></h3>","padding":"10px 10px 10px 10px"},"tablet":{"text":"<h3><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.question'
                    )}</span></h3>"},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.answer'
                    )}&nbsp;</span></p>","padding":"24px 24px 24px 24px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.second.answer'
                    )}&nbsp;</span></p>","padding":"18px 10px 18px 10px"},"tablet":{},"desktop":{},"metadata":{}}},{"id":"ds79hyCmsvwBMegfdQbpcF","title":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.question'
                    )}</span></h3>","padding":"16px 24px 16px 24px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1.15;\\"><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.question'
                    )}</span></h3>","padding":"10px 10px 10px 10px"},"tablet":{"text":"<h3><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.question'
                    )}</span></h3>"},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.answer'
                    )}&nbsp;</span></p>","padding":"24px 24px 24px 24px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.third.answer'
                    )}&nbsp;</span></p>","padding":"18px 10px 18px 10px"},"tablet":{},"desktop":{},"metadata":{}}},{"id":"57RRF23Lrf5bAe5FWkx7fp","title":{"text":"<h3><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255);\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.question'
                    )}</span></h3>","padding":"16px 24px 16px 24px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1.15;\\"><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.question'
                    )}</span></h3>","padding":"10px 10px 10px 10px"},"tablet":{"text":"<h3><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.question'
                    )}</span></h3>"},"desktop":{},"metadata":{}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.title'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.first'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.second'
                    )}&nbsp;</span></p><p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.third'
                    )}</span></p>","padding":"24px 24px 24px 24px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.title'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.first'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.second'
                    )}&nbsp;</span></p><p><br></p><p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fourth.answer.items.third'
                    )}</span></p>","padding":"18px 10px 18px 10px"},"tablet":{},"desktop":{},"metadata":{}}},{"id":"5uUGAukKXSdDWyFjkRcU5d","title":{"text":"<h3><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255);\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.question'
                    )}</span></h3>","padding":"16px 24px 16px 24px","max-width":"100%","mobile":{"text":"<h3 style=\\"line-height: 1.15;\\"><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 18px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.question'
                    )}</span></h3>","padding":"10px 10px 10px 10px"},"tablet":{"text":"<h3><span style=\\"font-family: &quot;Nunito Sans&quot;, sans-serif; color: rgb(255, 255, 255); font-size: 20px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.question'
                    )}</span></h3>"},"desktop":{},"metadata":{"unlinkedProps":{"mobile":{"text":true,"padding":true},"tablet":{"text":true},"desktop":{}}}},"content":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.answer'
                    )}</span></p>","padding":"24px 24px 24px 24px","max-width":"100%","mobile":{"text":"<p><span style=\\"color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 14px;\\">${i18next.t(
                      'pageWizard.salesPage.template.FAQ.fifth.answer'
                    )}</span></p>","padding":"18px 10px 18px 10px"},"tablet":{},"desktop":{},"metadata":{}}}]`,
                    desktop: {},
                    tablet: {
                      'max-width': '91%',
                      'vertical-spacing': '48px',
                    },
                    mobile: {
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      'vertical-spacing': '32px',
                      margin: '6px 0px 6px 0px',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-accordion',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
      {
        background: '#161616',
        'background-is-webp': false,
        tag: 'section',
        'is-fixed': false,
        'end-date': '2023-05-26T13:48:23',
        timezone: '-03:00_utcMinus0300MainCities',
        'time-on-page': '5',
        'time-on-page-unit': 'seconds',
        'delay-type': 'disabled',
        padding: '0px 0px 0px 0px',
        desktop: {},
        tablet: {},
        mobile: {},
        uid: `ls-${uuid()}`,
        component: 'ls-section',
        metadata: {
          label: 'Seção 6',
        },
        children: [
          {
            background: 'none',
            'background-is-webp': false,
            padding: '0px 0px 0px 0px',
            'flex-direction': 'row',
            desktop: {},
            tablet: {
              'flex-direction': 'column',
            },
            mobile: {
              'flex-direction': 'column',
            },
            uid: `ls-${uuid()}`,
            component: 'ls-row',
            children: [
              {
                padding: '40px 0px 40px 0px',
                'justify-content': 'center',
                'align-items': 'center',
                'flex-direction': 'column',
                size: 12,
                background: 'none',
                'background-is-webp': false,
                desktop: {},
                tablet: {},
                mobile: {
                  padding: '30px 0px 30px 0px',
                },
                uid: `ls-${uuid()}`,
                component: 'ls-column',
                children: [
                  {
                    text: `<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; font-size: 14px; color: rgb(255, 255, 255);">Copyright ©</span></p><p style="text-align: center;"><span style="font-family: Raleway, sans-serif; font-size: 14px; color: rgb(255, 255, 255);">${i18next.t(
                      'pageWizard.salesPage.template.copyright'
                    )}</span></p>`,
                    'max-width': '40%',
                    padding: '10px 10px 10px 10px',
                    desktop: {},
                    tablet: {
                      'max-width': '50%',
                      text: `<p style="text-align: center; line-height: 2;"><span style="font-family: Raleway, sans-serif; font-size: 14px; color: rgb(255, 255, 255);">Copyright ©</span></p><p style="text-align: center; line-height: 2;"><span style="font-family: Raleway, sans-serif; font-size: 14px; color: rgb(255, 255, 255);">${i18next.t(
                        'pageWizard.salesPage.template.copyright'
                      )}</span></p>`,
                    },
                    mobile: {
                      text: `<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; font-size: 12px; color: rgb(255, 255, 255);">Copyright ©</span></p><p style="text-align: center;"><span style="font-family: Raleway, sans-serif; font-size: 12px; color: rgb(255, 255, 255);">${i18next.t(
                        'pageWizard.salesPage.template.copyright'
                      )}</span></p>`,
                      'max-width': '80%',
                    },
                    uid: `ls-${uuid()}`,
                    component: 'ls-text',
                    metadata: {},
                  },
                ],
                metadata: {},
              },
            ],
            metadata: {},
          },
        ],
      },
    ],
    metadata: {},
  };
};

export const getDarkSecondaryFontsConfig = () => ({
  Raleway: {
    regular: [400, 700],
    italic: [400, 700],
  },
  Montserrat: {
    regular: [400, 700],
    italic: [400, 700],
  },
  'Nunito Sans': {
    regular: [400, 700],
    italic: [400, 700],
  },
});
