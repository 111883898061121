import React, { HTMLAttributes, memo, useMemo } from 'react';
import { getAddableSectionsByCategory } from '@components/Submenus/components/SectionsListSubmenu/addable-sections';
import { PageFragmentList } from '@components/PageFragmentList';
import { addablePopups } from '@components/Submenus/components/PopupsListSubmenu/addable-popups';
import { EmptyState as SectionEmptyState } from '@components/Submenus/components/SavedSectionsSubmenu/components/EmptyState';
import { EmptyState as PopupEmptyState } from '@components/Submenus/components/SavedPopupsSubmenu/components/EmptyState';
import { useSavedSections, useSavedPopups } from '@hooks/index';
import { Container } from './styles';

export type ElementTemplatesProps = HTMLAttributes<HTMLDivElement> & {
  onTemplateSelected?: (item: PageFragmentListItem) => void;
  elementType: 'section' | 'popup';
  selectedFragmentId?: string;
  activeTemplateId?: string;
};

const PreviewCardsComponent: React.FC<ElementTemplatesProps> = ({
  onTemplateSelected,
  elementType,
  selectedFragmentId,
  activeTemplateId,
  ...attrs
}) => {
  const { userSections, loading } = useSavedSections();
  const { userPopups } = useSavedPopups();

  const sectionsList = useMemo(
    () =>
      activeTemplateId
        ? getAddableSectionsByCategory(
            activeTemplateId as AddableSectionCategory
          )
        : [],

    [activeTemplateId]
  );

  const itemList = useMemo(() => {
    if (elementType === 'section') {
      return activeTemplateId === 'savedSections' ? userSections : sectionsList;
    }
    return activeTemplateId === 'saved-popups' ? userPopups : addablePopups();
  }, [elementType, activeTemplateId, userPopups, userSections, sectionsList]);

  const isSavedElement = useMemo(
    () =>
      activeTemplateId === 'savedSections' ||
      activeTemplateId === 'saved-popups',
    [activeTemplateId]
  );

  const showSectionEmptyState = useMemo(
    () => activeTemplateId === 'savedSections' && !userSections.length,
    [activeTemplateId, userSections.length]
  );

  const showPopupEmptyState = useMemo(
    () => activeTemplateId === 'saved-popups' && !userPopups.length,
    [activeTemplateId, userPopups.length]
  );

  const showEmptyState = useMemo(
    () => showSectionEmptyState || showPopupEmptyState,
    [showPopupEmptyState, showSectionEmptyState]
  );

  return (
    <Container {...attrs} centered={showEmptyState}>
      {showSectionEmptyState && <SectionEmptyState />}

      {showPopupEmptyState && <PopupEmptyState hideActions />}

      {Boolean(itemList.length) && (
        <PageFragmentList
          selectionMode
          groupName={
            elementType === 'section' ? 'addableSections' : 'addablePopups'
          }
          items={itemList}
          loading={loading}
          selectedFragment={selectedFragmentId}
          onFragmentChange={(item) => onTemplateSelected?.(item)}
          hideBorder={isSavedElement}
          hideActions={isSavedElement}
          hideToolbar={!isSavedElement}
        />
      )}
    </Container>
  );
};

export const PreviewCards = memo(PreviewCardsComponent);
