import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { saveUrlParam, saveUrlParamBuilder } from './saveUrlParam';
import { deleteUrlParam, deleteUrlParamBuilder } from './deleteUrlParam';

export const pageConfigThunks = { saveUrlParam, deleteUrlParam };

export const pageConfigExtraReducers = (
  builder: ActionReducerMapBuilder<PageConfigState>
) => {
  saveUrlParamBuilder(builder);
  deleteUrlParamBuilder(builder);
};
