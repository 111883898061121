import { useEffect } from 'react';
import { useMenu } from '@hooks/useMenu';
import { useEngine } from '@hooks/useEngine';
import { CosmosLoader } from '@components/CosmosLoader';
import { submenus } from './submenus';

const formsSubmenu = 'forms';
const timerSubmenu = 'timer';
const adjustmentSubmenu = 'adjustments';

export const Submenus: React.FC = () => {
  const { activeSubmenu, submenuLoading, setActiveMenu } = useMenu();
  const { activeComponent } = useEngine();

  useEffect(() => {
    if (activeComponent) {
      const { isFormConfig, isTimerConfig } = activeComponent;

      if (isFormConfig && activeSubmenu !== formsSubmenu) {
        setActiveMenu(formsSubmenu);
      }

      if (isTimerConfig && activeSubmenu !== timerSubmenu) {
        setActiveMenu(timerSubmenu);
      }

      if (
        !isFormConfig &&
        !isTimerConfig &&
        activeSubmenu !== adjustmentSubmenu
      ) {
        setActiveMenu(adjustmentSubmenu);
      }
    }
  }, [activeComponent, activeSubmenu, setActiveMenu]);

  return submenuLoading ? (
    <CosmosLoader />
  ) : (
    <>{activeSubmenu && submenus[activeSubmenu]}</>
  );
};
