import i18next from 'i18next';

export const getTypeOptions = () => [
  {
    id: 'community',
    value: 'community',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.community'
    ),
  },
  {
    id: 'course',
    value: 'course',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.course'
    ),
  },
  {
    id: 'ebook',
    value: 'ebook',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.ebook'
    ),
  },
  {
    id: 'event',
    value: 'event',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.event'
    ),
  },
  {
    id: 'mentorship',
    value: 'mentorship',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.mentorship'
    ),
  },
  {
    id: 'spreadsheet',
    value: 'spreadsheet',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.spreadsheet'
    ),
  },
  {
    id: 'other',
    value: 'other',
    label: i18next.t(
      'pageWizard.publishLeadCapturePage.ai.sidebar.type.options.other'
    ),
  },
];
