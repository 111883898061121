import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';

export const searchMedia = createAsyncThunk<SearchMediaResponse, string>(
  'mediaCenter/searchMedia',
  async (searchQuery) => {
    try {
      const mediaResponse = searchQuery
        ? await MediaCenter.getMediaBySearchString(searchQuery)
        : await MediaCenter.getRootMedia();
      return mediaResponse;
    } catch (error) {
      const serializedError = error as SerializedError;
      throw serializedError;
    }
  }
);

export const searchMediaBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(searchMedia.pending, (state: MediaCenterState, action) => {
    state.breadcrumbs = [];
    state.search = action.meta.arg;
    state.loading = true;
  });
  builder.addCase(searchMedia.fulfilled, (state: MediaCenterState, action) => {
    state.media = action.payload;
    state.loading = false;
  });
  builder.addCase(searchMedia.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
