import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Prepend, Append } from './styles';
import { minify } from '../../common/utils';
import '@hotmart-org-ca/cosmos/dist/tooltip';
import { tooltipStyle } from '../../styles/global';

export type LabelProps = HTMLAttributes<HTMLDivElement> & {
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  label?: string;
  htmlFor?: string;
  infoTooltip?: {
    displayMode?: 'hover' | 'always';
    title?: string;
    description?: string;
  };
};

const LabelComponent: React.FC<LabelProps> = ({
  prepend,
  append,
  label = '',
  htmlFor,
  infoTooltip,
  ...attrs
}) => {
  const { t } = useTranslation();

  const renderTooltip = useCallback(() => {
    if (infoTooltip) {
      return minify(
        `<style>${tooltipStyle}</style>
        <div class="custom-tooltip__label">${t(`${infoTooltip?.title}`)}</div>
        <div class="custom-tooltip__description">${t(
          `${infoTooltip.description}`
        )}</div>`
      );
    }
    return '';
  }, [infoTooltip, t]);

  return (
    <Wrapper {...attrs} displayMode={infoTooltip?.displayMode || 'hover'}>
      {prepend && <Prepend>{prepend}</Prepend>}
      <label htmlFor={htmlFor}>{t(label)}</label>
      {append && <Append>{append}</Append>}
      {infoTooltip && (
        <hot-tooltip content={renderTooltip()} position="right">
          <i className="fal fa-info-circle" />
        </hot-tooltip>
      )}
    </Wrapper>
  );
};

export const Label = memo(LabelComponent);
