import React, { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Submenu } from '@components/Submenu';
import { useMenu } from '@hooks/useMenu';
import { usePageConfig } from '@hooks/usePageConfig';
import {
  AddButton,
  AddIcon,
  Description,
  EmptyList,
  IconGroup,
  Link,
  UrlParam,
  UrlParamsList,
  UrlParamName,
} from './styles';
import { RemoveUrlParamModal } from './components/RemoveUrlParamModal';

export type urlParamsSubmenuProps = HTMLAttributes<HTMLDivElement>;

export const UrlParamsSubmenu: React.FC<urlParamsSubmenuProps> = ({
  ...attrs
}) => {
  const { t } = useTranslation();

  const { clearUrlParamForm, setActiveSubmenu, setUrlParamForm } = useMenu();
  const { urlParams } = usePageConfig();

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleAddUrlParam = useCallback(() => {
    clearUrlParamForm();
    setActiveSubmenu('url-params-form');
  }, [clearUrlParamForm, setActiveSubmenu]);

  const handleEditUrlParam = useCallback(
    (urlParam: UrlParam) => {
      setUrlParamForm({ selectedUrlParam: urlParam });
      setActiveSubmenu('url-params-form');
    },
    [setActiveSubmenu, setUrlParamForm]
  );

  const handleRemoveUrlParam = useCallback(
    (urlParam: UrlParam) => {
      setUrlParamForm({ selectedUrlParam: urlParam });
      setShowRemoveModal(true);
    },
    [setUrlParamForm]
  );

  const onModalClose = useCallback(() => {
    setShowRemoveModal(false);
    clearUrlParamForm();
  }, [clearUrlParamForm]);

  return (
    <Submenu title="sideMenu.settings.urlParams" {...attrs}>
      <Description>
        <span>
          {t('urlParams.description')}{' '}
          <Link
            href="https://suporteklickpages.zendesk.com/hc/pt-br/articles/4587902475917-Como-criar-v%C3%A1rias-campanhas-utilizando-par%C3%A2metros-na-URL-no-Klickpages"
            text={t('urlParams.info')}
          />
        </span>
      </Description>

      <AddButton
        className="hot-button hot-button--secondary hot-button--sm"
        onClick={handleAddUrlParam}
      >
        <AddIcon className="fal fa-plus" />
        <span>{t('urlParams.add', { type: 'head' })}</span>
      </AddButton>

      <UrlParamsList>
        {urlParams?.map((param: UrlParam) => (
          <UrlParam key={param.id}>
            <UrlParamName>{param.name}</UrlParamName>

            <hot-tooltip position="top" content={t('urlParams.edit')}>
              <IconGroup
                className="icon-group"
                onClick={() => handleEditUrlParam(param)}
              >
                <i className="fal fa-pen" />
              </IconGroup>
            </hot-tooltip>

            <hot-tooltip position="top" content={t('urlParams.delete')}>
              <IconGroup
                className="icon-group"
                onClick={() => handleRemoveUrlParam(param)}
              >
                <i className="fal fa-trash-alt" />
              </IconGroup>
            </hot-tooltip>
          </UrlParam>
        ))}
      </UrlParamsList>

      {!urlParams?.length && <EmptyList>{t('urlParams.emptyList')}</EmptyList>}

      <RemoveUrlParamModal isOpen={showRemoveModal} onClose={onModalClose} />
    </Submenu>
  );
};
