import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const defaultLanguage = 'en';

export const acceptedLanguages = ['en', 'es', 'pt-BR'];

export const defaultI18nConfig: InitOptions = {
  fallbackLng: ['en', 'pt-BR'],
  defaultNS: 'translations',
  compatibilityJSON: 'v4',
};

export const fetchResources = (languages: string[] = acceptedLanguages) => {
  const promises: Promise<any>[] = [];

  languages.forEach((language) => {
    promises.push(import(`./locales/${language.toLowerCase()}.json`));
  });

  return Promise.all(promises);
};

const getResources = async () => {
  const [en, es, ptBr] = await fetchResources();

  return {
    en,
    es,
    'pt-BR': ptBr,
  };
};

export const i18nInit = async () => {
  const i18nConfig: InitOptions = {
    ...defaultI18nConfig,
    resources: await getResources(),
  };

  return i18n.use(initReactI18next).init(i18nConfig);
};

const getNormalizedLink = (link: string) => {
  const normalizedLink = link.startsWith('/') ? link : `/${link}`;

  return normalizedLink.endsWith('/')
    ? normalizedLink.slice(0, -1)
    : normalizedLink;
};

export const getLocaleLink = (link: string, lang?: string) => {
  const language = lang || i18n.language;
  const normalizedLink = getNormalizedLink(link);

  if (language === defaultLanguage) {
    return normalizedLink;
  }
  return `/${language}${normalizedLink}`;
};

export default i18n;
