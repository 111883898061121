import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-radio.css';
import { memo, HTMLAttributes, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import {
  Description,
  NewSuggestionsButtonWrapper,
  RadioWrapper,
  Title,
} from './styles';

type SuggestionsProps = HTMLAttributes<HTMLDivElement> & {
  suggestions: AiTextsSuggestions;
  selectedSuggestions: Omit<UpdatedProductProps, 'description'>;
  onGenerateNewButtonClicked: () => void;
  onSuggestionSelected: (
    value: Omit<UpdatedProductProps, 'description'>
  ) => void;
};

const SuggestionsComponent: React.FC<SuggestionsProps> = ({
  suggestions,
  selectedSuggestions,
  onGenerateNewButtonClicked,
  onSuggestionSelected,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleSelectedOption = useCallback(
    (suggestionType: keyof AiTextsSuggestions, value: string) => {
      onSuggestionSelected({ ...selectedSuggestions, [suggestionType]: value });
    },
    [onSuggestionSelected, selectedSuggestions]
  );

  useEffect(() => {
    if (!selectedSuggestions.title && !selectedSuggestions.subtitle) {
      onSuggestionSelected({
        title: suggestions.title[0],
        subtitle: suggestions.subtitle[0],
      });
    }
  }, [
    onSuggestionSelected,
    selectedSuggestions.subtitle,
    selectedSuggestions.title,
    suggestions.subtitle,
    suggestions.title,
  ]);

  return (
    <div {...attrs}>
      {Object.keys(suggestions).map((suggestionType) => (
        <div key={`${suggestionType}-suggestions`}>
          <Title>
            {t(
              `pageWizard.salesPage.paidWithProduct.sidebar.presentProduct.suggestions.${suggestionType}.title`
            )}
          </Title>
          <Description>
            {t(
              `pageWizard.salesPage.paidWithProduct.sidebar.presentProduct.suggestions.${suggestionType}.description`
            )}
          </Description>
          <RadioWrapper className="hot-form hot-form--custom hot-form--radio">
            {suggestions[suggestionType as keyof AiTextsSuggestions].map(
              (suggestion, index) => (
                <div key={`${suggestionType}-${index + 1}`}>
                  <input
                    id={`${suggestionType}-${index + 1}`}
                    type="radio"
                    className="hot-form__input"
                    checked={
                      suggestion ===
                      selectedSuggestions[
                        suggestionType as keyof AiTextsSuggestions
                      ]
                    }
                    onChange={() => {
                      handleSelectedOption(
                        suggestionType as keyof AiTextsSuggestions,
                        suggestion
                      );
                    }}
                  />
                  <label
                    className="hot-form__label"
                    htmlFor={`${suggestionType}-${index + 1}`}
                  >
                    {suggestion}
                  </label>
                </div>
              )
            )}
          </RadioWrapper>
        </div>
      ))}
      <NewSuggestionsButtonWrapper>
        <CosmosButton
          onButtonClicked={onGenerateNewButtonClicked}
          label={t(
            'pageWizard.salesPage.paidWithProduct.sidebar.presentProduct.suggestions.newSuggestions'
          )}
          type="tertiary"
          size="md"
          width="auto"
        />
      </NewSuggestionsButtonWrapper>
    </div>
  );
};

export const Suggestions = memo(SuggestionsComponent);
