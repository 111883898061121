import i18next from 'i18next';
import '@hotmart-org-ca/cosmos/dist/popover';

import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Klickart from '@services/klickart';
import { useUserInfo } from '@hooks/useUserInfo';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { BodyPopover } from '@components/BodyPopover';
import TagManager from 'react-gtm-module';
import { Container, Icon, Option, Options } from './styles';

type HelperOptionIds = 'onboarding' | 'support';

type HelperOptions = {
  id: HelperOptionIds;
  label: string;
};

export const HelpButton: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const { isFreemium } = useUserInfo();
  const { zendeskMessengerEnabled } = useFeatureFlags();
  const [showHelpOption, setShowHelpOption] = useState(false);
  const [display, setDisplay] = useState(false);

  const eventNameById: {
    support: string;
    onboarding: string;
  } = useMemo(
    () => ({
      support: 'Help::Chat',
      onboarding: 'Help::Tour',
    }),
    []
  );

  const zendeskWidgetName = useMemo(
    () => (zendeskMessengerEnabled ? 'messenger' : 'webWidget'),
    [zendeskMessengerEnabled]
  );

  const merlinScriptId = useMemo(
    () =>
      isFreemium
        ? 'fc30776a-80a5-4a05-bfa6-14b68f7e23ab'
        : '3794fb4d-a261-46b8-901a-ba7789b7ce74',
    [isFreemium]
  );

  const defaultOptions = useMemo<HelperOptions[]>(
    () => [
      {
        id: 'onboarding',
        label: 'sideMenu.help.onboarding',
      },
    ],
    []
  );

  const options = useMemo<HelperOptions[]>(() => {
    if (showHelpOption) {
      return [
        { id: 'support', label: 'sideMenu.help.support' },
        ...defaultOptions,
      ];
    }
    return defaultOptions;
  }, [defaultOptions, showHelpOption]);

  const popoverStyle = useMemo(
    () =>
      showHelpOption
        ? 'top: unset; bottom: -40px'
        : 'top: unset; bottom: -20px',
    [showHelpOption]
  );

  const arrowStyle = useMemo(
    () => (showHelpOption ? 'top: unset; bottom: 8px;' : ''),
    [showHelpOption]
  );

  const loadChatMerlin = useCallback(() => {
    const merlinContainer = '#merlin-container';
    const merlinDiv =
      window.document.querySelector<HTMLElement>(merlinContainer)!;

    if (merlinDiv.innerHTML === '') {
      const merlinScript = `https://app.gomerlin.com.br/chat/${merlinScriptId}`;
      window.Merlin?.Container?.initFromSource(merlinScript, merlinContainer);
      merlinDiv.style.display = 'block';
      setDisplay(true);
    } else {
      merlinDiv.style.display = display ? 'none' : 'block';
      merlinDiv.innerHTML = '';
      setDisplay(!display);
    }
  }, [display, merlinScriptId]);

  const setZendeskLocale = useCallback(() => {
    const locale = i18next.language;

    if (zendeskWidgetName === 'messenger') {
      window.zE!('messenger:set', 'locale', locale);
    } else {
      window.zE!.setLocale(locale);
    }
  }, [zendeskWidgetName]);

  const setZendeskWidgetListeners = useCallback(() => {
    const merlinDiv = document.querySelector('#merlin-container');

    window.zE!(`${zendeskWidgetName}:on`, 'close', () => {
      setDisplay(false);

      setTimeout(() => {
        window.zE!(`${zendeskWidgetName}`, 'hide');
      }, 600000);
    });

    window.zE!(`${zendeskWidgetName}:on`, 'open', () => {
      if (merlinDiv) {
        merlinDiv.innerHTML = '';
      }

      setDisplay(false);
      setZendeskLocale();
      setTimeout(() => {
        window.zE!(`${zendeskWidgetName}`, 'show');
      }, 500);
    });
  }, [setZendeskLocale, zendeskWidgetName]);

  const waitForElements = useCallback(() => {
    if (typeof window.zE !== 'undefined') {
      setZendeskWidgetListeners();
    } else {
      setTimeout(waitForElements, 250);
    }
  }, [setZendeskWidgetListeners]);

  const handleZendeskLogin = useCallback((token: string) => {
    window.zE!(
      'messenger',
      'loginUser',
      (callback: (token: string) => void) => {
        callback(token);
      }
    );
  }, []);

  const getZendeskToken = useCallback(async () => {
    const zendeskToken = (window as any).UniversalNavigator?.data?.user.token;
    if (!zendeskToken) {
      const { token } = await Klickart.getZendeskToken();
      (window as any).UniversalNavigator = { data: { user: { token } } };
      handleZendeskLogin(token);
    }
  }, [handleZendeskLogin]);

  const openSupportChat = useCallback(() => {
    loadChatMerlin();

    if (zendeskMessengerEnabled && !isFreemium) {
      getZendeskToken();
    }
  }, [getZendeskToken, isFreemium, loadChatMerlin, zendeskMessengerEnabled]);

  const optionsCallback = useMemo<Record<HelperOptionIds, () => void>>(
    () => ({
      onboarding: () =>
        window.dispatchEvent(new CustomEvent('open-onboarding-steps-modal')),
      support: openSupportChat,
    }),
    [openSupportChat]
  );

  const handleOptionClick = useCallback(
    (id: HelperOptionIds) => {
      optionsCallback[id]();
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_menu_editor',
          event_details: eventNameById[id],
        },
      });
    },
    [eventNameById, optionsCallback]
  );

  useEffect(() => {
    const scriptSrc = 'https://scripts.gomerlin.com.br/merlin.js';

    if (!window.document.querySelector(`[src='${scriptSrc}']`)) {
      const merlinScript = window.document.createElement('script');
      merlinScript.setAttribute('src', scriptSrc);
      window.document.head.appendChild(merlinScript);
    }

    if (!isFreemium) {
      waitForElements();
    }
  }, [isFreemium, waitForElements]);

  useEffect(() => {
    let retries = 0;

    const waitForMerlin = setInterval(() => {
      if (retries === 5) {
        clearInterval(waitForMerlin);
        return;
      }

      if (window.Merlin) {
        setShowHelpOption(true);
        clearInterval(waitForMerlin);
        return;
      }

      retries += 1;
    }, 1000);
  }, []);

  return (
    <Container role="menuitem" id="helper-button" {...attrs}>
      <Icon icon={['fal', 'question-circle']} />

      <BodyPopover
        position="right"
        elementSelector="#helper-button"
        dismissible
        popoverCustomStyle={popoverStyle}
        arrowCustomStyle={arrowStyle}
      >
        <Options slot="body" className="hot-popover__body">
          {options.map(({ id, label }) => (
            <Option key={id} onClick={() => handleOptionClick(id)}>
              {t(label)}
            </Option>
          ))}
        </Options>
      </BodyPopover>
    </Container>
  );
};
