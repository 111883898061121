import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar } from '@components/Sidebar';
import { useUseCases } from '@hooks/useUseCases';
import { CancelAiModal } from '@pages/PageWizard/components/CancelAiModal';
import Toast from '@services/toast';
import { AiSideBarHeader } from '@pages/PageWizard/components/AiSidebarHeader';
import { AiSidebarFooter } from '@pages/PageWizard/components/AiSidebarFooter';
import { AiSidebarBody } from './components/AiSidebarBody';

type AiSidebarProps = HTMLAttributes<HTMLElement> & {
  isOpen: boolean;
  product: ProductProps;
  onClosed: () => void;
  onUseSuggestion: (suggestion: string) => void;
};

const AiSidebarComponent: React.FC<AiSidebarProps> = ({
  isOpen,
  product,
  onClosed,
  onUseSuggestion,
  ...attrs
}) => {
  const { generateDescriptionAiProperties } = useUseCases();
  const { t } = useTranslation();

  const [userTypedTexts, setUserTypedTexts] = useState(
    product.description || ''
  );
  const [aiLoading, setAiLoading] = useState<boolean>(false);
  const [aiDescriptionSuggestions, setAiDescriptionSuggestions] = useState<
    string[]
  >([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [source, setSource] = useState<CancelTokenSource | undefined>(
    undefined
  );

  useEffect(() => {
    if (!aiLoading && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [aiLoading, isModalOpen]);

  const clearStates = useCallback(
    (shouldClearTypedTexts: boolean = true) => {
      if (shouldClearTypedTexts) {
        setUserTypedTexts(product.description || '');
      }
      setSource(undefined);
      setAiDescriptionSuggestions([]);
      setSelectedSuggestion('');
    },
    [product.description]
  );

  const handleGenerate = useCallback(async () => {
    try {
      setAiLoading(true);
      const { source: cancelSource, promise } = generateDescriptionAiProperties(
        { productTitle: product.title, productDescription: userTypedTexts }
      );
      setSource(cancelSource);

      const descriptionSuggestion = await promise;

      clearStates(false);
      setAiDescriptionSuggestions(descriptionSuggestion.output.description);
    } catch (e) {
      const error = e as Error;
      if (error.message !== 'request_canceled_by_user') {
        Toast.error(t('toastMessages.general.error.generic'));
      }
    } finally {
      setAiLoading(false);
    }
  }, [
    clearStates,
    generateDescriptionAiProperties,
    product.title,
    t,
    userTypedTexts,
  ]);

  const handleRecreate = useCallback(() => {
    clearStates(false);
  }, [clearStates]);

  const handleUseSuggestion = useCallback(() => {
    onUseSuggestion(selectedSuggestion);
    clearStates();
    onClosed();
  }, [clearStates, onClosed, onUseSuggestion, selectedSuggestion]);

  const handleCancelProcess = useCallback(() => {
    source?.cancel('request_canceled_by_user');
    clearStates();
    onClosed();
  }, [clearStates, onClosed, source]);

  return (
    <div {...attrs}>
      <Sidebar
        isOpen={isOpen}
        header={<AiSideBarHeader />}
        onConfirmClose={() => {
          if (aiLoading) {
            setIsModalOpen(true);
            return false;
          }
          return true;
        }}
        footer={
          !aiLoading && (
            <AiSidebarFooter
              onRecreateButtonClicked={() => handleRecreate()}
              onUseButtonClicked={() => handleUseSuggestion()}
              disabledButtons={{
                recreate: !aiDescriptionSuggestions.length,
                use: !selectedSuggestion,
              }}
            />
          )
        }
        onClose={() => {
          onClosed();
          clearStates();
        }}
      >
        <AiSidebarBody
          isLoading={aiLoading}
          aiGeneratedDescriptionSuggestions={aiDescriptionSuggestions}
          onGenerateButtonClicked={handleGenerate}
          userTypedDescription={userTypedTexts}
          onUserTypedDescription={(value) => setUserTypedTexts(value)}
          onSelectedSuggestion={(suggestion) =>
            setSelectedSuggestion(suggestion)
          }
          selectedSuggestion={selectedSuggestion}
        />
      </Sidebar>
      <CancelAiModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancelButtonClicked={() => handleCancelProcess()}
      />
    </div>
  );
};

export const AiSidebar = memo(AiSidebarComponent);
