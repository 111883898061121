import '@hotmart-org-ca/cosmos/dist/header';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/tooltip';

import React, { HTMLAttributes, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next/';
import { useEngine } from '@hooks/useEngine';
import TagManager from 'react-gtm-module';
import { Container, Button } from './styles';

export const UndoRedo: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const { redo: stateRedo, undo: stateUndo, setUndo, setRedo } = useEngine();

  type undoRedoProps = 'undo' | 'redo';

  type nameEventByDeviceProps = {
    undo: string;
    redo: string;
  };

  const handleClick = useCallback(
    (iconAction: undoRedoProps) => {
      const nameEventByDevice: nameEventByDeviceProps = {
        undo: 'Undo',
        redo: 'Redo',
      };

      if (iconAction === 'undo') {
        setUndo();
      } else {
        setRedo();
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_navbar_editor',
          event_details: `${nameEventByDevice[iconAction]}`,
        },
      });
    },
    [setRedo, setUndo]
  );

  const icons: { name: 'undo' | 'redo'; icon: string; tooltip: string }[] =
    useMemo(
      () => [
        {
          name: 'undo',
          icon: 'far fa-undo',
          tooltip: t('header.undo'),
        },
        {
          name: 'redo',
          icon: 'far fa-redo',
          tooltip: t('header.redo'),
        },
      ],
      [t]
    );

  const isDisabled = useCallback(
    (iconName: 'undo' | 'redo') => {
      if (iconName === 'undo') {
        return !stateUndo.length;
      }
      return !stateRedo.length;
    },
    [stateUndo, stateRedo]
  );

  return (
    <Container {...attrs}>
      {icons.map((iconProps) => (
        <div key={iconProps.name}>
          <hot-tooltip position="bottom" content={iconProps.tooltip}>
            <Button
              onClick={() => handleClick(iconProps.name)}
              disabled={isDisabled(iconProps.name)}
              className={classNames('hot-button hot-button--tertiary', {
                'hot-button--disabled': isDisabled(iconProps.name),
              })}
            >
              <i className={`far fa-${iconProps.icon}`} />
            </Button>
          </hot-tooltip>
        </div>
      ))}
    </Container>
  );
};
