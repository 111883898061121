import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export type StyledMenuItemLabel = {
  hasIcon?: boolean;
  breakLine?: boolean;
};

export type StyledMenuItemWrapper = {
  hasIcon?: boolean;
};

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--structure-menu-minimized-width);
  padding: var(--spacer-2);
  height: 48px;
  bottom: 0;
  left: 0;
`;

export const Icon = styled(FontAwesomeIcon)`
  padding: 0;
  font-size: 24px;
  color: var(--gray-500);
`;

export const Options = styled.div`
  padding: 4px 0;
`;

export const Option = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  :hover {
    background-color: var(--gray-200);
  }
`;
