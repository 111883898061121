import '@hotmart-org-ca/cosmos/dist/header';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/popover';

import { HTMLAttributes, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useEngine } from '@hooks/index';
import { HoverPopover } from '@components/HoverPopover';
import TagManager from 'react-gtm-module';
import { ExternalLink } from '@components/ExternalLink';
import { Button, popoverStyle } from './styles';

export const Preview: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const { preview: statePreview, setPreview } = useEngine();

  type deviceProps = 'desktop' | 'mobile';

  type EventNameByDevice = {
    desktop: string;
    mobile: string;
  };

  const handleClick = useCallback(
    (device: deviceProps) => {
      const eventNameByDevice: EventNameByDevice = {
        desktop: 'Edit::Desktop',
        mobile: 'Edit::Mobile',
      };

      setPreview(device);
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_navbar_editor',
          event_details: `${eventNameByDevice[device]}`,
        },
      });
    },
    [setPreview]
  );

  const isActive = useCallback(
    (name: string) => statePreview === name,
    [statePreview]
  );

  // @todo remove when real link is available
  const moreAboutLink = false;

  return (
    <div {...attrs}>
      {['desktop', 'mobile'].map((device) => (
        <HoverPopover
          key={device}
          bodyContent={
            <>
              <style>{popoverStyle}</style>
              <div className="preview-popover__label">
                {t(`header.preview.${device}.title`)}
              </div>
              <div className="preview-popover__description">
                {t(`header.preview.${device}.description`)}
                <br />
                {moreAboutLink && (
                  <ExternalLink
                    // href="@todo add link here once is available"
                    href="www.test.com"
                    text={t('header.preview.moreAboutLink')}
                    openInNewTab
                  />
                )}
              </div>
            </>
          }
          buttonElement={
            <Button
              data-onboarding={`device-${device}`}
              slot="button"
              active={isActive(device)}
              onClick={() => handleClick(device as deviceProps)}
              className={`hot-button hot-button--tertiary ${
                isActive(device) ? 'hot-button--active' : ''
              }`}
            >
              <i className={`far fa-${device}`} />
            </Button>
          }
          position="bottom"
        />
      ))}
    </div>
  );
};
