import { useApplication } from '@hooks/useApplication';
import i18n from 'i18next';
import { HTMLAttributes, useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { Text } from './styles';

export const PreviewDraft: React.FC<HTMLAttributes<HTMLSpanElement>> = ({
  ...attrs
}) => {
  const { setIsPreviewMode } = useApplication();
  const handlePreviewDraft = useCallback(() => {
    setIsPreviewMode(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: 'click_navbar_editor',
        event_details: 'Preview',
      },
    });
  }, [setIsPreviewMode]);

  return (
    <Text onClick={handlePreviewDraft} {...attrs}>
      {i18n.t('header.previewDraft')}
    </Text>
  );
};
