import { v4 as uuid } from 'uuid';

export const ctaSections: PageFragmentListItem[] = [
  {
    id: 'cta-14',
    name: 'CTA',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'CTA',
        templateId: 'cta-3',
        templateName: 'CTA',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '64px 0px 0px 0px',
              },
              mobile: {
                padding: '40px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '48px 10px 48px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#615B9B',
                  'max-width': '80%',
                  margin: '48px 10px 48px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    margin: '24px 10px 48px 10px',
                    padding: '32px 10px 32px 10px',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="line-height: 2; text-align: center;"><s style="font-family: Montserrat, sans-serif; font-size: 32px; color: rgb(255, 255, 255);">DE R$XXX,XX</s></p><p style="line-height: 2; text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">em até 12x de</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="line-height: 2; text-align: center;"><s style="font-family: Montserrat, sans-serif; font-size: 24px; color: rgb(255, 255, 255);">DE R$XXX,XX</s></p><p style="line-height: 2; text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">em até 12x de</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);">R$ XXX,xx</strong></h1>',
                      'max-width': '100%',
                      padding: '0px 10px 24px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<h1 style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">R$ XXX,xx</strong></h1>',
                        padding: '0px 0px 24px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#615B9B',
                      background: '#FCDC4B',
                      'border-color': '#EF4E23',
                      'border-radius': '16px 16px 16px 16px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '24px',
                      'hover-background': '#FFE675',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'COMPRAR AGORA!',
                      margin: '0px 0px 20px 0px',
                      'max-width': '350px',
                      mobile: {
                        'max-width': '250px',
                        'font-size': '18px',
                        margin: '0px 0px 24px 0px',
                      },
                      padding: '16px 16px 16px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '48px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '32px 0px 80px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '16px 0px 48px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h3><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Garanta sua vaga e receba:</strong></h3>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<h3 style="text-align: center;"><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Garanta sua vaga e receba:</strong></h3>',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-size: 32px; font-family: Montserrat, sans-serif; color: rgb(229, 152, 186);">Garanta sua vaga e receba:</strong></p>',
                    'max-width': '80%',
                    padding: '10px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 2;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">● Benefício 1</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 2;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">● Benefício 2</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 2;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">● Benefício 3</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 2;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">● Benefício 4</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 2;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">● Benefício 5</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 2;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">● Benefício 6</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'cta-15',
    name: 'CTA',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '88px 0px 88px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '80px 0px 80px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h3 style="text-align: center;"><strong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Ainda com dúvidas?<span class="ql-cursor">﻿﻿﻿</span></strong></h3>',
                  'max-width': '100%',
                  padding: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-size: 32px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155);">Ainda com dúvidas?<span class="ql-cursor">﻿﻿﻿</span></strong></p>',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#615B9B',
                  background: '#00000000',
                  'border-color': '#D1BEDD',
                  'border-radius': '16px 16px 16px 16px',
                  'border-style': 'solid',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '20px',
                  'hover-background': '#D1BEDD',
                  'hover-color': '#615B9B',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': false,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'CONVERSE COM A MINHA EQUIPE',
                  margin: '40px 0px 0px 0px',
                  'max-width': '450px',
                  mobile: {
                    'font-size': '16px',
                    'max-width': '310px',
                    'border-radius': '16px 16px 16px 16px',
                    padding: '16px 0px 16px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      metadata: {
        label: 'CTA',
        templateId: 'cta-4',
        templateName: 'CTA',
      },
    },
  },
  {
    id: 'cta-16',
    name: 'CTA',
    template: {
      background: '#3B44B7',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 4',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '40px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 28px;">Explique aqui o que é seu produto</strong></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">[Por que ele é relevante, o que ele tem de diferente de todos os outros que são semelhantes ao seu. Reforce seu argumento principal de vendas.]</span></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 25px;">Explique aqui o que é seu produto</strong></p><p style="text-align: center;"><br></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">[Por que ele é relevante, o que ele tem de diferente de todos os outros que são semelhantes ao seu. Reforce seu argumento principal de vendas.]</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#141436',
                  background: '#6AFFB9',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#282A74',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'SIM! QUERO COMEÇAR AGORA!',
                  margin: '30px 0px 30px 0px',
                  'max-width': '450px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '330px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'cta-17',
    name: 'CTA',
    template: {
      background: '#141436',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 11',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 50px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 50px 0px',
              },
              mobile: {
                padding: '35px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;">Faça um resumo de todos os itens que o cliente irá receber, somando o valor de todos, e aplicando o desconto ao final.</strong></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;">Faça um resumo de todos os itens que o cliente irá receber, somando o valor de todos, e aplicando o desconto ao final.</strong></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">Faça um resumo de todos os itens que o cliente irá receber, somando o valor de todos, e aplicando o desconto ao final.</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '45px 10px 40px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '96%',
                  margin: '10px 20px 10px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '30px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 26px;">CURSO 1</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 22px;">CURSO 1</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 16px;">Breve descrição do curso.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 20px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Breve descrição do curso.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 40px;">R$ XXX,XX</strong></p>',
                      'max-width': '100%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 48px;">R$ XXX,XX</strong></p>',
                        'max-width': '100%',
                      },
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 35px;">R$ XXX,XX</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '45px 10px 40px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '96%',
                  margin: '10px 20px 10px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '30px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 26px;">CURSO 2</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 22px;">CURSO 2</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 16px;">Breve descrição do curso.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 20px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Breve descrição do curso.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 40px;">R$ XXX,XX</strong></p>',
                      'max-width': '100%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 48px;">R$ XXX,XX</strong></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 35px;">R$ XXX,XX</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 30px 0px',
              },
              mobile: {
                padding: '0px 0px 20px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '45px 10px 40px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '96%',
                  margin: '10px 20px 10px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '30px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 26px;">CURSO 3</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 22px;">CURSO 3</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 16px;">Breve descrição do curso.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 20px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Breve descrição do curso.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 40px;">R$ XXX,XX</strong></p>',
                      'max-width': '100%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 48px;">R$ XXX,XX</strong></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 35px;">R$ XXX,XX</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '45px 10px 40px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '96%',
                  margin: '10px 20px 10px 20px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '30px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 26px;">7 BÔNUS</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 22px;">7 BÔNUS</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 16px;">Breve descrição do curso.</span></p>',
                      'max-width': '100%',
                      padding: '0px 10px 20px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><span style="font-family: Montserrat, sans-serif; color: rgb(20, 20, 54); font-size: 14px;">Breve descrição do curso.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 40px;">R$ XXX,XX</strong></p>',
                      'max-width': '100%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 48px;">R$ XXX,XX</strong></p>',
                      },
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(186, 191, 255); font-size: 35px;">R$ XXX,XX</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '45px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '30px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;">VALOR TOTAL DO QUE VOCÊ IRÁ RECEBER =</span><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> </strong><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 40px;">R$ X.XXX,XX</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 26px;">VALOR TOTAL DO QUE VOCÊ IRÁ RECEBER =</span><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 30px;"> </strong><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 32px;">R$ X.XXX,XX</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">Mas você não terá que pagar por tudo isso.</strong></p><p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">Na oferta especial de HOJE, seu investimento será</span></p>',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Mas você não terá que pagar por tudo isso. </strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 18px;">Na oferta especial de HOJE, seu investimento será</span></p>',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">DE </span><s style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">R$ X.XXX,00</s></p>',
                  'max-width': '30%',
                  padding: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">DE </span><s style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">R$ X.XXX,00</s></p>',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;">DE </span><s style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;">R$ X.XXX,00</s></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center; line-height: 1;"><strong style="background-color: transparent; color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 60px;">R$ XXX,00</strong></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="text-align: center; line-height: 1;"><strong style="background-color: transparent; color: rgb(186, 191, 255); font-family: Montserrat, sans-serif; font-size: 56px;">R$ XXX,00</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(69, 81, 241);">________________________________________________________________________________________________________________________________</strong></p>',
                  'max-width': '100%',
                  padding: '50px 10px 72px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><strong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(69, 81, 241);">________________________________________________________________</strong></p>',
                    padding: '50px 10px 30px 10px',
                  },
                  mobile: {
                    padding: '30px 10px 10px 10px',
                    text: '<p style="text-align: center;"><strong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(69, 81, 241);">__________________________</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 37px;">E AINDA TEM MAIS!</strong></p>',
                  'max-width': '70%',
                  padding: '10px 10px 10px 30px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 37px;">E AINDA TEM MAIS!</strong></p>',
                    padding: '10px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 28px;">E AINDA TEM MAIS!</strong></p>',
                    padding: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;">Aproveite o valor promocional de pré-venda do treinamento e ganhe </span><strong style="background-color: rgb(106, 255, 185); color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 23px;"> 100 reais </strong></p><p><strong style="background-color: rgb(106, 255, 185); color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 23px;"> de desconto </strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;"> comprando </span><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;">AGORA</strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;">!</span></p>',
                  'max-width': '88%',
                  padding: '10px 10px 10px 30px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;">Aproveite o valor promocional de pré-venda do treinamento e ganhe </span><strong style="background-color: rgb(106, 255, 185); color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 23px;"> 100 reais de </strong></p><p style="text-align: center;"><strong style="background-color: rgb(106, 255, 185); color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 23px;"> desconto </strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;"> comprando </span><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;">AGORA</strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 23px;">!</span></p>',
                    'max-width': '70%',
                    padding: '10px 10px 10px 10px',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;">Aproveite o valor promocional de pré-venda do treinamento e ganhe </span><strong style="background-color: rgb(106, 255, 185); color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 19px;"> 100 reais de desconto </strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;"> comprando </span><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;">AGORA</strong><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 19px;">!</span></p>',
                    'max-width': '85%',
                    padding: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;">Esse valor é exclusivo para quem adquirir o treinamento agora.&nbsp; A qualquer momento podemos tirar essa promoção do ar, por isso, garanta sua vaga agora mesmo!</span></p>',
                  'max-width': '80%',
                  padding: '40px 10px 10px 30px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;">Esse valor é exclusivo para quem adquirir o treinamento agora.&nbsp; A qualquer momento podemos tirar essa promoção do ar, por isso, garanta sua vaga agora mesmo!</span></p>',
                    'max-width': '73%',
                    padding: '40px 10px 10px 10px',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Esse valor é exclusivo para quem adquirir o treinamento agora.&nbsp; A qualquer momento podemos tirar essa promoção do ar, por isso, garanta sua vaga agora mesmo!</span></p>',
                    'max-width': '90%',
                    padding: '20px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '40px 0px 30px 0px',
              },
              mobile: {
                padding: '10px 0px 10px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '30px 30px 30px 30px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#3B44B7',
                  'max-width': '85%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '80%',
                    padding: '30px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 24px;">VALOR DE PRÉ-VENDA</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 18px;">VALOR DE PRÉ-VENDA</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 20px;">de </span><s style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 20px;">R$ XXX,XX</s><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 20px;"> por apenas</span></p>',
                      'max-width': '100%',
                      padding: '30px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 15px;">de </span><s style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 15px;">R$ XXX,XX</s><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 15px;"> por apenas</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 70px; color: rgb(255, 255, 255);">R$ XXX,XX</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; font-size: 40px; color: rgb(255, 255, 255);">R$ XXX,XX</strong></p>',
                        padding: '10px 0px 10px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: right; line-height: 1;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 16px;">OU 12X DE R$ XX,XX</span></p>',
                      'max-width': '89%',
                      padding: '0px 0px 30px 10px',
                      desktop: {},
                      tablet: {
                        'max-width': '80%',
                      },
                      mobile: {
                        text: '<p style="text-align: right; line-height: 1;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 14px;">OU 12X DE R$ XX,XX</span></p>',
                        'max-width': '85%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#141436',
                      background: '#6AFFB9',
                      'border-color': '#EF4E23',
                      'border-radius': '15px 15px 15px 15px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '21px',
                      'hover-background': '#363889',
                      'hover-color': '#6AFFB9',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'QUERO ENTRAR AGORA!',
                      margin: '20px 0px 15px 0px',
                      'max-width': '350px',
                      mobile: {
                        'font-size': '16px',
                        'max-width': '250px',
                        margin: '20px 0px 10px 0px',
                        'hover-background': '#2B2D74',
                      },
                      padding: '16px 16px 16px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '40px',
                      href: '',
                      'max-width': '70%',
                      margin: '0px 0px 20px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 10px 0px',
                        'max-width': '80%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5321023/pagamento-vendas_assets_template-orange-chic.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5321023/thumb_pagamento-vendas_assets_template-orange-chic.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(69, 81, 241);">________________________________________________________________________________________________________________________________</strong></p>',
                  'max-width': '100%',
                  padding: '64px 10px 80px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><strong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(69, 81, 241);">________________________________________________________________</strong></p>',
                    padding: '1px 10px 80px 10px',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(69, 81, 241);">__________________________</strong></p>',
                    padding: '0px 10px 70px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'cta-18',
    name: 'CTA',
    template: {
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '50px',
                  href: '',
                  'max-width': '150px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '30px',
                    'max-width': '130px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5280678/seu-logo-aqui.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5280678/thumb_seu-logo-aqui.png',
                  metadata: {},
                },
              ],
              'align-items': 'start',
              'justify-content': 'center',
              background: '#FFFFFF',
              padding: '20px 0px 20px 0px',
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {},
              desktop: {},
              metadata: {},
            },
          ],
          tablet: {},
          mobile: {},
          desktop: {},
          metadata: {},
        },
      ],
      metadata: {
        label: 'Cabeçalho',
      },
      background: '#FFFFFF',
      tablet: {},
      mobile: {},
      desktop: {},
    },
  },
  {
    id: 'cta-19',
    name: 'CTA',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5365160/bg2-vendas_assets_template-erico-rocha.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Mídia x Oferta',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '100px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '80px 0px 40px 0px',
              },
              mobile: {
                padding: '60px 0px 40px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '315px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '560px',
                  src: 'https://www.youtube.com/embed/nY1LnAkc1mA',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '300px',
                    height: '169px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '30px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '10px 20px 40px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">Esta é a sua oportunidade de aprender o (nome do seu método)</strong></p>',
                  'max-width': '65%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 26px;">Esta é a sua oportunidade de aprender o (nome do seu método)</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '50%',
                  margin: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '100%',
                    margin: '15px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;">Por apenas 12x de</span></p>',
                      'max-width': '75%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        'max-width': '85%',
                      },
                      mobile: {
                        'max-width': '90%',
                        text: '<p><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Por apenas 12x de</span></p>',
                        padding: '0px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 100px;">R$XX,XX</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p style="text-align: center; line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 65px;">R$XX,XX</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: right;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 17px;">ou R$XXX,xx à vista</span></p>',
                      'max-width': '75%',
                      padding: '10px 0px 10px 0px',
                      desktop: {},
                      tablet: {
                        'max-width': '85%',
                      },
                      mobile: {
                        'max-width': '90%',
                        text: '<p style="text-align: right;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">ou R$XXX,xx à vista</span></p>',
                        padding: '0px 0px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="color: rgb(255, 95, 51); font-family: Montserrat, sans-serif; font-size: 18px; background-color: rgb(255, 255, 255);"> Pagamento </span><strong style="color: rgb(255, 95, 51); font-family: Montserrat, sans-serif; font-size: 18px; background-color: rgb(255, 255, 255);">100% seguro</strong><span style="color: rgb(255, 95, 51); font-family: Montserrat, sans-serif; font-size: 18px; background-color: rgb(255, 255, 255);"> com acesso imediato </span></p>',
                      'max-width': '100%',
                      padding: '45px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        padding: '40px 0px 0px 0px',
                        text: '<p style="text-align: center;"><span style="color: rgb(255, 95, 51); font-family: Montserrat, sans-serif; font-size: 13px; background-color: rgb(255, 255, 255);"> Pagamento </span><strong style="color: rgb(255, 95, 51); font-family: Montserrat, sans-serif; font-size: 13px; background-color: rgb(255, 255, 255);">100% seguro</strong><span style="color: rgb(255, 95, 51); font-family: Montserrat, sans-serif; font-size: 13px; background-color: rgb(255, 255, 255);"> com acesso imediato </span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      color: '#FFFFFF',
                      background: '#FF5F33',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'EU QUERO!',
                      margin: '15px 0px 20px 0px',
                      'max-width': '440px',
                      mobile: {
                        'font-size': '16px',
                        'max-width': '300px',
                        padding: '13px 16px 13px 16px',
                        margin: '20px 0px 20px 0px',
                      },
                      padding: '16px 16px 16px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                      metadata: {},
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '400px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '280px',
                        height: '40px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5321023/pagamento-vendas_assets_template-orange-chic.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5321023/thumb_pagamento-vendas_assets_template-orange-chic.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'cta-20',
    name: 'CTA',
    template: {
      background: '#FF3B71',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'oferta',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 88px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '24px 0px 48px 0px',
              },
              mobile: {
                padding: '8px 0px 24px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '450px',
                  href: '',
                  'max-width': '450px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '350px',
                    'max-width': '350px',
                  },
                  mobile: {
                    'max-width': '200px',
                    height: '200px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5486049/img2_vendas-assets_template-gi-isquierdo.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5486049/thumb_img2_vendas-assets_template-gi-isquierdo.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 100px 0px',
              'justify-content': 'end',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 72px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p><s style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;">de R$ X.XXX,XX</s><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;"> por</span></p>',
                  'max-width': '78%',
                  padding: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                  },
                  mobile: {
                    'max-width': '70%',
                    text: '<p style="text-align: center;"><s style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">de R$ X.XXX,XX</s><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;"> por</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center; line-height: 1;"><strong style="background-color: transparent; color: rgb(56, 54, 145); font-family: Oswald, sans-serif; font-size: 110px;">R$ XXX,XX</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    padding: '10px 0px 10px 0px',
                    text: '<p style="text-align: center; line-height: 1;"><strong style="background-color: transparent; color: rgb(56, 54, 145); font-family: Oswald, sans-serif; font-size: 72px;">R$ XXX,XX</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: right; line-height: 1;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;">ou 12x de R$ XX,XX</span></p>',
                  'max-width': '78%',
                  padding: '10px 10px 24px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '60%',
                    padding: '10px 0px 24px 10px',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1;"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 16px;">ou 12x de R$ XX,XX</span></p>',
                    'max-width': '70%',
                    padding: '10px 0px 16px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  padding: '0px 0px 0px 0px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 32px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '0px 10px 24px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '45px',
                      href: '',
                      'max-width': '100%',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {
                        'max-width': '70%',
                      },
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5321023/pagamento-vendas_assets_template-orange-chic.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5321023/thumb_pagamento-vendas_assets_template-orange-chic.png',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  color: '#14133A',
                  background: '#29FF89',
                  'border-color': '#1EE276',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '1px',
                  'border-right-width': '1px',
                  'border-bottom-width': '1px',
                  'border-left-width': '1px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  'hover-background': '#383691',
                  'hover-color': '#FFFFFF',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'COMPRAR COM DESCONTO',
                  margin: '0px 0px 0px 0px',
                  'max-width': '480px',
                  mobile: {
                    'font-size': '16px',
                    'max-width': '300px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {
                    'max-width': '350px',
                  },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'cta-1',
    name: 'CTA',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '48px 0px 48px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch2 style="text-align: center;"\u003e\u003cstrong style="font-size: 48px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eConte sobre como acessar o material gratuito.\u003c/strong\u003e\u003c/h2\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-size: 24px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eDiga o dia do evento ou para onde será enviado ao material disponibilizado.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#424242',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Nunito Sans, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Inscreva-se gratuitamente!',
                  margin: '24px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 3' },
    },
  },
  {
    id: 'cta-2',
    name: 'CTA',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 5' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 48px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003e7 dias de Garantia!\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '48px 10px 8px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 24px; color: rgb(28, 28, 28);"\u003eSeu dinheiro de volta sem perguntas nos primeiros 7 dias.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 72px; color: rgb(28, 28, 28);"\u003eR$ XX,xx\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#424242',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Nunito Sans, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Quero aproveitar!',
                  margin: '0px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'cta-3',
    name: 'CTA',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 9' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '48px 10px 48px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FFFFFF',
                  'max-width': '80%',
                  margin: '48px 10px 48px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="line-height: 2; text-align: center;"\u003e\u003cs style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 32px; color: rgb(28, 28, 28);"\u003eDE R$200,00\u003c/s\u003e\u003c/p\u003e\u003cp style="line-height: 2; text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eem até 12x de\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eR$ 140,00\u003c/strong\u003e\u003c/h1\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 24px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#424242',
                      'border-color': '#EF4E23',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Nunito Sans, sans-serif',
                      'font-size': '24px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': false,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'Comprar agora!',
                      margin: '0px 0px 0px 0px',
                      'max-width': '300px',
                      mobile: {},
                      padding: '8px 16px 8px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
            {
              padding: '48px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch3\u003e\u003cstrong style="font-size: 40px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eGaranta sua vaga e receba:\u003c/strong\u003e\u003c/h3\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003e● Benefício 1\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003e● Benefício 2\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003e● Benefício 3\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003e● Benefício 4\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003e● Benefício 5\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003e● Benefício 6\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'cta-4',
    name: 'CTA',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '48px 0px 48px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch3 style="text-align: center;"\u003e\u003cstrong style="font-size: 40px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eAinda com dúvidas?\u003cspan class="ql-cursor"\u003e﻿﻿\u003c/span\u003e\u003c/strong\u003e\u003c/h3\u003e',
                  'max-width': '100%',
                  padding: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#424242',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Nunito Sans, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Converse com minha equipe',
                  margin: '24px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {},
                  padding: '8px 16px 8px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 11' },
    },
  },
  {
    id: 'cta-5',
    name: 'CTA',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 90px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '90px 50px 90px 50px' },
              mobile: { padding: '90px 20px 90px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center;"\u003e\u003cstrong style="font-size: 48px; font-family: Raleway, sans-serif;"\u003eConte sobre como acessar o material gratuito.\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '2px 10px 2px 10px',
                  desktop: {},
                  tablet: {
                    text: '\u003ch1 style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 48px; font-family: Raleway, sans-serif;"\u003eConte sobre como acessar o material gratuito.\u003c/strong\u003e\u003c/h1\u003e',
                  },
                  mobile: {
                    text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-size: 38px; font-family: Raleway, sans-serif;"\u003eConte sobre como acessar o material gratuito.\u003c/strong\u003e\u003c/h1\u003e',
                    padding: '2px 10px 20px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-size: 18px; font-family: Raleway, sans-serif;"\u003eDiga o dia do evento ou para onde será enviado ao material disponibilizado.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '20px 10px 20px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#000000',
                  background: '#79E0BB',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Inscreva-se gratuitamente!',
                  margin: '30px 0px 0px 0px',
                  'max-width': '300px',
                  mobile: { margin: '20px 0px 20px 0px' },
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 3' },
    },
  },
  {
    id: 'cta-6',
    name: 'CTA',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 90px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 50px 0px' },
              mobile: { padding: '30px 20px 30px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 40px;"\u003e7 dias de Garantia!\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 20px;"\u003eSeu dinheiro de volta sem perguntas nos primeiros 7 dias.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '30%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 40px;"\u003eR$\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 60px;"\u003exx\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 54px;"\u003e,\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 40px;"\u003exx\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#000000',
                  background: '#79E0BB',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Quero aproveitar!',
                  margin: '30px 0px 0px 0px',
                  'max-width': '300px',
                  mobile: {},
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 5' },
    },
  },
  {
    id: 'cta-7',
    name: 'CTA',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 10px 0px' },
              mobile: { padding: '30px 0px 10px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '40px 10px 50px 10px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#7676B7',
                  'max-width': '65%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '80%',
                    padding: '30px 10px 30px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cs style="font-family: Raleway, sans-serif; font-size: 22px; color: rgb(255, 255, 255);"\u003eDe R$200,00\u003c/s\u003e\u003c/p\u003e\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Raleway, sans-serif; font-size: 30px; color: rgb(255, 255, 255);"\u003eem até 12x de\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 0px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 38px; color: rgb(255, 255, 255);"\u003eR$\u003c/strong\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 70px; color: rgb(255, 255, 255);"\u003e140\u003c/strong\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 42px; color: rgb(255, 255, 255);"\u003e,\u003c/strong\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 38px; color: rgb(255, 255, 255);"\u003e00\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 20px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#000000',
                      background: '#79E0BB',
                      'border-color': '#EF4E23',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Raleway, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'Comprar agora!',
                      margin: '0px 0px 0px 0px',
                      'max-width': '210px',
                      mobile: {},
                      padding: '12px 16px 12px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 70px 80px 70px' },
              mobile: { padding: '10px 30px 50px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 30px; font-family: Raleway, sans-serif;"\u003eGaranta sua vaga e receba:\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="line-height: 2;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003e• Benefício 1 lorem ipsum dollor sit amet consectetur \u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 2;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003e• Benefício 2 lorem ipsum dolor sit amet, consectetur adipiscing elit\u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 2;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003e• Benefício 3 lorem ipsum dolor sit amet, consectetur\u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 2;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003e• Benefício 4 lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do \u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 2;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003e• Benefício 5 lorem ipsum dollor sit amet\u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 2;"\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003e• Benefício 6 lorem ipsum dolor sit amet, consectetur\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 8' },
    },
  },
  {
    id: 'cta-8',
    name: 'CTA',
    template: {
      background: '#272AAD',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Oferta' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 60px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 40px 0px' },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '40px 20px 40px 20px',
                  'border-radius': '10px 10px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#00012C38',
                  'max-width': '500px',
                  margin: '20px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '80%',
                    padding: '20px 20px 20px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="line-height: 1;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 15px;"\u003ePOR APENAS 12x DE\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '350px',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 80px;"\u003eR$48\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '300px',
                      padding: '15px 0px 15px 0px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: right; line-height: 1;"\u003e\u003cspan style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;"\u003eOU R$ 497,00 À VISTA\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '350px',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(237, 88, 46); font-size: 12px;"\u003ePAGAMENTO 100% SEGURO COM ACESSO IMEDIATO\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '350px',
                      padding: '15px 10px 15px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#EF4E23',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 2px 10px 2px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '16px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'COMPRAR AGORA',
                      margin: '10px 0px 10px 0px',
                      'max-width': '300px',
                      mobile: {},
                      padding: '12px 16px 12px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'cta-9',
    name: 'CTA',
    template: {
      background: '#E5154A',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'transparent',
              desktop: {},
              tablet: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 30px;"\u003e\u003cu\u003e#TeEncontroLá\u003c/u\u003e\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '300px',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 30px;"\u003e\u003cu\u003e#TeEncontroLá\u003c/u\u003e\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eEvento Online / X de Mês / às XXh\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '400px',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eEvento Online / X de Mês / às XXh\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#000000',
                  'border-color': '#EF4E23',
                  'border-radius': '27px 27px 27px 27px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'FAZER INSCRIÇÃO GRATUITA',
                  margin: '0px 0px 0px 0px',
                  'max-width': '30%',
                  mobile: { 'font-size': '16px', 'max-width': '80%' },
                  padding: '15px 16px 15px 16px',
                  tablet: { 'max-width': '60%' },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 2' },
    },
  },
  {
    id: 'cta-10',
    name: 'CTA',
    template: {
      background: '#000000',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '30px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '30px 30px 30px 30px' },
              mobile: {
                'flex-direction': 'column',
                'align-items': 'center',
                'justify-content': 'center',
                padding: '30px 30px 30px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(255, 255, 255);"\u003eSeja parte do (Nome do produto) e aprenda como (promessa do produto)\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '700px',
                  padding: '10px 20px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '400px' },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 22px; color: rgb(255, 255, 255);"\u003eSeja parte do (Nome do produto) e aprenda como (promessa do produto)\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#E5154A',
                  'border-color': '#EF4E23',
                  'border-radius': '27px 27px 27px 27px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Comece por apenas 12x R$xx, xx',
                  margin: '10px 30px 10px 30px',
                  'max-width': '400px',
                  mobile: {},
                  padding: '12px 16px 12px 16px',
                  tablet: { 'max-width': '300px', 'font-size': '18px' },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 6' },
    },
  },
  {
    id: 'cta-11',
    name: 'CTA',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 80px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 50px 0px' },
              mobile: {
                background: '#FFFFFF',
                padding: '30px 10px 30px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '45px 20px 40px 20px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#E5154A',
                  'max-width': '480px',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '320px',
                    padding: '30px 20px 20px 20px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px;"\u003eTudo isso por\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; font-size: 32px;"\u003eR$XX,XX/Mês\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 20px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 1\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 2\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 3\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 4\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 6\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#000000',
                      'border-color': 'none',
                      'border-style': 'none',
                      'border-top-width': '0px',
                      'border-right-width': '0px',
                      'border-bottom-width': '0px',
                      'border-left-width': '0px',
                      'border-radius': '5px 5px 5px 5px',
                      desktop: {},
                      'end-date': '2022-09-28T12:58:43',
                      'font-family': 'Montserrat, sans-serif',
                      'number-size': '16px',
                      'label-size': '10px',
                      gap: '8px',
                      'is-bold': true,
                      'is-italic': false,
                      'items-height': '60px',
                      'items-width': '75px',
                      margin: '10px 0px 10px 0px',
                      'time-on-page':
                        '{"days":0,"hours":0,"minutes":5,"seconds":0}',
                      timezone: '-03:00_utcMinus0300MainCities',
                      type: 'timeOnPage',
                      padding: '12px 12px 12px 12px',
                      mobile: {
                        'number-size': '18px',
                        'label-size': '10px',
                        'items-height': '52px',
                        'items-width': '60px',
                        padding: '8px 8px 8px 8px',
                        gap: '5px',
                      },
                      tablet: { gap: '16px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-timer',
                      'has-action': false,
                      'items-visibility':
                        '{"days":true,"hours":true,"minutes":true,"seconds":true,"labels":true}',
                    },
                    {
                      text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 7\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 8\u003c/span\u003e\u003c/p\u003e\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif;"\u003eArgumento 9\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#000000',
                      'border-color': '#EF4E23',
                      'border-radius': '27px 27px 27px 27px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Montserrat, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'Assine agora',
                      margin: '30px 0px 25px 0px',
                      'max-width': '300px',
                      mobile: { 'max-width': '250px' },
                      padding: '12px 16px 12px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 7' },
    },
  },
  {
    id: 'cta-12',
    name: 'CTA',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Bônus' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '60px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '50px 0px 30px 0px' },
              mobile: { padding: '30px 0px 30px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 30px;"\u003eFazendo parte do [INSERIR NOME DO CURSO] você receberá \u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 30px; background-color: rgb(255, 124, 53);"\u003etotalmente grátis\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 30px;"\u003e o acesso para esses presentes exclusivos.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '90%' },
                  mobile: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 20px;"\u003eFazendo parte do [INSERIR NOME DO CURSO] você receberá \u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 20px; background-color: rgb(255, 124, 53);"\u003etotalmente grátis\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 20px;"\u003e o acesso para esses presentes exclusivos.\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 20px 00px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '0px 0px 0px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '130px',
                  href: '',
                  'max-width': '130px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5316251/numero-1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5316251/thumb_numero-1.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '60%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '70%' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(255, 124, 53); font-size: 19px; font-family: Raleway, sans-serif;"\u003eBônus 1\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 20px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '0px 0px 0px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '130px',
                  href: '',
                  'max-width': '130px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5316268/numero-2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5316268/thumb_numero-2.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '60%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '70%' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(255, 124, 53); font-size: 19px; font-family: Raleway, sans-serif;"\u003eBônus 2\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'row',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 50px 0px' },
              mobile: { padding: '0px 0px 20px 20px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '130px',
                  href: '',
                  'max-width': '130px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5316276/numero-3.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5316276/thumb_numero-3.png',
                },
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '60%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '70%' },
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp\u003e\u003cstrong style="color: rgb(255, 124, 53); font-size: 19px; font-family: Raleway, sans-serif;"\u003eBônus 3\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 18px;"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 75px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '0px 0px 70px 0px' },
              mobile: { padding: '0px 0px 60px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#FF7C35',
                  'max-width': '80%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '15px 10px 15px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 36px;"\u003eNão perca esta oportunidade!\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '40px 10px 30px 10px',
                      desktop: {},
                      tablet: {
                        padding: '40px 10px 40px 10px',
                        text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 36px;"\u003eNão perca esta oportunidade!\u003c/strong\u003e\u003c/p\u003e',
                      },
                      mobile: {
                        'max-width': '100%',
                        padding: '20px 10px 30px 10px',
                        text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 30px;"\u003eNão perca esta oportunidade!\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cs style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 22px;"\u003eDe R$ X.XXX,XX\u003c/s\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 22px;"\u003e por\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '36%',
                      padding: '10px 10px 15px 10px',
                      desktop: {},
                      tablet: { 'max-width': '50%' },
                      mobile: {
                        'max-width': '80%',
                        text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cs style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 16px;"\u003eDe R$ X.XXX,XX\u003c/s\u003e\u003cspan style="color: rgb(34, 34, 34); font-family: Montserrat, sans-serif; font-size: 16px;"\u003e por\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 65px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eR$ XXX,XX\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '0px 10px 0px 10px',
                      desktop: {},
                      tablet: { 'max-width': '70%' },
                      mobile: {
                        'max-width': '100%',
                        text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-size: 46px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eR$ XXX,XX\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#222222',
                      background: '#00FFFA',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 100px 100px 100px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Raleway, sans-serif',
                      'font-size': '21px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'COMPRAR COM DESCONTO',
                      margin: '45px 0px 15px 10px',
                      'max-width': '460px',
                      mobile: {
                        'max-width': '95%',
                        'font-size': '16px',
                        margin: '35px 0px 20px 10px',
                        padding: '15px 15px 15px 15px',
                      },
                      padding: '20px 15px 20px 15px',
                      tablet: { margin: '45px 0px 20px 10px' },
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '70px',
                      href: '',
                      'max-width': '400px',
                      margin: '0px 0px 50px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { margin: '0px 0px 40px 0px' },
                      mobile: {
                        'max-width': '90%',
                        height: '50px',
                        margin: '0px 0px 20px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5321023/pagamento-vendas_assets_template-orange-chic.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5321023/thumb_pagamento-vendas_assets_template-orange-chic.png',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'cta-13',
    name: 'CTA',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5334634/bg-vendas_assets_template-navy-blue.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Oferta' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 40px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '0px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '50px 0px 40px 0px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'linear-gradient(#283d77, #09204C)',
                  'max-width': '50%',
                  margin: '70px 10px 70px 10px',
                  desktop: {},
                  tablet: { 'max-width': '80%' },
                  mobile: { padding: '40px 0px 20px 0px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 40px;"\u003eNão perca esta oportunidade!\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '80%',
                      padding: '10px 10px 40px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(255, 255, 255); font-size: 30px;"\u003eNão perca esta oportunidade!\u003c/strong\u003e\u003c/p\u003e',
                        'max-width': '90%',
                        padding: '10px 10px 30px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(211, 219, 252); font-size: 20px;"\u003eDE \u003c/span\u003e\u003cs style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(211, 219, 252); font-size: 20px;"\u003eR$ X.XXX,XX\u003c/s\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(211, 219, 252); font-size: 20px;"\u003e POR\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '50%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '70%',
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(211, 219, 252); font-size: 15px;"\u003eDE \u003c/span\u003e\u003cs style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(211, 219, 252); font-size: 15px;"\u003eR$ X.XXX,XX\u003c/s\u003e\u003cspan style="font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; color: rgb(211, 219, 252); font-size: 15px;"\u003e POR\u003c/span\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 70px;"\u003eR$ XXX,XX\u003c/strong\u003e\u003c/p\u003e',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '100%',
                        text: '\u003cp style="text-align: center; line-height: 1;"\u003e\u003cstrong style="color: rgb(106, 129, 229); font-family: \u0026quot;Open Sans\u0026quot;, sans-serif; font-size: 50px;"\u003eR$ XXX,XX\u003c/strong\u003e\u003c/p\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      color: '#FFFFFF',
                      background: '#FF5959',
                      'border-color': '#EF4E23',
                      'border-radius': '10px 10px 10px 10px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      'button-class': '',
                      desktop: {},
                      'font-family': 'Open Sans, sans-serif',
                      'font-size': '18px',
                      'hover-background': 'none',
                      'hover-color': 'none',
                      'has-icon': false,
                      href: '',
                      'icon-position': 'right',
                      'icon-svg':
                        '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                      'is-bold': true,
                      'is-italic': false,
                      'is-underline': false,
                      'is-strike': false,
                      label: 'COMPRAR COM DESCONTO',
                      margin: '40px 0px 10px 0px',
                      'max-width': '60%',
                      mobile: {
                        'font-size': '15px',
                        padding: '15px 16px 15px 16px',
                        'max-width': '80%',
                      },
                      padding: '18px 16px 18px 16px',
                      tablet: {},
                      target: '_blank',
                      uid: `ls-${uuid()}`,
                      component: 'ls-button',
                    },
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '50px',
                      href: '',
                      'max-width': '300px',
                      margin: '0px 0px 30px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: { margin: '0px 0px 25px 0px' },
                      mobile: { 'max-width': '230px' },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5321023/pagamento-vendas_assets_template-orange-chic.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5321023/thumb_pagamento-vendas_assets_template-orange-chic.png',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
