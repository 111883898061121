import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-checkbox.css';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/secondary.css';
import '@hotmart-org-ca/cosmos/dist/tag';
import '@hotmart-org-ca/cosmos/dist/tag/variations/small.css';

import React, { HTMLAttributes, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import {
  ActionContent,
  ActionDescription,
  Actions,
  CheckboxLabel,
  CloseIcon,
  Container,
  ImageContent,
  StartButton,
  Title,
} from './styles';

export type OnboardingModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose?: (finished: boolean) => void;
  onRemainderClick?: (when: string) => void;
  onStart?: (finished: boolean) => void;
};

const OnboardingModalComponent: React.FC<OnboardingModalProps> = ({
  isOpen,
  onClose,
  onRemainderClick,
  onStart,
  ...attrs
}) => {
  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);

  const handleClose = useCallback(() => {
    onClose?.(finished);
  }, [finished, onClose]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFinished(event.target.checked);
    },
    []
  );

  const handleRemainder = useCallback(() => {
    const remainderDate = new Date();
    remainderDate.setDate(remainderDate.getDate() + 7);
    onRemainderClick?.(remainderDate.toISOString());
  }, [onRemainderClick]);

  const handleStart = useCallback(() => {
    onStart?.(finished);
  }, [finished, onStart]);

  return (
    <BaseModal isOpen={isOpen} fixedSize="670px" {...attrs}>
      <Container>
        <CloseIcon onClick={handleClose}>
          <i className="far fa-times" />
        </CloseIcon>

        <ImageContent>
          <img
            src="/images/onboarding.svg"
            width={241}
            height={206}
            alt="Onboarding"
          />

          <div className="hot-form--custom hot-form--checkbox">
            <input
              id="show-onboarding-again"
              type="checkbox"
              className="hot-form__input"
              checked={finished}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel
              className="hot-form__label"
              htmlFor="show-onboarding-again"
            >
              {t('onboarding.modals.welcome.hide')}
            </CheckboxLabel>
          </div>
        </ImageContent>

        <ActionContent>
          <Title>{t('onboarding.modals.title')}</Title>

          <ActionDescription>
            {t('onboarding.modals.welcome.description')}
          </ActionDescription>

          <Actions>
            <button
              className="hot-button hot-button--secondary"
              onClick={handleRemainder}
            >
              {t('onboarding.modals.welcome.later')}
            </button>

            <StartButton
              className="hot-button hot-button--primary"
              onClick={handleStart}
            >
              <span>{t('onboarding.modals.welcome.start')}</span>
              <i className="far fa-arrow-right" />
            </StartButton>
          </Actions>
        </ActionContent>
      </Container>
    </BaseModal>
  );
};

export const OnboardingModal = memo(OnboardingModalComponent);
