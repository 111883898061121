export const steps: OnboardingStep[] = [
  {
    description: 'onboarding.steps.preview',
    elementSelector: '*[data-onboarding="device-desktop"]',
    actions: [
      {
        type: 'DEVICE',
        device: 'desktop',
      },
    ],
  },
  {
    description: 'onboarding.steps.sectionModels',
    elementSelector: '*[data-onboarding="submenu-title"]',
    position: 'right',
    actions: [
      {
        type: 'OPEN_MENU',
        menu: 'sections-template',
      },
    ],
    distanceFromElement: 40,
    arrowStyle: 'top: 40px',
    popoverStyle: 'top: 68px',
  },
  {
    description: 'onboarding.steps.sections',
    elementSelector: '*[data-onboarding="add-section-menu-button"]',
    position: 'right',
    actions: [
      {
        type: 'OPEN_MENU',
        menu: 'page-structure',
      },
    ],
    distanceFromElement: 100,
    arrowStyle: 'top: 40px',
    popoverStyle: 'top: 116px',
  },
  {
    description: 'onboarding.steps.addableStructures',
    elementSelector: '*[data-onboarding="addable-structures"]',
    position: 'right',
    highlightHover: [
      '*[data-onboarding="addable-row"]',
      '*[data-onboarding="addable-column"]',
    ],
    actions: [
      {
        type: 'OPEN_MENU',
        menu: 'add',
      },
    ],
    distanceFromElement: 4,
    arrowStyle: 'top: 40px',
    popoverStyle: 'top: 228px',
  },
  {
    description: 'onboarding.steps.addableElements',
    elementSelector: '*[data-onboarding="addable-elements"]',
    position: 'right',
    highlightHover: [
      '*[data-onboarding="addable-title"]',
      '*[data-onboarding="addable-paragraph"]',
      '*[data-onboarding="addable-button"]',
      '*[data-onboarding="addable-form"]',
      '*[data-onboarding="addable-image"]',
      '*[data-onboarding="addable-video"]',
      '*[data-onboarding="addable-html"]',
      '*[data-onboarding="addable-icon"]',
      '*[data-onboarding="addable-timer"]',
    ],
    actions: [
      {
        type: 'OPEN_MENU',
        menu: 'add',
      },
    ],
    distanceFromElement: 4,
    popoverStyle: 'top: 104px',
  },
  {
    description: 'onboarding.steps.mediaCenter',
    elementSelector: '*[data-onboarding="upload-media-button"]',
    position: 'right',
    actions: [
      {
        type: 'OPEN_MENU',
        menu: 'media-center',
      },
    ],
    distanceFromElement: 28,
    arrowStyle: 'top: 40px',
    popoverStyle: 'top: 112px',
  },
  {
    description: 'onboarding.steps.settings',
    elementSelector: '*[data-onboarding="setting-url"]',
    position: 'right',
    actions: [
      {
        type: 'OPEN_MENU',
        menu: 'settings',
      },
    ],
    distanceFromElement: 12,
    arrowStyle: 'top: 40px',
    popoverStyle: 'top: 120px',
  },
  {
    description: 'onboarding.steps.publish',
    elementSelector: '*[data-onboarding="publish-button"]',
    position: 'bottom',
    distanceFromElement: 4,
    arrowStyle: 'left: 240px',
    popoverStyle: 'left: -48px',
  },
];
