import { HTMLAttributes, memo } from 'react';
import { Container, LeftSlot, RightSlot, CenterSlot, Content } from './styles';

export type FooterProps = HTMLAttributes<HTMLDivElement> & {
  leftSlot?: React.ReactNode;
  centerSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  centerSlotMaxWidth?: string;
  fixed?: boolean;
  padding?: string;
};

const FooterComponent: React.FC<FooterProps> = ({
  leftSlot,
  centerSlot,
  rightSlot,
  centerSlotMaxWidth = 'unset',
  fixed,
  padding,
  ...attrs
}) => (
  <Container isFixed={fixed} padding={padding} {...attrs}>
    <Content>
      <LeftSlot>{leftSlot}</LeftSlot>
      {centerSlot && (
        <CenterSlot maxWidth={centerSlotMaxWidth}>{centerSlot}</CenterSlot>
      )}
      <RightSlot>{rightSlot}</RightSlot>
    </Content>
  </Container>
);

export const Footer = memo(FooterComponent);
