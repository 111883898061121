import { v4 as uuid } from 'uuid';
import i18next from 'i18next';

export const addablePopups = (): PageFragmentListItem[] => [
  {
    id: 'popup-1',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '40px 20px 40px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 16px 0px 16px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': false,
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 10px 0px 10px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 32px; background-color: rgba(0, 0, 0, 0);">${i18next.t(
                    'fragments.popup.defaultPopups.title'
                  )}</strong></p>`,
                  'max-width': '560px',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 17px; background-color: rgba(0, 0, 0, 0);">${i18next.t(
                      'fragments.popup.defaultPopups.title'
                    )}</strong></p>`,
                    'max-width': '265.5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: `<p style="text-align:center;line-height:1.15"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 16px;">${i18next.t(
                    'fragments.popup.defaultPopups.description'
                  )}</span></p>`,
                  'max-width': '512px',
                  padding: '16px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center;line-height:1.15"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 14px;">${i18next.t(
                      'fragments.popup.defaultPopups.description'
                    )}</span></p>`,
                    padding: '12px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  action: 'link',
                  color: '#FFFFFF',
                  background: '#7F8891',
                  'border-color': '#EF4E23',
                  'border-radius': '5px 5px 5px 5px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2024-05-03T16:37:13',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: `${i18next.t(
                    'fragments.popup.defaultPopups.subscription'
                  )}`,
                  margin: '32px 0px 0px 0px',
                  'max-width': '276px',
                  mobile: {
                    margin: '20px 0px 0px 0px',
                    background: '#7F8891',
                    'font-size': '14px',
                    padding: '12px 32px 12px 32px',
                  },
                  padding: '16px 32px 16px 32px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
                {
                  text: `<p style="text-align:center;line-height:1.15"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 14px;">${i18next.t(
                    'fragments.popup.defaultPopups.spam'
                  )}</span></p>`,
                  'max-width': '512px',
                  padding: '16px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center;line-height:1.15"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 12px;">${i18next.t(
                      'fragments.popup.defaultPopups.spam'
                    )}</span></p>`,
                    padding: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      mobile: {
        padding: '24px 20px 32px 20px',
      },
      tablet: {},
      desktop: {},
      'max-width': '635px',
    },
  },
  {
    id: 'popup-2',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '32px 20px 32px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': false,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '561px',
                  href: '',
                  'is-webp': true,
                  'max-width': '258px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '172px',
                    'max-width': '111px',
                    'object-fit': 'contain',
                    src: '//static-public.pages.hotmart.com/uploads/media/file/7799403/img_placeholder-2.png',
                    'thumb-src':
                      '//static-public.pages.hotmart.com/uploads/media/file/7799403/thumb_img_placeholder-2.png',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.pages.hotmart.com/uploads/media/file/7804465/img_placeholder-2.png',
                  'thumb-src':
                    '//static-public.pages.hotmart.com/uploads/media/file/7804465/thumb_img_placeholder-2.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 10px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 20px;">${i18next.t(
                    'fragments.popup.defaultPopups.title'
                  )}</strong></p>`,
                  'max-width': '245px',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 16px;">${i18next.t(
                      'fragments.popup.defaultPopups.title'
                    )}</strong></p>`,
                    'max-width': '297.5px',
                    padding: '16px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: `<p style="line-height: 1.15;"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 14px;">${i18next.t(
                    'fragments.popup.defaultPopups.description'
                  )}</span></p>`,
                  'max-width': '245px',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height: 1.15; text-align: center;"><span style="color:rgb(0, 0, 0);font-family:Montserrat, sans-serif;font-size:14px">${i18next.t(
                      'fragments.popup.defaultPopups.description'
                    )}</span></p>`,
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  uid: `ls-${uuid()}`,
                  component: 'ls-empty-form',
                  'max-width': '100%',
                  metadata: {},
                  tablet: {},
                  mobile: {},
                  desktop: {},
                },
                {
                  text: `<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 12px;">${i18next.t(
                    'fragments.popup.defaultPopups.spam'
                  )}</span></p>`,
                  'max-width': '264px',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height:1.15;text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 11px;">${i18next.t(
                      'fragments.popup.defaultPopups.spam'
                    )}</span></p>`,
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      'max-width': '784px',
      mobile: {
        'max-width': '331px',
        padding: '32px 20px 24px 20px',
      },
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-3',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '32px 20px 32px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '250px',
                  href: '',
                  'is-webp': true,
                  'max-width': '560px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '135px',
                    'max-width': '297.5px',
                    'object-fit': 'contain',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.pages.hotmart.com/uploads/media/file/7799897/img_placeholder-3.png',
                  'thumb-src':
                    '//static-public.pages.hotmart.com/uploads/media/file/7799897/thumb_img_placeholder-3.png',
                  metadata: {},
                },
                {
                  uid: `ls-${uuid()}`,
                  component: 'ls-empty-form',
                  'max-width': '100%',
                  metadata: {},
                  tablet: {},
                  mobile: {},
                  desktop: {},
                },
                {
                  text: `<p style="line-height: 1; text-align: center;"><span style="color:rgb(0, 0, 0);font-family:Montserrat, sans-serif;font-size:12px">${i18next.t(
                    'fragments.popup.defaultPopups.spam'
                  )}</span></p>`,
                  'max-width': '264px',
                  padding: '5px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height:1.15;text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 11px;">${i18next.t(
                      'fragments.popup.defaultPopups.spam'
                    )}</span></p>`,
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      'max-width': '784px',
      mobile: {
        'max-width': '331px',
        padding: '24px 20px 16px 20px',
      },
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-4',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '32px 20px 32px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '250px',
                  href: '',
                  'is-webp': true,
                  'max-width': '560px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '135px',
                    'max-width': '297.5px',
                    'object-fit': 'contain',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.pages.hotmart.com/uploads/media/file/7799897/img_placeholder-3.png',
                  'thumb-src':
                    '//static-public.pages.hotmart.com/uploads/media/file/7799897/thumb_img_placeholder-3.png',
                  metadata: {},
                },
                {
                  text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 28px;">${i18next.t(
                    'fragments.popup.defaultPopups.title'
                  )}</strong></p>`,
                  'max-width': '508px',
                  padding: '32px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 17px;">${i18next.t(
                      'fragments.popup.defaultPopups.title'
                    )}</strong></p>`,
                    padding: '24px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: `<p style="text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 16px;">${i18next.t(
                    'fragments.popup.defaultPopups.description'
                  )}</span></p>`,
                  'max-width': '508px',
                  padding: '16px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 13px;">${i18next.t(
                      'fragments.popup.defaultPopups.description'
                    )}</span></p>`,
                    'max-width': '227px',
                    padding: '0px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  action: 'link',
                  color: '#FFFFFF',
                  background: '#7F8891',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2024-05-03T20:16:34',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: `${i18next.t(
                    'fragments.popup.defaultPopups.subscription'
                  )}`,
                  margin: '24px 0px 0px 0px',
                  'max-width': '469px',
                  mobile: {
                    margin: '13px 0px 0px 0px',
                    padding: '12px 32px 12px 32px',
                    'font-size': '14px',
                  },
                  padding: '16px 32px 16px 32px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
                {
                  text: `<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 12px;">${i18next.t(
                    'fragments.popup.defaultPopups.spam'
                  )}</span></p>`,
                  'max-width': '264px',
                  padding: '12px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height:1.15;text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 11px;">${i18next.t(
                      'fragments.popup.defaultPopups.spam'
                    )}</span></p>`,
                    padding: '10px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      'max-width': '784px',
      mobile: {
        'max-width': '331px',
        padding: '24px 20px 16px 20px',
      },
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-5',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '24px 20px 44px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': true,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 24px;">${i18next.t(
                    'fragments.popup.defaultPopups.title'
                  )}</strong></p>`,
                  'max-width': '481px',
                  padding: '10px 10px 32px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 18px;">${i18next.t(
                      'fragments.popup.defaultPopups.title'
                    )}</strong></p>`,
                    'max-width': '297.5px',
                    padding: '10px 10px 24px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': false,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '157px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '280px',
                  src: 'https://www.youtube.com/embed/I8mDcl0Tapg',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '167px',
                    'max-width': '297.5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 16px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 14px;">${i18next.t(
                    'fragments.popup.defaultPopups.description'
                  )}</span></p>`,
                  'max-width': '244px',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height: 1.15; text-align: center;"><span style="color:rgb(0, 0, 0);font-family:Montserrat, sans-serif;font-size:14px">${i18next.t(
                      'fragments.popup.defaultPopups.description'
                    )}</span></p>`,
                    padding: '24px 10px 8px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  action: 'link',
                  color: '#FFFFFF',
                  background: '#7F8891',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2024-05-03T20:16:34',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: `${i18next.t(
                    'fragments.popup.defaultPopups.subscription'
                  )}`,
                  margin: '16px 0px 5px 0px',
                  'max-width': '244px',
                  mobile: {
                    'max-width': '297.5px',
                    margin: '16px 0px 5px 0px',
                    'font-size': '14px',
                    padding: '12px 32px 12px 32px',
                  },
                  padding: '16px 32px 16px 32px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
                {
                  text: `<p style="line-height: 1.15; text-align: center;"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 12px;">${i18next.t(
                    'fragments.popup.defaultPopups.spam'
                  )}</span></p>`,
                  'max-width': '244px',
                  padding: '5px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height:1.15;text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 11px;">${i18next.t(
                      'fragments.popup.defaultPopups.spam'
                    )}</span></p>`,
                    padding: '6px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      'max-width': '784px',
      mobile: {
        'max-width': '331px',
        padding: '16px 20px 24px 20px',
      },
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-6',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '24px 20px 16px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '0px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '253px',
                  margin: '0px 0px 0px 0px',
                  'max-width': '436px',
                  src: 'https://www.youtube.com/embed/I8mDcl0Tapg',
                  'thumb-url': '',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    height: '148px',
                    'max-width': '272px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-video',
                  metadata: {},
                },
                {
                  text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 20px;">${i18next.t(
                    'fragments.popup.defaultPopups.title'
                  )}</strong></p>`,
                  'max-width': '365px',
                  padding: '24px 10px 5px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align:center"><strong style="color: rgb(127, 136, 145); font-family: Montserrat, sans-serif; font-size: 16px;">${i18next.t(
                      'fragments.popup.defaultPopups.title'
                    )}</strong></p>`,
                    padding: '24px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: `<p style="text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 14px;">${i18next.t(
                    'fragments.popup.defaultPopups.description'
                  )}</span></p>`,
                  'max-width': '553px',
                  padding: '0px 10px 5px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 13px;">${i18next.t(
                      'fragments.popup.defaultPopups.description'
                    )}</span></p>`,
                    'max-width': '221px',
                    padding: '0px 10px 0px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  uid: `ls-${uuid()}`,
                  component: 'ls-empty-form',
                  'max-width': '100%',
                  metadata: {},
                  tablet: {},
                  mobile: {},
                  desktop: {},
                },
                {
                  text: `<p style="line-height: 1; text-align: center;"><span style="color:rgb(0, 0, 0);font-family:Montserrat, sans-serif;font-size:12px">${i18next.t(
                    'fragments.popup.defaultPopups.spam'
                  )}</span></p>`,
                  'max-width': '264px',
                  padding: '5px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<p style="line-height:1.15;text-align:center"><span style="color: rgb(0, 0, 0); font-family: Montserrat, sans-serif; font-size: 11px;">${i18next.t(
                      'fragments.popup.defaultPopups.spam'
                    )}</span></p>`,
                    padding: '8px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      'max-width': '784px',
      mobile: {
        'max-width': '331px',
        padding: '24px 20px 16px 20px',
      },
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-7',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '20px 20px 30px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': true,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {},
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: '#2F6E32',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<h1 style="text-align:center"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">${i18next.t(
                    'fragments.popup.popup7.title'
                  )}</span></h1>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<h1 style="text-align:center"><span style="color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;">${i18next.t(
                      'fragments.popup.popup7.title'
                    )}</span></h1>`,
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {},
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p style="text-align:center"><span style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31); font-size: 20px;">${i18next.t(
                    'fragments.popup.popup7.description'
                  )}</span></p>`,
                  'max-width': '70%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '276px',
                    text: `<p style="text-align:center"><span style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31); font-size: 18px;">${i18next.t(
                      'fragments.popup.popup7.description'
                    )}</span></p>`,
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  action: 'link',
                  color: '#FFFFFF',
                  background: '#4CAF50',
                  'border-color': '#EF4E23',
                  'border-radius': '50px 50px 50px 50px',
                  'border-style': 'none',
                  'border-top-width': '10px',
                  'border-right-width': '10px',
                  'border-bottom-width': '10px',
                  'border-left-width': '10px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2024-05-25T16:25:03',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': '#2F6E32',
                  'hover-color': 'none',
                  'has-icon': true,
                  'has-text': true,
                  href: '',
                  'icon-position': 'left',
                  icon: 'fab fa-whatsapp',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: `${i18next.t('fragments.popup.popup7.button')}`,
                  margin: '0px 0px 0px 0px',
                  'max-width': '339px',
                  mobile: {
                    padding: '10px 15px 10px 15px',
                  },
                  padding: '10px 32px 10px 32px',
                  'popup-id': '',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      mobile: {},
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-8',
    name: 'Popup',
    template: {
      background: '#FFFFFF',
      padding: '20px 20px 20px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {},
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: '#E4E4E4',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<h1 style="text-align:center"><strong style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31); font-size: 22px;">${i18next.t(
                    'fragments.popup.popup8.imageText'
                  )}</strong></h1>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '235px',
                  href: '',
                  'is-webp': true,
                  'max-width': '280px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.pages.hotmart.com/uploads/media/file/7902143/homem-apontando.jpg',
                  'thumb-src':
                    '//static-public.pages.hotmart.com/uploads/media/file/7902143/thumb_homem-apontando.jpg',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': false,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31); font-size: 19px;">${i18next.t(
                    'fragments.popup.popup8.title'
                  )}</strong></p>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: `<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31);">${i18next.t(
                    'fragments.popup.popup8.description'
                  )}</span></p>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  action: 'link',
                  color: '#FFFFFF',
                  background: '#191C1F',
                  'border-color': '#000000',
                  'border-radius': '24px 24px 24px 24px',
                  'border-style': 'solid',
                  'border-top-width': '1px',
                  'border-right-width': '1px',
                  'border-bottom-width': '1px',
                  'border-left-width': '1px',
                  'button-class': '',
                  desktop: {},
                  'end-date': '2024-05-25T17:16:16',
                  timezone: '-03:00_utcMinus0300MainCities',
                  'time-on-page': '5',
                  'time-on-page-unit': 'seconds',
                  'delay-type': 'disabled',
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': '#FFFFFF',
                  'hover-color': '#000000',
                  'has-icon': false,
                  'has-text': true,
                  href: '',
                  'icon-position': 'right',
                  icon: 'fas fa-arrow-right',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: `${i18next.t('fragments.popup.popup8.button')}`,
                  margin: '20px 0px 0px 0px',
                  'max-width': '235px',
                  mobile: {},
                  padding: '10px 32px 10px 32px',
                  'popup-id': '',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      tablet: {},
      mobile: {},
      desktop: {},
    },
  },
  {
    id: 'popup-9',
    name: 'Popup',
    template: {
      background: 'linear-gradient(#004090, #002b61)',
      padding: '20px 20px 20px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<h1 style="line-height: 1.15; text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 45px;">${i18next.t(
                    'fragments.popup.popup9.title'
                  )}</span></span></h1>`,
                  'max-width': '100%',
                  padding: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<h1 style="line-height: 1.15; text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 35px;">${i18next.t(
                      'fragments.popup.popup9.title'
                    )}</span></h1>`,
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          'background-is-webp': true,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 20px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: '#FFFFFF',
              'background-is-webp': true,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31); font-size: 24px;">${i18next.t(
                    'fragments.popup.popup9.description'
                  )}</strong></p>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#FFFFFF',
                  background: '#B90000',
                  'border-color': 'unset',
                  'border-style': 'none',
                  'border-top-width': '0px',
                  'border-right-width': '0px',
                  'border-bottom-width': '0px',
                  'border-left-width': '0px',
                  'border-radius': '5px 5px 5px 5px',
                  desktop: {},
                  'end-date': '2024-05-25T18:38:13',
                  'font-family': 'Montserrat, sans-serif',
                  'items-visibility':
                    '{"days":true,"hours":true,"minutes":true,"seconds":true,"labels":true}',
                  'number-size': '25px',
                  'label-size': '15px',
                  gap: '5px',
                  'is-bold': false,
                  'is-italic': false,
                  'items-height': '50px',
                  'items-width': '100px',
                  margin: '0px 0px 0px 0px',
                  'time-on-page':
                    '{"days":0,"hours":0,"minutes":5,"seconds":0}',
                  timezone: '-03:00_utcMinus0300MainCities',
                  type: 'timeOnPage',
                  padding: '12px 12px 12px 12px',
                  mobile: {
                    padding: '8px 8px 8px 8px',
                    'max-width': '297.5px',
                    'items-width': '0px',
                    'items-height': '0px',
                    'number-size': '20px',
                    'label-size': '12px',
                  },
                  tablet: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-timer',
                  'has-action': false,
                  metadata: {},
                },
                {
                  text: `<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(25, 28, 31); font-size: 20px;">${i18next.t(
                    'fragments.popup.popup9.footer'
                  )}</span></p>`,
                  'max-width': '76%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '297.5px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      mobile: {},
      tablet: {},
      desktop: {},
    },
  },
  {
    id: 'popup-10',
    name: 'Popup',
    template: {
      background: 'linear-gradient(#004090, #011f45)',
      padding: '20px 20px 20px 20px',
      uid: `ls-${uuid()}`,
      component: 'ls-popup',
      metadata: {
        label: 'Pop-up',
      },
      children: [
        {
          background: 'none',
          'background-is-webp': false,
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {},
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              'background-is-webp': false,
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: `<h1 style="text-align: center; line-height: 1;"><span style="color: rgb(255, 193, 7); font-family: Montserrat, sans-serif; font-size: 27px;">${i18next.t(
                    'fragments.popup.popup10.title'
                  )}</span></h1>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: `<h1 style="text-align: center; line-height: 1;"><span style="color: rgb(255, 193, 7); font-family: Montserrat, sans-serif; font-size: 20px;">${i18next.t(
                      'fragments.popup.popup10.title'
                    )}</span></h1>`,
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: `<p style="text-align: center;"><span style="font-family: Raleway, sans-serif; color: rgb(255, 255, 255);">${i18next.t(
                    'fragments.popup.popup10.description'
                  )}</span></p>`,
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  uid: `ls-${uuid()}`,
                  component: 'ls-empty-form',
                  'max-width': '270px',
                  metadata: {},
                  tablet: {},
                  mobile: {},
                  desktop: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
      mobile: {},
      tablet: {},
      desktop: {},
    },
  },
];
