import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { saveScript, saveScriptBuilder } from './saveScript';
import { deleteScript, deleteScriptBuilder } from './deleteScript';
import {
  saveSupportChatScript,
  saveSupportChatScriptBuilder,
} from './saveSupportChatScript';

export const pageScriptsThunks = {
  saveScript,
  deleteScript,
  saveSupportChatScript,
};

export const pageScriptsExtraReducers = (
  builder: ActionReducerMapBuilder<PageScriptsState>
) => {
  saveScriptBuilder(builder);
  deleteScriptBuilder(builder);
  saveSupportChatScriptBuilder(builder);
};
