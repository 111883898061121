import styled from 'styled-components';

export const Container = styled.div`
  left: 70px;
  width: 299px;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white);
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0 16px 16px;
`;

export const Divider = styled.hr`
  margin: 0 0 16px;
  display: block;
  width: 100%;
`;
