import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { AppError } from '@common/app-error';
import Pages from '@services/pages';
import { extractHtmlValues, hasRedirectScriptInPage } from '@common/utils';
import {
  EditorEngineManager,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import { updateInfo } from '@store/slices/pageInfo';

export const savePageMetadata = createAsyncThunk<
  void,
  void,
  {
    state: LocalState;
    rejectedMeta: string[];
  }
>(
  'pageInfo/savePageMetadata',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const {
      userInfo: {
        permissions: { templateManagementEnabled },
      },
      pageInfo: { mode, uuid, metadata = {} },
      pageConfig: { exitPopup },
      pageScripts: { head, body, supportChatScript },
    } = getState();

    const pageState = EditorEngineManager.getFinalPage() as Template;
    const scripts = { head, body };

    const hasRedirectScript = hasRedirectScriptInPage([
      JSON.stringify(scripts.head.end),
      JSON.stringify(scripts.body.end),
      supportChatScript,
      ...extractHtmlValues(pageState),
    ]);

    const popupUids = (pageState.children || [])
      .filter((child) => child.component === 'ls-popup')
      .map((child) => child.uid);

    const countButtonsWithPopupId = (children: Template[] = []): Template[] =>
      children.reduce<Template[]>((acc, child) => {
        const isButtonWithPopupId =
          child.component === 'ls-button' &&
          child.action === 'popup-on-click' &&
          popupUids.includes(child['popup-id']);

        const buttons = countButtonsWithPopupId(child.children);
        const newAcc = [...acc, ...buttons];

        return isButtonWithPopupId ? [...newAcc, child] : newAcc;
      }, []);

    const clickButtonElements =
      pageState.children?.filter(
        (child) => child['when-display'] === 'buttonClick'
      ) || [];

    const buttonClickCount = clickButtonElements.length;
    const hasButtonClick = buttonClickCount > 0;

    const ctaConfiguredCount = pageState?.children?.some(
      (item: any) => item.component === 'ls-popup'
    )
      ? countButtonsWithPopupId(pageState.children).length
      : 0;

    try {
      const page = {
        metadata: {
          ...metadata,
          popup: {
            hasExitPopup: Boolean(exitPopup),
            hasButtonClick,
            buttonClickCount,
            ctaConfiguredCount,
          },
          mpt: {
            hasRedirectScript,
          },
        },
      };

      await Pages.updatePage(uuid, page, mode, templateManagementEnabled);
      dispatch(updateInfo(page));
    } catch (error) {
      const appError =
        error instanceof AppError
          ? error
          : new AppError(`toastMessages.${mode}.error.saved`);
      throw rejectWithValue(appError.message, [appError.message]);
    }
  }
);

export const savePageMetadataBuilder = (
  builder: ActionReducerMapBuilder<PageInfoState>
) => {
  builder.addCase(savePageMetadata.fulfilled, (state: PageInfoState) => {
    state.hasUnsavedChanges = false;
    state.savingStatus = 'success';
    state.savingStatusMessage = '';
  });
  builder.addCase(savePageMetadata.rejected, (state: PageInfoState, action) => {
    const savingStatusMessage = action.meta[0];
    state.savingStatus = 'error';
    state.savingStatusMessage = savingStatusMessage;
  });
};
