import Cookies from 'js-cookie';
import GenericCookieStrategy from '../genericCookieStrategy';

export default class ColaboratorTokenStrategy extends GenericCookieStrategy {
  constructor() {
    super('integrationBWCentral');
    const cookieApiWithoutConverter = Cookies.withConverter({
      read: (value, name) => {
        if (name === 'integrationBWCentral') {
          return value;
        }

        return Cookies.converter.read(value, name);
      },
    });
    this.setCookieApi(cookieApiWithoutConverter);
  }
}
