import { rebrand } from '@config/rebrand';
import Http from '../http';

export default class SavedPopups {
  private static http = new Http();

  private static url = `${rebrand.urls.API_URL}/v1/popups`;

  public static async get(): Promise<PageFragmentListItem[]> {
    return this.http.get<PageFragmentListItem[]>(this.url);
  }

  public static async create(body: Omit<PageFragmentListItem, 'id'>) {
    return this.http.post<PageFragmentListItem[]>(this.url, body);
  }

  public static async update(id: string, body: Partial<PageFragmentListItem>) {
    return this.http.patch<PageFragmentListItem[]>(`${this.url}/${id}`, body);
  }

  public static delete(id: string) {
    return this.http.delete<PageFragmentListItem[]>(`${this.url}/${id}`);
  }
}
