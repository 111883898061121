export default [
  {
    name: 'email',
    type: 'email',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.email',
    value: '',
    required: true,
  },
  {
    name: 'first_name',
    type: 'text',
    label:
      'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.firstName',
    value: '',
    required: false,
  },
  {
    name: 'last_name',
    type: 'text',
    label:
      'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.lastName',
    value: '',
    required: false,
  },
  {
    name: 'phone',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.phone',
    value: '',
    required: false,
  },
  {
    name: 'birthday',
    type: 'text',
    label:
      'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.birthday',
    value: '',
    required: false,
  },
  {
    name: 'company',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.company',
    value: '',
    required: false,
  },
  {
    name: 'address',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.address',
    value: '',
    required: false,
  },
  {
    name: 'city',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.city',
    value: '',
    required: false,
  },
  {
    name: 'state',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.state',
    value: '',
    required: false,
  },
  {
    name: 'zip_code',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.zipCode',
    value: '',
    required: false,
  },
  {
    name: 'country',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.country',
    value: '',
    required: false,
  },
  {
    name: 'website',
    type: 'text',
    label: 'pageWizard.publishLeadCapturePage.form.inputs.modal.fields.website',
    value: '',
    required: false,
  },
];
