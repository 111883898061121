import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0;
`;

export const GroupWrapper = styled.div`
  display: flex;
  gap: var(--spacer-4);
  margin-bottom: 16px;
`;

export const EndDateWrapper = styled.div`
  margin-top: 16px;
`;
