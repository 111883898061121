import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
`;

export const Button = styled.button`
  background: transparent !important;
  border: none;
  color: var(--gray-500) !important;
  cursor: pointer;
  display: flex;

  :hover {
    background: var(--blue-lightest) !important;
  }
`;
