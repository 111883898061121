import '@hotmart-org-ca/cosmos/dist/structure';
import '@hotmart-org-ca/cosmos/dist/content';
import '@hotmart-org-ca/cosmos/dist/content-menu';

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import isEqual from 'lodash.isequal';
import { SerializedError } from '@reduxjs/toolkit';
import {
  EditorEngineManager,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import { updateConfig } from '@store/slices/pageConfig';
import { pageInfoInitialState, updateInfo } from '@store/slices/pageInfo';
import { setAppLoading } from '@store/slices/application';
import { setPageScripts } from '@store/slices/pageScripts';
import { redirectToErrorPage } from '@common/utils';
import Pages from '@services/pages';
import Files from '@services/files';
import Klickart from '@services/klickart';
import { useUserInfo } from '@hooks/useUserInfo';
import { useFeatureFlags } from '@hooks/index';
import { useMenu } from '@hooks/useMenu';
import { useSavedSections } from '@hooks/useSavedSections';
import { useSavedPopups } from '@hooks/useSavedPopups';
import { redirectToCaptureFunnel } from '@pages/PageWizard/common';
import { SideMenu } from '@components/SideMenu';
import { Submenus } from '@components/Submenus';
import { Loader } from '@components/Loader';
import { casEnabled } from 'src/auth/authService';
import { EditorContainer } from './components/EditorContainer';
import { PageURL } from './components/PageURL';
import { loadChat } from './supportChat';
import { Onboarding } from './components/Onboarding';
import { MerlinChat } from './styles';
import { SaveSectionModal } from './components/SaveSectionModal';

export type EditorProps = {
  mode?: EditorMode;
};

const EditorComponent: React.FC<EditorProps> = ({ mode = 'page' }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isFreemium } = useUserInfo();
  const { zendeskMessengerEnabled, editFunnelPagesEnabled } = useFeatureFlags();
  const { closeMenu, setActiveMenu, setActiveSubmenu } = useMenu();
  const { getUserSections } = useSavedSections();
  const { getUserPopups } = useSavedPopups();

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    template: { uid: '', component: '' },
  });

  const activeMenu = useSelector((state: State) => state.menu.activeMenu);
  const devicePreview = useSelector((state: State) => state.lsEditor.preview);
  const templateManagementEnabled = useSelector(
    (state: State) => state.userInfo.permissions.templateManagementEnabled
  );
  const isPreviewMode = useSelector(
    (state: State) => state.application.isPreviewMode
  );
  const hasUnsavedChanges = useSelector(
    (state: State) => state.pageInfo.hasUnsavedChanges
  );

  const freezedIsPreviewMode = useRef(isPreviewMode).current;

  const normalizedPage = useCallback(
    async (uuid: string, pageFile?: PageFile, pageConfig?: PageConfigState) => {
      const pageFileContent = Files.normalizePage({
        mode,
        page: pageFile?.content,
        pageVersion: pageConfig?.pageVersion,
        mobileFirst: pageConfig?.mobileFirst,
      });

      if (!isEqual(pageFile?.content, pageFileContent)) {
        await Files.savePageFile(
          uuid,
          pageFileContent,
          mode,
          templateManagementEnabled
        );
      }

      EditorEngineManager.setPage(pageFileContent);

      return pageFileContent;
    },
    [mode, templateManagementEnabled]
  );

  const normalizedPageConfig = useCallback(
    async (
      uuid: string,
      configFile?: PageConfigState,
      scriptsFile?: PageScriptsFileState,
      hasScriptFile?: boolean
    ) => {
      const [configFileContent, scriptsFileContent] = Files.normalizePageConfig(
        configFile,
        scriptsFile
      );

      if (!isEqual(configFile, configFileContent)) {
        await Files.replaceConfigFile(
          uuid,
          configFileContent,
          mode,
          templateManagementEnabled
        );
      }

      if (!isEqual(scriptsFile, scriptsFileContent)) {
        await Files.saveScriptsFile(
          uuid,
          scriptsFileContent,
          hasScriptFile,
          mode,
          templateManagementEnabled
        );
      }

      dispatch(updateConfig(configFileContent));
      dispatch(setPageScripts(scriptsFileContent));

      return configFileContent;
    },
    [dispatch, mode, templateManagementEnabled]
  );

  const getPage = useCallback(
    async (uuid: string) => {
      try {
        setLoading(true);

        const pageInfo = await Pages.getPage(
          uuid,
          mode,
          templateManagementEnabled
        );

        if (
          pageInfo.metadata?.createdByFunnel &&
          !editFunnelPagesEnabled &&
          !freezedIsPreviewMode
        ) {
          redirectToCaptureFunnel();
          return;
        }

        const files = await Files.getFiles(
          uuid,
          mode,
          templateManagementEnabled
        );
        const configFile = files.find((file) => file.name === 'config');
        const pageFile = files.find((file) => file.name === 'page');
        const scriptsFile = files.find((file) => file.name === 'scripts');
        const pageInfoUpdated: PageInfoState = {
          ...pageInfo,
          hasBeenPublished: pageInfo.firstPublishedAt !== null,
          hasFontsConfig: files.some((file) => file.name === 'fontsConfig'),
          hasMetadata: files.some((file) => file.name === 'metadata'),
          mode,
        };

        await normalizedPage(uuid, pageFile, configFile?.content);
        await normalizedPageConfig(
          uuid,
          configFile?.content,
          scriptsFile?.content,
          Boolean(scriptsFile)
        );

        if (!casEnabled()) {
          await Klickart.fetchKlickToken();
        }

        dispatch(updateInfo(pageInfoUpdated));
        dispatch(setAppLoading(false));
        setLoading(false);
      } catch (e) {
        const error = e as ErrorResponse;

        if (error.response?.status === 404) {
          redirectToErrorPage('404');
        } else if (error.response?.status === 403) {
          redirectToErrorPage('403');
        } else {
          setLoading(false);
          dispatch(setAppLoading(false));
          redirectToErrorPage('500');
        }
      }
    },
    [
      dispatch,
      freezedIsPreviewMode,
      mode,
      normalizedPage,
      normalizedPageConfig,
      templateManagementEnabled,
      editFunnelPagesEnabled,
    ]
  );

  useEffect(() => {
    if (id) {
      getPage(id);
      loadChat(isFreemium, zendeskMessengerEnabled);
    } else {
      redirectToErrorPage('404');
    }
  }, [getPage, id, isFreemium, zendeskMessengerEnabled]);

  useEffect(() => {
    const activeMargin = activeMenu ? '370px' : '';
    const content = document.querySelector<HTMLElement>('hot-content')!;
    const container =
      content.shadowRoot!.querySelector<HTMLElement>('#container')!;
    const contentBody = container?.querySelector<HTMLElement>(
      '.hot-application__content-body'
    )!;

    content.style.marginLeft = isPreviewMode ? '0px' : activeMargin;
    container.style.width = '100%';
    contentBody.style.marginLeft = '0';
  }, [activeMenu, isPreviewMode]);

  useEffect(
    () => () => {
      EditorEngineManager.clearState();
      closeMenu();
      dispatch(updateInfo(pageInfoInitialState));
    },
    [closeMenu, dispatch]
  );

  useEffect(() => {
    window.onbeforeunload = hasUnsavedChanges ? () => true : null;
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        getUserSections();
        getUserPopups();
      } catch (error) {
        const serializedError = error as SerializedError;
        throw serializedError;
      }
    };

    fetchData();
  }, [dispatch, getUserPopups, getUserSections]);

  useEffect(() => {
    const eventHandler = (event: Event) => {
      const emittedValue: {
        template: Template;
        origin: 'MODIFIERS_LIST' | 'HIGHLIGHT_ACTION';
      } = (event as CustomEvent).detail;

      const eventType = {
        MODIFIERS_LIST: 'Side bar',
        HIGHLIGHT_ACTION: 'Section',
      };

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_save_section',
          event_details: eventType[emittedValue.origin],
        },
      });

      setShowModal({
        isOpen: true,
        template: emittedValue.template,
      });
    };

    window.addEventListener('on-template-emitted', eventHandler);

    return () => {
      window.removeEventListener('on-template-emitted', eventHandler);
    };
  }, []);

  useEffect(() => {
    const openMenu = (event: Event) => {
      const {
        menu,
        submenu = '',
        customEventName = '',
      } = (event as CustomEvent).detail;

      if (customEventName) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            custom_event_name: customEventName,
            event_details: undefined,
          },
        });
      }

      EditorEngineManager.clearActive();

      closeMenu();
      setActiveMenu(menu);

      if (submenu) {
        if (Array.isArray(submenu)) {
          submenu.forEach((submenuValue) => setActiveSubmenu(submenuValue));
        } else {
          setActiveSubmenu(submenu);
        }
      }
    };

    window.addEventListener('toast-open-menu', openMenu);

    return () => {
      window.removeEventListener('toast-open-menu', openMenu);
    };
  }, [closeMenu, dispatch, setActiveMenu, setActiveSubmenu]);

  return (
    <hot-structure>
      <SideMenu />

      <hot-content slot="content">
        <MerlinChat id="merlin-container" />
        <Loader loading={loading} />
        {!loading && !isPreviewMode && <Onboarding />}
        {!isPreviewMode && <Submenus />}
        {mode === 'page' && <PageURL device={devicePreview} />}
        <EditorContainer mode={mode} />
      </hot-content>

      <SaveSectionModal
        isOpen={showModal.isOpen}
        onClose={() =>
          setShowModal({ isOpen: false, template: { uid: '', component: '' } })
        }
        templateSection={showModal.template}
      />
    </hot-structure>
  );
};

export const Editor = memo(EditorComponent);
