import { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from '@components/Block';
import { ImageUploadButton } from '@components/ImageUploadButton';
import { Container, Preview, Image, Icon, Label } from './styles';

export type ImageUploadProps = HTMLAttributes<HTMLDivElement> & {
  src?: string;
  blockTitle: string;
  label: string;
  onImageChange?: (url: string) => void;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
  src: srcProp = '',
  blockTitle,
  label,
  onImageChange,
  ...attrs
}) => {
  const [src, setSrc] = useState(srcProp);
  const { t } = useTranslation();

  const handleImage = useCallback(
    (imageSrc: string) => {
      onImageChange?.(imageSrc);
      setSrc(imageSrc);
    },
    [onImageChange]
  );

  return (
    <Block label={blockTitle} {...attrs}>
      <Container>
        <Label>{t(label)}</Label>
        <Preview>
          {src ? (
            <Image src={src} alt="product image" />
          ) : (
            <div>
              <Icon className="fal fa-image" />
            </div>
          )}
        </Preview>

        <ImageUploadButton
          buttonType="secondary"
          fullWidth
          label="pageWizard.publishPage.imageUpload.uploadButton"
          onImageChange={(imageSource: string) => handleImage(imageSource)}
        />
      </Container>
    </Block>
  );
};
