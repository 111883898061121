import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from '@store/index';
import * as pageInfoSlice from '@store/slices/pageInfo';
import { pageInfoThunks } from '@store/thunks/pageInfo';

export const usePageInfo = () => {
  const dispatch = useAppDispatch();

  const updateInfo = useCallback(
    (updatedInfo: Partial<PageInfoState>) => {
      dispatch(pageInfoSlice.updateInfo(updatedInfo));
    },
    [dispatch]
  );

  const savePageMetadata = useCallback(async () => {
    await dispatch(pageInfoThunks.savePageMetadata()).unwrap();
  }, [dispatch]);

  const domain = useSelector((state: State) => state.pageInfo.domain);

  const enabled =
    useSelector((state: State) => state.pageInfo.enabled) || false;

  const hasMetadata = useSelector((state: State) => state.pageInfo.hasMetadata);

  const hasUnsavedChanges = useSelector(
    (state: State) => state.pageInfo.hasUnsavedChanges
  );

  const mode = useSelector((state: State) => state.pageInfo.mode);

  const hasBeenPublished = useSelector(
    (state: State) => state.pageInfo.hasBeenPublished
  );

  const hasFontsConfig = useSelector(
    (state: State) => state.pageInfo.hasFontsConfig
  );

  const pageName = useSelector((state: State) => state.pageInfo.name);

  const path = useSelector((state: State) => state.pageInfo.path);

  const published = useSelector((state: State) => state.pageInfo.published);

  const redirectUrl = useSelector((state: State) => state.pageInfo.redirectUrl);

  const savingStatus = useSelector(
    (state: State) => state.pageInfo.savingStatus
  );

  const savingStatusMessage = useSelector(
    (state: State) => state.pageInfo.savingStatusMessage
  );

  const uuid = useSelector((state: State) => state.pageInfo.uuid);

  const createdByFunnel = useSelector((state: State) =>
    Boolean(state.pageInfo.metadata?.createdByFunnel)
  );

  const useCaseType = useSelector(
    (state: State) => state.pageInfo.metadata?.useCaseType
  );

  const isFunnelCapturePage = useMemo(
    () =>
      createdByFunnel &&
      useCaseType === 'funnel_capture_publish_lead_capture_page',
    [createdByFunnel, useCaseType]
  );

  return {
    updateInfo,
    savePageMetadata,
    domain,
    enabled,
    hasBeenPublished,
    hasFontsConfig,
    hasMetadata,
    hasUnsavedChanges,
    mode,
    pageName,
    path,
    published,
    redirectUrl,
    savingStatus,
    savingStatusMessage,
    uuid,
    createdByFunnel,
    useCaseType,
    isFunnelCapturePage,
  };
};
