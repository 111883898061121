import '@hotmart-org-ca/cosmos/dist/structure';
import '@hotmart-org-ca/cosmos/dist/content';
import '@hotmart-org-ca/cosmos/dist/content-menu';

import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import { SideMenu } from '@components/SideMenu';
import { Loader } from '@components/Loader';
import { updateInfo } from '@store/slices/pageInfo';
import { redirectToErrorPage } from '@common/utils';
import { setAppLoading, setIsPreviewMode } from '@store/slices/application';
import Pages from '@services/pages';
import Files from '@services/files';
import { EditorContainer } from '../Editor/components/EditorContainer';

export type EditorPreviewProps = {
  mode?: EditorMode;
};

const EditorPreviewComponent: React.FC<EditorPreviewProps> = ({
  mode = 'page',
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const getPage = useCallback(
    async (uuid: string) => {
      try {
        setLoading(true);

        const pageInfo = await Pages.getTemplatePreview(uuid);
        const files = await Files.getTemplatePreviewFiles(uuid);
        const pageFile = files.find((file) => file.name === 'page');

        EditorEngineManager.setPage(pageFile?.content);

        const pageInfoUpdated: PageInfoState = {
          ...pageInfo,
          mode,
        };

        dispatch(updateInfo(pageInfoUpdated));
        dispatch(setIsPreviewMode(true));
        dispatch(setAppLoading(false));
        setLoading(false);
      } catch (e) {
        const error = e as ErrorResponse;

        if (error.response?.status === 404) {
          redirectToErrorPage('404');
        } else {
          setLoading(false);
          dispatch(setAppLoading(false));
        }
      }
    },
    [dispatch, mode]
  );

  useEffect(() => {
    if (id) {
      getPage(id);
    } else {
      redirectToErrorPage('404');
    }
  }, [getPage, id]);

  useEffect(() => {
    const content = document.querySelector<HTMLElement>('hot-content')!;
    const container =
      content.shadowRoot!.querySelector<HTMLElement>('#container')!;
    const contentBody = container?.querySelector<HTMLElement>(
      '.hot-application__content-body'
    )!;

    content.style.marginLeft = '0px';
    container.style.width = '100%';
    contentBody.style.marginLeft = '0';
  }, []);

  useEffect(
    () => () => {
      EditorEngineManager.clearState();
    },
    [dispatch]
  );

  return (
    <>
      <Loader loading={loading} />
      <hot-structure>
        <SideMenu isTemplatePreview />
        <hot-content slot="content">
          <EditorContainer mode={mode} />
        </hot-content>
      </hot-structure>
    </>
  );
};

export const EditorPreview = memo(EditorPreviewComponent);
