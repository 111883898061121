import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/tag';
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useUseCases } from '@hooks/useUseCases';
import { TextInput } from '@components/TextInput';
import { KlickSendFormTerms } from '@components/KlicksendFormTerms';
import { KlicksendFormTags } from '@components/KlicksendFormTags';
import { KlicksendFormsInputsModal } from '@components/KlicksendFormInputsModal';
import { Block } from '@components/Block';
import debounce from 'lodash.debounce';
import {
  StepContainer,
  FormInputsContainer,
  EditButton,
  TagsWrapper,
} from './styles';

export type CaptureThirdStepProps = UseCaseStepProps & {
  formFields: KlicksendFormField[];
  formName: string;
  tags?: KlicksendTag[];
  formSigleOptIn?: boolean;
  formTerms?: boolean;
  formTag?: string;
  personalizeTag?: boolean;
  type: UseCaseType;
};

const ThirdStepComponent: React.FC<CaptureThirdStepProps> = ({
  formFields = [],
  tags = [],
  formName: formNameProp,
  formSigleOptIn,
  formTerms,
  formTag,
  personalizeTag,
  onModifierChange,
  type,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { setFooterRightButtonConfig } = useUseCases();

  const [showFormInputsModal, setShowFormInputsModal] = useState(false);
  const [formName, setFormName] = useState(formNameProp);

  const debouncedOnValueChange = useRef(debounce(onModifierChange, 500));

  const handleNameChange = useCallback((e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;

    setFormName(value);
    debouncedOnValueChange.current({
      prop: 'formName',
      value,
    });
  }, []);

  const handleTermsChange = useCallback(
    (value: boolean) => {
      onModifierChange?.({ prop: 'formTerms', value });
    },
    [onModifierChange]
  );

  const handleSingleOptInChange = useCallback(
    (value: boolean) => {
      onModifierChange?.({ prop: 'formSigleOptIn', value });
      if (!value) {
        handleTermsChange(false);
      }
    },
    [handleTermsChange, onModifierChange]
  );

  const handleTagChange = useCallback(
    (value: string) => {
      onModifierChange?.({ prop: 'formTag', value });
    },
    [onModifierChange]
  );

  const handlePersonalizeTagChange = useCallback(
    (value: boolean) => {
      onModifierChange?.({ prop: 'personalizeTag', value });
    },
    [onModifierChange]
  );

  useEffect(() => {
    let disabled = false;
    let errorMessage = '';

    if (formSigleOptIn && !formTerms) {
      disabled = true;
      errorMessage = 'pageWizard.publishLeadCapturePage.form.terms.helper';
    } else if (personalizeTag && !formTag) {
      disabled = true;
      errorMessage = 'pageWizard.publishLeadCapturePage.form.tag.helper';
    }

    setFooterRightButtonConfig({
      disabled,
      errorMessage,
    });
  }, [
    formSigleOptIn,
    formTerms,
    personalizeTag,
    formTag,
    setFooterRightButtonConfig,
  ]);

  const formInputs = useMemo(
    () => (
      <FormInputsContainer className="hot-form">
        <label className="hot-form__label">
          {t('pageWizard.publishLeadCapturePage.form.inputs.label')}

          <hot-tooltip
            position="top"
            content={t('pageWizard.publishLeadCapturePage.form.inputs.tooltip')}
          >
            <EditButton
              className="hot-button hot-button--tertiary"
              onClick={() => setShowFormInputsModal(true)}
            >
              <i className="far fa-pen" />
            </EditButton>
          </hot-tooltip>
        </label>

        <TagsWrapper>
          {formFields
            .filter((field) => field.name !== 'gdpr')
            .map((field) => (
              <hot-tag key={field.name}>{t(`${field.label}`)}</hot-tag>
            ))}
        </TagsWrapper>
      </FormInputsContainer>
    ),
    [formFields, t]
  );

  return (
    <StepContainer {...attrs}>
      <Block
        label="pageWizard.publishLeadCapturePage.form.title"
        infoTooltip={{
          displayMode: 'hover',
          title: 'pageWizard.publishLeadCapturePage.form.title',
          description: 'pageWizard.publishLeadCapturePage.form.tooltip',
        }}
        tooltipPosition="top"
        showSeparator={type !== 'funnel_capture_publish_lead_capture_page'}
      >
        <TextInput
          id="form-name"
          name="form-name"
          placeholder={t(
            'pageWizard.publishLeadCapturePage.form.name.placeholder'
          )}
          size="md"
          removeEmoji
          onChange={handleNameChange}
          value={formName}
          label={t('pageWizard.publishLeadCapturePage.form.name.label')}
        />

        {formInputs}
      </Block>

      {type !== 'funnel_capture_publish_lead_capture_page' && (
        <Block
          label="pageWizard.publishLeadCapturePage.config.title"
          showSeparator={false}
        >
          <KlickSendFormTerms
            formSigleOptIn={formSigleOptIn}
            formTerms={formTerms}
            onTermsChange={handleTermsChange}
            onSingleOptInChange={handleSingleOptInChange}
          />

          {Boolean(tags.length) && (
            <KlicksendFormTags
              tags={tags}
              selectedTag={formTag}
              personalizeTag={personalizeTag}
              onTagSelected={handleTagChange}
              onPersonalizeChange={handlePersonalizeTagChange}
            />
          )}
        </Block>
      )}

      <KlicksendFormsInputsModal
        formInputs={formFields}
        isOpen={showFormInputsModal}
        onClose={() => setShowFormInputsModal(false)}
        onFormInputsChange={(updatedFields) =>
          onModifierChange({
            prop: 'formFields',
            value: updatedFields,
          })
        }
      />
    </StepContainer>
  );
};

export const ThirdStep = memo(ThirdStepComponent);
