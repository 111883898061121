import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';
import '@hotmart-org-ca/cosmos/dist/loading';

import React, {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Container,
  NotFoundMedia,
  ContainerPreview,
  MediaNameContainer,
  MediaNameContent,
  Content,
  Extension,
  Name,
  Media,
  Label,
  Divider,
} from './styles';
import { MediaFolder } from './components/MediaFolder';
import { MediaPreview } from './components/MediaPreview';
import { RemoveMediaModal } from './components/RemoveMediaModal';
import { InfoBar } from './components/InfoBar';
import { setMedia } from '../../../../store/slices/mediaCenter';
import MediaCenter from '../../../../services/mediaCenter';
import { ToolBar } from './components/Toolbar';
import { useMediaCenter } from '../../../../hooks/useMediaCenter';

export type MediaListProps = HTMLAttributes<HTMLDivElement> & {
  isSelectable?: boolean;
  selectedMediaId?: string;
  displayMode?: MediaCenterDisplayMode;
  onSelectedImageChange?: (media: Media) => void;
};

const MediaListComponent: React.FC<MediaListProps> = ({
  isSelectable = false,
  selectedMediaId,
  displayMode = 'submenu',
  onSelectedImageChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentMedia, setCurrentMedia] = useState('');

  const [mediaToRemove, setMediaToRemove] = useState<
    Record<string, Media> | undefined
  >();
  const [openModal, setOpenModal] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    showInfoBar,
    media: previewMedia,
    selectedMediaLength,
    uploadingMedia,
    loading: mediaLoading,
    openRootFolder,
  } = useMediaCenter();

  useEffect(() => {
    openRootFolder();
  }, [openRootFolder]);

  useEffect(() => {
    if (currentMedia && inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentMedia]);

  const media = useMemo(
    () => [...uploadingMedia, ...previewMedia],
    [previewMedia, uploadingMedia]
  );

  const getConcatMediaName = useCallback(
    (name: string, extension: string) => `${name}${extension}`,
    []
  );

  const getMediaKey = useCallback(
    (mediaItem: Media) => `${mediaItem.id}-${mediaItem.type}`,
    []
  );

  const saveMediaName = useCallback(
    (mediaItem: Media, label: string) => {
      if (mediaItem.name !== label) {
        const newName =
          mediaItem.type === 'media'
            ? getConcatMediaName(label, mediaItem.extension)
            : label;

        MediaCenter.updateItemName(mediaItem.id, mediaItem.type, newName);
      }
      dispatch(
        setMedia(
          previewMedia.map((item) => ({
            ...item,
            name:
              getMediaKey(item) === getMediaKey(mediaItem) ? label : item.name,
          }))
        )
      );

      setCurrentMedia('');
    },
    [dispatch, getConcatMediaName, getMediaKey, previewMedia]
  );

  const handleNameChange = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement>,
      mediaItem: Media
    ) => {
      event.stopPropagation();

      const validKeys = ['Escape', 'Enter'];
      const newName = (event.target as HTMLInputElement).value;
      const keyPressed = (event as React.KeyboardEvent<HTMLInputElement>).key;

      if (keyPressed && !validKeys.includes(keyPressed)) {
        return;
      }

      if (newName && keyPressed !== 'Escape') {
        saveMediaName(mediaItem, newName);
        return;
      }

      setCurrentMedia('');
    },
    [saveMediaName]
  );

  const isNotFoundMedia = useCallback(() => media && media.length < 1, [media]);

  const handleRemoveMediaClick = useCallback((mediaItem: Media) => {
    setMediaToRemove({ [`${mediaItem.id}-${mediaItem.type}`]: mediaItem });
    setOpenModal(true);
  }, []);

  const handleMediaClick = useCallback(
    (mediaItem: Media) => {
      if (isSelectable && mediaItem.type === 'media') {
        onSelectedImageChange?.(mediaItem);
      }
    },
    [isSelectable, onSelectedImageChange]
  );

  const isSelected = useCallback(
    (id: string) => selectedMediaId === id,
    [selectedMediaId]
  );

  return (
    <Container
      selectedMedia={Boolean(selectedMediaLength)}
      showInfoBar={showInfoBar}
      {...attrs}
    >
      <ToolBar />
      <Divider />

      {previewMedia.length || uploadingMedia.length ? (
        <>
          {!isSelectable && (
            <RemoveMediaModal
              isOpen={openModal}
              mediaItem={mediaToRemove}
              onClose={() => setOpenModal(false)}
            />
          )}
          <ContainerPreview
            isSelectable={isSelectable}
            isNotFoundMedia={isNotFoundMedia()}
          >
            {isNotFoundMedia() ? (
              <NotFoundMedia>{t('mediaCenter.imageNotFound')}</NotFoundMedia>
            ) : (
              media.map((mediaItem: UploadingMedia) => (
                <Media
                  isSelectable={isSelectable}
                  isFolder={mediaItem.type === 'media_folder'}
                  key={getMediaKey(mediaItem)}
                >
                  <Content onClick={() => handleMediaClick(mediaItem)}>
                    {mediaItem.type === 'media' ? (
                      <MediaPreview
                        isSelectable={isSelectable}
                        isSelected={isSelected(mediaItem.id)}
                        image={mediaItem}
                        onChangeCurrentMedia={() =>
                          setCurrentMedia(getMediaKey(mediaItem))
                        }
                        onRemoveCurrentMedia={() =>
                          handleRemoveMediaClick(mediaItem)
                        }
                      />
                    ) : (
                      <MediaFolder
                        folder={mediaItem}
                        isSelectable={isSelectable}
                        onChangeCurrentFolderName={() =>
                          setCurrentMedia(getMediaKey(mediaItem))
                        }
                        onRemoveCurrentFolder={() =>
                          handleRemoveMediaClick(mediaItem)
                        }
                      />
                    )}
                  </Content>

                  <MediaNameContainer>
                    {currentMedia === getMediaKey(mediaItem) ? (
                      <input
                        ref={inputRef}
                        className="hot-form__input hot-form__input--sm"
                        defaultValue={mediaItem.name}
                        onBlur={(event) => handleNameChange(event, mediaItem)}
                        onKeyDown={(event) =>
                          handleNameChange(event, mediaItem)
                        }
                      />
                    ) : (
                      <MediaNameContent
                        title={
                          mediaItem.type === 'media'
                            ? getConcatMediaName(
                                mediaItem.name,
                                mediaItem.extension
                              )
                            : mediaItem.name
                        }
                      >
                        <Name>{mediaItem.name}</Name>

                        {mediaItem.type === 'media' && (
                          <Extension>{mediaItem.extension}</Extension>
                        )}
                      </MediaNameContent>
                    )}
                  </MediaNameContainer>
                </Media>
              ))
            )}
          </ContainerPreview>
        </>
      ) : (
        <Label>{t('mediaCenter.searchImage.emptyGallery')}</Label>
      )}

      {mediaLoading ? <hot-loading /> : ''}

      <InfoBar displayMode={displayMode} />
    </Container>
  );
};

export const MediaList = memo(MediaListComponent);
