import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { removeEmojis } from '@common/utils';
import { Helper, Label, StyledHotForm } from './styles';

export type TextInputProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  id: string;
  label?: string | React.ReactElement;
  name: string;
  placeholder?: string;
  size: 'sm' | 'md' | 'lg';
  value?: string;
  rows?: number;
  inputType?: 'input' | 'textarea';
  readOnly?: boolean;
  infoTooltip?: string;
  helper?: string;
  width?: string;
  removeEmoji?: boolean;
  onChange?: (e: React.ChangeEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
};

const TextInputComponent: React.FC<TextInputProps> = ({
  id,
  label,
  name,
  placeholder,
  size,
  value = '',
  inputType = 'input',
  readOnly = false,
  rows = 4,
  infoTooltip,
  helper,
  width,
  removeEmoji = false,
  onChange,
  onBlur,
  ...attrs
}) => {
  const Tag = useMemo(() => inputType, [inputType]);
  const [text, setText] = useState('');

  const tagProps = useMemo(
    () => (inputType === 'input' ? { type: 'text' } : { rows }),
    [inputType, rows]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (removeEmoji) {
        e.target.value = removeEmojis(e.target.value);
      }
      setText(e.target.value);
      onChange?.(e);
    },
    [onChange, removeEmoji]
  );

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <StyledHotForm className="hot-form" width={width} {...attrs}>
      {label && (
        <Label className="hot-form__label" htmlFor={id}>
          {label}
          {infoTooltip && (
            <hot-tooltip position="top" content={infoTooltip}>
              <i className="fal fa-info-circle" />
            </hot-tooltip>
          )}
        </Label>
      )}
      <Tag
        id={id}
        name={name}
        className={`hot-form__input hot-form__input--${size}`}
        data-prevent-delete
        placeholder={placeholder}
        value={text}
        readOnly={readOnly}
        {...tagProps}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {helper && <Helper>{helper}</Helper>}
    </StyledHotForm>
  );
};
export const TextInput = memo(TextInputComponent);
