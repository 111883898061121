import HotmartAIService from '@services/hotmartAI';
import Http from '../http';

export default class RewriteAI {
  static getRewriteTextByType(rewriteFeature: string, text: string) {
    const source = Http.getCancelSource();

    const promptEnv =
      rewriteFeature === 'objective'
        ? (process.env.REACT_APP_EDITOR_REWRITE_OBJECTIVE_TEXT_PROMPT as string)
        : (process.env.REACT_APP_EDITOR_REWRITE_DETAILED_TEXT_PROMPT as string);

    return {
      promise: HotmartAIService.getFromAI<{ output: { rewrite: string[] } }>({
        prompt: promptEnv,
        context: {
          text,
          rewrite_feature: rewriteFeature,
        },
        token: source?.token,
      }),
      source,
    };
  }

  static getToneTextByType(tone: string, text: string) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<{
        output: { tone: string[] };
      }>({
        prompt: process.env.REACT_APP_EDITOR_REWRITE_TEXT_TONE_PROMPT as string,
        context: {
          text,
          tone,
        },
        token: source?.token,
      }),
      source,
    };
  }

  static getRevisedText(text: string) {
    const source = Http.getCancelSource();

    return {
      promise: HotmartAIService.getFromAI<{
        output: { text: string[] };
      }>({
        prompt: process.env
          .REACT_APP_EDITOR_REWRITE_REVISED_TEXT_PROMPT as string,
        context: {
          text,
        },
        token: source?.token,
      }),
      source,
    };
  }
}
