import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';
import '@hotmart-org-ca/cosmos/dist/form/input-checkbox.css';

import { HTMLAttributes, memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CosmosSwitch } from '@components/CosmosSwitch';
import { Anchor, CheckboxGroup, CheckboxLabel } from './styles';

export type FormTermsProps = HTMLAttributes<HTMLDivElement> & {
  formSigleOptIn?: boolean;
  formTerms?: boolean;
  onTermsChange?: (value: boolean) => void;
  onSingleOptInChange?: (value: boolean) => void;
};

const KlickSendFormTermsComponent: React.FC<FormTermsProps> = ({
  formSigleOptIn = false,
  formTerms = false,
  onTermsChange,
  onSingleOptInChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const handleTermsChange = useCallback(
    (value: boolean) => {
      onTermsChange?.(value);
    },
    [onTermsChange]
  );

  const handleSingleOptInChange = useCallback(
    (value: boolean) => {
      onSingleOptInChange?.(value);
    },
    [onSingleOptInChange]
  );

  return (
    <div {...attrs}>
      <CosmosSwitch
        id="form-single-confirmation"
        isChecked={formSigleOptIn}
        label={t('pageWizard.publishLeadCapturePage.form.terms.confirmation')}
        onSwitchChanged={(isChecked) => handleSingleOptInChange(isChecked)}
        infoTooltip={{
          title: 'form.klicksend.formCreation.singleOptInTooltip.title',
          description:
            'form.klicksend.formCreation.singleOptInTooltip.description',
          displayMode: 'hover',
        }}
        tooltipPosition="top"
      />
      {formSigleOptIn && (
        <CheckboxGroup className="hot-form hot-form--custom hot-form--checkbox">
          <input
            id="form-terms"
            type="checkbox"
            className="hot-form__input"
            checked={formTerms}
            onChange={(event) => handleTermsChange(event.target.checked)}
          />
          <CheckboxLabel className="hot-form__label" htmlFor="form-terms">
            <Trans
              i18nKey="pageWizard.publishLeadCapturePage.form.terms.description"
              components={{
                a: (
                  <Anchor
                    target="_blank"
                    href="https://extensoes.hotmart.com/termos-de-uso"
                  />
                ),
              }}
            />
          </CheckboxLabel>
        </CheckboxGroup>
      )}
    </div>
  );
};

export const KlickSendFormTerms = memo(KlickSendFormTermsComponent);
