import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const HTML: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="37"
    height="33"
    viewBox="0 0 37 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M15.25 32C15.4375 32.0625 15.6875 31.9375 15.6875 31.75L24.5 0.9375C24.5625 0.75 24.4375 0.5625 24.25 0.5L22.6875 0.0625C22.5 0 22.3125 0.125 22.25 0.3125L13.4375 31.125C13.375 31.3125 13.5 31.5 13.6875 31.5625L15.25 32ZM10.5 24.1875L11.6875 22.875C11.8125 22.75 11.8125 22.5625 11.625 22.4375L4.0625 16L11.625 9.625C11.8125 9.5 11.8125 9.3125 11.6875 9.1875L10.5 7.875C10.4375 7.75 10.1875 7.75 10.0625 7.875L1.0625 15.8125C0.9375 15.9375 0.9375 16.125 1.0625 16.25L10.0625 24.1875C10.1875 24.3125 10.4375 24.3125 10.5 24.1875ZM27.875 24.1875L36.875 16.25C37 16.125 37 15.9375 36.875 15.8125L27.875 7.875C27.75 7.75 27.5625 7.75 27.4375 7.875L26.25 9.1875C26.125 9.3125 26.1875 9.5 26.3125 9.625L33.875 16L26.3125 22.4375C26.1875 22.5625 26.125 22.75 26.25 22.875L27.4375 24.1875C27.5 24.3125 27.75 24.3125 27.875 24.1875Z"
      fill={color}
    />
  </StyledSVG>
);
