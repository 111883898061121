import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedSections from '@services/savedSections';

export const updateUserSection = createAsyncThunk<
  void,
  Omit<PageFragmentListItem, 'template'>,
  {
    state: LocalState;
    SerializedError: {
      name: string;
      stack: string;
      code: string;
    };
  }
>('pageFragments/updateUserSection', async ({ id, name }) => {
  try {
    await SavedSections.update(id, { name });
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const updateUserSectionBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(updateUserSection.pending, (state: PageFragmentsState) => {
    state.sectionLoading = true;
  });
  builder.addCase(
    updateUserSection.fulfilled,
    (state: PageFragmentsState, action) => {
      const { id, name } = action.meta.arg;
      const userSections = state.userSections.map((section) => {
        if (section.id === id) {
          return { ...section, name };
        }
        return section;
      });

      state.userSections = userSections;
      state.sectionLoading = false;
    }
  );
  builder.addCase(updateUserSection.rejected, (state: PageFragmentsState) => {
    state.sectionLoading = false;
  });
};
