import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageScriptsExtraReducers } from '@store/thunks/pageScripts';

export const pageScriptsInitialState: PageScriptsState = {
  head: {
    start: [],
    end: [],
  },
  body: {
    start: [],
    end: [],
  },
  scriptForm: {
    loading: false,
    type: 'head',
    position: 'end',
    scriptSelected: {
      id: '',
      name: '',
      script: '',
    },
  },
  supportChatScript: '',
};

const pageScripts = createSlice({
  name: 'pageScripts',
  initialState: pageScriptsInitialState,
  reducers: {
    addScript(state, action: PayloadAction<ScriptFormConfig>) {
      const { position, type, scriptSelected } = action.payload;

      state[type][position].push(scriptSelected);
    },
    updateScript(state, action: PayloadAction<ScriptFormConfig>) {
      const { position, type, scriptSelected } = action.payload;

      state[type][position] = state[type][position].map((pageScript) =>
        pageScript.id === scriptSelected.id ? scriptSelected : pageScript
      );
    },
    deleteScript(state, action: PayloadAction<ScriptFormConfig>) {
      const { position, type, scriptSelected } = action.payload;

      state[type][position] = state[type][position].filter(
        (pageScript) => pageScript.id !== scriptSelected.id
      );
    },
    setPageScripts(state, action: PayloadAction<PageScriptsFileState>) {
      state.head = action.payload.head;
      state.body = action.payload.body;
      state.supportChatScript = action.payload.supportChatScript;
    },
    setScriptForm(state, action: PayloadAction<Partial<ScriptFormConfig>>) {
      state.scriptForm = {
        ...state.scriptForm,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => pageScriptsExtraReducers(builder),
});

export const {
  addScript,
  deleteScript,
  setPageScripts,
  setScriptForm,
  updateScript,
} = pageScripts.actions;

export default pageScripts.reducer;
