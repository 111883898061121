import styled, { createGlobalStyle } from 'styled-components';
import { UploadMedia } from '@components/UploadMedia';
import { Media } from '@components/Medias';

export const ModalBodyStyle = createGlobalStyle`
  .media-modal__body {
    padding: 0;
  }
`;

export const StyledUploadMedia = styled(UploadMedia)`
  padding: 0;
  flex-direction: column-reverse;
  align-items: flex-start;
`;

export const StyledMedia = styled(Media)`
  overflow: auto;
  max-height: 460px;
  padding: 24px;
  min-height: 380px;
`;

export const Icon = styled.i`
  margin-right: 8px;
`;
