import React, { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { updateConfig } from '@store/slices/pageConfig';
import Toast from '@services/toast';
import Files from '@services/files';
import { containInvalidEmoji, isInvalidEmoji } from '@common/utils';
import { Title } from './components/Title';
import { Description } from './components/Description';
import { Keywords } from './components/Keywords';
import { ShareUrl } from './components/ShareUrl';
import { Container, Button } from './styles';

export type SeoOptions = {
  title?: boolean;
  description?: boolean;
  keywords?: boolean;
  shareUrl?: boolean;
};

export const SEO: React.FC<HTMLAttributes<HTMLDivElement>> = ({ ...attrs }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorMessage = 'seo.emojiError';

  const config = useSelector((state: State) => state.pageConfig);
  const uuid = useSelector((state: State) => state.pageInfo.uuid);

  const [isSaving, setIsSaving] = useState(false);
  const [updatedConfig, setUpdatedConfig] = useState(config);
  const [invalidEmoji, setInvalidEmoji] = useState<SeoOptions>({});

  const handleConfigChange = useCallback(
    (value: string, key: keyof PageConfigState) => {
      setInvalidEmoji((state) => ({
        ...state,
        [key]: isInvalidEmoji(value),
      }));

      setUpdatedConfig((state) => ({
        ...state,
        [key]: value,
      }));
    },
    []
  );

  const handleSave = useCallback(async () => {
    if (containInvalidEmoji(invalidEmoji)) {
      Toast.error('toastMessages.general.error.saved');
      return;
    }

    try {
      setIsSaving(true);
      dispatch(updateConfig(updatedConfig));

      await Files.saveConfigFile(uuid, updatedConfig);

      Toast.success('toastMessages.general.success.saved');
    } catch {
      Toast.error('toastMessages.general.error.saved');
    } finally {
      setIsSaving(false);
    }
  }, [dispatch, invalidEmoji, updatedConfig, uuid]);

  return (
    <Container {...attrs}>
      <Title
        value={config.title}
        onChange={(value) => handleConfigChange(value, 'title')}
        error={invalidEmoji.title}
        errorMessage={errorMessage}
      />
      <Description
        value={config.description}
        onChange={(value) => handleConfigChange(value, 'description')}
        error={invalidEmoji.description}
        errorMessage={errorMessage}
      />
      <Keywords
        value={config.keywords}
        onChange={(value) => handleConfigChange(value, 'keywords')}
        error={invalidEmoji.keywords}
        errorMessage={errorMessage}
      />
      <ShareUrl
        value={config.shareUrl}
        onChange={(value) => handleConfigChange(value, 'shareUrl')}
        error={invalidEmoji.shareUrl}
        errorMessage={errorMessage}
      />
      <Button
        onClick={handleSave}
        className={classNames('hot-button hot-button--sm hot-button--primary', {
          'hot-button--loading': isSaving,
        })}
      >
        <span>{t('seo.save')}</span>
      </Button>
    </Container>
  );
};
