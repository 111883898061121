import '@hotmart-org-ca/cosmos/dist/form/input-default.css';
import classNames from 'classnames';

import React, { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Description } from './styles';

export type ShareUrlProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  value?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
};

const ShareUrlComponent: React.FC<ShareUrlProps> = ({
  value = '',
  error,
  errorMessage,
  onChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleUrlChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div {...attrs} className="hot-form">
      <Title htmlFor="shareUrl">{t('seo.shareUrl.title')}</Title>
      <input
        id="shareUrl"
        className={classNames('hot-form__input  hot-form__input--sm', {
          'is-invalid': error,
        })}
        defaultValue={value}
        onChange={handleUrlChange}
      />

      {error && <div className="invalid-feedback">{t(`${errorMessage}`)}</div>}

      <Description>{t('seo.shareUrl.description')}</Description>
    </div>
  );
};

export const ShareUrl = memo(ShareUrlComponent);
