import styled from 'styled-components';

export const Anchor = styled.a``;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
`;

export const CheckboxLabel = styled.label`
  user-select: none;
`;

export const LinkedSwitchInfo = styled.a`
  display: inline-flex;
  color: var(--gray-500);

  :hover {
    color: var(--gray-500);
  }
`;

export const CheckboxGroup = styled.div`
  margin-left: 40px;
`;
