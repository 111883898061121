import Cookies from 'js-cookie';
import TokenStrategyInterface from '../tokenStrategyInterface';

export default class GenericCookieStrategy implements TokenStrategyInterface {
  private cookieName: string;

  private cookieApi = Cookies;

  constructor(cookieName: string) {
    this.cookieName = cookieName;
  }

  getToken(): string | undefined {
    return this.cookieApi.get(this.cookieName);
  }

  setToken(token: string): void {
    this.cookieApi.set(this.cookieName, token);
  }

  removeToken() {
    this.cookieApi.remove(this.cookieName);
  }

  setCookieApi(cookieApi: Cookies.CookiesStatic<object>) {
    this.cookieApi = cookieApi;
  }
}
