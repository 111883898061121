import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font-size: 16px;
  &::before,
  &::after {
    top: 50% !important;
    transform: translateY(-50%);
  }
`;

export const Description = styled.div`
  font-size: 12px;
  color: var(--gray-500);
  margin-top: 8px;
`;
