import { v4 as uuid } from 'uuid';

export const bonusSections: PageFragmentListItem[] = [
  {
    id: 'bonus-5',
    name: 'Bonus',
    template: {
      background: '#D1BEDD',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Bonus',
        templateId: 'bonus-1',
        templateName: 'Bonus',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '120px 0px 120px 60px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '80px 0px 0px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                padding: '48px 0px 0px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '16px 16px 16px 16px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '263px',
                  href: '',
                  'max-width': '478px',
                  margin: '24px 0px 24px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'object-fit': 'contain',
                    'max-width': '320px',
                    height: '170px',
                    margin: '10px 0px 24px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5410404/img4-nath-vendas_assets_template-nath-arcuri.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5410404/thumb_img4-nath-vendas_assets_template-nath-arcuri.png',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '24px 0px 24px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '24px 0px 80px 0px',
              },
              mobile: {
                padding: '0px 0px 48px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1><strong style="font-size: 48px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(247, 185, 212);"> Hora do bônus </strong></h1>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<h1 style="text-align: center;"><strong style="font-size: 48px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(247, 185, 212);"> Hora do bônus </strong></h1>',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="font-size: 32px; font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); background-color: rgb(247, 185, 212);"> Hora do bônus </strong></p>',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 18px;">Sempre que possível, adicione um material especial, uma live exclusiva ou até mesmo um outro curso de bônus. </span></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 18px;">Sempre que possível, adicione um material especial, uma live exclusiva ou até mesmo um outro curso de bônus. </span></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><span style="font-family: Montserrat, sans-serif; color: rgb(97, 91, 155); font-size: 16px;">Sempre que possível, adicione um material especial, uma live exclusiva ou até mesmo um outro curso de bônus. </span></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'bonus-6',
    name: 'Bonus',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 10',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 50px 0px',
              },
              mobile: {
                padding: '40px 0px 30px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 40px;">Ao comprar o curso hoje, você garante XX presentes exclusivos:</strong></p>',
                  'max-width': '60%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 26px;">Ao comprar o curso hoje, você garante XX presentes exclusivos:</strong></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif; font-size: 18px;">[Explique cada um dos bônus entregues, mostrando benefícios e resultados que o cliente terá ao consumir e aplicar os conhecimentos aprendidos em cada bônus.]</span></p>',
                  'max-width': '70%',
                  padding: '30px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif; font-size: 14px;">[Explique cada um dos bônus entregues, mostrando benefícios e resultados que o cliente terá ao consumir e aplicar os conhecimentos aprendidos em cada bônus.]</span></p>',
                    'max-width': '80%',
                    padding: '20px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 40px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 20px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '80%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '50%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '120px',
                      href: '',
                      'max-width': '120px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5396497/icone-10.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5396497/thumb_icone-10.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">BÔNUS 1</strong></p>',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif; font-size: 15px;">[Descreva o bônus aqui.]</span></p>',
                      'max-width': '80%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 40px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 20px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '80%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '50%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '120px',
                      href: '',
                      'max-width': '120px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5396497/icone-10.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5396497/thumb_icone-10.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">BÔNUS 2</strong></p>',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif; font-size: 15px;">[Descreva o bônus aqui.]</span></p>',
                      'max-width': '80%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 0px 40px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 20px 10px',
                  'border-radius': '20px 20px 20px 20px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#F3F5FA',
                  'max-width': '80%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '50%',
                  },
                  mobile: {
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '120px',
                      href: '',
                      'max-width': '120px',
                      margin: '0px 0px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5396497/icone-10.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5396497/thumb_icone-10.png',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183); font-size: 18px;">BÔNUS 3</strong></p>',
                      'max-width': '70%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {},
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                    {
                      text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(110, 110, 110); font-family: Montserrat, sans-serif; font-size: 15px;">[Descreva o bônus aqui.]</span></p>',
                      'max-width': '80%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        'max-width': '90%',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 70px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 50px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 23px;">Faça um fechamento da parte dos bônus: </strong></p>',
                  'max-width': '60%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 20px;">Faça um fechamento da parte dos bônus: </strong></p>',
                    'max-width': '70%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 19px;">[Exemplo: Entendi, Rafael! Eu já quero me tornar um prestador de serviços de marketing digital e começar a ganhar dinheiro na internet!]</span></p>',
                  'max-width': '60%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    'max-width': '82%',
                    text: '<p style="text-align: center;"><span style="background-color: transparent; color: rgb(20, 20, 54); font-family: Montserrat, sans-serif; font-size: 15px;">[Exemplo: Entendi, Rafael! Eu já quero me tornar um prestador de serviços de marketing digital e começar a ganhar dinheiro na internet!]</span></p>',
                    padding: '10px 0px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#141436',
                  background: '#61F5B0',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO COMEÇAR AGORA!',
                  margin: '40px 0px 20px 0px',
                  'max-width': '450px',
                  mobile: {
                    'font-size': '18px',
                    'max-width': '300px',
                    margin: '25px 0px 20px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'bonus-7',
    name: 'Bonus',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Bônus',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 10px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '60px 0px 10px 0px',
              },
              mobile: {
                padding: '40px 0px 10px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Montserrat, sans-serif; color: rgb(96, 182, 67); font-size: 26px;">Além das aulas, você também terá acesso aos seguintes bônus:</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '75%',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(147, 154, 189); font-size: 23px;">Além das aulas, você também terá acesso aos seguintes bônus:</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 120px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '50px 0px 80px 0px',
              },
              mobile: {
                padding: '30px 0px 60px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#D6D9E7',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(96, 182, 67); font-size: 22px;">+</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 22px;"> Bônus 1</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 19px;">+</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 19px;"> Bônus 1</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 50px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    padding: '20px 20px 20px 20px',
                    margin: '0px 10px 40px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(147, 154, 189);">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#D6D9E7',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(96, 182, 67); font-size: 22px;">+</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 22px;"> Bônus 2</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 19px;">+</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 19px;"> Bônus 2</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 50px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    margin: '0px 10px 40px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(147, 154, 189);">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '10px 20px 10px 20px',
                  'border-radius': '10px 10px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#D6D9E7',
                  'max-width': '80%',
                  margin: '10px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(96, 182, 67); font-size: 22px;">+</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 22px;"> Bônus 3</strong></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="font-family: Montserrat, sans-serif; color: rgb(125, 206, 98); font-size: 19px;">+</strong><strong style="font-family: Montserrat, sans-serif; color: rgb(57, 57, 57); font-size: 19px;"> Bônus 3</strong></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '20px 20px 20px 20px',
                  'border-radius': '0px 0px 10px 10px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: '#EDEFF8',
                  'max-width': '80%',
                  margin: '0px 10px 0px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 18px; color: rgb(57, 57, 57);">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><span style="font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 16px; color: rgb(147, 154, 189);">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'bonus-8',
    name: 'Bonus',
    template: {
      background: '#FF3B71',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'bonus',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '80px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '72px 0px 40px 0px',
              },
              mobile: {
                padding: '40px 0px 24px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">FAZENDO PARTE DO [INSERIR NOME DO CURSO] VOCÊ RECEBERÁ </strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 40px; background-color: rgb(41, 255, 137);"> </strong><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 40px; background-color: rgb(41, 255, 137);">TOTALMENTE GRÁTIS </strong><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 40px;"> </strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">O ACESSO PARA ESSES PRESENTES EXCLUSIVOS:</strong></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '90%',
                    text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 32px;">FAZENDO PARTE DO [INSERIR NOME DO CURSO] VOCÊ RECEBERÁ </strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 32px; background-color: rgb(41, 255, 137);"> </strong><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 32px; background-color: rgb(41, 255, 137);">TOTALMENTE GRÁTIS </strong><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 32px;"> </strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 32px;">O ACESSO PARA ESSES PRESENTES EXCLUSIVOS:</strong></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 20px;">FAZENDO PARTE DO [INSERIR NOME DO CURSO] VOCÊ RECEBERÁ </strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 20px; background-color: rgb(41, 255, 137);"> </strong><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 20px; background-color: rgb(41, 255, 137);">TOTALMENTE </strong></p><p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 20px; background-color: rgb(41, 255, 137);"> GRÁTIS </strong><strong style="font-family: Oswald, sans-serif; color: rgb(20, 19, 58); font-size: 20px;"> </strong><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 20px;">O ACESSO PARA ESSES PRESENTES EXCLUSIVOS:</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 30px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                padding: '0px 0px 16px 0px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background:
                    'linear-gradient(180deg, rgba(255,59,113,1) 0%, rgba(255,85,131,1) 100%);',
                  'max-width': '75%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '85%',
                    padding: '24px 8px 24px 8px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '115px',
                      href: '',
                      'max-width': '135px',
                      margin: '0px 20px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        margin: '0px 0px 0px 0px',
                        'max-width': '100px',
                        height: '100px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5485168/bonus-1.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5485168/thumb_bonus-1.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="background-color: rgb(255, 255, 255); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 24px;"> BÔNUS 1 </strong></p><p><br></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      'max-width': '51%',
                      padding: '10px 0px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="background-color: rgb(255, 255, 255); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 20px;"> BÔNUS 1 </strong></p><p><br></p><p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background:
                    'linear-gradient(180deg, rgba(255,59,113,1) 0%, rgba(255,85,131,1) 100%);',
                  'max-width': '75%',
                  margin: '40px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '85%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '115px',
                      href: '',
                      'max-width': '135px',
                      margin: '0px 20px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                        margin: '0px 8px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5485772/bonus-2.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5485772/thumb_bonus-2.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="background-color: rgb(255, 255, 255); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 24px;"> BÔNUS 2 </strong></p><p><br></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      'max-width': '51%',
                      padding: '10px 0px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="background-color: rgb(255, 255, 255); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 20px;"> BÔNUS 2 </strong></p><p><br></p><p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
                {
                  padding: '24px 10px 24px 10px',
                  'border-radius': '16px 16px 16px 16px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'row',
                  background:
                    'linear-gradient(180deg, rgba(255,59,113,1) 0%, rgba(255,85,131,1) 100%);',
                  'max-width': '75%',
                  margin: '40px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    'max-width': '85%',
                    margin: '40px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      alt: '',
                      'border-color': '#5981e3',
                      'border-radius': '4px 4px 4px 4px',
                      'border-style': 'none',
                      'border-top-width': '2px',
                      'border-right-width': '2px',
                      'border-bottom-width': '2px',
                      'border-left-width': '2px',
                      height: '115px',
                      href: '',
                      'max-width': '135px',
                      margin: '0px 20px 0px 0px',
                      'object-fit': 'contain',
                      target: '_blank',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        height: '100px',
                        'max-width': '100px',
                        margin: '0px 8px 0px 0px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-image',
                      src: '//static-public.klickpages.com.br/uploads/media/file/5485774/bonus-3.png',
                      'thumb-src':
                        '//static-public.klickpages.com.br/uploads/media/file/5485774/thumb_bonus-3.png',
                      metadata: {},
                    },
                    {
                      text: '<p><strong style="background-color: rgb(255, 255, 255); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 24px;"> BÔNUS 3 </strong></p><p><br></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      'max-width': '51%',
                      padding: '10px 0px 10px 10px',
                      desktop: {},
                      tablet: {},
                      mobile: {
                        text: '<p><strong style="background-color: rgb(255, 255, 255); color: rgb(255, 59, 113); font-family: Oswald, sans-serif; font-size: 20px;"> BÔNUS 3 </strong></p><p><br></p><p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Breve descrição do bônus que não passe de 4 linhas.</span></p>',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                      metadata: {},
                    },
                  ],
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 40px;">NÃO PERCA ESTA OPORTUNIDADE!</strong></p>',
                  'max-width': '100%',
                  padding: '64px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    padding: '56px 10px 10px 10px',
                  },
                  mobile: {
                    'max-width': '90%',
                    padding: '54px 10px 16px 10px',
                    text: '<p style="line-height: 1.15; text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 32px;">NÃO PERCA ESTA</strong></p><p style="line-height: 1.15; text-align: center;"><strong style="font-family: Oswald, sans-serif; color: rgb(255, 255, 255); font-size: 32px;">OPORTUNIDADE!</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
              ],
              metadata: {},
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'bonus-1',
    name: 'Bonus',
    template: {
      background: 'none',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Seção 8' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '48px 0px 48px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '478px',
                  margin: '24px 0px 24px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5225451/group_103.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5225451/thumb_group_103.png',
                },
              ],
            },
            {
              padding: '24px 0px 24px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1\u003e\u003cstrong style="font-size: 48px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eHora do bônus \u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eSempre que possível, adicione um material especial, uma live exclusiva ou até mesmo um outro curso de bônus. \u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'bonus-2',
    name: 'Bonus',
    template: {
      background: '#FAFAFA',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '90px 0px 90px 0px',
              'justify-content': 'center',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '50px 0px 30px 0px',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '350px',
                  href: '',
                  'max-width': '500px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { height: '300px', 'max-width': '400px' },
                  mobile: { height: '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284266/ilustracao_bonus.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284266/thumb_ilustracao_bonus.png',
                },
              ],
            },
            {
              padding: '90px 20px 90px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '10px 20px 90px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '10px 30px 50px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch1\u003e\u003cstrong style="font-size: 50px; font-family: Raleway, sans-serif;"\u003eHora do Bônus\u003c/strong\u003e\u003c/h1\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '80%' },
                  mobile: {
                    'max-width': '100%',
                    text: '\u003ch1 style="line-height: 1;"\u003e\u003cstrong style="font-size: 50px; font-family: Raleway, sans-serif;"\u003eHora do Bônus\u003c/strong\u003e\u003c/h1\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Raleway, sans-serif;"\u003eSempre que possível, adicione um material especial, uma live exclusiva ou até mesmo um outro curso de bônus.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '100%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 7' },
    },
  },
  {
    id: 'bonus-3',
    name: 'Bonus',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Bônus' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '50px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 0px 0px' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  padding: '10px 10px 10px 10px',
                  'border-radius': '0px 0px 0px 0px',
                  'justify-content': 'center',
                  'align-items': 'center',
                  'flex-direction': 'column',
                  background: 'none',
                  'max-width': '100%',
                  margin: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { margin: '10px 10px 10px 10px' },
                  mobile: { padding: '0px 10px 0px 10px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-group',
                  children: [
                    {
                      text: '\u003ch1 style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eBônus\u003c/span\u003e\u003c/h1\u003e',
                      'max-width': '100%',
                      padding: '10px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003ch1 style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 45px;"\u003eBônus\u003c/span\u003e\u003c/h1\u003e',
                        'max-width': '80%',
                        padding: '0px 10px 0px 10px',
                      },
                      mobile: {
                        text: '\u003ch1 style="text-align: center; line-height: 1;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 40px;"\u003eBônus\u003c/span\u003e\u003c/h1\u003e',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                    {
                      text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 19px;"\u003eFazendo parte do (Nome do Curso) você recebe \u003c/span\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 19px;"\u003etotalmente grátis\u003c/strong\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 19px;"\u003e o acesso para esses presentes exclusivos.\u003c/span\u003e\u003c/p\u003e',
                      'max-width': '100%',
                      padding: '0px 10px 10px 10px',
                      desktop: {},
                      tablet: {
                        text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 16px;"\u003eFazendo parte do (Nome do Curso) você recebe \u003c/span\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 16px;"\u003etotalmente grátis\u003c/strong\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(70, 70, 70); font-size: 16px;"\u003e o acesso para esses presentes exclusivos.\u003c/span\u003e\u003c/p\u003e',
                        'max-width': '70%',
                      },
                      mobile: {
                        'max-width': '80%',
                        padding: '10px 10px 10px 10px',
                      },
                      uid: `ls-${uuid()}`,
                      component: 'ls-text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 20px 0px' },
              mobile: { padding: '00px 0px 20px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { height: '150px', 'max-width': '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5293431/numero1.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5293431/thumb_numero1.png',
                },
                {
                  text: '\u003cp style="text-align: center; line-height: 2;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 19px;"\u003eBônus 1\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; line-height: 2;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '60%' },
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 19px;"\u003eBônus 1\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 0px 60px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '20px 0px 40px 0px' },
              mobile: { padding: '10px 0px 50px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '200px',
                  href: '',
                  'max-width': '300px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { height: '150px', 'max-width': '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5293440/numero2.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5293440/thumb_numero2.png',
                },
                {
                  text: '\u003cp style="text-align: center; line-height: 2;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 19px;"\u003eBônus 2\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; line-height: 2;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '60%' },
                  mobile: {
                    text: '\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173); font-size: 19px;"\u003eBônus 2\u003c/strong\u003e\u003c/p\u003e\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="text-align: center; line-height: 1.15;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; color: rgb(39, 42, 173);"\u003eBreve descrição do bônus que não passe de 4 linhas.\u003c/span\u003e\u003c/p\u003e',
                    'max-width': '80%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'bonus-4',
    name: 'Bonus',
    template: {
      background: '#E5154A',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 20px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '20px 0px 10px 20px',
              },
              mobile: { padding: '20px 30px 10px 30px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cstrong style="font-size: 22px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eBônus extra por tempo limitado \u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-size: 22px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eBônus extra por tempo limitado \u003c/strong\u003e\u003c/p\u003e',
                  },
                  mobile: {
                    'max-width': '100%',
                    text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-size: 18px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eBônus extra por tempo limitado \u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="line-height: 1;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px; color: rgb(255, 255, 255);"\u003eBenefício X + Bônus\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '80%',
                  padding: '5px 5px 5px 5px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 18px; color: rgb(255, 255, 255);"\u003eBenefício X + Bônus\u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: {
                    text: '\u003cp style="line-height: 1; text-align: center;"\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 14px; color: rgb(255, 255, 255);"\u003eBenefício X + Bônus\u003c/span\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
            {
              padding: '20px 0px 20px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '10px 20px 20px 0px' },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
                padding: '10px 15px 20px 15px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  color: '#000000',
                  background: '#F7F9FA',
                  'border-color': 'none',
                  'border-style': 'none',
                  'border-top-width': '0px',
                  'border-right-width': '0px',
                  'border-bottom-width': '0px',
                  'border-left-width': '0px',
                  'border-radius': '0px 0px 0px 0px',
                  desktop: {},
                  'end-date': '2022-09-27T20:18:47',
                  'font-family': 'Montserrat, sans-serif',
                  'number-size': '20px',
                  'label-size': '12px',
                  gap: '10px',
                  'is-bold': true,
                  'is-italic': false,
                  'items-height': '50px',
                  'items-width': '90px',
                  margin: '0px 0px 0px 0px',
                  'time-on-page':
                    '{"days":0,"hours":0,"minutes":5,"seconds":0}',
                  timezone: '-03:00_utcMinus0300MainCities',
                  type: 'timeOnPage',
                  padding: '12px 12px 12px 12px',
                  mobile: {
                    'number-size': '20px',
                    'label-size': '10px',
                    'items-height': '52px',
                    'items-width': '80px',
                    padding: '8px 8px 8px 8px',
                    gap: '8px',
                  },
                  tablet: { gap: '16px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-timer',
                  'has-action': false,
                  'items-visibility':
                    '{"days":true,"hours":true,"minutes":true,"seconds":true,"labels":true}',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 1' },
    },
  },
];
