import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 24px 0 24px;

  .hot-form {
    margin-bottom: 16px;
  }
`;

export const Button = styled.button`
  width: 100%;
`;

export const Title = styled.label`
  font-size: 16px;
  margin-bottom: 4px;
`;

export const ErrorMessage = styled.div`
  display: block;
`;
