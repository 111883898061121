import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 450px;
  margin: 0 auto;
`;

export const LinkConfig = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 4px;
  background-color: transparent !important;
  border: none;
  margin-top: 32px;
`;

export const Icon = styled.i`
  color: red;
  font-size: 16px;
`;

export const AddButton = styled.button`
  margin-right: 36px;
`;
