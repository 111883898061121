import React, { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaCenter } from '../../../../../../hooks/useMediaCenter';
import { MediaOptions } from '../MediaOptions';
import {
  Container,
  Image,
  ImageWrapper,
  Progress,
  SelectedImage,
  StyledDraggable,
} from './styles';

export type MediaPreviewProps = HTMLAttributes<HTMLDivElement> & {
  image: UploadingMedia<Image>;
  isSelectable?: boolean;
  isSelected?: boolean;
  onChangeCurrentMedia?: (id: string) => void;
  onRemoveCurrentMedia?: (id: string) => void;
};

const MediaPreviewComponent: React.FC<MediaPreviewProps> = ({
  image,
  isSelectable,
  isSelected,
  onChangeCurrentMedia,
  onRemoveCurrentMedia,
  ...attrs
}) => {
  const memoizedStatus = useMemo(() => {
    if (image.loading) {
      return <FontAwesomeIcon icon={['fad', 'spinner-third']} spin />;
    }
    if (image.error) {
      return <FontAwesomeIcon icon={['fal', 'times-circle']} />;
    }
    if (image.uploaded) {
      return <FontAwesomeIcon icon={['fal', 'check-circle']} />;
    }
    return null;
  }, [image.loading, image.error, image.uploaded]);

  const isUploading = useCallback(
    (media: UploadingMedia) => media.loading || media.uploaded || media.error,
    []
  );

  const getImageThumbnail = useCallback((src: string) => {
    if (
      src.match(
        /static-public\.(kpages|klickpages|pages)\.(com.br|hotmart.com)/g
      ) !== null
    ) {
      const split = src.split('/');
      const thumbFileName = `thumb_${split.pop()}`;
      return `${split.join('/')}/${thumbFileName}`;
    }
    return src;
  }, []);

  const { selectedMedia } = useMediaCenter();

  const mediaKey = useMemo(
    () => `${image.id}-${image.type}`,
    [image.id, image.type]
  );

  const isSelectedMedia = useMemo(
    () => Boolean(selectedMedia[mediaKey]),

    [mediaKey, selectedMedia]
  );

  return (
    <Container
      isSelectedMedia={isSelectedMedia}
      isSelectable={isSelectable}
      {...attrs}
    >
      {isUploading(image) || isSelectable ? (
        <Image src={image.url} />
      ) : (
        <StyledDraggable
          groupName="addableElements"
          uid={`media-${image.id}`}
          template={{
            uid: image.id,
            component: 'ls-image',
            src: image.url,
            'thumb-src': getImageThumbnail(image.url),
          }}
        >
          <ImageWrapper>
            <Image src={image.url} />
          </ImageWrapper>
        </StyledDraggable>
      )}

      {!isUploading(image) && !isSelectable && (
        <MediaOptions
          media={image}
          onChangeCurrentMedia={() => onChangeCurrentMedia?.(image.id)}
          onRemoveCurrentMedia={() => onRemoveCurrentMedia?.(image.id)}
        />
      )}
      <Progress
        isLoading={image.loading}
        isUploaded={image.uploaded}
        isError={image.error}
      >
        {memoizedStatus}
      </Progress>
      <SelectedImage isSelected={isSelectable && isSelected} />
    </Container>
  );
};

export const MediaPreview = memo(MediaPreviewComponent);
