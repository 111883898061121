import '@hotmart-org-ca/cosmos/dist/modal';
import '@hotmart-org-ca/cosmos/dist/modal/modal-header';
import '@hotmart-org-ca/cosmos/dist/modal/modal-body';
import '@hotmart-org-ca/cosmos/dist/modal/modal-footer';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/custom.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/danger.css';

import { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Toast from '@services/toast';
import { BaseModal } from '@components/BaseModal';
import { useMediaCenter } from '@hooks/useMediaCenter';
import { Description, ModalTitle, CancelButton } from './styles';

type RemoveMediaModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  mediaItem?: Record<string, Media>;
  onClose: () => void;
};

const RemoveMediaModalComponent: React.FC<RemoveMediaModalProps> = ({
  isOpen,
  mediaItem,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { deleteMedia, loading, selectedMedia } = useMediaCenter();
  const mediaItemType = mediaItem
    ? Object.values(mediaItem as Record<string, Media>)[0].type
    : 'media';

  const isSelectedMedia = useMemo(
    () => Boolean(selectedMedia),
    [selectedMedia]
  );

  const isFolder = useMemo(() => {
    if (mediaItemType) {
      return mediaItemType === 'media_folder';
    }
    return false;
  }, [mediaItemType]);

  const title = useMemo(
    () => (isSelectedMedia ? 'selectedMediaTitle' : 'title'),
    [isSelectedMedia]
  );

  const description = useMemo(() => {
    if (isSelectedMedia) {
      return 'selectedMediaDescription';
    }
    return isFolder ? 'folderDescription' : 'description';
  }, [isFolder, isSelectedMedia]);

  const deleteButton = useMemo(() => {
    if (isSelectedMedia) {
      return 'deleteItems';
    }
    return isFolder ? 'deleteFolderButton' : 'deleteButton';
  }, [isFolder, isSelectedMedia]);

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDeleteMedia = useCallback(async () => {
    try {
      if (mediaItem) {
        await deleteMedia(mediaItem);
      } else if (selectedMedia) {
        await deleteMedia(selectedMedia);
      }
    } catch (error) {
      Toast.error(`toastMessages.${mediaItemType}.error.deleted`);
    } finally {
      handleCloseModal();
    }
  }, [deleteMedia, handleCloseModal, mediaItem, mediaItemType, selectedMedia]);

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      header={<ModalTitle>{t(`modals.mediaCenter.${title}`)}</ModalTitle>}
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t('modals.mediaCenter.cancelButton')}
          </CancelButton>
          <button
            onClick={handleDeleteMedia}
            className={classNames('hot-button hot-button--danger', {
              'hot-button--loading': loading,
            })}
          >
            {t(`modals.mediaCenter.${deleteButton}`)}
          </button>
        </>
      }
    >
      <Description>{t(`modals.mediaCenter.${description}`)}</Description>{' '}
    </BaseModal>
  );
};

export const RemoveMediaModal = memo(RemoveMediaModalComponent);
