import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/success.css';

import { HTMLAttributes, memo, useCallback, useMemo, useState } from 'react';
import {
  EditorEngineManager,
  FontsConfig,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateInfo } from '@store/slices/pageInfo';
import { updateConfig } from '@store/slices/pageConfig';
import { BaseModal } from '@components/BaseModal';
import Pages from '@services/pages';
import Files from '@services/files';
import { BackButton, Description, HeaderTitle } from './styles';

export type ImportModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen?: boolean;
  filesToImport: ZipContent;
  onClose?: () => void;
  onImportSuccess?: () => void;
};

const mode = 'page';

const Component: React.FC<ImportModalProps> = ({
  isOpen = false,
  filesToImport,
  onClose,
  onImportSuccess,
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isImporting, setIsImporting] = useState(false);

  const uuid = useSelector((state: State) => state.pageInfo.uuid);
  const hasFontsConfig = useSelector(
    (state: State) => state.pageInfo.hasFontsConfig
  );

  const normalizedPageConfig = useCallback(
    async (pageUuid: string, config: Partial<PageConfigState>) => {
      const [configFileContent] = Files.normalizePageConfig({
        ...config,
        draft: true,
      } as PageConfigState);

      await Files.saveConfigFile(pageUuid, configFileContent, mode);

      dispatch(updateConfig(configFileContent));

      return configFileContent;
    },
    [dispatch]
  );

  const savePage = useCallback(
    async (page: Template, fontsConfig: FontsConfig) => {
      await Files.savePageFile(uuid, page, mode);
      await Files.saveFontsConfigFile(uuid, fontsConfig, hasFontsConfig, mode);
    },
    [hasFontsConfig, uuid]
  );

  const importFiles = useCallback(async () => {
    setIsImporting(true);

    EditorEngineManager.clearState();

    try {
      const pageInfo = await Pages.getPage(uuid, mode);
      const pageFile = filesToImport.page as Template;
      const configFile = filesToImport.config;
      const fontsConfigFile = filesToImport.fontsConfig;
      const pageInfoUpdated: PageInfoState = {
        ...pageInfo,
        hasBeenPublished: pageInfo.firstPublishedAt !== null,
        hasFontsConfig: true,
        hasMetadata: true,
        mode,
      };

      dispatch(updateInfo(pageInfoUpdated));

      await normalizedPageConfig(uuid, configFile);
      await savePage(pageFile, fontsConfigFile);

      EditorEngineManager.setPage(pageFile);

      onImportSuccess?.();
    } finally {
      setIsImporting(false);
    }
  }, [
    dispatch,
    filesToImport,
    normalizedPageConfig,
    onImportSuccess,
    savePage,
    uuid,
  ]);

  const footer = useMemo(
    () => (
      <>
        <BackButton
          className="hot-button hot-button--secondary"
          onClick={onClose}
        >
          {t('modals.importPage.cancel')}
        </BackButton>
        <button
          className={classNames('hot-button hot-button--primary', {
            'hot-button--loading': isImporting,
          })}
          onClick={importFiles}
        >
          {t('modals.importPage.import')}
        </button>
      </>
    ),
    [importFiles, isImporting, onClose, t]
  );

  return (
    <BaseModal
      {...attrs}
      size="small"
      isOpen={isOpen}
      header={<HeaderTitle>{t('modals.importPage.title')}</HeaderTitle>}
      footer={footer}
      onClose={onClose}
    >
      <Description>{t('modals.importPage.description')}</Description>
    </BaseModal>
  );
};

export const ImportModal = memo(Component);
