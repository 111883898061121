enum extensionsPlanCode {
  FREEMIUM = 'freemium',
  LITE = 'lite',
  PLUS = 'plus',
  ULTRA = 'ultra',
}

enum extensionsProductType {
  NONE = 'NONE',
  FULL_PACKAGE = 'FULL_PACKAGE',
  KLICK_PAGES = 'KLICK_PAGES',
  KLICK_SEND = 'KLICK_SEND',
  WEBINAR = 'WEBINAR',
  WHATSAPP = 'WHATSAPP',
  PRODUCT_LAUNCH = 'PRODUCT_LAUNCH',
  STARTER_PACKAGE = 'STARTER_PACKAGE',
  CAPTURE = 'CAPTURE',
  FREE_PACKAGE = 'FREE_PACKAGE',
  PRO_PACKAGE = 'PRO_PACKAGE',
  ACCELERATOR_PACKAGE = 'ACCELERATOR_PACKAGE',
}

type ExtensionsTextsType = {
  [key: string]: string;
};

const extensionsPlanTexts: ExtensionsTextsType = {
  [extensionsPlanCode.FREEMIUM]: 'planInfo.product_extensions.free_package',
  [extensionsPlanCode.LITE]: 'planInfo.product_extensions.plans.lite',
  [extensionsPlanCode.PLUS]: 'planInfo.product_extensions.plans.plus',
  [extensionsPlanCode.ULTRA]: 'planInfo.product_extensions.plans.ultra',
};

const extensionsProductTexts: ExtensionsTextsType = {
  [extensionsProductType.FREE_PACKAGE]:
    'planInfo.product_extensions.products.full_package',
  [extensionsProductType.FULL_PACKAGE]:
    'planInfo.product_extensions.products.full_package',
  [extensionsProductType.KLICK_PAGES]:
    'planInfo.product_extensions.products.hotmart_pages',
  [extensionsProductType.KLICK_SEND]:
    'planInfo.product_extensions.products.hotmart_send',
  [extensionsProductType.WEBINAR]:
    'planInfo.product_extensions.products.webinar',
  [extensionsProductType.WHATSAPP]:
    'planInfo.product_extensions.products.whatsapp',
  [extensionsProductType.PRODUCT_LAUNCH]:
    'planInfo.product_extensions.products.product_launch',
  [extensionsProductType.STARTER_PACKAGE]:
    'planInfo.product_extensions.products.starter_package',
  [extensionsProductType.PRO_PACKAGE]:
    'planInfo.product_extensions.products.pro_package',
  [extensionsProductType.ACCELERATOR_PACKAGE]:
    'planInfo.product_extensions.products.accelerator_package',
};

const extensionsPlanNameByPlanCode = (planCode: string) =>
  extensionsPlanTexts[planCode];

const extensionsProductNameByProductType = (productType: string) =>
  extensionsProductTexts[productType];

export { extensionsPlanNameByPlanCode, extensionsProductNameByProductType };
