import { HTMLAttributes, useCallback, useRef, useState } from 'react';
import { LsColorPicker } from '@hotmart-org-ca/saas-pages-engine-react';
import { Block } from '@components/Block';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { ColorPickerContainer, Picker } from './styles';

export type ColorsProps = HTMLAttributes<HTMLDivElement> & {
  label?: string;
  colors: UseCaseColors[];
  width?: string;
  onColorsChange: (value: UseCaseColors[]) => void;
};

export const Colors: React.FC<ColorsProps> = ({
  label,
  colors,
  width = '',
  onColorsChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [colorsConfig, setColorsConfig] = useState<UseCaseColors[]>(colors);

  const debouncedOnValueChange = useRef(debounce(onColorsChange, 500));

  const handleColorChange = useCallback(
    (event: CustomEvent, index: number) => {
      const { value } = event.target as HTMLInputElement;
      const updatedColors = colorsConfig.map((config, configIndex) => ({
        ...config,
        color: configIndex === index ? value : config.color,
      }));

      debouncedOnValueChange.current(updatedColors);
      setColorsConfig(updatedColors);
    },
    [colorsConfig]
  );

  return (
    <Block label={label} width={width} {...attrs}>
      <ColorPickerContainer>
        {colorsConfig.map((option, index) => (
          <Picker key={option.id}>
            <label htmlFor={option.id}>{t(option.label)}</label>

            <LsColorPicker
              size="md"
              position="auto"
              name={option.id}
              uid={option.id}
              previewColor={false}
              blockPickerOnWrapper={false}
              onPreviewColorChange={(event) => handleColorChange(event, index)}
              value={option.color}
            />
          </Picker>
        ))}
      </ColorPickerContainer>
    </Block>
  );
};
