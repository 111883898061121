import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import { NameText, Container, Button } from './styles';

type EditableNameProps = HTMLAttributes<HTMLDivElement> & {
  fontSize: number;
  name: string;
  blockEdition?: boolean;
  tooltipText?: string;
  onNameChanged: (name: string) => void;
};

export const EditableName: React.FC<EditableNameProps> = ({
  fontSize,
  name,
  blockEdition,
  onNameChanged,
  tooltipText,
  ...attrs
}) => {
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const updateName = useCallback(
    async (value: string) => {
      setEditMode(false);

      if (value !== name) {
        onNameChanged(value);
      }
    },
    [name, onNameChanged]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        inputRef.current.value
      ) {
        updateName(inputRef.current.value);
      }
    },
    [updateName]
  );

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        setEditMode(false);
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        const target = event.target as HTMLInputElement;
        const { value } = target;

        if (value) {
          updateName(value);
        }
      }
    },
    [updateName]
  );

  const handleClick = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        custom_event_name: 'click_navbar_editor',
        event_details: 'Rename::Start',
      },
    });
    setEditMode(true);
  }, []);

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Container {...attrs}>
      {editMode ? (
        <input
          ref={inputRef}
          className="hot-form__input"
          defaultValue={name}
          maxLength={50}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <NameText fontSize={fontSize}>{name}</NameText>
      )}

      {!blockEdition && !editMode && (
        <hot-tooltip position="bottom" content={tooltipText}>
          <Button
            className="hot-button hot-button--tertiary"
            onClick={() => handleClick()}
          >
            <i className="far fa-pen" />
          </Button>
        </hot-tooltip>
      )}
    </Container>
  );
};
