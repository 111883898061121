import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import SavedPopups from '@services/savedPopups';

export const getUserPopups = createAsyncThunk<
  PageFragmentListItem[],
  void,
  {
    state: LocalState;
  }
>('pageFragments/getUserPopups', async () => {
  try {
    const response = await SavedPopups.get();
    return response;
  } catch (error) {
    const serializedError = error as SerializedError;
    throw serializedError;
  }
});

export const getUserPopupsBuilder = (
  builder: ActionReducerMapBuilder<PageFragmentsState>
) => {
  builder.addCase(getUserPopups.pending, (state: PageFragmentsState) => {
    state.popupLoading = true;
  });
  builder.addCase(
    getUserPopups.fulfilled,
    (state: PageFragmentsState, action) => {
      state.userPopups = action.payload;
      state.popupLoading = false;
    }
  );
  builder.addCase(getUserPopups.rejected, (state: PageFragmentsState) => {
    state.popupLoading = false;
  });
};
