import { HTMLAttributes, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaCenter } from '../../../../../../../../hooks/useMediaCenter';
import { setSelectedMedia } from '../../../../../../../../store/slices/mediaCenter';
import { CheckboxLabel, Container } from './styles';

export type SelectAllProps = HTMLAttributes<HTMLDivElement>;

export const SelectAll: React.FC<SelectAllProps> = ({ ...attrs }) => {
  const dispatch = useDispatch();
  const { media, selectedMediaLength } = useMediaCenter();

  const getSelectedAllMedia = useCallback(
    (selectedMedias: Media[]) =>
      selectedMedias.reduce(
        (accumulator, currentSelectedMedia) => ({
          ...accumulator,
          [`${currentSelectedMedia.id}-${currentSelectedMedia.type}`]:
            currentSelectedMedia,
        }),
        {}
      ),
    []
  );

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(setSelectedMedia(getSelectedAllMedia(media)));
      } else {
        dispatch(setSelectedMedia({}));
      }
    },
    [dispatch, getSelectedAllMedia, media]
  );

  return (
    <Container
      {...attrs}
      className="hot-form hot-form--custom hot-form--checkbox"
    >
      <input
        type="checkbox"
        className="hot-form__input valid"
        id="select-all"
        checked={
          Boolean(selectedMediaLength) && selectedMediaLength === media.length
        }
        disabled={!media.length}
        onChange={handleCheck}
      />
      <CheckboxLabel
        aria-valuetext="media-checkbox"
        className="hot-form__label"
        htmlFor="select-all"
      />
    </Container>
  );
};
