import styled from 'styled-components';

export const Title = styled.p`
  font-size: 28px;
  margin-bottom: 16px;
`;

export const Description = styled.p`
  margin-bottom: 16px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const NewSuggestionsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
