import '@hotmart-org-ca/cosmos/dist/button/button.css';
import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MediaModal } from './components/MediaModal';

export type BackgroundModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps & {
    eventPrefix: string;
    displayMode?: 'background' | 'image';
  };

const BackgroundModifierComponent: React.FC<BackgroundModifierProps> = ({
  uid,
  eventPrefix,
  displayMode = 'background',
  ...attrs
}) => {
  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const imageButtonClickListener = useMemo(
    () => () => {
      openModal();
    },
    [openModal]
  );

  const handleImageSelectionChange = useCallback(
    (media: Image) => {
      closeModal();
      window.dispatchEvent(
        new CustomEvent(`${eventPrefix}-${uid}-image-url-change`, {
          detail: media.url,
        })
      );
    },
    [closeModal, eventPrefix, uid]
  );

  useEffect(() => {
    window.addEventListener(
      `${eventPrefix}-${uid}-image-button-click`,
      imageButtonClickListener
    );

    return () => {
      window.removeEventListener(
        `${eventPrefix}-${uid}-image-button-click`,
        imageButtonClickListener
      );
    };
  }, [eventPrefix, imageButtonClickListener, openModal, uid]);

  return (
    <MediaModal
      {...attrs}
      isOpen={open}
      onClose={closeModal}
      onImageSelectionChange={handleImageSelectionChange}
      displayMode={displayMode}
    />
  );
};

export const BackgroundModifier = memo(BackgroundModifierComponent);
