import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid var(--gray-400);
  border-radius: 4px;
  text-align: center;
  margin-bottom: 24px;
`;

type StyledItemProps = {
  selected?: boolean;
  showLeftBorder?: boolean;
};

export const Item = styled.div<StyledItemProps>`
  width: 50%;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color ease 0.3s;

  ${({ selected, showLeftBorder }) => css`
    background-color: ${selected ? 'var(--gray-200)' : 'var(--white)'};
    ${showLeftBorder
      ? css`
          border-radius: 0 4px 4px 0;
          border-left: 1px solid var(--gray-400);
        `
      : css`
          border-radius: 4px 0 0 4px;
        `}
  `}
`;
