import { v4 as uuid } from 'uuid';

export const captureSections: PageFragmentListItem[] = [
  {
    id: 'capture-7',
    name: 'Capture',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5392635/bg-vendas_assets_template-albertoni.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Seção 2',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '30px 0px 80px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 0px 60px 70px',
              },
              mobile: {
                padding: '20px 0px 40px 30px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 42px;">DESCUBRA O MAIOR SEGREDO DAS COPIES QUE VENDEM MILHÕES</strong></p>',
                  'max-width': '90%',
                  padding: '10px 10px 10px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15;"><strong style="font-family: Montserrat, sans-serif; color: rgb(255, 255, 255); font-size: 22px;">DESCUBRA O MAIOR SEGREDO DAS COPIES QUE VENDEM MILHÕES</strong></p>',
                    'max-width': '85%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;">E o melhor: sem ter que investir milhares de reais em cursos, ou contratar um profissional caríssimo para fazer por você.</span></p><p><br></p><p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 11px;">[Insira aqui o principal argumento pelo qual a pessoa deveria se cadastrar na página, em formato de citação. Qual o principal benefício/resultado ela terá ao entrar agora?]</span></p>',
                  'max-width': '85%',
                  padding: '10px 10px 10px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '80%',
                  },
                  mobile: {
                    text: '<p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">E o melhor: sem ter que investir milhares de reais em cursos, ou contratar um profissional caríssimo para fazer por você.</span></p><p><br></p><p style="line-height: 1.15;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 9px;">[Insira aqui o principal argumento pelo qual a pessoa deveria se cadastrar na página, em formato de citação. Qual o principal benefício/resultado ela terá ao entrar agora?]</span></p>',
                    'max-width': '83%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p style="line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183);">______________________________________________________________</strong></p>',
                  'max-width': '100%',
                  padding: '18px 10px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {
                    text: '<p style="line-height: 1;"><strong style="font-family: Montserrat, sans-serif; color: rgb(59, 68, 183);">___________________________________</strong></p>',
                    padding: '10px 10px 10px 0px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  text: '<p><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;">PREENCHA SEUS DADOS ABAIXO PARA RECEBER SEU ACESSO AGORA!</strong></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">[Tranquilize seu lead sobre o cadastro. Por exemplo:]</span></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 8px;"><span class="ql-cursor">﻿﻿﻿</span>(</span><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 9px;">Nós também odiamos SPAM, fique tranquilo, não vamos encher sua caixa de e-mails com bobagens...)</span></p>',
                  'max-width': '80%',
                  padding: '30px 10px 10px 0px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                  },
                  mobile: {
                    padding: '20px 10px 10px 0px',
                    'max-width': '90%',
                    text: '<p><strong style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 15px;">PREENCHA SEUS DADOS ABAIXO PARA RECEBER SEU ACESSO AGORA!</strong></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">[Tranquilize seu lead sobre o cadastro.</span></p><p><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 14px;">Por exemplo:]</span></p><p style="line-height: 1;"><span style="background-color: transparent; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 8px;"><span class="ql-cursor">﻿﻿</span>(Nós também odiamos SPAM, fique tranquilo, não vamos encher sua caixa de e-mails com bobagens...)</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                  metadata: {},
                },
                {
                  color: '#141436',
                  background: '#6AFFB9',
                  'border-color': '#EF4E23',
                  'border-radius': '15px 15px 15px 15px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '21px',
                  'hover-background': '#363889',
                  'hover-color': '#6AFFB9',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO RECEBER MEU ACESSO',
                  margin: '30px 0px 0px 0px',
                  'max-width': '400px',
                  mobile: {
                    'font-size': '17px',
                    'max-width': '350px',
                    margin: '30px 30px 0px 0px',
                  },
                  padding: '16px 16px 16px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                  metadata: {},
                },
              ],
              metadata: {},
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'end',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'end',
              },
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              metadata: {},
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '587px',
                  href: '',
                  'max-width': '600px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '450px',
                    'max-width': '500px',
                  },
                  mobile: {
                    'max-width': '300px',
                    height: '250px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5400659/img-captura_assets_template-albertoni.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5400659/thumb_img-captura_assets_template-albertoni.png',
                  metadata: {},
                },
              ],
            },
          ],
          metadata: {},
        },
      ],
    },
  },
  {
    id: 'capture-1',
    name: 'Capture',
    template: {
      background: '#404040',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '100%',
                  'max-width': '100%',
                  margin: '0px 0px 0px 0px',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5043913/unsplash_wxm465om4j4.png',
                  'object-fit': 'fill',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                },
              ],
            },
            {
              padding: '10px 10px 10px 10px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: '#404040',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<h1 style="line-height: 1.15;"><strong style="font-family: Roboto, sans-serif; color: rgb(255, 255, 255); font-size: 42px;">O CURSO DE [TEMA DO SEU CURSO] PENSADO PARA VOCÊ!</strong></h1>',
                  'max-width': '100%',
                  padding: '8px 70px 8px 70px',
                  desktop: {},
                  tablet: {},
                  mobile: { padding: '8px 70px 8px 70px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '<p><span style="font-size: 20px; color: rgb(255, 255, 255);">É desconto que você quer? Então receba! </span></p><p><strong style="font-size: 20px; color: rgb(255, 255, 255);">X% OFF</strong><span style="font-size: 20px; color: rgb(255, 255, 255);"> a um clique de distância.</span></p><p><br></p><p><strong style="font-size: 20px; color: rgb(255, 255, 255);">Eu, se fosse você, não perderia.</strong></p>',
                  'max-width': '464px',
                  padding: '8px 10px 24px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#0094D7',
                  'border-color': '#5981e3',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Roboto, sans-serif',
                  'font-size': '24px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  label: 'Comece agora',
                  margin: '0px 0px 0px 0px',
                  'max-width': '464px',
                  mobile: {},
                  padding: '14px 16px 14px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'capture-2',
    name: 'Capture',
    template: {
      background: '#F5F5F5',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '48px 0px 48px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '484px',
                  href: '',
                  'max-width': '556px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5224556/group_76.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5224556/thumb_group_76.png',
                },
              ],
            },
            {
              padding: '0px 0px 0px 16px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch2\u003e\u003cstrong style="font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; font-size: 48px; color: rgb(28, 28, 28);"\u003eNome da isca ou evento gratuito\u003c/strong\u003e\u003c/h2\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-size: 16px; font-family: \u0026quot;Nunito Sans\u0026quot;, sans-serif; color: rgb(28, 28, 28);"\u003eGratuito por tempo limitado: \u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 0px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#707780',
                  'border-color': 'none',
                  'border-style': 'none',
                  'border-top-width': '0px',
                  'border-right-width': '0px',
                  'border-bottom-width': '0px',
                  'border-left-width': '0px',
                  'border-radius': '0px 0px 0px 0px',
                  desktop: {},
                  'end-date': '2022-09-06T19:53:13',
                  'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif',
                  'number-size': '16px',
                  'label-size': '16px',
                  gap: '16px',
                  'is-bold': false,
                  'is-italic': false,
                  'items-height': '60px',
                  'items-width': '100px',
                  margin: '16px 0px 0px 0px',
                  'time-on-page':
                    '{"days":0,"hours":0,"minutes":5,"seconds":0}',
                  timezone: '-03:00_utcMinus0300MainCities',
                  type: 'timeOnPage',
                  padding: '12px 12px 12px 12px',
                  mobile: {
                    'number-size': '40px',
                    'label-size': '12px',
                    'items-height': '52px',
                    'items-width': '80px',
                    padding: '8px 8px 8px 8px',
                    gap: '8px',
                  },
                  tablet: { gap: '16px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-timer',
                  'has-action': false,
                  'items-visibility':
                    '{"days":true,"hours":true,"minutes":true,"seconds":true,"labels":true}',
                },
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '60px',
                  href: '',
                  'max-width': '490px',
                  margin: '24px 0px 0px 0px',
                  'object-fit': 'none',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5238901/input.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5238901/thumb_input.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 2' },
    },
  },
  {
    id: 'capture-3',
    name: 'Capture',
    template: {
      background: '#FFFFFF',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '60px 0px 90px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
            padding: '40px 0px 90px 0px',
          },
          mobile: {
            'flex-direction': 'column',
            padding: '40px 20px 90px 20px',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 20px 20px 20px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: { padding: '10px 10px 10px 10px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '20px 20px 20px 20px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '350px',
                  href: '',
                  'max-width': '450px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: { 'max-width': '300px', height: '250px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5284759/foto_quem-sou-eu.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5284759/thumb_foto_quem-sou-eu.png',
                },
              ],
            },
            {
              padding: '0px 30px 0px 15px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '0px 40px 0px 40px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '0px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003ch2 style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 48px; color: rgb(0, 0, 0);"\u003eNome da isca ou evento gratuito\u003c/strong\u003e\u003c/h2\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 20px 0px',
                  desktop: {},
                  tablet: {
                    text: '\u003ch2 style="line-height: 1.15; text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 48px; color: rgb(0, 0, 0);"\u003eNome da isca ou evento gratuito\u003c/strong\u003e\u003c/h2\u003e',
                  },
                  mobile: {
                    padding: '10px 0px 14px 0px',
                    text: '\u003ch2 style="line-height: 1; text-align: center;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; font-size: 38px; color: rgb(0, 0, 0);"\u003eNome da isca ou evento gratuito\u003c/strong\u003e\u003c/h2\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-size: 20px; font-family: Raleway, sans-serif; color: rgb(28, 28, 28);"\u003eGratuito por tempo limitado: \u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 0px',
                  desktop: {},
                  tablet: {
                    text: '\u003cp style="text-align: center;"\u003e\u003cspan style="font-size: 20px; font-family: Raleway, sans-serif; color: rgb(28, 28, 28);"\u003eGratuito por tempo limitado: \u003c/span\u003e\u003c/p\u003e',
                  },
                  mobile: { 'max-width': '90%' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#000000',
                  background: '#FFC673',
                  'border-color': 'none',
                  'border-style': 'none',
                  'border-top-width': '0px',
                  'border-right-width': '0px',
                  'border-bottom-width': '0px',
                  'border-left-width': '0px',
                  'border-radius': '10px 10px 10px 10px',
                  desktop: {},
                  'end-date': '2022-09-06T19:53:13',
                  'font-family': 'Raleway, sans-serif',
                  'number-size': '26px',
                  'label-size': '14px',
                  gap: '16px',
                  'is-bold': true,
                  'is-italic': false,
                  'items-height': '60px',
                  'items-width': '100px',
                  margin: '10px 0px 10px 0px',
                  'time-on-page':
                    '{"days":0,"hours":0,"minutes":5,"seconds":0}',
                  timezone: '-03:00_utcMinus0300MainCities',
                  type: 'timeOnPage',
                  padding: '6px 12px 10px 12px',
                  mobile: {
                    'number-size': '25px',
                    'label-size': '12px',
                    'items-height': '52px',
                    'items-width': '80px',
                    padding: '8px 8px 8px 8px',
                    gap: '5px',
                  },
                  tablet: { gap: '16px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-timer',
                  'has-action': false,
                  'items-visibility':
                    '{"days":true,"hours":true,"minutes":true,"seconds":true,"labels":true}',
                },
                {
                  color: '#000000',
                  background: '#79E0BB',
                  'border-color': '#EF4E23',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'Reserve seu lugar!',
                  margin: '30px 0px 0px 0px',
                  'max-width': '250px',
                  mobile: {},
                  padding: '12px 16px 13px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Seção 2' },
    },
  },
  {
    id: 'capture-4',
    name: 'Capture',
    template: {
      background: '#000000',
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '120px 30px 100px 0px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '40px 20px 40px 20px' },
              mobile: {
                padding: '40px 20px 40px 20px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-size: 40px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eA estratégia que vai (promessa da sua isca)\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cspan style="font-family: Montserrat, sans-serif; font-size: 20px; color: rgb(255, 255, 255);"\u003eConheça o jeito mais (benefício) para (atividade que sua persona sonha)\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cstrong style="font-size: 20px; font-family: Montserrat, sans-serif; color: rgb(255, 255, 255);"\u003eEvento Online / X de Mês / às XXh\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '100%',
                  padding: '30px 10px 20px 10px',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#E5154A',
                  'border-color': '#EF4E23',
                  'border-radius': '27px 27px 27px 27px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'FAZER INSCRIÇÃO GRATUITA',
                  margin: '20px 10px 30px 10px',
                  'max-width': '350px',
                  mobile: {},
                  padding: '12px 16px 12px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
            {
              padding: '100px 0px 100px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: { padding: '40px 0px 40px 0px' },
              mobile: { padding: '20px 0px 20px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '100%',
                  href: '',
                  'max-width': '100%',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: {},
                  mobile: {},
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5315946/micha-hero.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5315946/thumb_micha-hero.png',
                },
              ],
            },
          ],
        },
      ],
      metadata: { label: 'Section 1' },
    },
  },
  {
    id: 'capture-5',
    name: 'Capture',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5303311/bg-captura_assets_template-orange-chic.jpg') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: { label: 'Captura' },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: { 'flex-direction': 'column' },
          mobile: { 'flex-direction': 'column' },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '40px 0px 0px 0px',
              'justify-content': 'end',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '30px 0px 0px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '530px',
                  href: '',
                  'max-width': '650px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'contain',
                  target: '_blank',
                  desktop: {},
                  tablet: { 'max-width': '400px', height: '330px' },
                  mobile: { 'max-width': '220px', height: '200px' },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5304614/imagem-topo-captura_assets_template-orange-chic.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5304614/thumb_imagem-topo-captura_assets_template-orange-chic.png',
                },
              ],
            },
            {
              padding: '40px 0px 0px 30px',
              'justify-content': 'center',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '40px 40px 30px 40px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: { padding: '20px 0px 45px 0px' },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 44px;"\u003eBaixe agora, \u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 44px; background-color: rgb(0, 255, 250);"\u003eé grátis\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 44px;"\u003e!\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '80%' },
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 25px;"\u003eBaixe agora, \u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 25px; background-color: rgb(0, 255, 250);"\u003eé grátis\u003c/strong\u003e\u003cstrong style="font-family: Raleway, sans-serif; color: rgb(34, 34, 34); font-size: 25px;"\u003e!\u003c/strong\u003e\u003c/p\u003e',
                    padding: '10px 10px 5px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(34, 34, 34); font-size: 18px;"\u003eSaiba como [INSERIR ISCA DIGITAL AQUI - ebook, planilha, guia] pode te ajudar a [INSERIR BENEFÍCIO DA ISCA] em apenas poucos cliques.\u003c/strong\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '0px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '80%' },
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cstrong style="font-family: Montserrat, sans-serif; color: rgb(34, 34, 34); font-size: 14px;"\u003eSaiba como [INSERIR ISCA DIGITAL AQUI - ebook, planilha, guia] pode te ajudar a [INSERIR BENEFÍCIO DA ISCA] em apenas poucos cliques.\u003c/strong\u003e\u003c/p\u003e',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 13px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eO [NOME DA ISCA DIGITAL] é um material estruturado para [INSERIR OBJETIVO DO MATERIAL]. E o melhor: \u003c/span\u003e\u003cstrong style="font-size: 13px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eé totalmente gratuito\u003c/strong\u003e\u003cspan style="font-size: 13px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003e.\u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 13px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eQuanto antes você baixar, antes começará a sua transformação no [INSERIR NICHO DE MERCADO]\u003c/span\u003e\u003c/p\u003e',
                  'max-width': '90%',
                  padding: '25px 10px 10px 10px',
                  desktop: {},
                  tablet: { 'max-width': '80%' },
                  mobile: {
                    text: '\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 12px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eO [NOME DA ISCA DIGITAL] é um material estruturado para [INSERIR OBJETIVO DO MATERIAL]. E o melhor: \u003c/span\u003e\u003cstrong style="font-size: 12px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eé totalmente gratuito\u003c/strong\u003e\u003cspan style="font-size: 12px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003e.\u003c/span\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cbr\u003e\u003c/p\u003e\u003cp style="line-height: 1.15;"\u003e\u003cspan style="font-size: 12px; color: rgb(255, 255, 255); font-family: Montserrat, sans-serif;"\u003eQuanto antes você baixar, antes começará a sua transformação no [INSERIR NICHO DE MERCADO]\u003c/span\u003e\u003c/p\u003e',
                    padding: '15px 10px 10px 10px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#222222',
                  background: '#00FFFA',
                  'border-color': '#FFFFFF',
                  'border-radius': '10px 100px 100px 100px',
                  'border-style': 'none',
                  'border-top-width': '4px',
                  'border-right-width': '4px',
                  'border-bottom-width': '4px',
                  'border-left-width': '4px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Raleway, sans-serif',
                  'font-size': '19px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '\u003csvg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg"\u003e\n\u003cpath d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" /\u003e\n\u003c/svg\u003e',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'BAIXAR AGORA',
                  margin: '30px 0px 30px 10px',
                  'max-width': '400px',
                  mobile: {
                    'max-width': '300px',
                    margin: '20px 0px 0px 10px',
                    'font-size': '17px',
                    padding: '13px 10px 13px 10px',
                  },
                  padding: '15px 16px 15px 15px',
                  tablet: { 'max-width': '550px' },
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 'capture-6',
    name: 'Capture',
    template: {
      background:
        "url('//static-public.klickpages.com.br/uploads/media/file/5365203/bg-captura_assets_template-navy-blue.png') center/cover",
      tag: 'section',
      'is-fixed': false,
      desktop: {},
      tablet: {},
      mobile: {},
      uid: `ls-${uuid()}`,
      component: 'ls-section',
      metadata: {
        label: 'Vitrine',
      },
      children: [
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'start',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {},
              mobile: {},
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Open Sans&quot;, sans-serif;">O [NOME DA ISCA DIGITAL] é um material pensado para [INSERIR OBJETIVO DO MATERIAL].</strong></p><p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-size: 18px; font-family: &quot;Open Sans&quot;, sans-serif;">E deixa eu te contar uma coisa? O [MATERIAL - ex: ebook, guia] </strong><strong style="color: rgb(255, 89, 89); font-size: 18px; font-family: &quot;Open Sans&quot;, sans-serif;">é totalmente gratuito!</strong></p>',
                  'max-width': '100%',
                  padding: '10px 10px 20px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                    text: '<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-size: 16px; font-family: &quot;Open Sans&quot;, sans-serif;">O [NOME DA ISCA DIGITAL] é um material pensado para [INSERIR OBJETIVO DO MATERIAL]. E deixa eu te contar uma coisa? O [MATERIAL - ex: ebook, guia] </strong><strong style="color: rgb(255, 89, 89); font-size: 16px; font-family: &quot;Open Sans&quot;, sans-serif;">é totalmente gratuito!</strong></p>',
                  },
                  mobile: {
                    'max-width': '90%',
                    text: '<p style="text-align: center; line-height: 1.15;"><strong style="color: rgb(255, 255, 255); font-size: 13px; font-family: &quot;Open Sans&quot;, sans-serif;">O [NOME DA ISCA DIGITAL] é um material pensado para [INSERIR OBJETIVO DO MATERIAL]. E deixa eu te contar uma coisa? O [MATERIAL - ex: ebook, guia] </strong><strong style="color: rgb(255, 89, 89); font-size: 13px; font-family: &quot;Open Sans&quot;, sans-serif;">é totalmente gratuito!</strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '<p style="text-align: center; line-height: 2;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 15px;">Quanto antes você baixar, mais cedo a começará a sua transformação nas pessoas, e também no [INSERIR NICHO DE MERCADO].</span></p>',
                  'max-width': '100%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '70%',
                    text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 15px;">Quanto antes você baixar, mais cedo a começará a sua transformação nas pessoas, e também no [INSERIR NICHO DE MERCADO].</span></p>',
                  },
                  mobile: {
                    text: '<p style="text-align: center; line-height: 1.15;"><span style="color: rgb(255, 255, 255); font-family: &quot;Nunito Sans&quot;, sans-serif; font-size: 13px;">Quanto antes você baixar, mais cedo a começará a sua transformação nas pessoas, e também no [INSERIR NICHO DE MERCADO].</span></p>',
                    'max-width': '90%',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
              ],
            },
          ],
        },
        {
          background: 'none',
          padding: '0px 0px 0px 0px',
          'flex-direction': 'row',
          desktop: {},
          tablet: {
            'flex-direction': 'column',
          },
          mobile: {
            'flex-direction': 'column',
          },
          uid: `ls-${uuid()}`,
          component: 'ls-row',
          children: [
            {
              padding: '20px 0px 150px 0px',
              'justify-content': 'start',
              'align-items': 'start',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                padding: '20px 0px 50px 0px',
                'align-items': 'center',
                'justify-content': 'center',
              },
              mobile: {
                'align-items': 'start',
                'justify-content': 'center',
                padding: '10px 0px 40px 20px',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  text: '<p><strong style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 70px;">Baixe agora, </strong><strong style="color: rgb(255, 89, 89); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 70px;">é grátis!<span class="ql-cursor">﻿﻿﻿</span></strong></p>',
                  'max-width': '80%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    'max-width': '55%',
                    text: '<p style="text-align: center;"><strong style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 60px;">Baixe agora, </strong><strong style="color: rgb(255, 89, 89); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 60px;">é grátis!<span class="ql-cursor">﻿﻿﻿</span></strong></p>',
                  },
                  mobile: {
                    'max-width': '80%',
                    text: '<p><strong style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 42px;">Baixe agora, </strong><strong style="color: rgb(255, 89, 89); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 42px;">é grátis!<span class="ql-cursor">﻿﻿﻿﻿</span></strong></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  text: '<p><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 17px;">Saiba como [INSERIR ISCA DIGITAL AQUI - ebook, planilha, guia] pode TRANSFORMAR o seu negócio em apenas poucos cliques.</span></p>',
                  'max-width': '90%',
                  padding: '10px 10px 10px 10px',
                  desktop: {},
                  tablet: {
                    text: '<p style="text-align: center;"><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 17px;">Saiba como [INSERIR ISCA DIGITAL AQUI - ebook, planilha, guia] pode TRANSFORMAR o seu negócio em apenas poucos cliques.</span></p>',
                    'max-width': '70%',
                  },
                  mobile: {
                    padding: '10px 10px 10px 10px',
                    'max-width': '80%',
                    text: '<p><span style="color: rgb(255, 255, 255); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 16px;">Saiba como [INSERIR ISCA DIGITAL AQUI - ebook, planilha, guia] pode TRANSFORMAR o seu negócio em apenas poucos cliques.</span></p>',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-text',
                },
                {
                  color: '#FFFFFF',
                  background: '#FF5959',
                  'border-color': '#EF4E23',
                  'border-radius': '10px 10px 10px 10px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  'button-class': '',
                  desktop: {},
                  'font-family': 'Open Sans, sans-serif',
                  'font-size': '18px',
                  'hover-background': 'none',
                  'hover-color': 'none',
                  'has-icon': false,
                  href: '',
                  'icon-position': 'right',
                  'icon-svg':
                    '<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.26562 1.02344L8.48438 1.76562C8.28906 1.96094 8.28906 2.27344 8.48438 2.42969L14.5391 8.48438H1.21875C0.945312 8.48438 0.75 8.71875 0.75 8.95312V10.0469C0.75 10.3203 0.945312 10.5156 1.21875 10.5156H14.5391L8.48438 16.6094C8.28906 16.7656 8.28906 17.0781 8.48438 17.2734L9.26562 18.0156C9.42188 18.2109 9.73438 18.2109 9.92969 18.0156L18.0938 9.85156C18.2891 9.65625 18.2891 9.38281 18.0938 9.1875L9.92969 1.02344C9.73438 0.828125 9.42188 0.828125 9.26562 1.02344Z" />\n</svg>',
                  'is-bold': true,
                  'is-italic': false,
                  'is-underline': false,
                  'is-strike': false,
                  label: 'QUERO RECEBER GRATUITAMENTE',
                  margin: '30px 0px 0px 0px',
                  'max-width': '350px',
                  mobile: {
                    'max-width': '85%',
                    'font-size': '15px',
                    margin: '20px 0px 0px 10px',
                    padding: '15px 16px 15px 16px',
                  },
                  padding: '18px 16px 18px 16px',
                  tablet: {},
                  target: '_blank',
                  uid: `ls-${uuid()}`,
                  component: 'ls-button',
                },
              ],
            },
            {
              padding: '0px 0px 0px 0px',
              'justify-content': 'center',
              'align-items': 'center',
              'flex-direction': 'column',
              size: 12,
              background: 'none',
              desktop: {},
              tablet: {
                'align-items': 'center',
                'justify-content': 'start',
              },
              mobile: {
                'align-items': 'center',
                'justify-content': 'center',
              },
              uid: `ls-${uuid()}`,
              component: 'ls-column',
              children: [
                {
                  alt: '',
                  'border-color': '#5981e3',
                  'border-radius': '4px 4px 4px 4px',
                  'border-style': 'none',
                  'border-top-width': '2px',
                  'border-right-width': '2px',
                  'border-bottom-width': '2px',
                  'border-left-width': '2px',
                  height: '680px',
                  href: '',
                  'max-width': '500px',
                  margin: '0px 0px 0px 0px',
                  'object-fit': 'cover',
                  target: '_blank',
                  desktop: {},
                  tablet: {
                    height: '445px',
                    'max-width': '500px',
                    'object-fit': 'cover',
                  },
                  mobile: {
                    height: '350px',
                    'max-width': '500px',
                  },
                  uid: `ls-${uuid()}`,
                  component: 'ls-image',
                  src: '//static-public.klickpages.com.br/uploads/media/file/5328863/img-captura_assets_template-navy-blue.png',
                  'thumb-src':
                    '//static-public.klickpages.com.br/uploads/media/file/5328863/thumb_img-captura_assets_template-navy-blue.png',
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
