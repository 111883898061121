import styled, { createGlobalStyle } from 'styled-components';

export const ModalFooterStyle = createGlobalStyle`
  .modal-warning-footer{
    justify-content: center;
  }
  .modal-warning-header {
    padding-bottom: 0;
  }
`;

export const Container = styled.div``;

export const Image = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  font-size: 24px;
  text-align: center;
  color: var(--gray-900);
  font-weight: 300;
  line-height: 28.8px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: var(--gray-500);
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  margin-top: 12px;
`;

export const ActionsContainer = styled.div`
  width: 300px;
  margin-top: 8px;
`;

export const NewPlanButton = styled.button`
  margin-bottom: 8px;
  width: 100%;
`;

export const CloseButton = styled.button`
  width: 100%;
`;
