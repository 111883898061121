import { TextInput } from '@components/TextInput';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export const Switch = styled.div`
  margin-bottom: 24px;
`;

export const InputGroup = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  hot-tooltip {
    position: absolute;
    right: 0;
    top: 0px;
  }
`;

export const SearchInput = styled.input`
  padding-right: 38px;
`;

export const InputIcon = styled.i`
  position: absolute;
  right: 16px;
  top: 12px;
  z-index: 10;
  color: var(--gray-500);
`;

export const Label = styled.div`
  text-align: left;
  font-size: 12px;
  color: var(--gray-500);
  width: 100%;
  margin-bottom: 8px;
`;

export const Preview = styled.div`
  height: 239px;
  width: 100%;
  background-color: #000;

  iframe {
    #player {
      height: 239px !important;
    }
  }
`;

export const InvalidSrc = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;
