import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/index';
import { pageFragmentsThunks } from '@store/thunks';

export const useSavedSections = () => {
  const dispatch = useAppDispatch();

  const userSections = useSelector(
    (state: State) => state.pageFragments.userSections
  );

  const loading = useSelector(
    (state: State) => state.pageFragments.sectionLoading
  );

  const createUserSection = useCallback(
    (section: Omit<PageFragmentListItem, 'id'>) =>
      dispatch(pageFragmentsThunks.createUserSection(section)).unwrap(),
    [dispatch]
  );

  const getUserSections = useCallback(
    () => dispatch(pageFragmentsThunks.getUserSections()).unwrap(),
    [dispatch]
  );

  const updateUserSection = useCallback(
    (section: Omit<PageFragmentListItem, 'template'>) =>
      dispatch(pageFragmentsThunks.updateUserSection(section)).unwrap(),
    [dispatch]
  );

  const deleteUserSection = useCallback(
    (section: string) =>
      dispatch(pageFragmentsThunks.deleteUserSection(section)).unwrap(),
    [dispatch]
  );

  return {
    loading,
    userSections,
    createUserSection,
    deleteUserSection,
    getUserSections,
    updateUserSection,
  };
};
