import i18next from 'i18next';
import {
  EditorEngineManager,
  Template,
} from '@hotmart-org-ca/saas-pages-engine';
import { BaseUseCase } from '@pages/PageWizard/common/baseUseCase';
import { getFontsConfigByType, getTemplateByType } from './templates';

export class SalesUseCase extends BaseUseCase {
  private customStructureMiddlewares: StringMap<UseCaseStructureMiddleware> = {
    hasVideo: SalesUseCase.hasVideoMiddleware,
    offerInstallments: (config) => this.offerInstallmentsMiddleware(config),
  };

  static imagesByTemplate: Record<SalesPageTemplateType, string> = {
    default:
      '//static-public.pages.hotmart.com/uploads/media/file/6273384/placeholder-2_usecase-pagina-de-vendas.png',
    classic:
      '//static-public.pages.hotmart.com/uploads/media/file/7176939/group_102881.png',
    'dark-primary':
      '//static-public.pages.hotmart.com/uploads/media/file/7176922/group_10288.png',
    'dark-secondary':
      '//static-public.pages.hotmart.com/uploads/media/file/7176922/group_10288.png',
  };

  constructor(properties: SalesPageProperties) {
    super();

    this.updateStructureMiddlewares(this.customStructureMiddlewares);
    this.startPage(
      SalesUseCase.getTemplate(properties.template),
      SalesUseCase.getPropertiesMap(properties)
    );
  }

  replaceTemplate(properties: SalesPageProperties) {
    this.startPage(
      SalesUseCase.getTemplate(properties.template),
      SalesUseCase.getPropertiesMap(properties)
    );
  }

  static getDefaultProperties(): SalesPageProperties {
    const image = SalesUseCase.imagesByTemplate['dark-secondary'];

    return {
      pageName: i18next.t('pageWizard.salesPage.pageName'),
      author: {
        imgSrc: image,
        name: '',
        description: '',
      },
      product: {
        imgSrc: image,
        hasVideo: true,
        videoSrc: '',
        offerPrice: '',
        offerLink: '',
        offerMaxInstallments: 1,
        description: '',
        title: '',
      },
      updatedProduct: {
        title: '',
        subtitle: '',
        description: '',
        purchaseButton: i18next.t('pageWizard.salesPage.template.CTA'),
      },
      updatedAuthor: {
        description: '',
      },
      colors: {
        primaryColor: '#05234A',
        secondaryColor: '#DE5A07',
        isCustomColors: false,
        selectedTheme: '1',
      },
      template: 'dark-secondary',
    };
  }

  static getTemplate(type?: SalesPageTemplateType): Template {
    return getTemplateByType(type);
  }

  static getFontsConfig(type?: SalesPageTemplateType) {
    return getFontsConfigByType(type);
  }

  private offerInstallmentsMiddleware({
    propName,
    value: text,
    element,
  }: UseCaseStructureMiddlewareProps<{ parent: string }>) {
    const maxInstallments = (text || '1').toString();
    const page = EditorEngineManager.getFinalPage();

    if (!page) {
      return;
    }

    const parent = EditorEngineManager.getElement(element.uid, page);

    if (!parent) {
      return;
    }

    const installmentsElement = parent.children?.find(
      (child) => child.metadata?.installments
    );

    if (maxInstallments === '1') {
      if (installmentsElement) {
        EditorEngineManager.removeElement(installmentsElement.uid);
      }
      return;
    }

    const value = i18next.t(
      'pageWizard.salesPage.template.offer.installments',
      {
        maxInstallments,
      }
    );

    if (installmentsElement) {
      const payload = this.middlewares.text({
        propName,
        value,
        element: installmentsElement,
      });

      EditorEngineManager.updateElement(
        installmentsElement.uid,
        payload,
        false
      );
    } else {
      EditorEngineManager.addElement(
        {
          text: `<p style='text-align: center;'><span style='color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 24px;'>${value}</span></p>`,
          padding: '10px 0px 10px 0px',
          desktop: {},
          tablet: {
            text: `<p style='text-align: center;'><span style='color: rgb(255, 255, 255); font-family: Montserrat, sans-serif; font-size: 20px;'>${value}</span></p>`,
          },
          mobile: {},
          uid: 'ls-rytkYvfQCAA46LSA1LuXNd',
          component: 'ls-text',
          metadata: {
            installments: true,
          },
        },
        parent.uid,
        2
      );
    }
  }

  private static hasVideoMiddleware({
    value: hasVideo,
    element,
    props,
  }: UseCaseStructureMiddlewareProps<{
    videoSrc: string;
    template: SalesPageTemplateType;
  }>) {
    const page = EditorEngineManager.getFinalPage();

    if (!page) {
      return;
    }

    const columnElement = EditorEngineManager.getElement(element.uid, page);

    if (!columnElement) {
      return;
    }

    const videoElement = columnElement.children?.find(
      (child) => child.metadata?.salesVideo
    );

    const template = props?.template || 'default';

    if (hasVideo && !videoElement) {
      const videoTemplate = SalesUseCase.videoByTemplateType[template](
        props?.videoSrc || ''
      );
      const position = columnElement.children?.length || 0;

      EditorEngineManager.addElement(videoTemplate, element.uid, position);
      return;
    }

    if (!hasVideo && videoElement) {
      EditorEngineManager.removeElement(videoElement.uid);
    }
  }

  private static getPropertiesMap(properties: SalesPageProperties): StringMap {
    return {
      authorImage: properties.author.imgSrc,
      authorName: properties.author.name,
      authorDescription: properties.updatedAuthor.description,
      productImage: properties.product.imgSrc,
      productTitle: properties.updatedProduct.title,
      productSubtitle: properties.updatedProduct.subtitle,
      productDescription: properties.updatedProduct.description,
      purchaseButton: properties.updatedProduct.purchaseButton,
      hasVideo: properties.product.hasVideo,
      videoSrc: properties.product.videoSrc,
      offerPrice: properties.product.offerPrice,
      offerLink: properties.product.offerLink,
      offerInstallments: properties.product.offerMaxInstallments?.toString(),
      primaryColor: properties.colors.primaryColor,
      secondaryColor: properties.colors.secondaryColor,
    };
  }

  private static videoByTemplateType: StringMap<(src: string) => Template> = {
    default: (src: string) => ({
      'border-color': '#292C2F',
      'border-radius': '0px 0px 0px 0px',
      'border-style': 'none',
      'border-top-width': '6px',
      'border-right-width': '6px',
      'border-bottom-width': '6px',
      'border-left-width': '6px',
      height: '281px',
      margin: '0px 0px 0px 0px',
      'max-width': '500px',
      src,
      'thumb-url': '',
      desktop: {},
      tablet: {
        'border-style': 'none',
      },
      mobile: {
        'max-width': '300px',
        height: '169px',
      },
      uid: 'ls-c1NVuyvqZEf5fmMwBXPtDV',
      component: 'ls-video',
      metadata: {
        salesVideo: true,
        useCase: {
          videoSrc: {
            propName: 'src',
            middleware: 'string',
          },
        },
      },
    }),
    classic: (src: string) => ({
      'border-color': '#99C1DE',
      'border-radius': '0px 0px 0px 0px',
      'border-style': 'solid',
      'border-top-width': '3px',
      'border-right-width': '3px',
      'border-bottom-width': '3px',
      'border-left-width': '3px',
      height: '366px',
      margin: '64px 0px 0px 0px',
      'max-width': '650px',
      src,
      'thumb-url': '',
      desktop: {},
      tablet: {
        'border-style': 'solid',
      },
      mobile: {
        'max-width': '300px',
        height: '169px',
        'border-style': 'solid',
        'border-top-width': '2px',
        'border-right-width': '2px',
        'border-bottom-width': '2px',
        'border-left-width': '2px',
        margin: '48px 0px 0px 0px',
        'border-radius': '0px 0px 0px 0px',
      },
      uid: 'ls-c1NVuyvqZEf5fmMwBXPtDV',
      component: 'ls-video',
      metadata: {
        salesVideo: true,
        useCase: {
          videoSrc: {
            propName: 'src',
            middleware: 'string',
          },
        },
      },
    }),
    'dark-primary': (src: string) => ({
      'border-color': '#00000000',
      'border-radius': '0px 0px 0px 0px',
      'border-style': 'solid',
      'border-top-width': '4px',
      'border-right-width': '4px',
      'border-bottom-width': '4px',
      'border-left-width': '4px',
      height: '309px',
      margin: '0px 0px 0px 0px',
      'max-width': '550px',
      src,
      'thumb-url': '',
      desktop: {},
      tablet: {
        'border-style': 'solid',
      },
      mobile: {
        'max-width': '300px',
        height: '169px',
        'border-style': 'solid',
        'border-top-width': '7px',
        'border-right-width': '7px',
        'border-bottom-width': '7px',
        'border-left-width': '7px',
        margin: '0px 0px 0px 0px',
        'border-radius': '0px 0px 0px 0px',
      },
      uid: 'ls-c1NVuyvqZEf5fmMwBXPtDV',
      component: 'ls-video',
      metadata: {
        salesVideo: true,
        useCase: {
          videoSrc: {
            propName: 'src',
            middleware: 'string',
          },
        },
      },
    }),
    'dark-secondary': (src: string) => ({
      'border-color': '#6AAFFF',
      'border-radius': '0px 0px 0px 0px',
      'border-style': 'none',
      'border-top-width': '5px',
      'border-right-width': '5px',
      'border-bottom-width': '5px',
      'border-left-width': '5px',
      height: '326px',
      margin: '0px 0px 0px 0px',
      'max-width': '580px',
      src,
      'thumb-url': '',
      desktop: {},
      tablet: {
        'border-style': 'solid',
      },
      mobile: {
        'max-width': '300px',
        height: '169px',
        'border-style': 'solid',
        'border-top-width': '3px',
        'border-right-width': '3px',
        'border-bottom-width': '3px',
        'border-left-width': '3px',
      },
      uid: 'ls-c1NVuyvqZEf5fmMwBXPtDV',
      component: 'ls-video',
      metadata: {
        salesVideo: true,
        useCase: {
          videoSrc: {
            propName: 'src',
            middleware: 'string',
          },
        },
      },
    }),
  };
}
