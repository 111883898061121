import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  SerializedError,
} from '@reduxjs/toolkit';
import MediaCenter from '@services/mediaCenter';

export const openRootFolder = createAsyncThunk<Media[]>(
  'mediaCenter/openRootFolder',
  async () => {
    try {
      const response = await MediaCenter.getRootMedia();
      return response;
    } catch (error) {
      // todo - create error handling process
      const serializedError = error as SerializedError;
      throw serializedError;
    }
  }
);

export const openRootFolderBuilder = (
  builder: ActionReducerMapBuilder<MediaCenterState>
) => {
  builder.addCase(openRootFolder.pending, (state: MediaCenterState) => {
    state.breadcrumbsRedo = [];
    state.breadcrumbs = [];
    state.loading = true;
  });
  builder.addCase(
    openRootFolder.fulfilled,
    (state: MediaCenterState, action) => {
      state.media = action.payload;
      state.loading = false;
    }
  );
  builder.addCase(openRootFolder.rejected, (state: MediaCenterState) => {
    state.loading = false;
  });
};
