import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Popup: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg%22%3E"
    {...attrs}
  >
    <path
      d="M29 0.0600586C30.625 0.0600586 32 1.43506 32 3.06006V23.0601C32 24.7476 30.625 26.0601 29 26.0601H26V29.0601C26 30.7476 24.625 32.0601 23 32.0601H3C1.3125 32.0601 0 30.7476 0 29.0601V9.06006C0 7.43506 1.3125 6.06006 3 6.06006H6V3.06006C6 1.43506 7.3125 0.0600586 9 0.0600586H29ZM2 9.06006V14.0601H24V9.06006C24 8.56006 23.5 8.06006 23 8.06006H3C2.4375 8.06006 2 8.56006 2 9.06006ZM24 29.0601V16.0601H2V29.0601C2 29.6226 2.4375 30.0601 3 30.0601H23C23.5 30.0601 24 29.6226 24 29.0601ZM30 23.0601V3.06006C30 2.56006 29.5 2.06006 29 2.06006H9C8.4375 2.06006 8 2.56006 8 3.06006V6.06006H23C24.625 6.06006 26 7.43506 26 9.06006V24.0601H29C29.5 24.0601 30 23.6226 30 23.0601Z"
      fill={color}
    />
  </StyledSVG>
);
