import { HTMLAttributes, memo, useCallback, useEffect, useRef } from 'react';
import { Template } from '@hotmart-org-ca/saas-pages-engine';
import { useUseCases } from '@hooks/useUseCases';
import { useEngine } from '@hooks/useEngine';
import { useDetectBrowser } from '@hooks/useDetectBrowser';
import { persistentQuerySelector } from '@common/utils';
import { Container, LsPage } from './styles';

export type PreviewProps = HTMLAttributes<HTMLDivElement> & {
  template: Template;
  previewDevice?: UseCasePreviewDevice;
  scrollPreviewTo?: string;
  shouldSetPage?: boolean;
  getTemplateWithProps: (template: Template, props: any) => Template;
};

type ContainerRefProps = HTMLDivElement & {
  previewDevice: string;
  isFirefox: boolean;
};

const PreviewComponent: React.FC<PreviewProps> = ({
  template,
  previewDevice = 'mobile',
  scrollPreviewTo,
  shouldSetPage = false,
  getTemplateWithProps,
  ...attrs
}) => {
  const { isFirefox } = useDetectBrowser();
  const { properties } = useUseCases();
  const { clearState, setPage, setPreview } = useEngine();
  const containerRef = useRef<ContainerRefProps | null>(null);

  const scrollPreview = useCallback(async () => {
    const sectionToScroll = await persistentQuerySelector(
      `[id$="${scrollPreviewTo}"]`
    );
    if (containerRef.current && sectionToScroll) {
      containerRef.current.scrollTo({
        top: (sectionToScroll as HTMLElement).offsetTop,
        behavior: 'smooth',
      });
    }
  }, [scrollPreviewTo]);

  useEffect(() => {
    setPreview(previewDevice);
  }, [previewDevice, setPreview]);

  useEffect(() => {
    if (shouldSetPage) {
      clearState();

      if (properties) {
        setPage(getTemplateWithProps(template, properties));
      }
    }
  }, [
    clearState,
    getTemplateWithProps,
    properties,
    setPage,
    shouldSetPage,
    template,
  ]);

  useEffect(() => {
    scrollPreview();
  }, [scrollPreview]);

  return (
    <Container
      previewDevice={previewDevice}
      isFirefox={isFirefox}
      ref={containerRef}
      {...attrs}
    >
      <LsPage editable={false} />
    </Container>
  );
};

export const Preview = memo(PreviewComponent);
