import { SectionsTemplateSubmenu } from './components/SectionsTemplateSubmenu';
import { AddSubmenu } from './components/AddSubmenu';
import { AdjustmentsSubmenu } from './components/AdjustmentsSubmenu';
import { FacebookSubmenu } from './components/FacebookSubmenu';
import { FormsSubmenu } from './components/FormsSubmenu';
import { TimerSubmenu } from './components/TimerSubmenu';
import { MediaCenterSubmenu } from './components/MediaCenterSubmenu';
import { PageStructureSubmenu } from './components/PageStructureSubmenu';
import { SectionsListSubmenu } from './components/SectionsListSubmenu';
import { SettingsSubmenu } from './components/SettingsSubmenu';
import { UrlParamsSubmenu } from './components/UrlParamsSubmenu';
import { UrlParamsFormSubmenu } from './components/UrlParamsFormSubmenu';
import { UrlSubmenu } from './components/UrlSubmenu';
import { SeoSubmenu } from './components/SeoSubmenu';
import { ScriptsSubmenu } from './components/ScriptsSubmenu';
import { ScriptFormSubmenu } from './components/ScriptFormSubmenu';
import { ImportExportSubmenu } from './components/ImportExportSubmenu';
import { SupportChatSubmenu } from './components/SupportChatSubmenu';
import { SavedSectionsSubmenu } from './components/SavedSectionsSubmenu';
import { TemplateThumbSubmenu } from './components/TemplateThumbSubmenu';
import { TemplateCategorizationSubmenu } from './components/TemplateCategorizationSubmenu';
import { ModelsSubmenu } from './components/ModelsSubmenu';
import { PopupsSubmenu } from './components/PopupsSubmenu';
import { PopupsListSubmenu } from './components/PopupsListSubmenu';
import { SavedPopupsSubmenu } from './components/SavedPopupsSubmenu';

export const submenus: Record<string, JSX.Element> = {
  'sections-template': <SectionsTemplateSubmenu />,
  'sections-list': <SectionsListSubmenu />,
  'saved-sections': <SavedSectionsSubmenu />,
  add: <AddSubmenu />,
  adjustments: <AdjustmentsSubmenu />,
  'media-center': <MediaCenterSubmenu />,
  facebook: <FacebookSubmenu />,
  settings: <SettingsSubmenu />,
  scripts: <ScriptsSubmenu />,
  'scripts-form': <ScriptFormSubmenu />,
  'url-params': <UrlParamsSubmenu />,
  'url-params-form': <UrlParamsFormSubmenu />,
  'support-chat': <SupportChatSubmenu />,
  url: <UrlSubmenu />,
  seo: <SeoSubmenu />,
  forms: <FormsSubmenu />,
  timer: <TimerSubmenu />,
  'import-export': <ImportExportSubmenu />,
  'template-thumb': <TemplateThumbSubmenu />,
  'template-categorization': <TemplateCategorizationSubmenu />,
  'page-structure': <PageStructureSubmenu />,
  'models-list': <ModelsSubmenu />,
  'popups-submenu': <PopupsSubmenu />,
  'popups-templates': <PopupsListSubmenu />,
  'saved-popups': <SavedPopupsSubmenu />,
};
