import {
  HtmlHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { CosmosButton } from '@components/CosmosButton';
import { Block } from '@components/Block';
import { AiButtonWrapper, StyledTextInput, Inputs } from './styles';

type TextInputsValues = {
  name: string;
  description: string;
};

type InputTypes = keyof TextInputsValues;

type TextInputsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  description: string;
  name: string;
  onAiButtonClicked: () => void;
  onTextChanged: (value: string, inputType: InputTypes) => void;
};

const TextInputsComponent: React.FC<TextInputsProps> = ({
  description,
  name,
  onAiButtonClicked,
  onTextChanged,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [currentTexts, setCurrentTexts] = useState<TextInputsValues>({
    name: '',
    description: '',
  });

  const debouncedOnValueChange = useRef(debounce(onTextChanged, 500));

  const handleChange = useCallback(
    (e: React.ChangeEvent, inputType: InputTypes) => {
      const { value } = e.target as HTMLInputElement;
      setCurrentTexts({
        ...currentTexts,
        [inputType]: value,
      });
      debouncedOnValueChange.current(value, inputType);
    },
    [currentTexts]
  );

  useEffect(() => setCurrentTexts({ name, description }), [description, name]);

  return (
    <Block
      label={t('pageWizard.salesPage.paidWithProduct.presentAuthor.textTitle')}
      showSeparator
      {...attrs}
    >
      <Inputs>
        {Object.keys(currentTexts).map((inputType) => (
          <StyledTextInput
            key={`author-${inputType}-text`}
            id={`author-${inputType}-text`}
            name={inputType}
            removeEmoji
            placeholder={t(
              `pageWizard.salesPage.paidWithProduct.presentAuthor.${inputType}Placeholder`
            )}
            size="md"
            rows={6}
            value={currentTexts[inputType as InputTypes]}
            onChange={(e) => handleChange(e, inputType as InputTypes)}
            label={t(
              `pageWizard.salesPage.paidWithProduct.presentAuthor.${inputType}Label`
            )}
            inputType={inputType === 'description' ? 'textarea' : 'input'}
          />
        ))}
        <AiButtonWrapper>
          <CosmosButton
            label={t('pageWizard.salesPage.ai.aiButtonLabel')}
            iconClass="fal fa-bolt"
            iconSide="right"
            type="secondary"
            onButtonClicked={onAiButtonClicked}
            size="md"
          />
        </AiButtonWrapper>
      </Inputs>
    </Block>
  );
};

export const TextInputs = memo(TextInputsComponent);
