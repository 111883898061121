import {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar } from '@components/Sidebar';
import { useUseCases } from '@hooks/useUseCases';
import { CancelAiModal } from '@pages/PageWizard/components/CancelAiModal';
import Toast from '@services/toast';
import { AiSideBarHeader } from '@pages/PageWizard/components/AiSidebarHeader';
import { AiSidebarFooter } from '@pages/PageWizard/components/AiSidebarFooter';
import { AiSidebarBody } from './components/AiSidebarBody';

type AiSidebarProps = HTMLAttributes<HTMLElement> & {
  isOpen: boolean;
  product: ProductProps;
  onClosed: () => void;
  onUseSuggestions: (
    suggestions: Omit<UpdatedProductProps, 'description'>
  ) => void;
};

const emptySelection: Omit<UpdatedProductProps, 'description'> = {
  title: '',
  subtitle: '',
};

const AiSidebarComponent: React.FC<AiSidebarProps> = ({
  isOpen,
  product,
  onClosed,
  onUseSuggestions,
}) => {
  const { generateTitleAiProperties } = useUseCases();
  const { t } = useTranslation();
  const defaultInputValues: GetTitleTextsFromAIAttributes = useMemo(
    () => ({
      productName: product.title || '',
      productDescription: product.description || '',
    }),
    [product]
  );

  const [userTypedTexts, setUserTypedTexts] =
    useState<GetTitleTextsFromAIAttributes>(defaultInputValues);
  const [aiLoading, setAiLoading] = useState<boolean>(false);
  const [aiTextOptions, setAiTextOptions] = useState<
    AiTextsSuggestions | undefined
  >(undefined);
  const [selectedSuggestions, setSelectedSuggestions] =
    useState<Omit<UpdatedProductProps, 'description'>>(emptySelection);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [source, setSource] = useState<CancelTokenSource | undefined>(
    undefined
  );

  useEffect(() => {
    if (!aiLoading && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [aiLoading, isModalOpen]);

  const clearStates = useCallback(() => {
    setSource(undefined);
    setAiTextOptions(undefined);
    setUserTypedTexts(defaultInputValues);
    setSelectedSuggestions(emptySelection);
  }, [defaultInputValues]);

  const handleGenerate = useCallback(async () => {
    try {
      setAiLoading(true);
      const { source: cancelSource, promise } =
        generateTitleAiProperties(userTypedTexts);
      setSource(cancelSource);

      const {
        output: { title, subtitle },
      } = await promise;

      setAiTextOptions({ title, subtitle });
    } catch (e) {
      const error = e as Error;
      if (error.message !== 'request_canceled_by_user') {
        Toast.error(t('toastMessages.general.error.generic'));
      }
    } finally {
      setAiLoading(false);
    }
  }, [generateTitleAiProperties, t, userTypedTexts]);

  const handleRecreate = useCallback(() => {
    setSelectedSuggestions(emptySelection);
    setAiTextOptions(undefined);
  }, []);

  const handleUseSuggestions = useCallback(() => {
    onUseSuggestions(selectedSuggestions);
    clearStates();
    onClosed();
  }, [clearStates, onClosed, onUseSuggestions, selectedSuggestions]);

  const handleCancelProcess = useCallback(() => {
    source?.cancel('request_canceled_by_user');
    clearStates();
    onClosed();
  }, [clearStates, onClosed, source]);

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        header={<AiSideBarHeader />}
        onConfirmClose={() => {
          if (aiLoading) {
            setIsModalOpen(true);
            return false;
          }
          return true;
        }}
        footer={
          !aiLoading && (
            <AiSidebarFooter
              onRecreateButtonClicked={() => handleRecreate()}
              onUseButtonClicked={() => handleUseSuggestions()}
              disabledButtons={{
                recreate: !aiTextOptions,
                use:
                  !aiTextOptions ||
                  !selectedSuggestions.title ||
                  !selectedSuggestions.subtitle,
              }}
            />
          )
        }
        onClose={() => {
          onClosed();
          clearStates();
        }}
      >
        <AiSidebarBody
          isLoading={aiLoading}
          aiTextSuggestions={aiTextOptions}
          selectedSuggestions={selectedSuggestions}
          onGenerateButtonClicked={handleGenerate}
          onGenerateNewSuggestions={handleGenerate}
          onSuggestionSelected={(value) => setSelectedSuggestions(value)}
          userTypedTexts={userTypedTexts}
          onUserTypedText={(value) => setUserTypedTexts(value)}
        />
      </Sidebar>
      <CancelAiModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancelButtonClicked={() => handleCancelProcess()}
      />
    </>
  );
};

export const AiSidebar = memo(AiSidebarComponent);
