import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0px 24px;
  gap: 16px;
`;

export const LeftSlot = styled.div`
  align-items: center;
  gap: 16px;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export type StyledCenterSlotProps = {
  maxWidth: string;
};

export const CenterSlot = styled.div<StyledCenterSlotProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${({ maxWidth }) => css`
    max-width: ${maxWidth};
  `}
`;

export const RightSlot = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 16px;
  justify-content: flex-end;
`;
