import { surrogateEnabled } from '@auth/authService';
import TokenStrategyInterface from '../tokenStrategyInterface';

export default class CASTokenStrategy implements TokenStrategyInterface {
  private localStorageKey = surrogateEnabled()
    ? 'surrogate-id-token'
    : 'cas-id-token';

  getToken(): string | undefined {
    return localStorage.getItem(this.localStorageKey) || undefined;
  }

  setToken(token: string): void {
    localStorage.setItem(this.localStorageKey, token);
  }

  removeToken(): void {
    localStorage.removeItem(this.localStorageKey);
  }
}
