import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/large.css';

import { HTMLAttributes, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataHub } from '@hooks/useDataHub';
import { Button } from './styles';

type CosmosButtonProps = HTMLAttributes<HTMLButtonElement> & {
  size?: 'sm' | 'md';
  iconClass?: string;
  iconSide?: 'right' | 'left';
  label: string;
  type: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  sendMetrifyOptions?: Omit<SendMetrifyOptions, 'element'>;
  width?: string;
  onButtonClicked?: () => void;
  isLoading?: boolean;
};

export const CosmosButton: React.FC<CosmosButtonProps> = ({
  disabled = false,
  label,
  iconClass,
  iconSide = 'right',
  type,
  onButtonClicked,
  size = 'sm',
  sendMetrifyOptions,
  width = '100%',
  isLoading = false,
  ...attrs
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { sendMetrify } = useDataHub();

  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (sendMetrifyOptions && buttonRef.current) {
      sendMetrify({ ...sendMetrifyOptions, element: buttonRef.current });
    }
    onButtonClicked?.();
  }, [onButtonClicked, sendMetrify, sendMetrifyOptions]);

  const classList = useMemo(() => {
    const list = ['hot-button', `hot-button--${type}`];
    if (size !== 'md') {
      list.push(`hot-button--${size}`);
    }
    if (isLoading) {
      list.push('hot-button--loading');
    }
    return list.join(' ');
  }, [isLoading, size, type]);

  return (
    <Button
      onClick={handleClick}
      className={classList}
      disabled={disabled}
      iconSide={iconSide}
      ref={buttonRef}
      width={width}
      {...attrs}
    >
      <span>{t(label)}</span>
      {iconClass && <i className={iconClass} />}
    </Button>
  );
};
