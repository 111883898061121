import {
  ChangeEvent,
  HTMLAttributes,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Pagination from '@pages/PageWizard/useCases/Sales/components/Pagination';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  InputGroup,
  NameColumn,
  IDColumn,
  FormatColumn,
  RadioInput,
  RadioLabel,
  RadioGroup,
  Container,
  RadioColumn,
} from './styles';

export type ProductTableProps = HTMLAttributes<HTMLDivElement> & {
  loading: boolean;
  products: HotmartProduct[];
  selectedProductId?: string;
  searchBarSize?: string;
  hideColumns?: {
    name: boolean;
    id: boolean;
    format: boolean;
  };
  itemsPerPage?: number;
  onSelectProduct: (product: HotmartProduct) => void;
  textSize?: string;
};

const ProductTableComponent: React.FC<ProductTableProps> = ({
  loading,
  products,
  selectedProductId,
  searchBarSize,
  hideColumns,
  itemsPerPage = 6,
  onSelectProduct,
  textSize,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const filteredProducts = useMemo(
    () =>
      products.filter((product) => {
        const { name } = product;
        const searchTermLowerCase = searchTerm.toLowerCase();

        return name.toLowerCase().includes(searchTermLowerCase);
      }),
    [products, searchTerm]
  );

  const totalPages = useMemo(
    () => Math.ceil(filteredProducts.length / itemsPerPage),
    [filteredProducts.length, itemsPerPage]
  );

  const indexOfLastItem = useMemo(
    () => currentPage * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  const indexOfFirstItem = useMemo(
    () => indexOfLastItem - itemsPerPage,
    [indexOfLastItem, itemsPerPage]
  );

  const currentItems = useMemo(
    () => filteredProducts.slice(indexOfFirstItem, indexOfLastItem),
    [filteredProducts, indexOfFirstItem, indexOfLastItem]
  );

  return (
    <Container>
      <InputGroup searchBarSize={searchBarSize}>
        <input
          id="productSearch"
          className="hot-form__input hot-form__input--lg"
          placeholder={t(
            'pageWizard.salesPage.paidWithProduct.product.searchPlaceholder'
          )}
          type="text"
          onChange={handleSearch}
          data-prevent-delete
        />
        <Icon className="fal fa-search" />
      </InputGroup>

      {loading ? (
        <hot-loading />
      ) : (
        <table className="hot-table hot-table--bordered">
          <thead>
            <tr>
              <th> </th>
              <th>
                {t('pageWizard.salesPage.paidWithProduct.product.nameColumn')}
              </th>
              {!hideColumns?.id && <th>ID</th>}
              {!hideColumns?.format && (
                <th>
                  {t(
                    'pageWizard.salesPage.paidWithProduct.product.formatColumn'
                  )}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((product) => (
              <tr key={product.id}>
                <RadioColumn>
                  <RadioGroup className="hot-form hot-form--custom hot-form--radio">
                    <RadioInput
                      type="radio"
                      className="hot-form__input"
                      id={`selectProduct-${product.id}`}
                      checked={selectedProductId === product.id}
                      onChange={() => onSelectProduct(product)}
                    />
                    <RadioLabel
                      className="hot-form__label"
                      htmlFor={`selectProduct-${product.id}`}
                    />
                  </RadioGroup>
                </RadioColumn>

                <NameColumn textSize={textSize}>
                  <label htmlFor={`selectProduct-${product.id}`}>
                    {product.name}
                  </label>
                </NameColumn>

                {!hideColumns?.id && (
                  <IDColumn>
                    <div className="spacing">{product.id}</div>
                  </IDColumn>
                )}
                {!hideColumns?.format && (
                  <FormatColumn>
                    <div className="spacing">
                      {t(`product.${product.categoryName}`)}
                    </div>
                  </FormatColumn>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};

export const ProductTable = memo(ProductTableComponent);
