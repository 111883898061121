import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';

import { HTMLAttributes, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useUserInfo } from '@hooks/useUserInfo';
import { useMediaCenter } from '@hooks/useMediaCenter';
import i18n from '@i18n/index';
import { Container, Description, Icon, Input, Label } from './styles';
import MediaCenter from '../../services/mediaCenter';
import Toast from '../../services/toast';

export type UploadMediaProps = HTMLAttributes<HTMLDivElement> & {
  accept?: string;
  multiple?: boolean;
  allowedExtensions?: string[];
  description?: string;
  hideDescription?: boolean;
  showLoader?: boolean;
  isSingleUpload?: boolean;
  onSingleUpload?: (media: UploadMediaFile) => void;
  onUpload?: (medias: Media[]) => void;
};

const UploadMediaComponent: React.FC<UploadMediaProps> = ({
  accept = 'image/*',
  multiple = true,
  allowedExtensions = [],
  description = 'mediaCenter.addImage.description',
  hideDescription = false,
  showLoader = false,
  isSingleUpload = false,
  onSingleUpload,
  onUpload,
  ...attrs
}) => {
  const [uploading, setUploading] = useState(false);
  const { loading, uploadMedia } = useMediaCenter();
  const { isFreePlan, userMediaMaxSize } = useUserInfo();
  const { t } = useTranslation();

  const isUploading = useMemo(
    () => showLoader && uploading,
    [showLoader, uploading]
  );

  const handleUploadMedia = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { files } = target;

      if (!files) return;

      const filesArray = Object.values(files);
      target.value = '';

      filesArray.forEach(async (file) => {
        if (!MediaCenter.isValidMedia(file, allowedExtensions)) {
          Toast.error(t('toastMessages.media.error.unsupportedFormat'));
          return;
        }

        if (
          !MediaCenter.isValidMediaSize({
            size: file.size,
            isFreePlan,
          })
        ) {
          Toast.error(
            i18n.t('toastMessages.media.error.maximumSize', {
              count: userMediaMaxSize,
            })
          );
          return;
        }

        try {
          setUploading(true);

          if (isSingleUpload) {
            const media = await MediaCenter.uploadMedia(file);
            onSingleUpload?.(media);
          } else {
            const medias = await uploadMedia(file);
            onUpload?.(medias);
          }
        } catch {
          Toast.error('toastMessages.media.error.saved');
        } finally {
          setUploading(false);
        }
      });
    },

    [
      allowedExtensions,
      isFreePlan,
      isSingleUpload,
      onSingleUpload,
      onUpload,
      t,
      uploadMedia,
      userMediaMaxSize,
    ]
  );

  return (
    <Container {...attrs}>
      {!hideDescription && <Description>{t(description)}</Description>}
      <Label
        data-onboarding="upload-media-button"
        className={classNames('hot-button hot-button--sm hot-button--primary', {
          'hot-button--disabled': loading,
          'hot-button--loading': isUploading,
        })}
      >
        <Icon className="fal fa-upload" />
        {t('mediaCenter.addImage.button')}
        <Input
          disabled={loading}
          type="file"
          name="file"
          id="file"
          role="textbox"
          aria-hidden
          multiple={multiple}
          accept={accept}
          onChange={(e) => handleUploadMedia(e)}
        />
      </Label>
    </Container>
  );
};

export const UploadMedia = memo(UploadMediaComponent);
