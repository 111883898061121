import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const SectionsElement: React.FC<BaseIconProps> = ({
  color,
  ...attrs
}) => (
  <StyledSVG
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M29 0H3C1.3125 0 0 1.375 0 3V25C0 26.6875 1.3125 28 3 28H29C30.625 28 32 26.6875 32 25V3C32 1.375 30.625 0 29 0ZM2 3C2 2.5 2.4375 2 3 2H6V6H2V3ZM30 25C30 25.5625 29.5 26 29 26H3C2.4375 26 2 25.5625 2 25V8H30V25ZM30 6H8V2H29C29.5 2 30 2.5 30 3V6Z"
      fill={color}
    />
  </StyledSVG>
);
