import { HTMLAttributes, memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Container } from './styles';

export type BarMessageProps = HTMLAttributes<HTMLDivElement> & {
  selectedMediaLength?: number;
  searchResultLength?: number;
};

const BarMessageComponent: React.FC<BarMessageProps> = ({
  selectedMediaLength,
  searchResultLength,
  ...attrs
}) => {
  const renderMessageTrans = useCallback(() => {
    if (selectedMediaLength) {
      return (
        <Trans
          i18nKey="mediaCenter.infoBar.selectedMediaMessage"
          components={{ b: <b /> }}
          values={{ count: selectedMediaLength }}
        />
      );
    }
    return (
      <Trans
        i18nKey="mediaCenter.infoBar.searchMessage"
        components={{ b: <b /> }}
        values={{ count: searchResultLength }}
      />
    );
  }, [searchResultLength, selectedMediaLength]);

  return <Container {...attrs}>{renderMessageTrans()}</Container>;
};

export const BarMessage = memo(BarMessageComponent);
