import SupportChat from '../../services/supportChat';

const appendScriptsToParser = (chatScript: string) => {
  const parser = new DOMParser();
  const regex = /script( )*>/gi;
  const doc = parser.parseFromString(
    `<body>${chatScript.toString().replace(regex, 'script>')}</body>`,
    'application/xml'
  );
  const errorNode = doc.querySelector('parsererror');

  if (errorNode) {
    throw new Error('Error parsing script html');
  }

  doc.querySelectorAll('script').forEach((script) => {
    const news = document.createElement('script');

    Array.from(script.attributes).forEach((tag) => {
      news.setAttribute(tag.name, tag.value);
    });

    news.text = script.textContent as string;
    news.onload = () => {
      window.zE!('webWidget', 'hide');
    };
    document.body.appendChild(news);
  });
};

const loadZendeskLiveChat = async () => {
  const chat = await SupportChat.getChat();
  const chatScript = chat.html;
  appendScriptsToParser(chatScript);
};

const loadZendeskMessenger = () => {
  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_MESSENGER_ID}`;

  document.body.appendChild(script);

  script.onload = () => {
    window.zE!('messenger', 'close');
    window.zE!('messenger', 'hide');
  };
};

export const loadChat = async (
  isFreemium: boolean,
  zendeskMessengerEnabled: boolean
) => {
  if (!isFreemium) {
    if (zendeskMessengerEnabled) {
      loadZendeskMessenger();
      return;
    }

    await loadZendeskLiveChat();
  }
};
