import { HTMLAttributes, memo } from 'react';
import { CosmosButton } from '@components/CosmosButton';
import { useTranslation } from 'react-i18next';
import { FooterButtonsWrapper } from './styles';

type AiSidebarFooterProps = HTMLAttributes<HTMLDivElement> & {
  recreateLabel?: string;
  useLabel?: string;
  disabledButtons: { recreate: boolean; use: boolean };
  hideRecreateButton?: boolean;
  onRecreateButtonClicked: () => void;
  onUseButtonClicked: () => void;
};

const AiSidebarFooterComponent: React.FC<AiSidebarFooterProps> = ({
  recreateLabel = 'pageWizard.salesPage.paidWithProduct.sidebar.footer.firstButton',
  useLabel = 'pageWizard.salesPage.paidWithProduct.sidebar.footer.secondButton',
  disabledButtons,
  hideRecreateButton,
  onRecreateButtonClicked,
  onUseButtonClicked,
}) => {
  const { t } = useTranslation();

  return (
    <FooterButtonsWrapper>
      {!hideRecreateButton && (
        <CosmosButton
          label={t(recreateLabel)}
          size="md"
          type="secondary"
          width="auto"
          onButtonClicked={onRecreateButtonClicked}
          disabled={disabledButtons.recreate}
        />
      )}

      <CosmosButton
        label={t(useLabel)}
        size="md"
        type="primary"
        width="auto"
        iconClass="fal fa-arrow-right"
        onButtonClicked={onUseButtonClicked}
        disabled={disabledButtons.use}
      />
    </FooterButtonsWrapper>
  );
};

export const AiSidebarFooter = memo(AiSidebarFooterComponent);
