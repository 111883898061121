import { useTranslation } from 'react-i18next';
import camelcase from 'camelcase';
import { HTMLAttributes, memo, useEffect, useMemo } from 'react';
import { BaseHeader } from 'src/components/Header';
import { Logo } from './components/Logo';
import { Container, Title } from './styles';

export type EditorHeaderProps = HTMLAttributes<HTMLDivElement> & {
  type: UseCaseType;
};

const HeaderComponent: React.FC<EditorHeaderProps> = ({ type, ...attrs }) => {
  const { t } = useTranslation();

  const typeMapping: Record<string, string> = useMemo(
    () => ({
      funnel_capture_publish_lead_capture_page: 'publish_lead_capture_page',
    }),
    []
  );

  const formattedType = useMemo(
    () => camelcase(typeMapping[type] || type),
    [type, typeMapping]
  );

  useEffect(() => {
    const headerActions = document
      .querySelector('hot-header')
      ?.querySelector('div[slot="header-actions"]') as HTMLDivElement;
    if (headerActions) {
      headerActions.setAttribute('style', 'margin: 0; gap: 0;');
    }
  }, []);

  const renderCenterSlot = useMemo(
    () => (
      <Container>
        <Logo />
        <Title>{t(`pageWizard.${formattedType}.header`)}</Title>
      </Container>
    ),
    [formattedType, t]
  );

  return <BaseHeader {...attrs} centerSlot={renderCenterSlot} />;
};

export const Header = memo(HeaderComponent);
