import { HTMLAttributes, useCallback } from 'react';
import { useTemplateInfo } from '@hooks/useTemplateInfo';
import { useUserInfo } from '@hooks/useUserInfo';
import Pages from '@services/pages';
import { UploadMediaWithPreview } from '@components/UploadMediaWithPreview';
import { Submenu } from '@components/Submenu';

export const TemplateThumbSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { thumb, uuid, mode, updateInfo } = useTemplateInfo();
  const { templateManagementEnabled } = useUserInfo();

  const handleMediaChange = useCallback(
    async ({ url }: UploadMediaFile) => {
      const payload = { thumb: url };
      const template = await Pages.updatePage<TemplatePage>(
        uuid,
        payload,
        mode,
        templateManagementEnabled
      );
      updateInfo({ thumb: template.thumb });
    },
    [mode, templateManagementEnabled, updateInfo, uuid]
  );

  return (
    <Submenu title="templateThumb.title" padding="0" {...attrs}>
      <UploadMediaWithPreview
        previewSrc={thumb}
        previewHeight="204px"
        title="templateThumb.thumb.title"
        onUpload={handleMediaChange}
      />
    </Submenu>
  );
};
