import styled, { createGlobalStyle, css } from 'styled-components';

export const ModalFooterStyle = createGlobalStyle`
  .modal-warning-footer {
    justify-content: center;
  }
`;

export const ModalContent = styled.div`
  display: flex;

  hot-content {
    margin: 0;
    padding: 0;
    min-height: auto;

    hot-content-menu {
      position: unset;
      width: 290px;
      margin: 0;
      padding: 0;

      slot[name='content-menu-header'] {
        display: none !important;
      }
    }
  }
`;

export const CategoriesColumn = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #ccc;
`;

type CategoriesListProps = {
  isReplaceMode?: boolean;
};

export const CategoriesList = styled.div<CategoriesListProps>`
  overflow-y: auto;
  padding: 14px 12px;

  ${({ isReplaceMode }) => css`
    height: ${isReplaceMode ? '658px' : '570px'};
    border-bottom: ${isReplaceMode ? 'none' : ' 1px solid #ccc'};
  `}
`;

export const CategoriesButtonContainer = styled.div`
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  margin: 0;
  color: var(--gray-900);
  font-size: 20px;
`;

export const ActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 88px;
  padding: 24px;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddIcon = styled.i`
  width: 12px;
  height: 12px;
`;
