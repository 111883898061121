import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageInfoExtraReducers } from '@store/thunks/pageInfo';

export const pageInfoInitialState: PageInfoState = {
  uuid: '',
  name: '',
  path: '',
  domain: '',
  metadata: undefined,
  mode: 'page',
  redirectUrl: '',
  enabled: false,
  hasBeenPublished: false,
  hasFontsConfig: false,
  hasMetadata: false,
  hasUnsavedChanges: false,
  savingStatus: 'success',
  savingStatusMessage: '',
  lastPublishingStatus: null,
  published: false,
  isExpert: false,
  isNew: false,
  templateCategories: [],
  thumb: '',
};

const pageInfo = createSlice({
  name: 'pageInfo',
  initialState: pageInfoInitialState,
  reducers: {
    updateInfo(state, action: PayloadAction<Partial<PageInfoState>>) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => pageInfoExtraReducers(builder),
});

export const { updateInfo } = pageInfo.actions;

export default pageInfo.reducer;
