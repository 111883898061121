import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-radio.css';

import { HTMLAttributes } from 'react';
import { themes, Theme, reducedThemes } from './themes';
import {
  RadioLabel,
  Radio,
  ThemeContainer,
  Container,
  ColorSample,
} from './styles';

export type ThemesProps = HTMLAttributes<HTMLDivElement> & {
  selectedTheme?: string;
  disabled?: boolean;
  isReducedThemes?: boolean;
  onThemeChange?: (theme: Theme) => void;
};

export const Themes: React.FC<ThemesProps> = ({
  selectedTheme,
  disabled = false,
  isReducedThemes = false,
  onThemeChange,
  ...attrs
}) => (
  <Container {...attrs} isReducedThemes={isReducedThemes}>
    {(isReducedThemes ? reducedThemes : themes).map(
      ({ id, primaryColor, secondaryColor }) => (
        <ThemeContainer key={id} htmlFor={`theme-${id}`} disabled={disabled}>
          <Radio className="hot-form hot-form--custom hot-form--radio">
            <input
              id={`theme-${id}`}
              type="radio"
              className="hot-form__input"
              checked={id === selectedTheme}
              disabled={disabled}
              onChange={() =>
                onThemeChange?.({ id, primaryColor, secondaryColor })
              }
            />
            <RadioLabel className="hot-form__label" htmlFor={`theme-${id}`} />
          </Radio>

          <ColorSample color={primaryColor} />
          <ColorSample color={secondaryColor} />
        </ThemeContainer>
      )
    )}
  </Container>
);
