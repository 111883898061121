import { createSlice } from '@reduxjs/toolkit';
import { pageFragmentsExtraReducers } from '@store/thunks/pageFragments';

export const pageFragmentsInitialState: PageFragmentsState = {
  userSections: [],
  userPopups: [],
  popupLoading: false,
  sectionLoading: false,
};

const pageFragments = createSlice({
  name: 'pageFragments',
  initialState: pageFragmentsInitialState,
  reducers: {},
  extraReducers: (builder) => pageFragmentsExtraReducers(builder),
});

export default pageFragments.reducer;
