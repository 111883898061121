import styled from 'styled-components';

export const Container = styled.div`
  .hot-form {
    margin-bottom: 16px;

    &__label {
      margin-bottom: 4px;
    }
  }
`;

export const Title = styled.label`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const TextArea = styled.textarea`
  resize: none;
`;

export const Button = styled.button`
  width: 100%;
`;
