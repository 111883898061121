import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { TextInput } from '@components/TextInput';
import { useUseCases } from '@hooks/useUseCases';
import { Container } from './styles';

type UpdatedOffer = {
  offerPrice: string;
  offerLink: string;
};

export type OfferInputsProps = HTMLAttributes<HTMLDivElement> & {
  productId?: string;
  offerPrice: string;
  offerLink: string;
  onOfferChange: (updatedOffer: UpdatedOffer) => void;
};

export const OfferInputs: React.FC<OfferInputsProps> = ({
  productId,
  offerLink,
  offerPrice,
  onOfferChange,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { setFooterRightButtonConfig } = useUseCases();

  const [currentOffer, setCurrentOffer] = useState({
    offerPrice,
    offerLink,
  });

  const hasProduct = useMemo(() => Boolean(productId), [productId]);

  const debouncedOnValueChange = useRef(debounce(onOfferChange, 500));

  const handleChange = useCallback(
    (value: string, key: string) => {
      const state = { ...currentOffer, [key]: value };
      setCurrentOffer(state);
      debouncedOnValueChange.current(state);
    },
    [currentOffer]
  );

  useEffect(
    () => setCurrentOffer({ offerLink, offerPrice }),
    [offerLink, offerPrice]
  );

  useEffect(() => {
    const disabled = !currentOffer.offerLink;

    setFooterRightButtonConfig({
      disabled,
      errorMessage: disabled
        ? `${t('pageWizard.salesPage.paidWithProduct.updateOffer.emptyOffer')}`
        : '',
    });

    return () => {
      setFooterRightButtonConfig({
        disabled: false,
        errorMessage: '',
      });
    };
  }, [currentOffer.offerLink, setFooterRightButtonConfig, t]);

  return (
    <Container {...attrs} hasProduct={hasProduct}>
      <TextInput
        id="offer-price"
        name="offer-price"
        size="md"
        removeEmoji
        label={t(`pageWizard.salesPage.paidWithProduct.updateOffer.priceLabel`)}
        placeholder={t(
          `pageWizard.salesPage.paidWithProduct.updateOffer.pricePlaceholder`
        )}
        readOnly={hasProduct}
        value={currentOffer.offerPrice}
        onChange={(event) =>
          handleChange((event.target as HTMLInputElement).value, 'offerPrice')
        }
      />

      <TextInput
        id="offer-link"
        name="offer-link"
        size="md"
        removeEmoji
        label={t(`pageWizard.salesPage.paidWithProduct.updateOffer.linkLabel`)}
        placeholder="https://pay.hotmart.com/exemplo"
        infoTooltip={t(
          `pageWizard.salesPage.paidWithProduct.updateOffer.linkTooltip`
        )}
        readOnly={hasProduct}
        value={currentOffer.offerLink}
        onChange={(event) =>
          handleChange((event.target as HTMLInputElement).value, 'offerLink')
        }
      />
    </Container>
  );
};
