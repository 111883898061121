import {
  HTMLAttributes,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ComponentState,
  EditorEngineManager,
} from '@hotmart-org-ca/saas-pages-engine';
import { TextInput } from '@components/TextInput';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useHigherNameByElement } from '@hooks/useHigherNameByElement';
import { Container } from './styles';

export type ElementLabelModifierProps = HTMLAttributes<HTMLDivElement> &
  CustomModifierProps & {
    label: string;
    propName: string;
    component: 'ls-popup' | 'ls-section';
  };

export const ElementLabelModifier: React.FC<ElementLabelModifierProps> = ({
  uid,
  propName,
  component,
  label,
  ...attrs
}) => {
  const { t } = useTranslation();

  const metadata = useSelector(
    (state: State) => (state[uid] as ComponentState)?.metadata || {}
  );
  const [valueDescription, setValueDescription] = useState('');

  const { higherName } = useHigherNameByElement(component);

  const getItemValue = useCallback(
    () => metadata[propName] || '',
    [metadata, propName]
  );

  const saveItemValue = useCallback(
    async (value: string) => {
      const payload = {
        metadata: { [propName]: value },
      };

      EditorEngineManager.updateElement(uid, payload);
    },
    [propName, uid]
  );

  const debouncedOnValueChange = useRef(debounce(saveItemValue, 200));

  const handleBlur = useCallback(() => {
    if (!valueDescription) {
      saveItemValue(higherName);
    }
  }, [higherName, saveItemValue, valueDescription]);

  const handleChange = useCallback((e: React.ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setValueDescription(value);
    debouncedOnValueChange.current(value);
  }, []);

  useEffect(() => {
    setValueDescription(getItemValue);
  }, [component, getItemValue, uid]);

  return typeof valueDescription === 'string' ? (
    <Container>
      <TextInput
        {...attrs}
        id="metadata"
        name="metadata"
        label={t(label)}
        size="sm"
        value={valueDescription}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
      />
    </Container>
  ) : null;
};
