import AuthService, { Bootstrap } from '@hotmart/cas-js';
import { PossibleStorages } from '@hotmart/cas-js/dist/auth.service';

const surrogateEnabled = () =>
  sessionStorage.getItem('surrogate-enabled') === 'true';

const CAS_USER_STORE = surrogateEnabled()
  ? PossibleStorages.sessionStorage
  : PossibleStorages.localStorage;

const CAS_AUTHORITY = surrogateEnabled()
  ? process.env.REACT_APP_CAS_SURROGATE_URL
  : process.env.REACT_APP_CAS_URL;

const authService = new AuthService({
  env: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  authority: `${CAS_AUTHORITY}/oidc`,
  storeToUse: CAS_USER_STORE,
});

const bootstrap = new Bootstrap(authService);

const casEnabled = () => {
  const enabled = process.env.REACT_APP_CAS_AUTHENTICATION_ENABLED === 'true';
  return enabled;
};

export { bootstrap, casEnabled, surrogateEnabled };
export default authService;
