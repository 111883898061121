import { AxiosRequestConfig } from 'axios';
import Api from '../api';

export default class PublicHttp {
  private apiInstance = Api.getPublicInstance();

  async delete<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.delete<T>(url, config);
    return data;
  }

  async get<T>(url: string, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.get<T>(url, config);
    return data;
  }

  async patch<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.patch<T>(url, body, config);
    return data;
  }

  async post<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.post<T>(url, body, config);
    return data;
  }

  async put<T>(url: string, body?: any, config?: AxiosRequestConfig) {
    const { data } = await this.apiInstance.put<T>(url, body, config);
    return data;
  }
}
