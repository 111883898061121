import {
  ChangeEvent,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@components/TextInput';
import { Block } from '@components/Block';
import debounce from 'lodash.debounce';

export type NameProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  name: string;
  value: string;
  label: string;
  showSeparator?: boolean;
  inputLabel?: string;
  placeholder?: string;
  inputType?: 'input' | 'textarea';
  width?: string;
  rows?: number;
  onNameChange: (value: string) => void;
};

const TextboxComponent: React.FC<PropsWithChildren<NameProps>> = ({
  id,
  inputLabel = '',
  name,
  value: valueProp,
  showSeparator = true,
  label,
  placeholder = '',
  inputType = 'input',
  width,
  rows,
  children,
  onNameChange,
  ...attrs
}) => {
  const { t } = useTranslation();

  const [currentName, setCurrentName] = useState(valueProp);
  const debouncedOnValueChange = useRef(debounce(onNameChange, 500));

  const handleNameChange = useCallback((e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;

    setCurrentName(value);
    debouncedOnValueChange.current(value);
  }, []);

  useEffect(() => {
    setCurrentName(valueProp);
  }, [valueProp]);

  return (
    <Block label={label} showSeparator={showSeparator} {...attrs}>
      <TextInput
        id={id}
        name={name}
        inputType={inputType}
        placeholder={t(placeholder)}
        size="md"
        value={currentName}
        removeEmoji
        onChange={handleNameChange}
        label={t(inputLabel)}
        width={width}
        rows={rows}
      />
      {children}
    </Block>
  );
};

export const Textbox = memo(TextboxComponent);
