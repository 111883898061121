import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-switch.css';

import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateConfig } from '@store/slices/pageConfig';
import Toast from '@services/toast';
import Files from '@services/files';
import { Container, Content, Label, Description } from './styles';

export const MadeWithKlickpages: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uuid = useSelector((state: State) => state.pageInfo.uuid);
  const madeWithManagementEnabled = useSelector(
    (state: State) => state.userInfo.permissions.madeWithManagementEnabled
  );
  const madeWith = useSelector(
    (state: State) => state.pageConfig.showMadeWithKlickpages
  );

  const isChecked = useMemo(
    () => (!madeWithManagementEnabled ? false : !madeWith),
    [madeWithManagementEnabled, madeWith]
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const config = {
        showMadeWithKlickpages: !event.target.checked,
      };
      dispatch(updateConfig(config));

      try {
        await Files.saveConfigFile(uuid, config);
        Toast.success('toastMessages.general.success.saved');
      } catch {
        Toast.error('toastMessages.general.error.saved');
      }
    },
    [uuid, dispatch]
  );

  return (
    <Container {...attrs}>
      <Content className="hot-form hot-form--custom hot-form--switch">
        <input
          type="checkbox"
          className="hot-form__input"
          id="madeWithSwitch"
          onChange={handleChange}
          checked={isChecked}
          disabled={!madeWithManagementEnabled}
        />
        <Label className="hot-form__label" htmlFor="madeWithSwitch">
          {t('seo.madeWith.label.removeLabel')}
          <br />
          <strong>{t('seo.madeWith.label.madeWith')}</strong>
        </Label>
      </Content>
      <Description>{t('seo.madeWith.description')}</Description>
    </Container>
  );
};
