import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { savePageFiles, savePageFilesBuilder } from './savePageFiles';
import { savePageMetadata, savePageMetadataBuilder } from './savePageMetadata';

export const pageInfoThunks = { savePageFiles, savePageMetadata };

export const pageInfoExtraReducers = (
  builder: ActionReducerMapBuilder<PageInfoState>
) => {
  savePageFilesBuilder(builder);
  savePageMetadataBuilder(builder);
};
