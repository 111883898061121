import '@hotmart-org-ca/cosmos/dist/button/variations/primary.css';
import Pages from '@services/pages';
import Files from '@services/files';
import { getLocaleLink } from '@i18n/index';
import { HTMLAttributes, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import Toast from '@services/toast';
import { UserLimitModal } from '@components/UserLimitModal';
import { usePageInfo } from '@hooks/usePageInfo';

export const SelectModelButton: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isUserLimitModalOpen, setIsUserLimitModalOpen] = useState(false);
  const [errorMessageUserLimitModal, setErrorMessageUserLimitModal] =
    useState('');

  const { uuid: templateId } = usePageInfo();

  const handleSave = useCallback(async () => {
    setIsLoading(true);

    try {
      const { canCreatePage } = await Pages.validateUserLimits();
      if (!canCreatePage) {
        setErrorMessageUserLimitModal('pageLimit');
        setIsUserLimitModalOpen(true);
        return;
      }
      const { page, fontsConfig } = EditorEngineManager.getFinalFiles() || {};

      if (page) {
        const { uuid: newPageId } = await Pages.createPage({
          name: t('page.newPage'),
        });
        const [config] = Files.normalizePageConfig();

        await Files.createFiles(newPageId, [
          { name: 'fontsConfig', content: fontsConfig },
          { name: 'metadata', content: { templateId } },
          { name: 'page', content: page },
          { name: 'config', content: config },
        ]);
        window.location.replace(getLocaleLink(`/${newPageId}`));
      }
    } catch (error) {
      Toast.error('templateSelectModel.errorMessage');
    } finally {
      setIsLoading(false);
    }
  }, [templateId, t]);

  return (
    <>
      <button
        className={classNames('hot-button hot-button--primary', {
          'hot-button--loading': isLoading,
        })}
        id="select-model"
        onClick={handleSave}
        {...attrs}
      >
        {t('templateSelectModel.button')}
      </button>

      <UserLimitModal
        id="user-limit-modal"
        isOpen={isUserLimitModalOpen}
        error={errorMessageUserLimitModal}
        onClose={() => setIsUserLimitModalOpen(false)}
      />
    </>
  );
};
