import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  margin-bottom: 0;
  width: 100%;
`;

export const Icon = styled.i`
  font-size: 24px;
  color: var(--gray-400);
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  height: 65px;
  width: 65px;
  background: var(--gray-100);
  margin-right: 16px;
  border: 1px solid var(--gray-300);
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
