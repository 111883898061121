import styled from 'styled-components';

export const Label = styled.label`
  font-size: 16px;
`;

export const Icon = styled.i`
  margin-left: 2px;
`;

export const Button = styled.button`
  width: 100%;
`;

export const Description = styled.div`
  color: var(--gray-500);
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
`;

export const ErrorMessage = styled.div`
  display: block;
`;
