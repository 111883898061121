import '@hotmart-org-ca/cosmos/dist/table/variations/bordered.css';
import '@hotmart-org-ca/cosmos/dist/table/table.css';

import React, {
  HTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import HotmartProductService from '@services/hotmartProduct';
import { Block } from '@components/Block';
import { useUseCases } from '@hooks/useUseCases';
import { OfferTable } from '@components/OfferTable';
import { Container } from './styles';

export type OfferPropsComponent = HTMLAttributes<HTMLDivElement> &
  UseCaseStepProps & {
    product: ProductProps;
  };

const OfferComponent: React.FC<OfferPropsComponent> = ({
  onModifierChange,
  product: productProp,
  ...attrs
}) => {
  const { setFooterRightButtonConfig } = useUseCases();

  const [offers, setOffers] = useState<HotmartProductOffer[]>([]);
  const [loading, setLoading] = useState(true);

  const getOffers = useCallback(async () => {
    try {
      setLoading(true);

      const offersData = await HotmartProductService.getOffersByProductId(
        productProp.id as string
      );

      setOffers(offersData);
    } finally {
      setLoading(false);
    }
  }, [productProp.id]);

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  const handleSelectOffer = useCallback(
    (product: Partial<ProductProps>) => {
      onModifierChange({
        prop: 'product',
        value: {
          ...productProp,
          ...product,
        },
      });
    },
    [onModifierChange, productProp]
  );

  useEffect(() => {
    setFooterRightButtonConfig({ disabled: Boolean(!productProp.offerId) });
  }, [productProp.offerId, setFooterRightButtonConfig]);

  return (
    <Container {...attrs}>
      <Block
        label="pageWizard.salesPage.paidWithProduct.offer.title"
        showSeparator={false}
        width="100%"
        description="pageWizard.salesPage.paidWithProduct.offer.description"
        noPreview
      >
        <OfferTable
          loading={loading}
          offers={offers}
          selectedOfferId={productProp.offerId}
          onSelectOffer={handleSelectOffer}
          searchBarSize="100%"
        />
      </Block>
    </Container>
  );
};

export const Offer = memo(OfferComponent);
