import { HTMLAttributes, useCallback, useState } from 'react';
import { ImageUploadButton } from '@components/ImageUploadButton';
import { Block } from '@components/Block';
import { Container, Preview, Image, Icon } from './styles';

export type ImageUploadProps = HTMLAttributes<HTMLDivElement> & {
  src?: string;
  onImageChange?: (url: string) => void;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
  src: srcProp = '',
  onImageChange,
  ...attrs
}) => {
  const [src, setSrc] = useState(srcProp);

  const handleImage = useCallback(
    (imageSrc: string) => {
      onImageChange?.(imageSrc);
      setSrc(imageSrc);
    },
    [onImageChange]
  );

  return (
    <Block
      label="pageWizard.publishPage.imageUpload.label"
      showSeparator
      {...attrs}
    >
      <Container>
        <Preview>
          {src ? (
            <Image src={src} alt="linkTree" />
          ) : (
            <div>
              <Icon className="fal fa-image" />
            </div>
          )}
        </Preview>

        <ImageUploadButton
          label="pageWizard.publishPage.imageUpload.uploadButton"
          onImageChange={(imageSource: string) => handleImage(imageSource)}
        />
      </Container>
    </Block>
  );
};
