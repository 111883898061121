import { HTMLAttributes, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import {
  Container,
  ButtonsContainer,
  CancelButton,
  DestinationFolderDescription,
} from './styles';

export type MoveModalFooterProps = HTMLAttributes<HTMLDivElement> & {
  onMoveMedia?: () => void;
  onClose?: () => void;
  isMoving?: boolean;
  selectedFolder?: FolderTree;
};

const FooterComponent: React.FC<MoveModalFooterProps> = ({
  onMoveMedia,
  onClose,
  selectedFolder,
  isMoving = false,
  ...attrs
}) => {
  const { t } = useTranslation();

  return (
    <Container {...attrs}>
      <DestinationFolderDescription>
        <Trans
          i18nKey="mediaCenter.infoBar.moveModal.body.moveDescription"
          components={{ b: <b /> }}
          values={{ destinationFolder: selectedFolder?.name }}
        />
      </DestinationFolderDescription>

      <ButtonsContainer>
        <CancelButton
          className="hot-button hot-button--secondary"
          onClick={onClose}
        >
          {t('modals.mediaCenter.cancelButton')}
        </CancelButton>

        <button
          onClick={onMoveMedia}
          className={classNames('hot-button hot-button--primary', {
            'hot-button--loading': isMoving,
          })}
        >
          {t('mediaCenter.infoBar.moveModal.footer.move')}
        </button>
      </ButtonsContainer>
    </Container>
  );
};

export const Footer = memo(FooterComponent);
