import { useMemo, useCallback } from 'react';
import { useAppDispatch } from '@store/index';
import { useSelector } from 'react-redux';
import * as userInfoSlice from '@store/slices/userInfo';

export const useUserInfo = () => {
  const exportPageEnabled: boolean = useSelector(
    (state: State) => state.userInfo.permissions.exportPageEnabled
  );

  const importPageEnabled: boolean = useSelector(
    (state: State) => state.userInfo.permissions.importPageEnabled
  );

  const isExtensions: boolean = useSelector(
    (state: State) => state.userInfo.userProfile.isExtensions
  );

  const isFreePlan: boolean = useSelector(
    (state: State) => state.userInfo.userProfile.plan.isFree
  );

  const isFreemium: boolean = useMemo(
    () => isFreePlan && isExtensions,
    [isExtensions, isFreePlan]
  );

  const madeWithManagementEnabled: boolean = useSelector(
    (state: State) => state.userInfo.permissions.madeWithManagementEnabled
  );

  const pageWithRedirectEnabled: boolean = useSelector(
    (state: State) => state.userInfo.permissions.pageWithRedirectEnabled
  );

  const templateManagementEnabled: boolean = useSelector(
    (state: State) => state.userInfo.permissions.templateManagementEnabled
  );

  const userMediaMaxSize: number = useMemo(
    () => (isFreePlan ? 1 : 10),
    [isFreePlan]
  );

  const userProfile: UserProfile = useSelector(
    (state: State) => state.userInfo.userProfile
  );

  const dispatch = useAppDispatch();

  const updateInfo = useCallback(
    (userInfo: UserInfo) => {
      dispatch(userInfoSlice.updateInfo(userInfo));
    },
    [dispatch]
  );

  return {
    exportPageEnabled,
    importPageEnabled,
    isExtensions,
    isFreemium,
    isFreePlan,
    madeWithManagementEnabled,
    pageWithRedirectEnabled,
    templateManagementEnabled,
    userMediaMaxSize,
    userProfile,
    updateInfo,
  };
};
