import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Toast from '@services/toast';
import { useSavedPopups } from '@hooks/useSavedPopups';
import { BaseModal } from '@components/BaseModal';
import { Description, ModalTitle, CancelButton } from './styles';

export type RemovePopupModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  popupId: string;
  onClose: () => void;
};

const RemovePopupModalComponent: React.FC<RemovePopupModalProps> = ({
  isOpen,
  popupId,
  onClose,
  ...attrs
}) => {
  const { t } = useTranslation();
  const { deleteUserPopup: deletePopup, loading } = useSavedPopups();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(async () => {
    try {
      await deletePopup(popupId);
      Toast.success(t('toastMessages.savePopupModal.removed.success'));
    } catch {
      Toast.error(t('toastMessages.savePopupModal.removed.error'));
    } finally {
      handleCloseModal();
    }
  }, [deletePopup, handleCloseModal, popupId, t]);

  return (
    <BaseModal
      {...attrs}
      isOpen={isOpen}
      onClose={onClose}
      header={<ModalTitle>{t(`modals.removeSavedPopups.title`)}</ModalTitle>}
      footer={
        <>
          <CancelButton className="hot-button" onClick={handleCloseModal}>
            {t(`modals.removeSavedPopups.cancelButton`)}
          </CancelButton>
          <button
            onClick={handleDelete}
            className={classNames('hot-button hot-button--danger', {
              'hot-button--loading': loading,
            })}
          >
            {t(`modals.removeSavedPopups.removeButton`)}
          </button>
        </>
      }
    >
      <Description>{t(`modals.removeSavedPopups.description`)}</Description>{' '}
    </BaseModal>
  );
};

export const RemovePopupModal = memo(RemovePopupModalComponent);
