import '@hotmart-org-ca/cosmos/dist/dropdown';
import '@hotmart-org-ca/cosmos/dist/select';
import '@hotmart-org-ca/cosmos/dist/button/button.css';
import '@hotmart-org-ca/cosmos/dist/button/variations/tertiary.css';
import '@hotmart-org-ca/cosmos/dist/tooltip';
import '@hotmart-org-ca/cosmos/dist/tag';
import '@hotmart-org-ca/cosmos/dist/tag/variations/green.css';
import '@hotmart-org-ca/cosmos/dist/tag/variations/red.css';
import '@hotmart-org-ca/cosmos/dist/tag/variations/yellow.css';
import '@hotmart-org-ca/cosmos/dist/tag/variations/small.css';

import React, { HTMLAttributes, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getConcatUrl, copyToClipboard } from '@common/utils';
import { Label } from '@components/Label';
import {
  Container,
  Button,
  Link,
  Description,
  ContainerLink,
  ExternalLink,
  Tag,
} from './styles';

export type UrlDescriptionProps = HTMLAttributes<HTMLDivElement> & {
  domain: string;
  path: string;
};

const UrlDescriptionComponent: React.FC<UrlDescriptionProps> = ({
  domain,
  path,
  ...attrs
}) => {
  const { t } = useTranslation();

  const lastPublishingStatus = useSelector(
    (state: State) => state.pageInfo.lastPublishingStatus
  );
  const published = useSelector((state: State) => state.pageInfo.published);
  const draft = useSelector((state: State) => state.pageConfig.draft);

  const tooltipStatus = useMemo(() => {
    if (published && draft) {
      return 'unsavedChanges';
    }

    if (lastPublishingStatus) {
      return lastPublishingStatus;
    }

    return 'draft';
  }, [draft, lastPublishingStatus, published]);

  const memoizedStatus = useMemo(() => {
    if (tooltipStatus === 'success') {
      return 'hot-tag--sm hot-tag--green';
    }
    if (tooltipStatus === 'pending') {
      return 'hot-tag--sm hot-tag--yellow';
    }
    if (tooltipStatus === 'fail') {
      return 'hot-tag--sm hot-tag--red';
    }
    return 'hot-tag--sm';
  }, [tooltipStatus]);

  const url = useMemo(() => getConcatUrl(domain, path), [domain, path]);

  return (
    <Container {...attrs}>
      <Description>{t('urlConfig.path.description')}</Description>

      <Tag>
        <hot-tag class={memoizedStatus}>
          {t(`urlConfig.page.${tooltipStatus}`)}
        </hot-tag>
        <Label
          infoTooltip={{
            title: `${`labelTooltip.settings.domain.${tooltipStatus}.title`}`,
            description: `${`labelTooltip.settings.domain.${tooltipStatus}.description`}`,
            displayMode: 'always',
          }}
        />
      </Tag>

      <hot-tooltip
        position="top"
        content={t(
          `urlConfig.${published ? 'publishedLink' : 'unpublishedLink'}`
        )}
      >
        <ContainerLink isPublished={published}>
          <Link
            id="link"
            isPublished={published}
            aria-hidden="true"
            target="_blank"
            href={url}
            rel="noreferrer"
          >
            {url}
          </Link>
        </ContainerLink>
      </hot-tooltip>

      <hot-tooltip position="top" content={t('urlConfig.path.copy')}>
        <Button
          className="hot-button hot-button--sm"
          onClick={() => copyToClipboard(url)}
        >
          <i className="fal fa-copy" />
        </Button>
      </hot-tooltip>

      <hot-tooltip
        position="top"
        content={t(
          `urlConfig.${published ? 'publishedLink' : 'unpublishedLink'}`
        )}
      >
        <ExternalLink
          aria-hidden="true"
          target="_blank"
          href={url}
          rel="noreferrer"
          isPublished={published}
        >
          <Button className="hot-button hot-button--sm" disabled={!published}>
            <i className="fal fa-external-link" />
          </Button>
        </ExternalLink>
      </hot-tooltip>
    </Container>
  );
};

export const UrlDescription = memo(UrlDescriptionComponent);
