import { HTMLAttributes, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '@components/BaseModal';
import { useUserInfo } from '@hooks/useUserInfo';
import {
  Container,
  Image,
  Title,
  Description,
  ActionsContainer,
  NewPlanButton,
  CloseButton,
  ModalFooterStyle,
} from './styles';

type UserLimitModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  error: string;
  closeIconAbsolute?: boolean;
  onClose: () => void;
  onPrimaryButtonClicked?: () => void;
};

const UserLimitModalComponent: React.FC<UserLimitModalProps> = ({
  isOpen,
  error,
  closeIconAbsolute = false,
  onClose,
  onPrimaryButtonClicked,
  ...attrs
}) => {
  const { t } = useTranslation();

  const { isExtensions } = useUserInfo();

  const handlePageAccessClick = useCallback(() => {
    onPrimaryButtonClicked?.();
    window.open(
      `${
        isExtensions
          ? 'https://extensions.hotmart.com/salespage'
          : 'https://klickpages.com.br/monteseuplano'
      }`,
      '_blank'
    );
  }, [onPrimaryButtonClicked, isExtensions]);

  return (
    <Container>
      <ModalFooterStyle />
      <BaseModal
        {...attrs}
        fixedSize="428px"
        isOpen={isOpen}
        closeIconAbsolute={closeIconAbsolute}
        footerClass="modal-warning-footer"
        headerClass="modal-warning-header "
        header={
          <Image>
            <img
              src="/images/planlimit.svg"
              width={256}
              height={256}
              alt="planLimit"
            />
          </Image>
        }
        footer={
          <ActionsContainer>
            <NewPlanButton
              className="hot-button hot-button--primary"
              onClick={handlePageAccessClick}
            >
              {`${t('modals.warning.newPlanButton')} `}
            </NewPlanButton>
            <CloseButton
              className="hot-button hot-button--tertiary"
              onClick={onClose}
            >
              {t('modals.warning.closeButton')}
            </CloseButton>
          </ActionsContainer>
        }
        onClose={onClose}
      >
        <Title>{`${t(`modals.warning.${error}.title`)} `}</Title>
        <Description>
          {`${t(`modals.warning.${error}.description`)} `}
        </Description>
      </BaseModal>
    </Container>
  );
};

export const UserLimitModal = memo(UserLimitModalComponent);
