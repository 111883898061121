import styled from 'styled-components';

export const Export = styled.div`
  padding: 0 16px;
`;

export const ExportButton = styled.button`
  margin-top: 16px;
  width: 100%;
`;

export const Divider = styled.hr`
  display: block;
  width: 300px;
  margin: 16px 0;
`;

export const Description = styled.div`
  font-size: 12px;
  color: var(--gray-500);
`;

export const Import = styled.div`
  padding: 0 16px;
`;

export const FileInputContainer = styled.div`
  margin-top: 16px;
`;

export const FileInputLabel = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImportButton = styled.button`
  width: 100%;
`;
