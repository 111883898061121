import { useCallback } from 'react';
import { useAppDispatch } from '@store/index';
import { checkoutThunks } from '@store/thunks/checkout';
import { useSelector } from 'react-redux';

export const useCheckout = () => {
  const dispatch = useAppDispatch();

  const url = useSelector((state: State) => state.checkout.url);

  const selectedOffer = useSelector(
    (state: State) => state.checkout.selectedOffer
  );

  const selectedProduct = useSelector(
    (state: State) => state.checkout.selectedProduct
  );

  const isProductListEmpty = useSelector(
    (state: State) => state.checkout.isProductListEmpty
  );

  const initializeCheckoutIntegration = useCallback(
    () => dispatch(checkoutThunks.initializeCheckoutIntegration()).unwrap(),
    [dispatch]
  );

  return {
    url,
    selectedOffer,
    selectedProduct,
    isProductListEmpty,
    initializeCheckoutIntegration,
  };
};
