import { isInvalidEmoji } from '@common/utils';
import { usePageScripts } from '@hooks/usePageScripts';
import Toast from '@services/toast';
import classNames from 'classnames';
import React, { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageInfo } from '@hooks/usePageInfo';
import { Submenu } from '../../../Submenu';
import { Button, Title, ErrorMessage } from './styles';

export const SupportChatSubmenu: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...attrs
}) => {
  const { t } = useTranslation();

  const { supportChatScript, saveSupportChatScript } = usePageScripts();
  const { savePageMetadata } = usePageInfo();
  const [script, setScript] = useState(supportChatScript || '');
  const [invalidScript, setInvalidScript] = useState(false);
  const [invalidEmoji, setInvalidEmoji] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleChangeScript = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInvalidScript(false);
      setScript(event.target.value);
      setInvalidEmoji(isInvalidEmoji(event.target.value));
    },
    []
  );

  const isInvalidScript = useCallback(
    () => supportChatScript === '' && script === '',
    [script, supportChatScript]
  );

  const handleSave = useCallback(async () => {
    if (invalidEmoji) {
      Toast.error('toastMessages.general.error.saved');
      return;
    }

    if (isInvalidScript()) {
      setInvalidScript(true);
      return;
    }

    try {
      setLoading(true);
      await saveSupportChatScript(script);
      Toast.success('toastMessages.general.success.saved');
      await savePageMetadata();
      setLoading(false);
    } catch {
      Toast.error('toastMessages.general.error.saved');
      setLoading(false);
    }
  }, [
    invalidEmoji,
    isInvalidScript,
    savePageMetadata,
    saveSupportChatScript,
    script,
  ]);

  return (
    <Submenu title="sideMenu.settings.supportChat" {...attrs}>
      <div className="hot-form">
        <Title htmlFor="title">{t('supportChat.title')}</Title>

        <textarea
          className={classNames('hot-form__input hot-form__textarea--sm', {
            'is-invalid': invalidScript || invalidEmoji,
          })}
          id="title"
          placeholder={t('supportChat.placeholder')}
          value={script}
          onChange={(e) => handleChangeScript(e)}
          rows={3}
        />
        {invalidEmoji && (
          <div className="invalid-feedback">{t('seo.emojiError')}</div>
        )}

        {invalidScript && (
          <ErrorMessage className="invalid-feedback">
            {t('scripts.form.script.required')}
          </ErrorMessage>
        )}
      </div>

      <Button
        onClick={handleSave}
        className={classNames('hot-button hot-button--sm hot-button--primary', {
          'hot-button--loading': loading,
        })}
      >
        <span>{t('supportChat.saveButton')}</span>
      </Button>
    </Submenu>
  );
};
