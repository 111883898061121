import '@hotmart-org-ca/cosmos/dist/menu';
import '@hotmart-org-ca/cosmos/dist/menu-item';
import '@hotmart-org-ca/cosmos/dist/header';
import '@hotmart-org-ca/cosmos/dist/collapse';
import '@hotmart-org-ca/cosmos/dist/tooltip';

import { HTMLAttributes, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditorEngineManager } from '@hotmart-org-ca/saas-pages-engine';
import TagManager from 'react-gtm-module';
import { MenuItem } from '../MenuItem';
import { Icons } from '../Icon/icons';
import { setActiveMenu } from '../../store/slices/menu';
import { EditorHeader } from '../EditorHeader';
import { HelpButton } from '../HelpButton';

export type SideMenuProps = HTMLAttributes<HTMLDivElement> & {
  isTemplatePreview?: boolean;
};

const SideMenuComponent: React.FC<SideMenuProps> = ({
  isTemplatePreview = false,
  ...attrs
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPreviewMode = useSelector(
    (state: State) => state.application.isPreviewMode
  );
  const { activeMenu, activeComponent } = useSelector((state: State) => ({
    activeMenu: state.menu.activeMenu,
    activeComponent: state.lsEditor.activeComponent,
  }));

  const eventNameById: Record<string, string> = useMemo(
    () => ({
      add: 'Structures',
      settings: 'Settings',
      'page-structure': 'Page Structure',
      'media-center': 'Images',
      'sections-template': 'Section Templates',
      'models-list': 'Models',
    }),
    []
  );

  const menuItems: { id: string; icon: Icons; label: string }[] =
    useMemo(() => {
      const firstItemId = 'models-list';
      const firstItemLabel = t('sideMenu.models.title');

      return [
        { id: 'add', icon: 'add', label: t('sideMenu.add.title') },
        {
          id: firstItemId,
          icon: 'sections-template',
          label: firstItemLabel,
        },
        {
          id: 'page-structure',
          icon: 'sections',
          label: t('sideMenu.pageStructure.title'),
        },
        {
          id: 'media-center',
          icon: 'media',
          label: t('sideMenu.media.title'),
        },
        {
          id: 'settings',
          icon: 'settings',
          label: t('sideMenu.settings.title'),
        },
      ];
    }, [t]);

  const handleMenuItemClick = useCallback(
    (id: string) => {
      dispatch(setActiveMenu(id));

      if (id !== 'adjustments' && activeComponent) {
        EditorEngineManager.clearActive();
      }

      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          custom_event_name: 'click_menu_editor',
          event_details: eventNameById[id],
        },
      });
    },
    [activeComponent, dispatch, eventNameById]
  );

  useEffect(() => {
    const hotMenu = document.querySelector('hot-menu') as HTMLElement;
    const menu = hotMenu.shadowRoot?.querySelector(
      '.hot-application__menu'
    ) as HTMLElement;
    const menuHeader = hotMenu.shadowRoot?.querySelector(
      '.hot-application__menu-header'
    ) as HTMLElement;

    menuHeader.style.display = 'none';
    menu.style.display = isPreviewMode ? 'none' : '';
  }, [isPreviewMode]);

  return (
    <hot-menu slot="menu" role="menu" {...attrs}>
      <div slot="product-name">{t('sideMenu.menu')}</div>

      <EditorHeader isTemplatePreview={isTemplatePreview} />

      {menuItems.map((menuItemProps) => (
        <hot-tooltip
          content={menuItemProps.label}
          position="right"
          key={menuItemProps.id}
        >
          <MenuItem
            {...menuItemProps}
            breakLine={false}
            active={menuItemProps.id === activeMenu}
            onClick={handleMenuItemClick}
          />
        </hot-tooltip>
      ))}

      <HelpButton />
    </hot-menu>
  );
};

export const SideMenu = memo(SideMenuComponent);
