import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const ObjectGroup: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M25.25 10H20V6.75C20 6.375 19.625 6 19.25 6H6.75C6.3125 6 6 6.375 6 6.75V17.25C6 17.6875 6.3125 18 6.75 18H12V21.25C12 21.6875 12.3125 22 12.75 22H25.25C25.625 22 26 21.6875 26 21.25V10.75C26 10.375 25.625 10 25.25 10ZM8 16V8H18V16H8ZM24 20H14V18H19.25C19.625 18 20 17.6875 20 17.25V12H24V20ZM31.25 6C31.625 6 32 5.6875 32 5.25V0.75C32 0.375 31.625 0 31.25 0H26.75C26.3125 0 26 0.375 26 0.75V2H6V0.75C6 0.375 5.625 0 5.25 0H0.75C0.3125 0 0 0.375 0 0.75V5.25C0 5.6875 0.3125 6 0.75 6H2V22H0.75C0.3125 22 0 22.375 0 22.75V27.25C0 27.6875 0.3125 28 0.75 28H5.25C5.625 28 6 27.6875 6 27.25V26H26V27.25C26 27.6875 26.3125 28 26.75 28H31.25C31.625 28 32 27.6875 32 27.25V22.75C32 22.375 31.625 22 31.25 22H30V6H31.25ZM2 2H4V4H2V2ZM4 26H2V24H4V26ZM26 22.75V24H6V22.75C6 22.375 5.625 22 5.25 22H4V6H5.25C5.625 6 6 5.6875 6 5.25V4H26V5.25C26 5.6875 26.3125 6 26.75 6H28V22H26.75C26.3125 22 26 22.375 26 22.75ZM30 26H28V24H30V26ZM28 4V2H30V4H28Z"
      fill={color}
    />
  </StyledSVG>
);
