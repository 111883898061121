import React from 'react';
import { StyledSVG } from '../styles';
import { BaseIconProps } from '../types';

export const Button: React.FC<BaseIconProps> = ({ color, ...attrs }) => (
  <StyledSVG
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M25 2C25.5 2 26 2.5 26 3V25C26 25.5625 25.5 26 25 26H3C2.4375 26 2 25.5625 2 25V3C2 2.5 2.4375 2 3 2H25ZM25 0H3C1.3125 0 0 1.375 0 3V25C0 26.6875 1.3125 28 3 28H25C26.625 28 28 26.6875 28 25V3C28 1.375 26.625 0 25 0ZM21.25 15.125C21.625 15.125 22 14.8125 22 14.375V13.625C22 13.25 21.625 12.875 21.25 12.875H6.75C6.3125 12.875 6 13.25 6 13.625V14.375C6 14.8125 6.3125 15.125 6.75 15.125H21.25Z"
      fill={color}
    />
  </StyledSVG>
);
