import styled from 'styled-components';

export const Title = styled.label`
  font-size: 16px;
  margin-bottom: 4px;
`;

export const DescriptionArea = styled.label`
  margin-top: 8px;
  margin-bottom: 0px;
  color: var(--gray-500);
  font-size: 12px;
`;
