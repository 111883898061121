import '@hotmart-org-ca/cosmos/dist/form/form.css';
import '@hotmart-org-ca/cosmos/dist/form/input-custom.css';
import '@hotmart-org-ca/cosmos/dist/form/input-radio.css';
import { memo, HTMLAttributes, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CosmosButton } from '@components/CosmosButton';
import {
  Description,
  NewSuggestionsButtonWrapper,
  RadioWrapper,
  Title,
} from './styles';

type SuggestionsProps = HTMLAttributes<HTMLDivElement> & {
  suggestions: string[];
  selectedSuggestion: string;
  onGenerateNewButtonClicked: () => void;
  onSuggestionSelected: (suggestion: string) => void;
};

const SuggestionsComponent: React.FC<SuggestionsProps> = ({
  suggestions,
  selectedSuggestion,
  onGenerateNewButtonClicked,
  onSuggestionSelected,
  ...attrs
}) => {
  const { t } = useTranslation();

  const handleSelectedOption = useCallback(
    (suggestion: string) => {
      onSuggestionSelected(suggestion);
    },
    [onSuggestionSelected]
  );

  return (
    <div {...attrs}>
      <Title>
        {t('pageWizard.salesPage.ai.sidebar.describeProduct.suggestions.title')}
      </Title>
      <Description>
        {t(
          `pageWizard.salesPage.ai.sidebar.describeProduct.suggestions.description`
        )}
      </Description>
      <RadioWrapper className="hot-form hot-form--custom hot-form--radio">
        {suggestions.map((suggestion) => (
          <div key={suggestion}>
            <input
              id={suggestion}
              type="radio"
              className="hot-form__input"
              checked={suggestion === selectedSuggestion}
              onChange={() => {
                handleSelectedOption(suggestion);
              }}
            />
            <label className="hot-form__label" htmlFor={suggestion}>
              {suggestion}
            </label>
          </div>
        ))}
      </RadioWrapper>
      <NewSuggestionsButtonWrapper>
        <CosmosButton
          onButtonClicked={onGenerateNewButtonClicked}
          label={t('pageWizard.salesPage.ai.sidebar.newSuggestions')}
          type="tertiary"
          size="md"
          width="auto"
        />
      </NewSuggestionsButtonWrapper>
    </div>
  );
};

export const Suggestions = memo(SuggestionsComponent);
