import i18n from 'i18next';
import toastSuccess from './assets/toastSuccess.svg';
import toastDanger from './assets/toastDanger.svg';

interface ToastIcons {
  [key: string]: string;
}

const toastIcons: ToastIcons = {
  toastsuccess: toastSuccess,
  toastdanger: toastDanger,
};

type ToastType = 'toastinfo' | 'toastsuccess' | 'toastdanger' | 'toastwarning';

export default class Toast {
  static success(message: string) {
    this.show('toastsuccess', message);
  }

  static error(message: string) {
    this.show('toastdanger', message);
  }

  private static createToastEvent(
    type: ToastType,
    message: string,
    icon: string
  ) {
    const toastMessage = `
      <div style="display: flex; align-items: start;">
        <img src="${icon}" style="margin-right: 16px; margin-top: 2px;"/>
        <span>${i18n.t(message)}</span>
      </div>
    `;

    return new CustomEvent(type, {
      detail: {
        message: toastMessage,
        position: 'top right',
      },
    });
  }

  private static show(type: ToastType, message: string) {
    const icon = toastIcons[type];
    const customEvent = this.createToastEvent(type, message, icon);
    window.dispatchEvent(customEvent);
  }
}
